import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { AppState } from '../../../+state/app.state';
import { DisplaySnackbarAlert } from '../../../+state/layout/actions/layout.actions';
import { selectSnackbarAlert } from '../../../+state/layout/selectors/layout.selector';
import { isNonNull } from '../../helpers/rxjs-type-guards';
import { AlertMessage } from '../../models/alert.interface';

@Component({
  selector: 'app-alert-snackbar',
  templateUrl: './alert-snackbar.component.html',
  styleUrls: ['./alert-snackbar.component.scss'],
})
export class AlertSnackbarComponent implements OnInit, OnDestroy {
  destroyed$ = new Subject<boolean>();
  snackbarAlert$ = this.store.select(selectSnackbarAlert);

  alert?: AlertMessage;

  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    this.snackbarAlert$
      .pipe(takeUntil(this.destroyed$), filter(isNonNull))
      .subscribe((alert) => {
        this.alert = undefined;
        this.alert = alert;
      });
  }

  onClosed(dismissedAlert: any): void {
    this.store.dispatch(DisplaySnackbarAlert.ClearAlert());
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.unsubscribe();
  }
}
