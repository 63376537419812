import { createAction, props } from '@ngrx/store';
import { AddUserModel } from 'src/app/admin/add-user-modal/add-user-modal.component';
import {
    EngagementResourceCategory
} from 'src/app/shared/models/engagement-resource.interface';
import {
    AccountAdminManagedUsersDTO,
    AccountAdminManagedUsersFilter,
    AccountSettings,
    AccountSummaryData,
    AddedUsersResponseModel,
    NewAccountAdminModel,
    OrganisationSettings,
    SeatsInAcc,
    UpdateUsersDelegatedInvitesModel,
} from '../../shared/models/account-admin-managed-users-DTO';
import { DateRange } from '../../shared/models/date-range.interface';
import { HelpCenterCategory } from '../../shared/models/help-center.interface';
import { LiveAnalyticsGraph } from '../../shared/models/live-analytics/live-analytics-graph.interface';
import { RecreateUsersDTO } from '../../shared/models/reactivate-users-dto.interface';
import { UpdateTeamMapStatusReq } from '../../shared/models/team-map-processing.interface';
import { MindflickAccountBasicDetails, MindflickOrganisation, MindflickRole, UserBasicDetails } from 'src/app/shared/models';
import { EditRoleInfoInterface } from 'src/app/shared/models/edit-role-info-model.interface';
import { AddNoteToAccountUserReq } from 'src/app/shared/models/add-note-to-account-user';
import { AddNoteResponse } from 'src/app/shared/models/add-note-response';
import AccountsForOrgAdmin from 'src/app/shared/models/org-admin-accounts';

export namespace GetManagedUsersForAccountAdmin {
  export const Request = createAction(
    '[Account Admin] Get users for account admin request',
    props<{ accountId: number; }>()
  );

  export const Success = createAction(
    '[Account Admin] Get users for account admin success',
    props<{ users: AccountAdminManagedUsersDTO[]; }>()
  );

  export const Fail = createAction(
    '[Account Admin] Get users for account admin fail',
    props<{ error: any; }>()
  );
}

export namespace GetOnboardingLiveAnalytics {
  export const Request = createAction(
    '[Admin] Get Onboarding Live Analytics Data Request',
    props<{ accountId: number; }>()
  );
  export const Success = createAction(
    '[Admin] Get Onboarding Live Analytics Data Success',
    props<{ data: LiveAnalyticsGraph[]; }>()
  );
  export const Fail = createAction(
    '[Admin] Get Onboarding Live Analytics Data Fail',
    props<{ error: any; }>()
  );
}


export namespace GetConnectionsLiveAnalytics {
  export const Request = createAction(
    '[Admin] Get Connections Live Analytics Data Request',
    props<{ accountId: number; }>()
  );
  export const Success = createAction(
    '[Admin] Get Connections Live Analytics Data Success',
    props<{ data: LiveAnalyticsGraph[]; }>()
  );
  export const Fail = createAction(
    '[Admin] Get Connections Live Analytics Data Fail',
    props<{ error: any; }>()
  );
}

export namespace GetTeamsLiveAnalytics {
  export const Request = createAction(
    '[Admin] Get Teams Live Analytics Data Request',
    props<{ accountId: number; }>()
  );
  export const Success = createAction(
    '[Admin] Get Teams Live Analytics Data Success',
    props<{ data: LiveAnalyticsGraph[]; }>()
  );
  export const Fail = createAction(
    '[Admin] Get Teams Live Analytics Data Fail',
    props<{ error: any; }>()
  );
}

export namespace GetInteractionLiveAnalytics {
  export const Request = createAction(
    '[Admin] Get Interaction Live Analytics Data Request',
    props<{ accountId: number; dateRange: DateRange; }>()
  );
  export const Success = createAction(
    '[Admin] Get Interaction Live Analytics Data Success',
    props<{ data: LiveAnalyticsGraph[]; }>()
  );
  export const Fail = createAction(
    '[Admin] Get Interaction Live Analytics Data Fail',
    props<{ error: any; }>()
  );
}

export namespace GetNudgeAndWorkOnLiveAnalytics {
  export const Request = createAction(
    '[Admin] Get Nudge/Work-On Live Analytics Data Request',
    props<{ accountId: number; dateRange: DateRange; }>()
  );
  export const Success = createAction(
    '[Admin] Get Nudge/Work-On Live Analytics Data Success',
    props<{ data: LiveAnalyticsGraph[]; }>()
  );
  export const Fail = createAction(
    '[Admin] Get Nudge/Work-On Live Analytics Data Fail',
    props<{ error: any; }>()
  );
}

export namespace GetUsageLiveAnalytics {
  export const Request = createAction(
    '[Admin] Get Usage Live Analytics Data Request',
    props<{ accountId: number; dateRange: DateRange; }>()
  );
  export const Success = createAction(
    '[Admin] Get Usage Live Analytics Data Success',
    props<{ data: LiveAnalyticsGraph[]; }>()
  );
  export const Fail = createAction(
    '[Admin] Get Usage Live Analytics Data Fail',
    props<{ error: any; }>()
  );
}

export namespace AddUsers {
  export const Request = createAction(
    '[Admin] Add Users Request',
    props<{ users: AddUserModel[]; accountId: number; }>()
  );
  export const Success = createAction(
    '[Admin] Add Users Success',
    props<{ resp: AddedUsersResponseModel; }>()
  );
  export const Fail = createAction(
    '[Admin] Add Users Fail',
    props<{ error: any; }>()
  );
}

export const SetAddUsersSuccess = createAction(
  '[Admin] Set Add Users Success',
  props<{ success?: boolean; message?: string; }>()
);

export namespace GetMindflickAccountSettings {
  export const Request = createAction(
    '[Admin] Get Mindflick Account Settings Request', props<{accId : number}>()
  );

  export const Success = createAction(
    '[Admin] Get Mindflick Account Settings Success',
    props<{ resp: AccountSettings; }>()
  );

  export const Fail = createAction(
    '[Admin] Get Mindflick Account Settings Fail',
    props<{ error: any; }>()
  );
}

export namespace SetMindflickAccountSettings {
  export const Request = createAction(
    '[Admin] Set Mindflick Account Settings Request',
    props<{ settings: AccountSettings; }>()
  );

  export const Success = createAction(
    '[Admin] Set Mindflick Account Settings Success',
    props<{ settings: AccountSettings; }>()
  );

  export const Fail = createAction(
    '[Admin] Set Mindflick Account Settings Fail',
    props<{ error: any; }>()
  );
}

export namespace AccountAdminResendSpotlightInvites {
  export const Request = createAction(
    '[Admin] Account Admin Resend Spotlight Invites Request',
    props<{ userAks: number[]; accountId: number; }>()
  );

  export const Success = createAction(
    '[Admin] Account Admin Resend Spotlight Invites Success'
  );

  export const Fail = createAction(
    '[Admin] Account Admin Resend Spotlight Invites Fail',
    props<{ error: any; }>()
  );
}

export namespace AccountAdminGetRemainingSeatsForAcc {
  export const Request = createAction(
    '[Admin] Account Admin Get Remaining Seats For Org Request',
    props<{ accountId: number }>()
  );
  export const Success = createAction(
    '[Admin] Account Admin Get Remaining Seats For Org Success',
    props<{ resp: SeatsInAcc; }>()
  );
  export const Fail = createAction(
    '[Admin] Account Admin Get Remaining Seats For Org Fail',
    props<{ error: any; }>()
  );
}

export namespace UpdateSpotlightDownloadPermission {
  export const update = createAction(
    '[Admin] Update Spotlight Download Permission Request',
    props<{ enabled: boolean; }>()
  );
}

export const UpdateManagedUsersFilter = createAction(
  '[Admin] Account Admin Update Filter',
  props<{ filter: AccountAdminManagedUsersFilter; }>()
);

export const ClearManagedUsersFilter = createAction(
  '[Admin] Account Admin Clear Filter'
);

export const SetManagedUsersNameFilter = createAction(
  '[Account Admin] Set Managed Users Name Filter',
  props<{ searchTerm: string; }>()
);

export const ClearManagedUsersNameFilter = createAction(
  '[Account Admin] Clear Managed Users Name Filter'
);


export namespace GetEngagementResources {
  export const Request = createAction(
    '[Admin] Get Engagement Resources Request'
  );

  export const Success = createAction(
    '[Admin] Get Engagement Resources Success',
    props<{ categories: EngagementResourceCategory[]; }>()
  );

  export const Fail = createAction(
    '[Admin] Get Engagement Resources Fail',
    props<{ error: any; }>()
  );
}

export const SetSelectedPopoutTitles = createAction(
  '[Admin] Set Selected Popout Titles',
  props<{ categoryTitle?: string; subCategoryTitle?: string; }>()
);

export namespace GetPinnedEngagementResources {
  export const Request = createAction(
    '[Admin] Get Pinned Engagement Resources Request'
  );

  export const Success = createAction(
    '[Admin] Get Pinned Engagement Resources Success',
    props<{ pinnedEngagementResourceIds: number[]; }>()
  );

  export const Fail = createAction(
    '[Admin] Get Pinned Engagement Resources Fail',
    props<{ error: any; }>()
  );
}

export namespace AddPinnedEngagementResource {
  export const Request = createAction(
    '[Admin] Add Pinned Engagement Resource Request',
    props<{ engagementResourceId: number; }>()
  );

  export const Success = createAction(
    '[Admin] Add Pinned Engagement Resource Success',
    props<{ engagementResourceId: number; }>()
  );

  export const Fail = createAction(
    '[Admin] Add Pinned Engagement Resource Fail',
    props<{ error: any; }>()
  );
}

export namespace DeletePinnedEngagementResource {
  export const Request = createAction(
    '[Admin] Delete Pinned Engagement Resource Request',
    props<{ engagementResourceId: number; }>()
  );

  export const Success = createAction(
    '[Admin] Delete Pinned Engagement Resource Success',
    props<{ engagementResourceId: number; }>()
  );

  export const Fail = createAction(
    '[Admin] Delete Pinned Engagement Resource Fail',
    props<{ error: any; }>()
  );
}

export namespace GetHelpCenterCategories {
  export const Request = createAction(
    '[Admin] Get Help Center Categories Request'
  );

  export const Success = createAction(
    '[Admin] Get Help Center Categories Success',
    props<{ categories: HelpCenterCategory[]; }>()
  );

  export const Fail = createAction(
    '[Admin] Get Help Center Categories Fail',
    props<{ error: any; }>()
  );
}

export const SetAccountAdminSearchTerm = createAction(
  '[Admin] Set Account Admins Search Term',
  props<{ searchTerm: string; }>()
);

export namespace FilterEngagementResourcesBySearchTerm {
  export const Request = createAction(
    '[Admin] Filter Engagement Resources By Search Term Request',
    props<{ keywords: string; }>()
  );
  export const Success = createAction(
    '[Admin] Filter Engagement Resources By Search Term Success',
    props<{ filteredIds: number[]; }>()
  );
  export const Fail = createAction(
    '[Admin] Filter Engagement Resources By Search Term Fail',
    props<{ error: any; }>()
  );
}

export const ResetEngagamentResourceSaveResult = createAction(
  '[Admin] Reset Engagement Resource Save Result'
);

export namespace ReactivateUsers {
  export const Request = createAction(
    '[Account Admin] Reactivate users Request',
    props<{ userAKs: number[], accountId: number }>()
  );
  export const Success = createAction(
    '[Account Admin] Reactivate users Success',
    props<{ users: AccountAdminManagedUsersDTO[] }>()
  );
  export const Fail = createAction(
    '[Account Admin] Reactivate users Fail',
    props<{ error: any; }>()
  );
}

export namespace RecreateUsers {
  export const Request = createAction(
    '[Account Admin] Recreate users Request',
    props<{ model: RecreateUsersDTO, accountId: number }>()
  );
  export const Success = createAction(
    '[Account Admin] Reactivate users Success',
    props<{ users: AccountAdminManagedUsersDTO[]; }>()
  );
  export const Fail = createAction(
    '[Account Admin] Reactivate users Fail',
    props<{ error: any; }>()
  );
}

export const UpdateUserManagementOrderBy = createAction(
  'Update User Management Order By',
  props<{
    orderBy:
    | 'name-asc'
    | 'name-desc'
    | 'date-asc'
    | 'date-desc'
    | 'dept-asc'
    | 'dept-desc'
    | 'web-status-asc'
    | 'web-status-desc'
    | 'email-desc'
    | 'email-asc'
    | 'mobile-status-asc'
    | 'mobile-status-desc'
    | 'allocated-seats-asc'
    | 'allocated-seats-desc';
  }>()
);

export namespace UnlockUser {
  export const Request = createAction(
    '[Account Admin] Unlock User Request',
    props<{ userAk: number; accountId: number }>()
  );

  export const Success = createAction(
    '[Account Admin] Unlock User Success',
    props<{ userAk: number; }>()
  );

  export const Fail = createAction(
    '[Account Admin] Unlock User Fail',
    props<{ error: any; }>()
  );
}

export namespace UnlockUsers {
  export const Request = createAction(
    '[Account Admin] Unlock Users Request',
    props<{ userAks: number[]; accountId: number }>()
  );

  export const Success = createAction(
    '[Account Admin] Unlock Users Success',
    props<{ userAks: number[]; }>()
  );

  export const Fail = createAction(
    '[Account Admin] Unlock Users Fail',
    props<{ error: any; }>()
  );
}

export const AdminSetSelectedUser = createAction(
  '[Account Admin] Set Selected User',
  props<{ userAk: number; }>()
);

export const AdminClearSelectedUser = createAction(
  '[Account Admin] Clear Selected User'
);

export namespace UpdateUsersDelegatedInvites {
  export const Request = createAction(
    '[Account Admin] Update Users Delegated Invites Request',
    props<{ payload: UpdateUsersDelegatedInvitesModel; accountId: number; }>()
  );

  export const Success = createAction(
    '[Account Admin] Update Users Delegated Invites Success',
    props<{ payload: UpdateUsersDelegatedInvitesModel; }>()
  );

  export const Fail = createAction(
    '[Account Admin] Update Users Delegated Invites Fail',
    props<{ error: any; }>()
  );
}

export namespace DownloadAdminTeamMap {
  export const Request = createAction(
    '[Admin] Download Admin Team Map Request',
    props<{ memberIds: number[]; accountId: number; }>()
  );

  export const Success = createAction(
    '[Admin] Download Admin Team Map Success'
  );

  export const MapProcessingUpdate = createAction(
    '[Admin] Team map processing has updated',
    props<{ teamMap: UpdateTeamMapStatusReq; }>()
  );

  export const GetTeamMapFromToken = createAction(
    '[Admin] Download Admin Team Map Success',
    props<{ token: string; }>()
  );

  export const Fail = createAction(
    '[Admin] Download Admin Team Map Fail',
    props<{ error: any; }>()
  );
}

export namespace UpdateUserDebriefDate {
  export const Request = createAction(
    '[Admin] Update User Debrief Date Request',
    props<{ userAk: number; debriefDate: Date; accountId: number }>()
  );

  export const Success = createAction(
    '[Admin] Update User Debrief Date Success',
    props<{ userAk: number; debriefDate: Date; }>()
  );

  export const Fail = createAction(
    '[Admin] Update User Debrief Date Fail',
    props<{ error: any; }>()
  );
}

export namespace GetMindflickOrganisation {
  export const Request = createAction(
    '[Admin] Get Mindflick Organisation Request'
  );

  export const Success = createAction(
    '[Admin] Get Mindflick Organisation Success',
    props<{ mindflickOrganisation: MindflickOrganisation }>()
  );

  export const Fail = createAction(
    '[Admin] Get Mindflick Organisation Fail',
    props<{ error: any; }>()
  );
}

export const SetCoordinatorSearchFilter = createAction(
  '[Admin] Set Coordinator Search Filter',
  props<{ searchFilter: string; }>()
);

export const ClearCoordinatorSearchFilter = createAction(
  '[Admin] Clear Coordinator Search Filter'
);

export namespace UpdateUserDetails {
  export const Request = createAction(
    '[Admin] Update User Details Request',
    props<{ userDetails: EditRoleInfoInterface; accountId: number; }>()
  );

  export const Success = createAction(
    '[Admin] Update User Details Success',
    props<{ userDetails: EditRoleInfoInterface }>()
  );

  export const Fail = createAction(
    '[Admin] Update User Details Fail',
    props<{ error: any }>()
  );
}

export namespace SetMindflickOrganisationSettings {
  export const Request = createAction(
    '[Admin] Set Mindflick Organisation Settings Request',
    props<{ settings: OrganisationSettings }>()
  );

  export const Success = createAction(
    '[Admin] Set Mindflick Organisation Settings Success',
    props<{ settings: OrganisationSettings }>()
  );

  export const Fail = createAction(
    '[Admin] Set Mindflick Organisation Settings Fail',
    props<{ error: any }>()
  );
}

export namespace AddNoteToAccountUser {
  export const Request = createAction(
    '[Admin] Add Note To Account User Request',
    props<{ note: AddNoteToAccountUserReq; }>()
  );

  export const Success = createAction(
    '[Admin] Add Note To Account User Success',
    props<{ note: AddNoteResponse; }>()
  );

  export const Fail = createAction(
    '[Admin] Add Note To Account User Fail',
    props<{ error: any; }>()
  );
}

export namespace GetUserAccountsWithUserManagePermission {
  export const Request = createAction(
    '[Admin] Get User Accounts With User Manage Permission Request'
  );

  export const Success = createAction(
    '[Admin] Get User Accounts With User Manage Permission Success',
    props<{ accounts: MindflickAccountBasicDetails[] }>()
  );

  export const Fail = createAction(
    '[Admin] Get User Accounts With User Manage Permission Fail',
    props<{ error: any }>()
  );
}

export const SetUserManagementSelectedAccountId = createAction(
  '[Admin] Set User Management Selected Account Id',
  props<{ accountId: number }>()
);

export const ClearUserManagementSelectedAccountId = createAction(
  '[Admin] Clear User Management Selected Account Id'
);

export namespace GetUserAccountsWithLiveAnalyticsPermission {
  export const Request = createAction(
    '[Admin] Get User Accounts With Live Analytics Permission Request'
  );

  export const Success = createAction(
    '[Admin] Get User Accounts With Live Analytics Permission Success',
    props<{ accounts: MindflickAccountBasicDetails[] }>()
  );

  export const Fail = createAction(
    '[Admin] Get User Accounts With Live Analytics Permission Fail',
    props<{ error: any }>()
  );
}

export const SetLiveAnalyticsSelectedAccountId = createAction(
  '[Admin] Set Live Analytics Selected Account Id',
  props<{ accountId: number }>()
);

export const ClearLiveAnalyticsSelectedAccountId = createAction(
  '[Admin] Clear Live Analytics Selected Account Id'
);


export namespace GetAccountsSummaryWithinOrg {
  export const Request = createAction(
    '[Admin] Get Accounts Summary Within Org Request'  
  );

  export const Success = createAction(
    '[Admin] Get Accounts Summary Within Org Success',
    props<{ accountsData : AccountSummaryData[] }>()
  );

  export const Fail = createAction(
    '[Admin] Get Accounts Summary Within Org Fail',
    props<{ error : any }>()
  );
}


export const SetSelectedAdminSummaryAccountId = createAction(
  '[Admin] Set Selected Admin Summary Account Id', 
  props<{ accountId?: number }>()
);


export namespace SetNewAccountAdmin {
  export const Request = createAction(
    '[Admin] Set New Account Admin Request', 
    props<{payload : NewAccountAdminModel}>()
  );

  export const Success = createAction(
    '[Admin] Set New Account Admin Success',
    props<{ userId : number, mindflickAccountId: number, mindflickRole?: MindflickRole }>()
  );

  export const Fail = createAction(
    '[Admin] Set New Account Admin Fail',
    props<{ error : any }>()
  );
}

export namespace GetAccountChampions {
  export const Request = createAction(
    '[Admin] Get Account Champions Request',
    props<{ accountId: number }>()
  );

  export const Success = createAction(
    '[Admin] Get Account Champions Success',
    props<{ resp: UserBasicDetails[] }>()
  );

  export const Fail = createAction(
    '[Admin] Get Account Champions Fail',
    props<{ error: any }>()
  );
}

export namespace GetAccountCoordinators {
  export const Request = createAction(
    '[Admin] Get Account Coordinators Request',
    props<{ accountId: number }>()
  );

  export const Success = createAction(
    '[Admin] Get Account Coordinators Success',
    props<{ resp: UserBasicDetails[] }>()
  );

  export const Fail = createAction(
    '[Admin] Get Account Coordinators Fail',
    props<{ error: any }>()
  );
}

export namespace GetAccountSettingsAccounts {
  export const Request = createAction(
    '[Admin] Get Account Settings Accounts Request'
  );

  export const Success = createAction(
    '[Admin] Get Account Settings Accounts Success',
    props<{ resp: MindflickAccountBasicDetails[] }>()
  );

  export const Fail = createAction(
    '[Admin] Get Account Settings Accounts Fail',
    props<{ error: any }>()
  );
}

export namespace GetAccountSettingsAccountsForOrgAdmin {
  export const Request = createAction(
    '[Admin] Get Account Settings Accounts For Org Admin Request'
  );

  export const Success = createAction(
    '[Admin] Get Account Settings Accounts For Org Admin Success',
    props<{ resp: MindflickAccountBasicDetails[] }>()
  );

  export const Fail = createAction(
    '[Admin] Get Account Settings Accounts For Org Admin Fail',
    props<{ error: any }>()
  );
}

export const SetSelectedAccountSettingsAccountId = createAction(
  '[Admin] Set Selected Account Settings Account Id',
  props<{ accountId: number}>()
);

export namespace AddChampionToAccount {
  export const Request = createAction(
    '[Admin] Add Champion To Account Request',
    props<{ accountId: number, userAk: number }>()
  );

  export const Success = createAction(
    '[Admin] Add Champion To Account Success',
    props<{ accountId: number, userAk: number, mindflickRole?: MindflickRole }>()
  );

  export const Fail = createAction(
    '[Admin] Add Champion To Account Fail',
    props<{ error: any }>()
  );
}

export namespace AddCoordinatorToAccount {
  export const Request = createAction(
    '[Admin] Add Coordinator To Account Request',
    props<{ accountId: number, userAk: number }>()
  );

  export const Success = createAction(
    '[Admin] Add Coordinator To Account Success',
    props<{ accountId: number, userAk: number, mindflickRole?: MindflickRole }>()
  );

  export const Fail = createAction(
    '[Admin] Add Coordinator To Account Fail',
    props<{ error: any }>()
  );
}

export namespace RemoveChampionFromAccount {
  export const Request = createAction(
    '[Admin] Remove Champion From Account Request',
    props<{ accountId: number, userAk: number }>()
  );

  export const Success = createAction(
    '[Admin] Remove Champion From Account Success',
    props<{ accountId: number, userAk: number }>()
  );

  export const Fail = createAction(
    '[Admin] Remove Champion From Account Fail',
    props<{ error: any }>()
  );
}

export namespace RemoveCoordinatorFromAccount {
  export const Request = createAction(
    '[Admin] Remove Coordinator From Account Request',
    props<{ accountId: number, userAk: number }>()
  );

  export const Success = createAction(
    '[Admin] Remove Coordinator From Account Success',
    props<{ accountId: number, userAk: number }>()
  );

  export const Fail = createAction(
    '[Admin] Remove Coordinator From Account Fail',
    props<{ error: any }>()
  );
}

export namespace GetValidAccountAdminsForOrg {
  export const Request = createAction(
    '[Admin] Get Valid Account Admins For Org Request'
  );

  export const Success = createAction(
    '[Admin] Get Valid Account Admins For Org Success',
    props<{ resp: UserBasicDetails[] }>()
  );

  export const Fail = createAction(
    '[Admin] Get Valid Champions For Org Fail',
    props<{ error: any }>()
  );
}

export namespace GetValidChampionsForOrg {
  export const Request = createAction(
    '[Admin] Get Valid Champions For Org Request'
  );

  export const Success = createAction(
    '[Admin] Get Valid Champions For Org Success',
    props<{ resp: UserBasicDetails[] }>()
  );

  export const Fail = createAction(
    '[Admin] Get Valid Champions For Org Fail',
    props<{ error: any }>()
  );
}

export namespace GetValidCoordinatorsForOrg {
  export const Request = createAction(
    '[Admin] Get Valid Coordinators For Org Request'
  );

  export const Success = createAction(
    '[Admin] Get Valid Coordinators For Org Success',
    props<{ resp: UserBasicDetails[] }>()
  );

  export const Fail = createAction(
    '[Admin] Get Valid Coordinators For Org Fail',
    props<{ error: any }>()
  );
}

export namespace GetAllAccountsForOrgAdmin {
  export const Request = createAction(
    '[Admin] Get Accounts For Org Admin Request',
  );

  export const Success = createAction(
    '[Admin] Get Accounts For Org Admin Success',
    props<{ accounts: AccountsForOrgAdmin[] }>()
  );

  export const Fail = createAction(
    '[Admin] Get Accounts For Org Admin Fail',
    props<{ error: any; }>()
  );
}
