import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState } from 'src/app/+state/app.state';
import { SetSubMenuSmallPopout } from 'src/app/+state/layout/actions/layout.actions';
import { SubMenuSmallPopoutEnum } from 'src/app/+state/layout/reducers/layout.reducers';
import {
  SetSelectedPage,
  SetSelectedTooltipByNames,
} from 'src/app/+state/tooltip/tooltip.actions';
import { SetPlatformSetting } from 'src/app/+state/user/user.actions';
import { Tooltip, TooltipSection } from '../models';

@Injectable({
  providedIn: 'root',
})
export class TooltipService {
  tooltipUrl: string = '';

  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string,
    private store: Store<AppState>
  ) {
    this.tooltipUrl = `${baseUrl}tooltip`;
  }

  getAllTooltips(): Observable<GetAllTooltipsResponse> {
    return this.http.get<GetAllTooltipsResponse>(this.tooltipUrl);
  }

  openTooltipsFromSettingsKey(settingsKey: string) {
    this.store.dispatch(
      SetSubMenuSmallPopout({
        subMenuSmallPopout: SubMenuSmallPopoutEnum.Tooltip,
      })
    );

    let keyArr = settingsKey.split('_');
    this.store.dispatch(
      SetSelectedTooltipByNames({
        sectionShortName: keyArr[0],
        tooltipName: keyArr[1],
      })
    );
    this.store.dispatch(SetSelectedPage({ pageId: 0 }));

    this.store.dispatch(
      SetPlatformSetting.Request({ key: settingsKey, value: 'TRUE' })
    );
  }
}

export interface GetAllTooltipsResponse {
  sections: TooltipSection[];
  tooltips: Tooltip[];
}
