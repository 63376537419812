import { NgModule } from '@angular/core';
import { AuthModule } from 'angular-auth-oidc-client';
import { environment } from '../../environments/environment';

@NgModule({
  imports: [
    AuthModule.forRoot({
      config: {
        secureRoutes: [
          'https://localhost:44400',
          'http://localhost:44400',
          'https://localhost:7001',
          'https://localhost:7005',
          'https://id.mindflick.co',
          'https://dev.admin.mindflick.co',
          'https://app.mindflick.co',
        ],
        authority: environment.identityServerRoot,
        redirectUrl: `${document.baseURI}/#/callback`,
        postLoginRoute: '/dashboard',
        postLogoutRedirectUri:
          window.location.origin + window.location.pathname,
        clientId: environment.identityServerClientId,
        scope:
          'openid profile Mindflick.PlatformAPI mindflick.signalr.platform offline_access',
        responseType: 'code',
        silentRenew: true,
        useRefreshToken: true,
        triggerRefreshWhenIdTokenExpired: false,
        renewTimeBeforeTokenExpiresInSeconds: 30,
        maxIdTokenIatOffsetAllowedInSeconds: 30 * 60,
        historyCleanupOff: false, //The access tokens have a 30 minute life span set on identity server, allow for variance in the system clock
      },
    }),
  ],
  exports: [AuthModule],
})
export class AuthConfigModule {}
