import { createReducer, on } from '@ngrx/store';
import * as StatusActions from './status.actions';
import { CommonActions } from '../common-actions';

export interface StatusState {
  currentPageTitle: string;
  isPageSelected: boolean;
}

export const initialStatusState: StatusState = {
  currentPageTitle: '',
  isPageSelected: false,
};

export const statusReducer = createReducer(
  initialStatusState,

  on(CommonActions.ClearState, (state, props) => ({
    ...initialStatusState
  })),

  on(StatusActions.SetCurrentPageTitle, (state, props) => ({
    ...state,
    currentPageTitle: props.title,
  })),

  on(StatusActions.SetPageSelected, (state, props) => ({
    ...state,
    isPageSelected: props.isPageSelected,
  }))
);
