import {
  HttpClient,
  HttpEvent,
  HttpHeaders,
  HttpResponse,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import JSZip from 'jszip';
import { firstValueFrom, of } from 'rxjs';
import { Observable, Subject } from 'rxjs';
import { catchError, scan, takeWhile } from 'rxjs/operators';
import {
  isHttpProgressEvent,
  isHttpResponse,
} from '../helpers/http-event-helpers';
import { AccountAdminManagedUsersDTO } from '../models/account-admin-managed-users-DTO';
import { Download, QueueItem } from '../models/downloads.interface';
import { saveAs } from 'file-saver';
import * as LayoutActions from '../../+state/layout/actions/layout.actions';
import { Store } from '@ngrx/store';
import { AppState } from '../../+state/app.state';

@Injectable({
  providedIn: 'root',
})
export class DownloadService {
  completed: QueueItem[] = [];
  downloadQueue: QueueItem[] = [];
  queueUpdated$ = new Subject<number>();

  constructor(
    private httpClient: HttpClient,
    @Inject('BASE_URL') private baseUrl: string,
    private store: Store<AppState>
  ) {}

  addToQueue(downloadName: string, downloadUrl: string) {
    var pending = new Observable<Download>((observer) => {
      observer.next({
        state: 'PENDING',
        progress: 0,
        content: null,
        fileName: null,
      });
    });
    this.downloadQueue.push({
      downloadName: downloadName,
      downloadUrl: downloadUrl,
      download$: pending,
    });
    this.queueUpdated$.next(this.downloadQueue.length);

    if (this.downloadQueue.length === 1) this.downloadNext();
  }

  private downloadNext() {
    if (this.downloadQueue.length > 0) {
      this.downloadQueue[0].download$ = this.downloadAuthenticated(
        this.downloadQueue[0].downloadUrl,
        this.downloadQueue[0].downloadName
      );
      this.downloadQueue[0].download$
        .pipe(takeWhile((x) => x.state !== 'DONE'))
        .subscribe();
    }
  }

  private downloadAuthenticated(
    downloadUrl: string,
    downloadName: string
  ): Observable<Download> {
    return this.httpClient
      .get(downloadUrl, {
        responseType: 'blob',
        observe: 'events',
        reportProgress: true,
      })
      .pipe(
        this.download(downloadName, (response, fileName) =>
          this.saveFile(response, fileName)
        )
      );
  }

  saveFile(response: Blob, fileName: string) {
    // Create a URL for the blob
    const url = URL.createObjectURL(response);

    // Create an anchor element to "point" to it
    const anchor = document.createElement('a');
    anchor.href = url;

    // Get the suggested filename for the file from the response headers
    anchor.download = fileName;

    // Simulate a click on our anchor element
    anchor.click();

    // Discard the object data
    URL.revokeObjectURL(url);
  }

  removeComplete(url: string) {
    this.completed = this.completed.filter((x) => x.downloadUrl !== url);
  }

  private download(
    fileName: string,
    saver?: (b: Blob, fileName: string) => void
  ): (source: Observable<HttpEvent<Blob>>) => Observable<Download> {
    return (source: Observable<HttpEvent<Blob>>) =>
      source.pipe(
        scan(
          (previous: Download, event: HttpEvent<Blob>): Download => {
            if (isHttpProgressEvent(event)) {
              this.store.dispatch(
                LayoutActions.DisplaySnackbarAlert.SetAlert({
                  alert: {
                    alertType: 'info',
                    messageHeader: 'Downloading',
                    message: `Your download is ${
                      event.total
                        ? Math.round((100 * event.loaded) / event.total)
                        : previous.progress
                    }% complete.`,
                    timeout: 180 * 1000,
                  },
                })
              );

              return {
                progress: event.total
                  ? Math.round((100 * event.loaded) / event.total)
                  : previous.progress,
                state: 'IN_PROGRESS',
                content: null,
                fileName: fileName,
              };
            }
            if (isHttpResponse(event)) {
              return this.handleDownloadFinish(event, saver);
            }
            return previous;
          },
          { state: 'PENDING', progress: 0, content: null, fileName: fileName }
        ),
        catchError((e) => {
          console.log(e);
          this.handleDownloadFail();
          return of(e);
        })
      );
  }

  private handleDownloadFinish(
    event: HttpResponse<Blob>,
    saver: ((b: Blob, fileName: string) => void) | undefined
  ) {
    if (saver && event.body) {
      saver(event.body, this.downloadQueue[0].downloadName!);
    }

    this.completed = [...this.completed, this.downloadQueue[0]];

    this.downloadQueue.shift();
    this.queueUpdated$.next(this.downloadQueue.length);
    this.downloadNext();

    this.store.dispatch(
      LayoutActions.DisplaySnackbarAlert.SetAlert({
        alert: {
          alertType: 'success',
          messageHeader: 'Downloaded!',
          message: `Your download is complete!`,
        },
      })
    );

    return {
      progress: 100,
      state: 'DONE',
      content: event.body,
      fileName: this.downloadQueue.length > 0 ? this.downloadQueue[0].downloadName : '',
    } as Download;
  }

  private handleDownloadFail = () => {
    if (this.downloadQueue.length > 0) {
      this.downloadQueue.shift();
    }

    return of({
      state: 'PENDING',
      progress: 0,
      content: null,
      fileName: null,
    } as Download);
  };

  downloadMultipleReportsAsZip = (
    users: AccountAdminManagedUsersDTO[],
    accountId: number,
    folderName: string
  ) => {
    this.store.dispatch(
      LayoutActions.DisplaySnackbarAlert.SetAlert({
        alert: {
          alertType: 'info',
          messageHeader: 'Downloading',
          message: `Your download for ${users.length} profiles is starting...`,
          timeout: 180 * 1000,
        },
      })
    );

    var zip = new JSZip();

    users.forEach((user) => {
      var url = `${this.baseUrl}accountadmin/account/${accountId}/user/${user.ak}/profile/pdf`;
      var filename = `${user.name.replace(/\s/, '')}-Spotlight.pdf`;

      var data = firstValueFrom(this.httpClient.get(url, { responseType: 'arraybuffer' }));

      zip.file(filename, data, { binary: true });
    });

    zip
      .generateAsync({ type: 'blob' })
      .then((data: Blob) => {
        saveAs(data, folderName);
      })
      .then(() => {
        this.store.dispatch(
          LayoutActions.DisplaySnackbarAlert.SetAlert({
            alert: {
              alertType: 'success',
              messageHeader: 'Downloaded!',
              message: `Your download for ${users.length} profiles is complete!`,
            },
          })
        );
      })
      .catch(() => {
        this.store.dispatch(
          LayoutActions.DisplaySnackbarAlert.SetAlert({
            alert: {
              alertType: 'danger',
              messageHeader: 'Opps!',
              message: `Something went wrong. Please contact your account admin or try again later!`,
            },
          })
        );
      });
  };
}
