import { Component, OnDestroy, OnInit } from '@angular/core';
import { Dictionary } from '@ngrx/entity';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppState } from '../../../../+state/app.state';
import { SetSubMenuSmallPopout } from '../../../../+state/layout/actions/layout.actions';
import { SubMenuSmallPopoutEnum } from '../../../../+state/layout/reducers/layout.reducers';
import {
  SetSelectedPage,
  SetSelectedTooltip,
  SetSelectedTooltipSection,
} from '../../../../+state/tooltip/tooltip.actions';
import {
  selectAllTooltipEntities,
  selectAllTooltipSectionEntities,
  selectSelectedTooltip,
  selectSelectedTooltipSectionId,
  selectTooltipsInSelectedSection,
  selectSelectedPage,
  selectSelectedTooltipSection,
} from '../../../../+state/tooltip/tooltip.selector';
import { faBackSVG, faCloseSVG, faNextSVG } from '../../../../../icons';
import { Tooltip, TooltipPage, TooltipSection } from '../../../models';

@Component({
  selector: 'app-tooltip-menu',
  templateUrl: './tooltip-menu.component.html',
  styleUrls: ['./tooltip-menu.component.scss'],
})
export class TooltipMenuComponent implements OnInit, OnDestroy {
  destroyed$: Subject<boolean> = new Subject<boolean>();

  primaryMenu$: Observable<Dictionary<TooltipSection>> = this.store.select(
    selectAllTooltipSectionEntities
  );
  tooltips$: Observable<Tooltip[]> = this.store.select(
    selectTooltipsInSelectedSection
  );
  entities$: Observable<Dictionary<Tooltip>> = this.store.select(
    selectAllTooltipEntities
  );

  selectedTooltipSection$ = this.store.select(selectSelectedTooltipSection);
  selectedTooltip$ = this.store.select(selectSelectedTooltip);
  selectedTooltipPage$ = this.store.select(selectSelectedPage);

  menuItems: TooltipSection[] = [];
  isTooltipsShowing: boolean = false;
  validSectionids: string[] = [];
  tooltips: Tooltip[] = [];
  pages: TooltipPage[] = [];
  currentSectionTitle?: string;
  currentTooltip?: string;
  currentPage?: string;

  next = faNextSVG;
  back = faBackSVG;
  close = faCloseSVG;

  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    this.primaryMenu$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((sectionEntities) => {
        var order = [
          'General',
          'Profile',
          'Connections',
          'Connected Profiles',
          'Team',
          'Growth',
        ];

        order.map((name, index) => {
          for (const [id, entity] of Object.entries(sectionEntities)) {
            if (entity?.longName == name) {
              this.menuItems.push(entity);
            }
          }
        });
      });

    this.tooltips$.pipe(takeUntil(this.destroyed$)).subscribe((tooltips) => {
      this.tooltips = tooltips;
    });

    this.selectedTooltipSection$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((section) => {
        if (section) {
          this.isTooltipsShowing = true;
          this.currentSectionTitle = section.shortName;
        } else {
          this.isTooltipsShowing = false;
          this.currentSectionTitle = undefined;
        }
      });

    this.selectedTooltip$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((tooltip) => {
        if (tooltip) {
          this.currentTooltip = tooltip.description;
          this.pages = tooltip.pages;
        } else {
          this.currentTooltip = undefined;
          this.pages = [];
        }
      });

    this.selectedTooltipPage$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((page) => {
        if (page != undefined) {
          this.currentPage = page.title;
        } else {
          this.currentPage = undefined;
        }
      });
  }

  closeTooltips() {
    this.store.dispatch(
      SetSubMenuSmallPopout({ subMenuSmallPopout: SubMenuSmallPopoutEnum.None })
    );
    this.goToMenu();
  }

  setTooltipSection(sectionId: number, sectionTitle: string) {
    this.store.dispatch(SetSelectedTooltipSection({ sectionId }));
  }

  setTooltipId(tooltipId: number) {
    this.store.dispatch(SetSelectedTooltip({ tooltipId }));
    this.store.dispatch(SetSelectedPage({ pageId: 0 }));
  }

  goToMenu() {
    this.store.dispatch(SetSelectedTooltipSection({ sectionId: undefined }));
    this.goToSection();
  }

  goToSection() {
    this.store.dispatch(SetSelectedTooltip({ tooltipId: undefined }));
    this.store.dispatch(SetSelectedPage({ pageId: undefined }));
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.unsubscribe();
  }
}
