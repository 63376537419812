import { MindflickAccountType } from './enums/mindflick-account-type.enum';

export interface MindflickOrganisation {
  id: number;
  name: string;
  isActive: boolean;
  upcomingAccountRenewalDate?: Date;
  totalSeats: number;
  adminUserAk?: number;
  dataRetentionDuration: number;
  sessionDuration: number;
  mfaRequired: boolean;
  ssoRequired: boolean;
  adminName: string;
  organisationLeadAk: number;
  organisationLeadName: string;
  mindflickAccounts: MindflickAccount[];
}

export interface MindflickAccount {
  id: number;
  name: string;
  seats: number;
  creditPotSeats: number;
  usedSeats: number;
  creditPotUsedSeats: number;
  status: number;
  createdDate: Date;
  renewalDate: Date;
  creditPots: CreditPot[];
  championCanViewAnalyticsStatus: boolean;
  isDemo: boolean;
  isMindflickLed: boolean;
  accountLeadAK?: number;
  maximumChurnPercent: number;
  mindflickOrganisationId: number;
  mindflickOrganisationName: string;
  type: MindflickAccountType
}

export interface ChangeMindflickOrg{
  mindflickOrganisationId : number;
  mindflickAccountId : number;
  mindflickOrganisationName : string;
}

export interface CreditPot {
  id: number;
  ownerAk: number;
  ownerName: string;
  seats: number;
  usedSeats: number;
  mindflickAccountId: number;
  createdDate: Date;
  renewalDate: Date;
}

export interface SaveCreditPotModel {
  id?: number;
  ownerAk: number;
  mindflickAccountId: number;
  seats: number;
  renewalDate: Date;
}

// This interface is used by the super admin - connection wheels view
// it contains a list of all users and their primary teams as a hierarchy
export interface MindflickAccountConnections {
  // A primary team is the first team a user has joined
  // only primary teams are included in the data
  // if a user is not in a team then a fake primary team of ID=0 'No team' will be included
  groups: AccountConnectionNode[];
  connections: AccountMemberConnection[];
  mindflickAccountId: number;
}

export interface AccountConnectionNode {
  // Depending on how the members are being grouped the ID will have a different meaning here
  id: number;
  color?: string;
  name: string;
  children: AccountConnectionNode[];

  //These properties are used when determining the layout of the wheel
  level: number;
  angleWidth?: number;
  centerAngle?: number;
  endAngle?: number;
  startAngle?: number;
  padAngle?: number;
  radius?: number; //This isn't the radius drawn but is the radius used when bundling the lines inside the circle
}

// The account connection is populated from the IndividualPermission table but only if it has been approved
export interface AccountMemberConnection {
  requestorId: number;
  individualId: number;
  requestorGroupId?: number;
  individualGroupId?: number;
}

export enum ConnectionWheelGroupByOptionsEnum {
  Team,
  FLEX,
  NoGrouping,
}

export interface MindflickAccountBasicDetails {
  accountId: number;
  name: string;
}

export interface AccountSeatsDTO {
  mindflickAccountId: number;
  deltaSeats: number;
  comment: string;
}


export interface AddOrgDTO extends Pick<MindflickOrganisation,
  "name" | "adminUserAk" | "dataRetentionDuration" | "sessionDuration" | "mfaRequired" | "ssoRequired" | "organisationLeadAk">
{}
