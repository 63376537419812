import { Component } from '@angular/core';

@Component({
  selector: 'app-champion-charter-content',
  templateUrl: './champion-charter-content.component.html',
  styleUrls: ['./champion-charter-content.component.scss']
})
export class ChampionCharterContentComponent {

}
