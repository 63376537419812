export interface DashboardNudgeDTO {
  nudgeId: number;
  nudgeHistoryId: number;
  workOnId: number;
  title: string;
  paragraph1: string;
  paragraph2: string;
  seen: Date;
  imageUrl: string;
  homeImageUrl: string;
  squareImageUrl:string;
  organisationalImageUrl: string;
  primaryReflection: ReflectEnum;
  genuineImpact: boolean;
  highlyRelevant: boolean;
  easyToDo: boolean;
  sendMeAgain: boolean;
  notRelevant: boolean;
  difficultToApply: boolean;
  poorlyTimed: boolean;
  forgotToDoIt: boolean;
  didntHaveTime: boolean;
  dayOfWeek: number;
  firstNudgeEver: boolean;
  disableReflections: boolean;
  isOrganisationalNudge: boolean;
  nudgeReviewPrompt: NudgeReviewPromptEnum;
}


export enum ReflectEnum {
  Unknown,
  Useful,
  NotUseful,
  DidntDoIt
}

export enum NudgeReviewPromptEnum {
  Unopened,
  Skipped,
  Reviewed
}

export enum AdditionalReflectionOptions {
  Unknown,
  GenuineImpact,
  HighlyRelevant,
  EasyToDo,
  SendMeAgain,
  NotRelevant,
  DifficultToApply,
  PoorlyTimed,
  ForgotToDoIt,
  DidntHaveTime
}

export enum UsefulExtra {
  GenuineImpact,
  HighlyRelevant,
  EasyToDo
}

export enum NotUsefulExtra {
  NotRelevant,
  DifficultToApply,
  PoorlyTimed
}

export enum DidntExtra {
  NotRelevant,
  ForgotToDoIt,
  DidntHaveTime
}


