import { Component, OnInit } from '@angular/core';
import {
  FormControl,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { filter, map, take, takeUntil } from 'rxjs/operators';
import {
  ClearSaveMindflickOutcomes,
  ClearSelectedAccount,
  UpdateMindflickAccount,
} from 'src/app/+state/account/account.actions';
import {
  selectMindflickAccountIsSaving,
  selectMindflickAccountSaveError,
  selectMindflickAccountSaveSuccessful,
  selectSelectedAccount,
} from 'src/app/+state/account/account.selector';
import { AppState } from 'src/app/+state/app.state';
import { MindflickAccount, SelectValue } from 'src/app/shared/models';
import { faCloseSVG } from 'src/icons';
import { selectMindflickPractitioners } from '../../+state/super-admin/super-admin.selector';
import { SuperAdminService } from '../../shared/services/super-admin.service';
import { MindflickAccountType } from '../../shared/models/enums/mindflick-account-type.enum';

@Component({
  selector: 'app-edit-mindflick-account-modal',
  templateUrl: './edit-mindflick-account-modal.component.html',
  styleUrls: ['./edit-mindflick-account-modal.component.scss'],
})
export class EditMindflickAccountModalComponent implements OnInit {
  destroyed$ = new Subject<boolean>();
  selectedAccount$ = this.store.select(selectSelectedAccount);
  accountSaving$ = this.store.select(selectMindflickAccountIsSaving);
  accountSaveSuccess$ = this.store.select(selectMindflickAccountSaveSuccessful);
  accountSaveError$ = this.store.select(selectMindflickAccountSaveError);
  mindflickPractitioners$ = this.store.select(selectMindflickPractitioners);

  accountLeadOptions$ = this.mindflickPractitioners$.pipe(
    map((practs) =>
      practs.map((p) => {
        return {
          value: p.ak,
          description: p.name,
        } as SelectValue;
      })
    )
  );

  editAccountForm = new UntypedFormGroup({
    name: new UntypedFormControl('', Validators.required),
    renewalDate: new UntypedFormControl(),
    status: new UntypedFormControl(),
    type: new UntypedFormControl(null, Validators.required),
    maximumChurnPercentage: new FormControl<number | null>(null, Validators.required)
  });

  closeIcon = faCloseSVG;

  accountStatusOptions: SelectValue[] = [
    { description: 'Active', value: 0 },
    { description: 'On Hold', value: 1 },
    { description: 'Closed', value: 2 },
  ];

  types: SelectValue[] = [
    { value: MindflickAccountType.Paid, description: 'Paid' },
    { value: MindflickAccountType.Unpaid, description: 'Unpaid' },
    { value: MindflickAccountType.Demo, description: 'Demo' }
  ];


  coaches: SelectValue[] = [];

  selectedAccountId?: number;

  constructor(private store: Store<AppState>, private modalRef: BsModalRef, private superAdminService: SuperAdminService) {}

  ngOnInit(): void {
    this.superAdminService.getAccountMindflickCoaches().pipe(take(1), map((practs) =>
      this.coaches = practs.map((p) => {
        return {
          value: p.ak,
          description: p.firstName + ' ' + p.lastName,
        } as SelectValue;
      })
    ))
      .subscribe(_ => _);


    this.selectedAccount$
      .pipe(
        takeUntil(this.destroyed$),
        filter((x) => !!x)
      )
      .subscribe((account) => {
        if (account) {
          this.selectedAccountId = account?.id;
          this.editAccountForm.patchValue({
            name: account?.name,
            seats: account?.seats,
            renewalDate: account?.renewalDate,
            status: account?.status,
            maximumChurnPercentage: account?.maximumChurnPercent,
            type: account?.type
          });
          this.editAccountForm.updateValueAndValidity();
        }
      });
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.unsubscribe();
  }

  save() {
    if (this.editAccountForm.invalid) {
      this.editAccountForm.markAllAsTouched();
      return;
    }

    const updatedAccount: MindflickAccount = {
      ...this.editAccountForm.value,
      id: this.selectedAccountId,
    };

    

    this.store.dispatch(
      UpdateMindflickAccount.Request({ account: updatedAccount })
    );
  }

  close() {
    this.store.dispatch(ClearSaveMindflickOutcomes());
    this.store.dispatch(ClearSelectedAccount());
    this.modalRef.hide();
  }
}
