import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/+state/app.state';
import { NavigationItem } from '../../../models';
import * as StatusActions from '../../../../+state/status/status.actions';

@Component({
  selector: 'app-navbar-item',
  templateUrl: './navbar-item.component.html',
  styleUrls: ['./navbar-item.component.css'],
})
export class NavbarItemComponent implements OnInit {
  @Input()
  item: NavigationItem | undefined;

  isOpen = false;

  constructor(private router: Router, private store: Store<AppState>) {}

  ngOnInit(): void {}

  toggleOpen(item: NavigationItem) {
    if (item?.path && this.router.url != item?.path) {
      this.router.navigate([item?.path]);
      this.store.dispatch(
        StatusActions.SetCurrentPageTitle({ title: item?.title })
      );
      this.store.dispatch(
        StatusActions.SetPageSelected({ isPageSelected: true })
      );
    }

    this.isOpen = !this.isOpen;
  }
}
