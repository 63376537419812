import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  ResetSendPlatformEmailsState,
  SendPlatformEmails,
} from 'src/app/+state/admin/admin.actions';
import {
  selectEmailTemplates,
  selectSendingEmails,
  selectSendingEmailsError,
  selectSendingEmailsSuccess,
} from 'src/app/+state/admin/admin.selector';
import { AppState } from 'src/app/+state/app.state';
import { SelectValue } from 'src/app/shared/models';
import { faCloseSVG } from 'src/icons';

@Component({
  selector: 'app-send-emails-modal',
  templateUrl: './send-emails-modal.component.html',
  styleUrls: ['./send-emails-modal.component.scss'],
})
export class SendEmailsModalComponent implements OnInit {
  destroyed$ = new Subject<boolean>();
  emailTemplates$ = this.store.select(selectEmailTemplates);

  sendingEmails$ = this.store.select(selectSendingEmails);
  sendingEmailsSuccess$ = this.store.select(selectSendingEmailsSuccess);
  sendingEmailsError$ = this.store.select(selectSendingEmailsError);

  fileToUpload?: File | null;

  emailTemplates: SelectValue[] = [];

  selectedEmail?: number;

  closeIcon = faCloseSVG;

  constructor(
    private modalRef: BsModalRef,
    private store: Store<AppState>,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.emailTemplates$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((templates) => {
        if (templates) {
          this.emailTemplates = templates;
        }
      });
  }

  ngOnDestroy() {
    this.store.dispatch(ResetSendPlatformEmailsState());

    this.destroyed$.next(true);
    this.destroyed$.unsubscribe();
  }

  sendEmails() {
    if (!this.fileToUpload || this.selectedEmail == undefined) return;

    this.store.dispatch(
      SendPlatformEmails.Request({
        emailCsv: this.fileToUpload,
        emailTemplate: this.selectedEmail,
      })
    );
  }

  handleFileUpload(target: EventTarget | null) {
    this.fileToUpload = (target as HTMLInputElement)?.files?.item(0);
  }

  close() {
    this.modalRef.hide();
  }
}
