import { createReducer, on, props } from '@ngrx/store';
import { CreateTeamModel, StandardResponse, Team } from 'src/app/shared/models';
import * as TeamActions from './team.actions';
import * as UserActions from '../user/user.actions';
import { DeactivateUser } from '../account/account.actions';
import { Handle } from '../signalr/signalr.actions';
import { NotificationType } from 'src/app/shared/models/enums/notification-types.enum';
import { UntypedFormGroup } from '@angular/forms';
import { CommonActions } from '../common-actions';

export interface TeamState {
  allTeams: Team[];
  currentTeamFullDetails?: Team;
  myTeamsLoading: boolean;
  selectedTeamId?: number;
  teamMapIsFlex: boolean;
  lastCreatedTeamId: number;
  isSavingTeamDetails: boolean;
  isSavingTeamDetailsResult?: StandardResponse<Team>;
  generatingTeamSpotlights: number;
  isSavingLeaveTeam: boolean;
  isSavingLeaveTeamResult?: StandardResponse<Team>;
  isDeletingTeam: boolean;
  isDeletingTeamResult?: StandardResponse<Team>;
  connectionDensity: number;
  myTeamRequestsLoading: boolean;
  selectedTeamMapUserAK?: number;
  publicAccountTeamsLoading: boolean;
  allTeamsForAdmin?: Team[];
  allTeamsLoading?: boolean;
  allTeamsForAdminLoading: boolean;
  inviteTeamMembersSuccess: boolean;
  memberIdBeingRemoved: number;
  areTeamMembersUpdating: boolean;
  teamMembersUpdateResult?: StandardResponse<Team>;
  saveTeamSettingsSuccess: boolean;
  saveTeamMechanicsSuccess: boolean;
  showUploadTeamPhotoComponent: boolean;
  newTeamForm?: CreateTeamModel;
  currentTeamFullDetailsLoading?: boolean;
}

export const initialTeamState: TeamState = {
  lastCreatedTeamId: 0,
  myTeamsLoading: false,
  teamMapIsFlex: false,
  isSavingTeamDetails: false,
  generatingTeamSpotlights: -1,
  isSavingLeaveTeam: false,
  isDeletingTeam: false,
  connectionDensity: 0,
  myTeamRequestsLoading: false,
  publicAccountTeamsLoading: false,
  allTeamsForAdminLoading: false,
  inviteTeamMembersSuccess: false,
  memberIdBeingRemoved: 0,
  areTeamMembersUpdating: false,
  saveTeamSettingsSuccess: false,
  saveTeamMechanicsSuccess: false,
  allTeams: [],
  showUploadTeamPhotoComponent: false,
};

export const teamReducer = createReducer(
  initialTeamState,

  on(CommonActions.ClearState, (state, props) => ({
    ...initialTeamState,
  })),

  on(TeamActions.SetSelectedTeam, (state, props) => ({
    ...state,
    selectedTeamId: props.teamId,
  })),

  on(TeamActions.ClearSelectedTeam, (state) => ({
    ...state,
    selectedTeamId: undefined,
  })),

  on(TeamActions.ToggleTeamMapType, (state) => ({
    ...state,
    teamMapIsFlex: !state.teamMapIsFlex,
  })),

  on(TeamActions.SetTeamMapFlex, (state) => ({
    ...state,
    teamMapIsFlex: true,
  })),

  on(TeamActions.SetTeamMapCope, (state) => ({
    ...state,
    teamMapIsFlex: false,
  })),

  // When switching user, always flip back to the COPE view
  on(UserActions.SetSelectedUser, (state) => ({
    ...state,
    teamMapIsFlex: false,
  })),

  on(TeamActions.SaveTeamDetails.Request, (state, props) => ({
    ...state,
    isSavingTeamDetails: true,
    isSavingTeamDetailsResult: undefined,
  })),

  on(TeamActions.SaveTeamDetails.Success, (state, props) => ({
    ...state,
    isSavingTeamDetails: false,
    isSavingTeamDetailsResult: props.response,
    saveTeamSettingsSuccess: true,
    allTeams: state.allTeams.map((team) => {
      if (props.response.result && team.id === props.response.result.id)
        return {
          ...team,
          name: props.response.result.name,
          isPrivate: props.response.result.isPrivate,
        };

      return {
        ...team,
      };
    }),
  })),

  on(TeamActions.SaveTeamDetails.Fail, (state, props) => ({
    ...state,
    isSavingTeamDetails: false,
    isSavingTeamDetailsResult: {
      message: props.error,
      isError: true,
    },
  })),

  on(TeamActions.ResetInviteTeamMembersSuccess, (state) => ({
    ...state,
    inviteTeamMembersSuccess: false,
    teamMembersUpdateResult: { isError: false, result: undefined, message: '' },
  })),

  on(TeamActions.UpdateTeamMembers.Request, (state, props) => ({
    ...state,
    areTeamMembersUpdating: true,
    teamMembersUpdateResult: undefined,
  })),

  on(TeamActions.UpdateTeamMembers.Success, (state, props) => ({
    ...state,
    teamMembersUpdateResult: { isError: false, result: props.team, message: '' },
    allTeams: state.allTeams.map((team) => {
      if (team.id === props.team.id)
        return props.team;

      return {
        ...team,
      };
    }),
    currentTeamFullDetails: { ...props.team },
    areTeamMembersUpdating: false,
  })),

  on(TeamActions.UpdateTeamMembers.Fail, (state, props) => ({
    ...state,
    areTeamMembersUpdating: false,
    teamMembersUpdateResult: { isError: true, result: undefined, message: props.error },
  })),

  on(TeamActions.ClearSaveTeamMessage, (state) => ({
    ...state,
    isSavingTeamDetailsResult: undefined,
  })),

  on(TeamActions.GenerateMyTeamSpotlights.Request, (state, props) => ({
    ...state,
    generatingTeamSpotlights: props.teamId,
  })),

  on(TeamActions.GenerateMyTeamSpotlights.Success, (state, props) => ({
    ...state,
    generatingTeamSpotlights: -1,
    allTeams: state.allTeams.map((x) => {
      if (x.id != props.teamId) return x;

      return {
        ...x,
        teamSpotlights: props.resp,
      };
    }),
  })),

  on(TeamActions.GenerateMyTeamSpotlights.Fail, (state, props) => ({
    ...state,
    generatingTeamSpotlights: -1,
  })),

  on(TeamActions.CreateNewTeam.Request, (state, props) => ({
    ...state,
    isSavingTeamDetails: true,
    isSavingTeamDetailsResult: undefined,
  })),

  on(TeamActions.CreateNewTeam.Success, (state, props) => ({
    ...state,
    isSavingTeamDetails: false,
    lastCreatedTeamId: props.response.id,
    allTeams: [...state.allTeams, props.response],
    currentTeamFullDetails: props.response,
    isSavingTeamDetailsResult: {
      message: '',
      isError: false,
    },
  })),

  on(TeamActions.CreateNewTeam.Fail, (state, props) => ({
    ...state,
    isSavingTeamDetails: false,
    isSavingTeamDetailsResult: {
      message: props.error,
      isError: true,
    },
  })),

  on(TeamActions.AddExerciseToTeam.Success, (state, props) => ({
    ...state,
    allTeams: state.allTeams.map((x) => {
      if (x.id == props.response.teamId)
        return {
          ...x,
          exercises: [...x.exercises, props.response],
        };

      return x;
    }),
  })),

  on(TeamActions.RemoveExerciseFromTeam.Success, (state, props) => ({
    ...state,
    allTeams: state.allTeams.map((x) => {
      if (x.id === props.payload.teamId)
        return {
          ...x,
          exercises: x.exercises.filter(
            (x) => x.exerciseId !== props.payload.exerciseId,
          ),
        };

      return x;
    }),
  })),

  on(DeactivateUser.Success, (state, props) => ({
    ...state,
    allTeams: state.allTeams.map((x) => ({
      ...x,
      memberIds: x.memberIds.filter((m) => props.userAK != m),
    })),
  })),

  on(TeamActions.UpdateTeamDetails, (state, props) => ({
    ...state,
    allTeams: state.allTeams.map((x) => {
      if (x.id === props.team.id)
        return {
          ...props.team,
        };
      return x;
    }),
    currentTeamFullDetails: { ...props.team }
  })),

  on(TeamActions.RemoveTeam, (state, props) => ({
    ...state,
    allTeams: state.allTeams.filter((x) => x.id !== props.teamId),
  })),
  on(TeamActions.LeaveTeam.Request, (state, props) => ({
    ...state,
    isSavingLeaveTeam: true,
  })),
  on(TeamActions.LeaveTeam.Success, (state, props) => ({
    ...state,
    allTeams: [
      ...state.allTeams.filter((team) => team.id !== props.team.id),
      {
        ...props.team,
        userHasSentTeamRequest: false,
        userIsMember: false,
        teamHasInvitedUser: false,
      },
    ],
  })),
  on(TeamActions.LeaveTeam.Fail, (state, props) => ({
    ...state,
    isSavingLeaveTeam: false,
    isSavingLeaveTeamResult: {
      message: props.error,
      isError: true,
    },
  })),

  on(TeamActions.DeleteTeam.Request, (state, props) => ({
    ...state,
    isDeletingTeam: true,
  })),
  on(TeamActions.DeleteTeam.Success, (state, props) => ({
    ...state,
    isDeletingTeam: false,
    allTeams: [...state.allTeams.filter((team) => team.id != props.teamId)],
  })),
  on(TeamActions.DeleteTeam.Fail, (state, props) => ({
    ...state,
    isDeletingTeam: false,
    isDeletingTeamResult: {
      message: props.error,
      isError: true,
    },
  })),
  on(TeamActions.PutAcceptedDate.Success, (state, props) => ({
    ...state,
    allTeams: state.allTeams.map((team) =>
      team.id === props.team.id
        ? {
          ...props.team,
          userIsMember: true,
          teamHasInvitedUser: false,
          userHasSentTeamRequest: false,
        }
        : team,
    ),
  })),

  on(TeamActions.DeleteMember.Success, (state, props) => ({
    ...state,
    allTeams: state.allTeams.map((team) =>
      team.id === props.teamId ? { ...team, teamHasInvitedUser: false } : team,
    ),
  })),
  on(TeamActions.SetSelectedTeamMapUser, (state, props) => ({
    ...state,
    selectedTeamMapUserAK: props.userAK,
  })),
  on(Handle, (state, props) => {
    //INCOMING INVITE FROM A TEAM
    if (props.notification.type !== NotificationType.TeamRequest) return state;

    let payloadJson = JSON.parse(props.notification.payloadJson);

    if (state.allTeams.filter((x) => x.id === +payloadJson.teamId) != null) {
      return {
        ...state,
        allTeams: state.allTeams.map((team) =>
          team.id === +payloadJson.teamId
            ? {
              ...team,
              userHasSentTeamRequest: true,
              userIsMember: false,
              teamHasInvitedUser: false,
            }
            : team,
        ),
      };
    } else {
      return {
        ...state,
        allTeams: [
          ...state.allTeams,
          {
            id: +payloadJson.TeamId,
            name: +payloadJson.TeamName,
            ownerId: +payloadJson.ownerId,
          } as any as Team,
        ],
      };
    }
  }),
  on(TeamActions.ResetIsErrorMessageToFalse, (state, props) => ({
    ...state,
    isSavingTeamDetailsResult: {
      message: '',
      isError: false,
    },
  })),
  on(TeamActions.RequestToJoinTeam.Success, (state, props) => ({
    ...state,
    allTeams: [
      ...state.allTeams.filter((team) => team.id !== props.team.id),
      {
        ...props.team,
        userHasSentTeamRequest: true,
      },
    ],
  })),

  on(TeamActions.CancelRequestToJoinTeam.Success, (state, props) => ({
    ...state,
    allTeams: [
      ...state.allTeams.filter((team) => team.id !== props.team.id),
      {
        ...props.team,
        userHasSentTeamRequest: false,
        teamHasInvitedUser: false,
        userIsMember: false,
      },
    ],
  })),

  on(TeamActions.DeclineRequestToJoinTeam.Success, (state, props) => ({
    ...state,
    allTeams: state.allTeams.map((team) => {
      if (team.id != props.teamId)
        return {
          ...team,
        };

      return {
        ...team,
        incomingRequestMemberIds: [
          ...team.incomingRequestMemberIds.filter((x) => x != props.userAk),
        ],
        userHasSentTeamRequest: false,
        teamHasInvitedUser: false,
        userIsMember: false,
      };
    }),
  })),
  on(TeamActions.AcceptRequestToJoinTeam.Success, (state, props) => ({
    ...state,
    allTeams: state.allTeams.map((team) =>
      team.id === props.team.id
        ? {
          ...team,
          teamHasInvitedUser: false,
          userHasSentTeamRequest: false,
          userIsMember: true,
        }
        : team,
    ),
  })),
  on(TeamActions.UpdateTeamWithJoinRequest, (state, props) => ({
    ...state,
    allTeams: state.allTeams.map((team) =>
      team.id === props.teamId
        ? {
          ...team,
          teamHasInvitedUser: false,
          userHasSentTeamRequest: true,
          userIsMember: false,
          incomingRequestMemberIds: [
            ...team.incomingRequestMemberIds,
            props.userAk,
          ],
        }
        : team,
    ),
  })),
  on(TeamActions.UpdateTeamWithJoinRequestRevoked, (state, props) => ({
    ...state,
    allTeams: state.allTeams.map((team) => {
      if (team.id === props.teamId)
        return {
          ...team,
          teamHasInvitedUser: false,
          userHasSentTeamRequest: false,
          userIsMember: false,
          incomingRequestMemberIds: [
            ...team.incomingRequestMemberIds.filter((x) => x !== props.userAk),
          ],
        };

      return {
        ...team,
      };
    }),
  })),
  on(TeamActions.UpdateTeamWithJoinRequestAccepted, (state, props) => {
    return {
      ...state,
      allTeams: state.allTeams.map((team) => {
        if (team.id === props.team.id)
          return {
            ...props.team,
            teamHasInvitedUser: false,
            userHasSentTeamRequest: false,
            userIsMember: true,
          };

        return {
          ...team,
        };
      }),
    };
  }),
  on(TeamActions.UpdateTeamWithJoinRequestDeclined, (state, props) => {
    return {
      ...state,
      allTeams: state.allTeams.map((team) => {
        if (team.id === props.teamId)
          return {
            ...team,
            teamHasInvitedUser: false,
            userHasSentTeamRequest: false,
            userIsMember: false,
          };

        return {
          ...team,
        };
      }),
    };
  }),
  on(TeamActions.GetAllTeamsForAdmin.Request, (state) => ({
    ...state,
    allTeamsForAdminLoading: true,
  })),

  on(TeamActions.GetAllTeamsForAdmin.Success, (state, props) => ({
    ...state,
    allTeamsForAdmin: props.teams,
    allTeamsForAdminLoading: false,
  })),

  on(TeamActions.GetAllTeamsForAdmin.Fail, (state) => ({
    ...state,
    allTeamsForAdminLoading: false,
  })),

  on(TeamActions.UpdateTeamMechanics.Success, (state, props) => ({
    ...state,
    currentTeamFullDetails: state.currentTeamFullDetails
      ? {
        ...state.currentTeamFullDetails,
        mechanics: props.purposes.concat(props.foci).concat(props.principles),
      }
      : undefined,
    saveTeamMechanicsSuccess: true,
  })),
  on(TeamActions.ResetSaveTeamMechanicsSuccess, (state) => ({
    ...state,
    saveTeamMechanicsSuccess: false,
  })),
  on(TeamActions.ResetSaveTeamSettingsSuccess, (state) => ({
    ...state,
    saveTeamSettingsSuccess: false,
  })),

  on(TeamActions.GetAllTeams.Request, (state) => ({
    ...state,
    allTeamsLoading: true,
  })),
  on(TeamActions.GetAllTeams.Success, (state, props) => ({
    ...state,
    allTeams: props.teams,
    allTeamsLoading: false,
    getAllTeamSuccess: true,
  })),
  on(TeamActions.GetAllTeams.Fail, (state) => ({
    ...state,
    allTeamsLoading: false,
    getAllTeamSuccess: false,
  })),
  on(TeamActions.GetTeam.Request, (state, props) => ({
    ...state,
    currentTeamFullDetailsLoading: true,
  })),
  on(TeamActions.GetTeam.Success, (state, props) => ({
    ...state,
    allTeams: state.allTeams.map((team) => {
      if (team.id === props.team.id) {
        return { ...props.team };
      }
      return team;
    }),
    currentTeamFullDetails: { ...props.team },
    currentTeamFullDetailsLoading: false,
  })),
  on(TeamActions.GetTeam.Fail, (state, props) => ({
    ...state,
    currentTeamFullDetailsLoading: false,
  })),
  on(TeamActions.ToggleShowUploadTeamPhotoComponent, (state, props) => ({
    ...state,
    showUploadTeamPhotoComponent: props.show,
  })),
  on(TeamActions.UpdateNewTeamForm, (state, props) => ({
    ...state,
    newTeamForm: props.newTeamForm,
  })),
  on(TeamActions.ChangeTeamPicture.Success, (state, props) => ({
    ...state,
    currentTeamFullDetails: {
      ...state.currentTeamFullDetails!,
      photoLastModified: props.photoLastModified,
      teamImageId: 0,
    },
    allTeams: state.allTeams.map((team) => {
      if (props.teamId === team.id) {
        return {
          ...team,
          photoLastModified: props.photoLastModified,
        };
      } else {
        return team;
      }
    }),
    allTeamsForAdmin:
      state.allTeamsForAdmin == undefined || state.allTeamsForAdmin == null
        ? []
        : state.allTeamsForAdmin.map((team) => {
          if (props.teamId === team.id) {
            return {
              ...team,
              photoLastModified: props.photoLastModified,
            };
          } else {
            return team;
          }
        }),
  })),
  on(TeamActions.ChangeTeamPicture.Fail, (state, props) => ({
    ...state,
    teamImageError: props.error,
  })),
  on(TeamActions.UpdateTeamDefaultImageId.Success, (state, props) => ({
    ...state,
    currentTeamFullDetails: {
      ...state.currentTeamFullDetails!,
      photoLastModified: undefined,
      teamImageId: props.newImageId,
    },
  })),
);
