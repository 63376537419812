<div class="modal-header justify-content-end">
    <fa-icon
      class="cursor-pointer fa-lg"
      [icon]="close"
      (click)="hideModal()"
    ></fa-icon>
  </div>
  <div class="modal-body">
    <h2 class="mb-3">Why is my account locked?</h2>
    <p class="body-copy">Your account has been locked by the admin in your organisation. This could be because you have an upcoming session (either online or in-person) which is designed to set you up for using Mindflick. 
    <p class="body-copy">It’s important to attend this session prior to gaining access to Mindflick because it will help you understand your profile and the frameworks it’s built on. Having this understanding will help you make the most out of the features on Mindflick. </p>
    <p class="body-copy">The countdown on your screen indicates when account will go live. </p>
    <p class="body-copy">If you have already attended the session or believe your account should be unlocked, get in touch with the admin in your organisation.</p>
  </div>
  <div class="modal-bottom-border mt-3"></div>
