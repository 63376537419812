import { Component } from '@angular/core';
import { AppState } from '../../../+state/app.state';
import { Store } from '@ngrx/store';
import { OidcSecurityService } from 'angular-auth-oidc-client';

@Component({
  selector: 'app-locked-requires-external-auth',
  templateUrl: './locked-requires-external-auth.component.html',
  styleUrls: ['./locked-requires-external-auth.component.scss']
})
export class LockedRequiresExternalAuthComponent {
  constructor(
    private store: Store<AppState>,
    private oidcSecurityService: OidcSecurityService,
  ) { }

  logout() {
    localStorage.clear();
    this.oidcSecurityService.logoff().subscribe(() => { });
  }
}
