export enum WorkOnType {
  Learn,
  MicroAction,
  InitialWorkOn,
  None,
}

export enum ContentType {
  Text,
  Image,
  Video,
}
