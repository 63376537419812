<div class="page-container mb-5">
  <div class="d-flex justify-content-between">
    <h2 class="">Account settings</h2>
    <ng-container *ngIf="accountSettingsAccounts$ | async as accounts">
      <div class="btn-group" dropdown placement="bottom right">
        <h2
          id="button-credit-pots"
          class="d-flex"
          dropdownToggle
          type="button"
          aria-controls="dropdown-credit-pots"
        >
          <ng-container *ngIf="selectedAccount$ | async as selectedAccount">
            {{ selectedAccount.name }}
            <fa-icon class="fs-4 pot-dropdown d-flex align-items-center" [icon]="dropdownIcon" />
          </ng-container>
        </h2>
        <ul
          id="dropdown-credit-pots"
          *dropdownMenu
          class="dropdown-menu dropdown-menu-right"
          role="menu"
          aria-labelledby="button-credit-pots"
        >
          <li
            *ngFor="let account of accounts"
            class="cursor-pointer"
            role="menuitem"
            (click)="onAccountChange(account.accountId)"
          >
            <div class="dropdown-item" href="#">
              {{ account.name }}
            </div>
          </li>
        </ul>
      </div>
    </ng-container>
  </div>
  <div *ngIf="selectedAccount$ | async as selectedAccount" class="row gx-5 mt-4">
    <div class="col-4">
      <h2>Permissions</h2>
      <div class="toggle-container p-3 mt-3">
        <app-toggle-box
          [description]="'Champions access to analytics'"
          [isOn]="settings?.canChampionsViewAnalytics ?? false"
          [disabled]="!canEditAccountSettings"
          (toggleChanged)="championAnalyticsChanged($event)"
        ></app-toggle-box>
        <div class="body-copy w-75 pt-1">
          Allow champions to view analytics
        </div>
      </div>
  
      <div class="toggle-container mt-3 p-3">
        <app-toggle-box
          [description]="'Champions seat allocation'"
          [isOn]="settings?.championCanAllocateSeats ?? false"
          [disabled]="!canEditAccountSettings"
          (toggleChanged)="championAllocateSeatsChanged($event)"
        ></app-toggle-box>
        <div class="body-copy w-75 pt-1">
          Allow champions to allocate seats
        </div>
      </div>
    </div>
    <div class="col-4">
      <h2>Account Champions</h2>
      <div *ngIf="accountChampions$ | async as champions">
        <div *ngFor="let champion of champions" class="d-flex justify-content-between align-items-center champ-coordinator-details py-3">
          <div>
            <h3>{{ champion.firstName }} {{ champion.lastName }}</h3>
            <div class="body-copy mt-1">{{ champion.email }}</div>
          </div>
          <app-btn-action *ngIf="canEditChampions" [secondary]="true" [icon]="closeIcon" [iconDimensions]="30" (actionClick)="onRemoveAccountChampionClick(champion)"></app-btn-action>
        </div>
      </div>
      <div class="mt-2">
        <app-btn-tertiary *ngIf="canEditChampions" [icon]="addIcon" (click)="openAddAccountChampionModal()">Add New Champion</app-btn-tertiary>
      </div>
      
    </div>
    <div class="col-4">
      <h2>Account Coordinators</h2>
      <div *ngIf="accountCoordinators$ | async as coordinators">
        <div *ngFor="let coordinator of coordinators" class="d-flex justify-content-between align-items-center champ-coordinator-details py-3">
          <div>
            <h3>{{ coordinator.firstName }} {{ coordinator.lastName }}</h3>
            <div class="body-copy mt-1">{{ coordinator.email }}</div>
          </div>
          <app-btn-action *ngIf="canEditCoordinators" [secondary]="true" [icon]="closeIcon" [iconDimensions]="30" (actionClick)="onRemoveAccountCoordinatorClick(coordinator)"></app-btn-action>
        </div>
      </div>
      <div class="mt-2">
        <app-btn-tertiary *ngIf="canEditCoordinators" [icon]="addIcon" (click)="openAddAccountCoordinatorModal()">Add New Coordinator</app-btn-tertiary>
      </div>
      
    </div>
  </div>
</div>
