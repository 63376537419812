<div class="modal-header justify-content-end">
  <fa-icon
    class="cursor-pointer fa-lg"
    [icon]="closeIcon"
    (click)="deny()"
  ></fa-icon>
</div>
<div class="modal-body">
  <h2 class="mb-4">{{ title }}</h2>
  <h3 class="mb-4">{{ message }}</h3>
  <div class="d-flex justify-content-between">
    <button
      *ngIf="showDenyButton"
      class="btn btn-secondary w-100 me-2"
      (click)="deny()"
    >
      {{ negatory }}
    </button>
    <button class="btn btn-purple w-100 ms-2" (click)="confirm()">
      {{ affirmative }}
    </button>
  </div>
</div>
<div class="modal-bottom-border"></div>
