import { formatDate } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { combineLatest, Subject } from 'rxjs';
import { debounceTime, filter, take, takeUntil } from 'rxjs/operators';
import {
  selectCurrentUser,
  selectHasMindflickEmail,
} from 'src/app/+state/user/user.selector';
import {
  AccountAdminResendSpotlightInvites,
  DownloadAdminTeamMap,
  GetManagedUsersForAccountAdmin,
  SetUserManagementSelectedAccountId,
  UnlockUsers,
  UpdateUserManagementOrderBy,
} from '../../../+state/account-admin/account-admin.actions';
import {
  selectAccountAdminManagedUsers,
  selectAccountAdminManagedUsersLoading,
  selectAccountManagedUsersState,
  selectAccountSettings,
  selectHasManagedUsersRequestFailed,
  selectManagedUserFilter,
  selectMindflickOrganisationSettings,
  selectSelectedUserManagementAccount,
  selectUserManagementMindflickAccounts,
  selectUserManagementOrderBy,
} from '../../../+state/account-admin/account-admin.selector';
import { AppState } from '../../../+state/app.state';
import { selectUploadStatus } from '../../../+state/layout/selectors/layout.selector';
import {
  faBackSVG,
  faDownCaretSVG,
  faDropdownSVG,
  faNextSVG,
  faUpCaretSVG,
} from '../../../../icons';
import { isNonNull } from '../../../shared/helpers/rxjs-type-guards';
import { MindflickAccountBasicDetails, SelectValue } from '../../../shared/models';
import {
  AccountAdminManagedUsersDTO,
  AccountAdminManagedUsersFilter,
} from '../../../shared/models/account-admin-managed-users-DTO';
import { PlatformInviteStatusEnum } from '../../../shared/models/enums/platform-invite-status.enum';
import { UploadStatus } from '../../../shared/models/enums/upload-status.enum';
import { DialogService } from '../../../shared/services/dialog.service';
import { DownloadService } from '../../../shared/services/download.service';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { smoothHeight } from 'src/animations';
import FileSaver from 'file-saver';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-user-management-table',
  templateUrl: './user-management-table.component.html',
  styleUrls: ['./user-management-table.component.scss'],
  animations: [
    trigger("selectedUsersToggle", [
      transition("void => *", [
        style({ height: 0, opacity: 0 }),
        animate("0.3s ease-in-out", style({ height: "72px", opacity: 1 })),
      ]),
      transition("* => void", [
        style({ height: "72px", opacity: 1 }),
        animate("0.3s ease-in-out", style({ height: 0, opacity: 0 })),
      ]),
    ])
  ],
})
export class UserManagementTableComponent implements OnInit, OnDestroy {
  destroyed$ = new Subject<boolean>();

  users$ = this.store.select(selectAccountAdminManagedUsers);
  usersWithoutFilter$ = this.store.select(selectAccountManagedUsersState);
  usersLoading$ = this.store.select(selectAccountAdminManagedUsersLoading);
  hasUserRequestFailed$ = this.store.select(selectHasManagedUsersRequestFailed);
  updateStatus$ = this.store.select(selectUploadStatus);
  managedUserFilter$ = this.store.select(selectManagedUserFilter);
  accountSettings$ = this.store.select(selectAccountSettings);
  orgSettings$ = this.store.select(selectMindflickOrganisationSettings);
  currentUser$ = this.store.select(selectCurrentUser);
  orderBy$ = this.store.select(selectUserManagementOrderBy);
  hasMindflickEmail$ = this.store.select(selectHasMindflickEmail);
  userManagementMindflickAccounts$ = this.store.select(selectUserManagementMindflickAccounts);
  selectedUserManagementAccount$ = this.store.select(selectSelectedUserManagementAccount);

  users: AccountAdminManagedUsersDTO[] = [];
  displayUsers: AccountAdminManagedUsersDTO[] = [];
  userRequestFailed: boolean = false;

  selectedAccount?: MindflickAccountBasicDetails;

  displayUsersForm = new UntypedFormGroup({
    numOfUsers: new UntypedFormControl(10),
  });

  orderBy:
    | 'name-asc'
    | 'name-desc'
    | 'date-asc'
    | 'date-desc'
    | 'dept-asc'
    | 'dept-desc'
    | 'web-status-asc'
    | 'web-status-desc'
    | 'email-desc'
    | 'email-asc'
    | 'mobile-status-asc'
    | 'mobile-status-desc'
    | 'allocated-seats-asc'
    | 'allocated-seats-desc' = 'name-desc';

  isAllSelectedChampions: boolean = false;
  isAllSelectedNotChampions: boolean = false;

  isSendPlatformInviteValid: boolean = false;
  isResendPlatformInviteValid: boolean = false;

  isAllSelectedNotInvitedToSpotlight: boolean = false;
  isAllSelectedInvitedToSpotlightOrInProgress: boolean = false;

  isAllSelectedDeactivated: boolean = false;
  isSomeSelectedDeactivated: boolean = false;

  isAllSelectedSameCanDownloadPDF = false;

  isAllSelectedLocked = false;

  selectedUsersCanDownloadFlag = false;

  showDownloadPDFs = false;

  upCaret = faUpCaretSVG;
  downCaret = faDownCaretSVG;
  questionIcon = faQuestionCircle;
  dropdownIcon = faDropdownSVG;
  currentOrdering = 'name';

  selectedUserIds: number[] = [];

  inProgress = UploadStatus.InProgress;
  updateFailed = UploadStatus.Failed;
  updateSuccess = UploadStatus.Completed;

  filter?: AccountAdminManagedUsersFilter;

  numOfPages = 1;
  userLimit = 10;
  currentPage = 1;
  numOfUsersOptions: SelectValue[] = [
    { value: 10, description: '10' },
    { value: 25, description: '25' },
    { value: 50, description: '50' },
    { value: 'All', description: 'All' },
  ];

  next = faNextSVG;
  back = faBackSVG;

  daysRetention = 90;
  downloadErrorMessage: string = '';

  constructor(
    private store: Store<AppState>,
    private downloadService: DownloadService,
    @Inject('BASE_URL') private baseUrl: string,
    private dialogService: DialogService
  ) {}

  ngOnInit(): void {
    this.orgSettings$
      .pipe(takeUntil(this.destroyed$), filter(isNonNull))
      .subscribe((x) => {
        this.daysRetention = x.dataRetentionDurationDays!;
      });

    this.users$.pipe(takeUntil(this.destroyed$)).subscribe((x) => {
      this.setDisplayedActionButtons();
    });

    this.usersWithoutFilter$.pipe(takeUntil(this.destroyed$)).subscribe(x => {
      this.selectedUserIds = [];
      this.setDisplayedActionButtons();
    });

    this.selectedUserManagementAccount$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(account => {
        if (account) {
          this.selectedAccount = account;
          this.store.dispatch(GetManagedUsersForAccountAdmin.Request({ accountId: account.accountId }));
        }
      });

    combineLatest([
      this.users$,
      this.hasUserRequestFailed$,
      this.managedUserFilter$,
    ])
      .pipe(takeUntil(this.destroyed$), debounceTime(300))
      .subscribe(([users, hasFailed, filter]) => {
        this.currentPage = 1;

        this.filter = filter;
        if (hasFailed) {
          this.userRequestFailed = true;
        } else {
          this.users = users;
          this.displayUsers = [
            ...this.users.slice(
              (this.currentPage - 1) * this.userLimit,
              this.userLimit * this.currentPage
            ),
          ];

          this.numOfPages = Math.ceil(this.users.length / this.userLimit);
        }
      });

    this.orderBy$.pipe(takeUntil(this.destroyed$)).subscribe((orderBy) => {
      this.orderBy = orderBy;
    });
  }

  resendSpotlightInvites() {
    if (this.selectedAccount)
      this.store.dispatch(
        AccountAdminResendSpotlightInvites.Request({
          userAks: this.selectedUserIds,
          accountId: this.selectedAccount.accountId
        })
      );
  }

  stringCompare(a: string, b: string) {
    if (a === '' || a === null) return 1;
    if (b === '' || b === null) return -1;
    if (a === b) return 0;
    return a.toLowerCase() < b.toLowerCase() ? -1 : 1;
  }

  orderUsersByName() {
    if (this.orderBy === 'name-desc') {
      this.store.dispatch(UpdateUserManagementOrderBy({ orderBy: 'name-asc' }));
    } else {
      this.store.dispatch(
        UpdateUserManagementOrderBy({ orderBy: 'name-desc' })
      );
    }

    this.currentPage = 1;
  }

  orderUsersByEmail() {
    if (this.orderBy === 'email-desc') {
      this.store.dispatch(
        UpdateUserManagementOrderBy({ orderBy: 'email-asc' })
      );
    } else {
      this.store.dispatch(
        UpdateUserManagementOrderBy({ orderBy: 'email-desc' })
      );
    }
  }

  orderUsersByDepartment() {
    if (this.orderBy === 'dept-desc') {
      this.store.dispatch(UpdateUserManagementOrderBy({ orderBy: 'dept-asc' }));
    } else {
      this.store.dispatch(
        UpdateUserManagementOrderBy({ orderBy: 'dept-desc' })
      );
    }
    this.currentPage = 1;
  }

  orderUsersByDate() {
    if (this.orderBy === 'date-desc') {
      this.store.dispatch(UpdateUserManagementOrderBy({ orderBy: 'date-asc' }));
    } else {
      this.store.dispatch(
        UpdateUserManagementOrderBy({ orderBy: 'date-desc' })
      );
    }
    this.currentPage = 1;
  }

  orderUsersByStatus() {
    if (this.orderBy === 'web-status-desc') {
      this.store.dispatch(
        UpdateUserManagementOrderBy({ orderBy: 'web-status-asc' })
      );
    } else {
      this.store.dispatch(
        UpdateUserManagementOrderBy({ orderBy: 'web-status-desc' })
      );
    }
  }

  orderUsersByMobileStatus() {
    if (this.orderBy === 'mobile-status-desc') {
      this.store.dispatch(
        UpdateUserManagementOrderBy({ orderBy: 'mobile-status-asc' })
      );
    } else {
      this.store.dispatch(
        UpdateUserManagementOrderBy({ orderBy: 'mobile-status-desc' })
      );
    }
  }

  orderUsersByAllocatedSeats() {
    if (this.orderBy === 'allocated-seats-desc') {
      this.store.dispatch(
        UpdateUserManagementOrderBy({ orderBy: 'allocated-seats-asc' })
      );
    } else {
      this.store.dispatch(
        UpdateUserManagementOrderBy({ orderBy: 'allocated-seats-desc' })
      );
    }
  }

  onMindflickAccountChanged(mindflickAccountId: number) {
    this.store.dispatch(SetUserManagementSelectedAccountId({ accountId: mindflickAccountId }));
  }

  onUserSelected(userAk: number) {
    this.downloadErrorMessage = '';

    if (this.selectedUserIds.includes(userAk)) {
      this.selectedUserIds = this.selectedUserIds.filter((x) => x != userAk);
    } else {
      this.selectedUserIds = [...this.selectedUserIds, userAk];
    }

    this.setDisplayedActionButtons();
  }

  selectAllUsers() {
    if (this.selectedUserIds.length === this.users.length) {
      this.selectedUserIds = [];
    } else {
      this.selectedUserIds = this.users.map((x) => x.ak);
    }

    this.setDisplayedActionButtons();
  }

  clearSelectedUsers() {
    this.selectedUserIds = [];
  }

  checkIfAllSelectedChampions() {
    var isAllSelectedChampions =
      this.users.filter((x) => this.selectedUserIds.includes(x.ak)).length > 0
        ? this.users
          .filter((x) => this.selectedUserIds.includes(x.ak))
          .every((x) => x.isMindflickChampion)
        : false;
    if (isAllSelectedChampions) {
      this.isAllSelectedChampions = true;
    } else {
      this.isAllSelectedChampions = false;
    }
  }

  checkIfAllSelectedNotChampion() {
    var isAllSelectedNotChampions =
      this.users.filter((x) => this.selectedUserIds.includes(x.ak)).length > 0
        ? this.users
          .filter((x) => this.selectedUserIds.includes(x.ak))
          .every((x) => !x.isMindflickChampion)
        : false;

    if (isAllSelectedNotChampions) {
      this.isAllSelectedNotChampions = true;
    } else {
      this.isAllSelectedNotChampions = false;
    }
  }

  checkIfResendPlatformInviteValid() {
    var isAllSelectedInvited =
      this.users.filter((x) => this.selectedUserIds.includes(x.ak)).length > 0
        ? this.users
          .filter((x) => this.selectedUserIds.includes(x.ak))
          .every((x) => x.portalStatus == PlatformInviteStatusEnum.Invited)
        : false;

    this.isResendPlatformInviteValid = isAllSelectedInvited;
  }

  checkIfSendPlatformInviteValid() {
    var isAllSelectedNotInvited =
      this.users.filter((x) => this.selectedUserIds.includes(x.ak)).length > 0
        ? this.users
          .filter((x) => this.selectedUserIds.includes(x.ak))
          .every((x) => x.portalStatus == PlatformInviteStatusEnum.None)
        : false;

    this.isSendPlatformInviteValid = isAllSelectedNotInvited;
  }

  checkIfAllSelectedDeactivated() {
    this.isAllSelectedDeactivated =
      this.users.filter((x) => this.selectedUserIds.includes(x.ak)).length > 0
        ? this.users
          .filter((x) => this.selectedUserIds.includes(x.ak))
          .every((x) => !x.isUserActive)
        : false;
  }

  checkIfSomeSelectedDeactivated() {
    this.isSomeSelectedDeactivated =
      this.users.filter((x) => this.selectedUserIds.includes(x.ak)).length > 0
        ? this.users
          .filter((x) => this.selectedUserIds.includes(x.ak))
          .some((x) => !x.isUserActive)
        : false;
  }

  checkIsAllSelectedLocked() {
    const selectedUsers = this.users.filter((x) =>
      this.selectedUserIds.includes(x.ak)
    );
    this.isAllSelectedLocked =
      selectedUsers.length > 0 && selectedUsers.every((x) => x.isLocked);
  }

  checkAllSelectedUsersHaveSpotlightProfile() {
    this.accountSettings$.pipe(take(1)).subscribe((settings) => {
      this.showDownloadPDFs =
        !settings?.blockPDFDownloads &&
          this.users.filter((x) => this.selectedUserIds.includes(x.ak)).length > 0
          ? this.users
            .filter((x) => this.selectedUserIds.includes(x.ak))
            .every(
              (x) =>
                x.portalStatus == PlatformInviteStatusEnum.Locked ||
                x.portalStatus == PlatformInviteStatusEnum.Completed ||
                x.flexCope
            )
          : false;
    });
  }

  setDisplayedActionButtons() {
    this.checkIfAllSelectedChampions();
    this.checkIfAllSelectedNotChampion();

    this.checkIfResendPlatformInviteValid();
    this.checkIfSendPlatformInviteValid();

    this.checkIfAllSelectedDeactivated();
    this.checkIfSomeSelectedDeactivated();

    this.checkAllSelectedUsersHaveSpotlightProfile();

    this.checkIsAllSelectedLocked();
  }

  checkCreatedWithinCustomDate(user: AccountAdminManagedUsersDTO) {
    if (this.filter?.fromDate != undefined && this.filter.toDate != undefined) {
      return (
        user.createdDate > this.filter.fromDate &&
        user.createdDate < this.filter.toDate
      );
    } else if (
      this.filter?.fromDate != undefined &&
      this.filter.toDate == undefined
    ) {
      return user.createdDate > this.filter.fromDate;
    } else if (
      this.filter?.fromDate == undefined &&
      this.filter?.toDate != undefined
    ) {
      return user.createdDate < this.filter.toDate;
    }
    return true;
  }

  setCurrentPage(page: number) {
    this.currentPage = page;
    this.displayUsers = [
      ...this.users.slice(
        (this.currentPage - 1) * this.userLimit,
        this.currentPage * this.userLimit
      ),
    ];
  }

  incrementPageNumber() {
    this.currentPage++;
    this.displayUsers = [
      ...this.users.slice(
        (this.currentPage - 1) * this.userLimit,
        this.currentPage * this.userLimit
      ),
    ];
  }

  decrementPageNumber() {
    this.currentPage--;
    this.displayUsers = [
      ...this.users.slice(
        (this.currentPage - 1) * this.userLimit,
        this.currentPage * this.userLimit
      ),
    ];
  }

  changeNumberOfUsers(numberOfUsers: number | string) {
    if (numberOfUsers == 'All') {
      this.userLimit = this.users.length;
      this.displayUsers = this.users.slice();
      this.numOfPages = 1;
    } else if (typeof numberOfUsers === 'number') {
      this.userLimit = numberOfUsers;
      this.numOfPages = Math.ceil(this.users.length / this.userLimit);
      this.displayUsers = [...this.users.slice(0, this.userLimit)];
      this.currentPage = 1;
    }
  }

  downloadPDFs() {
    if (!this.selectedAccount) {
      this.downloadErrorMessage = 'Please select an account before trying to download profiles.';
      return;
    }

    if (!this.showDownloadPDFs) {
      this.selectedUserIds.length > 0 ? this.downloadErrorMessage = '*Please unselect users who have not completed Spotlight to enable PDFs download' : this.downloadErrorMessage = '*Please select one or more users to download';
      return;
    }
    this.downloadErrorMessage = '';
    var usersToDownload = this.users.filter((x) =>
      this.selectedUserIds.includes(x.ak)
    );
    var date = formatDate(new Date(), 'dd/MM/yy', 'en-GB').replace(/\//g, '-');

    this.downloadService.downloadMultipleReportsAsZip(
      usersToDownload,
      this.selectedAccount.accountId,
      `spotlight-profiles-${date}`
    );
  }

  unlockUsers() {
    this.dialogService
      .confirm(
        `Are you sure you want to unlock ${this.selectedUserIds.length} user${this.selectedUserIds.length > 1 ? 's' : ''
        }?`,
        'Are you sure?',
        true,
        'Yes',
        'No'
      )
      .pipe(take(1))
      .subscribe((result) => {
        if (result && this.selectedAccount)
          this.store.dispatch(
            UnlockUsers.Request({ userAks: this.selectedUserIds, accountId: this.selectedAccount.accountId })
          );
      });
  }

  downloadTeamMap() {
    if (!this.selectedAccount) {
      this.downloadErrorMessage = 'Please select an account before trying to download a team map.';
      return;
    }

    if (!this.showDownloadPDFs) {
      this.selectedUserIds.length > 0 ? this.downloadErrorMessage = '*Please unselect users who have not completed Spotlight to enable team map download' : this.downloadErrorMessage = '*Please select one or more users to download';
      return;
    }
    this.downloadErrorMessage = '';

    this.store.dispatch(
      DownloadAdminTeamMap.Request({ memberIds: this.selectedUserIds, accountId: this.selectedAccount.accountId })
    );
  }

  downloadCSV() {
    try {
      const headers = ['First name', 'Last name', 'Email', 'Status', 'Department', 'Job title', 'Notes'];
      var users = this.users
        .filter((x) => this.selectedUserIds.includes(x.ak));


      var txt = headers.join(", ").concat("\n");
      var rowText = users.map(x => {
        const notes = x.notes ? x.notes.replace(/,/g, ' - ').replace(/\n/g, " ") : ' ';
        const jobTitle = x.jobTitle ? x.jobTitle.replace(/,/g, ' - ').replace(/\n/g, " ") : ' ';
        const department = x.department ? x.department.replace(/,/g, ' - ').replace(/\n/g, " ") : ' ';

        const values = [x.firstName, x.lastName, x.email, this.convertStatusToString(x.portalStatus), department, jobTitle, notes]

        return values.join(", ");
      }).join("\n");

      txt = txt.concat(rowText);

      var date = formatDate(new Date(), 'dd/MM/yy', 'en-GB').replace(/\//g, '_');
      var file = new (FileSaver as any)(new Blob([txt], { type: "text/csv" }), `mindflick_users_${date}.csv`);
      FileSaver.saveAs(file);

    } catch (ex) {
    }
  }

  convertStatusToString(status: PlatformInviteStatusEnum) {
    switch (status) {
      case PlatformInviteStatusEnum.Invited:
        return "Invited"
      case PlatformInviteStatusEnum.SpotlightInProgress:
        return "Spotlight in progress"
      case PlatformInviteStatusEnum.Locked:
        return "Locked"
      case PlatformInviteStatusEnum.Completed:
        return "Onboarding Completed"
      default:
        return "n/a"
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.unsubscribe();
  }
}
