<div
  class="d-flex flex-row bg-dark align-items-center justify-content-between email py-4"
>
  <h2 class="text-white">Want to Contact us?</h2>
  <button class="btn btn-purple">
    <a
      href="mailto:support@mindflick.co.uk?subject=ADMIN%20ACCOUNT%20-%20Help%20Center%20Query"
    >
      SEND US AN EMAIL</a
    >
  </button>
</div>
