import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-btn',
  templateUrl: './btn.component.html',
  styleUrl: './btn.component.scss'
})
export class BtnComponent {
  @Input()
  label: string = '';

  @Input()
  buttonType: 'primary' | 'orange' | 'secondary' | 'secondaryLight' = 'primary';

  @Input()
  fullWidth: boolean = false;

  @Input()
  disabled: boolean = false;

  @Input()
  busy: boolean = false;

  @Output()
  buttonClicked = new EventEmitter<MouseEvent>();

  typeClassMap = {
    primary: 'btn btn-purple',
    orange: 'btn btn-orange',
    secondary: 'btn btn-secondary',
    secondaryLight: 'btn btn-secondary-light'
  };

  onClick(e: MouseEvent) {
    if (!this.disabled && !this.busy)
      this.buttonClicked.emit(e);
  }
}
