import { createSelector } from '@ngrx/store';
import {
  ChangeMindflickOrg,
  MindflickAccount,
  User,
} from 'src/app/shared/models';
import { accountStateKey, AppState } from '../app.state';
import { selectCurrentUser } from '../user/user.selector';
import { AccountState } from './account.reducer';
import {
  AlphabeticalSort,
  DateSortDesc,
} from 'src/app/shared/helpers/sorting-helpers';

export const selectAccountState = (state: AppState) => state[accountStateKey];

export const selectAllAccounts = (state: AppState) =>
  state[accountStateKey].allAccounts;
export const selectSelectedAccountId = (state: AppState) =>
  state[accountStateKey].selectedAccountId;

export const selectSelectedAccount = createSelector(
  selectAllAccounts,
  selectSelectedAccountId,
  (accounts: MindflickAccount[], selectedAccountId?: number) => {
    if (!selectedAccountId) return undefined;

    return accounts.find((x) => x.id === selectedAccountId);
  }
);

export const selectSelectedCreditPotId = (state: AppState) =>
  state[accountStateKey].selectedCreditPotId;

export const selectSelectedCreditPot = createSelector(
  selectAllAccounts,
  selectSelectedCreditPotId,
  (accounts: MindflickAccount[], selectedCreditPotId?: number) => {
    if (!selectedCreditPotId) return undefined;

    return accounts
      .find((x) => x.creditPots.some((x) => x.id === selectedCreditPotId))
      ?.creditPots.find((x) => x.id === selectedCreditPotId);
  }
);

export const selectUserAccounts = (state: AppState) =>
  state[accountStateKey].userAccounts;
export const selectUserAccount = (state: AppState) =>
  state[accountStateKey].currentUserAccount;
export const selectUserAccountsList = (state: AppState) =>
  state[accountStateKey].userAccountsList;
export const selectUserAccountsLoading = (state: AppState) =>
  state[accountStateKey].userAccountsLoading;

export const selectMindflickAccountIsSaving = (state: AppState) =>
  state[accountStateKey].mindflickAccountIsSaving;
export const selectMindflickAccountSaveSuccessful = (state: AppState) =>
  state[accountStateKey].mindflickAccountSaveSuccess;
export const selectMindflickAccountSaveError = (state: AppState) =>
  state[accountStateKey].mindflickAccountSaveError;

export const selectSendingInvites = (state: AppState) =>
  state[accountStateKey].sendingInvites;
export const selectSendingInvitesSuccess = (state: AppState) =>
  state[accountStateKey].sendingInvitesSuccess;
export const selectSendingInvitesError = (state: AppState) =>
  state[accountStateKey].sendingInvitesError;
export const selectChampionCanViewAnalytics = (state: AppState) =>
  state[accountStateKey].currentUserAccount?.championCanViewAnalyticsStatus;

const selectCurrentUserAccount = (state: AppState) =>
  state[accountStateKey].currentUserAccount;

export const selectCurrentAccountName = createSelector(
  selectCurrentUserAccount,
  (account: MindflickAccount | undefined) => {
    return account?.name;
  }
);

export const selectCurrentAccountId = createSelector(
  selectCurrentUserAccount,
  (account?: MindflickAccount) => {
    return account?.id;
  }
);

export const selectCurrentAccountIsMindflickLed = createSelector(
  selectCurrentUserAccount,
  (account?: MindflickAccount) => {
    return account?.isMindflickLed;
  }
);

export const selectIsUserInvitesEnabled = (state: AppState) =>
  state[accountStateKey].isUserInvitesEnabled;

export const selectOrgTwoFactorRequired = (state: AppState) =>
  state[accountStateKey].orgTwoFactorRequired;





export const selectChangeOrganisationData = createSelector(
  selectUserAccounts,
  (accounts: MindflickAccount[]): ChangeMindflickOrg[] => {

    const allOrgs: ChangeMindflickOrg[] = accounts.map(account => ({
      mindflickOrganisationId: account.mindflickOrganisationId,
      mindflickAccountId: account.id,
      mindflickOrganisationName: account.mindflickOrganisationName
    }));

    const resp : ChangeMindflickOrg[] = []
      allOrgs.forEach(org => {
        if (!resp.some(x => x.mindflickOrganisationId === org.mindflickOrganisationId)) {
          resp.push(org);
        }
      });

    return resp;

  }
);
