import { createReducer, on } from '@ngrx/store';
import { PlatformEventAudit } from 'src/app/shared/models/audit.interface';
import * as AuditActions from './audit.actions';
import { CommonActions } from '../common-actions';

export interface AuditState {
  auditEvents: PlatformEventAudit[];
  eventsSaving: boolean;
}

export const initialAuditState: AuditState = {
  auditEvents: [],
  eventsSaving: false,
};

export const auditReducer = createReducer(
  initialAuditState,

  on(CommonActions.ClearState, (state, props) => ({
    ...initialAuditState
  })),

  on(AuditActions.AddEvent, (state, props) => ({
    ...state,
    auditEvents: [...state.auditEvents, props.event],
  })),

  on(AuditActions.ClearEvents, (state, props) => ({
    ...state,
    // Remove just the events that we know have been sent to the server
    auditEvents: state.auditEvents.filter((x) => !props.events.includes(x)),
  })),

  on(AuditActions.SetAuditsSaving, (state) => ({
    ...state,
    eventsSaving: true,
  })),

  on(AuditActions.SaveEvents.Success, (state) => ({
    ...state,
    eventsSaving: false,
  })),

  on(AuditActions.SaveEvents.Fail, (state) => ({
    ...state,
    eventsSaving: false,
  }))
);
