import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../+state/app.state';
import { SetSubMenuLargePopout } from '../../../+state/layout/actions/layout.actions';
import { SubMenuLargePopoutEnum } from '../../../+state/layout/reducers/layout.reducers';
import { faCloseSVG } from '../../../../icons';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss'],
})
export class PrivacyPolicyComponent implements OnInit {
  close = faCloseSVG;

  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {}

  closePopup() {
    this.store.dispatch(
      SetSubMenuLargePopout({ subMenuLargePopout: SubMenuLargePopoutEnum.None })
    );
  }
}
