import { ChangeDetectorRef, EventEmitter, inject, Injectable, OnDestroy } from "@angular/core";
import { MindflickRole } from "../models";
import { Store } from "@ngrx/store";
import { AppState } from "src/app/+state/app.state";
import { selectCurrentUserAccountRoles, selectIsSuperAdmin } from "src/app/+state/user/user.selector";
import { Subject, takeUntil } from "rxjs";
import { selectCurrentAccountId } from "src/app/+state/account/account.selector";

@Injectable({
    providedIn: 'root'
})
export class RbacService implements OnDestroy {
    private _selectedMindflickAccount?: number;
    /*
    Any roles for secondary accounts within the same organisation, e.g Champions
    */
    private _accountRoles: Map<number, MindflickRole[]> = new Map<number, MindflickRole[]>();

    private isAdmin = false;

    private destroyed$ = new Subject<boolean>();

    rolesChanged = new EventEmitter<void>();

    constructor(private store: Store<AppState>) {
      this.store.select(selectCurrentAccountId)
        .pipe(takeUntil(this.destroyed$))
        .subscribe(accountId => {
            this._selectedMindflickAccount = accountId;
        });

      this.store.select(selectCurrentUserAccountRoles)
        .pipe(takeUntil(this.destroyed$))
        .subscribe(roles => {
            this._accountRoles = roles;
        });

      this.store.select(selectIsSuperAdmin)
        .pipe(takeUntil(this.destroyed$))
        .subscribe(isAdmin => {
          this.isAdmin = isAdmin;
        });
    }

    ngOnDestroy() {
        this.destroyed$.next(true);
        this.destroyed$.unsubscribe();
    }

    addRoleToAccount(role: MindflickRole, mindflickAccountId: number) {
        let accountRoles = this._accountRoles.get(mindflickAccountId);

        if (accountRoles) {
            accountRoles = [ ...accountRoles, role ];
        } else {
            accountRoles = [ role ];
        }

        this._accountRoles.set(mindflickAccountId, accountRoles);
        this.rolesChanged.emit();
    }

    isGranted(roleOrPermission: string): boolean {
        if (this.isAdmin) return true;

        if (!this._selectedMindflickAccount)
            return false;
        
        return this.isGrantedForAccount(roleOrPermission, this._selectedMindflickAccount);
    }

    isGrantedForAccount(roleOrPermission: string, mindflickAccountId: number) {
        if (this.isAdmin) return true;
        
        const accountRoles = this._accountRoles.get(mindflickAccountId);
        if (accountRoles && accountRoles.some(r => r.name === roleOrPermission))
            return true;

        const accountPermissions = accountRoles?.flatMap(x => x.permissions);
        return !!(accountPermissions && accountPermissions.some(p => p === roleOrPermission));
    }

    isGrantedAnyAccount(roleOrPermission: string) {
        const roles = Array.from(this._accountRoles.values()).flatMap(x => x);

        if (roles.some(r => r.name === roleOrPermission))
            return true;

        const permissions = roles.flatMap(r => r.permissions);

        return permissions.some(p => p === roleOrPermission);
    }

    removeRoleFromAccount(role: string, mindflickAccountId: number) {
        let accountRoles = this._accountRoles.get(mindflickAccountId);

        if (accountRoles) {
            accountRoles = accountRoles.filter(r => r.name !== role);

            this._accountRoles.set(mindflickAccountId, accountRoles);

            this.rolesChanged.emit();
        }
    }
}
