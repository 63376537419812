import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';

export const fadeInOut = trigger('fade', [
  transition('* => *', [
    animate(800, keyframes([
      style({ opacity: 0 }),
      style({ opacity: 1 }),
    ])),
  ]),
])

