<ng-container *ngIf="graph">
  <div class="d-flex flex-column position-relative my-2 graph">
    <div class="d-flex">
      <div class="info-popup position-absolute d-flex align-items-start"
           *ngIf="isDescriptionVisible">
        <div class="infoText text-white body-copy ps-4 pt-5 w-75">
          {{ graph.description }}
        </div>
        <fa-icon class="close position-absolute cursor-pointer"
                 [icon]="close"
                 (click)="isDescriptionVisible = false"></fa-icon>
      </div>
      <div class="d-flex mb-4">
        <h3 class="flex-fill">{{ graph.title }}</h3>
        <fa-icon class="question position-absolute cursor-pointer"
                 [icon]="question"
                 (click)="isDescriptionVisible = true"></fa-icon>
      </div>
    </div>
    <ng-container [ngSwitch]="graph.type">
      <div *ngSwitchCase="GraphTypes.NumberCard" class="text-card">
        <h1 class="text-card-text mb-4">
          {{ graph.value }} {{ graph.total ? ' / ' + graph.total : '' }}
        </h1>
      </div>
      <ng-container *ngIf="graph.percentageValue != null">
        <div *ngSwitchCase="GraphTypes.Percentage" class="percent-card">
          <ngx-charts-gauge *ngIf="containerWidth != null && containerWidth > 32"
                            [view]="[containerWidth - 32,300]"
                            [results]="graph.percentageValue!"
                            [startAngle]="0"
                            [angleSpan]="360"
                            [bigSegments]="0"
                            [smallSegments]="0"
                            [valueFormatting]="formatGaugeValue"
                            [scheme]="allOrangeScheme"
                            [margin]="[20, 20, 20, 20]">
          </ngx-charts-gauge>
        </div>
      </ng-container>

      <div *ngSwitchCase="GraphTypes.PieChart">
        <ngx-charts-pie-chart [view]="[containerWidth - 32, 300]"
                              [results]="graph.nudgeRatings"
                              [scheme]="allPurplePantonesScheme"
                              [legend]="true"
                              [legendTitle]="''"
                              [legendPosition]="LegendPosition.Below"
                              [doughnut]="false"
                              [gradient]="true">
        </ngx-charts-pie-chart>
      </div>
      <div *ngSwitchCase="GraphTypes.TopNudges">
        <div *ngIf="graph.topFiveNudges != null && graph.topFiveNudges.length > 0; else nudgesPlaceholder" class="row gy-3">
          <app-work-on-card-small *ngFor="let nudge of graph.topFiveNudges"
                                  [id]="-1"
                                  [title]="nudge.title"
                                  [imageUrl]="nudge.squareImageUrl"
                                  [growthType]="GrowthType.WorkOn"
                                  [disableShowWorkOn]="false"
                                  [hasRightMargin]="false"
                                  [showSummary]="false"
                                  [minWidthPx]="0"
                                  [widthVw]="0"
                                  (click)="showNudgeDetails(nudge)"></app-work-on-card-small>
        </div>

        <ng-template #nudgesPlaceholder>
          <p class="text-muted">No nudges to show</p>
        </ng-template>
      </div>
      <ng-container>
        <div *ngSwitchCase="GraphTypes.TopWorkOns">
          <div  *ngIf="graph.topWorkOns != null && graph.topWorkOns.length > 0; else workOnsPlaceholder" class="row gy-3">
            <ng-container *ngFor="let workOn of graph.topWorkOns; index as i">
              <app-work-on-card-small 
                                      [id]="workOn.id"
                                      [title]="workOn.title"
                                      [summary]="workOn.summary"
                                      [imageUrl]="workOn.portalUpcomingImageUrl"
                                      [growthType]="GrowthType.WorkOn"
                                      [workOnType]="workOn.type"
                                      [hasRightMargin]="false"
                                      [showSummary]="false"
                                      [minWidthPx]="0"
                                      [widthVw]="0"></app-work-on-card-small>
            </ng-container>
          </div>
          <ng-template #workOnsPlaceholder>
            <p class="text-muted">No work ons to show</p>
          </ng-template>
        </div>
      </ng-container>
        <div *ngSwitchCase="GraphTypes.TimeSeries">
          <ngx-charts-bar-vertical [view]="[450, 300]"
                                   [results]="graph.monthlyActiveUsers"
                                   [scheme]="horizontalBarScheme"
                                   [xAxis]="true"
                                   [yAxis]="true"
                                   [xAxisLabel]="'Month'"
                                   [yAxisLabel]="'Active users'"
                                   [yAxisTickFormatting]="formatBarYAxis"
                                   [legend]="false"
                                   [legendTitle]="''"
                                   [legendPosition]="LegendPosition.Below">
          </ngx-charts-bar-vertical>
        </div>
      </ng-container>
</div>
</ng-container>
