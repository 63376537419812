<div class="legal-container">
  <div class="legal-page pb-3 bg-white pt-4">
    <div class="legal-content d-flex flex-column">
      <div class="d-flex pt-5 pb-4 align-items-center header">
        <app-btn-action
          class="legal-close fa-lg top"
          [icon]="close"
          [iconDimensions]="35"
          (click)="closePopup()"
        ></app-btn-action>
      </div>
      <div class="legal-item-container overflow-auto">
        <div class="legal-content-container w-75">
          <h1 class="pb-4">Terms & Conditions</h1>

          <p>
            THE FOLLOWING TERMS AND CONDITIONS (“AGREEMENT”) APPLY TO THE
            MINDFLICK PLATFORM SERVICES (“SERVICES”) YOU ARE USING THAT ARE
            PROVISIONED BY MINDFLICK HOLDINGS LTD (“MINDFLICK”). BY ACCESSING OR
            USING ANY PART OF THE SERVICES, YOU ARE UNCONDITIONALLY CONSENTING
            TO BE BOUND BY, AND BECOME A PARTY TO, THE TERMS AND CONDITIONS
            STATED HERE. MINDFLICK’S ACCEPTANCE IS EXPRESSLY CONDITIONS UPON
            YOUR AGREEMENT TO ALL OF THE TERMS AND CONDITIONS, TO THE EXCLUSION
            OF ALL OTHER TERMS.
          </p>
          <hr />
          <ol>
            <li>
              This Agreement
              <ol>
                <li>
                  The General Terms, all Order Form(s), the Schedule to these
                  Condition and any privacy related documents executed by the
                  parties (collectively, this “Agreement”) form a contract
                  between you and Mindflick. In case of conflict between the
                  different parts of this Agreement, the Order Form shall take
                  precedence.
                </li>
                <li>
                  These Conditions shall govern the Agreement to the exclusion
                  of all other terms and conditions. No conflicting or
                  additional terms or conditions (including any quotation,
                  delivery note, invoice, acknowledgement) shall apply unless
                  specifically agreed to in writing by Mindflick’s authorised
                  representative.
                </li>
              </ol>
            </li>

            <li>
              User Subscriptions
              <ol>
                <li>
                  Subject to the Client purchasing the User Subscriptions in
                  accordance with clause 3.3, clause 8.1 and the Conditions,
                  Mindflick hereby grants the Client a non-exclusive,
                  non-transferable right, without the right to grant
                  sublicences, to permit the Authorised Users to use the
                  Services during the Subscription Term solely for the Client’s
                  internal business operations.
                </li>
                <li>
                  In relation to the Authorised Users, the Client undertakes
                  that:
                  <ol type="a" class="letter">
                    <li>
                      the maximum number of Authorised Users that the Client
                      authorises to access and use the Services shall not exceed
                      the number of User Subscriptions that the Client has
                      purchased from time to time;
                    </li>
                    <li>
                      the Client will not allow or suffer any User Subscription
                      to be used by more than one individual Authorised User
                      unless such Authorised User is no longer part of the
                      Client’s staff and that his/her subscription has been
                      reassigned in its entirety to another individual
                      Authorised User, in which case the prior Authorised User
                      shall no longer have any right to access or use the
                      Services and/or the Software; and
                    </li>
                    <li>
                      the Client will procure that each Authorised User shall
                      keep a secure password for their use of the Services and
                      that each Authorised User shall keep their password and
                      any other access code details confidential.
                    </li>
                  </ol>
                </li>
                <li>
                  The Client shall not, and the Client procures that the
                  Client’s Authorised Users shall not, access, store, distribute
                  or transmit any Viruses, or any material during the course of
                  the Client’s use of the Services that:
                  <ol type="a">
                    <li>
                      is unlawful, harmful, threatening, defamatory, obscene,
                      infringing, harassing or racially or ethnically offensive;
                    </li>
                    <li>facilitates illegal activity;</li>
                    <li>depicts sexually explicit images;</li>
                    <li>promotes unlawful violence;</li>
                    <li>
                      is discriminatory based on race, gender, colour, religious
                      belief, sexual orientation, disability; or
                    </li>
                    <li>
                      is otherwise illegal or causes damage or injury to any
                      person or property; and Mindflick reserves the right,
                      without liability or prejudice to its other rights to the
                      Client, to disable the Client or Authorised Users’ access
                      to any material that breaches the provisions of this
                      clause 2.3.
                    </li>
                  </ol>
                </li>

                <li>
                  The Client shall not:
                  <ol type="a">
                    <li>
                      except as may be allowed by any applicable law which is
                      incapable of exclusion by agreement between the parties
                      and except to the extent expressly permitted under this
                      Agreement:
                      <ol>
                        <li>
                          attempt to copy, modify, duplicate, create derivative
                          works from, frame, mirror, republish, download,
                          display, transmit, or distribute all or any portion of
                          the Software in any form or media or by any means;
                        </li>
                        <li>
                          attempt to de-compile, reverse compile, disassemble,
                          reverse engineer, or otherwise reduce to
                          human-perceivable form all or any part of the
                          Software;
                        </li>
                      </ol>
                    </li>
                    <li>
                      access all or any part of the Services in order to build a
                      product or service which competes with the Services;
                    </li>
                    <li>
                      use the Services to provide services to third parties;
                    </li>
                    <li>
                      subject to clause 20, license, sell, rent, lease,
                      transfer, assign, distribute, display, disclose, or
                      otherwise commercially exploit, or otherwise make the
                      Services available to any third party except the
                      Authorised Users,
                    </li>
                    <li>
                      attempt to obtain, or assist third parties in obtaining,
                      access to the Services other than as provided under this
                      clause 2; and/or
                    </li>
                    <li>
                      introduce or permit the introduction of any Virus or
                      Vulnerability into Mindflick’s network and information
                      systems and/or the Software.
                    </li>
                  </ol>
                </li>

                <li>
                  The Client shall use all reasonable endeavours to prevent any
                  unauthorised access to, or use of, the Services and, in the
                  event of any such unauthorised access or use, promptly notify
                  Mindflick.
                </li>
                <li>
                  The rights provided under this clause 2 are granted to the
                  Client only and shall not be considered granted to any
                  affiliate, subsidiary or holding company of the Client.
                </li>
              </ol>
            </li>

            <li>
              Additional User Subscriptions
              <ol>
                <li>
                  Subject to clause 3.2 and clause 3.3, the Client may, from
                  time to time during any Subscription Term, purchase additional
                  User Subscriptions in excess of the number set out in the
                  Order Form and Mindflick shall grant access to the Services to
                  such additional Authorised Users in accordance with these
                  Conditions.
                </li>
                <li>
                  If the Client wishes to purchase additional User
                  Subscriptions, the Client shall notify Mindflick in writing.
                  Mindflick shall evaluate such request for additional User
                  Subscriptions and respond to the Client with approval or
                  rejection of the request. Where Mindflick approves the
                  request, Mindflick shall aim to activate the additional User
                  Subscriptions within 2 days of Mindflick’s approval of the
                  Client’s request.
                </li>
                <li>
                  If Mindflick approves the Client’s request to purchase
                  additional User Subscriptions, the Client shall, within 30
                  days of the date of Mindflick’s invoice, pay Mindflick the
                  relevant fees for such additional User Subscriptions as set
                  out in the Order Form and, if the Client purchases such
                  additional User Subscriptions part way through the Initial
                  Subscription Term or any Renewal Period (as applicable), such
                  fees shall be pro-rated from the date of activation by
                  Mindflick for the remainder of the Initial Subscription Term
                  or then current Renewal Period (as applicable).
                </li>
              </ol>
            </li>
            <li>
              Services
              <ol>
                <li>
                  Access - Mindflick shall, during the Subscription Term,
                  provide to the Client and its Authorised Users access to the
                  Software and Services on and subject to the terms of the
                  Agreement.
                </li>
                <li>
                  Test results – Authorised Users will be offered access to
                  Mindflick’s personality test through the Software. As part of
                  the tests completed by Authorised Users, Mindflick shall
                  produce and make available to the Client the material, reports
                  and/or guidance on the findings of such test results. The
                  Client understands that such findings do not include
                  Mindflick’s consultancy services relating to personality
                  profiling, individual development and team building which may
                  be agreed by the parties subject to a separate agreement,
                </li>
                <li>
                  Maintenance - Mindflick shall use commercially reasonable
                  endeavours to make the Services available 24 hours a day,
                  seven days a week, except for:
                  <ol type="a">
                    <li>
                      planned maintenance carried out during the maintenance
                      window of 9.00 pm to 4.00 am UK time; and
                    </li>
                    <li>
                      unscheduled maintenance performed outside of Normal
                      Business Hours, provided that Mindflick have used
                      reasonable endeavours to give the Client at least 1
                      Business Days’ notice in advance, if possible.
                    </li>
                  </ol>
                </li>
                <li>
                  Training– During the Subscription Term, Mindflick shall
                  provide training services as and when agreed with the Client
                  on the following basis:
                  <ol type="a">
                    <li>
                      Where training services are provided on the Client’s
                      premises, the Client will provide Mindflick with access to
                      such premises, systems, information, data, facilities and
                      personnel as Mindflick may reasonably request in order to
                      perform such services and shall provide a safe-working
                      environment, in compliance with applicable laws for any
                      and all Mindflick personnel in attendance.
                    </li>
                    <li>
                      Mindflick reserves the right to reasonably re-schedule or
                      cancel training sessions, at its sole discretion, provided
                      Mindflick has given the Client at least five Business
                      Days’ notice. Mindflick shall not be liable for any costs,
                      damages or expenses suffered by the Client because of
                      cancellation or postponement.
                    </li>
                  </ol>
                </li>
                <li>
                  Support - If the Client encounters any technical issues whilst
                  using the Software, it shall contact Mindflick by email at:
                  support&#64;mindflick.co Mindflick shall provide support services
                  during Normal Business Hours.
                </li>
                <li>
                  Mindflick reserves the right to modify, enhance and/or
                  introduce new and existing features and functionalities to the
                  Software.
                </li>
              </ol>
            </li>
            <li>
              Data protection
              <ol>
                <li>
                  The Services are hosted by Mindflick on third party servers.
                  User Data will likely contain personal data (“User Personal
                  Data”) in relation to the Authorised User which Mindflick will
                  store, copy, and make available to the Client, and as the
                  Client chooses to access, as part of receiving the Services.
                </li>
                <li>
                  Both parties will comply with all applicable requirements of
                  the Data Protection Legislation. This clause 5 is in addition
                  to, and does not relieve, remove, or replace, a party's
                  obligations or rights under the Data Protection Legislation.
                </li>
                <li>
                  Both Mindflick and the Client acknowledge that:
                  <ol type="a">
                    <li>
                      In some cases, Mindflick will be acting as a data
                      controller and others as a data processor. Within its role
                      as a date controller Mindflick shall process personal data
                      in accordance with its
                      <button class="legal-link" (click)="openPrivacy()">
                        Privacy Policy</button
                      >. Whilst as a data processor Mindflick will comply with
                      its obligations set out in this clause 5.
                    </li>
                    <li>
                      The Order Form sets out the scope, nature, and purpose of
                      processing by Mindflick, the duration of the processing
                      and the types of personal data and categories of data
                      subject; and
                    </li>
                    <li>
                      the User Personal Data may be transferred or stored
                      outside the UK or the country where the Client is located,
                      in order to carry out the Services and Mindflick’s other
                      obligations under this Agreement.
                    </li>
                  </ol>
                </li>
                <li>
                  The Client shall ensure that the Client has all necessary
                  permissions, consents and lawful basis and comply with
                  applicable Data Protection Legislation in processing the User
                  Personal Data and in particular that the Client has the
                  necessary consents to process any special categories of
                  personal data.
                </li>
                <li>
                  Without prejudice to the generality of clause 5.2, where
                  Mindflick acts as a data processor to the Client, then
                  Mindflick shall, in relation to the personal data:
                  <ol type="a">
                    <li>
                      process that personal data only on the Client’s documented
                      written instructions (which are set out in the Agreement);
                    </li>
                    <li>
                      ensure that Mindflick has in place appropriate technical
                      and organisational measures, which Mindflick shall
                      regularly review, to protect against unauthorised or
                      unlawful processing of personal data and against
                      accidental loss or destruction of, or damage to, personal
                      data, appropriate to the harm that might result from the
                      unauthorised or unlawful processing or accidental loss,
                      destruction or damage and the nature of the data to be
                      protected, having regard to the state of technological
                      development and the cost of implementing any measures
                      (those measures may include, where appropriate,
                      pseudonymising and encrypting personal data, ensuring
                      confidentiality, integrity, availability and resilience of
                      its systems and services, ensuring that availability of
                      and access to personal data can be restored in a timely
                      manner after an incident, and regularly assessing and
                      evaluating the effectiveness of the technical and
                      organisational measures adopted by it).
                    </li>
                    <li>
                      not transfer any personal data outside of the UK unless
                      appropriate safeguards in relation to the transfer have
                      been put in place;
                    </li>
                    <li>
                      assist the Client in responding to any request from a data
                      subject in respect of personal data and in ensuring
                      compliance with Mindflick’s obligations under the Data
                      Protection Legislation with respect to security, breach
                      notifications, impact assessments and consultations with
                      supervisory authorities or regulators;
                    </li>
                    <li>
                      notify the Client without undue delay on becoming aware of
                      a personal data breach (not less than 48 working hours of
                      becoming aware);
                    </li>
                    <li>
                      at the Client’s written direction, delete or return
                      personal data and copies thereof to the Client unless
                      required to store the personal data; and
                    </li>
                    <li>
                      maintain complete and accurate records and information to
                      demonstrate Mindflick’s compliance with this clause 5 and
                      immediately inform the Client if, in its opinion, an
                      instruction infringes the Data Protection Legislation.
                    </li>
                  </ol>
                </li>
                <li>
                  The Client gives its general consent to Mindflick appointing
                  third parties as sub-processors of any personal data processed
                  under this Agreement. The current sub-processors are set out
                  in the Order Form. Mindflick confirms that Mindflick has
                  entered or (as the case may be) will enter with the
                  third-party processor into a written agreement substantially
                  similar to the ones in this Agreement and which reflect the
                  requirements of the Data Protection Legislation.
                </li>
                <li>
                  Mindflick will follow its archiving and security procedures
                  for User Data as set out in its data retention policy.
                  Mindflick shall endeavour to follow best practice and will
                  delete User Data 30 days after termination of this Agreement
                  and the Client can request access to this data for download
                  during this period.
                </li>
                <li>
                  Mindflick will promptly notify the Client in writing of any
                  actual or suspected loss or damage to the personal data. In
                  the event of any loss or damage to personal data, the Client’s
                  sole and exclusive remedy shall be for Mindflick to use
                  reasonable commercial endeavours to restore the lost or
                  damaged personal data from the latest backup of such personal
                  data which is maintained by Mindflick in accordance with its
                  archiving procedure. Mindflick shall not be responsible for
                  any loss, destruction, alteration or unauthorised access to,
                  or disclosure of any personal data caused by any third party
                  (except those third parties sub-contracted by Mindflick to
                  perform services related to personal data maintenance and
                  back-up). This clause 5.8 is without prejudice to the
                  generality of clause 5.2.
                </li>
              </ol>
            </li>

            <li>
              Mindflick’s obligations
              <ol>
                <li>
                  Mindflick undertakes that the Services will be performed with
                  reasonable skill and care and in accordance with good industry
                  standard.
                </li>
                <li>
                  The undertaking at clause 6.1 shall not apply to the extent of
                  any non-conformance which is caused by use of the Services
                  contrary to its instructions, or modification or alteration of
                  the Services by any party other than Mindflick or its
                  authorised contractors or agents. If the Services do not
                  conform with the foregoing undertaking, Mindflick will at its
                  expense, use all reasonable commercial endeavours to correct
                  any such non-conformance promptly, or provide the Client with
                  an alternative means of accomplishing the desired performance.
                  Such correction or substitution constitutes the Client's sole
                  and exclusive remedy for any breach of the undertaking set out
                  in clause 6.1.
                </li>
                <li>
                  Mindflick:
                  <ol>
                    <li>
                      does not warrant that:
                      <ol>
                        <li>
                          the Client's use of the Services will be uninterrupted
                          or error-free;
                        </li>
                        <li>
                          that the Services, Software, and/or the information
                          obtained by the Client through the Services will meet
                          the Client’s requirements; or (iii) the Software or
                          the Services will be free from Vulnerabilities or
                          Viruses;
                        </li>
                      </ol>
                    </li>
                    <li>
                      is not responsible for any delays, delivery failures, or
                      any other loss or damage resulting from the transfer of
                      data over communications networks and facilities,
                      including the internet, and the Client acknowledges that
                      the Services may be subject to limitations, delays and
                      other problems inherent in the use of such communications
                      facilities.
                    </li>
                  </ol>
                </li>
                <li>
                  This Agreement shall not prevent Mindflick from entering into
                  similar agreements with third parties, or from independently
                  developing, using, selling, or licensing documentation,
                  products and/or services which are similar to those provided
                  under this Agreement.
                </li>
                <li>
                  Mindflick warrants that it has and will maintain all necessary
                  licences, consents, and permissions necessary for the
                  performance of its obligations under this Agreement.
                </li>
              </ol>
            </li>

            <li>
              Client's obligations
              <ol>
                <li>
                  The Client shall:
                  <ol>
                    <li>
                      provide Mindflick with:
                      <ol>
                        <li>
                          all necessary co-operation in relation to this
                          Agreement; and
                        </li>
                        <li>
                          all necessary access to such information as may be
                          required by Mindflick; in order to provide the
                          Services, including but not limited to Client Data,
                          security access information and configuration
                          services;
                        </li>
                      </ol>
                    </li>
                    <li>
                      without affecting Client’s other obligations under this
                      Agreement, comply with all applicable laws and regulations
                      with respect to its activities under this Agreement;
                    </li>
                    <li>
                      carry out all other responsibilities set out in this
                      Agreement in a timely and efficient manner. In the event
                      of any delay in the Client’s provision of such assistance
                      as agreed by the parties, Mindflick may adjust any agreed
                      timetable or delivery schedule as reasonably necessary;
                    </li>
                    <li>
                      ensure that the Authorised Users use the Services and the
                      Software in accordance with these Conditions and shall be
                      responsible for any Authorised User's breach of the
                      Agreement;
                    </li>
                    <li>
                      obtain and shall maintain all necessary licences,
                      consents, and permissions necessary for Mindflick, its
                      contractors and agents to perform their obligations under
                      this Agreement, including without limitation the Services;
                    </li>
                    <li>
                      ensure that its network and systems comply with the
                      relevant specifications provided by Mindflick from time to
                      time; and
                    </li>
                    <li>
                      be, to the extent permitted by law and except as otherwise
                      expressly provided in this Agreement, solely responsible
                      for procuring, maintaining and securing the Client’s
                      network connections and telecommunications links from the
                      Client’s systems to Mindflick’s data centres, and all
                      problems, conditions, delays, delivery failures and all
                      other loss or damage arising from or relating to the
                      Client’s network connections or telecommunications links
                      or caused by the internet.
                    </li>
                  </ol>
                </li>
                <li>
                  The Client shall own all right, title, and interest in and to
                  all Client Data that is not personal data and shall have sole
                  responsibility for the legality, reliability, integrity,
                  accuracy, and quality of all such Data.
                </li>
              </ol>
            </li>
            <li>
              Payment
              <ol>
                <li>
                  The Client shall pay Mindflick the Subscription Fees and any
                  other sums owed to Mindflick under this Agreement, without
                  set-off or deduction, in accordance with this clause 8 and the
                  Order Form.
                </li>
                <li>
                  The Client shall on the Commencement Date provide Mindflick
                  with valid, up-to-date, and complete credit card details or
                  approved purchase order information acceptable to Mindflick
                  and any other relevant valid, up-to-date and complete contact
                  and billing details and, if the Client provides Mindflick with
                  the Client’s:
                  <ol>
                    <li>
                      credit card details, the Client hereby authorises
                      Mindflick to bill such credit card:
                      <ol>
                        <li>
                          on the Commencement Date for the Subscription Fees
                          payable in respect of the Initial Subscription Term;
                          and
                        </li>
                        <li>
                          subject to clause 13.1, on each anniversary of the
                          Commencement Date for the Subscription Fees payable in
                          respect of the next Renewal Period;
                        </li>
                      </ol>
                    </li>
                    <li>
                      approved purchase order information, Mindflick shall
                      invoice the Client:
                      <ol>
                        <li>
                          on the Commencement Date for the Subscription Fees
                          payable in respect of the Initial Subscription Term;
                          and
                        </li>
                        <li>
                          subject to clause 13.1, at least 30 days prior to each
                          anniversary of the Commencement Date for the
                          Subscription Fees payable in respect of the next
                          Renewal Period, and the Client shall pay each invoice
                          within 30 calendar days of the date of such invoice.
                        </li>
                      </ol>
                    </li>
                  </ol>
                </li>
                <li>
                  If Mindflick has not received payment within 7 calendar days
                  after the due date, and without prejudice to any of its other
                  rights and remedies:
                  <ol>
                    <li>
                      Mindflick may, without liability to the Client, disable
                      the Client’s and applicable Authorised Users’ password,
                      account and access to all or part of the Services and
                      Mindflick shall be under no obligation to provide any or
                      all of the Services while the invoice(s) concerned remain
                      unpaid; and
                    </li>
                    <li>
                      interest shall accrue on a daily basis on such due amounts
                      at an annual rate equal to 3% over the then current base
                      lending rate of Mindflick’s bankers in the UK from time to
                      time, commencing on the due date and continuing until
                      fully paid, whether before or after judgment.
                    </li>
                  </ol>
                </li>
                <li>
                  All amounts and fees stated or referred to in this Agreement:
                  <ol>
                    <li>shall be payable in pounds sterling;</li>
                    <li>
                      are non-cancellable, non-exchangeable and non-refundable;
                      and
                    </li>
                    <li>
                      are exclusive of value added tax, which shall be added to
                      Mindflick’s invoice(s) at the appropriate rate.
                    </li>
                  </ol>
                </li>
                <li>
                  Mindflick shall be entitled to increase the Subscription Fees,
                  the fees payable in respect of the additional User
                  Subscriptions purchased pursuant to clause 3.3 the start of
                  each Renewal Period upon 90 days' prior notice to the Client
                  the Order Form shall be deemed to have been amended
                </li>
                accordingly.
              </ol>
            </li>

            <li>
              Proprietary rights
              <ol>
                <li>
                  The Client acknowledges and agrees that Mindflick and/or
                  Mindflick’s licensors own all intellectual property rights in
                  the Services and the Software. Except as expressly stated
                  herein, these Conditions do not grant the Client any rights
                  to, under or in, any patents, copyright, database right, trade
                  secrets, trade names, trade marks (whether registered or
                  unregistered), or any other rights or licences in respect of
                  the Software nor the Services.
                </li>
                <li>
                  Mindflick confirms that it has all the rights in relation to
                  the Services that are necessary to grant all the rights it
                  purports to grant under, and in accordance with, the terms of
                  this Agreement.
                </li>
              </ol>
            </li>

            <li>
              Confidentiality
              <ol>
                <li>
                  Each party may be given access to Confidential Information
                  from the other party in order to perform its obligations under
                  this Agreement. A party's Confidential Information shall not
                  be deemed to include information that:
                  <ol>
                    <li>
                      is or becomes publicly known other than through any act or
                      omission of the receiving party;
                    </li>
                    <li>
                      was in the other party's lawful possession before the
                      disclosure;
                    </li>
                    <li>
                      is lawfully disclosed to the receiving party by a third
                      party without restriction on disclosure; or
                    </li>
                    <li>
                      is independently developed by the receiving party, which
                      independent development can be shown by written evidence.
                    </li>
                  </ol>
                </li>
                <li>
                  Subject to clause 10.4, each party shall hold the other's
                  Confidential Information in confidence and not make the
                  other's Confidential Information available to any third party
                  or use the other's Confidential Information for any purpose
                  other than the implementation of this Agreement.
                </li>
                <li>
                  Each party shall take all reasonable steps to ensure that the
                  other's Confidential Information to which it has access is not
                  disclosed or distributed by its employees or agents in
                  violation of the terms of this Agreement.
                </li>
                <li>
                  A party may disclose Confidential Information to the extent
                  such Confidential Information is required to be disclosed by
                  law, by any governmental or other regulatory authority or by a
                  court or other authority of competent jurisdiction, provided
                  that, to the extent it is legally permitted to do so, it gives
                  the other party as much notice of such disclosure as possible
                  and, where notice of disclosure is not prohibited and is given
                  in accordance with this clause 10.4, it takes into account the
                  reasonable requests of the other party in relation to the
                  content of such disclosure.
                </li>
                <li>
                  The Client acknowledges that details of the Services, and the
                  results of any performance tests of the Services and the User
                  Data constitutes Mindflick’s Confidential Information.
                </li>
                <li>
                  Mindflick acknowledges that the Client Data is the
                  Confidential Information of the Client.
                </li>
                <li>
                  No party shall make, or permit any person to make, any public
                  announcement concerning this Agreement without the prior
                  written consent of the other parties (such consent not to be
                  unreasonably withheld or delayed), except as required by law,
                  any governmental or regulatory authority (including, without
                  limitation, any relevant securities exchange), any court or
                  other authority of competent jurisdiction.
                </li>
                <li>
                  The above provisions of this clause 10 shall survive
                  termination of this Agreement, however arising.
                </li>
              </ol>
            </li>

            <li>
              indemnity
              <ol>
                <li>
                  The Client shall defend, indemnify and hold harmless Mindflick
                  against claims, actions, proceedings, losses, damages,
                  expenses and costs (including without limitation court costs
                  and reasonable legal fees) arising out of or in connection
                  with the Client’s use of the Services, provided that:
                  <ol>
                    <li>
                      the Client is given prompt notice of any such claim;
                    </li>
                    <li>
                      Mindflick provides reasonable co-operation in the defence
                      and settlement of such claim, at the Client’s expense; and
                    </li>
                    <li>
                      the Client is given sole authority to defend or settle the
                      claim.
                    </li>
                  </ol>
                </li>
                <li>
                  Mindflick shall defend and indemnify the Client, its officers,
                  directors and employees (subject to clause 12.3(b)) against
                  any claim that the Client’s use of the Services in accordance
                  with this Agreement, and excluding any Client Data and User
                  Data, infringes any United Kingdom patent effective as of the
                  Commencement Date and shall indemnify the Client for any
                  amounts awarded against the Client in judgment or settlement
                  of such claims, provided that:
                  <ol>
                    <li>Mindflick is given prompt notice of any such claim;</li>
                    <li>
                      the Client does not make any admission, or otherwise
                      attempt to compromise or settle the claim and provide
                      Mindflick with reasonable co-operation in the defence and
                      settlement of such claim, at Mindflick’s expense; and
                    </li>
                    <li>
                      Mindflick is given sole authority to defend or settle the
                      claim.
                    </li>
                  </ol>
                </li>
                <li>
                  In the defence or settlement of any claim, Mindflick may
                  procure the right for the Client to continue using the
                  Services, replace or modify the Services so that they become
                  non-infringing or, if such remedies are not reasonably
                  available, terminate this Agreement on 2 Business Days' notice
                  to the Client without any additional liability or obligation
                  to pay liquidated damages or other additional costs to the
                  Client.
                </li>
                <li>
                  In no event shall Mindflick, its employees, agents and
                  sub-contractors be liable to the Client to the extent that the
                  alleged infringement is based on:
                  <ol>
                    <li>
                      a modification of the Software by anyone other than
                      Mindflick; or
                    </li>
                    <li>
                      the Client’s use of the Services in a manner contrary to
                      this Agreement and/or Mindflick’s instructions to the
                      Client; or
                    </li>
                    <li>
                      the Client’s use of the Services after notice of the
                      alleged or actual infringement from Mindflick or any
                      appropriate authority.
                    </li>
                  </ol>
                </li>
                <li>
                  The foregoing states the Client’s sole and exclusive rights
                  and remedies, and Mindflick’s (including Mindflick’s
                  employees', agents’, and sub-contractors') entire obligations
                  and liability, for infringement of any UK patent.
                </li>
              </ol>
            </li>

            <li>
              Limitation of liability
              <ol>
                <li>
                  Except as expressly and specifically provided in this
                  Agreement:
                  <ol>
                    <li>
                      the Client assumes sole responsibility for results
                      obtained from the use of the Services, and for conclusions
                      drawn from such use. Mindflick shall have no liability for
                      any damage caused by errors or omissions in any
                      information, instructions or scripts provided to Mindflick
                      by the Client in connection with the Services, or any
                      actions taken by Mindflick at the Client’s direction;
                    </li>
                    <li>
                      all warranties, representations, conditions, and all other
                      terms of any kind whatsoever implied by statute or common
                      law are, to the fullest extent permitted by applicable
                      law, excluded from this Agreement; and
                    </li>
                    <li>
                      the Services are provided to the Client on an "as is"
                      basis.
                    </li>
                  </ol>
                </li>
                <li>
                  Nothing in this Agreement excludes the liability of Mindflick:
                  <ol>
                    <li>
                      for death or personal injury caused by Mindflick’s
                      negligence;
                    </li>
                    <li>for fraud or fraudulent misrepresentation; or</li>
                    <li>any other liability that cannot be excluded at law.</li>
                  </ol>
                </li>
                <li>
                  Subject to clause 12.1 and clause 12.2:
                  <ol>
                    <li>
                      Mindflick shall not be liable whether in tort (including
                      for negligence or breach of statutory duty), contract,
                      misrepresentation, restitution or otherwise for any:
                      <ol>
                        <li>loss of profits,</li>
                        <li>loss of business,</li>
                        <li>depletion of goodwill,</li>
                        <li>
                          similar losses or loss or corruption of data or
                          information,
                        </li>
                        <li>pure economic loss, and/or</li>
                        <li>
                          for any special, indirect or consequential loss,
                          costs, damages, charges or expenses however arising
                          under this Agreement; and
                        </li>
                      </ol>
                    </li>
                    <li>
                      Mindflick’s total aggregate liability in contract
                      (including in respect of the indemnity at clause 11.2),
                      tort (including negligence or breach of statutory duty),
                      misrepresentation, restitution or otherwise, arising in
                      connection with the performance or contemplated
                      performance of this Agreement shall be limited to the
                      total Subscription Fees paid for the User Subscriptions
                      during the 12 months immediately preceding the date on
                      which the claim arose.
                    </li>
                  </ol>
                </li>
                <li>
                  Nothing in this Agreement excludes the liability of the Client
                  for any breach, infringement, or misappropriation of
                  Mindflick’s intellectual property rights.
                </li>
              </ol>
            </li>

            <li>
              Term and termination
              <ol>
                <li>
                  This Agreement shall start on the Commencement Date and shall
                  continue for the Initial Subscription Term and, thereafter,
                  this Agreement shall be automatically renewed for successive
                  periods of 12 months (each a “Renewal Period”), unless:
                  <ol>
                    <li>
                      either party notifies the other party of termination, in
                      writing, at least 45 days before the end of the Initial
                      Subscription Term or any Renewal Period, in which case
                      this Agreement shall terminate upon the expiry of the
                      applicable Initial Subscription Term or Renewal Period; or
                    </li>
                    <li>
                      otherwise terminated in accordance with the provisions of
                      this Agreement; and the Initial Subscription Term together
                      with any subsequent Renewal Periods shall constitute the
                      “Subscription Term”.
                    </li>
                  </ol>
                </li>
                <li>
                  Without affecting any other right or remedy available to it,
                  either party may terminate this Agreement with immediate
                  effect by giving written notice to the other party if:
                  <ol>
                    <li>
                      the other party fails to pay any amount due under this
                      Agreement on the due date for payment and remains in
                      default not less than 7 calendar days after being notified
                      in writing to make such payment;
                    </li>
                    <li>
                      the other party commits a material breach of any other
                      term of these Conditions and (if such breach is
                      remediable) fails to remedy that breach within a period of
                      28 days after being notified in writing to do so; or
                    </li>
                    <li>suffers an Insolvency Event.</li>
                  </ol>
                </li>
                <li>
                  On termination of this Agreement for any reason:
                  <ol>
                    <li>
                      all licences granted under this Agreement shall
                      immediately terminate and the Client shall immediately
                      cease all use of the Services;
                    </li>
                    <li>
                      each party shall return and make no further use of any
                      equipment, property, documentation, and other items (and
                      all copies of them) belonging to the other party;
                    </li>
                    <li>
                      Mindflick may destroy or otherwise dispose of any of the
                      Client Data and/or User Data in its possession unless
                      Mindflick receives, no later than ten days after the
                      effective date of the termination of this Agreement, a
                      written request for the delivery to the Client of the then
                      most recent back-up of the Client Data and User Data.
                      Mindflick shall use reasonable commercial endeavours to
                      deliver the back-up to the Client within 30 days of its
                      receipt of such a written request, provided that the
                      Client has at that time, paid all fees and charges
                      outstanding at and resulting from termination (whether or
                      not due at the date of termination). The Client shall pay
                      all reasonable expenses incurred by Mindflick in returning
                      or disposing of Client Data and User Data; and
                    </li>
                    <li>
                      any rights, remedies, obligations or liabilities of the
                      parties that have accrued up to the date of termination,
                      including the right to claim damages in respect of any
                      breach of the Agreement which existed at or before the
                      date of termination shall not be affected or prejudiced.
                    </li>
                  </ol>
                </li>
              </ol>
            </li>

            <li>
              Non-solicitation
              <ol>
                <li>
                  During the Subscription Term and for 12 months after its
                  expiry or termination, the Client will not:
                  <ol>
                    <li>
                      solicit, or seek to solicit, any of Mindflick’s personnel;
                      or
                    </li>
                    <li>
                      entice, or seek to entice, any of Mindflick’s personnel to
                      transfer their services away from Mindflick; or
                    </li>
                    <li>
                      employ or contract with any of Mindflick’s personnel.
                    </li>
                  </ol>
                </li>
                <li>
                  For the purposes of clause 14.1 the terms “solicit” and
                  “entice” do not include searches for employees though general
                  recruitment efforts or that are not otherwise focused on
                  persons employed by Mindflick.
                </li>
                <li>
                  In the event that the Client breaches clause 14.1(c), then the
                  Client agrees to pay Mindflick an amount equal to 150% of that
                  person’s salary and benefits package, which the Client and
                  Mindflick both agree is a genuine pre-estimate of the actual
                  losses that Mindflick shall suffer if the Client breaches this
                  clause.
                </li>
              </ol>
            </li>

            <li>
              General
              <ol>
                <li>
                  Variation - No variation of this Agreement shall be effective
                  unless it is in writing and signed by a duly authorised
                  representatives on behalf of the parties.
                </li>
                <li>
                  Waiver - No failure or delay by a party to exercise any right
                  or remedy provided under this Agreement or by law shall
                  constitute a waiver of that or any other right or remedy, nor
                  shall it prevent or restrict the further exercise of that or
                  any other right or remedy. No single or partial exercise of
                  such right or remedy shall prevent or restrict the further
                  exercise of that or any other right or remedy.
                </li>
                <li>
                  Rights and remedies - Except as expressly provided in this
                  Agreement, the rights and remedies provided under this
                  Agreement are in addition to, and not exclusive of, any rights
                  or remedies provided by law.
                </li>
                <li>
                  Severance - If any provision or part-provision of this
                  Agreement is or becomes invalid, illegal, or unenforceable, it
                  shall be deemed deleted, but that shall not affect the
                  validity of the rest of this Agreement. If any provision or
                  part-provision of this Agreement is deemed deleted under
                  clause this clause the parties shall negotiate in good faith
                  to agree a replacement provision that, to the greatest extent
                  possible, achieves the intended commercial result of the
                  original provision.
                </li>
                <li>
                  Entire agreement - This Agreement constitutes the entire
                  agreement between the parties and supersedes and extinguishes
                  all previous agreements, promises, assurances, warranties,
                  representations, and understandings between them, whether
                  written or oral, relating to its subject matter. Each party
                  acknowledges that in entering into this Agreement it does not
                  rely on any statement, representation, assurance, or warranty
                  (whether made innocently or negligently) that is not set out
                  in this Agreement. Nothing in this clause will be interpreted
                  or construed as limiting or excluding the liability of any
                  person for fraud or fraudulent misrepresentation.
                </li>
                <li>
                  Assignment - The Client shall not, without the prior written
                  consent of Mindflick, assign, transfer, charge, sub-contract,
                  or deal in any other manner with all or any of its rights or
                  obligations under this Agreement.
                </li>
                <li>
                  No partnership or agency - Nothing in this Agreement is
                  intended to or shall operate to create a partnership between
                  the parties or authorise either party to act as agent for the
                  other, and neither party shall have the authority to act in
                  the name or on behalf of or otherwise to bind the other in any
                  way (including, but not limited to, the making of any
                  representation or warranty, the assumption of any obligation
                  or liability and the exercise of any right or power).
                </li>
                <li>
                  Third party rights - This Agreement does not confer any rights
                  on any person or party (other than the parties to this
                  Agreement and, where applicable, their successors and
                  permitted assigns) pursuant to the Contracts (Rights of Third
                  Parties) Act 1999.
                </li>
                <li>
                  Counterparts - This Agreement may be executed in any number of
                  counterparts, each of which shall constitute a duplicate
                  original, but all the counterparts shall together constitute
                  the one agreement.
                </li>
                <li>
                  Governing law - This Agreement and any dispute or claim
                  arising out of or in connection with it or its subject matter
                  or formation (including non-contractual disputes or claims)
                  shall be governed by and construed in accordance with the law
                  of England and Wales.
                </li>
                <li>
                  Jurisdiction - Each party irrevocably agrees that the courts
                  of England and Wales shall have exclusive jurisdiction to
                  settle any dispute or claim arising out of or in connection
                  with this Agreement or its subject matter or formation
                  (including non-contractual disputes or claims).
                </li>
              </ol>
            </li>
          </ol>
          <hr />
          <h2>SCHEDULE 1 - Definitions and Interpretation</h2>
          <p>
            The following definitions and rules of interpretation apply in this
            Agreement:
          </p>
          <div>
            <div>
              <h5>App</h5>
              <p>
                the Mindflick mobile application, version number 1.0, including
                any updates or supplements made to it;
              </p>
            </div>
            <div>
              <h5>Authorised User(s)</h5>
              <p>
                those employees, agents and independent contractors of the
                Client who are authorised by the Client to use the Services and
                for whom the Client purchased User Subscriptions;
              </p>
            </div>
            <div>
              <h5>Business Day</h5>
              <p>
                a day other than a Saturday, Sunday or public holiday in England
                when banks in London are open for business;
              </p>
            </div>
            <div>
              <h5>Commencement Date</h5>
              <p>the date of this Agreement;</p>
            </div>
            <div>
              <h5>Confidential Information</h5>
              <p>
                information that is proprietary or confidential and is either
                clearly labelled as such, confidential in nature or identified
                as confidential in accordance with clause 10.5;
              </p>
            </div>
            <div>
              <h5>Client Data</h5>
              <p>
                the data inputted by the Client or Mindflick on the Client's
                behalf for the purpose of using the Services or facilitating the
                Client's use of the Services;
              </p>
            </div>
            <div>
              <h5>Data Protection Legislation</h5>
              <p>
                all applicable data protection and privacy legislation in force
                from time to time in the UK, as amended from time to time,
                including UK GDPR (defined below); the Data Protection Act 2018;
                the Privacy and Electronic Communications Directive 2002/58/EC
                (as updated by Directive 2009/136/EC) and the Privacy and
                Electronic Communications Regulations 2003 (SI 2003/2426);
              </p>
            </div>

            <div>
              <h5>Initial Subscription Term</h5>
              <p>12 months from the Commencement Date;</p>
            </div>
            <div>
              <h5>Insolvency Event</h5>
              <ol class="letter-list">
                <li>
                  the other party suspends, or threatens to suspend, payment of
                  its debts or is unable to pay its debts as they fall due or
                  admits inability to pay its debts or is deemed unable to pay
                  its debts within the meaning of section 123 of the Insolvency
                  Act 1986;
                </li>
                <li>
                  the other party commences negotiations with all or any class
                  of its creditors with a view to rescheduling any of its debts,
                  or makes a proposal for or enters into any compromise or
                  arrangement with its creditors;
                </li>
                <li>
                  the other party applies to court for, or obtains, a moratorium
                  under Part A1 of the Insolvency Act 1986;
                </li>
                <li>
                  a petition is filed, a notice is given, a resolution is
                  passed, or an order is made, for or in connection with the
                  winding up of that other party;
                </li>
                <li>
                  an application is made to court, or an order is made, for the
                  appointment of an administrator, or if a notice of intention
                  to appoint an administrator is given or if an administrator is
                  appointed;
                </li>
                <li>
                  the other party suspends or ceases, or threatens to suspend or
                  cease, carrying on all or a substantial part of its business;
                  or
                </li>
                <li>
                  the other party's financial position deteriorates so far as to
                  reasonably justify the opinion that its ability to give effect
                  to the terms of this Agreement is in jeopardy.
                </li>
              </ol>
            </div>
            <div>
              <h5>Normal Business Hours</h5>
              <p>9.00 am to 5.30 pm local UK time, each Business Day;</p>
            </div>
            <div>
              <h5>Order Form</h5>
              <p>
                the written order setting out the commercial details on the
                Services agreed by the parties and which forms part of this
                Agreement;
              </p>
            </div>
            <div>
              <h5>Platform</h5>
              <p>
                Mindflick’s software platform that will be used to provide the
                Services. Renewal Period the period described in clause 13.1;
              </p>
            </div>
            <div>
              <h5>Services</h5>
              <p>
                the access to and use of the Software and/or Coaching provided
                by Mindflick to the Client under this Agreement; Software
                Spotlight, the Mindflick Platform and mobile App provided by
                Mindflick as part of the Services, including any amendments or
                modifications made to it;
              </p>
            </div>
            <div>
              <h5>Spotlight</h5>
              <p>
                Mindflick’s personality profiling tool that will be used to
                provide the Services; Subscription Fees the subscription fees
                payable by the Client to Mindflick for the User Subscriptions,
                as set out in the Order Form;
              </p>
            </div>
            <div>
              <h5>Subscription Term</h5>
              <p>
                has the meaning given in clause 13.1 (being the Initial
                Subscription Term together with any subsequent Renewal Periods);
                UK GDPR the retained EU law version of Regulation (EU) 2016/679
                of the European Parliament and of the Council on the protection
                of natural persons with regard to the processing of personal
                data and on the free movement of such data as it forms part of
                the law of England and Wales, Scotland and Northern Ireland by
                virtue of section 3 of the European Union (Withdrawal) Act of
                2018 and as amended by Schedule 1 to the Data Protection,
                Privacy and Electronic Communications Regulations (EU Exit) SI
                2019/419;
              </p>
            </div>
            <div>
              <h5>User Data</h5>
              <p>content submitted by an Authorised User on the Software;</p>
            </div>
            <div>
              <h5>User Subscriptions</h5>
              <p>
                the subscriptions purchased by the Client pursuant to the Order
                Form which entitle Authorised Users to access and use the
                Services in accordance with this Agreement;
              </p>
            </div>
            <div>
              <h5>Virus</h5>
              <p>
                any thing or device (including any software, code, file or
                programme) which may: prevent, impair or otherwise adversely
                affect the operation of any computer software, hardware,
                application or network, any telecommunications service,
                equipment or network or any other service or device; prevent,
                impair or otherwise adversely affect access to or the operation
                of any programme or data, including the reliability of any
                programme or data (whether by re-arranging, altering or erasing
                the programme or data in whole or part or otherwise) or
                adversely affect the user experience, including worms, trojan
                horses, viruses and other similar things or devices; and
              </p>
            </div>
            <div>
              <h5>Vulnerability</h5>
              <p>
                a weakness in the computational logic (for example, code) found
                in software and hardware components that when exploited, results
                in a negative impact to the confidentiality, integrity, or
                availability, and the term Vulnerabilities shall be construed
                accordingly.
              </p>
            </div>
          </div>
          <hr />
          <p>
            A person includes an individual, corporate, or unincorporated body
            (whether or not having separate legal personality) and that person's
            legal and personal representatives, successors or permitted assigns.
          </p>
          <p>
            Unless the context otherwise requires, words in the singular shall
            include the plural and, in the plural, shall include the singular. A
            reference to a statute or statutory provision shall include all
            subordinate legislation made as at the Commencement Date under that
            statute or statutory provision.
          </p>
          <p>A reference to writing or written includes email.</p>
        </div>
      </div>
    </div>
  </div>
  <div class="shadow-container"></div>
</div>
