import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { SuperAdminService } from 'src/app/shared/services/super-admin.service';
import { ConnectionWheelGroupByOptionsEnum } from '../../shared/models';
import * as AdminActions from './admin.actions';
import { BsModalService } from 'ngx-bootstrap/modal';

@Injectable()
export class AdminEffects {
  constructor(
    private actions$: Actions,
    private superAdminService: SuperAdminService,
    private modalService: BsModalService
  ) {}

  getEmailTemplates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.GetEmailTemplates.Request),
      mergeMap((action) =>
        this.superAdminService.getEmailTemplates().pipe(
          map((resp) =>
            AdminActions.GetEmailTemplates.Success({ templates: resp })
          ),
          catchError((error) =>
            of(AdminActions.GetEmailTemplates.Fail({ error }))
          )
        )
      )
    )
  );

  sendPlatformEmails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.SendPlatformEmails.Request),
      mergeMap((action) =>
        this.superAdminService
          .sendEmails(action.emailCsv, action.emailTemplate)
          .pipe(
            map((resp) => AdminActions.SendPlatformEmails.Success()),
            catchError((error) =>
              of(AdminActions.SendPlatformEmails.Fail({ error }))
            )
          )
      )
    )
  );

  getUsersForUserManagement$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.GetUserManagementUsers.Request),
      mergeMap((action) =>
        this.superAdminService
          .getUsersForUserManagement(action.mindflickAccountId)
          .pipe(
            map((resp) =>
              AdminActions.GetUserManagementUsers.Success({ users: resp })
            ),
            catchError((error) =>
              of(AdminActions.GetUserManagementUsers.Fail({ error }))
            )
          )
      )
    )
  );

  sendSpotlightInvites$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.SendSpotlightInvites.Request),
      mergeMap((action) =>
        this.superAdminService
          .sendSpotlightInvites(
            action.userAks,
            action.sendSignUpEmailImmediately
          )
          .pipe(
            map((resp) =>
              AdminActions.SendSpotlightInvites.Success({
                userAks: action.userAks,
              })
            ),
            catchError((error) =>
              of(AdminActions.SendSpotlightInvites.Fail({ error }))
            )
          )
      )
    )
  );

  resendSpotlightInvites$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.ResendSpotlightInvites.Request),
      mergeMap((action) =>
        this.superAdminService.resendSpotlightInvites(action.userAks).pipe(
          map((resp) => AdminActions.ResendSpotlightInvites.Success()),
          catchError((error) =>
            of(AdminActions.ResendSpotlightInvites.Fail({ error }))
          )
        )
      )
    )
  );

  sendNudgesImmediately$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.SendNudgesImmediately.Request),
      mergeMap((action) =>
        this.superAdminService.sendNudgesImmediately(action.userAks).pipe(
          map((resp) =>
            AdminActions.SendNudgesImmediately.Success({
              userAks: action.userAks,
            })
          ),
          catchError((error) =>
            of(AdminActions.SendNudgesImmediately.Fail({ error }))
          )
        )
      )
    )
  );

  upsertEngagementResourceCategory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.UpsertEngagementResourceCategory.Request),
      mergeMap((action) =>
        this.superAdminService
          .upsertEngagementResourceCategory(action.category)
          .pipe(
            map((resp) =>
              AdminActions.UpsertEngagementResourceCategory.Success({
                category: resp,
              })
            ),
            catchError((error) =>
              of(AdminActions.UpsertEngagementResourceCategory.Fail({ error }))
            )
          )
      )
    )
  );

  upsertEngagementResourceSubCategory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.UpsertEngagementResouceSubCategory.Request),
      mergeMap((action) =>
        this.superAdminService
          .upsertEngagementResourceSubCategory(action.subCategory)
          .pipe(
            map((resp) =>
              AdminActions.UpsertEngagementResouceSubCategory.Success({
                subCategory: resp,
              })
            ),
            catchError((error) =>
              of(
                AdminActions.UpsertEngagementResouceSubCategory.Fail({ error })
              )
            )
          )
      )
    )
  );

  saveEngagementResource$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.SaveEngagementResource.Request),
      mergeMap((action) =>
        this.superAdminService
          .saveEngagementResource(action.engagementResource)
          .pipe(
            map((resp) =>
              AdminActions.SaveEngagementResource.Success({
                engagementResource: resp,
              })
            ),
            catchError((error) =>
              of(AdminActions.SaveEngagementResource.Fail({ error }))
            )
          )
      )
    )
  );

  saveHelpCenterArticle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.SaveHelpCenterArticle.Request),
      mergeMap((action) =>
        this.superAdminService.saveHelpCenterArticle(action.article).pipe(
          map((resp) =>
            AdminActions.SaveHelpCenterArticle.Success({ article: resp })
          ),
          catchError((error) =>
            of(AdminActions.SaveHelpCenterArticle.Fail({ error }))
          )
        )
      )
    )
  );

  loadMindflickAccountConnections$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.GetMindflickAccountConnections.Request),
      mergeMap((action) =>
        this.superAdminService
          .getMindflickAccountConnections(
            action.mindflickAccountId,
            ConnectionWheelGroupByOptionsEnum[action.groupBy]
          )
          .pipe(
            map((resp) =>
              AdminActions.GetMindflickAccountConnections.Success({
                connections: resp,
              })
            ),
            catchError((error) =>
              of(AdminActions.GetMindflickAccountConnections.Fail({ error }))
            )
          )
      )
    )
  );

  assignUserSpecificWorkOns$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.AssignUserSpecificWorkOns.Request),
      mergeMap((action) =>
        this.superAdminService
          .assignUserSpecificWorkOns(action.userAk, action.workOnIds)
          .pipe(
            map((resp) =>
              AdminActions.AssignUserSpecificWorkOns.Success({
                userAk: action.userAk,
                workOnIds: action.workOnIds,
              })
            ),
            catchError((error) =>
              of(AdminActions.AssignUserSpecificWorkOns.Fail({ error }))
            )
          )
      )
    )
  );

  updateUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.UpdateUser.Request),
      mergeMap((action) =>
        this.superAdminService.updateUser(action.updatedUser).pipe(
          map((resp) => {
            this.modalService.hide(action.modalRef)
            return AdminActions.UpdateUser.Success({
              updatedUser: action.updatedUser,
            });
          }
          ),
          catchError((error) => of(AdminActions.UpdateUser.Fail({ error })))
        )
      )
    )
  );


}
