import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject, filter, takeUntil } from 'rxjs';
import { AppState } from 'src/app/+state/app.state';
import { SetSubMenuSmallPopout } from '../../../+state/layout/actions/layout.actions';
import { SubMenuSmallPopoutEnum } from '../../../+state/layout/reducers/layout.reducers';

import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  ReflectOnNudge,
  ReflectOnPreviousNudge,
} from '../../../+state/growth/growth.actions';
import {
  selectDashboardNudge,
  selectPreviousNudge,
} from '../../../+state/growth/growth.selector';
import {
  faBackSVG,
  faCloseSVG,
  faNextSVG,
  faReflectDidntSVG,
  faReflectNotUsefulSVG,
  faReflectUsefulSVG,
} from '../../../../icons';
import { isNonNull } from '../../helpers/rxjs-type-guards';
import { ConnectionStatus } from '../../models/enums/connection-status.enum';
import {
  AdditionalReflectionOptions,
  DashboardNudgeDTO,
  DidntExtra,
  NotUsefulExtra,
  NudgeReviewPromptEnum,
  ReflectEnum,
  UsefulExtra,
} from '../../models/nudge-dto.interface';

@Component({
  selector: 'app-nudge-reflection-popup',
  templateUrl: './nudge-reflection-popup.component.html',
  styleUrls: ['./nudge-reflection-popup.component.scss'],
})
export class NudgeReflectionPopupComponent implements OnInit, OnDestroy {
  destroyed$: Subject<boolean> = new Subject<boolean>();

  nudge$ = this.store.select(selectDashboardNudge);
  previousNudge$ = this.store.select(selectPreviousNudge);

  fgReflection = new FormGroup({
    primaryReflection: new FormControl<ReflectEnum | null>(
      null,
      Validators.required
    ),
    additionalReflection: new FormControl<AdditionalReflectionOptions[] | null>(
      null,
      [Validators.required, Validators.minLength(1)]
    ),
  });

  nudge?: DashboardNudgeDTO;
  backgroundUrl = '';

  dayOfWeek = new Date().getDay();
  SUNDAY = 0;
  FRIDAY = 5;
  isPreviousNudge =
    this.dayOfWeek < this.FRIDAY && this.dayOfWeek > this.SUNDAY;

  isReflectionSet = false;
  showError = false;
  showNudgeDetails = false;
  showAdditionalReflections = false;

  close = faCloseSVG;
  back = faBackSVG;
  next = faNextSVG;

  useful = faReflectUsefulSVG;
  notUseful = faReflectNotUsefulSVG;
  didnt = faReflectDidntSVG;

  ConnectionStatus = ConnectionStatus;
  primaryReflectionEnum = ReflectEnum.Unknown;
  reflectionOptions = ReflectEnum;

  additionalOptions: { imgUrl: string; text: string }[] = [];
  additionalReflectionEnum = AdditionalReflectionOptions.Unknown;
  additionalReflectionOptions = AdditionalReflectionOptions;
  selectedAdditionalOption: number[] = [];

  ADDITIONAL_RELFECTION_USEFUL = [
    {
      imgUrl: './assets/svg/dashboard/reflect_genuine_impact.svg',
      text: 'Genuine impact',
    },
    {
      imgUrl: './assets/svg/dashboard/reflect_highly_relevant.svg',
      text: 'Highly relevant',
    },
    {
      imgUrl: './assets/svg/dashboard/reflect_easy_to_do.svg',
      text: 'Easy to do',
    },
  ];
  ADDITIONAL_RELFECTION_NOT_USEFUL = [
    {
      imgUrl: './assets/svg/dashboard/reflect_not_relevant.svg',
      text: 'Not relevant',
    },
    {
      imgUrl: './assets/svg/dashboard/reflect_difficult_to_apply.svg',
      text: 'Difficult to apply',
    },
    {
      imgUrl: './assets/svg/dashboard/reflect_not_the_right_time.svg',
      text: 'Poorly timed',
    },
  ];
  ADDITIONAL_RELFECTION_DIDNT = [
    {
      imgUrl: './assets/svg/dashboard/reflect_not_relevant.svg',
      text: 'Not relevant',
    },
    {
      imgUrl: './assets/svg/dashboard/reflect_forgot.svg',
      text: 'Forgot to do it',
    },
    {
      imgUrl: './assets/svg/dashboard/reflect_notime.svg',
      text: "Didn't have time",
    },
  ];

  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    this.nudge$
      .pipe(takeUntil(this.destroyed$), filter(isNonNull))
      .subscribe((nudge) => {
        if (!this.isPreviousNudge) {
          this.nudge = nudge;
          this.backgroundUrl = !!nudge?.squareImageUrl
            ? '"' + encodeURI(nudge.squareImageUrl) + '"'
            : '"' + encodeURI(nudge?.imageUrl!) + '"';
        }
      });

    this.previousNudge$
      .pipe(takeUntil(this.destroyed$), filter(isNonNull))
      .subscribe((nudge) => {
        if (this.isPreviousNudge) {
          this.nudge = nudge;
          this.backgroundUrl = !!nudge?.squareImageUrl
            ? '"' + encodeURI(nudge.squareImageUrl) + '"'
            : '"' + encodeURI(nudge?.imageUrl!) + '"';
        }
      });
  }

  setPrimaryReflection(reflection: ReflectEnum) {
    this.primaryReflection.setValue(reflection);
    this.showError = false;
  }

  submitPreviousNudgeReflection() {
    if (!this.primaryReflection.valid) {
      this.showError = true;
      return;
    }

    var reflectedNudge = {
      ...this.nudge,
      primaryReflection: this.primaryReflection.value,
      nudgeReviewPrompt: NudgeReviewPromptEnum.Reviewed,
    } as DashboardNudgeDTO;

    this.store.dispatch(
      ReflectOnPreviousNudge.Request({ nudge: reflectedNudge })
    );
  }

  showAdditionalReflection() {
    if (!this.primaryReflection.valid) {
      this.showError = true;
      return;
    }

    this.showAdditionalReflections = true;
    this.setAdditionalReflectionOptions();
  }

  closeReflections() {
    this.store.dispatch(
      SetSubMenuSmallPopout({ subMenuSmallPopout: SubMenuSmallPopoutEnum.None })
    );
  }

  setShowNudgeDetails(showNudgeDetails: boolean) {
    this.showNudgeDetails = showNudgeDetails;
  }

  setAdditionalReflectionOptions() {
    switch (this.primaryReflection.value) {
      case ReflectEnum.Useful:
        this.additionalOptions = this.ADDITIONAL_RELFECTION_USEFUL;
        break;
      case ReflectEnum.NotUseful:
        this.additionalOptions = this.ADDITIONAL_RELFECTION_NOT_USEFUL;
        break;
      default:
        this.additionalOptions = this.ADDITIONAL_RELFECTION_DIDNT;
        break;
    }
  }

  setAdditionalReflection(option: number) {
    if (this.selectedAdditionalOption.includes(option)) {
      this.selectedAdditionalOption = this.selectedAdditionalOption.filter(
        (x) => x != option
      );
    } else {
      this.selectedAdditionalOption = [
        ...this.selectedAdditionalOption,
        option,
      ];
    }

    this.additionalReflection.patchValue(this.selectedAdditionalOption);

    switch (this.primaryReflection.value) {
      case ReflectEnum.Useful:
        switch (option as UsefulExtra) {
          case UsefulExtra.GenuineImpact:
            this.nudge = {
              ...this.nudge!,
              genuineImpact: !this.nudge?.genuineImpact,
            };
            break;
          case UsefulExtra.HighlyRelevant:
            this.nudge = {
              ...this.nudge!,
              highlyRelevant: !this.nudge?.highlyRelevant,
            };
            break;
          case UsefulExtra.EasyToDo:
            this.nudge = {
              ...this.nudge!,
              easyToDo: !this.nudge?.easyToDo,
            };
            break;
          default:
            this.nudge = {
              ...this.nudge!,
              sendMeAgain: !this.nudge?.sendMeAgain,
            };
            break;
        }
        break;
      case ReflectEnum.NotUseful:
        switch (option as NotUsefulExtra) {
          case NotUsefulExtra.NotRelevant:
            this.nudge = {
              ...this.nudge!,
              notRelevant: !this.nudge?.notRelevant,
            };
            break;
          case NotUsefulExtra.DifficultToApply:
            this.nudge = {
              ...this.nudge!,
              difficultToApply: !this.nudge?.difficultToApply,
            };
            break;
          case NotUsefulExtra.PoorlyTimed:
            this.nudge = {
              ...this.nudge!,
              poorlyTimed: !this.nudge?.poorlyTimed,
            };
            break;
          default:
            this.nudge = {
              ...this.nudge!,
              sendMeAgain: !this.nudge?.sendMeAgain,
            };
            break;
        }
        break;
      default:
        switch (option as DidntExtra) {
          case DidntExtra.NotRelevant:
            this.nudge = {
              ...this.nudge!,
              notRelevant: !this.nudge?.notRelevant,
            };
            break;
          case DidntExtra.ForgotToDoIt:
            this.nudge = {
              ...this.nudge!,
              forgotToDoIt: !this.nudge?.forgotToDoIt,
            };
            break;
          case DidntExtra.DidntHaveTime:
            this.nudge = {
              ...this.nudge!,
              didntHaveTime: !this.nudge?.didntHaveTime,
            };
            break;
          default:
            this.nudge = {
              ...this.nudge!,
              sendMeAgain: !this.nudge?.sendMeAgain,
            };
            break;
        }
        break;
    }
  }

  submitCurrentNudgeReflection() {
    if (!this.additionalReflection.valid) {
      this.showError = true;
      return;
    }

    var reflectedNudge = {
      ...this.nudge,
      genuineImpact: this.nudge?.genuineImpact,
      highlyRelevant: this.nudge?.highlyRelevant,
      easyToDo: this.nudge?.easyToDo,
      sendMeAgain: this.nudge?.sendMeAgain,
      notRelevant: this.nudge?.notRelevant,
      difficultToApply: this.nudge?.difficultToApply,
      poorlyTimed: this.nudge?.poorlyTimed,
      forgotToDoIt: this.nudge?.forgotToDoIt,
      didntHaveTime: this.nudge?.didntHaveTime,
      primaryReflection: this.primaryReflection.value,
      nudgeReviewPrompt: NudgeReviewPromptEnum.Reviewed,
    } as DashboardNudgeDTO;

    this.store.dispatch(ReflectOnNudge.Request({ nudge: reflectedNudge }));
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.unsubscribe();
  }

  get primaryReflection() {
    return this.fgReflection.controls.primaryReflection;
  }

  get additionalReflection() {
    return this.fgReflection.controls.additionalReflection;
  }
}
