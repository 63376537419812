import { HttpClient } from '@angular/common/http';
import { Inject, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ImageCacheService } from '../services/image-cache.service';

@Pipe({
  name: 'secureImage',
})
export class SecureImagePipe implements PipeTransform {
  constructor(
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    @Inject('BASE_URL') private baseUrl: string,
    private imageCacheService: ImageCacheService
  ) {}

  transform(url: string, refreshValue?: number): Observable<SafeUrl> {
    let blob = this.imageCacheService.tryGetImage(url);

    if (blob)
      return of(
        this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(blob))
      );

    return this.http
      .get(`${this.baseUrl}${url}`, { responseType: 'blob' })
      .pipe(
        map((val) => {
          this.imageCacheService.addImage(url, val);

          return this.sanitizer.bypassSecurityTrustUrl(
            URL.createObjectURL(val)
          );
        }),
        catchError(() => {
          console.log('Could not retrieve profile image');

          return of(
            this.sanitizer.bypassSecurityTrustUrl(
              './assets/svg/profile-placeholder.svg'
            )
          );
        })
      );
  }
}
