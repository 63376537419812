import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { faCloseSVG } from '../../../../icons';
import { SelectValue, Team } from '../../models';

@Component({
  selector: 'app-select-team-modal',
  templateUrl: './select-team-modal.component.html',
  styleUrls: ['./select-team-modal.component.scss'],
})
export class SelectTeamModalComponent implements OnInit {
  private _teams: Team[] = [];
  get teams() {
    return this._teams;
  }
  set teams(teams: Team[]) {
    this._teams = teams;
    this.values = teams.map((x) => ({ value: x.id, description: x.name }));
  }

  values: SelectValue[] = [];
  selectedTeamId?: number;

  @Output()
  teamSelected = new EventEmitter<Team>();

  closeIcon = faCloseSVG;

  constructor(private modalRef: BsModalRef) {}

  ngOnInit(): void {}

  select() {
    if (this.selectedTeamId) {
      this.teamSelected.emit(
        this.teams.find((x) => x.id === this.selectedTeamId)
      );

      this.modalRef.hide();
    }
  }

  close() {
    this.teamSelected.emit(undefined);

    this.modalRef.hide();
  }
}
