<div *ngIf="alert" class="position-absolute snackbar">
  <alert
         [type]="alert.alertType"
         [dismissible]="alert.isDismissible!"
         [dismissOnTimeout]="alert.timeout!"
         (onClosed)="onClosed(alert)"
         >
        <strong *ngIf="alert.messageHeader" class="pe-1">{{alert.messageHeader}}</strong>{{alert.message}}
  </alert>
</div>
