import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject, takeUntil, take } from 'rxjs';
import { AddCoordinatorToAccount } from 'src/app/+state/account-admin/account-admin.actions';
import { selectAddCoordinatorsOptions, selectSelectedAccountSettingsAccount } from 'src/app/+state/account-admin/account-admin.selector';
import { AppState } from 'src/app/+state/app.state';
import { SelectValue } from 'src/app/shared/models';
import { faCloseSVG } from 'src/icons';

@Component({
  selector: 'app-add-account-coordinator-modal',
  templateUrl: './add-account-coordinator-modal.component.html',
  styleUrls: ['./add-account-coordinator-modal.component.scss']
})
export class AddAccountCoordinatorModalComponent {
  selectedAccount$ = this.store.select(selectSelectedAccountSettingsAccount);
  coordinatorOptions$ = this.store.select(selectAddCoordinatorsOptions);

  coordinatorOptions: SelectValue[] = [];

  selectedUserAk?: number;

  closeIcon = faCloseSVG;

  destroyed$ = new Subject<boolean>();

  constructor(private store: Store<AppState>, private modalRef: BsModalRef) {}

  ngOnInit(): void {
    this.coordinatorOptions$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(coordinators => {
        this.coordinatorOptions = coordinators.map(c => ({
          value: c.ak!,
          description: c.firstName + ' ' + c.lastName + ' (' + c.email + ')'
        }));

        this.selectedUserAk = undefined;
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.unsubscribe();
  }

  addCoordinator() {
    if (!this.selectedUserAk)
      return;

    this.selectedAccount$
      .pipe(take(1))
      .subscribe(account => {
        if (account) 
          this.store.dispatch(AddCoordinatorToAccount.Request({ accountId: account.accountId, userAk: this.selectedUserAk! }));
      });
  }

  close() {
    this.modalRef.hide();
  }
}
