<label *ngIf="label" class="form-label my-2" [class.form-label-light]="light">{{
  label
}}</label>
<div class="input-group">
  <div class="search-icon d-flex align-items-center justify-content-center">
    <fa-icon class="text-dark fa-lg" [icon]="search"></fa-icon>
  </div>
  <input
    #currentsearch
    [placeholder]="placeholder"
    class="form-control mb-0"
    [class.form-control-light]="light"
    [(ngModel)]="value"
    [disabled]="disabled"
    [value]="manualValue"
  />
</div>
