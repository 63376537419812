<div class="modal-header justify-content-end">
  <fa-icon
    class="cursor-pointer fa-lg"
    [icon]="closeIcon"
    (click)="close()"
  ></fa-icon>
</div>
<div class="modal-body">
  <h2 class="mb-4">{{ title }}</h2>
  <div class="mb-4" (click)="onCopyToClipboard()">
    <div class="d-flex cursor-pointer">
      <input
        type="text"
        class="form-control link-input cursor-pointer"
        [(ngModel)]="link"
        placeholder="{{ link }}"
        readonly
      />
      <div
        class="clipboard-button d-flex align-items-center justify-content-center"
      >
        <fa-icon class="text-white" [icon]="clipboard"></fa-icon>
      </div>
    </div>
  </div>
  <button class="btn btn-purple w-100" (click)="close()">Okay</button>
</div>
<div class="modal-bottom-border"></div>
