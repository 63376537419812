<div class="legal-container">
  <div class="legal-page pb-3 bg-white pt-4">
    <div class="legal-content d-flex flex-column">
      <div class="d-flex pt-5 pb-4 align-items-center header">
        <app-btn-action
          class="legal-close fa-lg top"
          [icon]="close"
          [iconDimensions]="35"
          (click)="closePopup()"
        ></app-btn-action>
      </div>
      <div class="legal-item-container overflow-auto">
        <div class="legal-content-container w-75">
          <h1 class="pb-4">Data Policy</h1>
          <h2>Introduction</h2>
          <p>
            At Mindflick we take our responsibilities for Data Protection and
            confidentiality very seriously. It is the responsibility of all
            staff to uphold our company values, ethics and policies in relation
            to stakeholder data and information and to abide by the General Data
            Protection Regulation (GDPR) regulations.
          </p>
          <div>
            <p>
              All staff must be clear about their responsibilities relating to:
            </p>
            <ul>
              <li>
                What constitutes personal data in the company and how this is
                processed
              </li>

              <li>
                Our legal and ethical responsibility and the risk to individuals
                and the company
              </li>

              <li>
                Where to seek further information and guidance in relation to
                data protection and our legal responsibilities
              </li>

              <li>
                What happens if we have concerns about data management and if a
                data breach occurs
              </li>
            </ul>
          </div>
          <p>
            We expect all staff to demonstrate respect and confidentiality for
            all company and stakeholder data. Breach of confidentiality can have
            a significant impact on company reputation, relationship management
            and risk future business. Additionally, for those Mindflick staff who
            are Chartered Psychologists, we must uphold our professional Ethics
            and Code of Conduct with the British Psychological Society,
            <a href="https://cms.bps.org.uk/sites/default/files/2022-06/BPS%20Code%20of%20Ethics%20and%20Conduct.pdf" target="_blank">found here.</a>
          </p>
          <h2>GDPR Principles</h2>
          <p>
            When collecting, processing, storing and destroying data, all staff
            must be mindful of the principles of good data protection as
            identified in GDPR Regulations (Data Protection Act 2018):
          </p>
          <ul>
            <li>
              processed lawfully, fairly and in a transparent manner in relation
              to individuals (‘lawfulness, fairness and transparency’);
            </li>

            <li>
              collected for specified, explicit and legitimate purposes and not
              further processed in a manner that is incompatible with those
              purposes; further processing for archiving purposes in the public
              interest, scientific or historical research purposes or
              statistical purposes shall not be considered to be incompatible
              with the initial purposes (‘purpose limitation’);
            </li>

            <li>
              adequate, relevant and limited to what is necessary in relation to
              the purposes for which they are processed (‘data minimisation’);
            </li>

            <li>
              accurate and, where necessary, kept up to date; every reasonable
              step must be taken to ensure that personal data that are
              inaccurate, having regard to the purposes for which they are
              processed, are erased or rectified without delay (‘accuracy’);
            </li>

            <li>
              kept in a form which permits identification of data subjects for
              no longer than is necessary for the purposes for which the
              personal data are processed; personal data may be stored for
              longer periods insofar as the personal data will be processed
              solely for archiving purposes in the public interest, scientific
              or historical research purposes or statistical purposes subject to
              implementation of the appropriate technical and organisational
              measures required by the GDPR in order to safeguard the rights and
              freedoms of individuals (‘storage limitation’);
            </li>

            <li>
              processed in a manner that ensures appropriate security of the
              personal data, including protection against unauthorised or
              unlawful processing and against accidental loss, destruction or
              damage, using appropriate technical or organisational measures
              (‘integrity and confidentiality’).
            </li>
          </ul>
          <p>
            Further information can be found at:
            <a href="https://ico.org.uk/media/for-organisations/guide-to-data-protection/guide-to-the-general-data-protection-regulation-gdpr-1-1.pdf">Guide to the GDPR Regulations</a>,
            <a href="https://www.gov.uk/data-protection">Data Protection</a>
          </p>

          <h2>Scope</h2>
          <h2>What is personal data?</h2>
          <p>
            Personal data is information that relates to an identified or
            identifiable individual. If it is possible to identify an individual
            directly from the information you are processing, then that
            information is classed as personal data if this ‘relates’ to an
            individual.
          </p>
          <p>
            If you cannot directly identify an individual from that information,
            then you need to consider whether the individual is still
            identifiable. You should take into account the information you are
            processing together with all the means reasonably likely to be used
            by either you or any other person to identify that individual.
          </p>
          <p>
            Where the information ‘relates to’ an individual, you need to
            consider the content of the information, the purpose for processing
            the data and the likely impact of how we process that data and the
            event of a breach of information.
          </p>
          <p>
            Information which has had identifiers removed or replaced in order
            to pseudonymise the data is still personal data for the purposes of
            UK GDPR. Whereas fully anonymised information is not covered by the
            UK GDPR.
          </p>
          <h2>Who does the data relate to?</h2>
          <p>
            This policy relates to any individual whose data we come into
            contact with or process on behalf of the company. Data we process
            and retain will relate to staff, clients and third-party data (for
            further information, refer to the related Privacy Policies).
          </p>
          <p>
            Third parties include business to business relationships, agencies
            and governing bodies.
          </p>
          <p>
            Third parties also include participants who provide data for the
            purposes of our products, ie. Spotlight Practitioners and end-users.
            Please see the Spotlight Privacy Policy for participants and the
            Practitioner Agreement responsibilities for conduct and Data
            Protection.
          </p>
          <p>
            Spotlight Practitioner Administrators are required to sign a GDPR
            and confidentiality agreement on behalf of the Practitioner company.
            For further information, please refer to the GDPR Officer for
            process and authorisation details.
          </p>
          <h2>Processing and storing information</h2>
          <p>
            Individual policies and agreements referred to detail the way in
            which the data is processed.
          </p>
          <p>
            The processing of data can relate to written, printed, verbal or
            electronic data and our confidentiality we apply to all levels of
            personal data and company data for our stakeholders.
          </p>
          <p>
            We retain data only for the purposes of delivering our business.
            Once that data is no longer required for the purpose of our business
            or a relationship with a stakeholder, or at the request of the
            individual, we are required to destroy the data.
          </p>
          <p>
            Any request from an individual to destroy data or information
            related to our business with them, must be in writing and brought to
            the attention of the company Data Protection Lead.
          </p>
          <p>
            Some information is required to be retained for statutory or
            regulatory requirements. Please see the Data Retention Procedure for
            more information.
          </p>
          <h2>Where is your data stored and how do we keep it secure?</h2>
          <p>
            Your data is stored on secure servers located in the United Kingdom.
          </p>
          <p>
            We take the security of your data and the accessibility to our
            systems very seriously. The UK based Data Centre's that we use are
            compliant with the ISO 27018 standard for cloud privacy. With the
            main Data Centres located in London and Cardiff, data is stored
            within the boundaries of UK laws. Full testing has been performed to
            ensure that these act in a failover pair, thus again ensuring data
            can reside within the boundaries of the UK even during a Data Centre
            outage.
          </p>
          <p>
            By submitting your personal data, you agree to this. We take steps
            to ensure that the information we collect is processed according to
            this Privacy Statement and the requirements of applicable law
            wherever the data is located.
          </p>
          <h2>What Happens in the Event of a Data Breach?</h2>
          <p>
            Mindflick have a procedure in place if there is a breach of security
            leading to the accidental or unlawful destruction, loss, alteration,
            unauthorised disclosure of, or access to, personal data transmitted,
            stored or otherwise processed.
          </p>
          <p>
            In such cases, we will assess the scope and impact of the breach.
            Based on this assessment and the likely risks to the rights and
            freedoms of individuals, we will notify the individuals and/or their
            connected organisations that a data breach has occurred.
          </p>
          <p>
            Any such notification to individuals will be carried out as soon as
            reasonably possible and will include information on the nature of
            the breach. Such individuals will also be provided advice on how to
            make a complaint to the UK Information Commissioner's Office (ICO).
          </p>
          <p>
            As Mindflick Holdings Ltd is a UK company we are legally required to
            register with the UK ICO, an independent authority set up to uphold
            data privacy. The ICO is internationally recognised as being at the
            forefront of the protection of personal information. If, due to the
            nature of a data breach Mindflick is required to inform the ICO, we
            will do so within 72 hours of becoming aware of the essential facts
            of the breach.
          </p>
          <h2>Freedom of Information and Subject Access Requests</h2>
          <h2>Freedom of Information Request (FOI)</h2>
          <div>
            <p>
              Anyone has a right to request information from a public authority.
              Our duty when responding to these requests is:
            </p>

            <ul>
              <li>
                to tell the applicant whether you hold any information falling
                within the scope of their request; and
              </li>

              <li>to provide that information</li>
            </ul>
            <p>
              We will normally respond within 20 working days to a request or if
              a request will take longer to respond, we will inform the
              applicant of the reasons and provide a timescale. All requests
              must be made in writing to the Data Protection Officer.
            </p>
            <p>
              The provisions of the Freedom of Information Act come into force
              only if:
            </p>

            <ul>
              <li>
                you cannot provide the requested information straight away; or
              </li>

              <li>
                the requester makes it clear they expect a response under the
                Act
              </li>
            </ul>

            <p>
              We will abide by the recommendations provided by the ICO. More
              guidance can be found
              <a
                href="https://ico.org.uk/for-organisations/guide-to-freedom-of-information/receiving-a-request/"
                >here.</a
              >
            </p>
          </div>
          <h2>Subject Access Request (SAR)</h2>
          <p>
            Individuals have the right to access and receive a copy of their
            personal data, and other supplementary information.
          </p>
          <p>
            Individuals can make SARs verbally or in writing, including via
            social media. Any requests must be brought to the attention of the
            Data Protection Officer. A third party is able to submit an SAR on
            behalf of another person.
          </p>
          <p>
            Requests are processed as priority and responded to no later than
            one month from request or up to three months if the request is
            complex or multiple requests are received from the individual.
          </p>
          <p>
            We will abide by the recommendations provided by the ICO. More
            guidance can be found
            <a href="https://ico.org.uk/your-data-matters/your-right-to-get-copies-of-your-data/">here.</a>
          </p>
          <h2>
            Points of contact and for more information on company policy and
            requirements
          </h2>
          <p>
            For further information or in the event of concerns or data breach,
            immediately notify the company Data Protection Manager, Dr Pete
            Lindsay (Director) and Data Protection Officer Alison Cook,
            (Business Operations Manager).
          </p>
          <h2>References:</h2>
          <ul>
            <li>Information Security Policy</li>
            <li>Data Retention Procedure</li>
            <li>Risk Management Policy</li>
            <li>Business Continuity Planning</li>
            <li>Management Information Audit</li>
            <li>All privacy notices; Spotlight, Staff and Third Party</li>
            <li>All Data Protection Impact Assessments</li>
            <li>
              All procedures relating to authorised release of information such
              as Freedom of Information, Financial, Insurance.
            </li>
          </ul>
          <p class="m-0"><b>Mark Bawden</b></p>
          <p class="m-0"><b>CEO Mindflick</b></p>
          <p class="m-0"><b>2023</b></p>
        </div>
      </div>
    </div>
  </div>
  <div class="shadow-container"></div>
</div>
