import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import {
  catchError,
  filter,
  map,
  mergeMap,
  withLatestFrom,
} from 'rxjs/operators';
import { TeamService } from 'src/app/shared/services/team.service';
import * as LayoutActions from '../layout/actions/layout.actions';
import { RemoveNotification } from '../signalr/signalr.actions';
import * as TeamActions from './team.actions';
import { Router } from '@angular/router';

@Injectable()
export class TeamEffects {
  saveMyTeamDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamActions.SaveTeamDetails.Request),
      mergeMap((action) =>
        this.teamService.saveTeamDetails(action.payload).pipe(
          mergeMap((resp) => {
            // For the time being always refresh the team spotlights while Tim is making edits to the content
            if (resp.isError) {
              if (resp.message === 'Team is name not unique') {
                return [
                  TeamActions.CreateNewTeam.Fail({
                    error: 'Team name is not unique',
                  }),
                ];
              }
            }
            return [
              TeamActions.GenerateMyTeamSpotlights.Request({
                teamId: action.payload.teamId,
              }),
              TeamActions.SaveTeamDetails.Success({
                payload: action.payload,
                response: resp,
              }),
              LayoutActions.DisplaySnackbarAlert.SetAlert({
                alert: {
                  alertType: 'success',
                  messageHeader: 'Success!',
                  message: `You updated ${resp.result?.name}.`,
                },
              })
            ];
          }),
          catchError((error) =>
            of(TeamActions.SaveTeamDetails.Fail({ error })),
          ),
        ),
      ),
    ),
  );

  generateMyTeamSpotlights$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamActions.GenerateMyTeamSpotlights.Request),
      mergeMap((action) =>
        this.teamService.generateTeamSpotlights(action.teamId).pipe(
          map((resp) =>
            TeamActions.GenerateMyTeamSpotlights.Success({
              teamId: action.teamId,
              resp: resp,
            }),
          ),
          catchError((error) =>
            of(TeamActions.GenerateMyTeamSpotlights.Fail({ error })),
          ),
        ),
      ),
    ),
  );

  createNewTeam$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamActions.CreateNewTeam.Request),
      mergeMap((action) =>
        this.teamService.createNewTeam(action.payload).pipe(
          mergeMap((resp) => {
            if (resp.uniqueTeamNameError)
              return [
                TeamActions.CreateNewTeam.Fail({
                  error: 'Team name is not unique',
                }),
              ];

            return action.payload.membersToAdd.length > 0
              ? [
                  TeamActions.GenerateMyTeamSpotlights.Request({
                    teamId: resp.id,
                  }),
                  TeamActions.CreateNewTeam.Success({
                    response: resp,
                    imageFormData: action.imageFormData,
                  }),
                  LayoutActions.DisplaySnackbarAlert.SetAlert({
                    alert: {
                      alertType: 'success',
                      messageHeader: 'Success!',
                      message: `You created a team: ${resp.name}.`,
                    },
                  }),
                ]
              : [
                  TeamActions.CreateNewTeam.Success({ response: resp }),
                  LayoutActions.DisplaySnackbarAlert.SetAlert({
                    alert: {
                      alertType: 'success',
                      messageHeader: 'Success!',
                      message: `You created a team: ${resp.name}.`,
                    },
                  }),
                ];
          }),
          catchError((error) => of(TeamActions.CreateNewTeam.Fail({ error }))),
        ),
      ),
    ),
  );

  createTeamSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamActions.CreateNewTeam.Success),
      mergeMap((action) => {
        if (action.imageFormData) {
          return [
            TeamActions.ChangeTeamPicture.Request({
              teamId: action.response.id,
              formData: action.imageFormData,
            }),
          ];
        } else this.router.navigate([`/teams/${action.response.id}`]);
        return [];
      }),
    ),
  );

  changeTeamPicture$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamActions.ChangeTeamPicture.Success),
      mergeMap((action) => {
        this.router.navigate([`/teams/${action.teamId}`]);
        return [];
      }),
      catchError((error) => of(TeamActions.ChangeTeamPicture.Fail({ error }))),
    ),
  );

  updateTeamDefaultImageId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamActions.UpdateTeamDefaultImageId.Request),
      mergeMap((action) =>
        this.teamService.updateTeamImageId(action.teamId, action.newImageId).pipe(
          map((resp) =>
            TeamActions.UpdateTeamDefaultImageId.Success({
              teamId: action.teamId,
              newImageId: action.newImageId,
            }),
          ),
          catchError((error) =>
            of(TeamActions.UpdateTeamDefaultImageId.Fail({ error })),
          ),
        ),
      ),
    ),
  );

  uploadTeamPhoto$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamActions.ChangeTeamPicture.Request),
      mergeMap((action) =>
        this.teamService.setTeamPhoto(action.teamId, action.formData).pipe(
          map((resp) =>
            TeamActions.ChangeTeamPicture.Success({
              photoLastModified: resp,
              teamId: action.teamId,
            }),
          ),
          catchError((error) =>
            of(TeamActions.ChangeTeamPicture.Fail({ error })),
          ),
        ),
      ),
    ),
  );

  acceptTeamRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamActions.PutAcceptedDate.Request),
      mergeMap((action) =>
        this.teamService.setAcceptedDate(action.teamId).pipe(
          mergeMap((resp) =>
            action.notificationId
              ? [
                  RemoveNotification.Request({
                    notificationId: action.notificationId,
                  }),
                  TeamActions.GenerateMyTeamSpotlights.Request({
                    teamId: action.teamId,
                  }),
                  TeamActions.PutAcceptedDate.Success({ team: resp }),
                  LayoutActions.DisplaySnackbarAlert.SetAlert({
                    alert: {
                      alertType: 'success',
                      messageHeader: 'Success!',
                      message: `You're now a member of ${resp.name}.`,
                    },
                  }),
                ]
              : [
                  TeamActions.GenerateMyTeamSpotlights.Request({
                    teamId: action.teamId,
                  }),
                  TeamActions.PutAcceptedDate.Success({ team: resp }),
                  LayoutActions.DisplaySnackbarAlert.SetAlert({
                    alert: {
                      alertType: 'success',
                      messageHeader: 'Success!',
                      message: `You're now a member of ${resp.name}.`,
                    },
                  }),
                ],
          ),
          catchError((error) =>
            of(TeamActions.PutAcceptedDate.Fail({ error })),
          ),
        ),
      ),
    ),
  );

  declineTeamRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamActions.DeleteMember.Request),
      mergeMap((action) =>
        this.teamService.deleteTeamMember(action.team.id).pipe(
          mergeMap((resp) =>
            action.notificationId
              ? [
                  RemoveNotification.Request({
                    notificationId: action.notificationId,
                  }),
                  TeamActions.DeleteMember.Success({ teamId: action.team.id }),
                  LayoutActions.DisplaySnackbarAlert.SetAlert({
                    alert: {
                      alertType: 'info',
                      messageHeader: '',
                      message: `You've declined to join ${action.team.name}.`,
                    },
                  }),
                ]
              : [
                  TeamActions.GenerateMyTeamSpotlights.Request({
                    teamId: action.team.id,
                  }),
                  TeamActions.DeleteMember.Success({ teamId: action.team.id }),
                  LayoutActions.DisplaySnackbarAlert.SetAlert({
                    alert: {
                      alertType: 'info',
                      messageHeader: '',
                      message: `You've declined to join ${action.team.name}`,
                    },
                  }),
                ],
          ),
          catchError((error) => of(TeamActions.DeleteMember.Fail({ error }))),
        ),
      ),
    ),
  );

  cancelTeamRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamActions.CancelRequestToJoinTeam.Request),
      mergeMap((action) =>
        this.teamService.cancelTeamRequest(action.team.id).pipe(
          mergeMap((resp) => [
            TeamActions.CancelRequestToJoinTeam.Success({ team: action.team }),
            LayoutActions.DisplaySnackbarAlert.SetAlert({
              alert: {
                alertType: 'info',
                messageHeader: '',
                message: `You have cancelled your request to join ${action.team.name}`,
              },
            }),
          ]),
        ),
      ),
    ),
  );

  leaveTeam$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamActions.LeaveTeam.Request),
      mergeMap((action) =>
        this.teamService.leaveTeam(action.teamId).pipe(
          map((resp) => {
            return TeamActions.LeaveTeam.Success({ team: resp });
          }),
          catchError((error) => of(TeamActions.LeaveTeam.Fail({ error }))),
        ),
      ),
    ),
  );

  deleteTeam$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamActions.DeleteTeam.Request),
      mergeMap((action) =>
        this.teamService.deleteTeam(action.teamId).pipe(
          map(() => {
            return TeamActions.DeleteTeam.Success({ teamId: action.teamId });
          }),
          catchError((error) => of(TeamActions.DeleteTeam.Fail({ error }))),
        ),
      ),
    ),
  );

  addExerciseToTeam$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamActions.AddExerciseToTeam.Request),
      mergeMap((action) =>
        this.teamService.addExerciseToTeam(action.payload).pipe(
          map((resp) =>
            TeamActions.AddExerciseToTeam.Success({ response: resp }),
          ),
          catchError((error) =>
            of(TeamActions.AddExerciseToTeam.Fail({ error })),
          ),
        ),
      ),
    ),
  );

  removeExerciseFromTeam$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamActions.RemoveExerciseFromTeam.Request),
      mergeMap((action) =>
        this.teamService.removeExerciseFromTeam(action.payload).pipe(
          map((resp) =>
            TeamActions.RemoveExerciseFromTeam.Success({
              payload: action.payload,
            }),
          ),
          catchError((error) =>
            of(TeamActions.RemoveExerciseFromTeam.Fail({ error })),
          ),
        ),
      ),
    ),
  );

  requestToJoinTeam$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamActions.RequestToJoinTeam.Request),
      mergeMap((action) =>
        this.teamService.requestToJoinTeam(action.team.id).pipe(
          mergeMap((resp) => {
            let actions: any[] = [
              LayoutActions.DisplaySnackbarAlert.SetAlert({
                alert: {
                  alertType: 'success',
                  messageHeader: 'Success!',
                  message: `Requested to join ${action.team.name}.`,
                },
              }),
              TeamActions.RequestToJoinTeam.Success({ team: action.team }),
            ];
            if (resp)
              actions.push(
                TeamActions.UpdateTeamWithJoinRequestAccepted({ team: resp }),
              );
            return actions;
          }),
          catchError((error) =>
            of(TeamActions.RequestToJoinTeam.Fail({ error })),
          ),
        ),
      ),
    ),
  );

  declineRequestToJoinTeam$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamActions.DeclineRequestToJoinTeam.Request),
      mergeMap((action) =>
        this.teamService
          .declineRequestToJoin(action.userAk, action.teamId)
          .pipe(
            map((resp) =>
              TeamActions.DeclineRequestToJoinTeam.Success({
                userAk: action.userAk,
                teamId: action.teamId,
              }),
            ),
            catchError((error) =>
              of(TeamActions.DeclineRequestToJoinTeam.Fail({ error })),
            ),
          ),
      ),
    ),
  );

  acceptRequestToJoinTeam$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamActions.AcceptRequestToJoinTeam.Request),
      mergeMap((action) =>
        this.teamService.acceptRequestToJoin(action.userAk, action.teamId).pipe(
          map((resp) =>
            TeamActions.AcceptRequestToJoinTeam.Success({
              team: resp,
            }),
          ),
          catchError((error) =>
            of(TeamActions.AcceptRequestToJoinTeam.Fail({ error })),
          ),
        ),
      ),
    ),
  );

  getAllTeamsForAdmin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamActions.GetAllTeamsForAdmin.Request),
      mergeMap((action) =>
        this.teamService.getAllTeamsForAdmin().pipe(
          map((resp) =>
            TeamActions.GetAllTeamsForAdmin.Success({ teams: resp }),
          ),
          catchError((error) => of(TeamActions.GetAllTeamsForAdmin.Fail({ error }))),
        ),
      ),
    ),
  );
  

  updateTeamMembers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamActions.UpdateTeamMembers.Request),
      mergeMap((action) =>
        this.teamService.updateTeamMembers(action.teamId, action.adminIds, action.memberIds).pipe(
          mergeMap((resp) => [
            TeamActions.UpdateTeamMembers.Success({
              team: resp
            }),
            LayoutActions.DisplaySnackbarAlert.SetAlert({
              alert: {
                alertType: 'success',
                messageHeader: 'Success!',
                message: 'Team members updated successfully',
              },
            }),
          ]),
          catchError((error) =>
            of(TeamActions.UpdateTeamMembers.Fail({ error })),
          ),
        ),
      ),
    ),
  );

  updateTeamMechanics$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamActions.UpdateTeamMechanics.Request),
      mergeMap((action) =>
        this.teamService
          .updateTeamMechanics(
            action.teamId,
            action.purposes,
            action.foci,
            action.principles,
          )
          .pipe(
            mergeMap((resp) => [
              TeamActions.UpdateTeamMechanics.Success({ ...action }),
              LayoutActions.DisplaySnackbarAlert.SetAlert({
                alert: {
                  alertType: 'success',
                  messageHeader: 'Success!',
                  message: 'Team mechanics updated successfully',
                },
              }),
            ]),
            catchError((error) =>
              of(TeamActions.UpdateTeamMechanics.Fail({ error })),
            ),
          ),
      ),
    ),
  );

  downloadTeamMap$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamActions.DownloadTeamMap.Request),
      mergeMap((action) =>
        this.teamService.downloadTeamMap(action.teamId, action.teamName).pipe(
          map((resp) => TeamActions.DownloadTeamMap.Success({ teamMap: resp })),
          catchError((error) =>
            of(TeamActions.DownloadTeamMap.Fail({ error })),
          ),
        ),
      ),
    ),
  );

  downloadTeamMapAlertRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamActions.DownloadTeamMap.Request),
      map(() =>
        LayoutActions.DisplaySnackbarAlert.SetAlert({
          alert: {
            alertType: 'info',
            messageHeader: 'Processing...',
            message: `Your team map is being generated.`,
            timeout: 30 * 1000,
          },
        }),
      ),
    ),
  );

  handleTeamMapProcessing$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamActions.DownloadTeamMap.MapProcessingUpdate),
      filter((x) => x.teamMap.token != null),
      mergeMap((action) =>
        this.teamService.getTeamMapFromToken(action.teamMap.token).pipe(
          map((resp) =>
            TeamActions.DownloadTeamMap.GetTeamMapFromToken({
              token: action.teamMap.token,
            }),
          ),
          catchError((error) =>
            of(TeamActions.DownloadTeamMap.Fail({ error })),
          ),
        ),
      ),
    ),
  );

  getAllTeams$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamActions.GetAllTeams.Request),
      mergeMap((action) =>
        this.teamService.getAllTeams().pipe(
          map((resp) => TeamActions.GetAllTeams.Success({ teams: resp })),
          catchError((error) => of(TeamActions.GetAllTeams.Fail({ error }))),
        ),
      ),
    ),
  );

  getTeam$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamActions.GetTeam.Request),
      mergeMap((action) =>
        this.teamService.getTeam(action.teamId).pipe(
          map((resp) => TeamActions.GetTeam.Success({ team: resp })),
          catchError((error) => of(TeamActions.GetTeam.Fail({ error }))),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private teamService: TeamService,
    private router: Router,
  ) {}
}
