import { ErrorHandler, Injectable, NgModule } from '@angular/core';
import { Router } from '@angular/router';
import {
  AngularPlugin,
  ApplicationinsightsAngularpluginErrorService,
} from '@microsoft/applicationinsights-angularplugin-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class Insights {
  private angularPlugin = new AngularPlugin();
  public appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: environment.appInsights.instrumentationKey,
      enableAutoRouteTracking: false, //This is handled by the angular plugin
      extensions: [this.angularPlugin as any],
      extensionConfig: {
        [this.angularPlugin.identifier]: {
          router: this.router,
          errorServices: [new ErrorHandler()],
        },
      },
    },
  });

  // expose methods that can be used in components and services
  trackEvent = this.appInsights.trackEvent;
  trackTrace = this.appInsights.trackTrace;

  constructor(private router: Router) {
    this.appInsights.loadAppInsights();
    this.appInsights.addTelemetryInitializer(function (envelope) {
      if (!envelope.baseData) return;

      var telemetryItem = envelope.baseData;

      telemetryItem.properties = telemetryItem.properties || {};
      if (!environment.production)
        telemetryItem.properties['DeveloperMode'] = true;
      telemetryItem.properties['UIVersion'] = environment.version;
    });
  }
}

@NgModule({
  providers: [
    Insights,
    {
      provide: ErrorHandler,
      useClass: ApplicationinsightsAngularpluginErrorService,
    },
  ],
})
export class InsightsModule {}
