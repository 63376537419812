import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Store } from "@ngrx/store";
import { AppState } from "../+state/app.state";
import { Observable } from "rxjs";
import { selectIsSuperAdmin } from "../+state/user/user.selector";

@Injectable({ providedIn: 'root' })
export class SuperAdminGuard  {
    constructor(private store: Store<AppState>) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.store.select(selectIsSuperAdmin);
    }
}