<div class="modal-header justify-content-end">
    <fa-icon
      class="cursor-pointer fa-lg"
      [icon]="closeIcon"
      (click)="close()"
    ></fa-icon>
</div>
<div class="modal-body">
    <h2>Add Champion</h2>
    <h3 *ngIf="selectedAccount$ | async as account" class="mt-2">Select a champion to add to the {{ account.name }} account.</h3>
    <div class="mt-4">
        <label class="form-label">Select champion</label>
        <app-dropdown-select [options]="championOptions" [(ngModel)]="selectedUserAk" placeholder="Select champion"></app-dropdown-select>
    </div>
    <button class="btn btn-purple mt-4 w-100" (click)="addChampion()">Add Champion</button>
</div>
<div class="modal-bottom-border"></div>