import { Component, inject, OnDestroy, OnInit } from "@angular/core";
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import { Store } from "@ngrx/store";
import { BsModalService } from "ngx-bootstrap/modal";
import { Subject, Subscription, filter, take, takeUntil, withLatestFrom } from "rxjs";
import {
  GetAccountChampions,
  GetAccountCoordinators,
  GetAccountSettingsAccounts,
  GetAccountSettingsAccountsForOrgAdmin,
  GetMindflickAccountSettings,
  RemoveChampionFromAccount,
  RemoveCoordinatorFromAccount,
  SetMindflickAccountSettings,
  SetSelectedAccountSettingsAccountId,
} from "src/app/+state/account-admin/account-admin.actions";
import {
  selectAccountSettings,
  selectAccountSettingsAccounts,
  selectAccountSettingsChampions,
  selectAccountSettingsCoordinators,
  selectSelectedAccountSettingsAccount,
  selectSelectedAccountSettingsAccountId,
} from "src/app/+state/account-admin/account-admin.selector";
import { selectCurrentAccountId } from "src/app/+state/account/account.selector";
import { AppState } from "src/app/+state/app.state";
import { MindflickAccountBasicDetails, UserBasicDetails } from "src/app/shared/models";
import {
  AccountSettings,
} from "src/app/shared/models/account-admin-managed-users-DTO";
import { DialogService } from "src/app/shared/services/dialog.service";
import { faAddSVG, faCloseSVG, faDropdownSVG, faEditSVG } from "src/icons";
import { AddAccountChampionModalComponent } from "../add-account-champion-modal/add-account-champion-modal.component";
import { AddAccountCoordinatorModalComponent } from "../add-account-coordinator-modal/add-account-coordinator-modal.component";
import { RbacService } from "src/app/shared/services/rbac.service";
import { MindflickRoleEnum } from "src/app/shared/models/enums/mindflick-role.enum";
import { MindflickPermissionEnum } from "src/app/shared/models/enums/mindflick-permission.enum";

@Component({
  selector: "app-account-settings",
  templateUrl: "./account-settings.component.html",
  styleUrls: ["./account-settings.component.scss"],
})
export class AccountSettingsComponent implements OnInit, OnDestroy {
  rbacService = inject(RbacService);

  destroyed$: Subject<boolean> = new Subject<boolean>();

  settings$ = this.store.select(selectAccountSettings);
  accountSettingsAccounts$ = this.store.select(selectAccountSettingsAccounts);
  selectedAccountId$ = this.store.select(selectSelectedAccountSettingsAccountId);
  selectedAccount$ = this.store.select(selectSelectedAccountSettingsAccount);
  currentUserAccountId$ = this.store.select(selectCurrentAccountId);

  accountChampions$ = this.store.select(selectAccountSettingsChampions);
  accountCoordinators$ = this.store.select(selectAccountSettingsCoordinators);

  settings?: AccountSettings;
  selectedAccount?: MindflickAccountBasicDetails;
  selectedAccountId?: number;

  questionIcon = faQuestionCircle;
  addIcon = faAddSVG;
  editIcon = faEditSVG;
  dropdownIcon = faDropdownSVG;
  closeIcon = faCloseSVG;

  currentAccountChampionsAccessToAnalytics: boolean = false;
  currentAccountChampionsSeatAllocation: boolean = false;
  currentAccountDefaultSpotlightAccess: boolean = false;

  canEditAccountSettings: boolean = false;
  canEditChampions: boolean = false;
  canEditCoordinators: boolean = false;

  constructor(
    private store: Store<AppState>,
    private dialogService: DialogService,
    private modalService: BsModalService
  ) {}

  ngOnInit(): void {
    if (this.rbacService.isGrantedAnyAccount(MindflickRoleEnum.OrgAdmin)) {
      this.store.dispatch(GetAccountSettingsAccountsForOrgAdmin.Request());
    } else {
      this.store.dispatch(GetAccountSettingsAccounts.Request());
    }

    this.selectedAccountId$
      .pipe(takeUntil(this.destroyed$), withLatestFrom(this.accountSettingsAccounts$))
      .subscribe(([accountId, accounts]) => {
        if (accountId && accounts.some(x => x.accountId == accountId)) {
          this.selectedAccountId = accountId;

          this.store.dispatch(
            GetMindflickAccountSettings.Request({ accId: accountId })
          );
          this.store.dispatch(GetAccountChampions.Request({ accountId }));
          this.store.dispatch(GetAccountCoordinators.Request({ accountId }));

          this.canEditAccountSettings = this.rbacService.isGrantedForAccount(MindflickPermissionEnum.EditAccountSettings, this.selectedAccountId);
          this.canEditChampions = this.rbacService.isGrantedForAccount(MindflickPermissionEnum.AssignAccountChampion, this.selectedAccountId);
          this.canEditCoordinators = this.rbacService.isGrantedForAccount(MindflickPermissionEnum.AssignAccountCoordinator, this.selectedAccountId);
        }      
      });

    this.settings$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(settings => {
        if (settings)
          this.settings = { ...settings };
      });
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.unsubscribe();
  }

  canDownloadProfileChanged(canDownloadProfile: boolean) {
    if (this.settings) {
      this.settings.canUsersDownloadSpotlight = canDownloadProfile;
      this.saveSettings();
    }
  }

  championAllocateSeatsChanged(championCanAllocateSeat: boolean) {
    if (this.settings) {
      this.settings.championCanAllocateSeats = championCanAllocateSeat;
      this.saveSettings();
    }
  }

  championAnalyticsChanged(championAnalytics: boolean) {
    if (this.settings) {
      this.settings.canChampionsViewAnalytics = championAnalytics;
      this.saveSettings();
    }
  }

  onAccountChange(accountId: number): void {
    if (accountId)
      this.store.dispatch(SetSelectedAccountSettingsAccountId({ accountId }));
  }

  openAddAccountChampionModal() {
    this.modalService.show(AddAccountChampionModalComponent);
  }

  openAddAccountCoordinatorModal() {
    this.modalService.show(AddAccountCoordinatorModalComponent);
  }

  onRemoveAccountChampionClick(champion: UserBasicDetails) {
    this.dialogService
      .confirm(`Are you sure you wish to revoke champion rights for this account from ${champion.firstName} ${champion.lastName}?`, `Are you sure?`)
      .pipe(take(1))
      .subscribe(result => {
        if (result)
          this.store.dispatch(RemoveChampionFromAccount.Request({ accountId: this.selectedAccountId!, userAk: champion.ak! }));
    });
  }

  onRemoveAccountCoordinatorClick(coordinator: UserBasicDetails) {
    this.dialogService
      .confirm(`Are you sure you wish to revoke coordinator rights for this account from ${coordinator.firstName} ${coordinator.lastName}?`, `Are you sure?`)
      .pipe(take(1))
      .subscribe(result => {
        if (result)
          this.store.dispatch(RemoveCoordinatorFromAccount.Request({ accountId: this.selectedAccountId!, userAk: coordinator.ak! }));
    });
  }
  
  saveSettings() {
    if (this.settings)
      this.store.dispatch(
        SetMindflickAccountSettings.Request({ settings: this.settings })
      );
  }
}
