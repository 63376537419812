<div class="superAdminDropdownContainer position-absolute py-0 text-white">
  <div class="super-admin-list-container" (mouseleave)="hideSuperAdminList()" (mouseenter)="showSuperAdminList()">
    <div class="row">
      <div class="d-flex justify-content-between">
        <h1 class="flex-fill">Administration</h1>
        <div>
          <!--<a [routerLink]="['/super-admin/mindflick-accounts']" (click)="hideSuperAdminList()"
             class="btn btn-orange">
            <fa-icon [icon]="['fas', 'user-gear']"></fa-icon>&nbsp;User Accounts
          </a>-->
          <a (click)="hideSuperAdminList(); showSendEmailsModal();"
             class="btn btn-orange m-2">
            <fa-icon [icon]="['fas', 'envelope']" class="pe-2"></fa-icon>Send Emails
          </a>
          <a (click)="hideSuperAdminList(); openBulkInviteModal()"
             class="btn btn-orange m-2">
            <fa-icon [icon]="['fas', 'envelope']" class="pe-2"></fa-icon>Bulk Invite
          </a>
          <a [routerLink]="['/super-admin/tooltips']" (click)="hideSuperAdminList()"
             class="btn btn-orange m-2">
            <fa-icon [icon]="['fas', 'message']" class="pe-2"></fa-icon>Tooltips
          </a>
          <a [routerLink]="['/super-admin/team-playbooks']" (click)="hideSuperAdminList()"
             class="btn btn-orange m-2">
            <fa-icon [icon]="['fas', 'book']" class="pe-2"></fa-icon>Team Playbooks
          </a>
          <a [routerLink]="['/super-admin/work-ons']" (click)="hideSuperAdminList()"
             class="btn btn-orange">
            <fa-icon [icon]="['fas', 'person-digging']" class="p-2"></fa-icon>Work Ons
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="shadow-container"></div>
</div>
