import { Component, Inject, OnInit } from '@angular/core';

@Component({
  selector: 'app-app-footer',
  templateUrl: './app-footer.component.html',
  styleUrls: ['./app-footer.component.scss'],
})
export class AppFooterComponent implements OnInit {
  privacyPolicyUrl = this.baseUrl + '#/public/privacy-policy';
  termsUrl = this.baseUrl + '#/public/terms';
  cookieNoticeUrl = this.baseUrl + '#/public/cookie-notice';
  glossaryUrl = this.baseUrl + '#/public/glossary';

  constructor(@Inject('BASE_URL') private baseUrl: string) {}

  ngOnInit(): void {}
}
