

<div class="d-flex flex-column h-100 page">
  <app-help-center-header></app-help-center-header>
  <div class="d-flex flex-column category">
    <nav aria-label="breadcrumb"  class="my-4">
      <ol class="breadcrumb">
        <li class="breadcrumb-item body-copy p-0 pe-1 first" (click)="goToHelpCenter()">Help Center</li>
        <li class="breadcrumb-item body-copy pe-1 active">{{category.title}}</li>
      </ol>
    </nav>
    <div class="d-flex flex-column">
      <h2>{{category.title}}</h2>
      <div class="d-flex flow-row">
        <div class="d-flex flex-column col-4">
          <h3 *ngFor="let article of category.articles | slice:0:20" class="my-2 cursor" (click)="goToArticle(article.title)">{{article.title}}</h3>
        </div>
        <div class="d-flex flex-column col-4">
          <h3 *ngFor="let article of category.articles | slice:20:40" class="my-2 cursor">{{article.title}}</h3>
        </div>
        <div class="d-flex flex-column col-4">
          <h3 *ngFor="let article of category.articles | slice:40:60" class="my-2 cursor">{{article.title}}</h3>
        </div>
      </div>
    </div>
  </div>
  <app-help-center-email></app-help-center-email>
  <app-help-center-footer></app-help-center-footer>
</div>
