import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons';
import { Store } from '@ngrx/store';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject, take, takeUntil, withLatestFrom } from 'rxjs';
import {
  AdminClearSelectedUser,
  UpdateUsersDelegatedInvites,
} from 'src/app/+state/account-admin/account-admin.actions';
import {
  selectSeatsInAccData,
  selectSelectedUserAdmin,
  selectSelectedUserManagementAccountId,
} from 'src/app/+state/account-admin/account-admin.selector';
import { AppState } from 'src/app/+state/app.state';
import {
  AccountAdminManagedUsersDTO,
  UpdateUsersDelegatedInvitesModel,
} from 'src/app/shared/models/account-admin-managed-users-DTO';
import { faCloseSVG, faQuestionMarkSVG } from 'src/icons';

@Component({
  selector: 'app-delegate-invites-modal',
  templateUrl: './delegate-invites-modal.component.html',
  styleUrls: ['./delegate-invites-modal.component.scss'],
})
export class DelegateInvitesModalComponent implements OnInit {
  selectedUser$ = this.store.select(selectSelectedUserAdmin);
  seatsInOrg$ = this.store.select(selectSeatsInAccData);
  selectedAccountId$ = this.store.select(selectSelectedUserManagementAccountId);

  destroyed$ = new Subject<boolean>();

  delegateInvitesForm = new FormGroup({
    delegatedInvites: new FormControl<number | undefined>(0),
    delegatedInvitesExpiry: new FormControl<Date | undefined>(undefined),
  });

  closeIcon = faCloseSVG;
  question = faCircleQuestion;

  userAk: number = 0;

  minValue = 0;
  maxValue = 100;

  minDate = new Date();

  constructor(private modalRef: BsModalRef, private store: Store<AppState>) {}

  ngOnInit() {
    this.minDate.setDate(this.minDate.getDate() + 1);
    this.selectedUser$.pipe(withLatestFrom(this.seatsInOrg$),takeUntil(this.destroyed$)).subscribe(([user, seatsInOrg]) => {
      if (user) {
        this.userAk = user.ak;

        this.delegateInvitesForm.setValue({
          delegatedInvites: user.delegatedInvites,
          delegatedInvitesExpiry: user.delegatedInvitesExpiry,
        });

        this.maxValue = user.delegatedInvites + (seatsInOrg?.remainingSeats ?? 0);

        this.delegateInvitesForm.controls.delegatedInvites.addValidators(
          [Validators.min(user.delegatedInvitesUsed), Validators.max(this.maxValue)],
        );

        this.delegateInvitesForm.updateValueAndValidity();

        this.minValue = user.delegatedInvitesUsed;
      }
    });

    this.delegateInvitesForm.controls.delegatedInvites.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        if (value) this.setNegativeToZero(value);
      });
  }

  setNegativeToZero(value: number) {
    if (value < 0) {
      this.delegateInvitesForm.controls.delegatedInvites.setValue(0);
      this.delegateInvitesForm.updateValueAndValidity();
    }
  }

  saveChanges() {
    this.selectedAccountId$.pipe(take(1)).subscribe(accountId => {
      if (accountId) {
        if (this.delegateInvitesForm.invalid) return;

        const payload: UpdateUsersDelegatedInvitesModel = {
          userAk: this.userAk,
          delegatedInvites: this.delegateInvitesForm.value.delegatedInvites ?? 0,
          delegatedInvitesExpiry:
            this.delegateInvitesForm.value.delegatedInvitesExpiry ?? undefined,
        };
    
        if (payload.delegatedInvitesExpiry)
          payload.delegatedInvitesExpiry.setHours(0, 0, 0, 0);
    
        this.store.dispatch(UpdateUsersDelegatedInvites.Request({ payload, accountId }));
    
        this.close();
      }
    });
    
  }

  close() {
    this.store.dispatch(AdminClearSelectedUser());
    this.modalRef.hide();
  }
}
