import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { SuperAdminService } from 'src/app/shared/services/super-admin.service';
import { TooltipService } from '../../shared/services/tooltip.service';
import * as TooltipActions from './tooltip.actions';
import * as UserActions from '../user/user.actions';
import * as TeamActions from '../team/team.actions';
import * as AccountActions from '../account/account.actions';
import * as SignalActions from '../signalr/signalr.actions';
import { selectCurrentUser } from '../user/user.selector';
import { Store } from '@ngrx/store';
import { AppState } from '../app.state';

const toolTipSuccessArray = [
  AccountActions.GetUserMindflickAccount.Request(),
  UserActions.GetAllUsers.Request(),
  SignalActions.GetNotifications.Request(),
  TeamActions.GetAllTeams.Request(),
];
@Injectable()
export class TooltipEffects {
  getAllTooltips$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TooltipActions.GetTooltips.Request),
      mergeMap((action) =>
        this.tooltipService.getAllTooltips().pipe(
          map((resp) =>
            TooltipActions.GetTooltips.Success({
              tooltips: resp.tooltips,
              sections: resp.sections,
            })
          ),
          catchError((error) => of(TooltipActions.GetTooltips.Fail({ error })))
        )
      )
    )
  );

  getAllTooltipsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TooltipActions.GetTooltips.Success),
      withLatestFrom(this.store.select(selectCurrentUser)),
      mergeMap(([action, currentUser]) => {
        return toolTipSuccessArray;
      })
    )
  );

  saveTooltip$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TooltipActions.SaveTooltip.Request),
      mergeMap((action) =>
        this.superAdminService.saveTooltip(action.tooltip).pipe(
          map((resp) => TooltipActions.SaveTooltip.Success({ tooltip: resp })),
          catchError((error) => of(TooltipActions.SaveTooltip.Fail({ error })))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private tooltipService: TooltipService,
    private superAdminService: SuperAdminService,
    private store: Store<AppState>
  ) {}
}
