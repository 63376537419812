<div class="modal-header justify-content-end">
  <fa-icon *ngIf="!shepherdService.isActive"
           class="cursor-pointer fa-lg"
           [icon]="close"
           (click)="hideModal()"></fa-icon>
  <app-btn-tertiary *ngIf="shepherdService.isActive" [icon]="next" (click)="onboardingService.removeStrengthSpottingSteps(); hideModal();">Skip</app-btn-tertiary>
</div>
<div class="modal-body pt-0">
  <h2 class="mb-2">Spot a strength in {{ profile?.firstName }}</h2>
  <h3 class="pt-4">{{ strengthDescription }}.</h3>
  <hr class="my-35"/>
  <div>
    <label class="form-label">
      When did you see {{ profile?.firstName }} use this strength? <br />Describe the situation and the impact it had.

    </label>
    <textarea class="form-control" placeholder="E.g. In last Tuesday’s meeting you did this well when sharing your ideas with the team. This created a lot of excitement in the meeting." [formControl]="fcComment"></textarea>
  </div>
  <div class="my-35 form-check">
    <input type="checkbox" class="form-check-input me-2" [formControl]="fcChecked" />
    <label class="form-check-label">I shared feedback in person</label>
  </div>
  <p *ngIf="fcComment.touched && fcComment.invalid" class="mb-0 mb-2 text-error small-text body-copy">Please either provide some context for {{ profile?.firstName }} or check that you gave feedback in person.</p>
  <button class="btn btn-primary w-100 shepherd-add-strength" (click)="addComment()" [disabled]="!fcComment.valid">Spot strength</button>
</div>
<div class="modal-bottom-border"></div>
