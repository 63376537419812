<svg xmlns="http://www.w3.org/2000/svg"
         id="Layer_1"
         viewBox="0 0 439.88 449.58"
         version="1.1">
        <defs id="defs4">
            <style id="style2">
                .cls-4,
                .cls-5,
                .cls-6 {
                    isolation: isolate;
                }

                .cls-10 {
                    fill: none;
                    stroke: #dadbda;
                }

                .cls-11 {
                    fill: #4c4c4c;
                }

                .cls-11,
                .cls-12,
                .cls-13,
                .cls-14,
                .cls-15,
                .cls-16 {
                    stroke-width: 0px;
                }

                .cls-12 {
                    fill: #878787;
                }

                .cls-13 {
                    fill: #dadbda;
                }
            </style>
        </defs>
        <circle id="Ellipse_882"
                class="cls-14"
                [attr.fill]="selectedLetter === 'O' ? '#ff5f00' : '#666'"
                cx="309.56"
                cy="139.52"
                r="90" />
        <circle id="Ellipse_883"
                class="cls-14"
                [attr.fill]="selectedLetter === 'C' ? '#000000' : '#666'"
                cx="127.99"
                cy="139.48"
                r="90" />
        <circle id="Ellipse_884"
                class="cls-14"
                [attr.fill]="selectedLetter === 'E' ? '#878787' : '#666'"
                cx="309.57"
                cy="321.12"
                r="90" />
        <circle id="Ellipse_885"
                class="cls-14"
                [attr.fill]="selectedLetter === 'P' ? '#7000dd' : '#666'"
                cx="127.84"
                cy="321.12"
                r="90" />
        <line id="Line_857"
              class="cls-10"
              x1="44.71"
              y1="230.32"
              x2="395.71"
              y2="230.32" />
        <line id="Line_855"
              class="cls-10"
              x1="218.71"
              y1="50.47"
              x2="218.71"
              y2="408.47" />
        <path d="m 112.70636,126.10273 q 5.07273,-13.58182 11.45455,-21.27273 6.38182,-7.772727 14.31818,-7.772727 4.99091,0 7.77273,2.7 2.78182,2.699997 3.10909,7.527277 0.40909,4.74545 -1.88182,11.04545 -0.57273,1.71818 -0.9,3.02727 -0.24545,1.30909 -0.24545,2.29091 h -12.43637 q 0,-0.81818 0.16364,-1.63636 0.16363,-0.9 0.57273,-2.04546 l 3.35454,-9.32727 q 0.57273,-1.55454 0.0818,-2.61818 -0.40909,-1.14545 -2.04545,-1.14545 -2.86364,0 -4.41818,4.17272 l -13.99091,38.45455 q -0.73637,2.04545 -0.0818,3.27273 0.65454,1.14545 2.20909,1.14545 1.39091,0 2.20909,-0.9 0.9,-0.9 1.47273,-2.37273 l 2.7,-7.36363 q 0.40909,-1.22728 0.9,-2.12728 0.49091,-0.98181 1.14545,-1.55454 h 11.86364 v 0.81818 q -0.81818,0.9 -1.30909,2.12727 -0.49091,1.22728 -0.9,2.37273 -7.03637,19.22727 -20.20909,19.22727 -3.19091,0 -5.89091,-1.71818 -2.7,-1.8 -4.17273,-5.56364 -1.39091,-3.84545 -0.81818,-9.98182 0.57272,-6.21818 3.84545,-15.05454 z"
              id="path1035"
              class="cursor-pointer"
              fill="#fff" />
        <path d="m 292.31909,131.33 2.12727,-5.72727 q 5.07273,-13.58182 11.37273,-21.27273 6.3,-7.772727 13.99091,-7.772727 3.19091,0 5.89091,1.8 2.7,1.799997 4.09091,5.645457 1.39091,3.84545 0.81818,9.98182 -0.49091,6.13636 -3.76364,14.89091 l -2.12727,5.72727 q -4.99091,13.58182 -11.37273,21.35454 -6.3,7.69091 -13.99091,7.69091 -3.1909,0 -5.8909,-1.71818 -2.7,-1.8 -4.17273,-5.56364 -1.39091,-3.84545 -0.81818,-9.98182 0.57272,-6.21818 3.84545,-15.05454 z m 21.02727,-20.78182 -13.99091,38.45455 q -0.73636,2.04545 -0.0818,3.27273 0.65455,1.14545 2.2091,1.14545 2.78181,0 4.33636,-4.17273 L 319.81,110.79364 q 0.73636,-2.04546 0.0818,-3.19091 -0.57273,-1.22727 -2.20909,-1.22727 -2.78182,0 -4.33636,4.17272 z"
              id="path1017"
              class="cursor-pointer"
              fill="#fff" />
        <path d="m 310.43455,279.47545 h 22.09091 l -3.92728,10.63637 H 318.78 l -5.89091,16.36363 H 321.48 l -3.92727,10.63637 h -8.59091 l -6.21818,17.18182 h 9.81818 L 308.71636,344.93 h -22.09091 z"
              id="path1020"
              class="cursor-pointer"
              fill="#fff" />
        <path d="m 127.78454,279.65546 h 10.96364 q 5.07273,0 8.50909,2.94545 3.43637,2.94545 4.25455,8.18182 0.9,5.23636 -1.63637,12.19091 l -0.57272,1.55454 q -3.35455,9.08182 -9.57273,13.41818 -6.13636,4.25455 -15.13636,4.25455 L 116.24818,345.11 h -12.27273 z m 8.67273,9.81818 -8.34545,22.90909 h 0.65454 q 5.48182,0 7.44546,-5.48182 l 3.51818,-9.65455 q 1.22727,-3.43636 0.49091,-5.56363 -0.73637,-2.20909 -3.19091,-2.20909 z"
              id="path1023"
              class="cursor-pointer"
              fill="#fff" />
        <g id="Task-Oriented" class="cls-6">
            <g aria-label="Contained"
               transform="translate(93.91 188.12)"
               id="text73"
               fill="#fff"
               class="cls-4">
                <path d="M 5.306,0.14 Q 3.99,0.14 2.954,-0.476 1.918,-1.106 1.33,-2.198 0.742,-3.304 0.742,-4.69 q 0,-1.4 0.588,-2.492 0.588,-1.106 1.624,-1.722 1.036,-0.616 2.352,-0.616 1.064,0 1.932,0.406 0.882,0.406 1.456,1.162 0.574,0.742 0.756,1.736 H 7.98 Q 7.756,-7.154 7.042,-7.672 6.328,-8.204 5.292,-8.204 q -0.924,0 -1.638,0.434 -0.7,0.434 -1.092,1.232 -0.392,0.798 -0.392,1.834 0,1.05 0.392,1.848 0.392,0.798 1.092,1.246 0.714,0.434 1.638,0.434 1.05,0 1.764,-0.532 0.728,-0.532 0.938,-1.456 h 1.47 Q 9.282,-2.184 8.694,-1.428 8.12,-0.686 7.238,-0.266 6.356,0.14 5.306,0.14 Z"
                      style="letter-spacing: 0.02em"
                      id="path968" />
                <path d="m 14.335975,0.14 q -0.98,0 -1.75,-0.448 -0.756,-0.448 -1.19,-1.232 -0.434,-0.798 -0.434,-1.806 0,-1.008 0.434,-1.792 0.434,-0.798 1.19,-1.246 0.77,-0.448 1.75,-0.448 0.98,0 1.736,0.448 0.77,0.448 1.204,1.246 0.434,0.784 0.434,1.792 0,1.008 -0.434,1.806 -0.434,0.784 -1.204,1.232 -0.756,0.448 -1.736,0.448 z m -1.974,-3.486 q 0,1.05 0.532,1.68 0.546,0.616 1.442,0.616 0.896,0 1.428,-0.616 0.546,-0.63 0.546,-1.68 0,-1.05 -0.546,-1.666 -0.532,-0.63 -1.428,-0.63 -0.896,0 -1.442,0.63 -0.532,0.616 -0.532,1.666 z"
                      style="letter-spacing: 0.02em"
                      id="path970" />
                <path d="m 19.567999,-6.692 h 1.232 l 0.14,0.798 h 0.098 q 0.728,-0.938 1.988,-0.938 1.148,0 1.806,0.714 0.672,0.714 0.672,1.946 V 0 h -1.372 v -3.976 q 0,-0.756 -0.42,-1.204 -0.406,-0.462 -1.092,-0.462 -0.77,0 -1.232,0.504 -0.448,0.49 -0.448,1.344 V 0 h -1.372 z"
                      id="path972" />
                <path d="m 29.978001,0 q -0.868,0 -1.302,-0.406 -0.434,-0.42 -0.434,-1.302 v -3.864 h -1.54 v -1.12 h 0.714 q 0.602,0 0.784,-0.322 0.182,-0.322 0.182,-1.064 v -0.7 h 1.232 v 2.086 h 1.834 v 1.12 h -1.834 v 3.64 q 0,0.392 0.196,0.574 0.196,0.182 0.686,0.182 h 0.952 V 0 Z"
                      style="letter-spacing: 0.02em"
                      id="path974" />
                <path d="m 35.339988,0.14 q -1.05,0 -1.708,-0.574 -0.658,-0.574 -0.658,-1.484 0,-0.952 0.714,-1.498 0.714,-0.56 1.974,-0.56 h 1.764 v -0.336 q 0,-0.686 -0.392,-1.022 -0.392,-0.35 -1.106,-0.35 -1.274,0 -1.484,1.232 h -1.26 q 0.084,-1.078 0.812,-1.722 0.742,-0.658 1.96,-0.658 1.344,0 2.072,0.63 0.742,0.616 0.742,1.75 v 2.744 q 0,0.224 0.154,0.364 0.168,0.126 0.434,0.126 h 0.168 V 0 h -0.392 q -0.616,0 -1.022,-0.238 -0.406,-0.238 -0.574,-0.7 h -0.098 q -0.63,1.078 -2.1,1.078 z m -1.05,-2.156 q 0,0.462 0.336,0.742 0.336,0.266 0.924,0.266 0.84,0 1.358,-0.476 0.518,-0.49 0.518,-1.274 v -0.154 h -1.694 q -0.728,0 -1.092,0.21 -0.35,0.21 -0.35,0.686 z"
                      style="letter-spacing: 0.02em"
                      id="path976" />
                <path d="m 41.256001,-6.692 h 1.372 V 0 h -1.372 z m -0.042,-2.842 h 1.456 v 1.47 h -1.456 z"
                      style="letter-spacing: 0.01em"
                      id="path978" />
                <path d="m 44.888001,-6.692 h 1.232 l 0.14,0.798 h 0.098 q 0.728,-0.938 1.988,-0.938 1.148,0 1.806,0.714 0.672,0.714 0.672,1.946 V 0 h -1.372 v -3.976 q 0,-0.756 -0.42,-1.204 -0.406,-0.462 -1.092,-0.462 -0.77,0 -1.232,0.504 -0.448,0.49 -0.448,1.344 V 0 h -1.372 z"
                      style="letter-spacing: 0.02em"
                      id="path980" />
                <path d="m 56.003997,0.14 q -0.994,0 -1.764,-0.448 -0.77,-0.448 -1.204,-1.246 -0.42,-0.798 -0.42,-1.792 0,-1.008 0.406,-1.806 0.42,-0.798 1.148,-1.232 0.742,-0.448 1.708,-0.448 0.924,0 1.624,0.406 0.714,0.406 1.092,1.162 0.392,0.742 0.392,1.722 0,0.168 -0.028,0.588 h -4.956 q 0.112,0.896 0.644,1.414 0.532,0.518 1.4,0.518 0.644,0 1.05,-0.28 0.42,-0.294 0.588,-0.784 h 1.26 q -0.154,0.98 -0.952,1.61 -0.798,0.616 -1.988,0.616 z m 1.666,-4.13 q -0.056,-0.756 -0.546,-1.218 -0.476,-0.462 -1.246,-0.462 -0.728,0 -1.204,0.448 -0.476,0.448 -0.63,1.232 z"
                      style="letter-spacing: 0.02em"
                      id="path982" />
                <path d="m 63.521983,0.14 q -0.868,0 -1.568,-0.448 -0.686,-0.448 -1.078,-1.232 -0.378,-0.798 -0.378,-1.806 0,-1.008 0.392,-1.806 0.392,-0.798 1.106,-1.232 0.714,-0.448 1.652,-0.448 1.232,0 1.918,0.868 h 0.098 v -3.57 h 1.372 V 0 h -1.134 l -0.14,-0.84 h -0.196 q -0.686,0.98 -2.044,0.98 z m -1.624,-3.486 q 0,1.078 0.49,1.694 0.504,0.602 1.372,0.602 0.896,0 1.4,-0.602 0.504,-0.616 0.504,-1.694 0,-1.078 -0.504,-1.68 -0.504,-0.616 -1.4,-0.616 -0.868,0 -1.372,0.616 -0.49,0.602 -0.49,1.68 z"
                      style="letter-spacing: 0.02em"
                      id="path984" />
            </g>
        </g>
        <g id="Task-Oriented-2" class="cls-6">
            <g aria-label="Optimistic"
               transform="translate(275.32 188.31)"
               id="text90"
               fill="#fff"
               class="cls-4">
                <path d="M 5.278,0.14 Q 3.976,0.14 2.94,-0.476 1.904,-1.106 1.316,-2.198 0.742,-3.304 0.742,-4.69 q 0,-1.386 0.574,-2.478 0.588,-1.106 1.624,-1.722 1.036,-0.63 2.338,-0.63 1.302,0 2.338,0.63 1.036,0.616 1.61,1.722 0.588,1.092 0.588,2.478 0,1.386 -0.588,2.492 -0.574,1.092 -1.61,1.722 Q 6.58,0.14 5.278,0.14 Z M 2.17,-4.69 q 0,1.05 0.392,1.848 0.392,0.798 1.092,1.232 0.714,0.434 1.624,0.434 0.91,0 1.61,-0.434 0.714,-0.434 1.106,-1.232 0.392,-0.798 0.392,-1.848 0,-1.05 -0.392,-1.848 -0.392,-0.798 -1.106,-1.232 -0.7,-0.434 -1.61,-0.434 -0.91,0 -1.624,0.434 -0.7,0.434 -1.092,1.232 Q 2.17,-5.74 2.17,-4.69 Z"
                      style="letter-spacing: 0.02em"
                      id="path947" />
                <path d="m 11.848,-6.692 h 1.134 l 0.14,0.84 h 0.196 q 0.686,-0.98 2.044,-0.98 0.868,0 1.554,0.448 0.7,0.448 1.078,1.246 0.392,0.784 0.392,1.792 0,1.008 -0.392,1.806 -0.392,0.798 -1.092,1.246 -0.686,0.434 -1.582,0.434 -1.218,0 -1.974,-0.826 H 13.22 V 2.66 h -1.372 z m 1.372,3.346 q 0,1.078 0.504,1.694 0.504,0.602 1.4,0.602 0.868,0 1.358,-0.602 0.504,-0.616 0.504,-1.694 0,-1.078 -0.504,-1.68 -0.49,-0.616 -1.358,-0.616 -0.896,0 -1.4,0.616 -0.504,0.602 -0.504,1.68 z"
                      id="path949" />
                <path d="m 22.528,0 q -0.868,0 -1.302,-0.406 -0.434,-0.42 -0.434,-1.302 v -3.864 h -1.54 v -1.12 h 0.714 q 0.602,0 0.784,-0.322 0.182,-0.322 0.182,-1.064 v -0.7 h 1.232 v 2.086 h 1.834 v 1.12 h -1.834 v 3.64 q 0,0.392 0.196,0.574 0.196,0.182 0.686,0.182 h 0.952 V 0 Z"
                      style="letter-spacing: 0.02em"
                      id="path951" />
                <path d="m 25.925999,-6.692 h 1.372 V 0 h -1.372 z m -0.042,-2.842 h 1.456 v 1.47 h -1.456 z"
                      style="letter-spacing: 0.02em"
                      id="path953" />
                <path d="m 29.557999,-6.692 h 1.232 l 0.14,0.798 h 0.098 q 0.728,-0.938 1.862,-0.938 0.63,0 1.106,0.28 0.476,0.28 0.756,0.784 h 0.098 q 0.49,-0.546 0.994,-0.798 0.504,-0.266 1.106,-0.266 1.022,0 1.61,0.644 0.602,0.644 0.602,1.736 V 0 h -1.372 v -4.256 q 0,-0.616 -0.364,-0.994 -0.364,-0.392 -0.924,-0.392 -0.644,0 -1.05,0.448 -0.406,0.434 -0.406,1.12 V 0 h -1.372 v -4.256 q 0,-0.616 -0.364,-0.994 -0.364,-0.392 -0.924,-0.392 -0.644,0 -1.05,0.448 -0.406,0.434 -0.406,1.12 V 0 h -1.372 z"
                      style="letter-spacing: 0.02em"
                      id="path955" />
                <path d="m 41.415983,-6.692 h 1.372 V 0 h -1.372 z m -0.042,-2.842 h 1.456 v 1.47 h -1.456 z"
                      style="letter-spacing: 0.02em"
                      id="path957" />
                <path d="m 47.683998,0.14 q -1.288,0 -2.086,-0.602 -0.784,-0.602 -0.924,-1.75 h 1.274 q 0.126,0.574 0.56,0.896 0.448,0.308 1.218,0.308 0.686,0 1.05,-0.238 0.378,-0.238 0.378,-0.686 0,-0.336 -0.238,-0.518 -0.224,-0.182 -0.7,-0.252 l -1.33,-0.182 q -0.952,-0.14 -1.442,-0.616 -0.49,-0.49 -0.49,-1.26 0,-0.938 0.714,-1.498 0.714,-0.574 1.904,-0.574 1.232,0 1.904,0.616 0.686,0.602 0.826,1.568 h -1.274 q -0.266,-1.036 -1.498,-1.036 -0.602,0 -0.952,0.224 -0.35,0.224 -0.35,0.63 0,0.644 0.896,0.77 l 1.596,0.224 q 0.84,0.112 1.288,0.602 0.448,0.476 0.448,1.246 0,0.994 -0.714,1.568 -0.7,0.56 -2.058,0.56 z"
                      style="letter-spacing: 0em"
                      id="path959" />
                <path d="m 54.667999,0 q -0.868,0 -1.302,-0.406 -0.434,-0.42 -0.434,-1.302 v -3.864 h -1.54 v -1.12 h 0.714 q 0.602,0 0.784,-0.322 0.182,-0.322 0.182,-1.064 v -0.7 h 1.232 v 2.086 h 1.834 v 1.12 h -1.834 v 3.64 q 0,0.392 0.196,0.574 0.196,0.182 0.686,0.182 h 0.952 V 0 Z"
                      style="letter-spacing: 0.02em"
                      id="path961" />
                <path d="m 58.069987,-6.692 h 1.372 V 0 h -1.372 z m -0.042,-2.842 h 1.456 v 1.47 h -1.456 z"
                      style="letter-spacing: 0.02em"
                      id="path963" />
                <path d="m 64.579964,0.14 q -0.952,0 -1.694,-0.434 -0.742,-0.434 -1.148,-1.218 -0.406,-0.798 -0.406,-1.834 0,-1.036 0.406,-1.82 0.42,-0.798 1.162,-1.232 0.756,-0.434 1.708,-0.434 1.302,0 2.072,0.672 0.784,0.672 0.98,1.918 h -1.288 q -0.378,-1.4 -1.736,-1.4 -0.896,0 -1.414,0.602 -0.518,0.602 -0.518,1.694 0,1.092 0.518,1.694 0.518,0.602 1.386,0.602 1.386,0 1.764,-1.4 h 1.288 q -0.21,1.26 -1.008,1.932 -0.798,0.658 -2.072,0.658 z"
                      style="letter-spacing: 0.02em"
                      id="path965" />
            </g>
        </g>
        <g id="Task-Oriented-3" class="cls-6">
            <g aria-label="Prudent"
               transform="translate(101.83 369.49)"
               id="text101"
               fill="#fff"
               class="cls-4">
                <path d="m 1.26,-9.38 h 3.766 q 1.54,0 2.38,0.756 0.854,0.742 0.854,2.212 0,1.47 -0.854,2.226 -0.84,0.742 -2.38,0.742 H 2.66 V 0 h -1.4 z m 3.668,4.62 q 0.938,0 1.428,-0.42 0.49,-0.42 0.49,-1.232 0,-0.812 -0.49,-1.232 -0.49,-0.42 -1.428,-0.42 H 2.66 v 3.304 z"
                      style="letter-spacing: 0.01em"
                      id="path987" />
                <path d="m 10.078,-6.692 h 1.232 l 0.14,0.938 h 0.098 q 0.42,-0.546 0.896,-0.812 0.49,-0.266 1.232,-0.266 h 0.252 V -5.53 H 13.41 q -0.938,0 -1.456,0.504 -0.504,0.49 -0.504,1.372 V 0 h -1.372 z"
                      style="letter-spacing: 0.02em"
                      id="path989" />
                <path d="m 17.819974,0.14 q -1.12,0 -1.792,-0.714 -0.658,-0.714 -0.658,-1.946 v -4.172 h 1.372 v 3.976 q 0,0.756 0.406,1.218 0.406,0.448 1.078,0.448 0.756,0 1.204,-0.504 0.448,-0.504 0.448,-1.344 v -3.794 h 1.372 V 0 h -1.232 l -0.14,-0.798 h -0.098 q -0.728,0.938 -1.96,0.938 z"
                      style="letter-spacing: 0.02em"
                      id="path991" />
                <path d="m 26.177959,0.14 q -0.868,0 -1.568,-0.448 -0.686,-0.448 -1.078,-1.232 -0.378,-0.798 -0.378,-1.806 0,-1.008 0.392,-1.806 0.392,-0.798 1.106,-1.232 0.714,-0.448 1.652,-0.448 1.232,0 1.918,0.868 h 0.098 v -3.57 h 1.372 V 0 h -1.134 l -0.14,-0.84 h -0.196 q -0.686,0.98 -2.044,0.98 z m -1.624,-3.486 q 0,1.078 0.49,1.694 0.504,0.602 1.372,0.602 0.896,0 1.4,-0.602 0.504,-0.616 0.504,-1.694 0,-1.078 -0.504,-1.68 -0.504,-0.616 -1.4,-0.616 -0.868,0 -1.372,0.616 -0.49,0.602 -0.49,1.68 z"
                      style="letter-spacing: 0.02em"
                      id="path993" />
                <path d="m 34.941935,0.14 q -0.994,0 -1.764,-0.448 -0.77,-0.448 -1.204,-1.246 -0.42,-0.798 -0.42,-1.792 0,-1.008 0.406,-1.806 0.42,-0.798 1.148,-1.232 0.742,-0.448 1.708,-0.448 0.924,0 1.624,0.406 0.714,0.406 1.092,1.162 0.392,0.742 0.392,1.722 0,0.168 -0.028,0.588 h -4.956 q 0.112,0.896 0.644,1.414 0.532,0.518 1.4,0.518 0.644,0 1.05,-0.28 0.42,-0.294 0.588,-0.784 h 1.26 q -0.154,0.98 -0.952,1.61 -0.798,0.616 -1.988,0.616 z m 1.666,-4.13 q -0.056,-0.756 -0.546,-1.218 -0.476,-0.462 -1.246,-0.462 -0.728,0 -1.204,0.448 -0.476,0.448 -0.63,1.232 z"
                      style="letter-spacing: 0.02em"
                      id="path995" />
                <path d="m 39.828,-6.692 h 1.232 l 0.14,0.798 h 0.098 q 0.728,-0.938 1.988,-0.938 1.148,0 1.806,0.714 0.672,0.714 0.672,1.946 V 0 h -1.372 v -3.976 q 0,-0.756 -0.42,-1.204 -0.406,-0.462 -1.092,-0.462 -0.77,0 -1.232,0.504 -0.448,0.49 -0.448,1.344 V 0 h -1.372 z"
                      id="path997" />
                <path d="m 50.228001,0 q -0.868,0 -1.302,-0.406 -0.434,-0.42 -0.434,-1.302 v -3.864 h -1.54 v -1.12 h 0.714 q 0.602,0 0.784,-0.322 0.182,-0.322 0.182,-1.064 v -0.7 h 1.232 v 2.086 h 1.834 v 1.12 h -1.834 v 3.64 q 0,0.392 0.196,0.574 0.196,0.182 0.686,0.182 h 0.952 V 0 Z"
                      style="letter-spacing: 0.02em"
                      id="path999" />
            </g>
        </g>
        <g id="Task-Oriented-4" class="cls-6">
            <g aria-label="Engaged"
               transform="translate(281.25 369.34)"
               id="text108"
               fill="#fff"
               class="cls-4">
                <path d="m 1.26,-9.38 h 6.426 v 1.316 H 2.66 v 2.73 h 4.298 v 1.288 H 2.66 v 2.73 H 7.742 V 0 H 1.26 Z"
                      style="letter-spacing: 0em"
                      id="path1002" />
                <path d="M 9.6080004,-6.692 H 10.84 l 0.14,0.798 h 0.098 q 0.728,-0.938 1.988,-0.938 1.148,0 1.806,0.714 0.672,0.714 0.672,1.946 V 0 h -1.372 v -3.976 q 0,-0.756 -0.42,-1.204 -0.406,-0.462 -1.092,-0.462 -0.77,0 -1.232,0.504 -0.448,0.49 -0.448,1.344 V 0 H 9.6080004 Z"
                      style="letter-spacing: 0.02em"
                      id="path1004" />
                <path d="m 20.499996,2.786 q -1.022,0 -1.792,-0.294 -0.77,-0.294 -1.19,-0.826 -0.42,-0.518 -0.42,-1.204 0,-0.924 0.686,-1.526 0.686,-0.602 1.89,-0.728 v -0.112 q -0.952,-0.21 -1.526,-0.868 -0.574,-0.672 -0.574,-1.554 0,-0.7 0.378,-1.274 0.378,-0.574 1.05,-0.896 0.672,-0.336 1.498,-0.336 0.7,0 1.316,0.266 l 1.036,-1.218 0.966,0.812 -0.98,1.148 q 0.588,0.658 0.588,1.498 0,0.882 -0.574,1.554 -0.574,0.658 -1.526,0.868 v 0.112 q 1.204,0.126 1.89,0.728 0.686,0.602 0.686,1.526 0,0.686 -0.42,1.204 -0.42,0.532 -1.19,0.826 -0.77,0.294 -1.792,0.294 z m -2.03,-2.408 q 0,0.56 0.532,0.896 0.546,0.336 1.498,0.336 0.952,0 1.484,-0.336 0.546,-0.336 0.546,-0.896 0,-0.56 -0.546,-0.882 -0.532,-0.336 -1.484,-0.336 -0.952,0 -1.498,0.336 -0.532,0.322 -0.532,0.882 z m 0.434,-4.676 q 0,0.644 0.42,1.008 0.434,0.364 1.176,0.364 0.742,0 1.162,-0.364 0.434,-0.364 0.434,-1.008 0,-0.63 -0.434,-0.994 -0.42,-0.364 -1.162,-0.364 -0.742,0 -1.176,0.364 -0.42,0.364 -0.42,0.994 z"
                      style="letter-spacing: 0.02em"
                      id="path1006" />
                <path d="m 27.555979,0.14 q -1.05,0 -1.708,-0.574 -0.658,-0.574 -0.658,-1.484 0,-0.952 0.714,-1.498 0.714,-0.56 1.974,-0.56 h 1.764 v -0.336 q 0,-0.686 -0.392,-1.022 -0.392,-0.35 -1.106,-0.35 -1.274,0 -1.484,1.232 h -1.26 q 0.084,-1.078 0.812,-1.722 0.742,-0.658 1.96,-0.658 1.344,0 2.072,0.63 0.742,0.616 0.742,1.75 v 2.744 q 0,0.224 0.154,0.364 0.168,0.126 0.434,0.126 h 0.168 V 0 h -0.392 q -0.616,0 -1.022,-0.238 -0.406,-0.238 -0.574,-0.7 h -0.098 q -0.63,1.078 -2.1,1.078 z m -1.05,-2.156 q 0,0.462 0.336,0.742 0.336,0.266 0.924,0.266 0.84,0 1.358,-0.476 0.518,-0.49 0.518,-1.274 v -0.154 h -1.694 q -0.728,0 -1.092,0.21 -0.35,0.21 -0.35,0.686 z"
                      style="letter-spacing: 0.02em"
                      id="path1008" />
                <path d="m 36.179969,2.786 q -1.022,0 -1.792,-0.294 -0.77,-0.294 -1.19,-0.826 -0.42,-0.518 -0.42,-1.204 0,-0.924 0.686,-1.526 0.686,-0.602 1.89,-0.728 v -0.112 q -0.952,-0.21 -1.526,-0.868 -0.574,-0.672 -0.574,-1.554 0,-0.7 0.378,-1.274 0.378,-0.574 1.05,-0.896 0.672,-0.336 1.498,-0.336 0.7,0 1.316,0.266 l 1.036,-1.218 0.966,0.812 -0.98,1.148 q 0.588,0.658 0.588,1.498 0,0.882 -0.574,1.554 -0.574,0.658 -1.526,0.868 v 0.112 q 1.204,0.126 1.89,0.728 0.686,0.602 0.686,1.526 0,0.686 -0.42,1.204 -0.42,0.532 -1.19,0.826 -0.77,0.294 -1.792,0.294 z m -2.03,-2.408 q 0,0.56 0.532,0.896 0.546,0.336 1.498,0.336 0.952,0 1.484,-0.336 0.546,-0.336 0.546,-0.896 0,-0.56 -0.546,-0.882 -0.532,-0.336 -1.484,-0.336 -0.952,0 -1.498,0.336 -0.532,0.322 -0.532,0.882 z m 0.434,-4.676 q 0,0.644 0.42,1.008 0.434,0.364 1.176,0.364 0.742,0 1.162,-0.364 0.434,-0.364 0.434,-1.008 0,-0.63 -0.434,-0.994 -0.42,-0.364 -1.162,-0.364 -0.742,0 -1.176,0.364 -0.42,0.364 -0.42,0.994 z"
                      style="letter-spacing: 0.02em"
                      id="path1010" />
                <path d="m 44.201952,0.14 q -0.994,0 -1.764,-0.448 -0.77,-0.448 -1.204,-1.246 -0.42,-0.798 -0.42,-1.792 0,-1.008 0.406,-1.806 0.42,-0.798 1.148,-1.232 0.742,-0.448 1.708,-0.448 0.924,0 1.624,0.406 0.714,0.406 1.092,1.162 0.392,0.742 0.392,1.722 0,0.168 -0.028,0.588 h -4.956 q 0.112,0.896 0.644,1.414 0.532,0.518 1.4,0.518 0.644,0 1.05,-0.28 0.42,-0.294 0.588,-0.784 h 1.26 q -0.154,0.98 -0.952,1.61 -0.798,0.616 -1.988,0.616 z m 1.666,-4.13 q -0.056,-0.756 -0.546,-1.218 -0.476,-0.462 -1.246,-0.462 -0.728,0 -1.204,0.448 -0.476,0.448 -0.63,1.232 z"
                      style="letter-spacing: 0.02em"
                      id="path1012" />
                <path d="m 51.719939,0.14 q -0.868,0 -1.568,-0.448 -0.686,-0.448 -1.078,-1.232 -0.378,-0.798 -0.378,-1.806 0,-1.008 0.392,-1.806 0.392,-0.798 1.106,-1.232 0.714,-0.448 1.652,-0.448 1.232,0 1.918,0.868 h 0.098 v -3.57 h 1.372 V 0 h -1.134 l -0.14,-0.84 h -0.196 q -0.686,0.98 -2.044,0.98 z m -1.624,-3.486 q 0,1.078 0.49,1.694 0.504,0.602 1.372,0.602 0.896,0 1.4,-0.602 0.504,-0.616 0.504,-1.694 0,-1.078 -0.504,-1.68 -0.504,-0.616 -1.4,-0.616 -0.868,0 -1.372,0.616 -0.49,0.602 -0.49,1.68 z"
                      style="letter-spacing: 0.02em"
                      id="path1014" />
            </g>
        </g>
        <line id="Line_857-2"
              class="cls-10"
              x1="44.71"
              y1="230.12"
              x2="395.71"
              y2="230.12" />
        <line id="Line_855-2"
              class="cls-10"
              x1="218.71"
              y1="50.26"
              x2="218.71"
              y2="408.26" />

        <text
          style="font-size: 12px"
          fill="#B0B1B0"
          font-family="Zagma"
          text-anchor="middle"
          transform="translate(218.71,40.26)"
        >
          Composed
        </text>
        <text
          style="font-size: 12px"
          fill="#B0B1B0"
          font-family="Zagma"
          text-anchor="middle"
          transform="translate(34.71,230.12) rotate(-90)"
        >
          Measured
        </text>
        <text
          style="font-size: 12px"
          fill="#B0B1B0"
          font-family="Zagma"
          text-anchor="middle"
          transform="translate(405.71,230.12) rotate(90)"
        >
          Risk Tolerant
        </text>
        <text
          style="font-size: 12px"
          fill="#B0B1B0"
          font-family="Zagma"
          text-anchor="middle"
          transform="translate(218.71,428.26)"
        >
          Risk Aware
        </text>
    </svg>