import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AdminMenuLink } from 'src/app/shared/models';
import { selectIsSuperAdmin } from 'src/app/+state/user/user.selector';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/+state/app.state';
import { selectCanChampionsViewAnalyticss } from 'src/app/+state/account-admin/account-admin.selector';

@Component({
  selector: 'app-admin-top-menu',
  templateUrl: './admin-top-menu.component.html',
  styleUrls: ['./admin-top-menu.component.scss'],
})
export class AdminTopMenuComponent implements OnInit, OnDestroy {
  adminLinks: AdminMenuLink[] = [
    {
      title: 'User management',
      routerLink: ['./user-manage'],
      permissionRequired: 'USER_MANAGE',
    },
    {
      title: 'Live analytics',
      routerLink: ['./live-analytics'],
      permissionRequired: 'VIEW_ACC_ANALYTICS',
    },
    {
      title: 'Settings',
      routerLink: ['./admin-settings'],
      permissionRequired: 'VIEW_SETTINGS',
    }
    // { title: 'Help Center', routerLink: ['./help'], isChampionRestricted: false, hasConditionalRestrictions: false },
  ];

  destroyed$ = new Subject<boolean>();

  isSuperAdmin$ = this.store.select(selectIsSuperAdmin);

  isSuperAdmin?: boolean;

  //these are using the state that is already set in account settings ...
  canChampsView$ = this.store.select(selectCanChampionsViewAnalyticss);
  canChampsView?: boolean;

  constructor(private router: Router, private store: Store<AppState>) {
    this.router.events
      .pipe(takeUntil(this.destroyed$))
      .subscribe((event: any) => {
        if (event instanceof NavigationEnd) {
          let links = this.adminLinks.slice(0);

          this.adminLinks = links.map((l) => {
            if (l.routerLink && l.routerLink.length > 0) {
              return {
                ...l,
                active: event.url.includes(l.routerLink[0].substring(1)),
              };
            }
            return l;
          });
        }
      });
  }

  ngOnInit(): void {
    this.isSuperAdmin$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((isSupe) => (this.isSuperAdmin = isSupe));
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.unsubscribe();
  }
}
