import { createReducer, on } from '@ngrx/store';
import { DebriefReadout, DebriefReadoutIds, FlexCopeType } from 'src/app/shared/models/debrief-readout.interface';
import { DigitalDebriefMessages } from 'src/app/shared/services/messaging.service';
import { ClearLatestReadoutId, ClearSelectedReadoutItemId, SetSelectedReadoutItemId, SetSelectedReadoutVoice } from './debrief.actions';

export interface DebriefState {
  readoutItems: DebriefReadout[];
  selectedReadoutItemId?: DebriefReadoutIds;
  latestReadoutItemId?: DebriefReadoutIds;
  selectedReadoutVoice: 'm' | 'f';

}

export const initialDebreifState: DebriefState = {
  readoutItems: [
    {
      id: DebriefReadoutIds.MindsetIntro,
      subPath: 'mindset',
      fileName: 'mindset_intro',
      buttonText: 'REVEAL MY SPOTLIGHT',
      isButtonEnabled: true,
      buttonClickMessage: DigitalDebriefMessages.RevealMySpotlight
    },
    {
      id: DebriefReadoutIds.MindsetPreferences,
      subPath: 'mindset',
      flexCopeType: FlexCopeType.COPE,
      isButtonEnabled: false,
      audioCompleteMessage: DigitalDebriefMessages.SpotlightReadoutCompleted
    },
    {
      id: DebriefReadoutIds.BehaviouralStyleIntro,
      subPath: 'behaviour',
      fileName: 'behaviour_intro',
      buttonText: 'REVEAL MY SPOTLIGHT',
      isButtonEnabled: true,
      buttonClickMessage: DigitalDebriefMessages.RevealMySpotlight
    },
    {
      id: DebriefReadoutIds.BehaviouralStylePreferences,
      subPath: 'behaviour',
      flexCopeType: FlexCopeType.FLEX,
      isButtonEnabled: false,
      audioCompleteMessage: DigitalDebriefMessages.SpotlightReadoutCompleted
    },
    {
      id: DebriefReadoutIds.StrengthsIntro,
      subPath: 'strengths',
      fileName: 'sweetspot',
      buttonText: 'DONE',
      isButtonEnabled: false,
      buttonClickMessage: DigitalDebriefMessages.ShowStrengthCurve
    },
    {
      id: DebriefReadoutIds.TakeCaresIntro,
      subPath: 'take-cares',
      fileName: 'reveal_take_cares',
      buttonText: 'REVEAL TAKE CARES',
      isButtonEnabled: true,
      buttonClickMessage: DigitalDebriefMessages.ShowTakeCareCurve
    },
    {
      id: DebriefReadoutIds.TakeCaresDescription,
      subPath: 'take-cares',
      fileName: 'take_cares_selected',
      isButtonEnabled: false,
      audioCompleteMessage: DigitalDebriefMessages.TakeCareReadoutComplete
    },
    {
      id: DebriefReadoutIds.WriggleRoomIntro,
      subPath: 'take-cares',
      fileName: 'take_cares_complete',
      buttonText: 'REVEAL WRIGGLE ROOM',
      isButtonEnabled: true,
      buttonClickMessage: DigitalDebriefMessages.ShowWriggleRoomSelection
    },
    {
      id: DebriefReadoutIds.WriggleRoomSelection,
      subPath: 'wriggle-room',
      fileName: 'wriggle_room',
      buttonText: 'DONE',
      isButtonEnabled: false,
      buttonClickMessage: DigitalDebriefMessages.ShowWriggleRoomCurve
    },
    {
      id: DebriefReadoutIds.WriggleRoomComplete,
      subPath: 'wriggle-room',
      fileName: 'wriggle_room_complete',
      isButtonEnabled: false
    }
  ],
  selectedReadoutVoice: 'f',
};

export const debriefReducer = createReducer(
  initialDebreifState,

  on(SetSelectedReadoutItemId, (state, props) => ({
    ...state,
    selectedReadoutItemId: props.id,
    latestReadoutItemId: !state.latestReadoutItemId || props.id > state.latestReadoutItemId ? props.id : state.latestReadoutItemId
  })),

  on(ClearSelectedReadoutItemId, (state, props) => ({
    ...state,
    selectedReadoutItemId: undefined
  })),

  on(ClearLatestReadoutId, (state) => ({
    ...state,
    latestReadoutItemId: undefined
  })),

  on(SetSelectedReadoutVoice, (state, props) => ({
    ...state,
    selectedReadoutVoice: props.voice
  })),

);
