import { AfterViewInit, Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import anime from 'animejs';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { faCloseSVG } from '../../../../icons';
@Component({
  selector: 'app-strengths-curve',
  templateUrl: './strengths-curve.component.html',
  styleUrls: ['./strengths-curve.component.scss']
})
export class StrengthsCurveComponent implements AfterViewInit {

  @ViewChild('curveSvgContainer') curveContainer?: ElementRef<HTMLElement>;

  @HostListener('mousemove', ['$event'])
  handleMouseMove(e: any) {
    if (this.isDragging && this.curveContainer && e.target.tagName == 'svg') {
      var fractionAcross = e.offsetX / this.svgWidth;

      this.animateHandleMovement(fractionAcross);
      this.setStrengthTextIndex(fractionAcross);
    }
  }

  svgWidth = 1;
  isDragging = false;

  strengthText = 'THIS IS A STRENGTH OF YOURS';
  strengthSectionText = ['Underdone', 'Sweetspot', 'Wriggle room', 'OVERDONE'];
  strengthSectionTextIndex = 0;

  DURATION_OF_ANIMATION = 100;
  LINE_ONE_FRACTION_ACROSS = 0.338;
  LINE_TWO_FRACTION_ACROSS = 0.685;
  LINE_THREE_FRACTION_ACROSS = 0.895;

  closeIcon = faCloseSVG;
  constructor(private modalref: BsModalRef) {}

  ngAfterViewInit() {
    setTimeout(() => {
      this.animateHandleMovement(0);
      this.svgWidth = this.curveContainer ? this.curveContainer.nativeElement.clientWidth : 1;
    }, 100);
  }

  onSvgClick(e: MouseEvent) {
    var fractionAcross = e.offsetX / this.svgWidth;
    this.animateHandleMovement(fractionAcross);
  }

  animateHandleMovement(fractionAcross: number) {
    var bouncePath = anime.path('.curve path');

    anime({
      targets: '.curve .handle',
      translateX: bouncePath('x'),
      translateY: bouncePath('y'),
      rotate: 0,
      easing: 'linear',
      duration: this.DURATION_OF_ANIMATION,
      autoplay: false
    }).seek(fractionAcross * this.DURATION_OF_ANIMATION);
  }

  setDragging(_: any, isDragging: boolean) {
    this.isDragging = isDragging;
  }

  close() {
    this.modalref.hide();
  }

  setStrengthTextIndex(fractionAcross: number) {
    if (fractionAcross <= this.LINE_ONE_FRACTION_ACROSS) {
      this.strengthSectionTextIndex = 0;
    } else if (fractionAcross <= this.LINE_TWO_FRACTION_ACROSS) {
      this.strengthSectionTextIndex = 1;
    } else if (fractionAcross <= this.LINE_THREE_FRACTION_ACROSS) {
      this.strengthSectionTextIndex = 2;
    } else {
      this.strengthSectionTextIndex = 3;
    }
  }
}
