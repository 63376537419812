import { createReducer, on } from '@ngrx/store';
import { CoachingClientsDTO } from '../../shared/models/coaching-clients-dto.interface';
import { CoachingFeedbackDTO } from '../../shared/models/coaching-feedback-dto.interface';
import { GroupedCoachingFeedbackDTO } from '../../shared/models/practitioner-coaching-feedback-dto.interface';
import { UserPractitionerDTO } from '../../shared/models/user-practitioner.interface';
import * as SuperAdminActions from './super-admin.actions';
import * as AccountActions from '../account/account.actions';

import { QuestionnaireDTO } from '../../shared/models/questionnaire-option.interface';
import { QuestionnaireResponseManagementDTO } from '../../shared/models/questionnaire-response-management-dto.interface';
import { CommonActions } from '../common-actions';
import { MindflickOrganisation, SpotlightResponse, UserBasicDetails } from 'src/app/shared/models';
import { SeatsInAcc } from 'src/app/shared/models/account-admin-managed-users-DTO';

export interface SuperAdminState {
  mindflickPractitioners: UserPractitionerDTO[];
  clientInfo: CoachingClientsDTO;
  coachingFeedback?: CoachingFeedbackDTO;
  groupedFeedback?: GroupedCoachingFeedbackDTO[];
  questionnaires: QuestionnaireDTO[];
  questionnaireResponseManagementDetails?: QuestionnaireResponseManagementDTO[];
  userSpotlightResponses?: SpotlightResponse[];
  seatsInOrg?: SeatsInAcc;
  isAddingUser: boolean;
  isAddingMindflickCoach: boolean;
  isAddingMindflickCoachSuccess: string;
  isAddingMindflickCoachError: string;
  mindflickOrganisations: MindflickOrganisation[];
  mindflickOrganisationLoading: boolean;
  selectedOrganisationId?: number;
  mindflickOrganisationUsers: UserBasicDetails[];
}

export const initialSuperAdminState: SuperAdminState = {
  mindflickPractitioners: [],
  clientInfo: {} as CoachingClientsDTO,
  questionnaires: [],
  userSpotlightResponses: [] as SpotlightResponse[],
  isAddingUser: false,
  isAddingMindflickCoach: false,
  isAddingMindflickCoachSuccess: '',
  isAddingMindflickCoachError: '',
  mindflickOrganisations: [],
  mindflickOrganisationLoading: false,
  mindflickOrganisationUsers: []
};


export const superAdminReducer = createReducer(
  initialSuperAdminState,

  on(CommonActions.ClearState, (state, props) => ({
    ...initialSuperAdminState
  })),

  on(SuperAdminActions.GetMindflickPractitioners.Success, (state, props) => ({
    ...state,
    mindflickPractitioners: props.practitioners
  })
  ),
  on(SuperAdminActions.GetCoachingClientInfo.Success, (state, props) => ({
    ...state,
    clientInfo: props.clientInfo
  })
  ),
  on(SuperAdminActions.GetCoachingFeedback.Success, (state, props) => ({
    ...state,
    coachingFeedback: props.coachingFeedback
  })
  ),

  on(SuperAdminActions.GetGroupedCoachingFeedback.Success, (state, props) => ({
    ...state,
    groupedFeedback: props.feedback
  })
  ),
  on(SuperAdminActions.GetQuestionnaires.Success, (state, props) => ({
    ...state,
    questionnaires: props.questionnaires
  })
  ),
  on(SuperAdminActions.GetQuestionnaireResponseManagementDetails.Success, (state, props) => ({
    ...state,
    questionnaireResponseManagementDetails: props.questionnaireResponseManagementDTO
  })
  ),
  on(SuperAdminActions.GetUserSpotlightResponses.Success, (state, props) => ({
    ...state,
    userSpotlightResponses: props.spotlightResponses
  })
  ),
  on(SuperAdminActions.InviteUser.Request, (state, props) => ({
    ...state,
    isAddingUser: true,
  })),
  on(SuperAdminActions.InviteUser.Success, (state, props) => ({
    ...state,
    isAddingUser: false,
    accountAdminManagedUsers: [
      ...props.resp.addedUsers,
    ],
    addUsersSuccess: true,
    seatsInOrg: state.seatsInOrg
      ? {
        ...state.seatsInOrg,
        currentActiveUsersInAcc:
          state.seatsInOrg.currentActiveUsersInAcc +
          props.resp.addedUsers.length,
        seatsUsed: state.seatsInOrg.seatsUsed + props.resp.addedUsers.length,
      }
      : undefined,
  })),
  on(SuperAdminActions.InviteUser.Fail, (state, props) => ({
    ...state,
    isAddingUser: false,
  })),
  on(SuperAdminActions.AddMindflickCoachToAccount.Request, (state) => ({
    ...state,
    isAddingMindflickCoach: true,
    isAddingMindflickCoachSuccess: '',
    isAddingMindflickCoachError: ''
  })),
  on(SuperAdminActions.AddMindflickCoachToAccount.Success, (state, props) => ({
    ...state,
    isAddingMindflickCoach: false,
    isAddingMindflickCoachSuccess: !props.resp.isError ? props.resp.message : '',
    isAddingMindflickCoachError: props.resp.isError ? props.resp.message : ''
  })),
  on(SuperAdminActions.AddMindflickCoachToAccount.Fail, (state, props) => ({
    ...state,
    isAddingMindflickCoach: false,
    isAddingMindflickCoachSuccess: '',
    isAddingMindflickCoachError: props.error.message
  })),
  on(SuperAdminActions.ResetAddingMindflickCoachStatus, (state) => ({
    ...state,
    isAddingMindflickCoach: false,
    isAddingMindflickCoachSuccess: '',
    isAddingMindflickCoachError: ''
  })),
  on(SuperAdminActions.GetMindflickOrganisations.Request, (state, props) => ({
    ...state,
    mindflickOrganisationLoading: true
  })),
  on(SuperAdminActions.GetMindflickOrganisations.Success, (state, props) => ({
    ...state,
    mindflickOrganisations: props.resp,
    mindflickOrganisationLoading: false
  })),
  on(SuperAdminActions.GetMindflickOrganisations.Fail, (state, props) => ({
    ...state,
    mindflickOrganisationsLoading: false
  })),
  on(SuperAdminActions.SetSelectedMindflickOrganisation, (state, props) => ({
    ...state,
    selectedOrganisationId: props.organisationId
  })),
  on(SuperAdminActions.ClearSelectedMindflickOrganisation, (state) => ({
    ...state,
    selectedOrganisationId: undefined
  })),
  on(SuperAdminActions.UpdateMindflickOrganisationAccount, (state, props) => ({
    ...state,
    mindflickOrganisations: state.mindflickOrganisations.map(org => {
      if (org.id === props.organisationId)
        return {
          ...org,
          mindflickAccounts: org.mindflickAccounts.map(acc => {
            if (acc.id === props.mindflickAccount.id)
              return {
                ...props.mindflickAccount,
              };

            return acc;
          })
        };

      return org;
    })
  })),
  on(AccountActions.AddMindflickAccount.Success, (state, props) => ({
    ...state,
    mindflickOrganisations: state.mindflickOrganisations.map(org => {
      if (org.id === props.account.mindflickOrganisationId)
        return {
          ...org,
          mindflickAccounts: [...org.mindflickAccounts, props.account]
        };

      return org;
    })
  })),
  on(SuperAdminActions.SetMindflickOrganisationAdmin.Success, (state, props) => ({
    ...state,
    mindflickOrganisations: state.mindflickOrganisations.map(org => {
      if (org.id === props.organisationId)
        return {
          ...org,
          adminUserAk: props.userAk,
          adminName: props.adminName
        };

      return org;
    })
  })),
  on(SuperAdminActions.SetMindflickOrganisationLead.Success, (state, props) => ({
    ...state,
    mindflickOrganisations: state.mindflickOrganisations.map(org => {
      if (org.id === props.organisationId)
        return {
          ...org,
          organisationLeadAk: props.userAk,
          organisationLeadName: props.leadName
        };

      return org;
    })
  })),
  on(SuperAdminActions.GetMindflickOrganisationUsers.Success, (state, props) => ({
    ...state,
    mindflickOrganisationUsers: props.resp
  })),  on(SuperAdminActions.UpdateSeatCount.Success, (state, props) => ({
    ...state,
    mindflickOrganisations: state.mindflickOrganisations.map(org => {
      if (org.id === props.orgId)
        return {
          ...org,
          mindflickAccounts: org.mindflickAccounts.map(acc => {
            if (acc.id === props.account.id)
              return {
                ...acc,
                seats: props.account.seats

              };

            return acc;
          })
        };

      return org;
    })
  })),
);
