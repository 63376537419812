<div *ngIf="filteredCards.length > 0" class="carousel-container mt-5">
  <div class="d-flex justify-content-between pb-3 align-items-end" [class.title-container]="hasTitlePadding">
    <div class="h-100 d-flex align-items-end">
      <h1 *ngIf="growthType === 'workOn'" class="text-white">
        {{name}}
      </h1>
      <h2 *ngIf="growthType === 'engagement-resource'">{{name}}</h2>
    </div>
    <div class="d-flex align-items-end"
        [ngClass]="paginationColor">
      <h3 *ngIf="cards.length > 1 && !isSmallCards" class="cursor-pointer me-2" (click)="viewAsGrid()"> {{viewAsGridText}} <fa-icon [icon]="next"></fa-icon></h3>
      <app-btn-action
          [icon]="back"
          [iconDimensions]="30"
          [secondary]="true"
          [light]="light"
          [disabled]="disableLeft"
          (actionClick)="scrollCardsLeft()">
      </app-btn-action>
      <app-btn-action
          class="ms-1"
          [icon]="next"
          [iconDimensions]="30"
          [secondary]="true"
          [light]="light"
          [disabled]="disableRight"
          (actionClick)="scrollCardsRight()"></app-btn-action>
    </div>
  </div>
  <ng-container *ngIf="!viewAsGridEnabled; else gridview;">
    <ng-container *ngIf="!isSmallCards; else smallCards;">
      <div #subCategoryCards class="w-100 d-flex carousel" (scroll)="checkScrollPosition()" [ngClass]="growthType">
        <ng-container *ngFor="let card of filteredCards">
          <app-card
            [id]="card.id"
            [title]="card.title"
            [summary]="card.summary"
            [imageUrl]="card.imageUrl"
            [growthType]="growthType"
            [growthTypeEnum]="growthTypeEnum"
            [workOnType]="card.workOnType"
            [tags]="card.tags"
            [sections]="card.sections"
            [canPin]="canPin"
            (pinClicked)="onPinClicked($event)"
          ></app-card>
        </ng-container>
      </div>
    </ng-container>
    <ng-template #smallCards>
      <div #subCategoryCards class="w-100 d-flex carousel" (scroll)="checkScrollPosition()" [ngClass]="growthType">
        <ng-container *ngFor="let card of filteredCards">
          <!-- <app-card
          [id]="card.id"
          [title]="card.title"
          [summary]="card.summary"
          [imageUrl]="card.imageUrl"
          [growthType]="growthType"
          [workOnType]="card.workOnType"
          [tags]="card.tags"
          [sections]="card.sections"
          ></app-card> -->
          <app-work-on-card-small
            [id]="card.id"
            [title]="card.title"
            [summary]="card.summary"
            [imageUrl]="card.imageUrl"
            [growthType]="growthTypeEnum"
            [workOnType]="card.workOnType"
            [canPin]="canPin"
            (pinClicked)="onPinClicked($event)"
          ></app-work-on-card-small>
        </ng-container>
      </div>
    </ng-template>
  </ng-container>
  <!-- grid view -->
  <ng-template #gridview>
    <div class="row gy-5" [class.page-container]="hasTitlePadding">
      <ng-container *ngFor="let card of filteredCards; index as i">
        <app-card
        class="col-md-4"
        [ngClass]="{'end-padding': (i+1) % 3 == 0, 'middle-padding': (i-1) % 3 == 0}"
        [id]="card.id"
        [title]="card.title"
        [summary]="card.summary"
        [imageUrl]="card.imageUrl"
        [growthType]="growthType"
        [growthTypeEnum]="growthTypeEnum"
        [workOnType]="card.workOnType"
        [tags]="card.tags"
        ></app-card>
      </ng-container>
    </div>
  </ng-template>

</div>
