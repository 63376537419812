<div class="modal-header justify-content-end">
  <fa-icon class="cursor-pointer fa-lg"
           [icon]="closeIcon"
           (click)="close()"></fa-icon>
</div>
<div class="modal-body">
  <h2 class="mb-2">Create new Mindflick account</h2>
  <form [formGroup]="addAccountForm" class="row g-3">
    <div class="form-group col-12">
      <label for="name" class="form-label">Name</label>
      <input formControlName="name"
             id="name"
             name="name"
             type="text"
             placeholder="Enter account name"
             class="form-control" />
    </div>
    <div class="form-group col-6">
      <label for="seats" class="form-label">Seats</label>
      <input formControlName="seats"
             id="seats"
             name="seats"
             type="number"
             placeholder="Enter number of seats"
             class="form-control" />
    </div>
    <div class="form-group col-6">
      <label for="maximumChurnPercentage" class="form-label">Maximum Churn Percent</label>
      <input formControlName="maximumChurnPercentage"
             id="maximumChurnPercentage"
             name="maximumChurnPercentage"
             type="number"
             placeholder="Enter maximum churn percentage"
             class="form-control" />
    </div>

    <div class="form-group renewal-date position-relative col-6">
      <label for="renewalDate" class="form-label">Renewal Date</label>
      <input formControlName="renewalDate"
             type="text"
             placeholder="Select renewal date"
             class="form-control"
             bsDatepicker
             [container]="'.renewal-date'"
             [bsConfig]="{ showWeekNumbers: false, dateInputFormat: 'DD/MM/YYYY' }" />
    </div>
    <div class="form-group col-6">
      <label for="type" class="form-label">Account type</label>
      <app-dropdown-select [options]="types"
                           placeholder="Select account type"
                           formControlName="type"
                           ></app-dropdown-select>
    </div>
  </form>
  <button class="btn btn-purple w-100 mt-4" (click)="save()">
    Add Account
  </button>
</div>
<div class="modal-bottom-border"></div>
