import { createAction, props } from '@ngrx/store';
import { PortalNotification } from 'src/app/shared/models/notifications.interface';
import { ConnectionStatus } from '../../shared/models/enums/connection-status.enum';
import { UpdateTeamMapStatusReq } from '../../shared/models/team-map-processing.interface';

export const SetConnectionStatus = createAction(
  '[SignalR] Set Connection Status',
  props<{
    connectionStatus: ConnectionStatus;
    error: any;
  }>()
);

export namespace Example {
  // This action is triggered whenever the SignalR server sends the example message to this client
  export const Handle = createAction(
    '[SignalR] Received Example',
    props<{
      // The model here is a copy of the definition from the C# hub that is sending the message
      // See Signal-R\Mindflick.SignalR\Mindflick.SignalR\Hubs\PlatformHub.cs ln 110
      ownerId: string;
      message: string;
      someOtherProperty: string;
    }>()
  );

  // These actions are used to send a REST request to the SignalR relay, which then sends out the requested message to all connected clients
  export const Request = createAction(
    '[SignalR] Request Example',
    props<{ message: string }>()
  );

  export const Success = createAction('[SignalR] Request Example Success');

  export const Fail = createAction(
    '[SignalR] Request Example Fail',
    props<{ error: any }>()
  );
}

export const Handle = createAction(
  '[SignalR] Notification Handled',
  props<{
    notification: PortalNotification;
  }>()
);

export namespace RemoveNotification {
  export const Request = createAction(
    '[SignalR] Remove Notification Request',
    props<{ notificationId: number }>()
  );
  export const Success = createAction(
    '[SignalR] Remove Notification Success',
    props<{ notificationId: number }>()
  );

  export const Fail = createAction(
    '[SignalR] Remove Notification Fail',
    props<{ error: any }>()
  );
}

export namespace GetNotifications {
  export const Request = createAction('[SignalR] Get Notifications Request');

  export const Success = createAction(
    '[SignalR] Get Notifications Success',
    props<{ notifications: PortalNotification[] }>()
  );

  export const Fail = createAction(
    '[SignalR] Get Notifications Fail',
    props<{ error: any }>()
  );
}

export namespace ConnectionRequest {
  export const Request = createAction(
    '[SignalR] User Connection Request Request',
    props<{ requestedUserGuid: string }>()
  );

  export const Success = createAction(
    '[SignalR] Request User Connection Request Success'
  );

  export const Fail = createAction(
    '[SignalR] Request User Connection Request Fail',
    props<{ error: any }>()
  );
}

export namespace ConnectionAccepted {
  export const Request = createAction(
    '[SignalR] User Connection Acceptance Request',
    props<{ requestedUserGuid: string }>()
  );

  export const Success = createAction(
    '[SignalR] Request User Connection Acceptance Success'
  );

  export const Fail = createAction(
    '[SignalR] Request User Connection Acceptance Fail',
    props<{ error: any }>()
  );
}

export namespace TeamRequest {
  export const Request = createAction(
    '[SignalR] Team Request Request',
    props<{ requestedUserGuid: string }>()
  );

  export const Success = createAction('[SignalR] Team Request Success');

  export const Fail = createAction(
    '[SignalR] Team Request Fail',
    props<{ error: any }>()
  );
}

export namespace MarkNotificationAsSeen {
  export const Request = createAction(
    '[Notifications] Mark Notification As Seen Request',
    props<{ notificationIds: number[] }>()
  );

  export const Success = createAction(
    '[Notifications] Mark Notification As Seen Success',
    props<{ resp: PortalNotification[] }>()
  );

  export const Fail = createAction(
    '[Notifications] Mark Notification As Seen Fail',
    props<{ error: any }>()
  );
}

export namespace SetNotificationAsAcknowledged {
  export const Request = createAction(
    '[Notifications] Set Notification As Acknowledged Request',
    props<{ notificationId: number }>()
  );

  export const Success = createAction(
    '[Notifications] Set Notification As Acknowledged Success',
    props<{ notificationId: number }>()
  );

  export const Fail = createAction(
    '[Notifications] Set Notification As Acknowledged Fail',
    props<{ error: any }>()
  );
}

export namespace NotifyUserOfConnectionRequest {
  export const Request = createAction(
    '[Notification] Notify User Of Connection Request Request',
    props<{ userAK: number }>()
  );

  export const Success = createAction(
    '[Notification] Notify User Of Connection Request Success'
  );

  export const Fail = createAction(
    '[Notification] Notify User Of Connection Request Fail',
    props<{ error: any }>()
  );
}

export namespace NotifyUserOfConnectionAccepted {
  export const Request = createAction(
    '[Notification] Notify User Of Connection Accepted Request',
    props<{ userAK: number }>()
  );

  export const Success = createAction(
    '[Notification] Notify User Of Connection Accepted Success'
  );

  export const Fail = createAction(
    '[Notification] Notify User Of Connection Accepted Fail',
    props<{ error: any }>()
  );
}

export namespace NotifyUserOfStrengthSpotted {
  export const Request = createAction(
    '[Notification] Notify User Of Strength Spotted Request',
    props<{ userAK: number; strengthId: string }>()
  );

  export const Success = createAction(
    '[Notification] Notify User Of Strength Spotted Success'
  );

  export const Fail = createAction(
    '[Notification] Notify User Of Strength Spotted Fail',
    props<{ error: any }>()
  );
}

export namespace NotifyUserOfStrengthSpottedCommentAcknowledged {
  export const Request = createAction(
    '[Notification] Notify User Of Strength Spotted Comment Acknowledged Request',
    props<{ userAK: number; strengthCommentId: string }>()
  );

  export const Success = createAction(
    '[Notification] Notify User Of Strength Spotted Comment Acknowledged Success'
  );

  export const Fail = createAction(
    '[Notification] Notify User Of Strength Spotted Comment Acknowledged Fail',
    props<{ error: any }>()
  );
}

export namespace TeamMapProcessing {
  export const Updated = createAction(
    '[Signal R] Team Map Processing Updated',
    props<{ teamMap: UpdateTeamMapStatusReq }>()
  );

  export const TeamMapProcessed = createAction(
    '[Signal R] Team Map Processing COmpleted'
  );
}
