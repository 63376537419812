import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppState } from 'src/app/+state/app.state';
import { GrowthType } from 'src/app/+state/growth/growth.reducer';
import {
  selectSortBy,
  selectWorkOnType,
} from 'src/app/+state/growth/growth.selector';
import { faBackSVG, faNextSVG } from 'src/icons';
import { GrowthSortBy, WorkOnType } from '../../models';
import { CardLayout } from '../../models/carousel-card-layouts.interface';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
})
export class CarouselComponent implements OnInit {
  @ViewChild('subCategoryCards')
  subCategoryCards?: ElementRef<any>;

  @Input() name?: string;

  private _cards: CardLayout[] = [];
  @Input()
  set cards(cards: CardLayout[]) {
    this.filteredCards = [...cards];
    this._cards = [...cards];
  }
  get cards() {
    return this._cards;
  }

  @Input() growthTypeEnum: GrowthType = GrowthType.WorkOn;
  @Input() growthType: string = '';
  @Input() hasTitlePadding: boolean = true;
  @Input() isSmallCards: boolean = false;

  @Input() canPin: boolean = false;

  @Output()
  pinClicked: EventEmitter<number> = new EventEmitter<number>();

  filteredType$ = this.store.select(selectWorkOnType);
  filterSortBy$ = this.store.select(selectSortBy);
  destroyed$: Subject<boolean> = new Subject<boolean>();

  viewAsGridEnabled: boolean = false;

  filteredCards: CardLayout[] = [...this.cards];
  filteredType?: WorkOnType;
  filteredTagIds: number[] = [];
  filterSortBy?: GrowthSortBy;

  viewAsGridText: string = 'View as grid';
  next = faNextSVG;
  back = faBackSVG;
  disableLeft = true;
  disableRight = false;
  paginationColor = 'black';
  light: boolean = false;

  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    if (this.growthType === 'workOn') {
      this.paginationColor = 'white';
      this.light = true;
    }

    this.filteredType$.pipe(takeUntil(this.destroyed$)).subscribe((type) => {
      this.filteredType = type;
      type !== 3 ? this.filter() : (this.filteredCards = this.cards);
    });

    this.filterSortBy$.pipe(takeUntil(this.destroyed$)).subscribe((sortBy) => {
      this.filterSortBy = sortBy;

      sortBy === GrowthSortBy.Alphabetical
        ? this.sortCardsAlphabetically()
        : (this.filterSortBy = sortBy);
    });
  }

  viewAsGrid() {
    this.viewAsGridEnabled = !this.viewAsGridEnabled;
    if (!this.viewAsGridEnabled) this.viewAsGridText = 'View as grid';

    if (this.viewAsGridEnabled) this.viewAsGridText = 'View as carousel';
  }

  private checkControlState(
    newLeft: number,
    scrollWidth: number,
    clientWidth: number
  ) {
    if (newLeft <= 0) {
      this.disableLeft = true;
    } else {
      this.disableLeft = false;
    }

    if (newLeft + clientWidth >= scrollWidth - 1) {
      this.disableRight = true;
    } else {
      this.disableRight = false;
    }
  }

  getColor() {
    return this.growthType === 'workOn' ? 'white' : 'black';
  }

  scrollCardsRight() {
    let newLeft =
      this.subCategoryCards?.nativeElement.scrollLeft +
      this.subCategoryCards?.nativeElement.clientWidth * 0.25;

    this.subCategoryCards?.nativeElement.scrollTo({
      left: newLeft,
      behavior: 'smooth',
    });

    this.checkControlState(
      newLeft,
      this.subCategoryCards?.nativeElement.scrollWidth,
      this.subCategoryCards?.nativeElement.clientWidth
    );
  }
  scrollCardsLeft() {
    let newLeft =
      this.subCategoryCards?.nativeElement.scrollLeft -
      this.subCategoryCards?.nativeElement.clientWidth * 0.26;

    this.subCategoryCards?.nativeElement.scrollTo({
      left: newLeft,
      behavior: 'smooth',
    });

    this.checkControlState(
      newLeft,
      this.subCategoryCards?.nativeElement.scrollWidth,
      this.subCategoryCards?.nativeElement.clientWidth
    );
  }
  checkScrollPosition() {
    let scrollPosition = this.subCategoryCards?.nativeElement.scrollLeft;

    this.checkControlState(
      scrollPosition,
      this.subCategoryCards?.nativeElement.scrollWidth,
      this.subCategoryCards?.nativeElement.clientWidth
    );
  }

  filter() {
    if (this.filteredTagIds.length > 0) {
      this.filteredCards = this.filteredCards.filter((card) =>
        card.tagIds?.some((tagId) => this.filteredTagIds.includes(tagId))
      );
    }

    if (this.filteredType !== undefined) {
      this.filteredCards = this.filteredCards.filter(
        (card) => this.filteredType === card.workOnType
      );
    }
  }

  sortCardsAlphabetically() {
    this.filteredCards.sort((a, b) => a.title.localeCompare(b.title));
  }

  onPinClicked(id: number) {
    if (this.canPin) this.pinClicked.emit(id);
  }
}
