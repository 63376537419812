import { createReducer, on } from '@ngrx/store';
import { SpotlightResponse, SurveyWordPair } from 'src/app/shared/models';
import * as SurveyActions from '../actions/survey.actions';
import { QuestionnaireAnswer, QuestionnaireDTO, QuestionnaireResponseDTO } from '../../../shared/models/questionnaire-option.interface';
import { CommonActions } from '../../common-actions';

export interface SurveyState {
  uniqueRef: string;
  spotlightResponseId: number;
  currentPage: number;
  isLoading: boolean;
  loadingError: any;
  isSaving: boolean;
  savingError: any;
  spotlightResponse: SpotlightResponse | undefined;
  wordPairs: SurveyWordPair[];
  photoSrc?: string;
  questionnaireResponses: QuestionnaireResponseDTO[];
  currentQuestionnaire?: QuestionnaireResponseDTO;
}

export const initialSurveyState: SurveyState = {
  uniqueRef: '',
  spotlightResponseId: 0,
  currentPage: 0,
  isLoading: false,
  loadingError: undefined,
  isSaving: false,
  savingError: undefined,
  spotlightResponse: undefined,
  wordPairs: [],
  questionnaireResponses: []
};

export const surveyReducer = createReducer(
  initialSurveyState,

  on(CommonActions.ClearState, (state, props) => ({
    ...initialSurveyState
  })),

  on(SurveyActions.LoadSurveyFromInvite.Request, (state, props) => ({
    ...state,
    isLoading: true,
  })),
  on(SurveyActions.LoadSurveyFromInvite.Success, (state, props) => ({
    ...state,
    spotlightResponse: {
      ...props.response,
      id: state.spotlightResponseId,
      uniqueRef: state.uniqueRef,
    },
    isLoading: false,
  })),
  on(SurveyActions.SaveSurveyAnswers.Request, (state, props) => ({
    ...state,
    isSaving: true,
    spotlightResponse: state.spotlightResponse
      ? {
        ...state.spotlightResponse,
        answers: props.request.answers ?? '',
      }
      : undefined,
  })),
  on(SurveyActions.SaveSurveyAnswers.Success, (state) => ({
    ...state,
    isSaving: false,
    currentPage:
      state.spotlightResponse?.pages &&
        state.currentPage < state.spotlightResponse?.pages?.length
        ? state.currentPage + 1
        : state.currentPage,
  })),
  on(SurveyActions.SaveSurveyAnswers.Fail, (state, props) => ({
    ...state,
    isSaving: false,
  })),
  on(SurveyActions.SetSurveyPage, (state, props) => ({
    ...state,
    currentPage: props.pageId,
  })),
  on(SurveyActions.PreviousPage, (state) => ({
    ...state,
    currentPage:
      state.currentPage > 0 ? state.currentPage - 1 : state.currentPage,
  })),
  on(SurveyActions.NextPage, (state) => ({
    ...state,
    currentPage:
      state.spotlightResponse?.pages &&
        state.currentPage < state.spotlightResponse?.pages?.length
        ? state.currentPage + 1
        : state.currentPage,
  })),
  on(SurveyActions.SetSpotlightResponseId, (state, props) => ({
    ...state,
    spotlightResponseId: props.spotlightResponseId,
  })),
  on(SurveyActions.SetUniqueRef, (state, props) => ({
    ...state,
    uniqueRef: props.uniqueRef,
  })),
  on(SurveyActions.SetSurveyIsSaving, (state, props) => ({
    ...state,
    isSaving: props.isSaving,
  })),
  on(SurveyActions.SetUserPicture.Success, (state, props) => ({
    ...state,
    photoSrc: props.photoSrc,
  })),
  on(SurveyActions.GetQuestionnairesForSpotlightResponse.Success, (state, props) => ({
    ...state,
    questionnaireResponses: props.questionnaires,
  })),
  on(SurveyActions.GetQuestionnaireResponseDetails.Success, (state, props) => ({
    ...state,
    currentQuestionnaire: props.questionnaire,
  })),
  on(SurveyActions.SaveQuestionnaireAnswers.Request, (state, props) => {
    var updatedQuestionnaireAnswers: QuestionnaireAnswer[] = [...state.currentQuestionnaire!.answers];

    props.questionnaireAnswers.answers.filter(x => {
      var index = state.currentQuestionnaire!.answers.map(x => x.questionnaireQuestionId).indexOf(x.questionnaireQuestionId)
      if (index > -1) {
        updatedQuestionnaireAnswers[index] = x;
      } else {
        updatedQuestionnaireAnswers.push(x);
      }
    });

    return {
      ...state,
      currentQuestionnaire: {
        ...state.currentQuestionnaire!,
        answers: updatedQuestionnaireAnswers
      }
    };
  })
);
