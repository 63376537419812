import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { selectAccountsSummaryData } from "src/app/+state/account-admin/account-admin.selector";
import { AppState } from "src/app/+state/app.state";
import {
  GetAccountsSummaryWithinOrg
} from '../../+state/account-admin/account-admin.actions';
import { faAddSVG } from "src/icons";

@Component({
  selector: "app-organisation-accounts-summary-table",
  templateUrl: "./organisation-accounts-summary-table.component.html",
  styleUrls: ["./organisation-accounts-summary-table.component.scss"],
})
export class OrganisationAccountsSummaryTableComponent
  implements OnInit
{
  accountsInOrg$ = this.store.select(selectAccountsSummaryData);
  add = faAddSVG;


  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    this.store.dispatch(GetAccountsSummaryWithinOrg.Request());
  }

}
