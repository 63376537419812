import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as AccountAdminActions from 'src/app/+state/account-admin/account-admin.actions';
import {
  NotificationSettings,
  StandardResponse,
  User,
} from 'src/app/shared/models';
import { CookieSettings } from 'src/app/shared/models/cookies';
import { ConnectionStatus } from 'src/app/shared/models/enums/connection-status.enum';
import { NotificationType } from 'src/app/shared/models/enums/notification-types.enum';
import {
  DeactivateUser,
} from '../account/account.actions';
import { Handle } from '../signalr/signalr.actions';
import * as UserActions from './user.actions';
import { SetUserPlatformIntroViewedFlag } from './user.actions';
import { CommonActions } from '../common-actions';
import { StrengthSpottingSection } from 'src/app/shared/models/strength-spotting.interface';
import { DigitalDebriefStageEnum } from '../../shared/models/enums/digital-debrief-stage.enum';
import { GetMyProfile } from '../profile/profile.actions';

const notificationSettings: NotificationSettings[] = [
  {
    description: 'Nudges',
    pushNotificationKey: 'NUDGES_ENABLED',
    emailNotificationKey: 'EMAIL_NUDGES_ENABLED',
  },
  {
    description: 'Connection requests',
    pushNotificationKey: 'PUSH_NEW_CONNECTIONS',
    emailNotificationKey: 'EMAIL_NEW_CONNECTIONS',
  },
  {
    description: 'Strength Spotted',
    pushNotificationKey: 'PUSH_STRENGTH_SPOTTED',
    emailNotificationKey: 'EMAIL_STRENGTH_SPOTTED',
  },
  {
    description: 'Team Invites',
    pushNotificationKey: 'PUSH_TEAM_INVITES',
    emailNotificationKey: 'EMAIL_TEAM_INVITES',
  },
];

export interface AllUserState extends EntityState<User> {}
export interface DeactivatedUserState extends EntityState<User> {}

export const allUserAdapter: EntityAdapter<User> = createEntityAdapter<User>({
  sortComparer: sortUserByName,
});

export function sortUserByName(a: User, b: User) {
  return `${a.firstName} ${a.lastName}`.localeCompare(
    `${b.firstName} ${b.lastName}`
  );
}

export interface UserState {
  currentUser?: User;
  allUsers: AllUserState;
  allUsersLoading: boolean;
  requestingProfileAccess: boolean;
  requestingProfileAccessResponse?: StandardResponse<undefined>;
  selectedUserId?: number;
  userSearchFilter: string;
  userAdminPage: number;
  userAdminTake: number;
  twoFactorBase64QR?: string;
  twoFactorFormattedKey?: string;
  twoFactorVerified?: boolean;
  notificationSettings: NotificationSettings[];
  cookieSettings?: CookieSettings;
  isOnPlatformAndPractitioner: boolean;
}

export const initialUserState: UserState = {
  allUsers: allUserAdapter.getInitialState(),
  allUsersLoading: false,
  requestingProfileAccess: false,
  userSearchFilter: '',
  userAdminPage: 1,
  userAdminTake: 12,
  notificationSettings: notificationSettings,
  isOnPlatformAndPractitioner: false,

};

export const userReducer = createReducer(
  initialUserState,

  on(CommonActions.ClearState, (state, props) => ({
    ...initialUserState
  })),

  on(UserActions.GetHasBothProducts.Success, (state, props) => ({
    ...state,
    isOnPlatformAndPractitioner: props.resp,
  })),

  on(UserActions.GetUser.Success, (state, props) => ({
    ...state,
    currentUser: props.user,
    isOnPlatformAndPractitioner: props.user.isOnPlatformAndPractitioner,
  })),

  on(UserActions.UpdateCurrentUserDelegatedInvites, (state, props) => ({
    ...state,
    currentUser: state.currentUser
      ? {
        ...state.currentUser,
        delegatedInvites:
          props.delegatedInvites > state.currentUser.invitedUserAks.length
            ? props.delegatedInvites
            : state.currentUser.invitedUserAks.length,
      }
      : undefined,
  })),

  on(UserActions.GetUserByAK.Success, (state, props) => ({
    ...state,
    allUsers: allUserAdapter.upsertOne(props.user, state.allUsers),
  })),

  on(UserActions.GetAllUsers.Request, (state) => ({
    ...state,
    allUsersLoading: true,
  })),

  on(UserActions.GetAllUsers.Success, (state, props) => ({
    ...state,
    allUsers: allUserAdapter.setMany(props.allUsers, state.allUsers),
    allUsersLoading: false,
  })),

  on(UserActions.GetAllUsers.Fail, (state) => ({
    ...state,
    allUsers: allUserAdapter.removeAll(state.allUsers),
    allUsersLoading: false,
  })),

  on(UserActions.GetMyConnectedUsers.Request, (state) => ({
    ...state,
    connectedUsersLoading: true,
  })),

  on(UserActions.GetMyConnectedUsers.Success, (state, props) => ({
    ...state,
    connectedUsers: props.connectedUsers,
    connectedUsersLoading: false,
  })),

  on(UserActions.GetMyConnectedUsers.Fail, (state) => ({
    ...state,
    connectedUsers: [],
    connectedUsersLoading: false,
  })),

  on(UserActions.RequestProfileAccess.Request, (state) => ({
    ...state,
    requestingProfileAccess: true,
  })),

  on(UserActions.RequestProfileAccess.Success, (state, props) => ({
    ...state,
    requestingProfileAccess: false,
    requestingProfileAccessResponse: props.resp,
    allUsers: allUserAdapter.updateOne(
      {
        id: props.userAK,
        changes: {
          connectionStatus: ConnectionStatus.Requested,
        },
      },
      state.allUsers
    ),
  })),

  on(UserActions.RequestProfileAccess.Fail, (state, props) => ({
    ...state,
    requestingProfileAccess: false,
    requestingProfileAccessResponse: { message: props.error, isError: true },
  })),

  on(UserActions.ClearRequestProfileAccessResponse, (state) => ({
    ...state,
    requestingProfileAccessResponse: undefined,
  })),
  on(UserActions.SetSelectedUser, (state, props) => ({
    ...state,

    selectedUserId: props.id,
  })),

  on(UserActions.PutConnectionDate.Success, (state, props) => ({
    ...state,
    allUsers: allUserAdapter.updateOne(
      {
        id: props.userId,
        changes: {
          connectionStatus: ConnectionStatus.Connected,
          flex: props.flexCope[0],
          cope: props.flexCope[1]
        },
      },
      state.allUsers
    ),
  })),

  on(UserActions.ClearSelectedUser, (state) => ({
    ...state,
    selectedUser: undefined,
  })),

  on(UserActions.DeletePendingConnection.Success, (state, props) => ({
    ...state,
    allUsers: allUserAdapter.updateOne(
      {
        id: props.userId,
        changes: {
          connectionStatus: ConnectionStatus.Unconnected,
        },
      },
      state.allUsers
    ),
  })),

  on(UserActions.SetUserSearchFilter, (state, props) => ({
    ...state,
    userSearchFilter: props.filter,
  })),

  on(UserActions.ClearUserSearchFilter, (state) => ({
    ...state,
    userSearchFilter: '',
  })),

  on(UserActions.UpdateUserDetails.Success, (state, props) => ({
    ...state,
    currentUser: state.currentUser
      ? {
        ...state.currentUser,
        firstName: props.resp.firstName,
        lastName: props.resp.lastName,
        gender: props.resp.gender,
        jobTitle: props.resp.jobTitle,
      }
      : undefined,
  })),
  on(UserActions.DeleteUserConnection.Success, (state, props) => ({
    ...state,
    allUsers: allUserAdapter.updateOne(
      {
        id: props.userAK,
        changes: {
          connectionStatus: ConnectionStatus.Unconnected,
        },
      },
      state.allUsers
    ),
  })),
  on(UserActions.SetUserAdminPage, (state, props) => ({
    ...state,
    userAdminPage: props.page,
  })),

  on(DeactivateUser.Success, (state, props) => {
    let usersToRemove: User[] = allUserAdapter
      .getSelectors()
      .selectAll(state.allUsers)
      .filter((user) => props.userAK == user.id);
    let userIdsToRemove = usersToRemove.map((x) => x.id);

    return {
      ...state,
      allUsers: allUserAdapter.removeMany(userIdsToRemove, state.allUsers),
    };
  }),

  on(UserActions.Generate2FARegistrationKeys.Success, (state, props) => ({
    ...state,
    twoFactorBase64QR: props.keys.qrData,
    twoFactorFormattedKey: props.keys.formattedKey,
  })),
  on(UserActions.Verify2FAUserInputCode.Success, (state, props) => ({
    ...state,
    twoFactorBase64QR: undefined,
    twoFactorFormattedKey: undefined,
    twoFactorVerified: true,
    currentUser: state.currentUser
      ? {
        ...state.currentUser,
        twoFactorEnabled: true,
      }
      : undefined,
  })),
  on(UserActions.Verify2FAUserInputCode.Fail, (state, props) => ({
    ...state,
    twoFactorVerified: false,
  })),
  on(UserActions.Disable2FA.Success, (state, props) => ({
    ...state,
    currentUser: state.currentUser
      ? {
        ...state.currentUser,
        twoFactorEnabled: false,
      }
      : undefined,
  })),
  on(UserActions.ClearTwoFactorVerification, (state, props) => ({
    ...state,
    twoFactorVerified: undefined,
  })),
  on(Handle, (state, props) => {
    if (props.notification.type == NotificationType.ConnectionRequest)
      return {
        ...state,
        allUsers: allUserAdapter.updateOne(
          {
            id: props.notification.sendingUserAK,
            changes: { connectionStatus: ConnectionStatus.Pending },
          },
          state.allUsers
        ),
      };

    if (props.notification.type == NotificationType.ConnectionAccepted)
      return {
        ...state,
        allUsers: allUserAdapter.updateOne(
          {
            id: props.notification.sendingUserAK,
            changes: { connectionStatus: ConnectionStatus.Connected },
          },
          state.allUsers
        ),
      };

    return state;
  }),
  on(UserActions.SetUserTake, (state, props) => ({
    ...state,
    userAdminTake: props.userTake,
  })),
  on(UserActions.SetPlatformSetting.Success, (state, props) => ({
    ...state,
    currentUser: {
      ...state.currentUser,
      platformSettings: {
        ...state.currentUser?.platformSettings,
        [props.key]: props.value,
      },
    } as User,
  })),
  on(UserActions.SetBulkPlatformSettings.Success, (state, props) => ({
    ...state,
    currentUser: {
      ...state.currentUser!,
      platformSettings: {
        ...state.currentUser?.platformSettings,
        ...props.settings,
      },
    },
  })),
  on(UserActions.ChangeUserPicture.Success, (state, props) => ({
    ...state,
    currentUser: {
      ...state.currentUser!,
      photoLastModified: props.photoLastModified,
    },
    allUsers: allUserAdapter.updateOne(
      {
        id: state.currentUser?.id!,
        changes: {
          photoLastModified: props.photoLastModified,
        },
      },
      state.allUsers
    ),
  })),
  on(UserActions.ClearUserPicture.Success, (state) => ({
    ...state,
    currentUser: {
      ...state.currentUser!,
      photoLastModified: '',
    },
    allUsers: allUserAdapter.updateOne(
      {
        id: state.currentUser?.id!,
        changes: {
          photoLastModified: '',
        },
      },
      state.allUsers
    ),
  })),
  on(SetUserPlatformIntroViewedFlag.Success, (state, props) => ({
    ...state,
    currentUser: {
      ...state.currentUser!,
      platformIntroViewed: `${props.dateViewed}`,
    },
    allUsers: allUserAdapter.updateOne(
      {
        id: state.currentUser?.id!,
        changes: {
          platformIntroViewed: `${props.dateViewed}`,
        },
      },
      state.allUsers
    ),
  })),
  on(DeactivateUser.Success, (state, props) => ({
    ...state,
    allUsers: allUserAdapter.removeOne(props.userAK, state.allUsers),
  })),
  on(
    AccountAdminActions.UpdateSpotlightDownloadPermission.update,
    (state, props) => ({
      ...state,
      currentUser: {
        ...state.currentUser!,
        canDownloadSpotlight: props.enabled,
      },
    })
  ),
  on(UserActions.GetUserCookieSettings.Success, (state, props) => ({
    ...state,
    cookieSettings: props.settings,
  })),
  on(UserActions.SetUserCookieSettings.Success, (state, props) => ({
    ...state,
    cookieSettings: props.settings,
  })),
  on(UserActions.InviteDirectReport.Success, (state, props) => ({
    ...state,
    allUsers: allUserAdapter.addOne(props.resp, state.allUsers),
    currentUser: state.currentUser
      ? {
        ...state.currentUser,
        invitedUserAks: [...state.currentUser.invitedUserAks, props.resp.id],
      }
      : undefined,
  })),
  on(UserActions.CreatePulseSurvey.Success, (state) => ({
    ...state,
    currentUser: state.currentUser
      ? {
        ...state.currentUser,
        isPulseSurveyDue: false,
      }
      : undefined,
  })),
  on(UserActions.SetPlatformFirstLogin.Success, (state, props) => ({
    ...state,
    currentUser: {
      ...state.currentUser!,
      platformFirstLogin: props.user.platformFirstLogin,
    },
  })),
  on(UserActions.AcceptChampionCharter.Request, (state) => ({
    ...state,
    currentUser: state.currentUser
      ? {
        ...state.currentUser,
        hasAcceptedChampionCharter: true,
      }
      : undefined,
  })),
  on(UserActions.GetDebriefProgress.Success, (state, props) => ({
    ...state,
    currentUser: state.currentUser
      ? {
        ...state.currentUser,
        latestDebriefStage: props.debriefStage,
      }
      : undefined,
  }))
  ,
  on(UserActions.SaveDebriefProgress.Success, (state, props) => ({
    ...state,
    currentUser: state.currentUser
      ? {
        ...state.currentUser,
        latestDebriefStage: props.debriefStage,
      }
      : undefined,
  })),
  on(GetMyProfile.Success, (state, props) => {
    if (!state.currentUser || state.currentUser.isSpotlightProcessed)
      return {
        ...state
      }

    return {
      ...state,
      currentUser: {
        ...state.currentUser,
        isSpotlightProcessed: true
      }
    }
  }),
  on(UserActions.GoToDebriefIntro.Request, (state, props) => ({
    ...state,
    currentUser: state.currentUser
      ? {
        ...state.currentUser,
        latestDebriefStage: DigitalDebriefStageEnum.Intro,
      }
      : undefined,
  }))
  ,
  on(UserActions.CompleteDashboardOnboarding.Success, (state, props) => ({
    ...state,
    currentUser: state.currentUser
      ? {
        ...state.currentUser,
        dashboardOnboardingCompletedDate: props.completedDate,
      }
      : undefined,
  }))
  ,
  on(UserActions.CompleteProfileOnboarding.Success, (state, props) => ({
    ...state,
    currentUser: state.currentUser
      ? {
        ...state.currentUser,
        profileOnboardingCompletedDate: props.completedDate,
      }
      : undefined,
  }))
  ,
  on(UserActions.CompleteConnectionsOnboarding.Success, (state, props) => ({
    ...state,
    currentUser: state.currentUser
      ? {
        ...state.currentUser,
        connectionsOnboardingCompletedDate: props.completedDate,
      }
      : undefined,
  }))
);
