import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { AppState } from 'src/app/+state/app.state';
import { selectFlexOrCopeScores } from 'src/app/+state/profile/profile.selector';

@Component({
  selector: 'app-spotlight',
  templateUrl: './spotlight.component.html',
  styleUrls: ['./spotlight.component.scss'],
})
export class SpotlightComponent implements OnInit, OnDestroy {
  @Input()
  isFlex = false;

  @Input()
  showWords = false;

  //Useful for developing the position of the spotlights
  @Input()
  showSliders = false;

  @Input()
  showKey = false;

  private subs = new Subscription();
  constructor(private store: Store<AppState>) { }

  ngOnInit(): void {
    this.subs.add(
      this.store.select(selectFlexOrCopeScores).subscribe((scores) => {
        if (scores) {
          if (scores.flexScores && scores.copeScores) {
            this.scores[0] = this.isFlex
              ? scores.flexScores[0]
              : scores.copeScores[0];
            this.scores[1] = this.isFlex
              ? scores.flexScores[1]
              : scores.copeScores[1];
            this.scores[2] = this.isFlex
              ? scores.flexScores[2]
              : scores.copeScores[3];
            this.scores[3] = this.isFlex
              ? scores.flexScores[3]
              : scores.copeScores[2];

            this.updateTransformFull();
          }
        }
      })
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  scores: number[] = [];

  //The center of the ellipse
  cx: number = 500;
  cy: number = 500;
  //The radius of the ellipse
  rx: number = 0;
  ry: number = 0;
  //The rotation of the ellipse in degrees
  rot: number = 0;
  //Tranform strings to manipulate the SVG clip & the lamp position
  clipTransform: string = '';
  lampTransform: string = '';

  //The position of the lamp
  bx: number = 10;
  by: number = 10;

  //Internal for determining the closest point on the ellipse
  closest: number = 0;
  closestIndex: number = 0;
  closestX: number = 0;
  closestY: number = 0;
  highX: number = 0;
  highY: number = 0;
  lowX: number = 0;
  lowY: number = 0;
  points: { x: number; y: number }[] = [];

  //The path of the 'light' coming out of the lamp
  highlightTriangle: string = '';

  //Various SVG attributes
  tlCircleFill = '#000';
  trCircleFill = '#FF5F00';
  brCircleFill = '#878787';
  blCircleFill = '#7000DD';
  tlStroke = '#000';
  tlFill = '#fff';
  tlOpacity = 0.2;
  trStroke = '#000';
  trFill = '#fff';
  trOpacity = 0.2;
  brStroke = '#000';
  brFill = '#fff';
  brOpacity = 0.2;
  blStroke = '#000';
  blFill = '#fff';
  blOpacity = 0.2;
  lampFill = '#fff';
  topTitleFill = '#b0b1b0';
  rightTitleFill = '#b0b1b0';
  leftTitleFill = '#b0b1b0';
  bottomTitleFill = '#b0b1b0';
  topTitleFont = 'Zagma';
  rightTitleFont = 'Zagma';
  leftTitleFont = 'Zagma';
  bottomTitleFont = 'Zagma';
  highCount = 0;

  setScore(scoreId: number, event: EventTarget | null) {
    if (event == undefined)
      return;
    this.scores[scoreId] = Number((event as HTMLInputElement).value);
    this.updateTransformFull();
  }

  updateTransformFull() {
    //Figure out where the spotlight should be based on the FLEX values
    //It should be oriented between the two highest
    //then offset towards the third

    // Do not change the order of the scores here, they must be in TL, TR, BR, BL order
    let scores = [
      { letter: 'TL', score: this.scores[0] },
      { letter: 'TR', score: this.scores[1] + 0.0001 },
      { letter: 'BR', score: this.scores[2] + 0.0002 },
      { letter: 'BL', score: this.scores[3] + 0.0003 },
    ];

    if (this.isFlex) {
      this.tlCircleFill = '#ff2e5e';
      this.trCircleFill = '#83a9ff';
      this.brCircleFill = '#31c99a';
      this.blCircleFill = '#fecf33';
    } else {
      this.tlCircleFill = '#000';
      this.trCircleFill = '#FF5F00';
      this.brCircleFill = '#878787';
      this.blCircleFill = '#7000DD';
    }

    //Highlight the letters where the score is >= 3.5
    this.tlFill = scores[0].score >= 3.5 ? '#000' : '#fff';
    this.tlOpacity = scores[0].score >= 3.5 ? 1 : 0.2;
    this.tlStroke = scores[0].score >= 3.5 ? '#fff' : '#000';
    this.trFill = scores[1].score >= 3.5 ? '#000' : '#fff';
    this.trOpacity = scores[1].score >= 3.5 ? 1 : 0.2;
    this.trStroke = scores[1].score >= 3.5 ? '#fff' : '#000';
    this.brFill = scores[2].score >= 3.5 ? '#000' : '#fff';
    this.brOpacity = scores[2].score >= 3.5 ? 1 : 0.2;
    this.brStroke = scores[2].score >= 3.5 ? '#fff' : '#000';
    this.blFill = scores[3].score >= 3.5 ? '#000' : '#fff';
    this.blOpacity = scores[3].score >= 3.5 ? 1 : 0.2;
    this.blStroke = scores[3].score >= 3.5 ? '#fff' : '#000';

    //Determine the order of the letters and where they are on the board
    let scoreOrder = scores.sort((a, b) => (a.score < b.score ? 1 : -1));
    let letterOffsets: {
      [letter: string]: { x: number; y: number; color: string };
    } = {
      TL: { x: -1, y: -1, color: this.tlCircleFill },
      TR: { x: 1, y: -1, color: this.trCircleFill },
      BR: { x: 1, y: 1, color: this.brCircleFill },
      BL: { x: -1, y: 1, color: this.blCircleFill },
    };

    //Move the lamp purely based on the letters
    //FE should be top left, FL should be top left but right a bit (closer to L) and so on
    let offsetSize = 20;
    let originSize = 3;
    switch (scoreOrder[0].letter) {
      case 'TL':
        this.bx = originSize;
        this.by = originSize;
        if (scoreOrder[1].letter == 'TR') this.bx += offsetSize;
        else if (scoreOrder[1].letter == 'BL') this.by += offsetSize;
        else {
          this.bx += originSize;
          this.by += originSize;
        }
        break;
      case 'TR':
        this.bx = 100 - originSize;
        this.by = originSize;
        if (scoreOrder[1].letter == 'TL') this.bx -= offsetSize;
        if (scoreOrder[1].letter == 'BR') this.by += offsetSize;
        break;
      case 'BR':
        this.bx = 100 - originSize;
        this.by = 100 - originSize;
        if (scoreOrder[1].letter == 'TR') this.by -= offsetSize;
        if (scoreOrder[1].letter == 'BL') this.bx -= offsetSize;
        break;
      case 'BL':
        this.bx = originSize;
        this.by = 100 - originSize;
        if (scoreOrder[1].letter == 'TL') this.by -= offsetSize;
        if (scoreOrder[1].letter == 'BR') this.bx += offsetSize;
        break;
    }
    this.topTitleFill = '#b0b1b0';
    this.bottomTitleFill = '#b0b1b0';
    this.leftTitleFill = '#b0b1b0';
    this.rightTitleFill = '#b0b1b0';
    this.topTitleFont = 'Zagma';
    this.bottomTitleFont = 'Zagma';
    this.leftTitleFont = 'Zagma';
    this.rightTitleFont = 'Zagma';
    switch (scoreOrder[0].letter + scoreOrder[1].letter) {
      case 'TLTR':
      case 'TRTL':
        this.topTitleFill = '#000';
        this.topTitleFont = 'ZagmaBold';
        break;
      case 'BLBR':
      case 'BRBL':
        this.bottomTitleFill = '#000';
        this.bottomTitleFont = 'ZagmaBold';
        break;
      case 'TLBL':
      case 'BLTL':
        this.leftTitleFill = '#000';
        this.leftTitleFont = 'ZagmaBold';
        break;
      case 'TRBR':
      case 'BRRB':
        this.rightTitleFill = '#000';
        this.rightTitleFont = 'ZagmaBold';
        break;
    }

    //Make it an oval
    this.rx = 30;
    this.ry = 17;

    //Put it in the middle
    this.cx = 50;
    this.cy = 50;

    //If there is just one high letter then the ellipse should focus on that letter and clip the other 3, just. It should be more of a circle
    //If there are two high letters the ellipse should cover them both, biased to the first and a little more of the 3rd than the 4th
    //If the two lettes are opposed then it's more of an ellipse, if they are next to each other then it's more of a circle
    //If there are three high letters then the ellipse should cover all 3 and just clip the 4th. The ellipse should be wider than if there are just two high letters
    //If all four are high letters then the oval should almost be a circle and bias away from the last letter & towards the 1st
    this.highCount = scoreOrder.reduce(
      (sum, current) => sum + (current.score >= 3.5 ? 1 : 0),
      0
    );
    let scoreOffsets = scoreOrder.map((x) => letterOffsets[x.letter]);
    this.lampFill = scoreOffsets[0].color;

    switch (this.highCount) {
      case 4: {
        //The simplest of them all, a circle but shifted away from the last letter
        this.rx = 30;
        this.ry = 30;

        if (
          scoreOffsets[0].x == -scoreOffsets[1].x &&
          scoreOffsets[0].y == -scoreOffsets[1].y
        ) {
          //If the first two letters are diagonal then use a slightly more narrow ellipse
          this.ry -= 5;
          this.rx += 3;
        }

        //Angle the ellipse between the 1st & 2nd letters
        this.rot =
          Math.atan2(
            scoreOffsets[0].y - scoreOffsets[1].y,
            scoreOffsets[0].x - scoreOffsets[1].x
          ) / 0.01745329252;

        //Move towards the first letter
        let offsetSize = 5;
        this.cx += offsetSize * scoreOffsets[0].x;
        this.cy += offsetSize * scoreOffsets[0].y;

        //Move towards the second letter a little
        offsetSize = 2;
        this.cx += offsetSize * scoreOffsets[1].x;
        this.cy += offsetSize * scoreOffsets[1].y;

        //Then away from the last letter but in one direction
        offsetSize = 5;
        let offsetX = offsetSize * scoreOffsets[3].x;
        let offsetY = offsetSize * scoreOffsets[3].y;
        if ((this.cx < 50 && offsetX < 0) || (this.cx > 50 && offsetX > 0))
          this.cx -= offsetX;
        if ((this.cy < 50 && offsetY < 0) || (this.cy > 50 && offsetY > 0))
          this.cy -= offsetY;

        this.updateTransform();
        return;
      }

      case 3: {
        if (
          scoreOffsets[0].x == -scoreOffsets[1].x &&
          scoreOffsets[0].y == -scoreOffsets[1].y
        ) {
          //If the first two letters are diagonal then use a more narrow ellipse
          this.rx = 35;
          this.ry = 20;
        } else {
          //Otherwise use a wider ellipse than usual biased away from the last letter and towards the 1st
          this.rx = 35;
          this.ry = 27;
        }

        //Start towards the 1st letter
        let offsetSize = 6;
        this.cx += offsetSize * scoreOffsets[0].x;
        this.cy += offsetSize * scoreOffsets[0].y;

        //Move a little towards the 2nd letter
        offsetSize = 4;
        this.cx += offsetSize * scoreOffsets[1].x;
        this.cy += offsetSize * scoreOffsets[1].y;

        //Angle the ellipse across the diagonal, either the 1st and 3rd letter or 1st and 2nd, or 2nd and 3rd
        if (
          scoreOffsets[0].x == -scoreOffsets[1].x &&
          scoreOffsets[0].y == -scoreOffsets[1].y
        )
          this.rot =
            Math.atan2(
              scoreOffsets[0].y - scoreOffsets[1].y,
              scoreOffsets[0].x - scoreOffsets[1].x
            ) / 0.01745329252;
        else if (
          scoreOffsets[0].x == -scoreOffsets[2].x &&
          scoreOffsets[0].y == -scoreOffsets[2].y
        )
          this.rot =
            Math.atan2(
              scoreOffsets[0].y - scoreOffsets[2].y,
              scoreOffsets[0].x - scoreOffsets[2].x
            ) / 0.01745329252;
        else if (
          scoreOffsets[1].x == -scoreOffsets[2].x &&
          scoreOffsets[1].y == -scoreOffsets[2].y
        )
          this.rot =
            Math.atan2(
              scoreOffsets[1].y - scoreOffsets[2].y,
              scoreOffsets[1].x - scoreOffsets[2].x
            ) / 0.01745329252;

        offsetSize = -3;
        this.cx += offsetSize * scoreOffsets[3].x;
        this.cy += offsetSize * scoreOffsets[3].y;

        this.updateTransform();
        return;
      }

      case 2: {
        if (
          scoreOffsets[0].x == -scoreOffsets[1].x &&
          scoreOffsets[0].y == -scoreOffsets[1].y
        ) {
          //If the first two letters are diagonal then use a narrow ellipse and span both letters
          this.rx = 37;
          this.ry = 17;

          //Center the ellipse on the first letter
          let offsetSize = 8;
          this.cx += offsetSize * scoreOffsets[0].x;
          this.cy += offsetSize * scoreOffsets[0].y;

          //Then shift it towards the 2nd
          offsetSize = 5;
          this.cx += offsetSize * scoreOffsets[1].x;
          this.cy += offsetSize * scoreOffsets[1].y;

          //Shift it towards the 2nd but only in one direction
          //offsetSize = 5;
          //if (scoreOffsets[0].x != scoreOffsets[1].x)
          //  this.cx += offsetSize * scoreOffsets[1].x;
          //else
          //  this.cy += offsetSize * scoreOffsets[1].y;

          //And then a tad towards the third
          offsetSize = 3;
          this.cx += offsetSize * scoreOffsets[2].x;
          this.cy += offsetSize * scoreOffsets[2].y;

          //Angle the ellipse between the 1st & 2nd letters
          this.rot =
            Math.atan2(
              scoreOffsets[0].y - scoreOffsets[1].y,
              scoreOffsets[0].x - scoreOffsets[1].x
            ) / 0.01745329252;
          //but turn the ellipse towards the 3rd letter slightly
          let dir = 1;
          if (scoreOrder[0].letter == 'TR' && scoreOrder[1].letter == 'TL')
            dir = -1;
          if (scoreOrder[0].letter == 'TL' && scoreOrder[1].letter == 'BL')
            dir = -1;
          if (scoreOrder[0].letter == 'TL' && scoreOrder[1].letter == 'BR')
            dir = 0.8;
          if (scoreOrder[0].letter == 'BL' && scoreOrder[1].letter == 'TL')
            dir = -1;
          if (scoreOrder[0].letter == 'BR' && scoreOrder[1].letter == 'BL')
            dir = -1;
          if (
            (scoreOrder[0].letter == 'TR' &&
              scoreOrder[1].letter == 'BL' &&
              scoreOrder[2].letter == 'BR') ||
            (scoreOrder[0].letter == 'BL' &&
              scoreOrder[1].letter == 'TR' &&
              scoreOrder[2].letter == 'BR') ||
            (scoreOrder[0].letter == 'TL' &&
              scoreOrder[1].letter == 'BR' &&
              scoreOrder[2].letter == 'TR') ||
            (scoreOrder[0].letter == 'BR' &&
              scoreOrder[1].letter == 'TL' &&
              scoreOrder[2].letter == 'TR')
          ) {
            dir = 0.5;
            //Move a bit towards the last letter
            offsetSize = 2;
            this.cx += offsetSize * scoreOffsets[3].x;
            this.cy += offsetSize * scoreOffsets[3].y;
          }
          if (
            scoreOffsets[0].x == -scoreOffsets[2].x &&
            scoreOffsets[0].y == -scoreOffsets[2].y
          )
            this.rot -= scoreOffsets[0].y * 18 * dir;
          else this.rot += scoreOffsets[0].y * 18 * dir;

          this.updateTransform();
          return;
        } else {
          //If they are next to each other horizontally or vertically then use a smaller more circular ellipse and span both letters instead
          this.rx = 23;
          this.ry = 27;

          //Put it between the first two letters but a bit closer to the 1st
          let offsetSize = 16;
          if (scoreOffsets[0].x == scoreOffsets[1].x) {
            this.cx += offsetSize * scoreOffsets[0].x;
            this.cy += 6 * scoreOffsets[0].y;
          }
          if (scoreOffsets[0].y == scoreOffsets[1].y) {
            this.cx += 6 * scoreOffsets[0].x;
            this.cy += offsetSize * scoreOffsets[0].y;
          }

          //Angle the ellipse between the 1st & 2nd letters
          this.rot =
            Math.atan2(
              scoreOffsets[0].y - scoreOffsets[1].y,
              scoreOffsets[0].x - scoreOffsets[1].x
            ) / 0.01745329252;
          //but turn the ellipse towards the 3rd letter slightly
          let dir = 1;
          if (scoreOrder[0].letter == 'TR' && scoreOrder[1].letter == 'TL')
            dir = -1;
          if (scoreOrder[0].letter == 'TL' && scoreOrder[1].letter == 'BL')
            dir = -1;
          if (scoreOrder[0].letter == 'BL' && scoreOrder[1].letter == 'TL')
            dir = -1;
          if (scoreOrder[0].letter == 'BR' && scoreOrder[1].letter == 'BL')
            dir = -1;
          if (
            scoreOrder[0].letter == 'BR' &&
            scoreOrder[1].letter == 'BL' &&
            scoreOrder[2].letter == 'TL'
          ) {
            //Move a bit towards the last letter
            offsetSize = 2;
            this.cx += offsetSize * scoreOffsets[3].x;
            this.cy += offsetSize * scoreOffsets[3].y;
            this.ry = 34;
          }
          if (
            (scoreOrder[0].letter == 'TR' &&
              scoreOrder[1].letter == 'TL' &&
              scoreOrder[2].letter == 'BL') ||
            (scoreOrder[0].letter == 'TL' &&
              scoreOrder[1].letter == 'BL' &&
              scoreOrder[2].letter == 'BR') ||
            (scoreOrder[0].letter == 'BL' &&
              scoreOrder[1].letter == 'TL' &&
              scoreOrder[2].letter == 'TR') ||
            (scoreOrder[0].letter == 'TR' &&
              scoreOrder[1].letter == 'BR' &&
              scoreOrder[2].letter == 'BL') ||
            (scoreOrder[0].letter == 'BR' &&
              scoreOrder[1].letter == 'TR' &&
              scoreOrder[2].letter == 'TL')
          ) {
            //Make it a bit longer
            this.ry = 32;
          }
          //if (scoreOffsets[0].x == -scoreOffsets[2].x && scoreOffsets[0].y == -scoreOffsets[2].y)
          //  this.rot -= scoreOffsets[0].y * 30 * dir;
          //else
          this.rot += scoreOffsets[0].y * 35 * dir;

          //Move the ellipse towards the center but only in one direction
          //If the letters are horizontal then go vertically up / down
          //If vertical then go left / right
          if (scoreOffsets[0].x != scoreOffsets[1].x) {
            this.cy += (50 - this.cy) * 0.3;
            //Move it a little more towards the 1st letter in the opposite direction
            //this.cx += scoreOffsets[0].x * 4;
          } else {
            this.cx += (50 - this.cx) * 0.3;
            //Move it a little more towards the 1st letter in the opposite direction
            //this.cy += scoreOffsets[0].y * 4;
          }

          //If the first and last letters are not opposites diagonally then shift more towards the third letter, away from the last
          if (
            scoreOffsets[0].x != -scoreOffsets[3].x ||
            scoreOffsets[0].y != -scoreOffsets[3].y
          ) {
            offsetSize = 2;
            this.cx += offsetSize * scoreOffsets[2].x;
            this.cy += offsetSize * scoreOffsets[2].y;
            offsetSize = 1;
            this.cx -= offsetSize * scoreOffsets[3].x;
            this.cy -= offsetSize * scoreOffsets[3].y;
            this.rx -= offsetSize * 3;
            this.ry -= offsetSize;
          }

          this.updateTransform();
          return;
        }
        break;
      }

      case 1:
      case 0: {
        //Use a smaller & more circular ellipse
        this.rx = this.highCount == 0 ? 29 : 34;
        this.ry = this.highCount == 0 ? 18 : 20;

        //Centre the ellipse on the first letter
        let offsetSize = 14;
        this.cx += offsetSize * scoreOffsets[0].x;
        this.cy += offsetSize * scoreOffsets[0].y;

        //Shift it towards the 2nd but only in one direction
        offsetSize = 3;
        if (scoreOffsets[0].x != scoreOffsets[1].x)
          this.cx += offsetSize * scoreOffsets[1].x;
        else this.cy += offsetSize * scoreOffsets[1].y;

        //If the 1st & 2nd are opposite then shift more towards the 2nd
        if (
          scoreOffsets[0].x == -scoreOffsets[1].x &&
          scoreOffsets[0].y == -scoreOffsets[1].y
        ) {
          offsetSize = 3;
          this.cx += offsetSize * scoreOffsets[1].x;
          this.cy += offsetSize * scoreOffsets[1].y;
        }

        //Then again to the third
        offsetSize = 1;
        this.cx += offsetSize * scoreOffsets[2].x;
        this.cy += offsetSize * scoreOffsets[2].y;

        //And then a bit less so to the third
        //offsetSize = 4;
        //this.cx += offsetSize * scoreOffsets[2].x;
        //this.cy += offsetSize * scoreOffsets[2].y;

        //Angle the ellipse between the 1st & 2nd letters
        this.rot =
          Math.atan2(
            scoreOffsets[0].y - scoreOffsets[1].y,
            scoreOffsets[0].x - scoreOffsets[1].x
          ) / 0.01745329252;
        //but turn the ellipse towards the 3rd letter slightly
        let dir = 1;
        if (scoreOrder[0].letter == 'TR' && scoreOrder[1].letter == 'TL')
          dir = -1;
        if (scoreOrder[0].letter == 'TR' && scoreOrder[1].letter == 'BL') {
          dir = 0.3;
          this.ry *= 0.8;
        }
        if (scoreOrder[0].letter == 'TL' && scoreOrder[1].letter == 'BL')
          dir = -1;
        if (scoreOrder[0].letter == 'BL' && scoreOrder[1].letter == 'TL')
          dir = -1;
        if (scoreOrder[0].letter == 'BL' && scoreOrder[1].letter == 'BR')
          dir = 0.9;
        if (scoreOrder[0].letter == 'BR' && scoreOrder[1].letter == 'BL')
          dir = -1.3;
        if (scoreOrder[0].letter == 'BR' && scoreOrder[1].letter == 'TL') {
          dir = 0.3;
          this.ry *= 0.7;
        }
        if (scoreOrder[0].letter == 'TL' && scoreOrder[1].letter == 'BR') {
          dir = -0.3;
          this.ry *= 0.8;
        }
        if (scoreOrder[0].letter == 'BL' && scoreOrder[1].letter == 'TR') {
          dir = -0.3;
          this.ry *= 0.8;
        }
        //this.rot -= scoreOffsets[0].y * 38 * dir;

        if (
          scoreOffsets[0].x == -scoreOffsets[2].x &&
          scoreOffsets[0].y == -scoreOffsets[2].y
        )
          this.rot -= scoreOffsets[0].y * 32 * dir;
        else this.rot += scoreOffsets[0].y * 32 * dir;

        //If the first and last letters are opposites diagonally then shift more towards the last letter and make it more of a circle
        if (
          scoreOffsets[0].x == -scoreOffsets[3].x &&
          scoreOffsets[0].y == -scoreOffsets[3].y
        ) {
          offsetSize = 4;
          this.cx += offsetSize * scoreOffsets[3].x;
          this.cy += offsetSize * scoreOffsets[3].y;
          //  this.rx = highCount == 0 ? 29 : 34;
          //  this.ry = highCount == 0 ? 18 : 20;
          this.rx = this.highCount == 0 ? 26 : 28;
          this.ry = this.highCount == 0 ? 22 : 24;
        }

        this.updateTransform();
        return;
      }
    }
    this.updateTransform();
  }

  updateTransform() {
    //Determine the tangents to set up the lamp highlight
    this.clipTransform =
      this.rot == 0
        ? ''
        : 'rotate(' + this.rot + ' ' + this.cx + ' ' + this.cy + ')';
    this.lampTransform = 'translate(' + this.bx + ' ' + this.by + ')';

    //Determine the points of the ellipse, rotated as requested
    let rotRadians = (Math.PI / 180) * -this.rot;
    let rotCos = Math.cos(rotRadians);
    let rotSin = Math.sin(rotRadians);
    this.points = [...Array(360).keys()].map((deg) => {
      //Define the shape of the ellipse
      let x1 = Math.cos(deg * 0.01745329252) * this.rx;
      let y1 = Math.sin(deg * 0.01745329252) * this.ry;

      //Perform the rotation about the center of the ellipse
      let x = rotCos * x1 + rotSin * y1;
      let y = rotCos * y1 - rotSin * x1;

      return {
        x: x + this.cx - this.bx,
        y: y + this.cy - this.by,
      };
    });

    //Find the point closest to the lamp
    let distances = this.points.map((p) =>
      Math.sqrt(Math.pow(p.x, 2) + Math.pow(p.y, 2))
    );
    this.closest = Math.min.apply(null, distances);
    this.closestIndex = distances.indexOf(this.closest);
    this.closestX = this.points[this.closestIndex].x + this.bx;
    this.closestY = this.points[this.closestIndex].y + this.by;

    //Determine the angle of the lamp to the closest point
    let closestAngle = Math.atan2(
      this.points[this.closestIndex].x,
      this.points[this.closestIndex].y
    );
    if (closestAngle < 0) closestAngle += 2 * Math.PI;

    //Never need to go more than 90 degrees either way
    //Lets go clockwise first
    let highPoint = 0;
    let prevAngle = 0;
    let pointIndex = this.closestIndex;
    let highAngle = closestAngle;
    while (highPoint < 180) {
      highPoint++;

      pointIndex = this.closestIndex + highPoint;
      if (pointIndex < 0) pointIndex += 360;
      if (pointIndex >= 360) pointIndex -= 360;
      let pointAngle = Math.atan2(
        this.points[pointIndex].x,
        this.points[pointIndex].y
      );
      if (pointAngle < 0) pointAngle += 2 * Math.PI;
      let angleDif = Math.abs(
        pointAngle > closestAngle
          ? pointAngle - closestAngle
          : closestAngle - pointAngle
      );
      let angleDif2 = 2 * Math.PI - angleDif;

      //If the difference in angle is more than 180 degrees then measure the other way round instead
      if (angleDif > angleDif2) angleDif = angleDif2;
      let angleDelta = angleDif - prevAngle;

      if (angleDelta < 0) break;

      highAngle = pointAngle;
      prevAngle = angleDif;
    }
    this.highX = this.points[pointIndex].x + this.bx;
    this.highY = this.points[pointIndex].y + this.by;

    prevAngle = 0;
    let lowPoint = 0;
    pointIndex = this.closestIndex;
    let lowAngle = closestAngle;
    while (lowPoint > -180) {
      lowPoint--;

      pointIndex = this.closestIndex + lowPoint;
      if (pointIndex < 0) pointIndex += 360;
      if (pointIndex >= 360) pointIndex -= 360;
      let pointAngle = Math.atan2(
        this.points[pointIndex].x,
        this.points[pointIndex].y
      );
      if (pointAngle < 0) pointAngle += 2 * Math.PI;
      let angleDif = Math.abs(
        pointAngle > closestAngle
          ? pointAngle - closestAngle
          : closestAngle - pointAngle
      );
      let angleDif2 = 2 * Math.PI - angleDif;

      //If the difference in angle is more than 180 degrees then measure the other way round instead
      if (angleDif > angleDif2) angleDif = angleDif2;

      let angleDelta = angleDif - prevAngle;

      if (angleDelta < 0) break;

      lowAngle = pointAngle;
      prevAngle = angleDif;
    }
    this.lowX = this.points[pointIndex].x + this.bx;
    this.lowY = this.points[pointIndex].y + this.by;

    this.highlightTriangle =
      this.bx +
      ',' +
      this.by +
      ' ' +
      this.lowX +
      ',' +
      this.lowY +
      ' ' +
      this.highX +
      ',' +
      this.highY;

    //Determine the angle for the lamp, that isn't necessarily the closestAngle above but it is the mid point between the high & low angles

    let midAngle = (highAngle - lowAngle) / 2 + lowAngle;
    if (highAngle < lowAngle) midAngle += Math.PI;

    this.lampTransform += ' rotate(' + -midAngle / 0.01745329252 + ')';
  }
}
