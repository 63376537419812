import { createAction, props } from '@ngrx/store';

export const SetCurrentPageTitle = createAction(
  '[Status] Set Current Page Title',
  props<{ title: string }>()
);

export const SetPageSelected = createAction(
  '[Status] Set Page Selected',
  props<{ isPageSelected: boolean }>()
);
