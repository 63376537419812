<div *ngIf="growthDetails" class="details-container">
  <div #container class="growth-details-container pb-3 bg-white">
    <div class="growth-details-content d-flex flex-column">
      <div class="d-flex pb-4 pt-5 align-items-center bg-white top">
        <app-btn-action
          [icon]="back"
          [iconDimensions]="35"
          [secondary]="true"
          (actionClick)="closeDetailPopup()"
        ></app-btn-action>
        <h3 *ngIf="tags && tags.length" class="mx-2">{{ tags[0].tag }}</h3>
        <h3 *ngIf="isEngagementResource" class="mx-2">{{ categoryTitle }}</h3>
        <h3 *ngIf="isPlaybook" class="mx-2">{{ carouselHeading }}</h3>
        <div *ngIf="isWorkOn" class="d-flex">
          <div *ngFor="let tag of tags" class="mf-tag mf-tag-light mx-1">
            {{ tag.tag }}
          </div>
        </div>
      </div>
      <div class="heading bg-white">
        <p
          *ngIf="isWorkOn && workOn?.type == workOnType.MicroAction"
          class="type"
        >
          MICRO-ACTION
        </p>
        <p *ngIf="isWorkOn && workOn?.type == workOnType.Learn" class="type">
          LEARN
        </p>

        <p *ngIf="isEngagementResource" class="type">{{ subCategoryTitle }}</p>

        <p *ngIf="!isPlaybook && timeToRead > 1" class="type">
          <fa-icon [icon]="['fas', 'circle']" class="pe-2"></fa-icon
          >{{ timeToRead - 1 }}-{{ timeToRead }} min read
        </p>
        <p *ngIf="!isPlaybook && timeToRead <= 1" class="type">
          <fa-icon [icon]="['fas', 'circle']" class="pe-2"></fa-icon>1 min read
        </p>

        <div class="d-flex flex-column">
          <h2 (click)="scrollToSection('test')">{{ growthDetails.title }}</h2>
        </div>
      </div>
      <div class="tabs bg-white pt-3">
        <tabset class="w-100">
          <tab
            *ngFor="let section of growthDetails?.sections; index as i"
            [active]="
              (i == 0 && isUnscrolled) || activeTabScroll[section.title]
            "
          >
            <ng-template tabHeading>
              <h3 (click)="scrollToSection(section.title)">
                {{ !isWorkOn ? section.title : $any(section).shortTitle }}
              </h3>
            </ng-template>
          </tab>
          <!-- will need to include engagement resources in the below -->
          <tab *ngIf="!isWorkOn && hasResources">
            <ng-template tabHeading>
              <h3 (click)="scrollToSection('Resources')">Resources</h3>
            </ng-template>
          </tab>
        </tabset>
      </div>
      <div *ngIf="isScrollMenuShowing" class="d-flex bg-white scroll-menu-top">
        <app-btn-action
          [icon]="back"
          [iconDimensions]="35"
          [secondary]="true"
          (actionClick)="closeDetailPopup()"
        ></app-btn-action>
        <tabset class="ms-3 w-100">
          <tab
            *ngFor="let section of growthDetails.sections"
            [active]="activeTabScroll[section.title]"
          >
            <ng-template tabHeading>
              <h3 (click)="scrollToSection(section.title)">
                {{ !isWorkOn ? section.title : $any(section).shortTitle }}
              </h3>
            </ng-template>
          </tab>
          <tab *ngIf="!isWorkOn && hasResources">
            <ng-template tabHeading>
              <h3 (click)="scrollToSection('Resources')">Resources</h3>
            </ng-template>
          </tab>
        </tabset>
      </div>
      <div
        #content
        *ngFor="let section of growthDetails?.sections"
        class="formatted-content pt-3"
      >
        <h3 #contentTitle class="mb-3">{{ section.title }}</h3>
        <div [innerHtml]="section.contentHtml | safeHtml"></div>
      </div>
      <div
        #content
        *ngIf="!isWorkOn && hasResources"
        class="border-bottom-grey pb-4 pt-3"
      >
        <h3 #contentTitle class="mb-3">Resources</h3>
        <div class="d-flex justify-content-between flex-wrap">
          <div
            *ngFor="let resource of exercise?.resources"
            class="d-flex flex-column resource mb-3 cursor-pointer"
            (click)="downloadResource(resource)"
          >
            <div class="d-flex">
              <fa-icon [icon]="['far', 'file-lines']"></fa-icon>
              <h3 class="ps-2 flex-fill">{{ resource.fileName }}</h3>
              <app-btn-tertiary [icon]="next">View</app-btn-tertiary>
            </div>
            <ng-container
              *ngFor="let download of downloadService.downloadQueue"
            >
              <ng-container *ngIf="download.downloadName === resource.fileName">
                <ng-container *ngIf="download.download$ | async as dl">
                  <progressbar
                    class="position-relative mt-2"
                    [value]="dl.progress"
                    [animate]="true"
                    ><div
                      class="download-progress"
                      [class.text-primary]="dl.progress < 50"
                    >
                      {{ dl.progress }}%
                    </div></progressbar
                  >
                </ng-container>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="my-5 footer">
        <div
          class="row"
          *ngIf="
            isWorkOn && workOnType[workOn?.type!] == 'MicroAction' && nudgesQty
          "
        >
          <div class="col-12 col-md-6">
            <h3 class="pb-2">Want to put this into action?</h3>
            <div class="body-copy pb-2">
              <span *ngIf="nudgesQty"
                >There
                {{
                  nudgesQty | i18nPlural : nudgeCountMapping["nudge"]
                }}
                available for this Work On.</span
              >
              Click "Nudge me on this" and you will receive weekly nudges on
              your mobile app.
            </div>
          </div>
          <div class="col-12 col-md-6 text-end">
            <button
              class="btn btn-purple align-self-end mb-3"
              [disabled]="nudgeMeOnThisDisabled"
              (click)="nudgeMeOnThis()"
            >
              NUDGE ME ON THIS
            </button>
            <p class="body-copy">
              Don't have the mobile app?<br />Download it
              <button
                class="btn-link"
                (click)="showDownloadApp(downloadAppTemplate)"
              >
                here
              </button>
              to make sure you receive your nudges.
            </p>
          </div>
        </div>
        <button
          *ngIf="isWorkOn && workOnType[workOn?.type!] == 'InitialWorkOn'"
          class="btn btn-purple align-self-end mt-3"
          (click)="exploreGrowth()"
        >
          EXPLORE GROWTH OPPORTUNITIES
        </button>
        <button
          *ngIf="isPlaybook && (teamsILead$ | async)?.length"
          class="btn btn-purple align-self-end mt-3"
          [disabled]="addToTeamGrowthDisabled"
          (click)="addToTeamGrowth()"
        >
          ADD TO TEAM GROWTH
        </button>
      </div>
    </div>
  </div>
  <div class="shadow-container"></div>
</div>
<app-btn-tooltip>?</app-btn-tooltip>

<ng-template #downloadAppTemplate>
  <div class="MlogoContainer d-flex align-items-center ps-3">
    <img src="./assets/svg/mindflick_m.svg" class="Mlogo p-2" />
  </div>
  <div class="downloadText d-flex align-items-center px-4 fw-bold">
    <h2>Download the<br />Mindflick App</h2>
  </div>
  <div class="installText d-flex align-items-center body-copy fw-bold pe-5">
    Install the app to get the<br />most out of Mindflick
  </div>
  <div class="d-flex align-items-center pe-2">
    <button
      class="storeBtn"
      (click)="
        goToStore('https://apps.apple.com/gb/app/mindflick/id1549900116')
      "
    >
      <img class="appleBtnImg" src="./assets/images/storeLinks/appstore.svg" />
    </button>
  </div>
  <div class="d-flex align-items-center pe-3">
    <button
      class="storeBtn"
      (click)="
        goToStore(
          'https://play.google.com/store/apps/details?id=com.mindflick.spotlight'
        )
      "
    >
      <img
        class="googleBtnImg"
        src="./assets/images/storeLinks/google_play.png"
      />
    </button>
  </div>
</ng-template>
