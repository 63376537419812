import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as signalR from '@microsoft/signalr';
import { Store } from '@ngrx/store';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { firstValueFrom } from 'rxjs';
import { take } from 'rxjs/operators';
import {
  UpdateSpotlightDownloadPermission
} from 'src/app/+state/account-admin/account-admin.actions';
import { DeactivateUser } from 'src/app/+state/account/account.actions';
import { GetMyProfile } from 'src/app/+state/profile/profile.actions';
import {
  DownloadTeamMap,
  RemoveTeam,
  UpdateTeamDetails,
  UpdateTeamWithJoinRequest,
  UpdateTeamWithJoinRequestAccepted,
  UpdateTeamWithJoinRequestDeclined,
  UpdateTeamWithJoinRequestRevoked
} from 'src/app/+state/team/team.actions';
import {
  GetUser,
  GetUserByAK,
  UpdateCurrentUserDelegatedInvites
} from 'src/app/+state/user/user.actions';
import { selectCurrentUser } from 'src/app/+state/user/user.selector';
import { AppState } from '../../+state/app.state';
import * as SignalrActions from '../../+state/signalr/signalr.actions';
import { environment } from '../../../environments/environment';
import { Team } from '../models';
import { ConnectionStatus } from '../models/enums/connection-status.enum';
import { PortalNotification } from '../models/notifications.interface';
import { ProfileAccessNotification } from '../models/profile-access-notification.interface';
import { TeamMapDownloadStatus, UpdateTeamMapStatusReq } from '../models/team-map-processing.interface';
import { TeamRequestResponseModel } from '../models/team-request-notification';
import { MessagingService, SystemMessages } from './messaging.service';

const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

@Injectable({
  providedIn: 'root',
})
export class SignalrService {
  currentUser$ = this.store.select(selectCurrentUser);

  signalUrl = '';

  constructor(
    private router: Router,
    private store: Store<AppState>,
    private oidcSecurityService: OidcSecurityService,
    private messagingService: MessagingService,
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string
  ) {
    this.signalUrl = `${baseUrl}user`;
  }

  // Used to handle planned disconnects as opposed to unexpected ones
  private closeConnection: boolean = false;

  //This is public to allow the connection circles to subscribe to notifications directly without using this service
  public hubConnection?: signalR.HubConnection;

  public startConnection = async () => {
    if (this.hubConnection != null) {
      try {
        console.log('Closing down existing signalR connection');
        this.destroyHub();
        await delay(5000);
      } catch (e) {
        console.log('Error when trying to close signalR connection', e);
      }
    }

    this.closeConnection = false;
    this.store.dispatch(
      SignalrActions.SetConnectionStatus({
        connectionStatus: ConnectionStatus.Requested,
        error: null,
      })
    );

    // We have had some environments in the past where the client's network only partially supported websockets
    // so we added this method to allow us to disable it if we wanted
    // Hopefully it will never be needed in the platform
    var mindflickAccountId = localStorage.getItem(environment.localStoragePrefix + 'selected-mindflick-account');
    if (environment.disableWebSockets) {
      console.warn('SignaR WebSockets have been disabled');
      this.hubConnection = new signalR.HubConnectionBuilder()
        .withUrl(
          `${environment.signalRHub}?mindflick-account-id=${mindflickAccountId}`,
          {
            accessTokenFactory: () =>
              firstValueFrom(this.oidcSecurityService.getAccessToken()),
            transport: signalR.HttpTransportType.LongPolling,
          }
        )
        .withAutomaticReconnect([1000, 5000, 10000, 10000, 20000, 60000])
        .build();
    } else {
      this.hubConnection = new signalR.HubConnectionBuilder()
        .withUrl(
          `${environment.signalRHub}?mindflick-account-id=${mindflickAccountId}`,
          {
            accessTokenFactory: () =>
              firstValueFrom(this.oidcSecurityService.getAccessToken()),
            skipNegotiation: true,
            transport: signalR.HttpTransportType.WebSockets,
            headers: mindflickAccountId
              ? { 'custom-mindflick-account-id': mindflickAccountId }
              : {},
          }
        )
        .withAutomaticReconnect([1000, 5000, 10000, 10000, 20000, 60000])
        .build();
    }

    this.hubConnection.onclose(async (e: any) => {
      //If we are expecting the connection to close then do nothing
      if (this.closeConnection) {
        this.closeConnection = false;
        return;
      }
      console.log('signalR connection closed unexpectedly', e);

      //Log the error and attempt to reconnect
      this.store.dispatch(
        SignalrActions.SetConnectionStatus({
          connectionStatus: ConnectionStatus.Unconnected,
          error: e,
        })
      );

      await delay(10000);
      this.startConnection();
    });

    this.hubConnection.onreconnecting((e) => {
      this.store.dispatch(
        SignalrActions.SetConnectionStatus({
          connectionStatus: ConnectionStatus.Requested,
          error: e,
        })
      );
    });

    this.hubConnection.onreconnected((e) => {
      this.store.dispatch(
        SignalrActions.SetConnectionStatus({
          connectionStatus: ConnectionStatus.Connected,
          error: null,
        })
      );
    });

    this.hubConnection
      .start()
      .then(() => {
        this.store.dispatch(
          SignalrActions.SetConnectionStatus({
            connectionStatus: ConnectionStatus.Connected,
            error: null,
          })
        );
        console.log('Connection started');
      })
      .catch((err: any) => {
        this.store.dispatch(
          SignalrActions.SetConnectionStatus({
            connectionStatus: ConnectionStatus.Unconnected,
            error: err,
          })
        );
        console.log('Error while starting connection: ' + err);
      });

    // SUBSCRIBE TO MESSAGES HERE

    this.hubConnection.on('takeExampleMessage', (data: any) => {
      this.store.dispatch(SignalrActions.Example.Handle(data));
    });

    this.hubConnection.on(
      'takePortalNotification',
      (notification: PortalNotification) => {
        this.store.dispatch(SignalrActions.Handle({ notification }));
      }
    );

    this.hubConnection.on('takeUserDeactivated', (userAK: number) => {
      this.currentUser$.pipe(take(1)).subscribe((user) => {
        if (user && user.id === userAK)
          this.oidcSecurityService.logoff().subscribe(() => {});
      });
      this.store.dispatch(DeactivateUser.Success({ userAK: userAK }));
    });

    this.hubConnection.on('takeUserReactivated', (userAK: number) => {
      this.store.dispatch(GetUserByAK.Request({ userAK }));
    });

    this.hubConnection.on('takeTeamUpdated', (team: Team) => {
      console.log(team);
      this.currentUser$.pipe(take(1)).subscribe((user) => {
        if (user && !team.memberIds.includes(user.id)) {
          this.store.dispatch(RemoveTeam({ teamId: team.id }));

          this.startConnection();
          // Check if user is on team page before doing this
          // this.router.navigate(['/dashboard']);
        } else {
          this.store.dispatch(UpdateTeamDetails({ team }));
        }
      });
    });

    this.hubConnection.on('takeTeamDeleted', (teamId: number) => {
      this.store.dispatch(RemoveTeam({ teamId: teamId }));
      this.startConnection();
    });

    this.hubConnection.on('takeReportProcessed', () => {
      this.messagingService.sendSystemMessage(SystemMessages.ProfileProcessed);
    });

    this.hubConnection.on(
      'takeSpotlightDownloadPermissions',
      (enabled: boolean) => {
        this.store.dispatch(
          UpdateSpotlightDownloadPermission.update({ enabled: enabled })
        );
      }
    );

    this.hubConnection.on(
      'takeTeamJoinRequest',
      (msg: TeamRequestResponseModel) => {
        this.store.dispatch(
          UpdateTeamWithJoinRequest({ teamId: msg.teamId, userAk: msg.userAk })
        );
      }
    );

    this.hubConnection.on(
      'takeTeamJoinRequestRevoked',
      (msg: TeamRequestResponseModel) => {
        this.store.dispatch(
          UpdateTeamWithJoinRequestRevoked({
            teamId: msg.teamId,
            userAk: msg.userAk,
          })
        );
      }
    );

    this.hubConnection.on(
      'takeTeamJoinRequestAccepted',
      (team: Team, notification: PortalNotification) => {
        this.store.dispatch(UpdateTeamWithJoinRequestAccepted({ team }));
        this.store.dispatch(SignalrActions.Handle({ notification }));
        this.startConnection();
      }
    );

    this.hubConnection.on('takeTeamJoinRequestDeclined', (teamId: number) => {
      this.store.dispatch(UpdateTeamWithJoinRequestDeclined({ teamId }));
    });

    this.hubConnection.on('takeAccountClosed', (accountId: number) => {
      localStorage.clear();
      this.oidcSecurityService.logoff().subscribe(() => {});
    });

    this.hubConnection.on(
      'takeAllocatedSeatsChanged',
      (allocatedSeats: number) => {
        this.store.dispatch(
          UpdateCurrentUserDelegatedInvites({
            delegatedInvites: allocatedSeats,
          })
        );
      }
    );

    this.hubConnection.on(
      'updateTeamMapStatus',
      (teamMap: UpdateTeamMapStatusReq) => {
        if (teamMap.status == TeamMapDownloadStatus.Processed) {
          this.store.dispatch(DownloadTeamMap.MapProcessingUpdate({ teamMap }));
        }
      }
    );
  };

  public destroyHub() {
    if (this.hubConnection === undefined) return;
    this.closeConnection = true;
    this.hubConnection.stop();
    this.hubConnection = undefined;
  }
}
