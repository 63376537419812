<div class="legal-container">
  <div class="legal-page pb-3 bg-white pt-4">
    <div class="legal-content d-flex flex-column">
      <div class="d-flex pt-5 pb-4 align-items-center header">
        <app-btn-action
          class="legal-close fa-lg top"
          [icon]="close"
          [iconDimensions]="35"
          (click)="closePopup()"
        ></app-btn-action>
      </div>
      <div class="legal-item-container overflow-auto">
        <div class="legal-content-container w-75">
          <h1 class="pb-4">Mindflick Privacy Policy</h1>
          <p>Your privacy is important to us.</p>
          <p>
            This statement applies to the Mindflick Platform, which includes our
            personality profiling tool. We only collect information from you
            through the Platform with your permission, and in accordance with
            this policy.
          </p>
          <p>
            The purpose of this statement is to explain what personal data we
            collect from you, how we may use this data and how we protect it.
          </p>
          <p>We encourage you to read this policy.</p>
          <p>
            Mindflick Holdings Ltd is committed to protecting and respecting
            your privacy by complying with the General Data Protection
            Regulation and being registered with the UK Information
            Commissioner's Office (registration number ZA364623).
          </p>
          <h2>'At a Glance' Summary</h2>
          <p>
            As you complete the Mindflick questionnaire, the responses you give
            are securely sent to our servers based in the United Kingdom. We
            then use your responses to generate your unique profile. You will
            receive your profile through the Mindflick Platform or from an
            Accredited Practitioner, either in a 1-1 setting, or as a part of
            training event.
          </p>
          <p>
            Both Mindflick and Accredited Practitioners are required to never
            share the contents of your personal Profile with anyone else
            (including your employer) without your explicit permission.
          </p>
          <p>We will never sell your personal data for any purpose.</p>
          <h2>Here Are Some More Details:</h2>
          <h3>Who are we?</h3>
          <p>
            We are Mindflick Holdings Ltd (“Mindflick”) and we are registered in
            England and Wales (registered company number 09655591), with
            registered offices at Unit F, Whiteacres, Cambridge Road, Whetstone,
            Leicestershire, LE8 6ZG.
          </p>
          <p>
            Mindflick is committed to protecting and respecting your privacy,
            and to acting in compliance with the General Data Protection
            Regulation (registration number Z6917638). Mindflick is the “data
            controller” for all of the information you enter into the Mindflick
            Platform and questionnaire. For more information about Mindflick,
            please visit www.mindflick.co/why-mindflick
          </p>
          <h3>What Data Do We Collect & Process?</h3>
          <div>
            <p>
              When you complete the Mindflick questionnaire, Mindflick obtain
              data from you in order to generate your profile - a short report
              detailing your performance preferences.
            </p>
            <ul>
              <li>
                <b>Personal Details:</b>
                <p class="m-0">
                  To complete the Mindflick questionnaire, you will be asked for
                  the following “Personal Details”:
                </p>
                <ul>
                  <li>First Name and Family Name</li>

                  <li>Name of your employer / organisation</li>

                  <li>Email address</li>

                  <li>Gender</li>
                </ul>
                <p>
                  Customers often choose to include demographics within the
                  platform such as job title.
                </p>
              </li>
              <li>
                <b>Password</b>
                <p>
                  You will be asked to create a secure password that you must
                  keep private to you. We will keep your password secure in an
                  encrypted file and will never ask you to disclose your
                  password.
                </p>
              </li>
              <li>
                <b>Preference Data:</b>
                <p>
                  Once you have entered your Personal Details, you will be shown
                  a series of adjectives and asked to rate how well these
                  describe you relative to the other adjectives presented.
                  Mindflick then uses this “Preference Data” to generate your
                  Profile.
                </p>
              </li>
            </ul>
            <p>
              When you access the Mindflick Platform, we may collect the
              following data directly from you or through how you interact with
              the Platform:
            </p>
            <ul>
              <li>
                <b>Usage Information:</b>
                <p>
                  We collect usage data about you whenever you interact with our
                  Services, including the pages you visit, what you click on and
                  when you performed those actions. We also keep log data each
                  time a device accesses our servers, including the IP address.
                </p>
              </li>
              <li>
                <b>IP Address</b>
                <p>
                  Our UK based servers will also record the IP address of the
                  computer you use to complete the Mindflick questionnaire. We
                  only use this information to help us to identify and resolve
                  any technical issues that might occur whilst you are using the
                  Mindflick platform, to identify the country that you are based
                  in, and to ensure the security and functioning of the website.
                  We will keep a record of your IP address for no more than 24
                  hours.
                </p>
              </li>
              <li>
                <b>Account Information:</b>
                <p>
                  When an account is created for you by an administrator, we may
                  collect information from your company including your name,
                  email address, title and role.
                </p>
              </li>
              <li>
                <b>Your content:</b>
                <p>
                  When you use the Platform as an individual user, we will store
                  details of your usage and any content that you submit to us.
                  This may include feedback, connecting within other
                  individuals, images and messages.
                </p>
              </li>
            </ul>
          </div>
          <h3>Why Do We Process the Data You Provide?</h3>
          <p>
            Our lawful basis for processing your personal data is that it is
            necessary for the purposes of legitimate interests (i.e the
            production of the Mindflick profile and usage of the Mindflick
            Platform) pursued by the controller or a third party.
          </p>
          <p>
            Mindflick uses the Data you provide to generate your Mindflick
            Profile and allow you to utilise the Mindflick Platform. We will
            provide a copy of your Profile to you via the Mindflick Platform or
            within a workshop or during a 1-1 facilitated by an Accredited
            Practitioner.
          </p>
          <p>
            If your employer or organisation requests it, we may use the data to
            provide them with your overall “Performance Preference” and to
            generate a “Team Map” showing where the Positions of you and your
            colleagues sit in relation to each other.
          </p>
          <h3>Further Information:</h3>
          <p>
            Within a Mindflick profile, we use colours and a shaded spotlight to
            represent the recipient’s unique blend of preferences. This also
            conveys how they tend to behave and how they tend to respond when
            there is something to be won or lost (this will be covered in detail
            within the Platform or during your workshop or debrief). The
            combination of colours is also summarised by a Performance
            Preference.
          </p>
          <p>
            A Team Map is a graphic representation of your Performance
            Preferences together with those of your colleagues. Each of the
            Positions on a team map represents a different combination of colour
            energies.
          </p>
          <p class="font-italic">
            It is important to understand that the purpose of the Mindflick
            Platform and Profile is to help an individual learn and develop,
            encouraging the recipient to find new ways to flex their style in
            order to connect with others, and find new ways to cope more
            effectively. The Mindflick Profile and data from the Mindflick
            Platform in no way measure capability and therefore should never be
            used for the purposes of selection or recruitment.
          </p>
          <h3>What Happens After You Stop Using the Service?</h3>
          <p>
            Following your workshop or 1-1 debrief, Mindflick will keep your data on its secure servers
            for a maximum of 5 years until and unless you request that we delete it before that date.
          </p>
          <div>
            <p class="m-0">We may use this data for the following purposes:</p>

            <ul>
              <li>To improve our products and services;</li>

              <li>To conduct further research and product testing.</li>

              <li>
                To let you see how your preferences change over time, i.e. if
                you were to complete the profile again in the future.
              </li>
            </ul>
          </div>
          <p>
            Should you 'opt-in' and grant us permission to contact you for
            marketing purposes, we may also use your information to communicate
            directly with you. For example, informing you about new updates and
            product information;
          </p>
          <h3>Who Has Access to Your data?</h3>
          <p>
            We do not sell, rent or exchange your personal information with any
            other third party for commercial reasons.
          </p>
          <p>
            To receive your Mindflick Profile, you must access the Platform or
            attend a workshop, 1-1 debrief, or coaching session facilitated by
            an Accredited Practitioner. In order to do this, we may share your
            Mindflick Profile with the Practitioner before they deliver it to
            you.
          </p>
          <div>
            <p>All Accredited Practitioners are either:</p>
            <ul>
              <li>Mindflick employees</li>
              <li>Independent licensed Practitioners; or are</li>
              <li>
                Employed by the company or organisation that purchased the
                profile for you.
              </li>
            </ul>
          </div>
          <p>
            In order to maintain their Accreditation, all Accredited
            Practitioners must protect your right to confidentiality, and handle
            your data in compliance with this policy. They will only share your
            data with others, including your colleagues and your employer,
            either (a) where it is permitted by this policy, or (b) if you give
            your explicit permission.
          </p>
          <p>
            During a workshop or team meeting, you may be invited to share
            information from your profile with the group. However, only you will
            decide whether to share your profile. Accredited Practitioners may
            have administrative assistants to help them in preparing and
            delivering your profile to you. We only permit these individuals to
            handle your profile for the purpose of assisting the Practitioner in
            providing a copy of the profile directly to you.
          </p>
          <p>
            Accredited Practitioners may also use external printing suppliers to
            produce a hard copy of your profile for you. Accredited
            Practitioners must check external printing suppliers’ respective
            privacy policies, confirming that they are GDPR compliant. If such a
            supplier is used, the Accredited Practitioner must also request that
            your information is deleted after your profile has been printed.
          </p>
          <p>
            If your employer or organisation requests a Team Map, then we will
            provide this. However, we will still not share your unique Spotlight
            Profile without your explicit permission.
          </p>
          <h3>Where is your data stored and how do we keep it secure?</h3>
          <p>
            Your data (including your personal data and your responses to the
            Spotlight questionnaire) is stored on secure servers located in the
            United Kingdom.
          </p>
          <p>
            We take the security of your data and the accessibility to our
            systems very seriously. The UK based Data Centre's that we use are
            compliant with the ISO 27018 standard for cloud privacy. With the
            main Data Centres located in London and Cardiff, data is stored
            within the boundaries of UK laws. Full testing has been performed to
            ensure that these act in a failover pair, thus again ensuring data
            can reside within the boundaries of the UK even during a Data Centre
            outage.
          </p>
          <p>
            By submitting your personal data, you agree to this. We take steps
            to ensure that the information we collect is processed according to
            this Privacy Statement and the requirements of applicable law
            wherever the data is located.
          </p>
          <h3>What Happens in the Event of a Data Breach?</h3>
          <p>
            Mindflick have a procedure in place if there is a breach of security
            leading to the accidental or unlawful destruction, loss, alteration,
            unauthorised disclosure of, or access to, personal data transmitted,
            stored or otherwise processed.
          </p>
          <p>
            In such cases, we will assess the scope and impact of the breach.
            Based on this assessment and the likely risks to the rights and
            freedoms of individuals, we will notify the individuals and/or their
            connected organisations that a data breach has occurred. Any such
            notification to individuals will be carried out as soon as
            reasonably possible and will include information on the nature of
            the breach. Such individuals will also be provided advice on how to
            make a complaint to the UK Information Commissioner's Office (ICO).
          </p>
          <p>
            As Mindflick Holdings Ltd is a UK company we are legally required to
            register with the UK ICO, an independent authority set up to uphold
            data privacy. The ICO is internationally recognised as being at the
            forefront of the protection of personal information. If, due to the
            nature of a data breach Mindflick is required to inform the ICO, we
            will do so within 72 hours of becoming aware of the essential facts
            of the breach.
          </p>
          <p>
            It is important to note that Mindflick Holdings Ltd cannot be held
            responsible for client-side breaches of data confidentiality.
          </p>
          <h3>What countries could your data could be transferred to?</h3>
          <p>
            Mindflick provides products and services to customers around the
            world. However, we store your data on our servers in the UK. We will
            only transfer your data outside of the European Economic Area
            (“EEA”) in the following situations:
          </p>
          <ul>
            <li>
              You are outside the EEA and we are transferring your data to you;
              or
            </li>

            <li>
              We are transferring your data to an practitioner, Mindflick
              employee or an employee of your organisation who is using the
              Mindflick platform.
            </li>
          </ul>

          <p>
            All Accredited Practitioners are required to handle the personal
            data in strict compliance with this policy and with the General Data
            Protection Regulation.
          </p>
          <h3>How Long Will We Retain Your Data?</h3>
          <p>
            We will only keep data for as long as necessary to meet these
            purposes. At your request, we will delete your personal data from
            our records.
          </p>
          <p>
            Requests for the deletion of personal data should be sent to our
            Data Protection Officer by email (support&#64;mindflick.co) or at the
            postal address at the end of this policy.
          </p>
          <p>
            If you have any questions about Mindflick Holdings Ltd's privacy
            policy, wish to opt out of direct marketing, or wish to request any
            information about your personal data, please contact us at:
          </p>
          <div class="d-flex flex-column fw-bold">
            <p class="m-0">The Data Protection Officer,</p>
            <p class="m-0">Mindflick Holdings Ltd,</p>
            <p class="m-0">4, The Barn,</p>
            <p class="m-0">Hathersage Hall Business Centre,</p>
            <p class="m-0">Main Road,</p>
            <p class="m-0">Hathersage,</p>
            <p class="m-0">Derbyshire,</p>
            <p class="m-0">S32 1BB,</p>
            <p class="m-0">E-mail: support&#64;mindflick.co.uk,</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="shadow-container"></div>
</div>
