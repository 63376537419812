import { Component, Input, OnInit } from '@angular/core';
import { LegendPosition, ScaleType } from '@swimlane/ngx-charts';
import { BsModalService } from 'ngx-bootstrap/modal';
import { GrowthType } from 'src/app/+state/growth/growth.reducer';
import { NudgeDetailsModalComponent } from 'src/app/shared/components/nudge-details-modal/nudge-details-modal.component';
import {
  faCloseSVG,
  faHelpSVG
} from 'src/icons';
import { LiveAnalyticsGraphTypes } from '../../shared/models/enums/admin-dashboard-graph-types.enum';
import { LiveAnalyticsGraph } from '../../shared/models/live-analytics/live-analytics-graph.interface';
import { LiveAnalyticsNudgeDTO } from '../../shared/models/live-analytics/top-live-analytics-nudge-dto.interface';

@Component({
  selector: 'app-admin-live-analytics-graph',
  templateUrl: './admin-live-analytics-graph.component.html',
  styleUrls: ['./admin-live-analytics-graph.component.scss'],
})
export class AdminLiveAnalyticsGraphComponent implements OnInit {
  @Input()
  graph?: LiveAnalyticsGraph;

  @Input() containerWidth = 300;


  LegendPosition = LegendPosition;
  question = faHelpSVG;
  close = faCloseSVG;

  horizontalBarScheme = {
    domain: ['#7000DD', '#FF5F00'],
    name: '',
    selectable: false,
    group: ScaleType.Ordinal,
  };

  allPurplePantonesScheme = {
    domain: ['#7000DD', '#9A4CE7', '#C699F1', '#E2CCF8', '#F8F2FE'],
    name: '',
    selectable: false,
    group: ScaleType.Ordinal,
  };

  allOrangeScheme = {
    domain: ['#FF5F00', '#FD8740', '#FDAE7F', '#FECEB2', '#FFEFE5'],
    name: '',
    selectable: false,
    group: ScaleType.Ordinal,
  };

  isDescriptionVisible = false;

  GrowthType = GrowthType;
  GraphTypes = LiveAnalyticsGraphTypes;

  constructor(
    private modalService: BsModalService
  ) {}

  ngOnInit(): void {}

  formatGaugeValue(data: string) {
    return Math.round(Number(data)) + '%';
  }

  formatBarYAxis(val: any) {
    if (val % 1 != 0) {
      return undefined;
    }
    return val;
  };

  showNudgeDetails(nudge: LiveAnalyticsNudgeDTO) {
    this.modalService.show(NudgeDetailsModalComponent, {
      initialState: {
        nudge: nudge,
      },
    });
  }
}
