<div class="adminDropdownContainer position-absolute py-0 scrollable">
  <div class="admin-list-container" (mouseleave)="hideAdminList()" (mouseenter)="showAdminList()">
    <img src="./assets/svg/headers/teams_taller.svg" class="header-image">
    <div class="d-flex justify-content-between">
      <h2>Admin</h2>
    </div>

      <div
      class="admin-card row gx-0 border-bottom-grey"
    >
      <div class="col-md-8 d-flex">
        <h1 class="sectionName">Live Analytics</h1>
        <div #selectTeamBtn class="h-50 ms-4 align-self-end">
          <app-btn-action
            [icon]="next"
            [iconDimensions]="selectTeamBtn.offsetHeight"
            (click)="navigateTo('live-analytics')"
          ></app-btn-action>
        </div>
      </div>
      <div class="col-md-8 d-flex pt-5">
        <h1 class="sectionName">User Management</h1>
        <div #selectTeamBtn class="h-50 ms-4 align-self-end">
          <app-btn-action
            [icon]="next"
            [iconDimensions]="selectTeamBtn.offsetHeight"
            (click)="navigateTo('user-manage')"
          ></app-btn-action>
        </div>
      </div>
    </div>
  </div>
  <div class="shadow-container"></div>
</div>
