<ng-container *ngIf="selectedUser$ | async as user">
  <div class="modal-header justify-content-end">
    <fa-icon
      class="cursor-pointer fa-lg"
      [icon]="closeIcon"
      (click)="close()"
    ></fa-icon>
  </div>
  <div class="modal-body">
    <h2 class="mb-4">Manage allocated seats for {{ user.name }}</h2>
    <h3 class="mb-4">
      Set how many platform invites you want this user to be able to send out to
      colleagues.
    </h3>
    <form [formGroup]="delegateInvitesForm" class="row mb-4 gx-0">
      <div class="col-12 pb-2">
        <label class="form-label">Allocated Seats</label>
        <input
          class="form-control"
          type="number"
          formControlName="delegatedInvites"
          [min]="minValue"
          [max]="maxValue"
        />
        <div
          *ngIf="
            delegateInvitesForm.invalid &&
            delegateInvitesForm.touched &&
            delegateInvitesForm.controls.delegatedInvites.errors?.min
          "
          class="form-error"
        >
          Value can't be lower than seats already used
        </div>
        <div
          *ngIf="
            delegateInvitesForm.invalid &&
            delegateInvitesForm.touched &&
            delegateInvitesForm.controls.delegatedInvites.errors?.max
          "
          class="form-error"
        >
          You can't allocate more seats than you have remaining
        </div>
      </div>
      <div class="col-12 from-date position-relative">
        <label class="form-label"
          >Unused Seats Expiry Date (optional)
          <fa-icon
            class="cursor-pointer text-primary"
            [icon]="question"
            tooltip="The date on which any unused seats will automatically be revoked"
          ></fa-icon
        ></label>
        <input
          formControlName="delegatedInvitesExpiry"
          type="text"
          placeholder="Expiry Date"
          class="form-control"
          bsDatepicker
          [container]="'.from-date'"
               [minDate]="minDate"
          [bsConfig]="{ showWeekNumbers: false, dateInputFormat: 'DD/MM/YYYY' }"
        />
      </div>
    </form>
    <button
      class="btn btn-purple w-100"
      [disabled]="delegateInvitesForm.invalid"
      (click)="saveChanges()"
    >
      Save Changes
    </button>
  </div>
  <div class="modal-bottom-border"></div>
</ng-container>
