import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { interval, Subscription } from 'rxjs';
import { PlatformEventAudit } from '../models/audit.interface';
import { AuditEventType, PlatformAreas } from '../models/enums/audit.enum';

@Injectable({
  providedIn: 'root',
})
export class AuditService {
  auditUrl: string = '';

  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string
  ) {
    this.auditUrl = `${baseUrl}audit`;
  }

  saveAudit(eventAudits: PlatformEventAudit[]) {
    return this.http.post(`${this.auditUrl}/save`, eventAudits);
  }
}
