import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Component, Inject, OnInit } from '@angular/core';
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons';
import { faDownload, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { Store } from '@ngrx/store';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { FileUploader } from 'ng2-file-upload';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import {
  AddUsers,
  SetAddUsersSuccess,
} from 'src/app/+state/account-admin/account-admin.actions';
import {
  selectAddUserResultMessage,
  selectAddUserSuccess,
  selectSelectedUserManagementAccountId,
} from 'src/app/+state/account-admin/account-admin.selector';
import { selectAllAccounts } from 'src/app/+state/account/account.selector';
import { GetUserManagementUsers } from 'src/app/+state/admin/admin.actions';
import { selectUserManagementUsers } from 'src/app/+state/admin/admin.selector';
import { AppState } from 'src/app/+state/app.state';
import { SelectValue } from 'src/app/shared/models';
import { AddedUsersResponseModel } from 'src/app/shared/models/account-admin-managed-users-DTO';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { environment } from 'src/environments/environment';
import { faCloseSVG } from 'src/icons';
import { DisplaySnackbarAlert } from '../../+state/layout/actions/layout.actions';
import { AddQuestionnaires } from '../../+state/super-admin/super-admin.actions';
import { selectQuestionnaires } from '../../+state/super-admin/super-admin.selector';
import { AddDeactivatedUsersModalComponent } from '../../shared/components/add-deactivated-users-modal/add-deactivated-users-modal.component';
import { AddQuestionnaireInvitesArgs } from '../../shared/models/add-questionnaire-invite.interface';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-bulk-invite-users',
  templateUrl: './bulk-invite-users.component.html',
  styleUrls: ['./bulk-invite-users.component.scss'],
  animations: [
    trigger('addUserShowConfirm', [
      state(
        'addUser',
        style({
          height: '396.43px',
          opacity: 1,
          'overflow-y': 'inherit',
        })
      ),
      state(
        'showConfirm',
        style({
          height: '290.8px',
          opacity: 1,
          'overflow-y': 'inherit',
        })
      ),
      transition('addUser <=> showConfirm', animate('300ms 0ms ease-out')),
    ]),
  ],
})
export class BulkInviteUsersComponent implements OnInit {
  destroyed$ = new Subject<boolean>();
  mindflickAccounts$ = this.store.select(selectAllAccounts);
  users$ = this.store.select(selectUserManagementUsers);
  addUsersSuccess$ = this.store.select(selectAddUserSuccess);
  addUsersResultMessage$ = this.store.select(selectAddUserResultMessage);
  questionnaires$ = this.store.select(selectQuestionnaires);
  selectedAccountId$ = this.store.select(selectSelectedUserManagementAccountId);

  sendingInvites = false;
  sendingInvitesSuccess = false;
  sendingInvitesError = '';

  fileToUpload?: File | null;

  mindflickAccounts: SelectValue[] = [];

  selectedAccount?: number;
  accountReadonly: boolean = false;

  closeIcon = faCloseSVG;
  download = faDownload;

  isAccountAdminModalVersion: boolean = false;

  isDisabled: boolean = true;

  fileTypeWarning: boolean = false;

  questionIcon = faCircleQuestion;
  infoIcon = faInfoCircle;
  currentAccessToken: string = '';

  referrerOptions: SelectValue[] = [];
  selectedReferrer?: number;

  creditPotOptions: SelectValue[] = [];
  selectedCreditPot?: number;

  questionnaireOptions: SelectValue[] = [{ value: undefined, description: 'none' }];
  selectedQuestionnaire?: number;

  debriefDate?: Date;

  showNoLockConfirmation = false;

  tomorrow = new Date();

  fcIsDigitalDebriefRequired = new FormControl<boolean>(true, [Validators.required]);
  fcIsConnectionRequired = new FormControl<boolean>(false, [Validators.required]);

  debriefOptions: SelectValue[] = [{ value: false, description: "In-person debrief", subText: "The user will be debriefed in-person (recommended for team or one-to-one coaching sessions)" }, { value: true, description: "Digital debrief", subText: "The user will be debriefed online during their onboarding" }];

  invalidDebriefDate = false;

  public downloadCsvTemplate =
    environment.platformResourceStorageUrl + 'MindflickBulkUserUpload.xlsx';

  constructor(
    private modalRef: BsModalRef,
    private store: Store<AppState>,
    private spinner: NgxSpinnerService,
    private securityService: OidcSecurityService,
    private modalService: BsModalService,
    private dialogService: DialogService,
    @Inject('BASE_URL') private baseUrl: string
  ) {}

  ngOnInit(): void {
    this.tomorrow.setDate(this.tomorrow.getDate() + 1);

    this.securityService
      .getAccessToken()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((accessToken) => {
        this.currentAccessToken = accessToken;
      });
    this.mindflickAccounts$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((accounts) => {
        this.mindflickAccounts = accounts.map((x) => {
          return { value: x.id, description: x.name };
        });
      });

    this.users$.pipe(takeUntil(this.destroyed$)).subscribe((users) => {
      this.referrerOptions = [{ value: undefined, description: 'None' }];

      this.referrerOptions.push(
        ...users.map(
          (x) =>
          ({
            value: x.ak,
            description: x.firstName + ' ' + x.lastName,
          } as SelectValue)
        )
      );
    });

    this.questionnaires$.pipe(takeUntil(this.destroyed$)).subscribe((questionnaires) => {
      this.questionnaireOptions = [{ value: undefined, description: 'None' }];

      this.questionnaireOptions.push(
        ...questionnaires.filter(x => !(x.longName.toLowerCase().includes("post") || x.longName.includes("360")))
          .map(
            (x) =>
            ({
              value: x.id,
              description: x.longName,
            } as SelectValue)
          )
      );
    });

    if (this.isAccountAdminModalVersion) {
      this.selectedAccountId$.pipe(take(1)).subscribe(accoundId => {
        this.selectedAccount = accoundId;
      });
    }
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.unsubscribe();
  }

  submitForm() {
    if (!this.fileToUpload) {
      this.sendingInvitesError = "No file uploaded";
      return;
    } else {
      this.sendingInvitesError = "";
    }

    if (
      !this.selectedAccount
    )
      return;

    const debriefDateInvalid = !this.fcIsDigitalDebriefRequired.value && !this.debriefDate;

    if (debriefDateInvalid) {
      this.invalidDebriefDate = debriefDateInvalid;
      return;
    }
    
    this.sendInvites();
  }

  sendInvites() {
    if (
      !this.fileToUpload ||
      !this.selectedAccount
    )
      return;

    this.sendingInvites = true;
    this.spinner.show();

    let urlString = this.isAccountAdminModalVersion
      ? `${this.baseUrl}accountadmin/account/${this.selectedAccount}/users/bulk-add`
      : `${this.baseUrl}superadmin/bulk-add-users`;

    var fileUploader = new FileUploader({
      url: urlString,
      isHTML5: true,
      autoUpload: true,
      authToken: 'Bearer ' + this.currentAccessToken,
      removeAfterUpload: true,
      headers: [
        {
          name: 'custom-mindflick-account-id',
          value: localStorage.getItem(environment.localStoragePrefix + 'selected-mindflick-account')!,
        },
      ],
    });

    fileUploader.onBuildItemForm = (item, form) => {
      if (!this.isAccountAdminModalVersion) {
        form.append('accountId', this.selectedAccount);

        if (this.selectedReferrer)
          form.append('referrerAk', this.selectedReferrer);

        if (this.selectedCreditPot)
          form.append('creditPotId', this.selectedCreditPot);
      } else {
        form.append('isConnectionRequired', this.fcIsConnectionRequired.value);
      }

      if (this.debriefDate) {
        this.debriefDate.setHours(0, 0, 0, 0);
        form.append('debriefDate', this.debriefDate.toUTCString());
      }
    };

    fileUploader.onSuccessItem = (item, response, status, headers) =>
      this.bulkInviteResponse(response, status);
    fileUploader.onErrorItem = (item, response, status, headers) =>
      this.bulkInviteResponse(response, status);

    fileUploader.addToQueue([this.fileToUpload]);

    fileUploader.uploadAll();

    this.fileToUpload = null;
  }

  cancelNoLock() {
    this.fileToUpload = null;
    this.showNoLockConfirmation = false;
    this.sendingInvitesError = "";
  }

  confirmNoLock() {
    this.sendInvites();
  }

  handleFileUpload(target: EventTarget | null) {
    this.fileToUpload = (target as HTMLInputElement)?.files?.item(0);


    if (
      this.fileToUpload &&
      ['.csv', '.xls', 'xlsx', 'xlsm'].some((fileType) =>
        this.fileToUpload?.name.endsWith(fileType)
      )
    ) {
      this.fileTypeWarning = false;
      this.isDisabled = false;
      this.sendingInvitesError = "";

    } else {
      this.isDisabled = true;
      this.fileTypeWarning = true;
    }
  }

  mindflickAccountSelectionChanged(value: number) {
    this.creditPotOptions = [];

    if (value) {
      this.mindflickAccounts$.pipe(take(1)).subscribe((accounts) => {
        const selectedAccount = accounts.find((x) => x.id === value);

        if (selectedAccount && selectedAccount.creditPots.length) {
          this.creditPotOptions = [{ value: undefined, description: 'None' }];
          this.creditPotOptions.push(
            ...selectedAccount.creditPots.map((x) => {
              return { value: x.id, description: x.ownerName } as SelectValue;
            })
          );
        }
      });
    }
  }

  close() {
    this.modalRef.hide();
  }

  bulkInviteResponse(response: string, status: number) {
    this.sendingInvites = false;

    if (status === 200) {
      this.handleInviteSuccess(response);
    } else {
      this.sendingInvitesSuccess = false;
      this.sendingInvitesError = response;
    }
  }

  private handleInviteSuccess(response: string) {
    this.sendingInvitesSuccess = true;

    let resp = JSON.parse(response) as AddedUsersResponseModel;

    if (resp.success && resp.deactivatedUsers.length > 0) {
      this.modalService.show(AddDeactivatedUsersModalComponent, {
        initialState: {
          deactivatedUsers: resp.deactivatedUsers,
          addedUserCount: resp.addedUsers.length,
          usersNotAdded: resp.usersNotAdded,
          debriefDate: this.debriefDate
        },
        class: 'modal-dialog-centered',
      });
      this.close();
      return;
    }

    if (this.isAccountAdminModalVersion) {
      if (resp.success) {
        this.store.dispatch(AddUsers.Success({ resp: resp }));
        if (resp.addedUsers.length && resp.usersNotAdded.length) {
          this.store.dispatch(
            DisplaySnackbarAlert.SetAlert({
              alert: {
                alertType: 'info',
                messageHeader: 'Info',
                message: `You added ${resp.addedUsers.length} user(s), and ${resp.usersNotAdded.length} user(s) were not added.`,
              },
            })
          );
        } else if (resp.addedUsers.length > 0) {
          this.store.dispatch(
            DisplaySnackbarAlert.SetAlert({
              alert: {
                alertType: 'success',
                messageHeader: 'Success!',
                message: `You added ${resp.addedUsers.length} user(s).`,
              },
            })
          );
        }
        this.close();
      } else {
        this.sendingInvitesSuccess = false;
        this.showTooManyUsersModal(
          resp.message ? resp.message : 'Could not add user(s)'
        );
      }
    } else {
      if (this.selectedAccount) {
        this.store.dispatch(
          GetUserManagementUsers.Request({
            mindflickAccountId: this.selectedAccount,
          })
        );
      }


      if (resp.addedUsers.length > 0 && this.selectedQuestionnaire) {
        var invites: AddQuestionnaireInvitesArgs[] = resp.addedUsers.map(x => {
          return {
            spotlightResponseId: x.spotlightResponseId,
            questionnaireId: this.selectedQuestionnaire
          } as AddQuestionnaireInvitesArgs;
        }
        );

        this.store.dispatch(AddQuestionnaires.Request({ questionnaireInvites: invites }));
      }


      this.close();
    }
  }

  private showTooManyUsersModal(message: string) {
    this.dialogService
      .confirm(message, 'User(s) not added')
      .pipe(take(1))
      .subscribe((_) => {
        this.store.dispatch(
          SetAddUsersSuccess({ success: undefined, message: undefined })
        );
      });
  }
}
