import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GetHelpCenterCategories } from '../../../+state/account-admin/account-admin.actions';
import { selectAllHelpCenterCategories } from '../../../+state/account-admin/account-admin.selector';
import { AppState } from '../../../+state/app.state';
import { HelpCenterCategory } from '../../../shared/models/help-center.interface';

@Component({
  selector: 'app-category-page',
  templateUrl: './category-page.component.html',
  styleUrls: ['./category-page.component.scss'],
})
export class CategoryPageComponent implements OnInit, OnDestroy {
  categories$ = this.store.select(selectAllHelpCenterCategories);
  destroyed$: Subject<boolean> = new Subject<boolean>();

  category: HelpCenterCategory = {} as HelpCenterCategory;
  categoryTitle: string = '';

  constructor(
    private store: Store<AppState>,
    private route: Router,
    private router: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.store.dispatch(GetHelpCenterCategories.Request());

    this.categoryTitle = this.router.snapshot.paramMap.get('category-title')!;

    this.categories$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((categories) => {
        this.category = categories.find(
          (x) => x.title.toLowerCase() == this.categoryTitle.toLowerCase()
        )!;
      });
  }

  goToHelpCenter() {
    this.route.navigateByUrl('/admin/help');
  }

  goToArticle(title: string) {
    this.route.navigateByUrl(`/admin/help/${this.category.title}/${title}`);
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.unsubscribe();
  }
}
