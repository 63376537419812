export enum MindflickPermissionEnum {
    AddUser = "ADD_USER",
    RemoveUser = "REMOVE_USER",
    DownloadTeamMap = "DOWNLOAD_TEAM_MAP",
    TeamGhostMode = "TEAM_GHOST_MODE",
    DownloadFullProfiles = "DOWNLOAD_PDF_PROFILES",
    ViewOrgAnalytics = "VIEW_ORG_ANALYTICS",
    ViewAccountAnalytics = "VIEW_ACC_ANALYTICS",
    ViewSeatBreakdown = "VIEW_SEAT_BREAKDOWN",
    EditOrgSettings = "EDIT_ORG_SETTINGS",
    EditAccountSettings = "EDIT_ACC_SETTINGS",
    ViewUserPreferences = "VIEW_PREFS",
    EditUser = "EDIT_USER",
    UnlockUser = "UNLOCK_USER",
    AllocateSeats = "ALLOCATE_SEATS",
    AssignAccountAdmin = "ASSIGN_ACC_ADMIN",
    AssignAccountCoordinator = "ASSIGN_ACC_COORDINATOR",
    AssignAccountChampion = "ASSIGN_ACC_CHAMPION",
    ManageUsers = "USER_MANAGE",
    ViewAllTeams = "VIEW_ALL_TEAMS",
    ResendInvite = "RESEND_INVITE",
    EmailSpotlight = "EMAIL_SPOTLIGHT",
    ViewCoachFeedback = "VIEW_COACH_FEEDBACK",
    ViewExtraLegalDocs = "VIEW_EXTRA_LEGAL_DOCS",
    ViewAccountSettings = "VIEW_ACC_SETTINGS",
    ViewSettingsPage = "VIEW_SETTINGS",
}