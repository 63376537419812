import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { RbacService } from "../shared/services/rbac.service";
import { Observable } from "rxjs";

@Injectable({ providedIn: 'root' })
export class RolePermissionGuard  {
    constructor(private rbacService: RbacService, private router: Router) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const rolesOrPermissions = (route.data as RouteData).rolesOrPermissions;

        let canActivate = false;

        for (let i = 0; i < rolesOrPermissions.length; i++) {
            if (this.rbacService.isGrantedAnyAccount(rolesOrPermissions[i])) {
                canActivate = true;
                i = rolesOrPermissions.length;
            }
        }

        if (canActivate) {
            return true;
        } else {
            this.router.navigate(['/dashboard']);

            return false;
        }
    }
}

interface RouteData {
  rolesOrPermissions: string[];
}