<div class="btn-tertiary d-flex align-items-center"
     [class.justify-content-end]="contentRightAligned"
     [class.light]="light"
     [class.purple300]="purple300"
     [class.disabled]="disabled"
     [class.flex-row-reverse]="reverse"
     (click)="onButtonClick($event)">
  <ng-content></ng-content>
  <fa-icon *ngIf="icon" class="ps-1 fa-lg"
           [class]="{'fa-lg': !isSmallIcon, 'fa-xs': isSmallIcon}"
           [icon]="icon"></fa-icon>
  <img *ngIf="svgPath" [src]="svgPath" [height]="size" [width]="size" class="me-1" />

</div>
