import { Dictionary } from "@ngrx/entity";
import { createSelector } from "@ngrx/store";
import {
  MindflickAccountBasicDetails,
  MindflickOrganisation,
  User,
  UserBasicDetails,
  WorkOnType,
} from "src/app/shared/models";
import {
  EngagementResource,
  EngagementResourceCategory,
  EngagementResourceSubCategory,
} from "src/app/shared/models/engagement-resource.interface";
import {
  AccountAdminManagedUsersDTO,
  AccountAdminManagedUsersFilter,
  OrganisationSettings,
  UserStatusEnum,
} from "../../shared/models/account-admin-managed-users-DTO";
import { AppState, accountAdminStateKey } from "../app.state";
import { GrowthType } from "../growth/growth.reducer";
import { selectFilteredTagIds } from "../growth/growth.selector";
import { selectAllUsers } from "../user/user.selector";
import {
  AccountAdminState,
  engagementResourceAdapter,
  engagementResourceCategoryAdapter,
  engagementResourceSubCategoryAdapter,
  initialAccountAdminState,
} from "./account-admin.reducer";
import {
  CardLayout,
  CarouselLayout,
  CategoryLayout,
} from "../../shared/models/carousel-card-layouts.interface";

export const selectAccountAdminState = (state: AppState) =>
  state[accountAdminStateKey];

export const selectUserManagementOrderBy = (state: AppState) =>
  state[accountAdminStateKey].userManagementOrderBy;

export const selectManagedUserFilter = (state: AppState) =>
  state[accountAdminStateKey].managedUsersFilter;

export const selectIsManagedUserFilterCleared = createSelector(
  selectManagedUserFilter,
  (filter: AccountAdminManagedUsersFilter) => {
    const initialFilter = initialAccountAdminState.managedUsersFilter;
    return JSON.stringify(filter) === JSON.stringify(initialFilter);
  }
);

export const selectAccountManagedUsersState = createSelector(
  selectAccountAdminState,
  (state: AccountAdminState) => state.accountAdminManagedUsers
);

export const selectAccountAdminManagedUsers = createSelector(
  selectAccountManagedUsersState,
  selectUserManagementOrderBy,
  selectManagedUserFilter,
  (
    accountAdminManagedUsersState: AccountAdminManagedUsersDTO[],
    orderBy:
      | "name-asc"
      | "name-desc"
      | "date-asc"
      | "date-desc"
      | "dept-asc"
      | "dept-desc"
      | "web-status-asc"
      | "web-status-desc"
      | "email-desc"
      | "email-asc"
      | "mobile-status-asc"
      | "mobile-status-desc"
      | "allocated-seats-asc"
      | "allocated-seats-desc",
    filter?: AccountAdminManagedUsersFilter
  ) => {
    function stringCompare(a: string, b: string) {
      if (a === "" || a === null) return 1;
      if (b === "" || b === null) return -1;
      if (a === b) return 0;
      return a.toLowerCase() < b.toLowerCase() ? -1 : 1;
    }

    let orderedUsers: AccountAdminManagedUsersDTO[] =
      accountAdminManagedUsersState.slice();

    switch (orderBy) {
      case "name-desc":
        orderedUsers.sort((a, b) => stringCompare(a.name, b.name));
        break;
      case "name-asc":
        orderedUsers.sort((a, b) => stringCompare(b.name, a.name));
        break;
      case "date-asc":
        orderedUsers.sort(
          (a, b) => a.createdDate.getTime() - b.createdDate.getTime()
        );
        break;
      case "date-desc":
        orderedUsers.sort(
          (a, b) => b.createdDate.getTime() - a.createdDate.getTime()
        );
        break;
      case "dept-asc":
        orderedUsers.sort((a, b) => stringCompare(b.department ?? '', a.department ?? ''));
        break;
      case "dept-desc":
        orderedUsers.sort((a, b) => stringCompare(a.department ?? '', b.department ?? ''));
        break;
      case "web-status-asc":
        orderedUsers.sort((a, b) => a.portalStatus - b.portalStatus);
        break;
      case "web-status-desc":
        orderedUsers.sort((a, b) => b.portalStatus - a.portalStatus);
        break;
      case "email-desc":
        orderedUsers.sort((a, b) => stringCompare(a.email, b.email));
        break;
      case "email-asc":
        orderedUsers.sort((a, b) => stringCompare(b.email, a.email));
        break;
      case "mobile-status-desc":
        orderedUsers.sort((a, b) =>
          a.userJoinedApp && b.userJoinedApp
            ? 0
            : a.userJoinedApp && !b.userJoinedApp
              ? 1
              : -1
        );
        break;
      case "mobile-status-asc":
        orderedUsers.sort((a, b) =>
          b.userJoinedApp && a.userJoinedApp
            ? 0
            : b.userJoinedApp && !a.userJoinedApp
              ? 1
              : -1
        );
        break;
      case "allocated-seats-desc":
        const usersWithInvitesDesc = orderedUsers
          .filter((x) => x.delegatedInvites > 0)
          .sort((a, b) => b.delegatedInvitesUsed - a.delegatedInvitesUsed);
        const usersWithoutInvitesDesc = orderedUsers.filter(
          (x) => x.delegatedInvites === 0
        );
        orderedUsers = usersWithInvitesDesc.concat(usersWithoutInvitesDesc);
        break;
      case "allocated-seats-asc":
        const usersWithInvitesAsc = orderedUsers
          .filter((x) => x.delegatedInvites > 0)
          .sort((a, b) => a.delegatedInvitesUsed - b.delegatedInvitesUsed);
        const usersWithoutInvitesAsc = orderedUsers.filter(
          (x) => x.delegatedInvites === 0
        );
        orderedUsers = usersWithoutInvitesAsc.concat(usersWithInvitesAsc);
        break;
    }

    if (filter) {
      if (filter?.userStatus) {
        orderedUsers = orderedUsers.filter((x) => {
          switch (filter?.userStatus) {
            case UserStatusEnum.User:
              return !x.isMindflickChampion && !x.isAccountAdmin;

            case UserStatusEnum.Champion:
              return x.isMindflickChampion;

            case UserStatusEnum.Admin:
              return x.isAccountAdmin;

            default:
              return true;
          }
        });
      }

      if (filter?.preference) {
        orderedUsers = orderedUsers.filter((x) => {
          if (filter?.preference == undefined) {
            return true;
          }

          if (filter?.preference == x.flexCope) {
            return true;
          }

          return false;
        });
      }


      if (filter.dateAdded) {
        orderedUsers = orderedUsers.filter((user) => {
          if (filter?.dateAdded == 'custom') {
            if (filter?.fromDate != undefined && filter.toDate != undefined) {
              return (
                user.createdDate > filter.fromDate &&
                user.createdDate < filter.toDate
              );
            } else if (
              filter?.fromDate != undefined &&
              filter.toDate == undefined
            ) {
              return user.createdDate > filter.fromDate;
            } else if (
              filter?.fromDate == undefined &&
              filter?.toDate != undefined
            ) {
              return user.createdDate < filter.toDate;
            }
            return true;
          }
          return user.createdDate > filter.dateAdded!;
        });
      }

      if (filter?.department) {
        orderedUsers = orderedUsers.filter((x) => {
          return filter.department != undefined
            ? x.department == filter.department
            : true;
        });
      }

      if (filter?.portalStatus || filter?.portalStatus === 0) {
        orderedUsers = orderedUsers.filter((x) => {
          return filter.portalStatus != undefined
            ? x.portalStatus == filter.portalStatus
            : true;
        });
      }

      if (filter?.appStatus !== undefined) {
        if (filter.appStatus) {
          orderedUsers = orderedUsers.filter((x) => {
            return x.userJoinedApp;
          });
        }
        if (!filter.appStatus) {
          orderedUsers = orderedUsers.filter((x) => {
            return !x.userJoinedApp;
          });
        }
      }

      if (filter?.nameFilter) {
        orderedUsers = orderedUsers.filter((x) => {
          return x.name
            .toLocaleLowerCase()
            .includes(filter.nameFilter?.toLowerCase()!);
        });
      }

      if (filter.hasUnusedSeats) {
        orderedUsers = orderedUsers.filter((x) => {
          return x.delegatedInvites > x.delegatedInvitesUsed;
        });
      }
    }
    return orderedUsers;
  }
);

export const selectCoordinatorSearchFilter = (state: AppState) =>
  state[accountAdminStateKey].coordinatorSearchFilter;

export const selectCoordinatorSearchResults = createSelector(
  selectAccountManagedUsersState,
  selectCoordinatorSearchFilter,
  (users: AccountAdminManagedUsersDTO[], searchFilter?: string) => {
    if (!searchFilter) return [];

    return users.filter((u) =>
      u.name.toLocaleLowerCase().includes(searchFilter.toLocaleLowerCase())
    );
  }
);

export const selectAccountAdminManagedUsersLoading = (state: AppState) =>
  state[accountAdminStateKey].accountAdminManagedUsersLoading;

export const selectHasManagedUsersRequestFailed = (state: AppState) =>
  state[accountAdminStateKey].hasManagedUsersRequestFailed;

export const selectAccountSettings = (state: AppState) =>
  state[accountAdminStateKey].accountSettings;
export const selectSeatsInAccData = (state: AppState) =>
  state[accountAdminStateKey].seatsInOrg;

export const selectAddUserSuccess = (state: AppState) =>
  state[accountAdminStateKey].addUsersSuccess;
export const selectAddUserResultMessage = (state: AppState) =>
  state[accountAdminStateKey].addUsersResultMessage;

export const selectIsAddingUsers = (state: AppState) =>
  state[accountAdminStateKey].addingUsers;

export const selectHelpCenterCategories = (state: AppState) =>
  state[accountAdminStateKey].helpCenterCategories;

const selectUnsortedHelpCenterCategories = (state: AppState) =>
  state[accountAdminStateKey].helpCenterCategories;
export const selectAllHelpCenterCategories = createSelector(
  selectUnsortedHelpCenterCategories,
  (unsortedCategories) => {
    let categories = unsortedCategories
      .slice()
      .sort((a, b) => a.orderById - b.orderById);
    categories = categories.map((category) => {
      return {
        ...category,
        articles: category.articles
          .slice()
          .sort((a, b) => a.orderById - b.orderById),
      };
    });
    return categories;
  }
);

export const selectAccountAdminSearchTerm = (state: AppState) =>
  state[accountAdminStateKey].currentSearchTerm;

export const selectCanChampionsViewAnalyticss = (state: AppState) =>
  state[accountAdminStateKey].accountSettings?.canChampionsViewAnalytics;

export const selectEngagementResourceCategoryState = (state: AppState) =>
  state[accountAdminStateKey].engagementResourceCategories;

const {
  selectIds: engagementResourceCategoryIds,
  selectEntities: engagementResourceCategoryEntities,
  selectAll: engagementResourceCategories,
  selectTotal: engagementResourceCategoryCount,
} = engagementResourceCategoryAdapter.getSelectors(
  selectEngagementResourceCategoryState
);

export const selectEngagementResourceSubCategoryState = (state: AppState) =>
  state[accountAdminStateKey].engagementResourceSubCategories;

const {
  selectIds: engagementResourceSubCategoryIds,
  selectEntities: engagementResourceSubCategoryEntities,
  selectAll: engagementResourceSubCategories,
  selectTotal: engagementResourceSubCategoryCount,
} = engagementResourceSubCategoryAdapter.getSelectors(
  selectEngagementResourceSubCategoryState
);

export const selectEngagementResourceState = (state: AppState) =>
  state[accountAdminStateKey].engagementResources;

const {
  selectIds: engagementResourceIds,
  selectEntities: engagementResourceEntities,
  selectAll: engagementResources,
  selectTotal: engagementResourceCount,
} = engagementResourceAdapter.getSelectors(selectEngagementResourceState);

export const selectEngagementResourceTree = createSelector(
  engagementResourceCategories,
  engagementResourceSubCategoryEntities,
  engagementResourceEntities,
  (
    catEntities: EngagementResourceCategory[],
    subCatEntities: Dictionary<EngagementResourceSubCategory>,
    engagementResouces: Dictionary<EngagementResource>
  ) => {
    const mappedCategories = catEntities.map((category) => ({
      ...category,
      subCategories: category.subCategoryIds.map((x) => {
        let subCategory = subCatEntities[x]!;

        return {
          ...subCategory,
          engagementResources: subCategory.engagementResourceIds.map((y) => {
            return engagementResouces[y]!;
          }),
        };
      }),
    }));

    return mappedCategories;
  }
);
export const selectEngagementResourceCategories = (state: AppState) =>
  state[accountAdminStateKey].engagementResourceCategories;

// export const selectCategoryTitle = (state : AppState) => state[accountAdminStateKey].selectedCategoryTitle;
// export const selectSubCategoryTitle = (state : AppState) => state[accountAdminStateKey].selectedSubCategoryTitle;
export const selectFilteredEngagementResourceIds = (state: AppState) =>
  state[accountAdminStateKey].filteredEngagementResourceIds;
export const selectFilteredResourceIds = createSelector(
  selectFilteredEngagementResourceIds,
  selectFilteredTagIds,
  (resourceIds: number[], tagIds: number[]) => {
    let newArray: number[] = [...resourceIds, ...tagIds];

    let uniqueArray: number[] = [];

    newArray.forEach((x) => {
      if (!uniqueArray.includes(x)) uniqueArray.push(x);
    });

    return uniqueArray;
  }
);
export const selectEngagementResourceCarouselLayout = createSelector(
  selectEngagementResourceTree,
  selectFilteredResourceIds,
  selectAccountAdminSearchTerm,
  (
    categories: EngagementResourceCategory[],
    filteredCardIds: number[],
    currentSearchTerm: string
  ) => {
    if (categories.length === 0) return;

    let newCategoriesLayout: CategoryLayout[] = [];

    categories.forEach((category) => {
      let categoryTitle = category.title;
      let subCategories: CarouselLayout[] = [];

      category.subCategories.forEach((subCat) => {
        let subCatTitle = subCat.title;
        let subCatCards: CardLayout[] = [];

        function createCard(resource: EngagementResource) {
          let card: CardLayout = {
            id: resource.id,
            title: resource.title,
            summary: resource.summary,
            growthType: GrowthType.EngagementResource,
            workOnType: WorkOnType.None,
            sections: resource.sections.map((resource) => {
              return {
                title: resource.title,
                orderById: resource.orderById,
                contentHtml: resource.contentHtml,
              };
            }),
          };
          subCatCards.push(card);
        }

        //if there is no search but there are filteredCardIds
        if (currentSearchTerm.length === 0 && filteredCardIds.length > 0) {
          subCat.engagementResources.forEach((resource) => {
            //if there are filtered card Ids and these include the resource.id
            if (
              resource.id !== undefined &&
              filteredCardIds.length > 0 &&
              filteredCardIds.includes(resource.id)
            ) {
              createCard(resource);
            }

            //if there is no search push all the cards
            if (filteredCardIds.length == 0 && currentSearchTerm.length === 0) {
              createCard(resource);
            }
          });
        }

        let newSubCat: CarouselLayout = {
          name: subCatTitle,
          cards: subCatCards,
        };
        subCategories.push(newSubCat);
      });

      let newCategory: CategoryLayout = {
        title: categoryTitle,
        subCategories: subCategories,
      };

      newCategoriesLayout.push(newCategory);
    });
    return newCategoriesLayout;
  }
);

export const selectPinnedEngagementResourceIds = (state: AppState) =>
  state[accountAdminStateKey].pinnedEngagementResources;

export const selectPinnedEngagementResources = createSelector(
  engagementResourceEntities,
  selectPinnedEngagementResourceIds,
  (entities: Dictionary<EngagementResource>, ids: number[]) => {
    if (!entities) return [];

    return ids
      .filter((x) => !!entities[x])
      .map((x) => {
        let pinnedResource = entities[x]!;

        let card: CardLayout = {
          id: pinnedResource.id,
          title: pinnedResource.title,
          summary: pinnedResource.summary,
          imageUrl: pinnedResource.imageUrl,
          growthType: GrowthType.EngagementResource,
          workOnType: WorkOnType.None,
          sections: [],
        };

        return card;
      });
  }
);

export const selectPossibleDepartments = createSelector(
  selectAccountAdminManagedUsers,
  (users: AccountAdminManagedUsersDTO[]) => {
    return [
      ...new Set(users.filter((x) => x.department != null && x.department != '').map((x) => x.department!)),
    ];
  }
);
export const selectPossibleRoles = createSelector(
  selectAccountAdminManagedUsers,
  selectAllUsers,
  (managedUsers: AccountAdminManagedUsersDTO[], allUsers: User[]) => {
    let validUsers = allUsers.filter((x) =>
      managedUsers.map((x) => x.ak).includes(x.id)
    );

    return [
      ...new Set(validUsers.filter((x) => x.jobTitle).map((x) => x.jobTitle)),
    ];
  }
);

export const selectSelectedUserAkAdmin = (state: AppState) =>
  state[accountAdminStateKey].selectedUserAk;

export const selectSelectedUserAdmin = createSelector(
  selectAccountAdminManagedUsers,
  selectSelectedUserAkAdmin,
  (managedUsers: AccountAdminManagedUsersDTO[], selectedUserAk?: number) => {
    if (selectedUserAk)
      return managedUsers.find((x) => x.ak === selectedUserAk);

    return undefined;
  }
);

export const selectLiveAnalyticsData = (state: AppState) =>
  state[accountAdminStateKey].liveAnalyticsData;

export const selectIsDownloadingTeamMap = (state: AppState) =>
  state[accountAdminStateKey].isDownloadingTeamMap;

export const selectMindflickOrganisation = (state: AppState) =>
  state[accountAdminStateKey].mindflickOrganisation;

export const selectMindflickOrganisationSettings = createSelector(
  selectMindflickOrganisation,
  (org?: MindflickOrganisation) => {
    if (org)
      return {
        dataRetentionDurationDays: org.dataRetentionDuration,
        sessionDurationMins: org.sessionDuration,
        mfaRequired: org.mfaRequired,
        requiresExternalAuthentication: org.ssoRequired,
      } as OrganisationSettings;

    return undefined;
  }
);

export const selectUserManagementMindflickAccounts = createSelector(
  selectAccountAdminState,
  (state: AccountAdminState) => state.userManagementMindflickAccounts
);

export const selectSelectedUserManagementAccountId = createSelector(
  selectAccountAdminState,
  (state: AccountAdminState) => state.userManagementSelectedAccountId
);

export const selectSelectedUserManagementAccount = createSelector(
  selectUserManagementMindflickAccounts,
  selectSelectedUserManagementAccountId,
  (accounts: MindflickAccountBasicDetails[], accountId?: number) => {
    if (!accountId)
      return;

    return accounts.find(a => a.accountId === accountId);
  }
);

export const selectLiveAnalyticsMindflickAccounts = createSelector(
  selectAccountAdminState,
  (state: AccountAdminState) => state.liveAnalyticsMindflickAccounts
);

export const selectSelectedLiveAnalyticsAccountId = createSelector(
  selectAccountAdminState,
  (state: AccountAdminState) => state.liveAnalyticsSelectedAccountId
);

export const selectSelectedLiveAnalyticsAccount = createSelector(
  selectLiveAnalyticsMindflickAccounts,
  selectSelectedLiveAnalyticsAccountId,
  (accounts: MindflickAccountBasicDetails[], accountId?: number) => {
    if (!accountId)
      return;

    return accounts.find(a => a.accountId === accountId);
  }
);

export const selectAccountsSummaryData = (state: AppState) =>
  state[accountAdminStateKey].accountsSummaryData;


export const selectSelectedAccountSummaryId = (state: AppState) =>
  state[accountAdminStateKey].currentAccountSummaryId;

export const selectSelectedAccountsSummaryData = createSelector(
  selectAccountsSummaryData,
  selectSelectedAccountSummaryId,
  (accounts, selectedId) => {
    return accounts.find(x => x.mindflickAccountId == selectedId);
  }
);

export const selectAccountSettingsChampions = (state: AppState) =>
  state[accountAdminStateKey].accountSettingsChampions;

export const selectAccountSettingsCoordinators = (state: AppState) =>
  state[accountAdminStateKey].accountSettingsCoordinators;

export const selectAccountSettingsAccounts = (state: AppState) =>
  state[accountAdminStateKey].accountSettingsAccounts;

export const selectSelectedAccountSettingsAccountId = (state: AppState) =>
  state[accountAdminStateKey].selectedAccountSettingsAccountId;

export const selectSelectedAccountSettingsAccount = createSelector(
  selectAccountSettingsAccounts,
  selectSelectedAccountSettingsAccountId,
  (accounts: MindflickAccountBasicDetails[], accountId?: number) => {
    return accounts.find(x => x.accountId == accountId);
  }
);

export const selectValidChampionsForOrg = (state: AppState) =>
  state[accountAdminStateKey].validChampionsForOrg;

export const selectAddChampionOptions = createSelector(
  selectValidChampionsForOrg,
  selectAccountSettingsChampions,
  (validChampions: UserBasicDetails[], selectedAccountChampions: UserBasicDetails[]) => {
    const selectedAccountChampionAks = selectedAccountChampions.map(sac => sac.ak!);

    return validChampions.filter(vc => !selectedAccountChampionAks.includes(vc.ak!));
  }
);

export const selectValidCoordinatorsForOrg = (state: AppState) =>
  state[accountAdminStateKey].validCoordinatorsForOrg;

export const selectAddCoordinatorsOptions = createSelector(
  selectValidCoordinatorsForOrg,
  selectAccountSettingsCoordinators,
  (validCoordinators: UserBasicDetails[], selectedAccountCoordinators: UserBasicDetails[]) => {
    const selectedAccountCoordinatorsAks = selectedAccountCoordinators.map(sac => sac.ak!);

    return validCoordinators.filter(vc => !selectedAccountCoordinatorsAks.includes(vc.ak!));
  }
);

export const selectValidAccountAdmins = (state: AppState) =>
  state[accountAdminStateKey].validAccountAdminsForOrg;

export const selectOrgAdminAccounts = createSelector(
  selectAccountAdminState,
  (state: AccountAdminState) => state.orgAdminAccounts
);


export const isPincodesCreatedLoading = (state: AppState) =>
  state[accountAdminStateKey].pincodesCreatedGraphLoading;
export const isMeetingsCreatedLoading = (state: AppState) =>
  state[accountAdminStateKey].meetingsCreatedGraphLoading;
export const isStrengthsSpottedLoading = (state: AppState) =>
  state[accountAdminStateKey].strengthsSpottedGraphLoading;

export const selectLiveAnalyticsPincodesCreated = (state: AppState) =>
  state[accountAdminStateKey].liveAnalyticsPincodesCreated;
export const selectLiveAnalyticsMeetingsCreated = (state: AppState) =>
  state[accountAdminStateKey].liveAnalyticsMeetingsCreated;
export const selectLiveAnalyticsStrengthsSpotted = (state: AppState) =>
  state[accountAdminStateKey].liveAnalyticsStrengthsSpotted;
