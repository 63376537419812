import { createAction, props } from '@ngrx/store';
import { AddUserModel } from 'src/app/admin/add-user-modal/add-user-modal.component';
import {
  EditUserDetailsModel,
  StandardResponse,
  User
} from 'src/app/shared/models';
import { CookieSettings } from 'src/app/shared/models/cookies';
import { DigitalDebriefStageEnum } from '../../shared/models/enums/digital-debrief-stage.enum';
import { PulseSurvey } from '../../shared/models/pulse-survey.interface';
import { TwoFactorAuthenticationKeys } from '../../shared/models/two-factor-authentication-keys';

export namespace GetHasBothProducts {
  export const Request = createAction('[User] Get Has Both Products Request');

  export const Success = createAction(
    '[User] Get Has Both Products Success',
    props<{ resp: boolean; }>()
  );

  export const Fail = createAction(
    '[User] Get Has Both Products Fail',
    props<{ error: any; }>()
  );
}

export namespace GetUser {
  export const Request = createAction('[User] Get User Request');

  export const Success = createAction(
    '[User] Get User Success',
    props<{ user: User; }>()
  );

  export const Fail = createAction(
    '[User] Get User Fail',
    props<{ error: any; }>()
  );
}

export namespace GetUserByAK {
  export const Request = createAction(
    '[User] Get User By AK Request',
    props<{ userAK: number; }>()
  );

  export const Success = createAction(
    '[User] Get User By AK Success',
    props<{ user: User; }>()
  );

  export const Fail = createAction(
    '[User] Get User By AK Fail',
    props<{ error: any; }>()
  );
}

export namespace GetAllUsers {
  export const Request = createAction('[Users] Get All Users Request');

  export const Success = createAction(
    '[Users] Get All Users Success',
    props<{ allUsers: User[]; }>()
  );

  export const Fail = createAction(
    '[Users] Get All Users Failure',
    props<{ error: any; }>()
  );
}

export namespace GetMyConnectedUsers {
  export const Request = createAction('[Users] Get My Connected Users Request');

  export const Success = createAction(
    '[Users] Get My Connected Users Success',
    props<{ connectedUsers: User[]; }>()
  );

  export const Fail = createAction(
    '[Users] Get My Connected Users Fail',
    props<{ error: any; }>()
  );
}

export namespace RequestProfileAccess {
  export const Request = createAction(
    '[Users] Request Profile Access Request',
    props<{ userAK: number; }>()
  );

  export const Success = createAction(
    '[Users] Request Profile Access Success',
    props<{ resp: StandardResponse<undefined>; userAK: number; }>()
  );

  export const Fail = createAction(
    '[Users] Request Profile Access Fail',
    props<{ error: any; }>()
  );
}

export namespace DeleteUserConnection {
  export const Request = createAction(
    '[Users] Delete User Connection Request',
    props<{ userAK: number; }>()
  );

  export const Success = createAction(
    '[Users] Delete User Connection Success',
    props<{ userAK: number; }>()
  );

  export const Fail = createAction(
    '[Users] Delete User Connection Fail',
    props<{ error: any; }>()
  );
}

export namespace PutConnectionDate {
  export const Request = createAction(
    '[Users] Put Date For Connecting To User Request',
    props<{
      userAK: number;
      notificationId?: number;
      fromProfilePage: boolean;
    }>()
  );

  export const Success = createAction(
    '[Users] Put Date For Connecting To User Success',
    props<{ userId: number; fromProfilePage: boolean; flexCope: string; }>()
  );

  export const Fail = createAction(
    '[Users] Put Date For Connecting To User Fail',
    props<{ error: any; }>()
  );
}

export namespace DeletePendingConnection {
  export const Request = createAction(
    '[Users] Delete Pending Connection Request',
    props<{ userAK: number; }>()
  );

  export const Success = createAction(
    '[Users] Delete Pending Connection Success',
    props<{ userId: number; }>()
  );

  export const Fail = createAction(
    '[Users] Delete Pending Connection Fail',
    props<{ error: any; }>()
  );
}

export const ClearRequestProfileAccessResponse = createAction(
  '[Users] Clear Request Profile Access Response'
);

export const ClearSelectedUser = createAction('[Users] Clear Selected User');

export const SetSelectedUser = createAction(
  '[Users] Set Selected User',
  props<{ id: number; }>()
);

export const SetUserTeams = createAction(
  '[Users] Set User Teams',
  props<{ id: number; }>()
);

export const SetUserSearchFilter = createAction(
  '[Users] Set User Search Filter',
  props<{ filter: string; }>()
);

export const ClearUserSearchFilter = createAction(
  '[Users] Clear User Search Filter'
);

export namespace UpdateUserDetails {
  export const Request = createAction(
    '[User] Update User Details Request',
    props<{ payload: EditUserDetailsModel; }>()
  );

  export const Success = createAction(
    '[User] Update User Details Success',
    props<{ resp: User; }>()
  );

  export const Fail = createAction(
    '[User] Update User Details Fail',
    props<{ error: any; }>()
  );
}

export namespace ChangeUserPicture {
  export const Request = createAction(
    '[User] Change User Picture Request',
    props<{ formData: FormData; }>()
  );

  export const Success = createAction(
    '[User] Change User Picture Success',
    props<{ photoLastModified: string; }>()
  );

  export const Fail = createAction(
    '[User] Change User Picture Fail',
    props<{ error: any; }>()
  );
}

export namespace ClearUserPicture {
  export const Request = createAction('[User] Clear User Picture Request');

  export const Success = createAction('[User] Clear User Picture Success');

  export const Fail = createAction(
    '[User] Clear User Picture Fail',
    props<{ error: any; }>()
  );
}

export const SetUserAdminPage = createAction(
  '[User Admin] Set User Admin Page',
  props<{ page: number; }>()
);

export namespace Generate2FARegistrationKeys {
  export const Request = createAction(
    '[User] Generate 2FA Registration Key Request'
  );

  export const Success = createAction(
    '[User] Generate 2FA Registration Key Success',
    props<{ keys: TwoFactorAuthenticationKeys; }>()
  );

  export const Fail = createAction(
    '[User] Generate 2FA Registration Key Fail',
    props<{ error: any; }>()
  );
}

export namespace Verify2FAUserInputCode {
  export const Request = createAction(
    '[User] Verify 2FA User Input Code Request',
    props<{ code: string; }>()
  );

  export const Success = createAction(
    '[User] Verify 2FA User Input Code Success'
  );

  export const Fail = createAction(
    '[User] Verify 2FA User Input Code Fail',
    props<{ error: any; }>()
  );
}

export const ClearTwoFactorVerification = createAction(
  '[User] Set Two Factor Verification To Undefined'
);

export namespace Disable2FA {
  export const Request = createAction('[User] Disable 2FA Request');

  export const Success = createAction('[User] Disable 2FA Success');

  export const Fail = createAction(
    '[User] Disable 2FA Fail',
    props<{ error: any; }>()
  );
}

export const SetUserTake = createAction(
  '[User] Set User Take',
  props<{ userTake: number; }>()
);

export namespace SetPlatformSetting {
  export const Request = createAction(
    '[User] Set PlatformSetting Request',
    props<{ key: string; value: string; }>()
  );

  export const Success = createAction(
    '[User] Set PlatformSetting Success',
    props<{ key: string; value: string; }>()
  );

  export const Fail = createAction(
    '[User] Set PlatformSetting Fail',
    props<{ error: any; }>()
  );
}

export namespace SetBulkPlatformSettings {
  export const Request = createAction(
    '[User] Set Bulk Platform Settings Request',
    props<{ settings: { [id: string]: string; }; }>()
  );

  export const Success = createAction(
    '[User] Set Bulk Platform Settings Success',
    props<{ settings: { [id: string]: string; }; }>()
  );

  export const Fail = createAction(
    '[User] Set Bulk Platform Settings Fail',
    props<{ error: any; }>()
  );
}

export namespace SetUserPlatformIntroViewedFlag {
  export const Request = createAction(
    '[User] Set User PlatformIntroViewed Flag Request',
    props<{ userId: number; }>()
  );
  export const Success = createAction(
    '[User] Set User PlatformIntroViewed Flag Success',
    props<{ dateViewed: Date; }>()
  );
  export const Fail = createAction(
    '[User] Set User PlatformIntroViewed Flag Fail',
    props<{ error: any; }>()
  );
}

export namespace GetUserCookieSettings {
  export const Request = createAction(
    '[User] Get User Cookie Settings Request'
  );

  export const Success = createAction(
    '[User] Get User Cookie Settings Success',
    props<{ settings: CookieSettings; }>()
  );

  export const Fail = createAction(
    '[User] Get User Cookie Settings Fail',
    props<{ error: any; }>()
  );
}

export namespace SetUserCookieSettings {
  export const Request = createAction(
    '[User] Set User Cookie Settings Request',
    props<{ settings: CookieSettings; }>()
  );

  export const Success = createAction(
    '[User] Set User Cookie Settings Success',
    props<{ settings: CookieSettings; }>()
  );

  export const Fail = createAction(
    '[User] Set User Cookie Settings Fail',
    props<{ error: any; }>()
  );
}

export namespace InviteDirectReport {
  export const Request = createAction(
    '[USer] Invite Direct Report Request',
    props<{ payload: AddUserModel; }>()
  );

  export const Success = createAction(
    '[USer] Invite Direct Report Success',
    props<{ resp: User; }>()
  );

  export const Fail = createAction(
    '[USer] Invite Direct Report Fail',
    props<{ error: any; }>()
  );
}

export const UpdateCurrentUserDelegatedInvites = createAction(
  '[User] Update Current Users Delegated Invites',
  props<{ delegatedInvites: number; }>()
);

export namespace CreatePulseSurvey {
  export const Request = createAction(
    '[User] Update PMF Survey Request',
    props<{ survey: PulseSurvey; }>()
  );

  export const Success = createAction('[User] Update PMF Survey Success');

  export const Fail = createAction(
    '[User] Update PMF Survey Fail',
    props<{ error: any; }>()
  );
}

export namespace SetPlatformFirstLogin {
  export const Request = createAction(
    '[User] Set Platform First Login Request'
  );

  export const Success = createAction(
    '[User] Set Platform First Login Success',
    props<{ user: User; }>()
  );

  export const Fail = createAction(
    '[User] Set Platform First Login Fail',
    props<{ error: any; }>()
  );
}

export namespace AcceptChampionCharter {
  export const Request = createAction('[User] Accept Champion Charter Request');

  export const Success = createAction('[User] Accept Champion Charter Success');

  export const Fail = createAction(
    '[User] Accept Champion Charter Fail',
    props<{ error: any; }>()
  );
}

export namespace SaveDebriefProgress {
  export const Request = createAction('[User] Save Debrief Progress Request', props<{ debriefStage: DigitalDebriefStageEnum; }>());

  export const Success = createAction('[User] Save Debrief Progress Success', props<{ debriefStage: DigitalDebriefStageEnum; }>());

  export const Fail = createAction(
    '[User] Save Debrief Progress Fail',
    props<{ error: any; }>()
  );
}

export namespace GetDebriefProgress {
  export const Request = createAction('[User] Get Debrief Progress Request');

  export const Success = createAction('[User] Get Debrief Progress Success', props<{ debriefStage: DigitalDebriefStageEnum; }>());

  export const Fail = createAction(
    '[User] Get Debrief Progress Fail',
    props<{ error: any; }>()
  );
}


export namespace GoToDebriefIntro {
  export const Request = createAction(
    '[User] Goto Debrief Stage Intro Request'
  );
  export const Success = createAction(
    '[User] Goto Debrief Stage Intro Success'
  );
  export const Fail = createAction(
    '[User] Goto Debrief Stage Intro Fail',
    props<{ error: any; }>()

  );
}


export namespace CompleteDashboardOnboarding {
  export const Request = createAction(
    '[User] Complete Dashboard Onboarding Request'
  );
  export const Success = createAction(
    '[User] Complete Dashboard Onboarding Success',
    props<{ completedDate: Date; }>()
  );
  export const Fail = createAction(
    '[User] Complete Dashboard Onboarding Fail',
    props<{ error: any; }>()
  );
}

export namespace CompleteProfileOnboarding {
  export const Request = createAction(
    '[User] Complete Profile Onboarding Request'
  );
  export const Success = createAction(
    '[User] Complete Profile Onboarding Success',
    props<{ completedDate: Date; }>()

  );
  export const Fail = createAction(
    '[User] Complete Profile Onboarding Fail',
    props<{ error: any; }>()
  );
}

export namespace CompleteConnectionsOnboarding {
  export const Request = createAction(
    '[User] Complete Connections Onboarding Request'
  );

  export const Success = createAction(
    '[User] Complete Connections Onboarding Success',
    props<{ completedDate: Date; }>()
  );

  export const Fail = createAction(
    '[User] Complete Connections Onboarding Fail',
    props<{ error: any; }>()
  );
}
