import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';
import {
  AccountAdminResendSpotlightInvites,
  AddUsers,
  ReactivateUsers,
  RecreateUsers,
  SetMindflickAccountSettings,
  UpdateUserDebriefDate,
} from '../../account-admin/account-admin.actions';
import { DeactivateUser } from '../../account/account.actions';
import {
  CreateNewTeam,
  DeleteMember,
  PutAcceptedDate,
  RequestToJoinTeam,
  SaveTeamDetails,
} from '../../team/team.actions';
import * as LayoutActions from '../actions/layout.actions';

@Injectable()
export class LayoutEffects {
  constructor(private actions$: Actions) {}

  displayErrorALert$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        RequestToJoinTeam.Fail,
        PutAcceptedDate.Fail,
        DeleteMember.Fail,
        CreateNewTeam.Fail,
        SetMindflickAccountSettings.Fail,
        AddUsers.Fail,
        AccountAdminResendSpotlightInvites.Fail,
        ReactivateUsers.Fail,
        DeactivateUser.Fail,
        SaveTeamDetails.Fail,
        UpdateUserDebriefDate.Fail,
        RecreateUsers.Fail
      ),

      map((action) =>
        LayoutActions.DisplaySnackbarAlert.SetAlert({
          alert: {
            alertType: 'danger',
            messageHeader: 'Oops!',
            message: action.error.error.detail
              ? action.error.error.detail
              : 'Something went wrong. Please contact your account admin or try again later!',
          },
        })
      )
    )
  );

  displayOrgSettingsSavingAlert$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SetMindflickAccountSettings.Request),
      map(() =>
        LayoutActions.DisplaySnackbarAlert.SetAlert({
          alert: {
            alertType: 'info',
            messageHeader: 'Saving',
            message: `Your new organisational settings are saving...`,
          },
        })
      )
    )
  );
}
