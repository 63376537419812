import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AccountSummaryData } from '../models/account-admin-managed-users-DTO';
import AccountsForOrgAdmin from '../models/org-admin-accounts';

@Injectable({
  providedIn: 'root',
})
export class OrganisationAdminService {
  organisationAdminUrl: string = '';

  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string
  ) {
    this.organisationAdminUrl = `${baseUrl}organisationadmin`;
  }

  getAllAccountsForOrgAdmin() {
    return this.http.get<AccountsForOrgAdmin[]>(`${this.organisationAdminUrl}/live-analytics/accounts`);
  }
}
