import { Dictionary } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import {
  MindflickRole,
  Team,
  TeamMapCoordinates,
  TeamMapUser,
  TeamMapUsers,
  User,
} from 'src/app/shared/models';
import { ConnectionStatus } from 'src/app/shared/models/enums/connection-status.enum';
import { AppState, userStateKey } from '../app.state';
import { TeamState } from '../team/team.reducer';
import {
  selectMyTeams,
  selectSelectedTeam,
  selectSelectedTeamMapUserAK,
  selectTeamMapIsFlex,
  selectTeamState,
} from '../team/team.selector';
import {
  allUserAdapter,
  UserState,
} from './user.reducer';

export const selectUserState = (state: AppState) => state[userStateKey];

export const selectAllUserState = (state: AppState) =>
  state[userStateKey].allUsers;

const {
  selectIds: allUserIds,
  selectEntities: allUserEntities,
  selectAll: allUsers,
  selectTotal: totalActiveUsers,
} = allUserAdapter.getSelectors(selectAllUserState);

export const selectAllUserEntities = allUserEntities;
export const selectAllUsers = createSelector(allUsers, (users: User[]) => {
  return users.filter(x => !x.isMindflickCoach);
});



export const selectCurrentUser = createSelector(
  selectUserState,
  (userState: UserState) => {
    return userState.currentUser;
  }
);

export const selectCurrentUserRoles = createSelector(
  selectCurrentUser,
  (user?: User) => {
    if (!user)
      return [];

    return user.roles;
  }
);

export const selectCurrentUserAccountRoles = createSelector(
  selectCurrentUser,
  (user?: User) => {
    let accountRoleMap = new Map<number, MindflickRole[]>();

    if (!user)
      return accountRoleMap;

    // Convert the dictionary object to a Map
    Object.entries(user.accountRoles).forEach(val => {
      accountRoleMap.set(+val[0], val[1]);
    });

    return accountRoleMap;
  }
)

export const selectSelectedUserId = (state: AppState) =>
  state[userStateKey].selectedUserId;

export const selectTwoFactorQRData = (state: AppState) =>
  state[userStateKey].twoFactorBase64QR!;

export const selectTwoFactorFormattedKey = (state: AppState) =>
  state[userStateKey].twoFactorFormattedKey!;

export const selectIsTwoFactorVerified = (state: AppState) =>
  state[userStateKey].twoFactorVerified!;

export const selectSelectedUser = createSelector(
  selectAllUserEntities,
  selectSelectedUserId,
  (users: Dictionary<User>, userId?: number) => {
    if (userId) return users[userId];

    return undefined;
  }
);

export const selectSelectedUserConnectionStatus = createSelector(
  selectSelectedUser,
  (user: User | undefined) => {
    if (user) return user.connectionStatus;

    return ConnectionStatus.Unconnected;
  }
);

export const selectAllUsersLoading = (state: AppState) =>
  state[userStateKey].allUsersLoading;

export const selectRequestingProfileAccess = (state: AppState) =>
  state[userStateKey].requestingProfileAccess;

export const selectRequestingProfileAccessResponse = (state: AppState) =>
  state[userStateKey].requestingProfileAccessResponse;

export const selectUserSearchFilter = (state: AppState) =>
  state[userStateKey].userSearchFilter;

export const isSelectedUserEqualToCurrentUser = createSelector(
  selectCurrentUser,
  selectSelectedUser,
  (currentUser?: User, selectedUser?: User) => {
    if (!currentUser) return false;

    if (!selectedUser) return false;

    return currentUser?.id === selectedUser?.id;
  }
);

export const selectMyTeamsWithUsers = createSelector(
  selectMyTeams,
  selectAllUsers,
  (teams: Team[], users: User[]) => {
    let teamsWithUsers: Team[] = [...teams.map((team) => ({ ...team }))];

    teamsWithUsers.forEach((team) => {
      team.members = users.filter((x) => team.memberIds.includes(x.id));
    });

    return {
      teams: teamsWithUsers,
      allUsers: users,
    };
  }
);

export const selectTeamUsers = (teamId: number) =>
  createSelector(
    selectMyTeams,
    selectAllUsers,
    (teams: Team[], users: User[]) => {
      let team = teams.find((x) => x.id == teamId);

      if (!team) {
        return [];
      }

      return users.filter((x) => team!.memberIds.includes(x.id));
    }
  );

export const selectSelectedTeamWithUsers = createSelector(
  selectSelectedTeam,
  selectAllUserEntities,
  (team: Team | undefined, users: Dictionary<User>) => {
    if (team) {
      // Need to copy so we can add members
      let teamsWithUsers: Team = { ...team };

      teamsWithUsers.members = [];

      team.memberIds.forEach((x) => {
        let member = users[x];

        if (member) teamsWithUsers.members.push(member);
      });

      teamsWithUsers.invitedMembers = [];

      team.invitedMemberIds.forEach((x) => {
        let member = users[x];

        if (member) teamsWithUsers.invitedMembers.push(member);
      });

      return teamsWithUsers;
    }

    return team;
  }
);
export const selectSelectedTeamWithAllUsers = createSelector(
  selectSelectedTeam,
  selectAllUserEntities,
  (team: Team | undefined, users: Dictionary<User>) => {
    if (team) {
      // Need to copy so we can add members
      let teamsWithUsers: Team = { ...team };

      teamsWithUsers.members = [];

      team.memberIds.forEach((x) => {
        let member = users[x];

        if (member)
          teamsWithUsers.members.push({
            ...member,
            ...{
              connectionStatus: ConnectionStatus.Connected,
            },
          });
      });

      team.invitedMemberIds.forEach((x) => {
        let member = users[x];

        if (member)
          teamsWithUsers.members.push({
            ...member,
            ...{
              //Hijack the connection status to pass through the pending team member info
              connectionStatus: ConnectionStatus.Pending,
            },
          });
      });

      return teamsWithUsers;
    }

    return team;
  }
);

export const selectSelectedTeamRequestsToJoin = createSelector(
  selectSelectedTeam,
  selectAllUserEntities,
  (team: Team | undefined, users: Dictionary<User>) => {
    if (!team) return [];

    let mappedUsers = team.incomingRequestMemberIds.map((x) => users[x]);
    return mappedUsers;
  }
);

export const selectUsersForTeamMap = createSelector(
  selectAllUserEntities,
  selectSelectedTeam,
  selectTeamMapIsFlex,
  (
    users: Dictionary<User>,
    team: Team | undefined,
    isFlex: boolean
  ): TeamMapUsers => {
    let userIdsToRemove: number[] = [];
    let userGroups: TeamMapUser[] = [];
    let teamMapUsers: TeamMapUser[] = [];

    team?.memberIds.forEach((x) => {
      let member = users[x];

      if (member) {
        teamMapUsers.push({
          userId: member.id,
          initials: member.initials,
          flexCoords: member.flexCoords,
          copeCoords: member.copeCoords,
        });
      }
    });

    teamMapUsers.forEach((user) => {
      // If the user has already been aggregated with a previous user skip it
      if (!userIdsToRemove.includes(user.userId)) {
        let matches = isFlex
          ? teamMapUsers.filter(
              (x) =>
                x.userId != user.userId &&
                doCoordsMatch(x.flexCoords, user.flexCoords)
            )
          : teamMapUsers.filter(
              (x) =>
                x.userId != user.userId &&
                doCoordsMatch(x.copeCoords, user.copeCoords)
            );

        if (matches.length > 0) {
          // Add the matches to the user to remove array along with the current user
          userIdsToRemove = [
            ...userIdsToRemove,
            ...matches.map((x) => x.userId),
            user.userId,
          ];

          let group: TeamMapUser = {
            userId: -1,
            flexCoords: isFlex ? matches[0].flexCoords : { x: 0, y: 0 },
            copeCoords: !isFlex ? matches[0].copeCoords : { x: 0, y: 0 },
            initials: '2+',
            nestedUsers: [...matches, { ...user }],
          };

          userGroups.push(group);
        }
      }
    });

    //Remove the users that have been grouped
    teamMapUsers = teamMapUsers.filter(
      (x) => !userIdsToRemove.includes(x.userId)
    );

    //Add the groups
    teamMapUsers = [...teamMapUsers, ...userGroups];

    let teamDescription = isFlex
      ? team?.flexDescription!
      : team?.copeDescription!;
    if (teamDescription == undefined) teamDescription = '';

    return {
      members: teamMapUsers,
      inSpotlight: isFlex ? team?.flexInSpotlight! : team?.copeInSpotlight!,
      description: teamDescription.split('\n\n'),
    };
  }
);

export const selectFilteredUsers = createSelector(
  selectAllUsers,
  selectUserSearchFilter,
  (users: User[], filter: string) => {
    if (filter.length === 0) {
      return [];
    }

    return users.filter((x) =>
      `${x.firstName} ${x.lastName}`
        .toLowerCase()
        .includes(filter.toLowerCase())
    );
  }
);

export const selectFilteredTeamUsers = createSelector(
  selectSelectedTeamWithUsers,
  selectUserSearchFilter,
  (team: Team | undefined, filterString: string) => {
    if (filterString.length === 0) {
      return team?.members;
    }
    return team?.members.filter((x) =>
      `${x.firstName} ${x.lastName}`
        .toLowerCase()
        .includes(filterString.toLowerCase())
    );
  }
);

export const selectConnectedUsers = createSelector(
  selectAllUsers,
  (users: User[]) => {
    return users.filter(
      (x) => x.connectionStatus === ConnectionStatus.Connected
    );
  }
);

export const selectConnectedUsersAks = createSelector(
  selectConnectedUsers,
  (users: User[]) => {
    return users.map((x) => x.id);
  }
);

export const selectConnectedUserCount = createSelector(
  selectAllUsers,
  (users: User[]) => {
    return users.filter(
      (x) => x.connectionStatus === ConnectionStatus.Connected
    ).length;
  }
);

export const selectPendingUsers = createSelector(
  selectAllUsers,
  (users: User[]) => {
    return users.filter((x) => x.connectionStatus === ConnectionStatus.Pending);
  }
);

export const selectRandomUnconnectedUsers = createSelector(
  selectAllUsers,
  selectCurrentUser,
  (users: User[], currentUser?: User) => {
    var unconnectedUsers = users
      .filter(
        (x) =>
          x.connectionStatus === ConnectionStatus.Unconnected &&
          x.id != currentUser?.id
      )
      .sort(() => 0.5 - Math.random());

    return unconnectedUsers.slice(
      0,
      unconnectedUsers.length >= 3 ? 3 : unconnectedUsers.length
    );
  }
);

export const selectPendingRequestCount = createSelector(
  selectAllUsers,
  (users: User[]) => {
    return users.filter((x) => x.connectionStatus === ConnectionStatus.Pending)
      .length;
  }
);

export const selectSelectedUserLeadingFlex = createSelector(
  selectSelectedUser,
  (user: User | undefined) => {
    if (user) {
      switch (user.flex[0]) {
        case 'F':
          return 'Forceful';
        case 'L':
          return 'Logical';
        case 'E':
          return 'Empathic';
        case 'X':
          return 'Expressive';
        default:
          return '';
      }
    }
    return '';
  }
);

export const selectSelectedUserLeadingCope = createSelector(
  selectSelectedUser,
  (user: User | undefined) => {
    if (user) {
      switch (user.cope[0]) {
        case 'C':
          return 'Contained';
        case 'O':
          return 'Optimistic';
        case 'P':
          return 'Prudent';
        case 'E':
          return 'Enagaged';
        default:
          return '';
      }
    }

    return '';
  }
);




export const selectCurrentUserLeadingFlex = createSelector(
  selectCurrentUser,
  (user: User | undefined) => {
    if (user) {
      switch (user.flex[0]) {
        case 'F':
          return 'Forceful';
        case 'L':
          return 'Logical';
        case 'E':
          return 'Empathic';
        case 'X':
          return 'Expressive';
        default:
          return '';
      }
    }
    return '';
  }
);

export const selectCurrentUserLeadingCope = createSelector(
  selectCurrentUser,
  (user: User | undefined) => {
    if (user) {
      switch (user.cope[0]) {
        case 'C':
          return 'Contained';
        case 'O':
          return 'Optimistic';
        case 'P':
          return 'Prudent';
        case 'E':
          return 'Enagaged';
        default:
          return '';
      }
    }

    return '';
  }
);
export const selectSelectedTeamMapUserFlex = createSelector(
  selectAllUserEntities,
  selectSelectedTeamMapUserAK,
  (users: Dictionary<User>, userAK?: number) => {
    if (userAK) {
      return users[userAK]?.flex!;
    }
    return '';
  }
);

export const selectSelectedTeamMapUserCope = createSelector(
  selectAllUserEntities,
  selectSelectedTeamMapUserAK,
  (users: Dictionary<User>, userAK?: number) => {
    if (userAK) {
      return users[userAK]?.cope!;
    }
    return '';
  }
);

export const selectTeamsILead = createSelector(
  selectCurrentUser,
  selectMyTeams,
  (user: User | undefined, teams: Team[]) => {
    if (user) return teams.filter((x) => x.ownerId === user.id);

    return [];
  }
);

export const selectIsSuperAdmin = createSelector(
  selectCurrentUser,
  (user: User | undefined) => {
    return user?.isSuperAdmin ? true : false;
  }
);

export const selectUserCount = totalActiveUsers;

export const selectUserAdminPage = (state: AppState) =>
  state[userStateKey].userAdminPage;
export const selectUserAdminTake = (state: AppState) =>
  state[userStateKey].userAdminTake;

export const selectUserManagementPageCount = createSelector(
  selectUserCount,
  selectUserAdminTake,
  (count: number, take: number) => {
    if (!count) return 0;

    return Math.ceil(count / take);
  }
);

export const selectUserForUserManagement = createSelector(
  selectAllUsers,
  selectUserAdminPage,
  selectUserAdminTake,
  (users: User[], page: number, take: number) => {
    let skip = take * (page - 1);
    let end = take * page;

    return users.slice(skip, end);
  }
);

function doCoordsMatch(
  coordsA: TeamMapCoordinates,
  coordsB: TeamMapCoordinates
) {
  return coordsA.x == coordsB.x && coordsA.y == coordsB.y;
}

export const selectUserGroup = (userGroup: number[]) =>
  createSelector(selectAllUsers, (users: User[]) => {
    return users.filter((user) => userGroup.includes(user.id));
  });

export const selectNotificationSettings = (state: AppState) =>
  state[userStateKey].notificationSettings;

export const selectUserById = (userId: number) =>
  createSelector(selectAllUserEntities, (users: Dictionary<User>): User => {
    return users[userId]!;
  });

export const selectCookieSettings = (state: AppState) =>
  state[userStateKey].cookieSettings;

export const selectMyChampionCreatedTeams = createSelector(
  selectMyTeams,
  selectCurrentUser,
  (teams: Team[], user?: User) => {
    return user
      ? teams.filter(
          (x) => x.ownerId == user.id && !x.memberIds.includes(user.id)
        )
      : [];
  }
);

export const selectAllTeamsForAdmin = createSelector(
  selectTeamState,
  selectCurrentUser,
  (teamState: TeamState, user?: User) => {
    return teamState.allTeamsForAdmin;
  }
);

export const selectMyJoinedTeams = createSelector(
  selectMyTeams,
  selectCurrentUser,
  (teams: Team[], user?: User) => {
    return user ? teams.filter((x) => x.memberIds.includes(user.id)) : [];
  }
);

export const selectHasTeamAdminPermissions = createSelector(
  selectSelectedTeamWithUsers,
  selectCurrentUser,
  (team?: Team, user?: User) => {
    if (!team || !user) return false;

    if (!team.teamAdminIds)
      return team.ownerId === user.id;

    return (
      team.ownerId === user.id ||
      team.teamAdminIds.includes(user.id)
    );
  }
);

export const selectDirectReports = createSelector(
  selectCurrentUser,
  selectAllUserEntities,
  (currentUser: User | undefined, allUserEntities: Dictionary<User>) => {
    const directReports: User[] = [];

    if (currentUser && currentUser.invitedUserAks?.length) {
      currentUser.invitedUserAks.forEach((ak) => {
        const user = allUserEntities[ak];

        if (user) directReports.push(user);
      });
    }

    return directReports;
  }
);

export const selectIsPulseSurveyDue = createSelector(
  selectCurrentUser,
  (currentUser: User | undefined) => {
    return !!currentUser ? currentUser.isPulseSurveyDue : false;
  }
);

export const selectHasMindflickEmail = createSelector(
  selectCurrentUser,
  (currentUser?: User) => currentUser?.email.includes('@mindflick.co.uk')
);

export const selectIsOnPlatformAndPractitioner = (state: AppState) =>
  state[userStateKey].isOnPlatformAndPractitioner;

export const selectIsMindflickCoach = createSelector(
  selectCurrentUser,
  (user?: User) => {
    return user?.isMindflickCoach ?? false
  }
)


export const selectCurrentUserIsOwnerOfTeam = createSelector(
  selectCurrentUser,
  selectSelectedTeam,
  (currentUser?: User, currentTeam?: Team) => {
    return currentTeam?.ownerId == currentUser?.id;
  }
);


export const selectIsCurrentUserGhost = createSelector(
  selectCurrentUser,
  selectSelectedTeam,
  (currentUser? : User, currentTeam? : Team) => {
    if(currentUser && currentTeam){
      if(!(currentUser.id == currentTeam.ownerId)) return false

      return !currentTeam.memberIds.includes(currentUser.id)
    }
    return false
  }
)

export const selectCanViewTeamInAdminMode = createSelector(
  selectCurrentUser,
  selectSelectedTeam,
  (user?: User, team?: Team) => {
    if (!user)
      return;

    if (!team)
      return;

    const canViewTeamInAdminMode = user.accountRoles[team.mindflickAccountId]?.some(ar => ar.permissions.some(p => p === 'VIEW_ALL_TEAMS'));

    return canViewTeamInAdminMode;
  }
);

export const selectCanUserViewAdmin = createSelector(
  selectCurrentUser,
  (user?: User) => {
    if (!user)
      return false;

    const userPermissions = Object.values(user.accountRoles).flatMap(x => x).flatMap(x => x.permissions);

    return userPermissions.includes("USER_MANAGE") || userPermissions.includes("VIEW_ACC_ANALYTICS");
  }
)

export const selectCurrentUserLatestDebriefStage = createSelector(
  selectCurrentUser,
  (user?: User) => {
    return user?.latestDebriefStage;
  }
);
