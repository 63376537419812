import { Component, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SetAccountAdminSearchTerm } from '../../../+state/account-admin/account-admin.actions';
import {
  selectAccountAdminSearchTerm,
  selectHelpCenterCategories,
} from '../../../+state/account-admin/account-admin.selector';
import { AppState } from '../../../+state/app.state';
import { HelpCenterCategory } from '../../../shared/models/help-center.interface';

@Component({
  selector: 'app-help-center-header',
  templateUrl: './help-center-header.component.html',
  styleUrls: ['./help-center-header.component.scss'],
})
export class HelpCenterHeaderComponent implements OnInit, OnDestroy, OnChanges {
  categories$ = this.store.select(selectHelpCenterCategories);
  searchTerm$ = this.store.select(selectAccountAdminSearchTerm);
  destroyed$ = new Subject<boolean>();

  currentSearchTerm: string = '';
  filteredCategories: HelpCenterCategory[] = [];
  originalCategories: HelpCenterCategory[] = [];

  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    this.categories$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((categories) => {
        this.originalCategories = categories.map((category) => category);
      });

    this.searchTerm$.pipe(takeUntil(this.destroyed$)).subscribe((term) => {
      this.filterCategoryArticles(term);
    });
  }

  filterCategoryArticles(term: string) {
    if (this.originalCategories.length) {
      this.filteredCategories = this.originalCategories.map((category) => {
        return {
          ...category,
          articles: category.articles.filter((article) =>
            article.title.toLowerCase().includes(term.toLowerCase())
          ),
        } as HelpCenterCategory;
      });
      this.filteredCategories = this.filteredCategories.filter(
        (x) => x.articles.length > 0
      );
    }

    if (term === '') {
      this.filteredCategories = [];
    }
  }

  changeCurrentSearchTerm(_: any) {
    this.store.dispatch(
      SetAccountAdminSearchTerm({ searchTerm: this.currentSearchTerm })
    );
  }

  ngOnChanges() {}

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.unsubscribe();
  }
}
