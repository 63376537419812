import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { GrowthService } from 'src/app/shared/services/growth.service';
import { SuperAdminService } from 'src/app/shared/services/super-admin.service';
import * as GrowthActions from './growth.actions';
import { GrowthType } from './growth.reducer';

@Injectable()
export class GrowthEffects {
  getAllWorkOns$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GrowthActions.GetWorkOns.Request),
      mergeMap((action) =>
        this.growthService.getWorkOns().pipe(
          map((resp) =>
            GrowthActions.GetWorkOns.Success({
              workOns: resp.workOns,
              currentWorkOnId: resp.currentWorkOnId,
              tags: resp.tags,
              playbooks: resp.playbooks,
              workOnHistory: resp.workOnHistory,
              suggestedWorkOnIds: resp.suggestedWorkOnIds,
            })
          ),
          catchError((error) => of(GrowthActions.GetWorkOns.Fail({ error })))
        )
      )
    )
  );

  getLocalGrowth$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GrowthActions.GetWorkOns.Success),
      mergeMap((action) =>
        this.growthService.getLocalGrowth().pipe(
          map((resp) =>
            GrowthActions.SetSelectedGrowth({
              growthId: resp.growthId,
              growthType: resp.growthType,
              playbookId: resp.playbookId,
            })
          ),
          catchError((error) =>
            of(GrowthActions.SetSelectedGrowthFail({ error }))
          )
        )
      )
    )
  );

  getAllWorkOnsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GrowthActions.GetWorkOns.Success),
      mergeMap((action) => [GrowthActions.GetMyWorkOnHistory.Request()])
    )
  );

  setSelectedWorkOn = createEffect(() =>
    this.actions$.pipe(
      ofType(GrowthActions.SetSelectedGrowth),
      mergeMap((action) =>
        action.growthType == GrowthType.WorkOn
          ? [
              GrowthActions.SetWorkOnViewed.Request({
                workOnId: action.growthId,
              }),
            ]
          : []
      )
    )
  );

  setWorkOnViewed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GrowthActions.SetWorkOnViewed.Request),
      mergeMap((action) =>
        this.growthService.setWorkOnViewed(action.workOnId).pipe(
          map((resp) => GrowthActions.SetWorkOnViewed.Success()),
          catchError((error) =>
            of(GrowthActions.SetWorkOnViewed.Fail({ error }))
          )
        )
      )
    )
  );

  saveWorkOn$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GrowthActions.SaveWorkOn.Request),
      mergeMap((action) =>
        this.superAdminService.saveWorkOn(action.workOn).pipe(
          map((resp) => GrowthActions.SaveWorkOn.Success({ newWorkOn: resp })),
          catchError((error) => of(GrowthActions.SaveWorkOn.Fail({ error })))
        )
      )
    )
  );

  savePlaybook$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GrowthActions.SavePlaybook.Request),
      mergeMap((action) =>
        this.superAdminService.savePlaybook(action.playbook).pipe(
          map((resp) =>
            GrowthActions.SavePlaybook.Success({ savedPlaybook: resp })
          ),
          catchError((error) => of(GrowthActions.SavePlaybook.Fail({ error })))
        )
      )
    )
  );

  savePlaybookExercise$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GrowthActions.SavePlaybookExercise.Request),
      mergeMap((action) =>
        this.superAdminService.savePlaybookExercise(action.exercise).pipe(
          map((resp) =>
            GrowthActions.SavePlaybookExercise.Success({ savedExercise: resp })
          ),
          catchError((error) =>
            of(GrowthActions.SavePlaybookExercise.Fail({ error }))
          )
        )
      )
    )
  );

  getWorkOnToHistory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GrowthActions.GetMyWorkOnHistory.Request),
      mergeMap((action) =>
        this.growthService.getWorkOnHistory().pipe(
          map((resp) =>
            GrowthActions.GetMyWorkOnHistory.Success({
              MyWorkOns: resp,
            })
          ),
          catchError((error) =>
            of(GrowthActions.GetMyWorkOnHistory.Fail({ error }))
          )
        )
      )
    )
  );

  addWorkOnToHistory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GrowthActions.AddWorkOnToHistory.Request),
      mergeMap((action) =>
        this.growthService.addWorkOnToHistory(action.workOnId).pipe(
          map((resp) =>
            GrowthActions.AddWorkOnToHistory.Success({
              workOnHistoryEntry: resp,
            })
          ),
          catchError((error) =>
            of(GrowthActions.AddWorkOnToHistory.Fail({ error }))
          )
        )
      )
    )
  );

  setSelectedGrowth$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GrowthActions.SetSelectedGrowth),
      mergeMap((action) =>
        this.growthService
          .setSelectedGrowth(
            action.growthType,
            action.growthId,
            action.playbookId,
            action.engagementResourceId
          )
          .pipe(
            map((resp) => GrowthActions.SetSelectedWorkOnSuccess()),
            catchError((error) =>
              of(GrowthActions.SetSelectedWorkOnFail({ error }))
            )
          )
      )
    )
  );

  updateUpcomingWorkOnsOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GrowthActions.UpdateUpcomingWorkOnsOrder.Request),
      mergeMap((action) =>
        this.growthService.updateUpcomingWorkOnsOrder(action.newOrder).pipe(
          map((resp) =>
            GrowthActions.UpdateUpcomingWorkOnsOrder.Success({
              upcomingWorkOns: resp,
            })
          ),
          catchError((error) =>
            of(GrowthActions.UpdateUpcomingWorkOnsOrder.Fail({ error }))
          )
        )
      )
    )
  );

  deleteUncompletedWorkOns$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GrowthActions.DeleteUpcomingWorkOn.Request),
      mergeMap((action) =>
        this.growthService
          .deleteUncompletedWorkOn(action.workOnId)
          .pipe(
            map((resp) =>
              GrowthActions.DeleteUpcomingWorkOn.Success({ historyId: resp })
            ),
            catchError((error) =>
              of(GrowthActions.DeleteUpcomingWorkOn.Fail({ error }))
            )
          )
      )
    )
  );

  getCurrentNudge$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GrowthActions.GetCurrentNudge.Request),
      mergeMap(() =>
        this.growthService
          .getCurrentNudge()
          .pipe(
            map((resp) =>
              GrowthActions.GetCurrentNudge.Success({ nudge: resp })
            ),
            catchError((error) =>
              of(GrowthActions.GetCurrentNudge.Fail({ error }))
            )
          )
      )
    )
  );

  triggerGetNudges$ = createEffect(() => 
    this.actions$.pipe(
      ofType(GrowthActions.GetMyWorkOnHistory.Success),
      mergeMap(() => 
        [GrowthActions.GetCurrentNudge.Request(), GrowthActions.GetPreviousNudge.Request()]
      )
    )
  )

  getTopOrganisationNudges$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GrowthActions.GetTopOrganisationNudges.Request),
      mergeMap(() =>
        this.growthService
          .getTopOrganisationNudges()
          .pipe(
            map((resp) =>
              GrowthActions.GetTopOrganisationNudges.Success({ nudges: resp })
            ),
            catchError((error) =>
              of(GrowthActions.GetTopOrganisationNudges.Fail({ error }))
            )
          )
      )
    )
  );

  getUsefulNudges$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GrowthActions.GetUsefulNudges.Request),
      mergeMap(() =>
        this.growthService
          .getUsefulNudges()
          .pipe(
            map((resp) =>
              GrowthActions.GetUsefulNudges.Success({ nudges: resp })
            ),
            catchError((error) =>
              of(GrowthActions.GetUsefulNudges.Fail({ error }))
            )
          )
      )
    )
  );

  reflectOnNudge$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GrowthActions.ReflectOnNudge.Request),
      mergeMap((action) =>
        this.growthService
          .reflectOnNudge(action.nudge)
          .pipe(
            map((resp) =>
              GrowthActions.ReflectOnNudge.Success({ nudge: resp })
            ),
            catchError((error) =>
              of(GrowthActions.ReflectOnNudge.Fail({ error }))
            )
          )
      )
    )
  );

  getTopWorkOnThemes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GrowthActions.GetTopWorkOnThemes.Request),
      mergeMap(() =>
        this.growthService
          .getTopWorkOnThemes()
          .pipe(
            map((resp) =>
              GrowthActions.GetTopWorkOnThemes.Success({ themes: resp })
            ),
            catchError((error) =>
              of(GrowthActions.GetTopWorkOnThemes.Fail({ error }))
            )
          )
      )
    )
  );

  getPreviousNudge$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GrowthActions.GetPreviousNudge.Request),
      mergeMap(() =>
        this.growthService
          .getPreviousNudge()
          .pipe(
            map((resp) =>
              GrowthActions.GetPreviousNudge.Success({ nudge: resp })
            ),
            catchError((error) =>
              of(GrowthActions.GetPreviousNudge.Fail({ error }))
            )
          )
      )
    )
  );

  reflectOnPreviousNudge$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GrowthActions.ReflectOnPreviousNudge.Request),
      mergeMap((action) =>
        this.growthService
          .reflectOnPreviousNudge(action.nudge)
          .pipe(
            map((resp) =>
              GrowthActions.ReflectOnPreviousNudge.Success({ nudge: resp })
            ),
            catchError((error) =>
              of(GrowthActions.ReflectOnPreviousNudge.Fail({ error }))
            )
          )
      )
    )
  );


  constructor(
    private actions$: Actions,
    private growthService: GrowthService,
    private superAdminService: SuperAdminService,
  ) {}
}
