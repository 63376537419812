<div class="modal-header justify-content-end">
  <fa-icon
    class="cursor-pointer fa-lg"
    [icon]="closeIcon"
    (click)="close()"
  ></fa-icon>
</div>
<div class="modal-body">
  <h2 class="mb-2">Update Mindflick account</h2>
  <form [formGroup]="editAccountForm" class="row g-3">
    <div class="form-group col-12">
      <label for="name" class="form-label">Name</label>
      <input formControlName="name"
             id="name"
             name="name"
             type="text"
             placeholder="Enter account name"
             required
             class="form-control" />
    </div>
    <div class="form-group col-12">
      <label for="maximumChurnPercentage" class="form-label">Maximum Churn Percent</label>
      <input formControlName="maximumChurnPercentage"
             id="maximumChurnPercentage"
             name="maximumChurnPercentage"
             type="number"
             placeholder="Enter maximum churn percentage"
             class="form-control" />
    </div>
    <div class="form-group renewal-date position-relative col-12">
      <label for="renewalDate" class="form-label">Renewal Date</label>
      <input formControlName="renewalDate"
             type="text"
             placeholder="Select renewal date"
             class="form-control"
             bsDatepicker
             [container]="'.renewal-date'"
             [bsConfig]="{ showWeekNumbers: false, dateInputFormat: 'DD/MM/YYYY' }" />
    </div>
    <div class="form-group col-6">
      <label for="status" class="form-label">Status</label>
      <app-dropdown-select formControlName="status"
                           [options]="accountStatusOptions"
                           [placeholder]="'Select'"></app-dropdown-select>
    </div>
    <div class="form-group col-6">
      <label for="type" class="form-label">Account type</label>
      <app-dropdown-select formControlName="type"
                           [options]="types"
                           [placeholder]="'Select account type'"></app-dropdown-select>
    </div>
  </form>

  <button
    class="btn btn-purple w-100 mt-4"
    [disabled]="accountSaving$ | async"
    (click)="save()"
  >
    <ng-container *ngIf="!(accountSaving$ | async); else spinner"
      >Update Account</ng-container
    >
    <ng-template #spinner
      ><div class="spinner-border text-primary" role="status">
        <span class="visually-hidden">Account Updating...</span>
      </div></ng-template
    >
  </button>
  <div *ngIf="accountSaveSuccess$ | async" class="mt-2 body-copy text-primary">
    Update Successful
  </div>
  <div
    *ngIf="accountSaveError$ | async as error"
    class="mt-2 body-copy text-error"
  >
    {{ error }}
  </div>
</div>
<div class="modal-bottom-border"></div>
