import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../+state/app.state';
import { GetHelpCenterCategories } from 'src/app/+state/account-admin/account-admin.actions';
import { selectAllHelpCenterCategories } from '../../+state/account-admin/account-admin.selector';
import { HelpCenterCategory } from '../../shared/models/help-center.interface';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { faNextSVG } from '../../../icons';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-help-center',
  templateUrl: './help-center.component.html',
  styleUrls: ['./help-center.component.scss'],
})
export class HelpCenterComponent implements OnInit, OnDestroy {
  categories$ = this.store.select(selectAllHelpCenterCategories);
  destroyed$: Subject<boolean> = new Subject<boolean>();
  categories: HelpCenterCategory[] = [];

  next = faNextSVG;

  constructor(
    private store: Store<AppState>,
    private route: Router,
    private router: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.store.dispatch(GetHelpCenterCategories.Request());

    this.categories$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((categories) => {
        this.categories = categories;
      });
  }

  openCategoryPage(title: string) {
    this.route.navigate([`${title}`], { relativeTo: this.router });
  }

  goToArticle(categoryTitle: string, articleTitle: string) {
    this.route.navigate([`${categoryTitle}/${articleTitle}`], {
      relativeTo: this.router,
    });
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.unsubscribe();
  }
}
