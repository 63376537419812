import { createAction, props } from '@ngrx/store';
import {
  ConnectionDetails,
  Profile,
  SaveProfileSelectionModel,
  SaveStrengthCommentDTO,
  StrengthComment,
  TopTipToConnect,
} from 'src/app/shared/models';
import { DigitalDebriefItem } from 'src/app/shared/models/digital-debrief-item';

export namespace GetMyProfile {
  export const Request = createAction('[Profile] Get My Profile Request');

  export const Success = createAction(
    '[Profile] Get My Profile Success',
    props<{ profile: Profile }>()
  );

  export const Fail = createAction(
    '[Profile] Get My Profile Fail',
    props<{ error: any }>()
  );
}

export namespace GetProfileForUser {
  export const Request = createAction(
    '[Profile] Get Profile For User Request',
    props<{ userId: number }>()
  );

  export const Success = createAction(
    '[Profile] Get Profile For User Success',
    props<{ profile: Profile; isCurrentUser: boolean }>()
  );

  export const Fail = createAction(
    '[Profile] Get Profile For User Fail',
    props<{ error: any }>()
  );
}

export namespace SaveProfileSectionSelection {
  export const Request = createAction(
    '[Profile] Save Profile Section Selection Request',
    props<{ body: SaveProfileSelectionModel; sectionId: string }>()
  );

  export const Success = createAction(
    '[Profile] Save Profile Section Selection Success',
    props<{ body: SaveProfileSelectionModel; sectionId: string }>()
  );

  export const Fail = createAction(
    '[Profile] Save Profile Section Selection Fail',
    props<{ error: any }>()
  );
}

export namespace SaveRecognizedStrengths {
  export const Request = createAction(
    '[Profile] Save Recognized Strengths Request',
    props<{
      saveStrengthComment: SaveStrengthCommentDTO,
      modalRef?: string | number;
    }>()
  );

  export const Success = createAction(
    '[Profile] Save Recognized Strengths Success',
    props<{
      newStrengthComment: StrengthComment
    }>()
  );

  export const Fail = createAction(
    '[Profile] Save Recognized Strengths Fail',
    props<{ error: any }>()
  );
}

export namespace GenerateConnectionDetails {
  export const Request = createAction(
    '[User] Generate Connection Details Request',
    props<{ currentUserAK: number; connectedUserAK: number }>()
  );

  export const Success = createAction(
    '[User] Generate Connection Details Success',
    props<{ connectedUserAK: number; response: ConnectionDetails }>()
  );

  export const Fail = createAction(
    '[User] Generate Connection Details Fail',
    props<{ error: any }>()
  );
}

export const SetViewingOwnProfile = createAction(
  '[User] Set Viewing Own Profile',
  props<{ isViewingOwnProfile: boolean }>()
);

export namespace StrengthSpottedNotification {
  export const View = createAction(
    '[Profile] Navigate To Spotted Strength',
    props<{ strengthId: string, userAK: number }>()
  );

  export const Hide = createAction('[Profile] Hide spotted strength');
}

export namespace GetProfileDetails {
  export const Request = createAction(
    '[Profile] Get Profile Details for current user Request'
  );

  export const Success = createAction(
    '[Profile] Get Profile Details for current user Success'
  );

  export const Fail = createAction(
    '[Profile] Get Profile Details for current user Fail',
    props<{ error: any }>()
  );
}

export namespace GetTopTipToConnect {
  export const Request = createAction(
    '[User] Get Top Tip To Connect Request',
    props<{ viewedUserAk: Number }>()
  );
  export const Success = createAction(
    '[User] Get Top Tip To Connect Success',
    props<{ topTip: TopTipToConnect }>()
  );

  export const Fail = createAction(
    '[User] Get Top Tip To Connect Fail',
    props<{ error: any }>()
  );
}

export namespace SetTopTip {
  export const Request = createAction(
    '[User] Set Top Tip Request',
    props<{ pinnedTip: TopTipToConnect }>()
  );
  export const Success = createAction(
    '[User] Set Top Tip Success',
    props<{ topTip: TopTipToConnect }>()
  );

  export const Fail = createAction(
    '[User] Set Top Tip Fail',
    props<{ error: any }>()
  );
}

export namespace RemoveTopTip {
  export const Request = createAction(
    '[User] Remove Top Tip Request',
    props<{ pinnedTip: TopTipToConnect }>()
  );
  export const Success = createAction(
    '[User] Remove Top Tip Success',
    props<{ topTip: TopTipToConnect }>()
  );

  export const Fail = createAction(
    '[User] Remove Top Tip Fail',
    props<{ error: any }>()
  );
}

export namespace SayThanksStrengthSpot {
  export const Request = createAction(
    '[Profile] Say Thanks Strength Spot Request',
    props<{ strengthComment: StrengthComment | StrengthComment }>()
  );

  export const Success = createAction(
    '[Profile] Say Thanks Strength Spot Success',
    props<{
      strengthComment: StrengthComment
    }>()
  );

  export const Fail = createAction(
    '[Profile] Say Thanks Strength Spot Fail',
    props<{ error: any }>()
  );
}

export namespace SaveDebriefSelectedProfileItem {
  export const Request = createAction(
    '[User] Save Selected Profile Item Request',
    props<{ items: DigitalDebriefItem[]; }>()
  );
  export const Success = createAction(
    '[User] Save Selected Profile Item Success'
  );

  export const Fail = createAction(
    '[User] Save Selected Profile Item Fail',
    props<{ error: any; }>()
  );
}

export namespace ProcessDebriefReport {
  export const Request = createAction(
    '[User] Process Debrief Report Request',
    props<{ spotlightResponseId: number }>()
  );
  export const Success = createAction(
    '[User] Process Debrief Report Success'
  );

  export const Fail = createAction(
    '[User] Process Debrief Report Fail',
    props<{ error: any; }>()
  );
}


