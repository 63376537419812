export interface UpdateTeamMapStatusReq {
  ownerId: string,
  status: number,
  errors: string,
  token: string;
}

export enum TeamMapDownloadStatus {
  Incomplete,
  Complete,
  Processing,
  Processed,
  Errored,
  Deleted
}
