import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { combineLatest, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { AppState } from 'src/app/+state/app.state';
import { GrowthType } from 'src/app/+state/growth/growth.reducer';
import {
  selectAllActivePlaybooks,
  selectCurrentWorkOn,
  selectSelectedGrowth,
  selectSelectedGrowthTags,
  selectSelectedGrowthType,
  selectUpcomingWorkOns,
} from 'src/app/+state/growth/growth.selector';
import { selectTeamsILead } from 'src/app/+state/user/user.selector';

import {
  GrowthTag,
  WorkOn,
  WorkOnTagsDTO,
  WorkOnType,
} from 'src/app/shared/models';
import {
  PlaybookExercise,
  PlaybookExerciseResource,
} from 'src/app/shared/models/playbooks.interface';
import { SelectTeamModalComponent } from '../select-team-modal/select-team-modal.component';
import * as TeamActions from '../../../+state/team/team.actions';
import * as GrowthActions from '../../../+state/growth/growth.actions';
import { SetSubMenuLargePopout } from '../../../+state/layout/actions/layout.actions';
import { SubMenuLargePopoutEnum } from '../../../+state/layout/reducers/layout.reducers';
import { Router } from '@angular/router';
import { DownloadService } from '../../services/download.service';
import { faBackSVG, faNextSVG } from '../../../../icons';
import { DialogService } from '../../services/dialog.service';
import { AddEvent } from '../../../+state/audit/audit.actions';
import { AuditEventType, PlatformAreas } from '../../models/enums/audit.enum';
import { EngagementResource } from '../../models/engagement-resource.interface';

@Component({
  selector: 'app-growth-details-popout',
  templateUrl: './growth-details-popout.component.html',
  styleUrls: ['./growth-details-popout.component.scss'],
})
export class GrowthDetailsPopoutComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChildren('content')
  contentSections?: QueryList<ElementRef>;
  @ViewChildren('contentTitle')
  contentTitles?: QueryList<ElementRef>;
  @ViewChild('container') container?: ElementRef;
  @Input() carouselHeading: string = '';

  destroyed$: Subject<boolean> = new Subject<boolean>();
  growth$ = this.store.select(selectSelectedGrowth);
  growthType$ = this.store.select(selectSelectedGrowthType);
  growthTags$ = this.store.select(selectSelectedGrowthTags);
  activePlaybooks$ = this.store.select(selectAllActivePlaybooks);

  workOn?: WorkOn;
  exercise?: PlaybookExercise;
  engagementResource?: EngagementResource;

  growthDetails?: WorkOn | PlaybookExercise | EngagementResource;
  tags: GrowthTag[] = [];

  workOnType = WorkOnType;

  activeTabScroll: { [key: string]: boolean } = {};
  isUnscrolled: boolean = true;
  yOffset: number = -60;
  isWorkOn: boolean = false;
  isPlaybook: boolean = false;
  isEngagementResource: boolean = false;
  isScrollMenuShowing = false;
  hasResources = false;

  addToTeamGrowthDisabled = false;
  nudgeMeOnThisDisabled = false;
  timeToRead: number = 0;
  modalRef?: BsModalRef;
  teamsILead$ = this.store.select(selectTeamsILead);

  back = faBackSVG;
  next = faNextSVG;

  nudgesQty?: number = undefined;

  categoryTitle?: string;
  subCategoryTitle?: string;

  constructor(
    private store: Store<AppState>,
    private modalService: BsModalService,
    private router: Router,
    public downloadService: DownloadService,
    public dialogService: DialogService,
    @Inject('BASE_URL') private baseUrl: string
  ) {}

  ngOnInit(): void {
    combineLatest([
      this.store.select(selectSelectedGrowth),
      this.store.select(selectSelectedGrowthType),
      this.teamsILead$,
      this.store.select(selectCurrentWorkOn),
      this.store.select(selectUpcomingWorkOns),
      this.store.select(selectSelectedGrowthTags),
    ])
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        ([growth, type, teams, currentWorkOn, upcomingWorkOns, tags]) => {
          this.growthDetails = growth;

          if (type == GrowthType.Playbook) {
            this.isPlaybook = true;
            this.exercise = <PlaybookExercise>growth;

            this.addToTeamGrowthDisabled = teams.every((x) =>
              x.exercises.some((e) => e.exerciseId === growth?.id)
            );

            this.hasResources = !!this.exercise.resources.length;
          } else if (type == GrowthType.WorkOn) {
            this.isWorkOn = true;
            this.workOn = <WorkOn>growth;
            this.nudgeMeOnThisDisabled =
              (currentWorkOn && currentWorkOn?.id === growth?.id) ||
              upcomingWorkOns.some((x) => x.id === growth?.id);
            this.nudgesQty = this.workOn?.nudges.length;
            this.timeToRead = 0;
            this.growthDetails?.sections.forEach(
              (section) =>
                (this.timeToRead += Math.ceil(
                  section.contentHtml.split(/\s+/).length / 250
                ))
            );

            var sortedTags = this.workOn!.workOnTags?.map((x) => {
              return <WorkOnTagsDTO>{ tagId: x.tagId, orderById: x.orderById };
            }).sort((a, b) => a.orderById - b.orderById)!;

            this.tags = sortedTags.map(
              (workOnTag) => tags.find((x) => x.id == workOnTag.tagId)!
            )!;
          } else if (type == GrowthType.EngagementResource) {
            this.isEngagementResource = true;
            this.growthDetails?.sections.forEach(
              (section) =>
                (this.timeToRead += Math.ceil(
                  section.contentHtml.split(/\s+/).length / 250
                ))
            );
          }
        }
      );

    this.activePlaybooks$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((playbooks) => {
        this.carouselHeading = playbooks.find(
          (x) => x.id == this.exercise?.playbookId
        )?.title!;
      });
  }

  nudgeCountMapping = {
    nudge: {
      '=0': 'are no weekly nudges',
      '=1': 'is one nudge',
      other: 'are # weekly nudges',
    },
  };

  ngAfterViewInit(): void {
    this.container?.nativeElement.addEventListener('scroll', () => {
      this.setActiveTab();
    });
  }

  setActiveTab() {
    this.isUnscrolled = false;

    this.contentSections?.forEach((div, index) => {
      let sectionTitle =
        this.contentTitles?.get(index)?.nativeElement.innerText;

      let isScrollPositionWithinDiv = this.checkScrollPosition(div, index);

      this.activeTabScroll[sectionTitle] = isScrollPositionWithinDiv;
    });
  }

  private checkScrollPosition(div: ElementRef<any>, index: number) {
    let topYPositionWithOffset = div.nativeElement.offsetTop + this.yOffset;
    let isScrollPositionGreaterThanTop =
      this.container?.nativeElement.scrollTop > topYPositionWithOffset;

    let bottomYPosition =
      topYPositionWithOffset + div.nativeElement.offsetHeight;
    let isScrollPositionLessThanBottom =
      this.container?.nativeElement.scrollTop < bottomYPosition;

    if (index == 0) {
      this.isScrollMenuShowing = isScrollPositionGreaterThanTop ? true : false;
      isScrollPositionGreaterThanTop = true;
    }

    return isScrollPositionLessThanBottom && isScrollPositionGreaterThanTop;
  }

  scrollToSection(sectionTitle: string) {
    this.contentTitles?.forEach((title) => {
      if (title.nativeElement.innerText == sectionTitle) {
        let titleYPosition = title.nativeElement.offsetTop;
        let yPositionWithOffset = titleYPosition + this.yOffset;

        setTimeout(() => {
          this.container?.nativeElement.scrollTo({
            left: 0,
            top: yPositionWithOffset,
            behavior: 'smooth',
          });
        }, 100);

        let scrollPosition = this.container?.nativeElement.offsetTop;

        if (scrollPosition != yPositionWithOffset) {
          setTimeout(() => {
            title.nativeElement.scrollIntoView({
              block: 'start',
              behavior: 'smooth',
            });
          }, 100);
        }
      }
    });
  }

  addToTeamGrowth() {
    if (!this.isPlaybook) return;

    this.teamsILead$.pipe(take(1)).subscribe((teams) => {
      let availableTeams = teams.filter(
        (x) => !x.exercises.some((e) => e.exerciseId === this.growthDetails?.id)
      );

      if (!availableTeams.length) return;

      if (teams.length > 1) {
        // Open select team modal
        let modalRef = this.modalService.show(SelectTeamModalComponent, {
          initialState: {
            teams: availableTeams,
          },
        });

        this.handleSelectTeamCallback(modalRef);
      } else {
        if (this.growthDetails)
          this.store.dispatch(
            TeamActions.AddExerciseToTeam.Request({
              payload: {
                teamId: availableTeams[0].id,
                exerciseId: this.growthDetails.id,
              },
            })
          );
      }
    });
  }

  exploreGrowth() {
    this.router.navigate(['/growth/work-ons']);
    this.closeDetailPopup();
  }

  handleSelectTeamCallback(modalRef: BsModalRef<SelectTeamModalComponent>) {
    modalRef.content?.teamSelected.pipe(take(1)).subscribe((value) => {
      if (value && this.growthDetails) {
        this.store.dispatch(
          TeamActions.AddExerciseToTeam.Request({
            payload: { teamId: value.id, exerciseId: this.growthDetails.id },
          })
        );
      }
    });
  }

  nudgeMeOnThis() {
    if (this.isPlaybook) return;

    if (this.growthDetails)
      this.store.dispatch(
        GrowthActions.AddWorkOnToHistory.Request({
          workOnId: this.growthDetails.id,
        })
      );

    this.nudgeConfirmation();
  }

  closeDetailPopup() {
    this.store.dispatch(
      SetSubMenuLargePopout({ subMenuLargePopout: SubMenuLargePopoutEnum.None })
    );
  }

  goToStore(url: string) {
    this.modalRef?.hide();
    window.open(url, '_blank');
  }

  showDownloadApp(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { class: 'modal-lg' });
  }

  ngOnDestroy(): void {
    this.store.dispatch(GrowthActions.ClearSelectedGrowth());
    this.destroyed$.next(true);
    this.destroyed$.unsubscribe();
    this.container?.nativeElement.removeEventListener(
      'scroll',
      this.setActiveTab
    );
  }

  downloadResource(resource: PlaybookExerciseResource) {
    this.store.dispatch(
      AddEvent({
        event: {
          eventDate: new Date(),
          eventType: AuditEventType.PlaybookResourceDownloaded,
          area: PlatformAreas.Growth,
          areaId: resource.id,
        },
      })
    );
    this.downloadService.addToQueue(
      resource.fileName,
      `${this.baseUrl}growth/playbook/resource/download/${resource.id}`
    );
  }

  nudgeConfirmation() {
    this.dialogService
      .confirm(
        `Please click confirm to return to individual work ons`,
        `You will receive nudges on '${this.growthDetails?.title}'`
      )
      .pipe(take(1))
      .subscribe((result) => {
        if (result) {
          this.closeDetailPopup();
          this.store.dispatch(
            AddEvent({
              event: {
                eventDate: new Date(),
                eventType: AuditEventType.WorkOnNudged,
                area: PlatformAreas.WorkOns,
                areaId: this.growthDetails?.id,
              },
            })
          );
        }
      });
  }
}
