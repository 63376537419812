import { Component, Input, OnInit, inject } from '@angular/core';
import { faCloseSVG } from '../../../../icons';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UpdateSeatCount } from '../../../+state/super-admin/super-admin.actions';
import { AccountSeatsDTO } from '../../../shared/models';
import { Store } from '@ngrx/store';
import { AppState } from '../../../+state/app.state';

@Component({
  selector: 'app-edit-seats-modal',
  templateUrl: './edit-seats-modal.component.html',
  styleUrl: './edit-seats-modal.component.scss'
})
export class EditSeatsModalComponent implements OnInit {

  store = inject(Store<AppState>);
  modalRef = inject(BsModalRef);

  @Input() mindflickAccountId = 0;
  @Input() minSeats = 0;
  @Input() currentSeats = 0;
  @Input() orgId = 0;

  fgSeatAudit = new FormGroup(
    {
      seats: new FormControl<number | null>(this.currentSeats, [Validators.required]),
      comment: new FormControl<string | null>(null, [Validators.required])
    }
  );

  closeIcon = faCloseSVG;

  ngOnInit() {
    this.fgSeatAudit.controls.seats.setValue(this.currentSeats);
    this.fgSeatAudit.controls.seats.addValidators(Validators.min(this.minSeats));
  }

  close() {
    this.modalRef.hide();
  }

  updateSeats() {
    if (this.fgSeatAudit.invalid) {
      this.fgSeatAudit.markAllAsTouched();
      return;
    }

    var model: AccountSeatsDTO = {
      mindflickAccountId: this.mindflickAccountId,
      deltaSeats: (this.fgSeatAudit.controls.seats?.value ?? 0) - this.currentSeats,
      comment: this.comment?.value ?? ''
    };

    this.store.dispatch(UpdateSeatCount.Request({ model: model, orgId: this.orgId, modalRef: this.modalRef.id }));
  }

  get seats() {
    return this.fgSeatAudit.get("seats");
  }

  get comment() {
    return this.fgSeatAudit.get("comment");
  }

}
