<div class="modal-header justify-content-end">
  <fa-icon
    class="cursor-pointer fa-lg"
    [icon]="closeIcon"
    (click)="close()"
  ></fa-icon>
</div>
<div class="modal-body">
  <h2>Edit Account Admin</h2>
  <h3 *ngIf="selectedAccount$ | async as account" class="mt-2">Select an admin for the {{ account.accountName }} account.</h3>
  <div class="mt-4">
      <label class="form-label">Select admin</label>
      <app-dropdown-select [options]="accountAdminOptions" [(ngModel)]="selectedUserAk" placeholder="Select admin"></app-dropdown-select>
  </div>
  <button class="btn btn-purple mt-4 w-100" (click)="saveChanges()">Save Changes</button>
</div>
<div class="modal-bottom-border"></div>