export const AlphabeticalSort = (a: string, b: string) => {
  if (a < b) return -1;
  if (a > b) return 1;
  return 0;
};

export const NumericalSort = (a: number, b: number) => {
  if (a < b) return -1;
  if (a > b) return 1;
  return 0;
};

export const DateSortDesc = (a: Date, b: Date) => {
  if (a > b) return -1;
  if (a < b) return 1;
  return 0;
};
