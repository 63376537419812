import { createAction, props } from '@ngrx/store';
import {
  SaveAnswersRequest,
  SpotlightResponse,
  SurveyCreateRequest,
  SurveyCreateResponse,
} from 'src/app/shared/models';
import { QuestionnaireResponseDTO, SaveQuestionnaireAnswersDTO } from '../../../shared/models/questionnaire-option.interface';

export namespace LoadSurveyFromInvite {
  export const Request = createAction(
    '[Survey] Load Survey From Invite Request',
    props<{ spotlightResponseId: number; uniqueRef: string, langId?: string }>()
  );

  export const Success = createAction(
    '[Survey] Load Survey From Invite Success',
    props<{ response: SpotlightResponse }>()
  );

  export const Fail = createAction(
    '[Survey] Load Survey From Invite Fail',
    props<{ error: any }>()
  );
}

export namespace UpdateSurvey {
  export const Request = createAction(
    '[Survey] Update Survey Request',
    props<{
      uniqueRef: string;
      spotlightResponseId: number;
      request: SurveyCreateRequest;
    }>()
  );

  export const Success = createAction(
    '[Survey] Update Survey Success',
    props<{ data: SurveyCreateResponse }>()
  );

  export const Fail = createAction(
    '[Survey] Update Survey Failure',
    props<{ error: any }>()
  );
}

export namespace SaveSurveyAnswers {
  export const Request = createAction(
    '[Survey] Save Survey Answers Request',
    props<{ request: SaveAnswersRequest }>()
  );

  export const Success = createAction('[Survey] Save Survey Answers Success');

  export const Fail = createAction(
    '[Survey] Save Survey Answers Failure',
    props<{ error: any }>()
  );
}

export const SetSurveyPage = createAction(
  '[Survey] Set Page',
  props<{ pageId: number }>()
);

export const PreviousPage = createAction('[Survey] Previous Page');

export const NextPage = createAction('[Survey] Next Page');

export const SetSpotlightResponseId = createAction(
  '[Survey] Set Spotlight Response Id',
  props<{ spotlightResponseId: number }>()
);

export const SetUniqueRef = createAction(
  '[Survey] Set Unique Ref',
  props<{ uniqueRef: string }>()
);

export const CheckExistingSurvey = createAction(
  '[Survey] Check Existing Survey',
  props<{ uniqueRef: string; spotlightResponseId: number }>()
);

export const SetSurveyIsSaving = createAction(
  '[Survey] Set Survey Is Saving',
  props<{ isSaving: boolean }>()
);

export namespace GetQuestionnairesForSpotlightResponse {
  export const Request = createAction(
    '[Survey] Get Questionnaires For Spotlight Responses Request',
    props<{
      uniqueRef: string;
      spotlightResponseId: number;
    }>()
  );

  export const Success = createAction(
    '[Survey] Get Questionnaires For Spotlight Responses Success',
    props<{ questionnaires: QuestionnaireResponseDTO[]; }>()
  );

  export const Fail = createAction(
    '[Survey] Get Questionnaires For Spotlight Responses Fail',
    props<{ error: any; }>()
  );
}

export namespace GetQuestionnaireResponseDetails {
  export const Request = createAction(
    '[Survey] Get Details for Questionnaire Response Request',
    props<{
      uniqueRef: string;
      questionnaireResponseId: number;
    }>()
  );

  export const Success = createAction(
    '[Survey] Get Details for Questionnaire Response Success',
    props<{ questionnaire: QuestionnaireResponseDTO; }>()
  );

  export const Fail = createAction(
    '[Survey] Get Details for Questionnaire Response Fail',
    props<{ error: any; }>()
  );
}

export namespace SaveQuestionnaireAnswers {
  export const Request = createAction(
    '[Survey] Save Questionnaire Answers Request',
    props<{
      questionnaireAnswers: SaveQuestionnaireAnswersDTO,
      isComplete: boolean;
    }>()
  );

  export const Success = createAction(
    '[Survey] Save Questionnaire Answers Success',
    props<{
      isComplete: boolean;
    }>()
  );

  export const Fail = createAction(
    '[Survey] Save Questionnaire Answers Fail',
    props<{ error: any; }>()
  );
}

export namespace SetUserPicture {
  export const Request = createAction(
    '[Survey] Set User Picture Request',
    props<{
      formData: FormData;
      spotlightResponseId: number;
      uniqueRef: string;
    }>()
  );

  export const Success = createAction(
    '[Survey] Set User Picture Success',
    props<{ photoSrc: string }>()
  );

  export const Fail = createAction(
    '[Survey] Set User Picture Fail',
    props<{ error: any }>()
  );
}
