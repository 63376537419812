import { createAction, props } from "@ngrx/store";
import { DebriefReadoutIds } from "src/app/shared/models/debrief-readout.interface";

export const SetSelectedReadoutItemId = createAction(
  '[Debrief] Set Selected Readout Item Id',
  props<{ id: DebriefReadoutIds }>()
);

export const ClearSelectedReadoutItemId = createAction(
  '[Debrief] Clear Selected Readout Item Id'
);

export const ClearLatestReadoutId = createAction(
  '[Debrief] Clear Latest Readout Id'
);

export const SetSelectedReadoutVoice = createAction(
  '[Debrief] Set Selected Readout Voice',
  props<{ voice: 'f' | 'm' }>()
);

