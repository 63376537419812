import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GetValidAccountAdminsForOrg, SetMindflickOrganisationSettings } from 'src/app/+state/account-admin/account-admin.actions';
import { selectMindflickOrganisationSettings } from 'src/app/+state/account-admin/account-admin.selector';
import { AppState } from 'src/app/+state/app.state';
import { SelectValue } from 'src/app/shared/models';
import { OrganisationSettings } from 'src/app/shared/models/account-admin-managed-users-DTO';
import { RbacService } from 'src/app/shared/services/rbac.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit {
  dataRetentionOptions: SelectValue[] = [
    {
      value: 0,
      description: '0 days',
    },
    {
      value: 30,
      description: '30 days',
    },
    {
      value: 60,
      description: '60 days',
    },
    {
      value: 90,
      description: '90 days',
    },
    {
      value: 120,
      description: '120 days',
    },
    {
      value: 150,
      description: '150 days',
    },
    {
      value: 180,
      description: '180 days',
    },
  ];

  sessionDurationOptions: SelectValue[] = [
    {
      value: 180,
      description: '3hr',
    },
    {
      value: 360,
      description: '6hr',
    },
    {
      value: 540,
      description: '9hr',
    },
    {
      value: 720,
      description: '12hr',
    },
    {
      value: 1440,
      description: '24hr',
    },
  ];

  mfaTitle = 'Two factor authentication';
  mfaLabel = 'Add extra security to your account';

  ssoTitle = 'Single sign-on';
  ssoLabel = 'Use one login for multiple platforms';

  selectedDataRetention: number = 180;
  selectedSessionDuration: number = 720;

  settings$ = this.store.select(selectMindflickOrganisationSettings);
  settings?: OrganisationSettings;

  destroyed$ = new Subject<boolean>();

  constructor(
    private store: Store<AppState>
  ) { }

  ngOnInit(): void {
    this.store.dispatch(GetValidAccountAdminsForOrg.Request());
    
    this.settings$.pipe(takeUntil(this.destroyed$)).subscribe((settings) => {
      if (settings) {
        this.settings = {
          ...settings,
        };
      }
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.unsubscribe();
  }

  dataRetentionDurationChanged(duration: number) {
    if (this.settings && duration != null && duration != undefined) {
      if (this.settings.dataRetentionDurationDays == duration) return;

      this.settings.dataRetentionDurationDays = duration;

      this.saveSettings();
    }
  }

  sessionDurationChanged(duration: number) {
    if (this.settings && duration != null && duration != undefined) {
      if (this.settings.sessionDurationMins == duration) return;

      this.settings.sessionDurationMins = duration;

      this.saveSettings();
    }
  }


  mfaRequiredChanged(mfaRequired: boolean) {
    if (this.settings) {
      this.settings.mfaRequired = mfaRequired;

      this.saveSettings();
    }
  }

  singleSignOnChanged(singleSignOn: boolean) {
    if (this.settings) {
      this.settings.requiresExternalAuthentication = singleSignOn;

      this.saveSettings();
    }
  }

  saveSettings() {
    if (this.settings)
      this.store.dispatch(
        SetMindflickOrganisationSettings.Request({ settings: this.settings })
      );
  }
}
