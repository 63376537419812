<div class="holding-screen bg-dark text-white d-flex flex-column justify-content-center align-items-center h-100 w-100">
  <div class="container">
    <h2 class="bigish-text mb-2">To access this account you must login using an external single sign on provider such as Microsoft Azure Active Directory</h2>
    <h3 class="account-question-text">
      Please <span class="nav-link d-inline px-1" (click)="logout()">logout</span> and try again
    </h3>
  </div>
</div>
<img class="top-right-pattern"
     src="./assets/svg/nudge-patterns/top-right.svg" />
<div class="top-left-logo">
  <img class="me-4" src="./assets/images/logos/mindflick.svg" />
  <div class="nav-link cursor-pointer" (click)="logout()">Logout</div>
</div>
