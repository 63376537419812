<div (mouseup)="setDragging($event, false)">
  <div class="modal-header justify-content-end">
    <fa-icon class="cursor-pointer fa-lg"
             [icon]="closeIcon"
             (click)="close()"></fa-icon>
  </div>
  <div class="modal-body h-auto">
    <h2>Strengths curve component</h2>
    <h3 class="fw-bold my-15">{{strengthText}}</h3>
    <h3 class="fw-bold my-15">{{strengthSectionText[strengthSectionTextIndex]}}</h3>

    <div class="curve position-relative" (click)="onSvgClick($event)" #curveSvgContainer>
      <div class="handle m-0 bg-primary cursor-pointer" (mousedown)="setDragging($event, true)" (click)="$event.stopPropagation()"><p class="body-copy text-white user-select-none ">< ></p></div>
      <svg id="Layer_1" version="1.1" viewBox="0 0 30 20" xmlns="http://www.w3.org/2000/svg">
        <defs id="defs2766">
          <style id="style2764">
            .cls-2 {
              fill: none;
              stroke: #878787;
              stroke-dasharray: 0 0 .25 .25;
              stroke-miterlimit: 8;
              stroke-width: .25px
            }
          </style>
        </defs>
        <path id="path2780" d="M.012 18.127a10.538 10.538 0 0 0 3.657-1.175c1.355-.733 2.19-1.578 2.728-2.131.392-.404 1.65-1.748 3.1-4.82.823-1.742.52-1.526 1.901-4.908 1.257-3.08 2.188-4.925 3.475-4.996.067-.004.122-.002.156 0m-.075 0c.035-.002.09-.004.157 0 1.29.071 2.222 1.92 3.481 5.005 1.384 3.388 1.08 3.171 1.904 4.916 1.453 3.078 2.714 4.424 3.107 4.828.539.555 1.375 1.401 2.733 2.135a10.559 10.559 0 0 0 3.663 1.177" style="stroke-width:.0993048;stroke-dasharray:none;fill:none;stroke:#7000dd;stroke-miterlimit:10" />
        <text class="user-select-none" xml:space="preserve" style="font-size:.506675px;font-family:sans-serif;-inkscape-font-specification:'sans-serif, Normal';fill:#000;stroke-width:.0633345" x="4.286" y="19.04" id="text2904" transform="scale(1.01335 .98682)"><tspan id="tspan2902" x="4.286" y="19.04" style="stroke-width:.0633345">UNDERDONE</tspan></text>
        <text class="user-select-none" xml:space="preserve" style="font-size:.506675px;font-family:sans-serif;-inkscape-font-specification:'sans-serif, Normal';fill:#000;stroke-width:.0633345" x="13.812" y="19.04" id="text2908" transform="scale(1.01335 .98682)"><tspan id="tspan2906" x="13.812" y="19.04" style="stroke-width:.0633345">SWEETSPOT</tspan></text>
        <text class="user-select-none" xml:space="preserve" style="font-size:.506675px;font-family:sans-serif;-inkscape-font-specification:'sans-serif, Normal';fill:#000;stroke-width:.0633345" x="20.882" y="18.723" id="text2912" transform="scale(1.01335 .98682)"><tspan x="20.882" y="18.723" id="tspan2916" style="stroke-width:.0633345">WRIGGLE </tspan><tspan x="20.882" y="19.356" id="tspan2922" style="stroke-width:.0633345">   ROOM</tspan></text>
        <text class="user-select-none" xml:space="preserve" style="font-size:.506675px;font-family:sans-serif;-inkscape-font-specification:'sans-serif, Normal';fill:#000;stroke-width:.0633345" x="26.122" y="19.04" id="text2926" transform="scale(1.01335 .98682)"><tspan id="tspan2924" x="26.122" y="19.04" style="stroke-width:.0633345">OVERDONE</tspan></text>
        <path class="cls-2" id="line2768" d="M10.938.001v20" #lineOne/>
        <path class="cls-2" id="line2770" d="M19.695.001v20" #lineTwo/>
        <path class="cls-2" id="line2772" d="M25.34.001v20" #linThree/>
      </svg>
    </div>
  </div>
  <div class="modal-bottom-border"></div>
</div>
