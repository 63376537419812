import { Directive, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, TemplateRef, ViewContainerRef, inject } from "@angular/core";
import { RbacService } from "../services/rbac.service";
import { Subject, takeUntil } from "rxjs";

@Directive({
    selector: '[isGranted]'
})
export class IsGrantedDirective implements OnInit, OnChanges, OnDestroy {
    private _rbacService = inject(RbacService);
    private _templateRef = inject(TemplateRef);
    private _viewContainer = inject(ViewContainerRef);
    private _roleOrPermission!: string;
    private _mindflickAccountId?: number;
    private _forAnyAccount: boolean = false;

    @Input()
    set isGranted(roleOrPermission: string) {
        this._roleOrPermission = roleOrPermission;
    }

    @Input()
    set isGrantedForAccount(accountId: number | undefined) {
        this._mindflickAccountId = accountId;
    }

    @Input()
    set isGrantedForAnyAccount(value: boolean) {
        this._forAnyAccount = value;
    }

    private destroyed$ = new Subject<boolean>();

    ngOnInit(): void {
        this.checkPermissions();

        this._rbacService.rolesChanged
            .pipe(takeUntil(this.destroyed$))
            .subscribe(() => {
                this.checkPermissions();
            });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.isGrantedForAccount) {
            this.checkPermissions();
        }
    }

    ngOnDestroy(): void {
        this.destroyed$.next(true);
        this.destroyed$.unsubscribe();
    }

    private checkPermissions() {
        this.clearView();

        if (this._forAnyAccount) {
            if (this._rbacService.isGrantedAnyAccount(this._roleOrPermission))
                this.createView();
            return;
        }

        if (this._mindflickAccountId) {
            if (this._rbacService.isGrantedForAccount(this._roleOrPermission, this._mindflickAccountId)) {
                this.createView();       
            }
            return;
        }

        if (this._rbacService.isGranted(this._roleOrPermission)) {
            this.createView();
        }
    }

    private createView() {
        this._viewContainer.clear();
        this._viewContainer.createEmbeddedView(this._templateRef);
    }

    private clearView() {
        this._viewContainer.clear();
    }
}