<div class="modal-header">
  <h4 class="modal-title">Send Invite</h4>
</div>
<div class="modal-body">
  <p>Invite a new user to the platform</p>
  <div class="row gy-2">
    <div class="form-group col-md-12">
      <label for="email">Email Address</label>
      <input type="email" class="form-control" id="email" />
    </div>
    <div class="form-group col-md-6">
      <label for="firstName">First Name</label>
      <input type="text" class="form-control" id="firstName" />
    </div>
    <div class="form-group col-md-6">
      <label for="lastName">Last Name</label>
      <input type="text" class="form-control" id="lastName" />
    </div>

    <div class="form-group col-md-12">
      <label for="company">Company</label>
      <input type="text" class="form-control" id="company" />
    </div>
  </div>
</div>
<div class="modal-footer">
  <button class="btn btn-outline-secondary" (click)="modalRef.hide()">Cancel</button>
  <button class="btn btn-secondary" (click)="sendInvite()">Send Invite</button>
</div>
