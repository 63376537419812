import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ListItem } from '../../models/list-item.interface';

@Component({
  selector: 'app-item-list',
  templateUrl: './item-list.component.html',
  styleUrls: ['./item-list.component.css'],
})
export class ItemListComponent<T> implements OnInit {
  @Input()
  list: ListItem<T>[] = [];

  @Input()
  newItemStr = 'New Item';

  @Output()
  listItemClicked = new EventEmitter<T>();

  @Output()
  newItemClicked = new EventEmitter();

  selectedId: number = -1;

  constructor() {}

  ngOnInit(): void {}

  onListItemClick(item: ListItem<T>) {
    this.selectedId = item.id;
    this.listItemClicked.emit(item.value);
  }

  onNewItemClick() {
    this.selectedId = -1;
    this.newItemClicked.emit();
  }
}
