import { getLocaleDateFormat } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateAgo',
  pure: true,
})
export class DateAgoPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (value) {
      let utcOffset = 3600;
      const seconds =
        Math.floor((+new Date() - +new Date(value)) / 1000) - utcOffset;
      if (seconds < 29) return 'Just now';

      let timePeriods = ['day', 'hour', 'minute', 'second'];
      let intervals = [86400, 3600, 60, 1];
      let counter;
      for (const i in intervals) {
        counter = Math.floor(seconds / intervals[i]!);

        if (timePeriods[i] == 'day') {
          if (counter > 7) {
            return new Date(value).toLocaleDateString();
          }
        }
        if (counter > 0)
          if (counter === 1) {
            return counter + ' ' + timePeriods[i] + ' ago'; // singular (1 day ago)
          } else {
            return counter + ' ' + timePeriods[i] + 's ago'; // plural (2 days ago)
          }
      }
    }
    return value;
  }
}
