<ng-container>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <defs>
      <clipPath clipPathUnits="userSpaceOnUse" [id]="isFlex ? 'f' : 'c'">
        <ellipse
          [attr.cx]="cx"
          [attr.cy]="cy"
          [attr.rx]="rx"
          [attr.ry]="ry"
          [attr.transform]="clipTransform"
        />
      </clipPath>
    </defs>
    <polygon [attr.points]="highlightTriangle" fill="#F9F9F9" />
    <path
      d="M-.235-1.773a.258.258 0 0 1 .454 0l1.765 3.305a.257.257 0 0 1-.226.377h-3.515a.26.26 0 0 1-.228-.378z"
      [attr.fill]="lampFill"
      [attr.transform]="lampTransform"
    />
    <ellipse
      id="spotlight-clip"
      [attr.cx]="cx"
      [attr.cy]="cy"
      [attr.rx]="rx"
      [attr.ry]="ry"
      [attr.transform]="clipTransform"
      fill="#F9F9F9"
    />
    <path
      d="M26.302 3.417c12.788 0 23.154 10.37 23.154 23.163 0 12.792-10.366 23.162-23.154 23.162S3.147 39.372 3.147 26.58c0-12.793 10.367-23.163 23.155-23.163"
      [attr.fill]="tlCircleFill"
      [attr.clip-path]="isFlex ? 'url(#f)' : 'url(#c)'"
    />
    <path
      d="M72.91 3.611c12.787 0 23.154 10.37 23.154 23.163 0 12.792-10.367 23.162-23.154 23.162-12.788 0-23.155-10.37-23.155-23.162C49.755 13.98 60.122 3.61 72.91 3.61"
      [attr.fill]="trCircleFill"
      [attr.clip-path]="isFlex ? 'url(#f)' : 'url(#c)'"
    />
    <path
      d="M72.91 49.936c12.787 0 23.154 10.37 23.154 23.163 0 12.792-10.367 23.163-23.154 23.163-12.788 0-23.155-10.37-23.155-23.163 0-12.792 10.367-23.163 23.155-23.163"
      [attr.fill]="brCircleFill"
      [attr.clip-path]="isFlex ? 'url(#f)' : 'url(#c)'"
    />
    <path
      d="M26.302 50c12.788 0 23.154 10.37 23.154 23.162 0 12.793-10.366 23.163-23.154 23.163S3.147 85.955 3.147 73.162C3.147 60.37 13.514 50 26.302 50"
      [attr.fill]="blCircleFill"
      [attr.clip-path]="isFlex ? 'url(#f)' : 'url(#c)'"
    />

    <path
      d="M0 0h351"
      fill="none"
      stroke="#dadbda"
      transform="matrix(.25727 0 0 -.25736 4.913 49.92)"
    />
    <path
      d="M0 0v-358"
      fill="none"
      stroke="#dadbda"
      transform="matrix(.25727 0 0 -.25736 49.678 3.633)"
    />

    <ng-container *ngIf="showWords">
      <text
        style="font-size: 2.56916px"
        [attr.font-family]="topTitleFont"
        [attr.fill]="topTitleFill"
        text-anchor="middle"
        transform="translate(50,2.3)"
      >
        <ng-container *ngIf="isFlex">Task-Oriented</ng-container>
        <ng-container *ngIf="!isFlex">Composed</ng-container>
      </text>
      <text
        style="font-size: 2.56916px"
        [attr.fill]="leftTitleFill"
        text-anchor="middle"
        [attr.font-family]="leftTitleFont"
        transform="translate(2.1,50) rotate(-90)"
      >
        <ng-container *ngIf="isFlex">Externally Focused</ng-container>
        <ng-container *ngIf="!isFlex">Measured</ng-container>
      </text>
      <text
        style="font-size: 2.56916px"
        [attr.fill]="rightTitleFill"
        text-anchor="middle"
        [attr.font-family]="rightTitleFont"
        transform="translate(98.1,50) rotate(90)"
      >
        <ng-container *ngIf="isFlex">Internally Focused</ng-container>
        <ng-container *ngIf="!isFlex">Risk Tolerant</ng-container>
      </text>
      <text
        style="font-size: 2.56916px"
        [attr.fill]="bottomTitleFill"
        [attr.font-family]="bottomTitleFont"
        text-anchor="middle"
        transform="translate(50,99.4)"
      >
        <ng-container *ngIf="isFlex">People-Oriented</ng-container>
        <ng-container *ngIf="!isFlex">Risk Aware</ng-container>
      </text>
    </ng-container>

    <!-- These are the FLEX letters -->
    <g *ngIf="isFlex">
      <path
        d="m75.703 18.185-5.136 14.107h2.631l-.99 2.738H66.42l6.126-16.845Z"
        [attr.stroke]="trStroke"
        stroke-width=".257"
        [attr.opacity]="trOpacity"
        [attr.fill]="trFill"
      />
      <path
        d="M35.281 64.82v.21l-4.357 8.591-1.579 8.044h-3.094l1.242-5.727h-.21l-2.926 5.727H21.41v-.21l4.651-9.034 1.516-7.602h2.905l-1.2 5.623h.21l2.884-5.622Z"
        [attr.opacity]="blOpacity"
        [attr.stroke]="blStroke"
        stroke-width=".257"
        [attr.fill]="blFill"
      />
      <path
        d="m78.956 64.305-1.01 2.737H75.42l-1.516 4.212h2.21l-1.01 2.737h-2.21l-1.6 4.422h2.526l-.99 2.737h-5.683l6.126-16.845Z"
        [attr.stroke]="brStroke"
        stroke-width=".257"
        [attr.opacity]="brOpacity"
        [attr.fill]="brFill"
      />
      <path
        d="M26.516 18.185H32.2l-1.01 2.737h-2.527l-1.515 4.211h2.104l-1.01 2.738h-2.105l-2.589 7.159h-3.157Z"
        [attr.stroke]="tlStroke"
        stroke-width=".257"
        [attr.opacity]="tlOpacity"
        [attr.fill]="tlFill"
      />
    </g>

    <!-- These are the COPE letters -->
    <g *ngIf="!isFlex">
      <path
        d="M30.244 17.498c2.58 0 3.488 2.27 2.325 5.513-.21.594-.296 1.04-.296 1.379h-3.212c0-.276.042-.552.19-.955l.866-2.417c.19-.53.043-.975-.507-.975-.528 0-.887.382-1.14 1.08l-3.615 9.967c-.254.7 0 1.145.55 1.145.464 0 .76-.34.95-.848l.698-1.908c.148-.424.296-.743.528-.955h3.065v.212c-.275.319-.423.785-.57 1.167-1.205 3.308-2.938 4.983-5.22 4.983-2.22 0-4.08-2.248-1.819-8.376l.55-1.484c1.733-4.686 3.91-7.528 6.657-7.528Z"
        [attr.stroke]="tlStroke"
        stroke-width=".257"
        [attr.opacity]="tlOpacity"
        [attr.fill]="tlFill"
      />
      <path
        d="M71.072 31.09c-.254.7 0 1.145.55 1.145.507 0 .866-.382 1.12-1.081l3.614-9.966c.253-.7 0-1.145-.55-1.145-.507 0-.866.382-1.12 1.08Zm-1.268-6.064c1.733-4.686 3.91-7.528 6.552-7.528 2.22 0 4.057 2.333 1.817 8.376l-.55 1.484c-1.733 4.686-3.91 7.528-6.55 7.528-2.22 0-4.08-2.248-1.819-8.376Z"
        [attr.stroke]="trStroke"
        stroke-width=".257"
        [attr.opacity]="trOpacity"
        [attr.fill]="trFill"
      />
      <path
        d="m79.01 64.154-1.015 2.757h-2.537l-1.522 4.24h2.22l-1.016 2.757h-2.218l-1.607 4.452h2.536l-.993 2.757h-5.706l6.15-16.963Z"
        [attr.stroke]="brStroke"
        stroke-width=".257"
        [attr.opacity]="brOpacity"
        [attr.fill]="brFill"
      />
      <path
        d="M27.717 73.154h.17c.971 0 1.563-.425 1.922-1.421l.91-2.502c.421-1.188.147-2.014-.699-2.014h-.148Zm2.747-8.482c2.62 0 4.185 2.46 2.875 6.043l-.148.403c-1.163 3.138-3.297 4.58-6.383 4.58l-2.156 5.937h-3.17l6.15-16.963Z"
        [attr.opacity]="blOpacity"
        [attr.stroke]="blStroke"
        stroke-width=".257"
        [attr.fill]="blFill"
      />
    </g>
  </svg>
</ng-container>
<ng-container *ngIf="showSliders && isFlex">
  <input
    type="range"
    [value]="scores[0]"
    min="0"
    max="5"
    (input)="setScore(0, $event.target)"
    step="0.1"
  />F: {{ scores[0] }}<br />
  <input
    type="range"
    [value]="scores[1]"
    min="0"
    max="5"
    (input)="setScore(1, $event.target)"
    step="0.1"
  />L: {{ scores[1] }}<br />
  <input
    type="range"
    [value]="scores[2]"
    min="0"
    max="5"
    (input)="setScore(2, $event.target)"
    step="0.1"
  />E: {{ scores[2] }}<br />
  <input
    type="range"
    [value]="scores[3]"
    min="0"
    max="5"
    (input)="setScore(3, $event.target)"
    step="0.1"
  />X: {{ scores[3] }}<br />
  High count: {{ highCount }}
</ng-container>
<ng-container *ngIf="showSliders && !isFlex">
  <input
    type="range"
    [value]="scores[0]"
    min="0"
    max="5"
    (input)="setScore(0, $event.target)"
    step="0.1"
  />C: {{ scores[0] }}<br />
  <input
    type="range"
    [value]="scores[1]"
    min="0"
    max="5"
    (input)="setScore(1, $event.target)"
    step="0.1"
  />O: {{ scores[1] }}<br />
  <input
    type="range"
    [value]="scores[3]"
    min="0"
    max="5"
    (input)="setScore(3, $event.target)"
    step="0.1"
  />P: {{ scores[3] }}<br />
  <input
    type="range"
    [value]="scores[2]"
    min="0"
    max="5"
    (input)="setScore(2, $event.target)"
    step="0.1"
  />E: {{ scores[2] }}<br />
  High count: {{ highCount }}
</ng-container>
<ng-container *ngIf="showKey">
  <h3>{{ isFlex ? "Behavioural Style" : "Mindset" }} key</h3>
  <div class="row mt-2">
    <div class="col d-flex align-items-center">
      <div class="key me-2" [ngClass]="isFlex ? 'forceful' : 'contained'"></div>
      <div class="small-text flex-fill">
        {{ isFlex ? "Forceful" : "Contained" }}
      </div>
    </div>
    <div class="col d-flex align-items-center">
      <div class="key me-2" [ngClass]="isFlex ? 'logical' : 'optimistic'"></div>
      <div class="small-text flex-fill">
        {{ isFlex ? "Logical" : "Optimistic" }}
      </div>
    </div>
  </div>
  <div class="row mt-2">
    <div class="col d-flex align-items-center">
      <div class="key me-2" [ngClass]="isFlex ? 'expressive' : 'prudent'"></div>
      <div class="small-text flex-fill">
        {{ isFlex ? "Expressive" : "Prudent" }}
      </div>
    </div>
    <div class="col d-flex align-items-center">
      <div class="key me-2" [ngClass]="isFlex ? 'empathic' : 'engaged'"></div>
      <div class="small-text flex-fill">
        {{ isFlex ? "Empathic" : "Engaged" }}
      </div>
    </div>
  </div>
</ng-container>
