<div class="d-flex notifications-container">
  <div class="shadow-container flex-fill"></div>
  <div class="notification-page bg-white">
    <div class="d-flex pt-5 pb-4 align-items-center header">
      <app-btn-action class="notification-close" [icon]="close" [iconDimensions]="35" (click)="closeNotifications()"></app-btn-action>
      <div class="d-flex flex-column">
        <h2>Notifications</h2>
      </div>
    </div>
    <div class="notification-content-container pb-3">
      <div *ngFor="let notification of notifications; index as i" class="d-flex notification-item-container">
        <div class="d-flex my-2 align-items-center notification-item" [class.notification-seen]="notification.acknowledged || (notification.seenDate && notification.seenDate < nowMinusTwelve)">
          <div #imageContainer class="col-3 me-2" [style.height.px]="imageContainer.offsetWidth">
            <img *ngIf="
                notification.type == notificationType.ConnectionRequest ||
                notification.type == notificationType.ConnectionAccepted
              "
                 class="member-picture h-100"
                 [src]="notification.sendingUser?.strId && notification.sendingUser?.photoLastModified ? publicPhotoBasePath + notification.sendingUser?.strId + '_' + notification.sendingUser?.photoLastModified + '.jpg' : './assets/svg/profile-placeholder.svg'" />
            <img *ngIf="notification.type != notificationType.ConnectionRequest && notification.type != notificationType.ConnectionAccepted && notification.type != notificationType.StrengthSpotted" class="w-100 h-100" src="./assets/svg/profile-placeholder.svg" />
            <div *ngIf="notification.type == notificationType.StrengthSpotted" class="p-0">
              <img class="h-100 w-100" [src]="notification.sendingUser?.strId && notification.sendingUser?.photoLastModified ? publicPhotoBasePath + notification.sendingUser?.strId + '_' + notification.sendingUser?.photoLastModified + '.jpg' : './assets/svg/profile-placeholder.svg'" />
              <img class="other-picture " src="./assets/svg/notifications/strengths.svg" />
            </div>

          </div>
          <div class="flex-fill d-flex flex-column justify-content-between notification-text h-100">
            <p *ngIf="notification.mindflickAccountId == currentAccountId || notification.acknowledged"
               class="body-copy m-0">
              {{ notification.description }}
            </p>
            <p *ngIf="notification.mindflickAccountId != currentAccountId && notification.type == notificationType.ConnectionRequest && !notification.acknowledged"
               class="body-copy m-0">
              This connection request is from {{notification.sendingUser?.firstName}} {{notification.sendingUser?.lastName}} in the {{mindflickAccountTeamNames[notification.mindflickAccountId]}} account. Do you want to switch to {{mindflickAccountTeamNames[notification.mindflickAccountId]}} now?
            </p>
            <ng-container *ngIf="notification.type == notificationType.ConnectionRequest || notification.type == notificationType.ConnectionAccepted">
              <app-btn-tertiary *ngIf="notification.type == notificationType.ConnectionRequest && notification.sendingUser?.connectionStatus !== ConnectionStatus.Connected && !notification.acknowledged && notification.mindflickAccountId == currentAccountId"
                                [contentRightAligned]="false"
                                [icon]="tick"
                                class="body-copy"
                                (click)="acceptRequest(notification)">
                Accept Request
              </app-btn-tertiary>
              <app-btn-tertiary *ngIf="notification.type == notificationType.ConnectionRequest && notification.sendingUser?.connectionStatus !== ConnectionStatus.Connected && !notification.acknowledged && notification.mindflickAccountId != currentAccountId"
                                [contentRightAligned]="false"
                                [icon]="right"
                                class="body-copy"
                                (click)="switchAccounts(notification.mindflickAccountId)">
                Switch accounts
              </app-btn-tertiary>
              <div *ngIf="notification.type == notificationType.ConnectionRequest && notification.sendingUser?.connectionStatus === ConnectionStatus.Connected" class="small-text text-grey">Connected</div>
            </ng-container>


            <app-btn-tertiary *ngIf="notification.type == notificationType.TeamRequest"
                              [contentRightAligned]="false"
                              class="body-copy"
                              (click)="acceptRequest(notification)">
              Accept Team request
            </app-btn-tertiary>
            <app-btn-tertiary *ngIf="notification.type == notificationType.StrengthSpotted"
                              [contentRightAligned]="false"
                              class="body-copy"
                              (click)="viewSpottedStrength(notification)">
              View Spotted Strength
            </app-btn-tertiary>
            <app-btn-tertiary *ngIf="notification.type == notificationType.ConnectionAccepted"
                              [contentRightAligned]="false"
                              class="body-copy"
                              (click)="viewProfile(notification)">
              View Profile
            </app-btn-tertiary>
            <div class="d-flex justify-content-between small-text">
              <p class="m-0">
                {{ notification.notificationDate | dateAgo }}
              </p>
              <app-btn-tertiary (click)="dismissNotification(notification)">Dismiss</app-btn-tertiary>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>

</div>
