import {
  AfterViewInit,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { faAddSVG, faSearchSVG } from '../../../../icons';

@Component({
  selector: 'app-autocomplete-input',
  templateUrl: './autocomplete-input.component.html',
  styleUrls: ['./autocomplete-input.component.scss'],
})
export class AutocompleteInputComponent
  implements OnInit, OnDestroy, OnChanges {
  @HostListener('document:click', ['$event'])
  clickout(event: MouseEvent) {
    event.stopPropagation();

    if (event === undefined || event.target === null) return;

    if (this.isClickIn(event.target, 0)) return;

    this.isShowingResults = false;
  }

  @Input() placeholder = '';
  @Input() options: string[] = [];
  @Input() newOptionPlaceholder: string = '';
  @Output() termChanged = new EventEmitter<string>();

  destroyed$ = new Subject<boolean>();

  searchFilter = new UntypedFormControl('');
  filteredOptions: string[] = [];
  isShowingResults = false;

  search = faSearchSVG;
  plus = faAddSVG;

  constructor() {}

  ngOnInit() {
    this.filteredOptions = this.options.slice();

    this.searchFilter.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((searchTerm) => {
        if (typeof searchTerm != 'string') {
          searchTerm = searchTerm.value;
        }

        this.termChanged.emit(this.searchFilter.value);
        this.filterOptions(searchTerm);
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.options.currentValue != changes.options.previousValue) {
      this.filteredOptions = changes.options.currentValue.slice();
    }
  }

  showResults() {
    this.isShowingResults = true;
  }

  filterOptions(searchTerm: string) {
    this.filteredOptions = this.options
      .slice()
      .filter((x) => x.toLowerCase().includes(searchTerm.toLowerCase()));
  }

  selectOption(option: string) {
    this.searchFilter.setValue(option);
    this.isShowingResults = false;
    this.termChanged.emit(this.searchFilter.value);
  }

  private isClickIn(target: any, iteration: number): boolean {
    if (iteration > 5) return false;

    // In safari the initial click is registered on the component itself rather than any of it's children so need this check to prevent it instantly closing
    if (
      target.localName !== undefined &&
      target.localName === `app-autocomplete-input-${this.placeholder}`
    )
      return true;

    if (
      target.id === `test-search-${this.placeholder}` ||
      target.id === `test-adding-${this.placeholder}` ||
      target.id === `test-option-${this.placeholder}`
    )
      return true;

    if (target.parentNode === null || target.parentNode === undefined)
      return false;

    return this.isClickIn(target.parentNode, iteration + 1);
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.unsubscribe();
  }
}
