import Step from 'shepherd.js/src/types/step';


export const addProgressToStep = (step: Step) => {
  const currentStepElement = step.getElement();
  const content = currentStepElement?.querySelector('.shepherd-content');
  const progress = document.createElement('span');
  progress.className = 'shepherd-progress';
  content?.appendChild(progress);
};

export const addButtonSpan = (step: Step) => {
  const currentStepElement = step.getElement();
  const content = currentStepElement?.querySelector('.shepherd-footer');
  const span = document.createElement('span');
  span.className = 'd-flex flex-grow-1';
  content?.insertBefore(span, currentStepElement!.querySelector(".btn-purple"));
};
