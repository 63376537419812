import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';

@Component({
  selector: 'app-toggle-box',
  templateUrl: './toggle-box.component.html',
  styleUrls: ['./toggle-box.component.scss'],
  animations: [
    trigger('onOff', [
      state(
        'on',
        style({
          left: '17.5px',
        })
      ),
      state(
        'off',
        style({
          left: '3.5px',
        })
      ),
      transition('on => off', [animate('200ms 0ms')]),
      transition('off => on', [animate('200ms 0ms')]),
    ]),
  ],
})
export class ToggleBoxComponent implements OnInit {
  @Input()
  description: string = '';

  @Input()
  isOn = false;

  @Input()
  disabled = false;

  @Output()
  toggleChanged = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit(): void {}

  toggle() {
    if (!this.disabled)
      this.toggleChanged.emit(!this.isOn);
  }
}
