<div class="modal-header justify-content-end">
  <fa-icon
    class="cursor-pointer fa-lg"
    [icon]="closeIcon"
    (click)="close()"
  ></fa-icon>
</div>
<div class="modal-body">
  <h2 class="mb-4">Seats usage breakdown</h2>
  <h3 class="mb-2">
    You have currently used <strong>{{ usedSeats }}</strong> out of
    <strong>{{ totalSeats }}</strong> seats. Your seats are being used as
    follows:
  </h3>
  <ul class="body-copy mb-4">
    <li>Active Users - {{ activeUsers }}</li>
    <li>Invited Users - {{ invitedUsers }}</li>
    <li>In Progress Users - {{ usersInProgress }}</li>
    <li>Locked Users - {{ lockedUsers }}</li>
    <li>Unused Teammate invites - {{ unusedTeammateInvites }}</li>
    <li *ngIf="usersWithoutAnInvite">
      Added users that haven't been sent an invite - {{ usersWithoutAnInvite }}
    </li>
  </ul>
  <button class="btn btn-purple w-100" (click)="close()">Okay</button>
</div>
<div class="modal-bottom-border"></div>
