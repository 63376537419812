<div class="d-flex reflections-container">
  <div class="shadow-container flex-fill"></div>
  <div class="d-flex col-4 h-100 nudge-page bg-white">
    <div class="d-flex pt-5 pb-3 align-items-end header">
      <div
        class="bg-white rounded"
        [class.invisible]="isPreviousNudge && !showNudgeDetails"
      >
        <app-btn-action
          *ngIf="!showNudgeDetails"
          class="nudge-close"
          [icon]="close"
          [iconDimensions]="35"
          [secondary]="true"
          (click)="closeReflections()"
        ></app-btn-action>
        <app-btn-action
          *ngIf="showNudgeDetails"
          class="nudge-close"
          [icon]="back"
          [iconDimensions]="35"
          [secondary]="true"
          (click)="setShowNudgeDetails(false)"
        ></app-btn-action>
      </div>
      <div class="d-flex flex-column">
        <h2>
          {{
            isPreviousNudge
              ? "How was last weeks nudge?"
              : "How was this weeks nudge?"
          }}
        </h2>
      </div>
    </div>
    <div class="h-100 nudge-content-container pb-3">
      <div *ngIf="nudge" class="d-flex flex-column h-100 nudge-item-container">
        <ng-container *ngIf="showNudgeDetails">
          <h3 class="mb-35">{{ nudge.title }}</h3>
          <div
            class="d-flex flex-column image justify-content-end p-15 mb-35"
            [style.background-image]="'url(' + backgroundUrl + ')'"
          ></div>
          <p class="m-0 mb-2 body-copy">{{ nudge.paragraph1 }}</p>
          <p class="m-0 body-copy">{{ nudge.paragraph2 }}</p>
        </ng-container>
        <ng-container *ngIf="!showNudgeDetails">
          <div
            class="d-flex flex-column image justify-content-end p-15 mb-35 cursor-pointer"
            [style.background-image]="'url(' + backgroundUrl + ')'"
            (click)="setShowNudgeDetails(true)"
          >
            <p class="text-white-50 body-copy m-0">
              {{
                (isPreviousNudge ? "Last week's nudge" : "This week''s nudge")
                  | uppercase
              }}
            </p>
            <h3 class="text-white">{{ nudge.title }}</h3>
            <p class="m-0 text-white body-copy nudge-paragraph">
              {{ nudge.paragraph1 }}
            </p>
            <p class="m-0 align-self-end small-text text-white">
              Know more<fa-icon [icon]="next"></fa-icon>
            </p>
          </div>
          <ng-container *ngIf="isPreviousNudge">
            <ng-container>
              <div
                class="d-flex align-items-baseline nudge-reflection py-15 ps-4 pe-2 cursor-pointer rounded"
                [class.bg-primary]="
                  primaryReflection.value == reflectionOptions.Useful
                "
                [class.text-white]="
                  primaryReflection.value == reflectionOptions.Useful
                "
                (click)="setPrimaryReflection(reflectionOptions.Useful)"
              >
                <div class="reflection-icon-container">
                  <fa-icon [icon]="useful" class="fa-lg"></fa-icon>
                </div>
                <p class="m-0">Useful</p>
              </div>

              <div
                class="d-flex align-items-baseline nudge-reflection py-15 ps-4 pe-2 cursor-pointer rounded"
                [class.bg-primary]="
                  primaryReflection.value == reflectionOptions.NotUseful
                "
                [class.text-white]="
                  primaryReflection.value == reflectionOptions.NotUseful
                "
                (click)="setPrimaryReflection(reflectionOptions.NotUseful)"
              >
                <div class="reflection-icon-container">
                  <fa-icon [icon]="notUseful" class="fa-lg"></fa-icon>
                </div>
                <p class="m-0">Not useful</p>
              </div>

              <div
                class="d-flex align-items-baseline nudge-reflection py-15 ps-4 pe-2 cursor-pointer rounded"
                [class.bg-primary]="
                  primaryReflection.value == reflectionOptions.DidntDoIt
                "
                [class.text-white]="
                  primaryReflection.value == reflectionOptions.DidntDoIt
                "
                (click)="setPrimaryReflection(reflectionOptions.DidntDoIt)"
              >
                <div class="reflection-icon-container">
                  <fa-icon [icon]="didnt" class="fa-lg"></fa-icon>
                </div>
                <p class="m-0">Didn't do it</p>
              </div>
            </ng-container>

            <div
              class="d-flex flex-column w-100 mb-35 mt-auto justify-content-end"
            >
              <p
                *ngIf="!primaryReflection.valid && showError"
                class="m-0 mb-2 form-error"
              >
                Selection required before continuing
              </p>
              <button
                class="btn btn-primary w-100"
                (click)="submitPreviousNudgeReflection()"
              >
                Finish
              </button>
            </div>
          </ng-container>
          <ng-container *ngIf="!isPreviousNudge">
            <ng-container *ngIf="!showAdditionalReflections">
              <div
                class="d-flex align-items-baseline nudge-reflection py-15 ps-4 pe-2 mb-2 cursor-pointer rounded"
                [class.bg-primary]="
                  primaryReflection.value == reflectionOptions.Useful
                "
                [class.text-white]="
                  primaryReflection.value == reflectionOptions.Useful
                "
                (click)="setPrimaryReflection(reflectionOptions.Useful)"
              >
                <div class="reflection-icon-container">
                  <fa-icon [icon]="useful" class="fa-lg"></fa-icon>
                </div>
                <p class="m-0">Useful</p>
              </div>

              <div
                class="d-flex align-items-baseline nudge-reflection py-15 ps-4 pe-2 mb-2 cursor-pointer rounded"
                [class.bg-primary]="
                  primaryReflection.value == reflectionOptions.NotUseful
                "
                [class.text-white]="
                  primaryReflection.value == reflectionOptions.NotUseful
                "
                (click)="setPrimaryReflection(reflectionOptions.NotUseful)"
              >
                <div class="reflection-icon-container">
                  <fa-icon [icon]="notUseful" class="fa-lg"></fa-icon>
                </div>
                <p class="m-0">Not useful</p>
              </div>

              <div
                class="d-flex align-items-baseline nudge-reflection py-15 ps-4 pe-2 mb-auto cursor-pointer rounded"
                [class.bg-primary]="
                  primaryReflection.value == reflectionOptions.DidntDoIt
                "
                [class.text-white]="
                  primaryReflection.value == reflectionOptions.DidntDoIt
                "
                (click)="setPrimaryReflection(reflectionOptions.DidntDoIt)"
              >
                <div class="reflection-icon-container">
                  <fa-icon [icon]="didnt" class="fa-lg"></fa-icon>
                </div>
                <p class="m-0">Didn't do it</p>
              </div>

              <div
                class="d-flex flex-column flex-grow-1 mb-35 justify-content-end"
              >
                <p
                  *ngIf="!primaryReflection.valid && showError"
                  class="m-0 text-error"
                >
                  Selection required before continuing
                </p>
                <button
                  class="btn btn-primary w-100 mt-2"
                  (click)="showAdditionalReflection()"
                >
                  Continue
                </button>
              </div>
            </ng-container>

            <ng-container *ngIf="showAdditionalReflections">
              <div class="d-flex flex-grow-1 additional-reflection-container">
                <div class="row gx-2 gy-2">
                  <div
                    class="col align-items-center cursor-pointer"
                    (click)="setAdditionalReflection(0)"
                  >
                    <div
                      class="d-flex flex-column align-items-center additional-reflection h-100 justify-content-center w-100"
                      [class.bg-primary]="selectedAdditionalOption.includes(0)"
                      [class.text-white]="selectedAdditionalOption.includes(0)"
                    >
                      <img [src]="additionalOptions[0].imgUrl" [width]="80" />

                      <p class="mb-0 mt-1">{{ additionalOptions[0].text }}</p>
                    </div>
                  </div>
                  <div
                    class="col-6 align-items-center cursor-pointer"
                    (click)="setAdditionalReflection(1)"
                  >
                    <div
                      class="d-flex flex-column align-items-center additional-reflection h-100 justify-content-center w-100"
                      [class.bg-primary]="selectedAdditionalOption.includes(1)"
                      [class.text-white]="selectedAdditionalOption.includes(1)"
                    >
                      <img [src]="additionalOptions[1].imgUrl" [width]="80" />
                      <p class="mb-0 mt-1">{{ additionalOptions[1].text }}</p>
                    </div>
                  </div>
                  <div
                    class="col-6 align-items-center cursor-pointer"
                    (click)="setAdditionalReflection(2)"
                  >
                    <div
                      class="d-flex flex-column align-items-center additional-reflection h-100 justify-content-center w-100"
                      [class.bg-primary]="selectedAdditionalOption.includes(2)"
                      [class.text-white]="selectedAdditionalOption.includes(2)"
                    >
                      <img [src]="additionalOptions[2].imgUrl" [width]="80" />
                      <p class="mb-0 mt-1">{{ additionalOptions[2].text }}</p>
                    </div>
                  </div>
                  <div
                    class="col-6 align-items-center cursor-pointer"
                    (click)="setAdditionalReflection(3)"
                  >
                    <div
                      class="d-flex flex-column align-items-center additional-reflection h-100 justify-content-center w-100"
                      [class.bg-primary]="selectedAdditionalOption.includes(3)"
                      [class.text-white]="selectedAdditionalOption.includes(3)"
                    >
                      <img
                        [src]="
                          './assets/svg/dashboard/reflect_send_me_again.svg'
                        "
                        [width]="80"
                      />
                      <p class="mb-0 mt-1">Send me again</p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="d-flex flex-column w-100 my-35 justify-content-end">
                <p
                  *ngIf="!additionalReflection.valid && showError"
                  class="m-0 text-error"
                >
                  Please select an option
                </p>
                <button
                  class="btn btn-primary w-100"
                  (click)="submitCurrentNudgeReflection()"
                >
                  Finish
                </button>
              </div>
            </ng-container>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
</div>
