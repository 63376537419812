import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../+state/app.state';
import { SetSubMenuLargePopout } from '../../../+state/layout/actions/layout.actions';
import { SubMenuLargePopoutEnum } from '../../../+state/layout/reducers/layout.reducers';

@Component({
  selector: 'app-help-center-footer',
  templateUrl: './help-center-footer.component.html',
  styleUrls: ['./help-center-footer.component.scss'],
})
export class HelpCenterFooterComponent implements OnInit {
  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {}

  openTermsAndConditions() {
    this.store.dispatch(
      SetSubMenuLargePopout({
        subMenuLargePopout: SubMenuLargePopoutEnum.Terms,
      })
    );
  }

  openPrivacyPolicy() {
    this.store.dispatch(
      SetSubMenuLargePopout({
        subMenuLargePopout: SubMenuLargePopoutEnum.Privacy,
      })
    );
  }
}
