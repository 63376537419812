<h2>Mindflick Champion Charter</h2>
<h3 class="mt-4">This charter applies to all Mindflick Champions.</h3>
<p class="body-copy mt-2">
  Please read carefully and make sure you comply with its principles. if you do
  not agree with these principles, you must stop using the Mindflick Platform as
  a Mindflick Champion.
</p>
<p class="body-copy mt-2 border-bottom-grey pb-4">
  Unless defined in this Charter, all capitalised terms are defined in the
  <a href="https://app.mindflick.co/#/public/glossary" target="_blank"
    >Glossary</a
  >
  which should be read alongside this Charter.
</p>
<p class="body-copy mt-4"><strong>Who:</strong></p>
<p class="body-copy mt-2">
  ‘You’ are a Mindflick Champion. If you no longer meet the criteria to be a
  Mindflick Champion and are no longer a Mindflick Champion you must stop
  practising or holding yourself out as one.
</p>
<p class="body-copy mt-4"><strong>What:</strong></p>
<p class="body-copy mt-2">
  The Mindflick Platform to which You are given access and which You may use
  pursuant to a Services Agreement.
</p>
<p class="body-copy mt-4"><strong>Commitments:</strong></p>
<p class="body-copy mt-2">By practising as a Mindflick Champion, You commit:</p>
<ol class="body-copy mt-2">
  <li>
    <p>
      to use the Mindflick Platform for the purpose for which it was designed.
      Specifically, You shall use the Mindflick Platform and data obtained
      through it to:
    </p>
    <ol type="a">
      <li>
        <p>
          help individuals in your organisation learn and develop, and shall not
          use it to their detriment; and
        </p>
      </li>
      <li>
        <p>champion:</p>
        <ol type="i">
          <li>
            <p>
              <em>development of adaptability</em>, by using it as a tool for
              personal and/or team development, and <strong>never</strong> for
              selection of any kind;
            </p>
          </li>
          <li>
            <p>
              the importance of <em>diverse thinking</em>, and
              <strong>not</strong> that there is a best profile or preference;
            </p>
          </li>
          <li>
            <p>
              a <em>strengths-based</em> approach to personal and team growth;
              and
            </p>
          </li>
          <li>
            <p>
              Mindflick Users using the Mindflick Platform to
              <em>enhance the quality of their relationships</em>
            </p>
          </li>
        </ol>
      </li>
      <li>
        <p>
          treat Spotlight Profiles and personal data of Mindflick Users to which
          You have access responsibly and take all necessary steps to protect
          such Spotlight Profiles and personal data including when printing out
          Spotlight Profiles
        </p>
      </li>
    </ol>
  </li>
  <li>
    <p>to honour the principles in the Client Privacy Guidance</p>
  </li>
  <li>
    <p>not to:</p>
    <ol type="a">
      <li>
        <p>
          share a Mindflick User’s Spotlight Profile or personal data with
          anyone other than the Mindflick User him- or herself unless he or she
          gives You explicit written permission to do so, with the exception
          that You may share a Team Map;
        </p>
      </li>
      <li>
        <p>
          give access to anyone (intentionally or not) a Mindflick User’s
          Spotlight Profile or personal data to which You have access including
          printed-out Spotlight Profiles when You print such profiles out;
        </p>
      </li>
      <li>
        <p>
          invite to the Mindflick Platform or using it, with individuals under
          the age of 18 years; or
        </p>
      </li>
      <li>
        <p>
          sharing your account details (such as password and login details) with
          anyone.
        </p>
      </li>
    </ol>
  </li>
</ol>
<p class="body-copy mt-2">
  We illustrate the principles above in the following table:
</p>
<table class="table table-bordered body-copy">
  <thead>
    <tr>
      <th scope="col">Use the Mindflick Platform to:</th>
      <th scope="col">Do NOT use the Mindflick Platform to:</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>Understand preferences</td>
      <td>Infer capability</td>
    </tr>
    <tr>
      <td>Support individual and team development</td>
      <td>
        Promote, recruit, select or deselect staff in organisational structuring
      </td>
    </tr>
    <tr>
      <td>Focus on adaptability and promote diverse thinking</td>
      <td>Type others and make negative judgments of others</td>
    </tr>
    <tr>
      <td>
        Be strengths-focused in development activities and with positive intent
      </td>
      <td>
        Be weakness-focused in development activities and with critical intent
      </td>
    </tr>
    <tr>
      <td>Encourage permission-based sharing</td>
      <td>Share others’ Spotlight Profiles without permission</td>
    </tr>
    <tr>
      <td>Promote connection between individuals</td>
      <td>Label differences and create disconnection</td>
    </tr>
    <tr>
      <td>Provide Spotlight Questionnaire to adults, 18 years and over</td>
      <td>
        Provide Spotlight Questionnaires invitation to individuals under 18
        years
      </td>
    </tr>
  </tbody>
</table>
<p class="body-copy mt-4"><strong>Changes to the Charter:</strong></p>
<p class="body-copy mt-2">
  We may update the terms of this Charter at any time on notice to You in
  accordance with this paragraph. We would email such notice and the email would
  be deemed received and served 24 hours after it was sent. Your continued
  practice as a Mindflick Champion following the deemed receipt and service of
  the notice shall constitute Your acceptance to the terms of this Charter, as
  varied. If you do not wish to accept the terms of the Charter (as varied) You
  must immediately stop practising as a Mindflick Champion on the deemed receipt
  and service of the notice.
</p>
<p class="body-copy mt-4">
  <strong>Consequences to your breach of the Charter:</strong>
</p>
<p class="body-copy mt-2">
  Although we do not have any obligation to monitor Your use of the Mindflick
  Platform, if we become aware that You have breached this Charter, we may,
  without prejudice to any other rights or remedies we may legally have,
  immediately suspend Your access to the Mindflick Platform, inform Your
  organisation and remove Your accreditation.
</p>
