import { createSelector } from '@ngrx/store';
import { SelectValue, User, UserManagementUser } from 'src/app/shared/models';
import { PlatformStatusEnum } from 'src/app/shared/models/enums/platform-status.enum';
import { adminStateKey, AppState } from '../app.state';
import { userManagementUserAdapter } from './admin.reducer';
import { Dictionary } from '@ngrx/entity';
import { PlatformInviteStatusEnum } from 'src/app/shared/models/enums/platform-invite-status.enum';

export const selectAdminState = (state: AppState) => state[adminStateKey];

export const selectEmailTemplates = (state: AppState) =>
  state[adminStateKey].emailTemplates;

export const selectSendingEmails = (state: AppState) =>
  state[adminStateKey].sendingEmails;
export const selectSendingEmailsSuccess = (state: AppState) =>
  state[adminStateKey].sendingEmailsSuccess;
export const selectSendingEmailsError = (state: AppState) =>
  state[adminStateKey].sendingEmailsError;

export const selectUserManagementUserState = (state: AppState) =>
  state[adminStateKey].userManagementUsers;

const {
  selectIds: userManagementUserIds,
  selectEntities: userManagementUserEntities,
  selectAll: userManagementUsers,
  selectTotal: totalUserManagementUsers,
} = userManagementUserAdapter.getSelectors(selectUserManagementUserState);

export const selectUserManagementUserEntities = userManagementUserEntities;
export const selectUserManagementUsers = userManagementUsers;
export const selectUserManagementUserCount = totalUserManagementUsers;

export const selectUserManagementUsersLoading = (state: AppState) =>
  state[adminStateKey].userManagementUsersLoading;
export const selectUserManagementUsersError = (state: AppState) =>
  state[adminStateKey].userManagementUsersError;

export const selectUserManagementPage = (state: AppState) =>
  state[adminStateKey].userManagementPage;
export const selectUserManagementTake = (state: AppState) =>
  state[adminStateKey].userManagementTake;
export const selectUserStatusFilter = (state: AppState) =>
  state[adminStateKey].userStatusFilter;
export const selectUserReferrerFilter = (state: AppState) =>
  state[adminStateKey].userReferrerFilter;
export const selectUserNameFilter = (state: AppState) =>
  state[adminStateKey].userNameFilter;
export const selectAddedDateStartFilter = (state: AppState) =>
  state[adminStateKey].userAddedDateStartDate;
export const selectAddedDateEndFilter = (state: AppState) =>
  state[adminStateKey].userAddedDateEndDate;

export const selectReferrersToShowInFilterDropdown = createSelector(
  selectUserManagementUsers,
  (users: UserManagementUser[]) => {
    const referrers = [
      ...users
        .filter((user) => Boolean(user.referredBy))
        .map(
          (user) =>
            ({
              value: user.referredBy?.ak,
              description:
                user.referredBy?.firstName + ' ' + user.referredBy?.lastName,
            } as SelectValue)
        )
        .sort((a: SelectValue, b: SelectValue) =>
          a.description.localeCompare(b.description)
        ),
    ];

    const distinctReferrers: SelectValue[] = [];

    referrers.forEach((ref) => {
      if (!distinctReferrers.some((x) => x.value == ref.value))
        distinctReferrers.push(ref);
    });

    return distinctReferrers;
  }
);

export const selectAllUsersForFilter = createSelector(
  selectUserManagementUsers,
  selectUserStatusFilter,
  selectUserReferrerFilter,
  selectUserNameFilter,
  selectAddedDateStartFilter,
  selectAddedDateEndFilter,
  (
    users: UserManagementUser[],
    statusFilter?: PlatformInviteStatusEnum,
    referrerFilter?: number,
    nameFilter?: string,
    startDateFilter?: Date,
    endDateFilter?: Date
  ) => {
    return users.filter(
      (x) =>
        (statusFilter ? x.platformStatus === statusFilter : true) &&
        referredByFilter(referrerFilter, x.referredByAk) &&
        userNameFilter(x.firstName + ' ' + x.lastName, nameFilter) &&
        addedDateFilter(x.createdDate, startDateFilter, endDateFilter)
    );
  }
);

export const selectUsersForFilterCount = createSelector(
  selectAllUsersForFilter,
  (users: UserManagementUser[]) => {
    return users.length;
  }
);

export const selectUserManagementUsersToDisplay = createSelector(
  selectUserManagementUsers,
  selectUserManagementPage,
  selectUserManagementTake,
  selectUserStatusFilter,
  selectUserReferrerFilter,
  selectUserNameFilter,
  selectAddedDateStartFilter,
  selectAddedDateEndFilter,
  (
    users: UserManagementUser[],
    page: number,
    take: number,
    statusFilter?: PlatformInviteStatusEnum,
    referrerFilter?: number,
    nameFilter?: string,
    startDateFilter?: Date,
    endDateFilter?: Date
  ) => {
    const skip = take * (page - 1);
    const end = take * page;

    return users
      .filter(
        (x) =>
          (statusFilter ? x.platformStatus === statusFilter : true) &&
          referredByFilter(referrerFilter, x.referredByAk) &&
          userNameFilter(x.firstName + ' ' + x.lastName, nameFilter) &&
          addedDateFilter(x.createdDate, startDateFilter, endDateFilter)
      )
      .slice(skip, end);
  }
);

export const selectEditingEngagementResource = (state: AppState) =>
  state[adminStateKey].editingEngagementResource;
export const selectEngagementResourceSaving = (state: AppState) =>
  state[adminStateKey].engagementResourceSaving;
export const selectEngagementResourceSaveResult = (state: AppState) =>
  state[adminStateKey].engagementResourceSaveResult;

export const selectEditingHelpCenterArticle = (state: AppState) =>
  state[adminStateKey].editingHelpCenterArticle;
export const selectHelpCenterArticleSaving = (state: AppState) =>
  state[adminStateKey].helpCenterArticleSaving;
export const selectHelpCenterArticleSaveResult = (state: AppState) =>
  state[adminStateKey].helpCenterArticleSaveResult;

export const selectMindflickAccountConnections = (state: AppState) =>
  state[adminStateKey].mindflickAccountConnections;
export const selectMindflickAccountConnectionsLoading = (state: AppState) =>
  state[adminStateKey].mindflickAccountConnectionsLoading;

export const selectSelectedUserAkSuperAdmin = (state: AppState) =>
  state[adminStateKey].selectedUserAk;

export const selectSelectedUserSuperAdmin = createSelector(
  selectUserManagementUserEntities,
  selectSelectedUserAkSuperAdmin,
  (users: Dictionary<UserManagementUser>, userAk?: number) => {
    return userAk ? users[userAk] : undefined;
  }
);

const referredByFilter = (filter?: number, referredByAk?: number): boolean => {
  return filter == undefined
    ? true
    : filter == 0
    ? referredByAk == undefined
    : referredByAk == filter;
};

const userNameFilter = (fullName: string, filter?: string): boolean => {
  return filter == undefined
    ? true
    : fullName.toLocaleLowerCase().includes(filter.toLocaleLowerCase());
};

const addedDateFilter = (
  userAddedDate?: Date,
  startDate?: Date,
  endDate?: Date
) => {
  if (!userAddedDate) return true;

  const start = startDate
    ? startDate.setHours(0, 0, 0)
    : new Date(1970, 0, 1, 0, 0, 0);
  const end = endDate ? endDate.setHours(23, 59, 59) : new Date();

  return userAddedDate >= start && userAddedDate <= end;
};
