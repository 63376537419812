<div class="modal-header justify-content-end">
  <fa-icon class="cursor-pointer fa-lg"
           [icon]="closeIcon"
           (click)="close()"></fa-icon>
</div>
<div class="modal-body">
  <ng-container *ngIf="totalInvited > 0; else inviteFailed">
    <h2 class="mb-2">Invites Successful</h2>
    <h3 class="mb-3">
      Succesfully added {{ totalInvited }} user(s) to the platform.
    </h3>
  </ng-container>
  <ng-template #inviteFailed>
    <h2 class="mb-2">Invites Failed</h2>
    <h3 class="mb-3">Could not add user(s) to the platform.</h3>
  </ng-template>
  <div *ngIf="notInvited.length > 0" class="body-copy mb-2">
    The following user(s) could not be invited to the platform. Please use the
    search function to verify these users don't already exist, for assistance
    please contact
    <a href="mailto:support@mindflick.co.uk">support&#64;mindflick.co.uk</a>:
  </div>
  <ul class="mb-4">
    <li *ngFor="let user of notInvited">
      {{ user.firstName }} {{ user.lastName }} ({{ user.email }})
    </li>
  </ul>

  <button class="btn btn-purple w-100" (click)="close()">Okay</button>
</div>
<div class="modal-bottom-border"></div>
