import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { AppState } from 'src/app/+state/app.state';
import {
  DeleteUpcomingWorkOn,
  SetSelectedGrowth,
  SetSelectedHeading,
} from 'src/app/+state/growth/growth.actions';
import { GrowthType } from 'src/app/+state/growth/growth.reducer';
import { selectAllTagEntities } from 'src/app/+state/growth/growth.selector';
import { SetSubMenuLargePopout } from 'src/app/+state/layout/actions/layout.actions';
import { SubMenuLargePopoutEnum } from 'src/app/+state/layout/reducers/layout.reducers';
import { AddEvent } from '../../../+state/audit/audit.actions';
import { faCloseSVG, faPinSVG } from '../../../../icons';
import { WorkOn, WorkOnType } from '../../models';
import { AuditEventType, PlatformAreas } from '../../models/enums/audit.enum';
import { DialogService } from '../../services/dialog.service';

@Component({
  selector: 'app-work-on-card-small',
  templateUrl: './work-on-card-small.component.html',
  styleUrls: ['./work-on-card-small.component.scss'],
})
export class WorkOnCardSmallComponent implements OnInit {
  @Input() id?: number;
  @Input() title?: string;
  @Input() summary?: string;
  @Input()
  set imageUrl(value: string | undefined) {
    if (value)
      this.encodedImageUri = "\"" + encodeURI(value) + "\"";
    else
      this.encodedImageUri = undefined;
  }
  @Input() growthType: GrowthType = GrowthType.WorkOn;
  @Input() workOnType: WorkOnType = WorkOnType.None;

  @Input()
  canPin: boolean = false;

  @Input()
  disableShowWorkOn: boolean = false;

  @Input()
  hasRightMargin: boolean = true;

  @Input()
  showSummary: boolean = true;

  @Input()
  minWidthPx: number = 325;

  @Input()
  widthVw: number = 25;

  @Input() isUpcoming: boolean = false;

  @Output()
  pinClicked: EventEmitter<number> = new EventEmitter<number>();

  growthTags$ = this.store.select(selectAllTagEntities);

  GrowthType = GrowthType;
  WorkOnType = WorkOnType;

  isHovering = false;
  close = faCloseSVG;
  pin = faPinSVG;
  encodedImageUri?: string;

  constructor(
    private store: Store<AppState>,
    private dialogService: DialogService
  ) {}

  ngOnInit(): void {}

  showWorkOn() {
    if (!this.disableShowWorkOn) {
      this.store.dispatch(
        SetSelectedGrowth({
          growthId: this.id,
          growthType: this.growthType,
          engagementResourceId: this.id,
        })
      );
      this.store.dispatch(
        AddEvent({
          event: {
            eventDate: new Date(),
            eventType: AuditEventType.WorkOnViewed,
            area: PlatformAreas.Dashboard,
            areaId: this.id,
          },
        })
      );

      this.store.dispatch(
        SetSubMenuLargePopout({
          subMenuLargePopout: SubMenuLargePopoutEnum.GrowthDetails,
        })
      );
    }
  }

  onMouseEnter() {
    this.isHovering = true;
  }

  onMouseLeave() {
    this.isHovering = false;
  }

  remove() {
    this.dialogService
      .confirm(
        'Are you sure you want to remove this upcoming work-on?',
        'Remove'
      )
      .pipe(take(1))
      .subscribe((x) => {
        if (x)
          this.store.dispatch(
            DeleteUpcomingWorkOn.Request({ workOnId: this.id! })
          );
      });
  }

  onPinClicked(event: MouseEvent) {
    event.stopPropagation();

    if (this.canPin) this.pinClicked.emit(this.id);
  }
}
