<nav class="navbar p-0 d-flex py-2">
  <div class="container-fluid">
    <div class="d-flex">
      <img
        src="./assets/images/logos/mindflick.svg"
        class="ms-2 logo"
        (click)="navigateHome()"
      />
      <div
        *ngIf="!env.production"
        class="body-copy text-white align-self-end dev-tag"
      >
        DEV
      </div>
    </div>
    <div class="pe-3 d-flex flex-wrap">
      <div
        *ngIf="connectingToSignalR$ | async"
        title="Connecting to server..."
        class="nav-link active"
      >
        <fa-icon [icon]="['fas', 'satellite-dish']"></fa-icon>
      </div>
      <div *ngIf="viewCoachFeedback">
        <div class="nav-link"
          [class.active]="routeStartPath == '/super-admin/coach-feedback'" (click)="navigateToSection(coachFeedback)">
          <fa-icon class="fa-spin" [icon]="['fas', 'star']"></fa-icon>
        </div>
      </div>
      <div
        *ngIf="isSuperAdmin$ | async"
        class="nav-link"
        [class.active]="routeStartPath == '/super-admin'"
        (click)="navigateToSection(superAdmin)"
      >
        <fa-icon class="fa-spin" [icon]="['fas', 'gears']"></fa-icon>
      </div>

      <div class="position-relative" (click)="toggleNotifications()">
        <fa-icon
          [icon]="['fas', 'bell']"
          alt="Notification Bell"
          class="nav-link"
        ></fa-icon>
        <fa-icon
          *ngIf="isNotificationsPending"
          [icon]="['fas', 'circle']"
          alt="Notifications Pending"
          class="nav-link text-secondary position-absolute circle"
        ></fa-icon>
      </div>

      <div
        *ngFor="let option of topMenuLinks"
        class="nav-link position-relative"
        [class.active]="option.active"
        [class.shepherd-connect-nav]="option.title === 'Connect'"
        [class.pe-none]="shepherdService.isActive && option.title !== 'Connect'"
        [routerLink]="option.routerLink"
        (click)="navigateToSection(option)"
        [attr.id]="'test-' + option.title"
      >
        {{ option.title }}
        <fa-icon
          *ngIf="
            (option.title == 'Connections' &&
              (isConnectionsPending$ | async)) ||
            (option.title == 'Teams' && (isTeamRequestPending$ | async))
          "
          [icon]="['fas', 'circle']"
          alt="Connections Pending"
          class="nav-link text-secondary position-absolute circle-connection"
        ></fa-icon>
      </div>
      <div
        *ngIf="canUserViewAdmin$ | async"
        class="nav-link"
        [class.active]="routeStartPath == '/admin'"
        (click)="navigateToSection(accountAdmin)"
      >
        Admin
      </div>
    </div>
  </div>
</nav>
