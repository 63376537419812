<div
  class="d-flex toggle-container align-items-center"
  [class.cursor-pointer]="!disabled"
  (click)="toggle()"
>
  <h3 class="flex-fill">
    {{ description }}
  </h3>
  <div class="toggle-switch d-flex" [class.toggle-switch-on]="isOn" [class.disabled]="disabled" [ariaDisabled]="disabled">
    <div class="toggle-switch-slider" [@onOff]="isOn ? 'on' : 'off'"></div>
  </div>
</div>
