import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, of, take } from 'rxjs';
import {
  CreateTeamModel,
  SaveTeamDetailsModel,
  StandardResponse,
  Team,
  TeamExercise,
  TeamMechanic,
  TeamSpotlight,
  UpsertTeamExerciseModel,
} from '../models';
import { DownloadService } from './download.service';
import { AppState } from 'src/app/+state/app.state';
import { Store } from '@ngrx/store';
import { selectIsDownloadingTeamMap } from 'src/app/+state/account-admin/account-admin.selector';

@Injectable({
  providedIn: 'root',
})
export class TeamService {
  teamUrl: string = '';
  teamName = '';

  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string,
    private downloadService: DownloadService,
    private store: Store<AppState>
  ) {
    this.teamUrl = `${baseUrl}team`;
  }

  downloadingTeamMap$ = this.store.select(selectIsDownloadingTeamMap);

  getMyTeams(): Observable<Team[]> {
    return this.http.get<Team[]>(`${this.teamUrl}/all`);
  }

  saveTeamDetails(
    body: SaveTeamDetailsModel,
  ): Observable<StandardResponse<Team>> {
    return this.http.post<StandardResponse<Team>>(
      `${this.teamUrl}/details/save`,
      body,
    );
  }

  generateTeamSpotlights(teamId: number): Observable<TeamSpotlight[]> {
    return this.http.post<TeamSpotlight[]>(
      `${this.teamUrl}/generate-team-spotlights/${teamId}`,
      {},
    );
  }

  createNewTeam(body: CreateTeamModel): Observable<Team> {
    return this.http.post<Team>(`${this.teamUrl}/create`, body);
  }

  notifyTeamInvite(userGuid: string) {
    return this.http.post<Team>(`${this.teamUrl}/team-invite/request`, {
      userGuid: userGuid,
    });
  }

  setAcceptedDate(teamId: number): Observable<Team> {
    return this.http.post<Team>(`${this.teamUrl}/${teamId}/add`, '');
  }

  deleteTeamMember(teamId: number): Observable<string> {
    return this.http.delete<string>(`${this.teamUrl}/${teamId}/decline`);
  }

  leaveTeam(teamId: number): Observable<Team> {
    return this.http.delete<Team>(`${this.teamUrl}/${teamId}/leave`);
  }

  deleteTeam(teamId: number) {
    return this.http.delete<Team>(`${this.teamUrl}/delete/${teamId}`);
  }

  addExerciseToTeam(body: UpsertTeamExerciseModel): Observable<TeamExercise> {
    return this.http.post<TeamExercise>(`${this.teamUrl}/exercise/add`, body);
  }

  removeExerciseFromTeam(
    body: UpsertTeamExerciseModel,
  ): Observable<TeamExercise> {
    return this.http.post<TeamExercise>(
      `${this.teamUrl}/exercise/remove`,
      body,
    );
  }

  getMyTeamRequests(): Observable<Team[]> {
    return this.http.get<Team[]>(`${this.teamUrl}/requests`);
  }

  cancelTeamRequest(teamId: number) {
    return this.http.delete<Team>(`${this.teamUrl}/${teamId}/cancel-request`);
  }

  getUnjoinedPublicAccountTeams(): Observable<Team[]> {
    return this.http.get<Team[]>(`${this.teamUrl}/account/all`);
  }

  requestToJoinTeam(teamId: number) {
    return this.http.post<Team>(`${this.teamUrl}/request-to-join`, teamId);
  }

  declineRequestToJoin(userAk: number, teamId: number) {
    return this.http.post(`${this.teamUrl}/request-to-join/decline`, {
      userAk,
      teamId,
    });
  }

  acceptRequestToJoin(userAk: number, teamId: number) {
    return this.http.post<Team>(`${this.teamUrl}/request-to-join/accept`, {
      userAk,
      teamId,
    });
  }

  getIsNewTeamNameUnique(teamName: string): Observable<boolean> {
    return this.http.get<boolean>(`${this.teamUrl}/create/unique/${teamName}`);
  }

  getAllTeamsForAdmin(): Observable<Team[]> {
    return this.http.get<Team[]>(`${this.teamUrl}/admin`);
  }
  
  updateTeamMembers(teamId: number, adminIds: number[], memberIds: number[]) {
    return this.http.patch<Team>(`${this.teamUrl}/members`, { teamId, adminIds, memberIds });
  }

  updateTeamMechanics(
    teamId: number,
    purposes: TeamMechanic[],
    foci: TeamMechanic[],
    principles: TeamMechanic[],
  ) {
    return this.http.patch(`${this.teamUrl}/mechanics`, {
      teamId,
      purposes,
      foci,
      principles,
    });
  }

  downloadTeamMap(teamId: number, teamName: string) {
    this.teamName = teamName;
    return this.http.get<any>(`${this.teamUrl}/${teamId}/map`);
  }

  getTeamMapFromToken(token: string) {
    if (token != null) {
      this.downloadingTeamMap$
      .pipe(take(1))
      .subscribe((isDownloading) => {
        if (isDownloading)
          this.downloadService.addToQueue(
            `Team_Map_${this.teamName}_${new Date().toLocaleDateString('en-GB')}`,
            `${this.teamUrl}/map/${token}`,
          );
        return of();
      });
    }
    return of();
  }

  getAllTeams(): Observable<Team[]> {
    return this.http.get<Team[]>(`${this.teamUrl}/all`);
  }

  getTeam(teamId: number): Observable<Team> {
    return this.http.get<Team>(`${this.teamUrl}/${teamId}`);
  }

  setTeamPhoto(teamId: number, body: FormData): Observable<string> {
    return this.http.post<string>(`${this.teamUrl}/${teamId}/photo/set`, body);
  }

  updateTeamImageId(teamId: number, newImageId: number) {
    return this.http.patch(`${this.teamUrl}/defaultImage/update`, {
      teamId,
      newImageId,
    });
  }
}
