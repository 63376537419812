<div class="d-flex flex-column h-100 page">
  <app-help-center-header></app-help-center-header>
  <div class="flex-fill d-flex flex-wrap categories">
    <div *ngFor="let category of categories " class="col-4 px-2 my-4 category pb-4">
      <h2 (click)="openCategoryPage(category.title)" class="cursor">{{category.title}}</h2>

      <div *ngIf="category.articles.length <= 5">
        <h3 *ngFor="let article of category.articles" (click)="goToArticle(category.title, article.title)" class="my-2 cursor">
          {{article.title}}
        </h3>
      </div>
      <div *ngIf="category.articles.length > 5">
          <h3 *ngFor="let article of category.articles | slice:0:4" (click)="goToArticle(category.title, article.title)" class="my-2 cursor">
            {{article.title}}
          </h3>
          <app-btn-tertiary [contentRightAligned]="false" [icon]="next" (click)="openCategoryPage(category.title)">See all {{category.articles.length}} articles</app-btn-tertiary>
        </div>
      </div>
    </div>
 <app-help-center-email></app-help-center-email>
  <app-help-center-footer></app-help-center-footer>



</div>
