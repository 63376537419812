<div *ngIf="item" (click)="toggleOpen(item)" class="menu-item-font">
  <fa-icon *ngIf="item.icon" [icon]="item.icon" class="menu-item-icon"></fa-icon>
  <span>{{item.title}}</span>
</div>
<ng-container *ngIf="isOpen">
  <ul class="child-list">
    <li *ngFor="let child of item?.children" class="my-4 mx-3">
      <div class="sidebar-list-box d-flex flex-column secondary-items">
        <app-navbar-item [item]="child"></app-navbar-item>
      </div>
    </li>
  </ul>
</ng-container>

