import { createReducer, on } from '@ngrx/store';
import { Tooltip, TooltipPage, TooltipSection } from '../../shared/models';
import * as TooltipActions from './tooltip.actions';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { CommonActions } from '../common-actions';

export interface TooltipSectionState extends EntityState<TooltipSection> {}

export interface TooltipState extends EntityState<Tooltip> {
  sections: TooltipSectionState;
  allTooltipsLoading: boolean;
  allTooltipsLoaded: boolean;
  editingTooltipId?: number;
  savingTooltip: boolean;
  saveTooltipResult?: string;
  saveTooltipError: boolean;

  // Before a list of tooltips is shown the user needs to select a tooltip section
  selectedTooltipSectionId?: number;

  // Once the user has selected a single tooltip to view then it will be stored here
  selectedTooltipId?: number;

  selectedPage?: TooltipPage;
  selectedPageId?: number;
}

export function sortTooltipsByName(a: Tooltip, b: Tooltip): number {
  return a.description.localeCompare(b.description);
}

export function sortTooltipsByOrderBy(a: Tooltip, b: Tooltip): number {
  return a.orderBy - b.orderBy;
}

export function sortTooltipSectionByName(
  a: TooltipSection,
  b: TooltipSection
): number {
  return a.longName.localeCompare(b.longName);
}

export const tooltipAdapter: EntityAdapter<Tooltip> =
  createEntityAdapter<Tooltip>({
    sortComparer: sortTooltipsByOrderBy,
  });

export const tooltipSectionAdapter: EntityAdapter<TooltipSection> =
  createEntityAdapter<TooltipSection>({
    sortComparer: sortTooltipSectionByName,
  });

export const initialTooltipSectionState: TooltipSectionState =
  tooltipSectionAdapter.getInitialState();

export const initialTooltipState: TooltipState = tooltipAdapter.getInitialState(
  {
    allTooltipsLoading: false,
    allTooltipsLoaded: false,
    sections: tooltipSectionAdapter.getInitialState(),
    saveTooltipError: false,
    savingTooltip: false,
  }
);

export const tooltipReducer = createReducer(
  initialTooltipState,

  on(CommonActions.ClearState, (state, props) => ({
    ...initialTooltipState
  })),

  on(TooltipActions.GetTooltips.Request, (state) => ({
    ...state,
    allTooltipsLoading: true,
    allTooltipsLoaded: false,
  })),

  on(TooltipActions.GetTooltips.Success, (state, props) => {
    // Replace the sections in memory
    let midState = tooltipSectionAdapter.setAll(props.sections, state.sections);
    // and then the tooltips themselves
    return tooltipAdapter.setAll(props.tooltips, {
      ...state,
      sections: midState,
      allTooltipsLoaded: true,
      allTooltipsLoading: false,
    });
  }),

  on(TooltipActions.GetTooltips.Fail, (state) => ({
    ...state,
    allTooltipsLoading: false,
    allTooltipsLoaded: false,
  })),

  on(TooltipActions.SetSelectedTooltip, (state, props) => {
    if (props.tooltipId) {
      let tooltip: Tooltip | undefined = state.entities[props.tooltipId];

      return {
        ...state,
        selectedTooltipId: tooltip?.id,
        selectedTooltipSectionId: tooltip?.sectionId,
      };
    }

    return {
      ...state,
      selectedTooltipId: undefined,
    };
  }),

  on(TooltipActions.SetSelectedTooltipSection, (state, props) => ({
    ...state,
    selectedTooltipSectionId: props.sectionId,
  })),

  on(TooltipActions.SetSelectedTooltipByNames, (state, props) => {
    let section = tooltipSectionAdapter
      .getSelectors()
      .selectAll(state.sections)
      .find(
        (x) =>
          x.shortName.toLowerCase() === props.sectionShortName.toLowerCase()
      );

    if (section) {
      let tooltip = tooltipAdapter
        .getSelectors()
        .selectAll(state)
        .find(
          (x) =>
            x.description.toLowerCase() === props.tooltipName.toLowerCase() &&
            x.sectionId === section?.id
        );

      if (tooltip) {
        return {
          ...state,
          selectedTooltipId: tooltip.id,
          selectedTooltipSectionId: tooltip.sectionId,
        };
      }
    }

    return state;
  }),

  on(TooltipActions.SaveTooltip.Request, (state, props) => ({
    ...state,
    saveTooltipResult: 'Saving...',
    saveTooltipError: false,
    savingTooltip: true,
  })),

  on(TooltipActions.SaveTooltip.Edit, (state, props) => ({
    ...state,
    saveTooltipResult: undefined,
    saveTooltipError: false,
    savingTooltip: false,
    editingTooltipId: props.tooltipId,
  })),

  on(TooltipActions.SaveTooltip.Success, (state, props) =>
    tooltipAdapter.upsertOne(props.tooltip, {
      ...state,
      saveTooltipResult: 'Save Successful',
      saveTooltipError: false,
      savingTooltip: false,
      editingTooltipId: props.tooltip.id,
    })
  ),

  on(TooltipActions.SaveTooltip.Fail, (state, props) => ({
    ...state,
    saveTooltipResult: props.error ? props.error.message : props,
    saveTooltipError: true,
    savingTooltip: false,
  })),

  on(TooltipActions.SaveTooltip.Clear, (state) => ({
    ...state,
    saveTooltipResult: undefined,
    saveTooltipError: false,
    savingTooltip: false,
  })),

  on(TooltipActions.SetSelectedPage, (state, props) => ({
    ...state,
    selectedPageId: props.pageId,
  })),

  on(TooltipActions.ChangeSelectedPage, (state, props) => ({
    ...state,
    selectedPageId: state.selectedPageId! + props.pageChange,
  }))
);
