import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '../../models';
import * as UserActions from '../../../+state/user/user.actions';
import { AppState } from 'src/app/+state/app.state';
import { Store } from '@ngrx/store';
import { ButtonActions } from '../../models/enums/btn-actions.enum';
import {
  faAddSVG,
  faAddUserSVG,
  faAdminSVG,
  faCloseSVG,
  faNextSVG,
  faRevokeConnectionSVG,
  faTickSVG,
} from '../../../../icons';
import { ConnectionStatus } from '../../models/enums/connection-status.enum';
import { selectCurrentUser } from 'src/app/+state/user/user.selector';
import { take } from 'rxjs/operators';
import { DialogService } from '../../services/dialog.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-user-panel-small',
  templateUrl: './user-panel-small.component.html',
  styleUrls: ['./user-panel-small.component.scss'],
})
export class UserPanelSmallComponent implements OnInit {
  @Input()
  user?: User;

  @Input()
  buttonAction: ButtonActions = ButtonActions.Disabled;

  //we don't always want to be able to click through to the user profile - to disable this pass down an input variable that disables this functionality

  @Input()
  disableOpenProfile: boolean = false;

  // sometimes we don't want to show the little 'Connected' message, e.g. on Connections
  @Input()
  showConnected = true;

  // Sometimes we want to be able to select the current user, usually we dont
  @Input()
  allowSelectCurrentUser: boolean = false;

  // If the user connection is pending then show a little message showing as such
  @Input()
  showPending = false;

  @Input()
  showRequesting = false;

  @Input()
  displayAdminIcon?: boolean;

  @Input()
  removeActionTooltip: string = '';

  @Input()
  isChecked?: boolean = false;

  @Output()
  buttonClicked = new EventEmitter();

  @Output()
  declineTeamRequestClicked = new EventEmitter();

  @Output()
  acceptTeamRequestClicked = new EventEmitter();

  currentUser$ = this.store.select(selectCurrentUser);

  // Expose enums to template
  ButtonActions = ButtonActions;
  ConnectionStatus = ConnectionStatus;
  add = faAddSVG;
  close = faCloseSVG;
  tick = faTickSVG;
  connect = faAddUserSVG;
  isTeamAdmin = faAdminSVG;
  profileSrc = '';
  revoke = faRevokeConnectionSVG;
  right_arrow = faNextSVG;

  constructor(
    private router: Router,
    private store: Store<AppState>,
    private dialogService: DialogService
  ) {}

  ngOnInit(): void {
    this.profileSrc =
      this.user?.photoLastModified && this.user.strId
        ? this.publicPhotoBasePath +
          this.user.strId +
          '_' +
          this.user.photoLastModified +
          '.jpg'
        : './assets/svg/profile-placeholder.svg';
  }

  public publicPhotoBasePath = environment.publicStorageUrl + 'photos/thumb/';

  openUserProfile() {
    if (!this.disableOpenProfile) {
      this.currentUser$.pipe(take(1)).subscribe((currentUser) => {
        if (this.user?.id === currentUser?.id) {
          this.router.navigate([`/profile`]);
        } else {
          this.router.navigate([`/profile/${this.user?.id}`]);
        }
      });
    } else {
      this.onActionButtonClicked(undefined);
    }
  }

  onRequestAccessClicked(e: MouseEvent) {
    e.stopPropagation();

    if (this.user) {
      this.store.dispatch(
        UserActions.RequestProfileAccess.Request({ userAK: this.user.id })
      );
    }
  }
  declineUser(e: MouseEvent, userAK: number) {
    e.stopPropagation();
    this.store.dispatch(
      UserActions.DeletePendingConnection.Request({ userAK })
    );
  }

  acceptUser(e: MouseEvent, userAK: number) {
    e.stopPropagation();
    this.dialogService.showAcceptConnectionRequestDialog(userAK);
  }

  declineTeamRequest(e: MouseEvent) {
    e.stopPropagation();
    this.declineTeamRequestClicked.emit();
  }

  acceptTeamRequest(e: MouseEvent) {
    e.stopPropagation();
    this.acceptTeamRequestClicked.emit();
  }

  onActionButtonClicked(e?: MouseEvent) {
    if (e) e.stopPropagation();
    this.buttonClicked.emit();
  }

  setDefaultPic() {
    this.profileSrc = './assets/svg/profile-placeholder.svg';
  }
}
