<div class="modal-header justify-content-end">
  <fa-icon
    class="cursor-pointer fa-lg"
    [icon]="closeIcon"
    (click)="close()"
  ></fa-icon>
</div>
<div class="modal-body">
  <h2 class="mb-4">Filter</h2>
  <form [formGroup]="filterForm" class="row gx-0 gy-2">
    <div class="col-6 pe-4">
      <label class="form-label">User status</label>
      <app-dropdown-select
        formControlName="userStatus"
        [label]="'User Status'"
        [options]="userStatusOptions"
        [placeholder]="'Select'"
      ></app-dropdown-select>
    </div>
    <div class="col-6">
      <label class="form-label">Preference</label>
      <app-dropdown-select
        formControlName="userPreference"
        [label]="'Preference'"
        [options]="preferenceOptions"
        [placeholder]="'Select'"
      ></app-dropdown-select>
    </div>
    <div class="form-group col-12">
      <label class="form-label">Date added</label>
      <app-dropdown-select
        formControlName="dateAdded"
        [label]="'Date Added'"
        [options]="dateAddedOptions"
        [placeholder]="'Select'"
        (selectionChanged)="checkIfSpecificDate($event)"
      >
      </app-dropdown-select>
    </div>

    <ng-container *ngIf="isSpecificDateRange">
      <div class="col-6 from-date position-relative pe-4">
        <label class="form-label">From</label>
        <input
          formControlName="fromDate"
          [maxDate]="maxFromDate"
          type="text"
          placeholder="Select"
          class="form-control"
          bsDatepicker
          [container]="'.from-date'"
          [bsConfig]="{ showWeekNumbers: false }"
          (bsValueChange)="setMinToDate($event)"
        />
      </div>
      <div class="col-6 to-date position-relative">
        <label class="form-label">To</label>
        <input
          formControlName="toDate"
          [minDate]="minToDate"
          type="text"
          placeholder="Select Date"
          class="form-control"
          bsDatepicker
          [container]="'.to-date'"
          [bsConfig]="{ showWeekNumbers: false }"
          (bsValueChange)="setMaxFromDate($event)"
        />
      </div>
    </ng-container>
    <div class="col-12">
      <label class="form-label">Department</label>
      <app-dropdown-select
        formControlName="department"
        [label]="'Department'"
        [options]="departmentOptions"
        [placeholder]="'Select'"
      ></app-dropdown-select>
    </div>
    <div class="col-6 pe-4">
      <label class="form-label">Web app status</label>
      <app-dropdown-select
        formControlName="portalStatus"
        [label]="'Portal Status'"
        [options]="portalStatusOptions"
        [placeholder]="'Select'"
      ></app-dropdown-select>
    </div>
    <div class="col-6">
      <label class="form-label">Mobile app status</label>
      <app-dropdown-select
        formControlName="appStatus"
        [label]="'App Status'"
        [options]="appStatusOptions"
        [placeholder]="'Select'"
      ></app-dropdown-select>
    </div>
    <div *ngIf="adminCanAllocateSeats$ | async" class="mt-4 form-group col-12">
      <app-toggle-box
        description="Has Unused Seats"
        [isOn]="hasUnusedSeats"
        (toggleChanged)="hasUnusedSeats = $event"
      ></app-toggle-box>
    </div>
  </form>

  <div class="flex-column justify-content-between pt-4">
    <button class="btn btn-secondary col-12" (click)="clearFilters()">
      Clear Filters
    </button>
    <button class="btn btn-purple col-12 mt-3" (click)="applyFilters()">
      Apply Filters
    </button>
  </div>
</div>
<div class="modal-bottom-border"></div>
