<div
  class="w-100 bg-dark py-2 d-flex align-items-center justify-content-center small-text"
>
  <a class="me-2 text-grey" [href]="privacyPolicyUrl" target="_blank"
    >Privacy Policy</a
  >
  <a class="mx-2 text-grey" [href]="termsUrl" target="_blank">Terms of Use</a>
  <a class="mx-2 text-grey" [href]="cookieNoticeUrl" target="_blank"
    >Cookie Notice</a
  >
  <a class="ms-2 text-grey" [href]="glossaryUrl" target="_blank">Glossary</a>
</div>
