import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import {
  catchError,
  filter,
  map,
  mergeMap,
  withLatestFrom,
} from 'rxjs/operators';
import { SurveyService } from 'src/app/shared/services/survey.service';
import { AppState, surveyStateKey } from '../../app.state';
import { SetDashboardIsLoading, noOpAction, setBackground } from '../../layout/actions/layout.actions';
import * as SurveyActions from '../actions/survey.actions';
import { BackgroundStyle } from '../../layout/reducers/layout.reducers';
import { UserService } from '../../../shared/services/user.service';
import { SpotlightResponse } from '../../../shared/models';
import { ProfileService } from '../../../shared/services/profile.service';
import { selectCurrentUser } from '../../user/user.selector';
import { isNonNull } from '../../../shared/helpers/rxjs-type-guards';

@Injectable()
export class SurveyEffects {
  loadSurvey$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SurveyActions.LoadSurveyFromInvite.Request),
      mergeMap((action) =>
        this.surveyService
          .getInvite(action.spotlightResponseId, action.uniqueRef, action.langId)
          .pipe(
            map((response) =>
              SurveyActions.LoadSurveyFromInvite.Success({ response })
            ),
            catchError((error) =>
              of(SurveyActions.LoadSurveyFromInvite.Fail({ error }))
            )
          )
      )
    )
  );

  updateSurveyRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SurveyActions.UpdateSurvey.Request),
      mergeMap((action) =>
        this.surveyService
          .updateSurvey(
            action.uniqueRef,
            action.spotlightResponseId,
            action.request
          )
          .pipe(
            map((data) => SurveyActions.UpdateSurvey.Success({ data })),
            catchError((error) =>
              of(SurveyActions.UpdateSurvey.Fail({ error }))
            )
          )
      )
    )
  );

  updateSurveySuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SurveyActions.UpdateSurvey.Success),
      mergeMap((action) => {
        return of(
          SurveyActions.LoadSurveyFromInvite.Request({
            uniqueRef: action.data.uniqueRef,
            spotlightResponseId: action.data.surveyId,
          })
        );
      })
    )
  );

  saveSurveyAnswers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SurveyActions.SaveSurveyAnswers.Request),
      mergeMap((action) =>
        this.surveyService.saveProgress(action.request).pipe(
          map((response) => SurveyActions.SaveSurveyAnswers.Success()),
          catchError((error) =>
            of(SurveyActions.SaveSurveyAnswers.Fail({ error }))
          )
        )
      )
    )
  );

  saveSurveyAnswersSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SurveyActions.SaveSurveyAnswers.Success),
        withLatestFrom(this.store),
        map(([_, state]) => {
          if(state[surveyStateKey].currentPage == 20 && !state[surveyStateKey].questionnaireResponses.length) {
            this.router.navigate([
              `survey/${state.surveyState.uniqueRef}/${state.surveyState.spotlightResponseId}/summary`,
            ]);
          } else if (state[surveyStateKey].currentPage == 20 && state[surveyStateKey].questionnaireResponses.length) {
            this.router.navigate([
              `survey/${state.surveyState.uniqueRef}/${state.surveyState.spotlightResponseId}/questionnaire/${state.surveyState.questionnaireResponses[0].questionnaireResponseId}`,
            ]);
          }
          else {
            this.router.navigate([
              `survey/${state.surveyState.uniqueRef}/${state.surveyState.spotlightResponseId}/page/${state.surveyState.currentPage}`,
            ]);
          }
        })
      ),
    { dispatch: false }
  );

  checkExistingSurvey$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SurveyActions.CheckExistingSurvey),
      withLatestFrom(this.store),
      map(([action, state]) => {
        if (
          state.surveyState.spotlightResponse?.id ==
          action.spotlightResponseId &&
          state.surveyState.spotlightResponse.uniqueRef == action.uniqueRef
        ) {
          return noOpAction();
        }

        this.store.dispatch(
          SurveyActions.SetSpotlightResponseId({
            spotlightResponseId: action.spotlightResponseId,
          })
        );
        this.store.dispatch(
          SurveyActions.SetUniqueRef({ uniqueRef: action.uniqueRef })
        );

        return SurveyActions.LoadSurveyFromInvite.Request({
          spotlightResponseId: action.spotlightResponseId,
          uniqueRef: action.uniqueRef,
        });
      }),
      filter((action) => action.type != noOpAction.type)
    )
  );

  nextPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SurveyActions.NextPage),
        withLatestFrom(this.store),
        map(([_, state]) => {
          if (state.surveyState.currentPage >= 0) {
            this.router.navigate([
              `survey/${state.surveyState.uniqueRef}/${state.surveyState.spotlightResponseId}/page/${state.surveyState.currentPage}`,
            ]);
          }
        })
      ),
    { dispatch: false }
  );

  previousPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SurveyActions.PreviousPage),
        withLatestFrom(this.store),
        map(([_, state]) => {
          if (state.surveyState.currentPage >= 0) {
            this.router.navigate([
              `survey/${state.surveyState.uniqueRef}/${state.surveyState.spotlightResponseId}/page/${state.surveyState.currentPage}`,
            ]);
          } else {
            this.router.navigate([
              `survey/${state.surveyState.uniqueRef}/${state.surveyState.spotlightResponseId}`,
            ]);
          }
        })
      ),
    { dispatch: false }
  );

  changePage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        SurveyActions.SetSurveyPage,
        SurveyActions.NextPage,
        SurveyActions.PreviousPage,
        SurveyActions.SaveSurveyAnswers.Success
      ),
      withLatestFrom(this.store),
      map(([action, state]) => {
        //The effect happens after the reducer so we can check the page number in either the reducer or the action
        //If we are going to the intro page...
        if (state.surveyState.currentPage == -2) {
          return setBackground({
            style: BackgroundStyle.IntroBoxesBottomRight,
          });
        }
        //If we are going from intro to main question pages then use the light background
        //Effects happen after the reducer, so the page will be after the
        if (state.surveyState.currentPage >= -1) {
          return setBackground({
            style: BackgroundStyle.BlurredQuestionnaireDark,
          });
        }
        //If we are going into the last page then use the dark background
        if (
          state.surveyState.spotlightResponse &&
          state.surveyState.currentPage ==
          state.surveyState.spotlightResponse.pages.length
        ) {


          return setBackground({
            style: BackgroundStyle.BlurredQuestionnaireDark,
          });
        }

        return noOpAction();
      }),
      filter((action) => action.type != noOpAction.type)
    )
  );

  setProfilePicture$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SurveyActions.SetUserPicture.Request),
      mergeMap((action) =>
        this.userService
          .setUserPicture(
            action.formData,
            action.spotlightResponseId,
            action.uniqueRef
          )
          .pipe(
            map((resp) =>
              SurveyActions.SetUserPicture.Success({ photoSrc: resp })
            ),
            catchError((error) =>
              of(SurveyActions.SetUserPicture.Fail({ error }))
            )
          )
      )
    )
  );


  getQuestionnaires$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SurveyActions.GetQuestionnairesForSpotlightResponse.Request),
      mergeMap((action) =>
        this.surveyService
          .getQuestionnaires(
            action.uniqueRef,
            action.spotlightResponseId
          )
          .pipe(
            map((resp) =>
              SurveyActions.GetQuestionnairesForSpotlightResponse.Success({ questionnaires: resp })
            ),
            catchError((error) =>
              of(SurveyActions.GetQuestionnairesForSpotlightResponse.Fail({ error }))
            )
          )
      )
    )
  );

  getQuestionnaireResponseDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SurveyActions.GetQuestionnaireResponseDetails.Request),
      mergeMap((action) =>
        this.surveyService
          .getQuestionnaireResponseDetails(
            action.uniqueRef,
            action.questionnaireResponseId
          )
          .pipe(
            map((resp) =>
              SurveyActions.GetQuestionnaireResponseDetails.Success({ questionnaire: resp })
            ),
            catchError((error) =>
              of(SurveyActions.GetQuestionnaireResponseDetails.Fail({ error }))
            )
          )
      )
    )
  );


  saveQuestionnaireAnswers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SurveyActions.SaveQuestionnaireAnswers.Request),
      mergeMap((action) =>
        this.surveyService
          .saveQuestionnaireAnswers(
            action.questionnaireAnswers
          )
          .pipe(
            map(() =>
              SurveyActions.SaveQuestionnaireAnswers.Success({isComplete: action.isComplete})
            ),
            catchError((error) =>
              of(SurveyActions.SaveQuestionnaireAnswers.Fail({ error }))
            )
          )
      )
    )
  );

  triggerDashboardLoading$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        SurveyActions.SaveQuestionnaireAnswers.Success
      ),
      filter(action => action.isComplete),
      map(() => SetDashboardIsLoading({ isLoading: true })),
    )
  );

  triggerNavigateToDashboardAndReload$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        SurveyActions.SaveQuestionnaireAnswers.Success
      ),
      filter(action => action.isComplete),
      withLatestFrom(this.store.select(selectCurrentUser).pipe(filter(isNonNull))),
      map(([_, user]) => {
        if (user.isDebriefRequired) {
          this.router.navigate(['debrief/intro']).then(() => {
            window.location.reload();
          });
        } else {
          this.router.navigate(['dashboard']).then(() => {
            window.location.reload();
          });
        }
      }),
    ), { dispatch: false }
  );





  constructor(
    private actions$: Actions,
    private surveyService: SurveyService,
    private userService: UserService,
    private profileService: ProfileService,
    private router: Router,
    private store: Store<AppState>
  ) {}
}
