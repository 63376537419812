<div class="modal-header justify-content-end">
  <fa-icon class="cursor-pointer fa-lg"
           [icon]="closeIcon"
           (click)="close()"></fa-icon>
</div>
<div class="modal-body">
    <h2>Action required</h2>
    <h3 class="mt-15">
      {{ message }}
    </h3>
    <div>
      <p class="my-15">The following users are currently deactivated. You may reactivate their accounts or create new ones.</p>
      <ul class="mb-4">
        <li *ngFor="let user of deactivatedUsers" >
          {{ user.firstName }} {{ user.lastName }} ({{ user.email }})
        </li>
      </ul>
      <div class="d-flex gap mt-4">
        <button class="btn btn-primary me-15" (click)="reactivateUsers()">Reactivate</button>
        <button class="btn btn-outline-dark" (click)="recreateUsers()">Create new accounts</button>
      </div>
    </div>
  <div *ngIf="usersNotAdded.length > 0" class="body-copy mb-2">
    The following user(s) could not be invited to the platform. Please use the
    search function to verify these users don't already exist, for assistance
    please contact
    <a href="mailto:support@mindflick.co.uk">support&#64;mindflick.co.uk</a>:
  </div>
  <ul *ngIf="usersNotAdded.length > 0" class="mb-4">
    <li *ngFor="let user of usersNotAdded">
      {{ user.firstName }} {{ user.lastName }} ({{ user.email }})
    </li>
  </ul>
</div>
<div class="modal-bottom-border"></div>
