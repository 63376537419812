import { Component, Inject, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppState } from '../+state/app.state';
import { SetSubMenuLargePopout } from '../+state/layout/actions/layout.actions';
import { SubMenuLargePopoutEnum } from '../+state/layout/reducers/layout.reducers';
import { SetUserCookieSettings } from '../+state/user/user.actions';
import { selectCookieSettings } from '../+state/user/user.selector';
import { CookieSettings } from '../shared/models/cookies';

@Component({
  selector: 'app-cookie-banner',
  templateUrl: './cookie-banner.component.html',
  styleUrls: ['./cookie-banner.component.scss'],
})
export class CookieBannerComponent implements OnInit {
  cookieSettings$ = this.store.select(selectCookieSettings);

  cookiePolicyUrl = this.baseUrl + '#/public/cookie-notice';

  destroyed$ = new Subject<boolean>();

  constructor(
    private store: Store<AppState>,
    @Inject('BASE_URL') private baseUrl: string
  ) {}

  ngOnInit(): void {
    this.cookieSettings$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((settings) => {});
  }

  acceptAll() {
    const settings: CookieSettings = {
      acknowledgedDate: new Date(),
      hasAcceptedMixpanel: false,
    };

    this.store.dispatch(SetUserCookieSettings.Request({ settings }));
  }
}
