<div class="page flex-fill mt-5">
  <div class="d-flex justify-content-between">
    <h2>All users</h2>
    <div *ngIf="userManagementMindflickAccounts$ | async as accounts" class="btn-group" dropdown placement="bottom right">
      <h2
        id="button-credit-pots"
        class="d-flex"
        dropdownToggle
        type="button"
        aria-controls="dropdown-credit-pots"
      >
        {{ selectedAccount?.name }}
        <fa-icon class="fs-4 pot-dropdown d-flex align-items-center" [icon]="dropdownIcon" />
      </h2>
      <ul
        id="dropdown-credit-pots"
        *dropdownMenu
        class="dropdown-menu dropdown-menu-right"
        role="menu"
        aria-labelledby="button-credit-pots"
      >
        <li
          *ngFor="let account of accounts"
          class="cursor-pointer"
          role="menuitem"
          (click)="onMindflickAccountChanged(account.accountId)"
        >
          <div class="dropdown-item" href="#">
            {{ account.name }}
          </div>
        </li>
      </ul>
    </div>
  </div>
  
  <ng-container *ngIf="selectedUserIds.length > 0">
    <div [@selectedUsersToggle] class="anim-container">
      <div class="mt-3 selected-profiles-container d-flex justify-content-between align-items-center p-3">
        <div class="d-flex align-items-center">
          <div
            class="selected-profiles-count body-copy text-white d-flex align-items-center justify-content-center"
          >
            {{ selectedUserIds.length }}
          </div>
          <h2 class="ps-2">
            {{ selectedUserIds.length > 1 ? "Users" : "User" }}
            selected
          </h2>
          <app-btn-tertiary class="ps-4" (click)="clearSelectedUsers()"
            >Clear</app-btn-tertiary
          >
        </div>
        <div class="d-flex align-items-center justify-content-end">
          <app-btn-tertiary
            [disabled]="!isResendPlatformInviteValid"
            (buttonClick)="resendSpotlightInvites()"
            >Resend invite</app-btn-tertiary
          >
          <app-btn-tertiary
            [disabled]="!isAllSelectedLocked"
            class="ms-4"
            (buttonClick)="unlockUsers()"
            >Unlock</app-btn-tertiary
          >
    
          <div class="ms-4" *isGranted="'DOWNLOAD_PDF_PROFILES'; forAccount: selectedAccount?.accountId">
            <app-btn-tertiary [disabled]="!showDownloadPDFs" (buttonClick)="downloadPDFs()">Download PDFs</app-btn-tertiary>
          </div>
    
          <div class="ms-4" *isGranted="'DOWNLOAD_TEAM_MAP'; forAccount: selectedAccount?.accountId">
            <app-btn-tertiary [disabled]="!showDownloadPDFs"
                              (buttonClick)="downloadTeamMap()">Download team map</app-btn-tertiary>
          </div>
    
          <div class="ms-4">
            <app-btn-tertiary (buttonClick)="downloadCSV()">Download CSV</app-btn-tertiary>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  
  
  <div *ngIf="downloadErrorMessage.length > 0" class="form-error"> {{this.downloadErrorMessage}} </div>
  <div class="table-container">
    <div class="table-content">
      <div class="d-flex flex-column mt-3">
        <div class="row gx-0 px-1 py-3 table-headers">
          <div
            class="col-width-250 flex-row order-header col"
            (click)="orderUsersByName()"
          >
            <input
              class="form-check-input me-1"
              type="checkbox"
              [checked]="users.length == selectedUserIds.length"
              [indeterminate]="
                selectedUserIds.length && users.length != selectedUserIds.length
              "
              (click)="$event.stopPropagation(); selectAllUsers()"
            />
            Name
            <fa-icon
              *ngIf="orderBy === 'name-asc'"
              class="fw-bolder fa-2xs ps-1"
              [icon]="upCaret"
            ></fa-icon>
            <fa-icon
              *ngIf="orderBy === 'name-desc'"
              class="fw-bolder fa-2xs ps-1 mt-2"
              [icon]="downCaret"
            ></fa-icon>
          </div>
          <div
            class="col-width-250 cursor-pointer"
            (click)="orderUsersByEmail()"
          >
            Email address
            <fa-icon
              *ngIf="orderBy === 'email-asc'"
              class="fw-bolder fa-2xs ps-1"
              [icon]="upCaret"
            ></fa-icon>
            <fa-icon
              *ngIf="orderBy === 'email-desc'"
              class="fw-bolder fa-2xs ps-1 mt-2"
              [icon]="downCaret"
            ></fa-icon>
          </div>
          <div class="col order-header" (click)="orderUsersByDate()">
            Date added
            <fa-icon
              *ngIf="orderBy === 'date-asc'"
              class="fw-bolder fa-2xs ps-1"
              [icon]="upCaret"
            ></fa-icon>
            <fa-icon
              *ngIf="orderBy === 'date-desc'"
              class="fw-bolder fa-2xs ps-1 mt-2"
              [icon]="downCaret"
            ></fa-icon>
          </div>
          <div class="col order-header" (click)="orderUsersByDepartment()">
            Department
            <fa-icon
              *ngIf="orderBy === 'dept-asc'"
              class="fw-bolder fa-2xs ps-1"
              [icon]="upCaret"
            ></fa-icon>
            <fa-icon
              *ngIf="orderBy === 'dept-desc'"
              class="fw-bolder fa-2xs ps-1 mt-2"
              [icon]="downCaret"
            ></fa-icon>
          </div>
          <div class="col order-header" (click)="orderUsersByDepartment()">
            Notes
            <fa-icon
              *ngIf="orderBy === 'dept-asc'"
              class="fw-bolder fa-2xs ps-1"
              [icon]="upCaret"
            ></fa-icon>
            <fa-icon
              *ngIf="orderBy === 'dept-desc'"
              class="fw-bolder fa-2xs ps-1 mt-2"
              [icon]="downCaret"
            ></fa-icon>
          </div>
          <div
            class="col-width-100 col text-center cursor-pointer"
            (click)="orderUsersByStatus()"
          >
            Onboarding status
            <fa-icon
              *ngIf="orderBy === 'web-status-asc'"
              class="fw-bolder fa-2xs ps-1"
              [icon]="upCaret"
            ></fa-icon>
            <fa-icon
              *ngIf="orderBy === 'web-status-desc'"
              class="fw-bolder fa-2xs ps-1 mt-2"
              [icon]="downCaret"
            ></fa-icon>
          </div>
          <div
            *isGranted="'ALLOCATE_SEATS'; forAccount: selectedAccount?.accountId"
            class="col-width-100 col text-center cursor-pointer"
            (click)="orderUsersByAllocatedSeats()"
          >
            Allocated seats used/total
            <fa-icon
              *ngIf="orderBy === 'allocated-seats-asc'"
              class="fw-bolder fa-2xs ps-1"
              [icon]="upCaret"
            ></fa-icon>
            <fa-icon
              *ngIf="orderBy === 'allocated-seats-desc'"
              class="fw-bolder fa-2xs ps-1 mt-2"
              [icon]="downCaret"
            ></fa-icon>
          </div>
          <div class="action-space"></div>
        </div>
      </div>
      <div
        *ngIf="usersLoading$ | async"
        class="py-5 text-center fw-bold body-copy grey"
      >
        <div class="spinner-border text-primary mt-3"></div>
      </div>
      <div
        *ngIf="users.length == 0 && filter == null && !(usersLoading$ | async)"
        class="py-5 text-center fw-bold body-copy grey"
      >
        You haven't added any users yet!
      </div>
      <div
        *ngIf="users.length == 0 && filter != null && !(usersLoading$ | async)"
        class="py-5 text-center fw-bold body-copy grey"
      >
        No results found.
      </div>
      <div
        *ngIf="userRequestFailed && !(usersLoading$ | async)"
        class="py-5 text-center text-error"
      >
        Request failed, please try refreshing.
      </div>
      <div
        *ngIf="
          (updateStatus$ | async) == inProgress && !(usersLoading$ | async)
        "
        class="py-5 text-center flex-column text-primary"
      >
        <h2>Update in progress...</h2>
        <div class="spinner-border text-primary mt-3"></div>
      </div>
      <div
        *ngIf="
          (updateStatus$ | async) != inProgress && !(usersLoading$ | async)
        "
      >
        <div *ngFor="let user of displayUsers; let i = index">
          <app-user-management-table-row
            [user]="user"
            [isSelected]="selectedUserIds.includes(user.ak)"
            [showDropUp]="displayUsers.length - i <= 5"
            (userSelected)="onUserSelected($event)"
          ></app-user-management-table-row>
        </div>
      </div>
    </div>
  </div>
  <div class="d-flex justify-content-between">
    <div class="d-flex flex-column pt-1">
      <p class="form-label">Users per page</p>
      <form [formGroup]="displayUsersForm">
        <app-dropdown-select
          formControlName="numOfUsers"
          [options]="numOfUsersOptions"
          [placeholder]="'10'"
          [label]="'num of users'"
          [dropUp]="true"
          (selectionChanged)="changeNumberOfUsers($event)"
        ></app-dropdown-select>
      </form>
    </div>

    <pagination
      *ngIf="numOfPages > 1"
      [totalItems]="users.length"
      [itemsPerPage]="userLimit"
      [customPreviousTemplate]="prevTemplate"
      [customNextTemplate]="nextTemplate"
      [maxSize]="8"
      [rotate]="false"
      (pageChanged)="setCurrentPage($event.page)"
      [ngModel]="currentPage"
    ></pagination>
  </div>
</div>

<ng-template
  #prevTemplate
  let-disabled="disabled"
  let-currentPage="currentPage"
>
  <app-btn-action
    [icon]="back"
    [iconDimensions]="36"
    [secondary]="true"
    [disabled]="disabled"
  >
  </app-btn-action>
</ng-template>

<ng-template
  #nextTemplate
  let-disabled="disabled"
  let-currentPage="currentPage"
>
  <app-btn-action
    [icon]="next"
    [iconDimensions]="36"
    [secondary]="true"
    [disabled]="disabled"
  >
  </app-btn-action>
</ng-template>
