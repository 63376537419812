<nav class="navbar p-0 d-flex py-2">
  <div class="container-fluid">
    <div class="pe-3 d-flex">
      <ng-container  *ngFor="let option of adminLinks">
        <div *isGranted="option.permissionRequired; forAnyAccount: true"
          class="nav-link position-relative"
          [class.active]="option.active"
          [routerLink]="option.routerLink">
          {{ option.title }}
        </div>
      </ng-container>
    </div>
  </div>
</nav>
