import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

@Component({
  selector: 'app-btn-tertiary',
  templateUrl: './btn-tertiary.component.html',
  styleUrls: ['./btn-tertiary.component.scss'],
})
export class BtnTertiaryComponent implements OnInit {
  @Input()
  icon: IconProp | undefined;

  @Input()
  light = false;

  @Input()
  purple300 = false;

  @Input()
  disabled = false;

  @Input()
  contentRightAligned = true;

  @Input() reverse = false;

  @Input() svgPath?: string;

  @Input() size: number = 20;

  @Input() isSmallIcon = false;

  @Output()
  buttonClick = new EventEmitter<MouseEvent>();

  constructor() {}

  ngOnInit(): void {}

  onButtonClick(e: MouseEvent) {
    if (!this.disabled)
      this.buttonClick.emit(e);
  }
}
