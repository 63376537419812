import { createAction, props } from '@ngrx/store';
import {
  CreateTeamModel,
  SaveTeamDetailsModel,
  StandardResponse,
  Team,
  TeamExercise,
  TeamMechanic,
  TeamSpotlight,
  UpsertTeamExerciseModel,
} from 'src/app/shared/models';
import { UpdateTeamMapStatusReq } from '../../shared/models/team-map-processing.interface';
import { UntypedFormGroup } from '@angular/forms';


export const SetSelectedTeam = createAction(
  '[Team] Set Selected Team',
  props<{ teamId: number }>(),
);

export const ClearSelectedTeam = createAction('[Team] Clear Selected Team');

export const ToggleTeamMapType = createAction('[Team] Toggle Team Map Type');
export const SetTeamMapFlex = createAction('[Team] Set Team Map Flex');

export const SetTeamMapCope = createAction('[Team] Set Team Map Cope');

export namespace SaveTeamDetails {
  export const Request = createAction(
    '[Team] Save Team Details Request',
    props<{ payload: SaveTeamDetailsModel }>(),
  );

  export const Success = createAction(
    '[Team] Save Team Details Success',
    props<{
      payload: SaveTeamDetailsModel;
      response: StandardResponse<Team>;
    }>(),
  );

  export const Fail = createAction(
    '[Team] Save Team Details Fail',
    props<{ error: any }>(),
  );
}

export const ClearSaveTeamMessage = createAction(
  '[Team] Clear Save Team Message',
);

export namespace GenerateMyTeamSpotlights {
  export const Request = createAction(
    '[Team] Generate My Team Spotlights Request',
    props<{ teamId: number }>(),
  );

  export const Success = createAction(
    '[Team] Generate My Team Spotlights Success',
    props<{ teamId: number; resp: TeamSpotlight[] }>(),
  );

  export const Fail = createAction(
    '[Team] Generate My Team Spotlights Fail',
    props<{ error: any }>(),
  );
}

export namespace CreateNewTeam {
  export const Request = createAction(
    '[Team] Create New Team Request',
    props<{ payload: CreateTeamModel; imageFormData?: FormData }>(),
  );

  export const Success = createAction(
    '[Team] Create New Team Success',
    props<{ response: Team; imageFormData?: FormData }>(),
  );

  export const Fail = createAction(
    '[Team] Create New Team Fail',
    props<{ error: any }>(),
  );
}

export namespace PutAcceptedDate {
  export const Request = createAction(
    '[Team] Put Date For Accepting Team Request',
    props<{ teamId: number; notificationId?: number }>(),
  );

  export const Success = createAction(
    '[Team] Put Date For Accepting Team Success',
    props<{ team: Team }>(),
  );

  export const Fail = createAction(
    '[Team] Put Date For Accepting Team Fail',
    props<{ error: any }>(),
  );
}
export namespace AddExerciseToTeam {
  export const Request = createAction(
    '[Team] Add Exercise To Team Request',
    props<{ payload: UpsertTeamExerciseModel }>(),
  );

  export const Success = createAction(
    '[Team] Add Exercise To Team Success',
    props<{ response: TeamExercise }>(),
  );

  export const Fail = createAction(
    '[Team] Add Exercise To Team Fail',
    props<{ error: any }>(),
  );
}

export namespace DeleteMember {
  export const Request = createAction(
    '[Team] Delete Team Member Request',
    props<{ team: Team; notificationId?: number }>(),
  );

  export const Success = createAction(
    '[Team] Delete Team Member Success',
    props<{ teamId: number }>(),
  );

  export const Fail = createAction(
    '[Team] Delete Team Member Fail',
    props<{ error: any }>(),
  );
}
export namespace RemoveExerciseFromTeam {
  export const Request = createAction(
    '[Team] Remove Exercise From Team Request',
    props<{ payload: UpsertTeamExerciseModel }>(),
  );

  export const Success = createAction(
    '[Team] Remove Exercise From Team Success',
    props<{ payload: UpsertTeamExerciseModel }>(),
  );

  export const Fail = createAction(
    '[Team] Remove Exercise From Team Fail',
    props<{ error: any }>(),
  );
}

export const UpdateTeamDetails = createAction(
  '[Team] Update Team Details',
  props<{ team: Team }>(),
);

export const RemoveTeam = createAction(
  '[Team] Remove Team',
  props<{ teamId: number }>(),
);
export namespace LeaveTeam {
  export const Request = createAction(
    '[Team] Leave Team Request',
    props<{ teamId: number }>(),
  );
  export const Success = createAction(
    '[Team] Leave Team Success',
    props<{ team: Team }>(),
  );
  export const Fail = createAction(
    '[Team] Leave Team Fail',
    props<{ error: any }>(),
  );
}

export namespace DeleteTeam {
  export const Request = createAction(
    '[Team] Delete Team Request',
    props<{ teamId: number }>(),
  );
  export const Success = createAction(
    '[Team] Delete Team Success',
    props<{ teamId: number }>(),
  );
  export const Fail = createAction(
    '[Team] Delete Team Fail',
    props<{ error: any }>(),
  );
}

export const SetSelectedTeamMapUser = createAction(
  '[Users] Set Selected Team Map User',
  props<{ userAK: number }>(),
);

export const ResetIsErrorMessageToFalse = createAction(
  '[Users] Reset Is Error Message To False',
);

export namespace RequestToJoinTeam {
  export const Request = createAction(
    '[Team] Request To Join Available Team Request',
    props<{ team: Team }>(),
  );

  export const Success = createAction(
    '[Team] Request To Join Available Team Success',
    props<{ team: Team }>(),
  );

  export const Fail = createAction(
    '[Team] Request To Join Available Team Fail',
    props<{ error: any }>(),
  );
}

export namespace DeclineRequestToJoinTeam {
  export const Request = createAction(
    '[Team] Decline Request To Join Team Request',
    props<{ userAk: number; teamId: number }>(),
  );

  export const Success = createAction(
    '[Team] Decline Request To Join Team Success',
    props<{ userAk: number; teamId: number }>(),
  );

  export const Fail = createAction(
    '[Team] Decline Request To Join Team Fail',
    props<{ error: any }>(),
  );
}

export namespace CancelRequestToJoinTeam {
  export const Request = createAction(
    '[Team] Cancel Request To Join Team Request',
    props<{ team: Team }>(),
  );
  export const Success = createAction(
    '[Team] Cancel Request To Join Team Success',
    props<{ team: Team }>(),
  );
  export const Fail = createAction(
    '[Team] Cancel Request To Join Team Fail',
    props<{ error: any }>(),
  );
}

export namespace AcceptRequestToJoinTeam {
  export const Request = createAction(
    '[Team] Accept Request To Join Team Request',
    props<{ userAk: number; teamId: number }>(),
  );

  export const Success = createAction(
    '[Team] Accept Request To Join Team Success',
    props<{ team: Team }>(),
  );

  export const Fail = createAction(
    '[Team] Accept Request To Join Team Fail',
    props<{ error: any }>(),
  );
}

export const UpdateTeamWithJoinRequest = createAction(
  '[Team] Update Team With Join Request',
  props<{ teamId: number; userAk: number }>(),
);

export const UpdateTeamWithJoinRequestRevoked = createAction(
  '[Team] Update Team With Join Request Revoked',
  props<{ teamId: number; userAk: number }>(),
);

export const UpdateTeamWithJoinRequestAccepted = createAction(
  '[Team] Update Team With Join Request Accepted',
  props<{ team: Team }>(),
);

export const UpdateTeamWithJoinRequestDeclined = createAction(
  '[Team] Update Team With Join Request Declined',
  props<{ teamId: number }>(),
);

export namespace GetAllTeamsForAdmin {
  export const Request = createAction('[Team] Get All Teams For Admin Request');
  export const Success = createAction(
    '[Team] Get All Teams For Admin Success',
    props<{ teams: Team[] }>(),
  );

  export const Fail = createAction(
    '[Team] Get All Teams For Admin Fail',
    props<{ error: any }>(),
  );
}


export namespace UpdateTeamMembers {
  export const Request = createAction(
    '[Team] Update Team Members Request',
    props<{ teamId: number; adminIds: number[]; memberIds: number[] }>(),
  );

  export const Success = createAction(
    '[Team] Update Team Members Success',
    props<{ team: Team }>(),
  );

  export const Fail = createAction(
    '[Team] Update Team Members Fail',
    props<{ error: any }>(),
  );
}

export namespace UpdateTeamMechanics {
  export const Request = createAction(
    '[Team] Update Team Mechanics Request',
    props<{
      teamId: number;
      purposes: TeamMechanic[];
      foci: TeamMechanic[];
      principles: TeamMechanic[];
    }>(),
  );

  export const Success = createAction(
    '[Team] Update Team Mechanics Success',
    props<{
      teamId: number;
      purposes: TeamMechanic[];
      foci: TeamMechanic[];
      principles: TeamMechanic[];
    }>(),
  );

  export const Fail = createAction(
    '[Team] Update Team Mechanics Fail',
    props<{ error: any }>(),
  );
}

export namespace DownloadTeamMap {
  export const Request = createAction(
    '[Team] Download Team Map Request',
    props<{ teamId: number; teamName: string }>(),
  );
  export const Success = createAction(
    '[Team] Download Team Map Success',
    props<{ teamMap: any }>(),
  );
  export const Fail = createAction(
    '[Team] Download Team Map Fail',
    props<{ error: any }>(),
  );

  export const MapProcessingUpdate = createAction(
    '[Team] Team map processing has updated',
    props<{ teamMap: UpdateTeamMapStatusReq }>(),
  );

  export const GetTeamMapFromToken = createAction(
    '[Team] Download Team Map Success',
    props<{ token: string }>(),
  );
}

export const ResetInviteTeamMembersSuccess = createAction(
  '[Team] Reset Invite Team Members Success',
);

export const ResetSaveTeamMechanicsSuccess = createAction(
  '[Team] Reset Save Team Mechanics Success',
);

export const ResetSaveTeamSettingsSuccess = createAction(
  '[Team] Reset Save Team Settings Success',
);

export namespace GetAllTeams {
  export const Request = createAction('[Team] Get All Teams Request');
  export const Success = createAction(
    '[Team] Get All Teams Success',
    props<{ teams: Team[] }>(),
  );

  export const Fail = createAction(
    '[Team] Get All Teams',
    props<{ error: any }>(),
  );
}

export namespace GetTeam {
  export const Request = createAction(
    '[Team] Get Team Request',
    props<{ teamId: number }>(),
  );

  export const Success = createAction(
    '[Team] Get Team Success',
    props<{ team: Team }>(),
  );

  export const Fail = createAction(
    '[Team] Get Team Fail',
    props<{ error: any }>(),
  );
}

export const ToggleShowUploadTeamPhotoComponent = createAction(
  '[Team] Toggle Show Upload Team Photo Component',
  props<{ show: boolean }>(),
);

export const UpdateNewTeamForm = createAction(
  '[Team] Update New Team Form',
  props<{ newTeamForm?: CreateTeamModel }>(),
);

export namespace ChangeTeamPicture {
  export const Request = createAction(
    '[User] Change Team Picture Request',
    props<{ teamId: number; formData: FormData }>(),
  );

  export const Success = createAction(
    '[User] Change Team Picture Success',
    props<{ photoLastModified: string; teamId: number }>(),
  );

  export const Fail = createAction(
    '[User] Change Team Picture Fail',
    props<{ error: any }>(),
  );
}

export namespace UpdateTeamDefaultImageId {
  export const Request = createAction(
    '[Team] Update Team Default Image Id Request',
    props<{ teamId: number; newImageId: number }>(),
  );
  export const Success = createAction(
    '[Team] Update Team Default Image Id Success',
    props<{ teamId: number; newImageId: number }>(),
  );
  export const Fail = createAction(
    '[Team] Update Team Default Image Id Fail',
    props<{ error: any }>(),
  );
}
