<div class="modal-header">
  <h4 class="modal-title">Request Profile Access</h4>
</div>
<div class="modal-body">
  <p>Enter the email address of the person whose profile you want to request access to:</p>
  <div class="form-group">
    <label for="requesteeEmail">Email Address</label>
    <input type="email" class="form-control" id="requesteeEmail" [(ngModel)]="email" />
  </div>
  <div
    *ngIf="(response$ | async) as response" class="alert mt-1"
    [class.alert-success]="!response.isError"
    [class.alert-danger]="response.isError" role="alert">
    {{ response.message }}
  </div>
</div>
<div class="modal-footer">
  <button class="btn btn-outline-secondary" (click)="close()">Cancel</button>
  <button class="btn btn-secondary" (click)="requestAccess()">Send Request</button>
</div>
