import { DigitalDebriefMessages } from "../services/messaging.service";

export enum DebriefReadoutIds {
    MindsetIntro,
    MindsetPreferences,
    BehaviouralStyleIntro,
    BehaviouralStylePreferences,
    StrengthsIntro,
    TakeCaresIntro,
    TakeCaresDescription,
    WriggleRoomIntro,
    WriggleRoomSelection,
    WriggleRoomComplete
}

export enum FlexCopeType {
    COPE,
    FLEX
}

export interface DebriefReadout {
    id: DebriefReadoutIds;
    subPath: string;
    fileName?: string;
    flexCopeType?: FlexCopeType;
    buttonText?: string;
    isButtonEnabled: boolean;
    buttonClickMessage?: DigitalDebriefMessages;
    audioCompleteMessage?: DigitalDebriefMessages;
}