import { Routes } from '@angular/router';
import { AdminLiveAnalyticsComponent } from './admin-live-analytics/admin-live-analytics.component';
import { AdminComponent } from './admin.component';
import { EngagementResourcesComponent } from './engagement-resources/engagement-resources.component';
import { HelpCenterComponent } from './help-center/help-center.component';
import { SettingsComponent } from './settings/settings.component';
import { UserManageComponent } from './user-manage/user-manage.component';
import { ArticlePageComponent } from './help-center/category-page/article-page/article-page.component';
import { CategoryPageComponent } from './help-center/category-page/category-page.component';
import { path } from 'd3';
import { AccountSettingsComponent } from './account-settings/account-settings.component';
import { RolePermissionGuard } from '../auth/role-permission.guard';
import { AdminSettingsComponent } from './admin-settings/admin-settings.component';

export const adminRoutes: Routes = [
  {
    path: '',
    data: ['Admin'],
    component: AdminComponent,
    children: [
      {
        path: '',
        component: UserManageComponent,
        canActivate: [RolePermissionGuard],
        data: { rolesOrPermissions: ['USER_MANAGE'] },
      },
      {
        path: 'user-manage',
        component: UserManageComponent,
        canActivate: [RolePermissionGuard],
        data: { rolesOrPermissions: ['USER_MANAGE'] },
      },
      {
        path: 'live-analytics',
        component: AdminLiveAnalyticsComponent,
        canActivate: [RolePermissionGuard],
        data: { rolesOrPermissions: ['VIEW_ACC_ANALYTICS','VIEW_ORG_ANALYTICS'] },
      },
      {
        path: 'settings',
        component: SettingsComponent,
        canActivate: [RolePermissionGuard],
        data: { rolesOrPermissions: ['EDIT_ORG_SETTINGS', 'EDIT_ACC_SETTINGS'] },
      },
      {
        path: 'admin-settings',
        component: AdminSettingsComponent,
        canActivate: [RolePermissionGuard],
        data: { rolesOrPermissions: ['EDIT_ORG_SETTINGS', 'EDIT_ACC_SETTINGS'] },
      },
      {
        path: 'account-settings',
        component: AccountSettingsComponent,
        canActivate: [RolePermissionGuard],
        data: { rolesOrPermissions: ['EDIT_ORG_SETTINGS', 'EDIT_ACC_SETTINGS'] },
      },
      {
        path: 'help',
        component: HelpCenterComponent,
        canActivate: [RolePermissionGuard],
        data: { rolesOrPermissions: ['ACC_ADMIN'] },
      },
      {
        path: 'help/:category-title',
        component: CategoryPageComponent,
        canActivate: [RolePermissionGuard],
        data: { rolesOrPermissions: ['ACC_ADMIN'] },
      },
      {
        path: 'help/:category-title/:article-title',
        component: ArticlePageComponent,
        canActivate: [RolePermissionGuard],
        data: { rolesOrPermissions: ['ACC_ADMIN'] },
      },
      {
        path: '**',
        component: UserManageComponent,
        canActivate: [RolePermissionGuard],
        data: { rolesOrPermissions: ['USER_MANAGE'] },
      },

    ],
  },
];
