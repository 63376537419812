<div class="survey-container">
  <div class="shadow-container"></div>
  <div class="survey-page pb-3 bg-white pt-4">
    <div class="survey-content d-flex flex-column">
      <div class="d-flex pt-5 pb-4 align-items-center header w-100">
        <app-btn-action
          class="survey-close fa-lg top"
          [icon]="closeIcon"
          [iconDimensions]="35"
          (click)="close()"
        ></app-btn-action>
      </div>
      <div class="survey-item-container overflow-auto">
        <div *ngIf="!isSurveyCompleted" class="survey-content-container">
          <h2 class="my-1">Mindflick feedback</h2>
          <h3>Thanks for helping us improve Mindflick</h3>
          <form
            [formGroup]="fgPMF"
            class="d-flex flex-column h-100 mt-4 body-copy"
          >
            <p class="card-text mb-3 fw-bold">
              How disappointed would you be if you could no longer use
              Mindflick? *
            </p>
            <div class="form-check mb-3">
              <input
                class="form-check-input"
                type="radio"
                [value]="pmfAnswerEnum.VeryDisappointed"
                id="veryDisappointed"
                formControlName="pmfRating"
              />
              <label class="form-check-label" for="veryDisappointed">
                Very disappointed
              </label>
            </div>
            <div class="form-check mb-3">
              <input
                class="form-check-input"
                type="radio"
                [value]="pmfAnswerEnum.SomewhatDisappointed"
                id="somewhatDisappointed"
                formControlName="pmfRating"
              />
              <label class="form-check-label" for="somewhatDisappointed">
                Somewhat disappointed
              </label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                [value]="pmfAnswerEnum.NotDisappointed"
                id="notDisappointed"
                formControlName="pmfRating"
              />
              <label class="form-check-label" for="notDisappointed">
                Not disappointed
              </label>
            </div>

            <div class="d-flex flex-column mt-4">
              <p class="card-text mb-3 fw-bold">
                How likely are you to recommend Mindflick to a friend or
                colleague? *
              </p>
              <div class="d-flex justify-content-between nps">
                <button
                  class="btn"
                  [class]="{
                    'btn-secondary': npsRating.value! != 1,
                    'btn-purple': npsRating.value! == 1
                  }"
                  (click)="setNPS(1)"
                >
                  1
                </button>
                <button
                  class="btn"
                  [class]="{
                    'btn-secondary': npsRating.value! != 2,
                    'btn-purple': npsRating.value! == 2
                  }"
                  (click)="setNPS(2)"
                >
                  2
                </button>
                <button
                  class="btn"
                  [class]="{
                    'btn-secondary': npsRating.value! != 3,
                    'btn-purple': npsRating.value! == 3
                  }"
                  (click)="setNPS(3)"
                >
                  3
                </button>
                <button
                  class="btn"
                  [class]="{
                    'btn-secondary': npsRating.value! != 4,
                    'btn-purple': npsRating.value! == 4
                  }"
                  (click)="setNPS(4)"
                >
                  4
                </button>
                <button
                  class="btn"
                  [class]="{
                    'btn-secondary': npsRating.value! != 5,
                    'btn-purple': npsRating.value! == 5
                  }"
                  (click)="setNPS(5)"
                >
                  5
                </button>
                <button
                  class="btn"
                  [class]="{
                    'btn-secondary': npsRating.value! != 6,
                    'btn-purple': npsRating.value! == 6
                  }"
                  (click)="setNPS(6)"
                >
                  6
                </button>
                <button
                  class="btn"
                  [class]="{
                    'btn-secondary': npsRating.value! != 7,
                    'btn-purple': npsRating.value! == 7
                  }"
                  (click)="setNPS(7)"
                >
                  7
                </button>
                <button
                  class="btn"
                  [class]="{
                    'btn-secondary': npsRating.value! != 8,
                    'btn-purple': npsRating.value! == 8
                  }"
                  (click)="setNPS(8)"
                >
                  8
                </button>
                <button
                  class="btn"
                  [class]="{
                    'btn-secondary': npsRating.value! != 9,
                    'btn-purple': npsRating.value! == 9
                  }"
                  (click)="setNPS(9)"
                >
                  9
                </button>
                <button
                  class="btn"
                  [class]="{
                    'btn-secondary': npsRating.value! != 10,
                    'btn-purple': npsRating.value! == 10
                  }"
                  (click)="setNPS(10)"
                >
                  10
                </button>
              </div>
              <div class="d-flex justify-content-between nps-label">
                <label>Not at all likely</label>
                <label>Very likely</label>
              </div>
            </div>

            <div
              class="d-flex flex-column flex-grow-1 justify-content-end mt-4"
            >
              <p class="card-text mb-3 fw-bold" [@fade]="followUpQuestion">
                {{ followUpQuestion }}
              </p>
              <textarea
                class="form-control"
                rows="3"
                placeholder="Let us know your thoughts"
                [formControl]="followUp"
              ></textarea>
            </div>
            <button
              class="btn btn-purple align-self-start my-4"
              [disabled]="!fgPMF.valid"
              (click)="submit()"
            >
              Submit
            </button>
          </form>
        </div>

        <div *ngIf="isSurveyCompleted" class="survey-content-container">
          <h3>Thanks you for your feedback!</h3>
        </div>
      </div>
    </div>
  </div>
</div>
