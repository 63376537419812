<div class="position-relative">
  <div #search [id]="'test-search-'+placeholder">
    <app-search-input [formControl]="searchFilter" [placeholder]="placeholder" (click)="showResults();" [manualValue]="searchFilter.value"></app-search-input>
  </div>
  <div *ngIf="filteredOptions.length > 0 && isShowingResults"
       class="search-results"
       [style.width.px]="search.offsetWidth">
    <div [id]="'test-option-'+placeholder" *ngFor="let option of filteredOptions" class="py-2 ps-3 option" (click)="selectOption(option);">
      <h3>{{option}}</h3>
    </div>
  </div>
  <div *ngIf="filteredOptions.length == 0 && isShowingResults" class="search-results" [style.width.px]="search.offsetWidth" [id]="'test-adding-'+placeholder">
    <h3 class="py-2 ps-3"><fa-icon [icon]="plus"></fa-icon>Adding new {{newOptionPlaceholder}}</h3>
  </div>
</div>
