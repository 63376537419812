export enum AuditEventType {
  PageVisited,
  ConnectionMade,
  TeamCreated,
  TeamDeleted,
  TeamMemberJoined,
  WorkOnViewed,
  WorkOnAdded,
  PlaybookExerciseViewed,
  ProfileViewed,
  PlaybookResourceDownloaded,
  CreateTeamClicked,
  DiscardTeamClicked,
  DeleteTeamClicked,
  AppLoaded,
  WorkOnNudged,
}

// keep in same order, append new
export enum PlatformAreas {
  Dashboard,
  OwnProfile,
  Connections,
  Teams,
  Growth,
  Notifications,
  WorkOns,
  Playbooks,
  App,
  OtherProfile,
}
