<div class="d-flex full-page-container">
  <img class="bg-logo" src="./assets/svg/Mindflick_Monogram_Colour_RGB.svg" />
  <img
    class="bg-pattern"
    src="./assets/svg/nudge-patterns/pages-bg-pattern.svg"
  />
  <div class="col-6 left-container">
    <img class="logo" src="./assets/images/logos/mindflick.svg" />
    <div class="welcome-back">
      <h1 class="text-white">WELCOME</h1>
      <h1 class="text-white">BACK</h1>
    </div>
  </div>
  <div class="bg-dark col-6 d-flex flex-column account-container">
    <h2 class="text-white">Choose account</h2>
    <h3 class="mt-2 text-white">You're a user on two different apps.</h3>
    <h3 class="text-white">Select the account you wish to use.</h3>
    <div
      class="mt-5 px-3 py-4 d-flex justify-content-between align-items-center account-option cursor-pointer"
      id="select-mindflick"
      (click)="continueUserFlow()"
    >
      <img class="account-logo" src="./assets/images/logos/mindflick.svg" />
      <app-btn-tertiary [light]="true" [icon]="faNext"
        >Mindflick account</app-btn-tertiary
      >
    </div>
    <div
      class="mt-3 px-3 py-4 d-flex justify-content-between align-items-center account-option cursor-pointer"
      id="select-spotlight"
      (click)="goToSpotlight()"
    >
      <img
        class="account-logo"
        src="./assets/images/logos/spotlight-white.svg"
      />
      <app-btn-tertiary [light]="true" [icon]="faNext"
        >Spotlight account</app-btn-tertiary
      >
    </div>
  </div>
</div>
