import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { take } from 'rxjs';
import { AppState } from 'src/app/+state/app.state';
import { SetMindflickOrganisationLead } from 'src/app/+state/super-admin/super-admin.actions';
import { selectMindflickPractitioners } from 'src/app/+state/super-admin/super-admin.selector';
import { SelectValue } from 'src/app/shared/models';
import { faCloseSVG } from 'src/icons';

@Component({
  selector: 'app-edit-organisation-lead-modal',
  templateUrl: './edit-organisation-lead-modal.component.html',
  styleUrls: ['./edit-organisation-lead-modal.component.scss']
})
export class EditOrganisationLeadModalComponent implements OnInit {
  mindflickPractitioners$ = this.store.select(selectMindflickPractitioners);

  mindflickOrgId: number = 0;

  mindflickUsers: SelectValue[] = [];
  selectedUserAk: number = 0;

  closeIcon = faCloseSVG;

  constructor(private store: Store<AppState>, private modalRef: BsModalRef) {}

  ngOnInit(): void {
    this.mindflickPractitioners$
      .pipe(take(1))
      .subscribe((practitioners) => {
        this.mindflickUsers = practitioners.map((x) => {
          return {
            value: x.ak,
            description: x.name,
          } as SelectValue;
        });
      });
  }

  close() {
    this.modalRef.hide();
  }

  saveChanges() {
    if (this.mindflickOrgId && this.selectedUserAk)
      this.store.dispatch(SetMindflickOrganisationLead.Request({ organisationId: this.mindflickOrgId, userAk: this.selectedUserAk }));
  }
}
