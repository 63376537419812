<form>
  <div class="input-container" [class.hideInput]="!showInput">
    <input #searchInput
           type="text"
           aria-label="Search in docs" name="search"
           placeholder="Search..."
           [value]="search.text"
           (keyup)='search.text = searchInput.value'
           (keypress)='preventReloading($event)' />
  </div>
</form>

<!--<ul class="sidebar-list menu" *ngIf="search.text && routes.length" data-cypress="search-result">
  <ng-container *ngFor="let route of routes | slice:4 ">
    <li *ngIf="route.path"
        [routerLinkActive]="['active']"
        [routerLinkActiveOptions]="{exact: true}"
        class="w-100"
        (click)="search.text = ''">
      <a [routerLink]="getRouteLink(route.path)" class="text-white d-block w-100">{{route.data[0]}}</a>
    </li>
  </ng-container>
</ul>-->
