<div class="position-relative user-item body-copy px-1 d-flex align-items-center"
     [class.user-item-hover]="user.isUserActive"
     [class.selected]="isSelected"
     (mouseenter)="setIconsVisibility(true)"
     (mouseleave)="setIconsVisibility(false)"
     (click)="selectUser()">
  <div class="flex-fill row gx-0" [class.isInactive]="!user.isUserActive">

    <div class="col-width-250 d-flex align-items-center">
      <input class="form-check-input mt-0 me-2"
             type="checkbox"
             [checked]="isSelected"
             [disabled]="!user.isUserActive" />
      <fa-icon *ngIf="user.isMindflickChampion"
               class="fw-bolder fa-xl icon px-1 align-middle crown"
               [icon]="champion"></fa-icon>
      <fa-icon *ngIf="user.isAccountAdmin"
               class="fw-bolder fa-xl icon px-1 align-middle spanner"
               [class.text-dark]="!user.isUserActive"
               [icon]="admin"></fa-icon>
      <div class="break-word" [tooltip]="user.name">
        {{ user.name }}
      </div>
    </div>
    <div class="col-width-250 d-flex align-items-center">
      <div class="break-word" [tooltip]="user.email">
        {{ user.email }}
      </div>
    </div>
    <div class="col d-flex align-items-center">
      {{ user.createdDate | date : "dd/MM/yy" }}
    </div>
    <div class="col d-flex align-items-center">
      {{ user.department ? user.department : "n/a" }}
    </div>
    <div class="col d-flex align-items-center text-truncate" (click)="openNotesModal(user)">
      {{ user.notes }}
    </div>
    <div class="col-width-100 col d-flex align-items-center justify-content-center">
      <div *ngIf="user.portalStatus == PlatformInviteStatus.None && user.isUserActive"
           class="text-center">
        -
      </div>
      <div *ngIf="!user.isUserActive"
           class="text-center">
        <div class="deactivated fit-item py-1 ms-auto me-auto">Deactivated</div>
      </div>
      <div *ngIf="user.portalStatus == PlatformInviteStatus.Invited && user.isUserActive"
           class="text-center">
        <div class="invited fit-item py-1 ms-auto me-auto">Invited</div>
      </div>
      <div *ngIf="user.portalStatus == PlatformInviteStatus.SpotlightInProgress && user.isUserActive"
           class="text-center">
        <div class="in-progress fit-item py-1 ms-auto me-auto"
             tooltip="Spotlight in progress">
          {{ user.spotlightCompletion }}%
        </div>
      </div>
      <div *ngIf="user.portalStatus == PlatformInviteStatus.Locked && user.isUserActive"
           class="text-center">
        <fa-icon class="fw-bolder fa-l icon px-1 align-middle lock"
                 [icon]="['fas', 'lock']"
                 [tooltip]="unlockTooltip"
                 (click)="openEditDebriefDateModal(user)"></fa-icon>
        <fa-icon *ngIf="user.userJoinedApp"
                 [icon]="['fas', 'mobile-screen']"></fa-icon>
        <ng-template #unlockTooltip>
          <ng-container *ngIf="user.debriefDate">
            This user will be unlocked on
            {{ user.debriefDate | date : "dd MMM yyyy" }}
          </ng-container>
          <ng-container *ngIf="!user.debriefDate">This user is locked</ng-container>
        </ng-template>
      </div>
      <div *ngIf="user.portalStatus == PlatformInviteStatus.Completed  && user.isUserActive"
           class="text-center">
        <fa-icon class="fw-bolder fa-xl" [icon]="tick"></fa-icon>
        <fa-icon *ngIf="user.userJoinedApp"
                 [icon]="['fas', 'mobile-screen']"></fa-icon>
      </div>
    </div>
    <div *isGranted="'ALLOCATE_SEATS'; forAccount: (selectedAccountId$ | async) ?? 0"
         class="col-width-100 col d-flex align-items-center justify-content-center text-center">
      <ng-container *ngIf="user.delegatedInvites; else noInvites">
        {{ user.delegatedInvitesUsed }}/{{ user.delegatedInvites }}
      </ng-container>
      <ng-template #noInvites>-</ng-template>
    </div>
  </div>
  <div class="action-space">
    <div class="btn-group" dropdown *ngIf="isIconsVisible">
      <app-btn-action [iconDimensions]="30"
                      [icon]="edit"
                      [secondary]="true"
                      [primary]="true"
                      [noBorder]="true"
                      tooltip="Edit details"
                      *isGranted="'EDIT_USER'; forAccount: (selectedAccountId$ | async) ?? 0"
                      (actionClick)="$event.stopPropagation();editUser(user)"></app-btn-action>
      <app-btn-action [id]="'button_' + user.ak"
                      dropdownToggle
                      type="button"
                      [attr.aria-controls]="'dropdown_' + user.ak"
                      [iconDimensions]="30"
                      [secondary]="true"
                      [icon]="['fas', 'ellipsis-vertical']"
                      [noBorder]="true"
                      [primary]="true"
                      (click)="$event.stopPropagation()">
      </app-btn-action>
      <ul [id]="'dropdown_' + user.ak"
          *dropdownMenu
          class="dropdown-menu"
          [class.dropdown-menu-down]="!showDropUp"
          [class.dropdown-menu-up]="showDropUp"
          role="menu"
          [attr.aria-labelledby]="'button_' + user.ak">
        <li *ngIf="user.isLocked" role="menuitem">
          <a *isGranted="'UNLOCK_USER'; forAccount: (selectedAccountId$ | async) ?? 0" class="dropdown-item body-copy" (click)="unlockUser(user.ak)">Unlock</a>
        </li>
        <li *ngIf="
            (user.portalStatus == PlatformInviteStatus.Completed ||
              user.portalStatus == PlatformInviteStatus.Locked ||
              user.flexCope)
          "
            role="menuitem">
          <a *isGranted="'DOWNLOAD_PDF_PROFILES'; forAccount: (selectedAccountId$ | async) ?? 0" class="dropdown-item body-copy"
             (click)="downloadUserProfile(user.ak, user.name)">Download profile</a>
        </li>
        <li *ngIf="
            (user.portalStatus == PlatformInviteStatus.Invited ||
              user.portalStatus == PlatformInviteStatus.SpotlightInProgress) &&
            user.isUserActive
          "
            role="menuitem">
          <a class="dropdown-item body-copy"
             (click)="individualGetSpotlightLink(user)">Get invite link</a>
        </li>
        <li *ngIf="
            user.portalStatus == PlatformInviteStatus.Invited &&
            user.isUserActive
          "
            role="menuitem">
          <a class="dropdown-item body-copy"
             (click)="individualResendSpotlight(user.ak)">Resend invite</a>
        </li>
        <ng-container *isGranted="'ALLOCATE_SEATS'; forAccount: (selectedAccountId$ | async) ?? 0">
          <li *ngIf="
              user.isUserActive
            "
              role="menuitem">
            <a  class="dropdown-item body-copy"
               (click)="openManageDelegatedInvitesModal(user)">Manage allocated seats</a>
          </li>
          <li *ngIf="
              user.isUserActive &&
              user.delegatedInvites>
            user.delegatedInvitesUsed
            "
            role="menuitem"
            >
            <a class="dropdown-item body-copy"
               (click)="revokeUnusedInvites(user.ak)">Revoke unused seats</a>
          </li>
        </ng-container>
        <li role="menuitem">
          <a class="dropdown-item body-copy"
            (click)="openNotesModal(user)">Add notes</a>
        </li>
        <li *ngIf="user.isUserActive" role="menuitem">
          <a *isGranted="'REMOVE_USER'; forAccount: (selectedAccountId$ | async) ?? 0" class="dropdown-item body-copy"
             (click)="deactivateSingleUser(user.ak, user.name)">Delete</a>
        </li>
      </ul>
    </div>
  </div>
</div>
