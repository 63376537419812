import {
  AfterContentInit,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  inject,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { OidcSecurityService, UserDataResult } from 'angular-auth-oidc-client';
import { filter, take } from 'rxjs/operators';
import { AppState } from './+state/app.state';
import * as StatusActions from './+state/status/status.actions';
import * as UserActions from './+state/user/user.actions';

import { animate, style, transition, trigger } from '@angular/animations';
import {
  ActivatedRoute,
  NavigationEnd,
  NavigationStart,
  Router,
  Scroll,
} from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CookieService } from 'ngx-cookie-service';
import { Subscription, combineLatest, interval } from 'rxjs';
import { faCloseSVG } from '../icons';
import { GetMindflickAccountSettings, GetMindflickOrganisation } from './+state/account-admin/account-admin.actions';
import { selectMindflickOrganisationSettings, selectSelectedUserAkAdmin } from './+state/account-admin/account-admin.selector';
import { selectUserAccounts } from './+state/account/account.selector';
import { SaveEvents } from './+state/audit/audit.actions';
import { selectAuditEventsLength } from './+state/audit/audit.selector';
import {
  selectCarouselHeading,
  selectDashboardNudge,
  selectPreviousNudge,
} from './+state/growth/growth.selector';
import {
  SetSubMenuSmallPopout,
  ShowChampionCharter,
  setTitleBar,
} from './+state/layout/actions/layout.actions';
import { SubMenuSmallPopoutEnum } from './+state/layout/reducers/layout.reducers';
import {
  selectIsDashboardLoading,
  selectRequiresExternalAuthentication,
  selectShowAccountAdminList,
  selectShowChampionCharter,
  selectShowDataPolicy,
  selectShowDownloads,
  selectShowGrowthDetails,
  selectShowGrowthList,
  selectShowNotifications,
  selectShowNudgeReflection,
  selectShowPrivacy,
  selectShowSuperAdminList,
  selectShowTerms,
  selectShowTooltip,
  selectSnackbarAlert,
  selectTitleBarVisible,
  selectUploadImageError,
  selectUserIsLocked,
} from './+state/layout/selectors/layout.selector';
import {
  selectCurrentPageTitle,
  selectIsPageSelected,
} from './+state/status/status.selector';
import { selectSelectedMindflickOrganisationId } from './+state/super-admin/super-admin.selector';
import { selectCurrentPageId } from './+state/survey/selectors/survey.selector';
import {
  selectCookieSettings,
  selectCurrentUser,
  selectIsPulseSurveyDue,
} from './+state/user/user.selector';
import {
  NudgeReviewPromptEnum,
  ReflectEnum,
} from './shared/models/nudge-dto.interface';
import { Insights } from './shared/services/insights.service';
import { SignalrService } from './shared/services/signalr.service';
import { ShepherdService } from 'angular-shepherd';
import { environment } from '../environments/environment';
import { offset } from '@floating-ui/dom';
import { addProgressToStep } from './shared/helpers/onboarding-footer.helpers';
import { OnboardingStepsService } from './shared/services/onboarding-steps.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('inAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('1s ease-out', style({ opacity: 1 })),
      ]),
    ]),
    trigger('outAnimation', [
      transition(':leave', [
        style({ opacity: 1 }),
        animate('1s ease-in', style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class AppComponent implements OnInit, OnDestroy, AfterContentInit {
  route = inject(ActivatedRoute);
  router = inject(Router);
  oidcSecurityService = inject(OidcSecurityService);
  store = inject(Store<AppState>);
  modalService = inject(BsModalService);
  signalRService = inject(SignalrService);
  applicationInsights = inject(Insights);
  cookieService = inject(CookieService);
  shepherdService = inject(ShepherdService);
  onboardingService = inject(OnboardingStepsService);

  @ViewChild('mainPage') scrollableElement?: ElementRef;

  @ViewChild('uploadImageErrorTemplate')
  uploadImageErrorTemplate?: TemplateRef<any>;
  uploadImageErrorModalRef?: BsModalRef;

  @HostListener('document:click', ['$event'])
  clickHandler(e: MouseEvent) {
    this.createAccountCookie();
    this.trackNewSession();
  }

  @HostListener('document:mousemove', ['$event'])
  moveMouse(e: MouseEvent) {
    this.createAccountCookie();
    this.trackNewSession();
  }

  showTitleBar$ = this.store.select(selectTitleBarVisible);

  currentPageTitle$ = this.store.select(selectCurrentPageTitle);
  isPageSelected$ = this.store.select(selectIsPageSelected);
  currentPage$ = this.store.select(selectCurrentPageId);
  currentUser$ = this.store.select(selectCurrentUser);

  showGrowthListToggle$ = this.store.select(selectShowGrowthList);
  showGrowthDetails$ = this.store.select(selectShowGrowthDetails);
  showAccountAdminListToggle$ = this.store.select(selectShowAccountAdminList);
  showSuperAdminListToggle$ = this.store.select(selectShowSuperAdminList);
  showNotifications$ = this.store.select(selectShowNotifications);
  showDownloads$ = this.store.select(selectShowDownloads);
  showNudgeReflection$ = this.store.select(selectShowNudgeReflection);

  showDataPolicyShowing$ = this.store.select(selectShowDataPolicy);
  showTermsPolicyShowing$ = this.store.select(selectShowTerms);
  showPrivacyPolicyShowing$ = this.store.select(selectShowPrivacy);

  adminSelectedUserAk$ = this.store.select(selectSelectedUserAkAdmin);
  selecteMindflickOrganisationId$ = this.store.select(selectSelectedMindflickOrganisationId);

  mindflickOrgSettings$ = this.store.select(selectMindflickOrganisationSettings);
  cookieSettings$ = this.store.select(selectCookieSettings);

  showTopMenu: boolean = true;
  checkedFirstLogin: boolean = false;
  isShowingTooltips$ = this.store.select(selectShowTooltip);

  uploadImageError$ = this.store.select(selectUploadImageError);

  growthHeading$ = this.store.select(selectCarouselHeading);

  isDashboardLoading$ = this.store.select(selectIsDashboardLoading);

  mindflickAccounts$ = this.store.select(selectUserAccounts);

  isSnackbarAlertShowing$ = this.store.select(selectSnackbarAlert);

  userIsLocked$ = this.store.select(selectUserIsLocked);

  requiresExternalAuthentication$ = this.store.select(selectRequiresExternalAuthentication);

  isPulseSurveyDue$ = this.store.select(selectIsPulseSurveyDue);

  nudge$ = this.store.select(selectDashboardNudge);
  previousNudge$ = this.store.select(selectPreviousNudge);

  showChampionCharter$ = this.store.select(selectShowChampionCharter);

  title = 'Mindflick Platform';
  showSidebar = true;

  subs = new Subscription();
  userInitSubscription?: Subscription;

  showTeamList: boolean = false;
  showGrowthList: boolean = false;

  close = faCloseSVG;

  accountSessionsDuration = 0;

  showCookieBanner = false;

  showAppBanner = false;
  isiPhone = false;
  isAndroid = false;

  isOnSurvey = false;

  constructor() {
    this.subs.add(
      this.router.events
        .pipe(filter((e: any): e is Scroll => e instanceof Scroll))
        .subscribe((e) => {
          this.scrollableElement?.nativeElement.scrollTo({
            top: 0,
            left: 0,
            behaviour: 'smooth',
          });

          this.isOnSurvey = e.routerEvent.url.includes("survey");

          this.store.dispatch(
            SetSubMenuSmallPopout({
              subMenuSmallPopout: SubMenuSmallPopoutEnum.None,
            })
          );
        })
    );

    combineLatest([
      this.oidcSecurityService.isAuthenticated$,
      this.router.events,
    ])
      .pipe(
        filter(
          ([isAuthorized, event]) =>
            !isAuthorized.isAuthenticated && event instanceof NavigationStart
        )
      )
      .subscribe(([isAuthorized, event]) => {
        var url = (event as NavigationStart).url;
        this.isOnSurvey = url.includes('survey');
        var redirectUrl = localStorage.getItem(environment.localStoragePrefix + 'redirect-url');
        if (url != '/' && url != '/account/switch' && !url.startsWith("/callback")) {
          localStorage.setItem(environment.localStoragePrefix + 'redirect-url', url);
        } else if (!redirectUrl) {
          localStorage.setItem(environment.localStoragePrefix + 'redirect-url', '/dashboard');
        }
      });

    setInterval(() => {
      if (!this.cookieService.get(environment.localStoragePrefix + 'accountNewSession')) {
        this.logout();
      }
    }, 60000);
  }


  ngOnInit() {
    this.isAndroid = !!navigator.userAgent.match(/Android/i);
    this.isiPhone = !!navigator.userAgent.match(/iPhone/i);

    const isSurvey = window.location.href.includes('/survey');
    const isDebrief = window.location.href.includes('/debrief');

    this.showAppBanner = (this.isAndroid || this.isiPhone) && !isSurvey && !isDebrief;

    this.store.dispatch(setTitleBar({ visible: true }));

    const selectedMindflickAccount = localStorage.getItem(
      environment.localStoragePrefix + 'selected-mindflick-account'
    );

    this.subs.add(
      this.mindflickOrgSettings$.subscribe((settings) => {
        if (settings?.sessionDurationMins) {
          this.accountSessionsDuration = settings?.sessionDurationMins;
          this.createAccountCookie();
        }
      })
    );

    this.subs.add(
      combineLatest([this.nudge$, this.previousNudge$]).subscribe(
        ([nudge, previousNudge]) => {
          var dayOfWeek = new Date().getDay();
          var SUNDAY = 0;
          var FRIDAY = 5;
          var isPreviousNudge = dayOfWeek < FRIDAY && dayOfWeek > SUNDAY;

          if (
            !isDebrief &&
            (nudge &&
              nudge.primaryReflection == ReflectEnum.Unknown &&
              !nudge.disableReflections &&
              !isPreviousNudge) ||
            (previousNudge &&
              !previousNudge.disableReflections &&
              previousNudge.nudgeReviewPrompt ==
              NudgeReviewPromptEnum.Unopened &&
              isPreviousNudge
            )
          ) {
            this.store.dispatch(
              SetSubMenuSmallPopout({
                subMenuSmallPopout: SubMenuSmallPopoutEnum.NudgeReflection,
              })
            );
          } else {
            this.store.dispatch(
              SetSubMenuSmallPopout({
                subMenuSmallPopout: SubMenuSmallPopoutEnum.None,
              })
            );
          }
        }
      )
    );

    this.subs.add(
      this.showGrowthListToggle$.subscribe((growthList) => {
        this.showGrowthList = growthList;
      })
    );

    if (selectedMindflickAccount) {
      this.userInitSubscription = this.oidcSecurityService.userData$.subscribe(
        (user) => {
          /* This call is required to handle the callback from the login process */
          if (user != null && user.userData != null) {
            // Tell Application insights about the current user
            this.applicationInsights.appInsights.setAuthenticatedUserContext(
              user.userData.sub,
              '',
              true
            );

            // Avoid hammering the server with multiple requests at once. There is a chance that it would overload the DB / Server
            // Make an initial request to get the current user's details
            // It's OK to request a few things at once but it is likely that this list will expand over time
            // so break up the requests into groups of max 4 and then add any more to the success effect on one of the 4 and so on
            if (!this.isOnSurvey) {


              if (!this.isOnSurvey) this.store.dispatch(UserActions.GetHasBothProducts.Request());

              // See the user.actions for GetUser.Success to see what get's requested next

              this.store.dispatch(GetMindflickOrganisation.Request());
              this.store.dispatch(GetMindflickAccountSettings.Request({ accId: 0 }));

              // Get me some cookie settings
              this.store.dispatch(UserActions.GetUserCookieSettings.Request());
            }

            this.signalRService.startConnection();

            // We no longer need this subscription so unsubscribe to avoid requesting the current user's details again
            this.userInitSubscription?.unsubscribe();
          }
        }
      );

      this.subs.add(
        this.cookieSettings$.subscribe((settings) => {
          if (settings) {
            var today = new Date();
            var oneYearAgo = new Date(
              today.getTime() - 365 * 24 * 60 * 60 * 1000
            );
            if (settings.acknowledgedDate < oneYearAgo && !this.isOnSurvey) {
              this.showCookieBanner = true;
            } else {
              this.showCookieBanner = false;
            }
          }
        })
      );

      this.subs.add(
        this.store.select(selectUploadImageError).subscribe((error) => {
          if (error) {
            // Show a modal to explain there was a problem uploading an image
            // This is here rather than on the uploading control to make it reusable
            if (this.uploadImageErrorTemplate)
              this.uploadImageErrorModalRef = this.modalService.show(
                this.uploadImageErrorTemplate
              );
          }
        })
      );

      // Start audit
      this.subs.add(
        // Every 10 seconds send request to save audit to API
        combineLatest([
          this.store.select(selectAuditEventsLength),
          interval(1000 * 10),
        ]).subscribe(([auditEventsLength, itteration]) => {
          if (auditEventsLength > 0) this.store.dispatch(SaveEvents.Request());
        })
      );
    } else {
      this.oidcSecurityService.userData$
        .pipe(
          filter((x) => !!x && !!x.userData),
          take(1)
        )
        .subscribe((user: UserDataResult) => {
          //check to see if the user has both products which will trigger a redirect  The GetHasBothProducts effect will check to see if the user has multiple mindflick accounts
          if (!this.isOnSurvey) this.store.dispatch(UserActions.GetHasBothProducts.Request());
        });


      //selector for has both products - combine with below and if has both products go to that page first
      this.mindflickAccounts$
        .pipe(
          filter((x) => !!x && x.length > 0),
          take(1)
        )
        .subscribe((accounts) => {
          if (!accounts.length) return;

          if (accounts.length > 1) {
            this.router.navigate(['/account/switch']);
          } else {
            localStorage.setItem(
              environment.localStoragePrefix + 'selected-mindflick-account',
              accounts[0].id.toString()
            );
            window.location.reload();
          }
        });
    }

    this.subs.add(
      this.currentUser$.subscribe((currentUser) => {
        if (currentUser && !currentUser.hasAcceptedChampionCharter) {
          this.store.dispatch(ShowChampionCharter());
        }
      })
    );

    this.subs.add(
      this.oidcSecurityService.checkAuth()
        .subscribe(({ isAuthenticated, userData, accessToken, idToken, configId }) => {})
    );

  }

  createAccountCookie() {
    if (this.accountSessionsDuration > 0) {
      var date = new Date();
      date = new Date(
        date.getTime() + this.accountSessionsDuration * 60 * 1000
      );
      this.cookieService.set(environment.localStoragePrefix + 'accountNewSession', 'true', { expires: date });
    }
  }

  ngOnDestroy() {
    this.store.dispatch(SaveEvents.Request());
    this.subs.unsubscribe();
  }

  /* Cribbed from https://github.com/valor-software/ngx-bootstrap/blob/bd81f14ca80fbdd5a845f828496f2ebe1efa4981/apps/ngx-bootstrap-docs/src/app/app.component.ts */
  ngAfterContentInit(): any {
    const getUrl = (router: Router) =>
      router.routerState.snapshot.url.slice(
        0,
        router.routerState.snapshot.url.indexOf('#')
      );
    let _prev = getUrl(this.router);
    const justDoIt = (): void => {
      const _cur = getUrl(this.router);
      //this.showSidebar = !!getUrl(this.router);

      const hash = this.route.snapshot.fragment;
      if (hash) {
        const target: HTMLElement | null = document.getElementById(hash);
        const header: HTMLElement | null = document.getElementById('header');
        if (target && header) {
          setTimeout(() => {
            const sidebar: HTMLElement | null =
              document.getElementById('sidebar');
            const targetPosY: number =
              innerWidth <= 991
                ? target.offsetTop -
                header.offsetHeight -
                6 -
                (sidebar?.offsetHeight || 0)
                : target.offsetTop - header.offsetHeight - 6;
            window.scrollTo({ top: targetPosY, behavior: 'smooth' });
          }, 100);
        }
      } else {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    };
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => setTimeout(() => justDoIt(), 50));
  }

  ngAfterViewInit() {
    const updateCurrentStep = (id: string) => {
      this.onboardingService.currentStepId$.next(id);
    }
    this.shepherdService.defaultStepOptions = {
      floatingUIOptions: {
        middleware: [offset({ mainAxis: 50, crossAxis: 0 })]
      },
      modalOverlayOpeningRadius: 5,
      modalOverlayOpeningPadding: 25,
      scrollTo: { behavior: 'smooth', block: 'center' },
      when: {
        show() {
          addProgressToStep(this);
          updateCurrentStep(this.id);
        },
      }
    };
    this.shepherdService.modal = true;
    this.shepherdService.confirmCancel = false;
    this.shepherdService.exitOnEsc = false;
  }

  trackNewSession() {
    if (!this.cookieService.check(environment.localStoragePrefix + 'newSession')) {
      var date = new Date();
      date = new Date(date.getTime() + 30 * 60000);
      this.cookieService.set(environment.localStoragePrefix + 'newSession', 'true', { expires: date });
    }
  }

  logout() {
    localStorage.clear();
    this.oidcSecurityService.logoff().subscribe(() => {});
  }

  closePage() {
    this.router.navigate(['/']);
    this.store.dispatch(
      StatusActions.SetPageSelected({ isPageSelected: false })
    );
  }

  closeAppBanner() {
    this.showAppBanner = false;
  }

  goToAppStore() {
    if (this.isiPhone) {
      window.open(
        'https://apps.apple.com/gb/app/mindflick/id1644319413',
        '_blank'
      );
    } else if (this.isAndroid) {
      window.open(
        'https://play.google.com/store/apps/details?id=com.mindflick.platform.mobile',
        '_blank'
      );
    } else {
      this.closeAppBanner();
    }
  }
}
