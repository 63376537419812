import {
  Component,
  OnDestroy,
  OnInit
} from '@angular/core';
import { Store } from '@ngrx/store';
import { LegendPosition, ScaleType } from '@swimlane/ngx-charts';
import { Subject, takeUntil } from 'rxjs';
import { AppState } from 'src/app/+state/app.state';
import { MindflickAccountBasicDetails, SelectValue } from 'src/app/shared/models';
import {
  GetConnectionsLiveAnalytics,
  GetInteractionLiveAnalytics,
  GetNudgeAndWorkOnLiveAnalytics,
  GetOnboardingLiveAnalytics,
  GetTeamsLiveAnalytics,
  GetUsageLiveAnalytics,
  GetUserAccountsWithLiveAnalyticsPermission,
  SetLiveAnalyticsSelectedAccountId
} from '../../+state/account-admin/account-admin.actions';
import { selectLiveAnalyticsData, selectLiveAnalyticsMindflickAccounts, selectSelectedLiveAnalyticsAccount } from '../../+state/account-admin/account-admin.selector';
import { LiveAnalyticsSection } from '../../shared/models/enums/live-analytics-section.enum';
import { DateRange } from '../../shared/models/date-range.interface';
import { LiveAnalyticsGraph } from '../../shared/models/live-analytics/live-analytics-graph.interface';
import { faDropdownSVG } from 'src/icons';

@Component({
  selector: 'app-admin-live-analytics',
  templateUrl: './admin-live-analytics.component.html',
  styleUrls: ['./admin-live-analytics.component.scss'],
})
export class AdminLiveAnalyticsComponent implements OnInit, OnDestroy {

  destroyed$ = new Subject<boolean>();

  liveAnalyticsData$ = this.store.select(selectLiveAnalyticsData);
  mindflickAccounts$ = this.store.select(selectLiveAnalyticsMindflickAccounts);
  selectedAccount$ = this.store.select(selectSelectedLiveAnalyticsAccount);

 

  accounts: SelectValue[] = [];
  selectedAccount?: MindflickAccountBasicDetails;
  selectedAccountId: number = 0;
  CUSTOM_RANGE_VALUE = 999;
  ALL_TIME_VALUE = -999;
  MIN_ALL_TIME_DATE = new Date(2022, 6, 1);

  dateRange: DateRange = {
    fromDate: this.MIN_ALL_TIME_DATE,
    toDate: new Date()
  };

  dateFilterOptions: SelectValue[] = [
    {
      value: 0,
      description: 'Current month',
    },
    {
      value: -1,
      description: 'Previous month',
    },
    {
      value: -3,
      description: 'Previous 3 months',
    },
    {
      value: this.CUSTOM_RANGE_VALUE,
      description: 'Custom date range',
    },
    {
      value: this.ALL_TIME_VALUE,
      description: 'All time',
    },
  ];

  selectedDateFilterOption: number = this.ALL_TIME_VALUE;

  today = new Date();
  customDateTo = new Date();
  customDateFrom = new Date();

  LegendPosition = LegendPosition;
  ScaleType = ScaleType;

  onboardingGraphs: LiveAnalyticsGraph[] = [];
  isDateDropdownReadonly = true;
  sectionType = LiveAnalyticsSection;
  isLoading = true;

  latestTab = LiveAnalyticsSection.Onboarding;

  dropdownIcon = faDropdownSVG;

  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    this.store.dispatch(GetUserAccountsWithLiveAnalyticsPermission.Request());

    this.customDateTo.setHours(0, 0, 0, 0);
    this.customDateFrom = new Date(this.customDateTo);
    this.customDateFrom.setDate(this.customDateFrom.getDate() - 30);

    this.mindflickAccounts$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((accounts) => {
        this.accounts = accounts.map((x) => {
          return { value: x.accountId, description: x.name };
        });
      });

    this.selectedAccount$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((account) => {
        if (account) {
          this.selectedAccountId = account.accountId;
          this.selectedAccount = account;
          this.getLiveAnalyticsData(this.dateRange.fromDate, this.dateRange.toDate);
        }
      });

    this.liveAnalyticsData$.pipe(takeUntil(this.destroyed$)).subscribe(data => {
      this.onboardingGraphs = data.map(graph => {
        return {
          ...graph,
          percentageValue: [{ name: graph.title, value: graph.value ?? 0 }]
        };
      });
      setTimeout(() => {
        this.isLoading = false;
      }, 1000);
    });
  }

  onDateSelectionChanged(months: number) {
    if (months == null || months == this.CUSTOM_RANGE_VALUE) return;

    var fromDate = this.setFromDate(months);
    var toDate = this.setToDate(months);

    this.getLiveAnalyticsData(fromDate, toDate);
  }

  setFromDate(months: number) {
    var today = new Date();

    if (months == this.ALL_TIME_VALUE) {
      return this.MIN_ALL_TIME_DATE;
    }

    var fromDate = new Date(today.getFullYear(), today.getMonth() + months, 1);

    return fromDate;
  }

  setToDate(months: number) {
    var today = new Date();

    if (months == this.ALL_TIME_VALUE) {
      return today;
    }

    var monthsToAdd = months == 0 ? 1 : 0;
    var toDate = new Date(today.getFullYear(), today.getMonth() + monthsToAdd, 0);

    return toDate;
  }

  customDateChanged(fromDate: Date, toDate: Date) {
    if (!fromDate || !toDate) return;

    this.getLiveAnalyticsData(fromDate, toDate);
  }

  getLiveAnalyticsData(fromDate: Date, toDate: Date) {
    if(!this.selectedAccountId)
      return;

    this.dateRange = {
      fromDate: fromDate,
      toDate: toDate,
    };

    this.onSelectTab(this.latestTab);
  }

  onSelectTab(sectionType: LiveAnalyticsSection) {
    this.isLoading = true;
    this.latestTab = sectionType;

    switch (sectionType) {
      case LiveAnalyticsSection.Onboarding:
        this.resetDateDropdownToReadOnly();
        this.store.dispatch(GetOnboardingLiveAnalytics.Request({ accountId: this.selectedAccountId }));
        break;
      case LiveAnalyticsSection.Connections:
        this.resetDateDropdownToReadOnly();
        this.store.dispatch(GetConnectionsLiveAnalytics.Request({ accountId: this.selectedAccountId }));
        break;
      case LiveAnalyticsSection.Teams:
        this.resetDateDropdownToReadOnly();
        this.store.dispatch(GetTeamsLiveAnalytics.Request({ accountId: this.selectedAccountId }));
        break;
      case LiveAnalyticsSection.Interactions:
        this.isDateDropdownReadonly = false;
        this.store.dispatch(GetInteractionLiveAnalytics.Request({ accountId: this.selectedAccountId, dateRange: this.dateRange }));
        break;
      case LiveAnalyticsSection.NudgesAndWorkOns:
        this.isDateDropdownReadonly = false;
        this.store.dispatch(GetNudgeAndWorkOnLiveAnalytics.Request({ accountId: this.selectedAccountId, dateRange: this.dateRange }));
        break;
      case LiveAnalyticsSection.Usage:
        this.isDateDropdownReadonly = false;
        this.store.dispatch(GetUsageLiveAnalytics.Request({ accountId: this.selectedAccountId, dateRange: this.dateRange }));
        break;
      default:
        break;
    }
  }

  resetDateDropdownToReadOnly() {
    this.isDateDropdownReadonly = true;
    this.selectedDateFilterOption = this.ALL_TIME_VALUE;
  }

  onMindflickAccountChanged(mindflickAccountId: number) {
    this.store.dispatch(SetLiveAnalyticsSelectedAccountId({ accountId: mindflickAccountId }));
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.unsubscribe();
  }
}
