import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { BsModalService } from 'ngx-bootstrap/modal';
import { SetSelectedAdminSummaryAccountId } from 'src/app/+state/account-admin/account-admin.actions';
import { AppState } from 'src/app/+state/app.state';
import { EditAccountAdminModalComponent } from '../edit-account-admin-modal/edit-account-admin-modal.component';
import { faEditSVG } from 'src/icons';
import { AccountSummaryData } from 'src/app/shared/models/account-admin-managed-users-DTO';


@Component({
  selector: 'app-org-accounts-summary-table-row',
  templateUrl: './org-accounts-summary-table-row.component.html',
  styleUrls: ['./org-accounts-summary-table-row.component.scss']
})
export class OrgAccountsSummaryTableRowComponent implements OnInit{

  @Input() account?: AccountSummaryData;
  

  editIcon = faEditSVG;


  constructor(private store: Store<AppState>, private modalService: BsModalService) {}

  ngOnInit(): void {}

  openEditAccountAdminModal() {
    if (this.account) {
      this.store.dispatch(SetSelectedAdminSummaryAccountId({ accountId: this.account.mindflickAccountId }));
      this.modalService.show(EditAccountAdminModalComponent);
    }
  }
}
