import { createSelector } from '@ngrx/store';
import { SpotlightResponse } from 'src/app/shared/models';
import { AppState, surveyStateKey } from '../../app.state';

export const selectCurrentPageId = (state: AppState) =>
  state[surveyStateKey].currentPage;
export const selectSpotlightResponse = (state: AppState) =>
  state[surveyStateKey].spotlightResponse;
export const selectSurveyState = (state: AppState) => state[surveyStateKey];
export const selectHasInProgress = (state: AppState) =>
  state[surveyStateKey].spotlightResponse?.answers[0] != '-';

export const selectPhotoSrc = (state: AppState) =>
  state[surveyStateKey].photoSrc;

export const selectIsLoading = (state: AppState) =>
  state[surveyStateKey].isLoading;
export const selectIsSaving = (state: AppState) =>
  state[surveyStateKey].isSaving;

export const selectQuestionnaireResponses = (state: AppState) => state[surveyStateKey].questionnaireResponses
export const selectCurrentQuestionnaire = (state:AppState) => state[surveyStateKey].currentQuestionnaire

export const onSurveyIntroPage = createSelector(
  selectCurrentPageId,
  (pageId) => {
    return pageId < -1;
  }
);

export const onSurveyDemoPage = createSelector(
  selectCurrentPageId,
  (pageId) => {
    return pageId === -1;
  }
);

export const onLastSurveyPage = createSelector(
  selectCurrentPageId,
  selectSpotlightResponse,
  (pageId, survey) => {
    return survey?.pages != null && pageId == survey?.pages.length;
  }
);

export const selectCurrentPageWordPairs = createSelector(
  selectSpotlightResponse,
  selectCurrentPageId,
  (response: SpotlightResponse | undefined, currentPage: number) => {
    if (!response) return [];

    return response.pages[currentPage]?.wordPairs;
  }
);

export const selectCurrentPageAnswers = createSelector(
  selectSpotlightResponse,
  selectCurrentPageId,
  (response: SpotlightResponse | undefined, currentPage: number) => {
    const questionIndex = currentPage * 4;

    return response?.answers?.substring(questionIndex, questionIndex + 4);
  }
);
