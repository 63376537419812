import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppState } from 'src/app/+state/app.state';
import { SetSubMenuDropdown } from 'src/app/+state/layout/actions/layout.actions';
import {
  selectAllActivePlaybooks,
  selectAllActiveWorkOns,
} from 'src/app/+state/growth/growth.selector';
import { SubMenuDropdownEnum } from '../../+state/layout/reducers/layout.reducers';
import { faNextSVG } from '../../../icons';

@Component({
  selector: 'app-growth-dropdown',
  templateUrl: './growth-dropdown.component.html',
  styleUrls: ['./growth-dropdown.component.scss'],
})
export class GrowthDropdownComponent implements OnInit, OnDestroy {
  destroyed$ = new Subject<boolean>();

  workOns$ = this.store.select(selectAllActiveWorkOns);
  playbooks$ = this.store.select(selectAllActivePlaybooks);

  numberOfWorkOns: number = 0;
  numberOfPlaybooks: number = 0;

  next = faNextSVG;
  isMouseWithinDropdown: boolean = true;

  constructor(private store: Store<AppState>, private router: Router) {}

  ngOnInit(): void {
    this.workOns$.pipe(takeUntil(this.destroyed$)).subscribe((workOns) => {
      this.numberOfWorkOns = workOns.length;
    });

    this.playbooks$.pipe(takeUntil(this.destroyed$)).subscribe((playbooks) => {
      this.numberOfPlaybooks = playbooks.length;
    });
  }

  onMouseLeaveList() {
    this.isMouseWithinDropdown = false;
    setTimeout(() => {
      if (!this.isMouseWithinDropdown) {
        this.hideDropdown();
      }
    }, 1000);
  }

  showGrowthList() {
    this.isMouseWithinDropdown = true;
  }

  navigateTo(link: string) {
    this.router.navigate([`/growth/${link}`]);
    this.hideDropdown();
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.unsubscribe();
  }

  hideDropdown() {
    this.store.dispatch(
      SetSubMenuDropdown({ subMenuDropdown: SubMenuDropdownEnum.None })
    );
  }
}
