<div class="header bg-dark text-white d-flex flex-column py-5">
  <h2 class="mb-4">Admin</h2>
  <h1 class="flex-fill">Settings</h1>
</div>

<app-settings *isGranted="'EDIT_ORG_SETTINGS'; forAnyAccount: true"></app-settings>
<ng-container *isGranted="'EDIT_ORG_SETTINGS'; forAnyAccount: true">
  <ng-container *isGranted="'VIEW_ACC_SETTINGS'; forAnyAccount: true">
    <div class="partition"></div>
  </ng-container>
</ng-container>
<app-account-settings *isGranted="'VIEW_ACC_SETTINGS'; forAnyAccount: true"></app-account-settings>

