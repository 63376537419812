import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  MindflickAccount,
} from '../models';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  accountUrl: string = '';

  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string
  ) {
    this.accountUrl = `${baseUrl}account`;
  }

  getAccounts(): Observable<MindflickAccount[]> {
    return this.http.get<MindflickAccount[]>(`${this.accountUrl}/all`);
  }

  getAccountsForCurrentUser(): Observable<MindflickAccount[]> {
    return this.http.get<MindflickAccount[]>(
      `${this.accountUrl}/all/current-user`
    );
  }

  getUserMindflickAccount(): Observable<MindflickAccount> {
    return this.http.get<MindflickAccount>(`${this.accountUrl}/current-user`);
  }

  saveAccount(account: MindflickAccount): Observable<MindflickAccount> {
    return this.http.post<MindflickAccount>(this.accountUrl, account);
  }

  getIsUserInvitesEnabled() {
    return this.http.get<boolean>(`${this.accountUrl}/user-invites-enabled`);
  }

  getOrgTwoFactorRequired() {
    return this.http.get<boolean>(`${this.accountUrl}/org-two-factor`);
  }
}
