import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ImageCacheService {
  images: { [id: string]: Blob } = {};

  addImage(id: string, file: Blob) {
    this.images = {
      ...this.images,
      [id]: file,
    };
  }

  tryGetImage(id: string) {
    return this.images[id];
  }

  flushCache() {
    this.images = {};
  }

  setUserImage(id: string, file: Blob) {
    this.images[id] = file;
  }
}
