<div class="modal-header justify-content-end">
  <fa-icon class="cursor-pointer" [icon]="closeIcon" (click)="close()"></fa-icon>
</div>
<div class="modal-body">
  <h2 class="mb-4">Choose File</h2>
  <h3 class="mb-4">Select a file to upload {{ maxSize ? '(max size: ' + maxSize + 'MB)' : ''}}</h3>
  <div>
    <input class="form-control" type="file" placeholder="Choose File" (change)="handleFileUpload($event.target)" />
  </div>
  <button class="btn btn-purple w-100"(click)="confirm()">Confirm</button>
</div>
<div class="modal-bottom-border">

</div>
