
<div class="modal-header justify-content-end">
  <fa-icon class="cursor-pointer fa-lg" [icon]="closeIcon" (click)="close()"></fa-icon>
</div>
<div *ngIf="teams.length" class="modal-body">
  <h2 class="mb-4">Select Team</h2>
  <div class="py-2 border-bottom-grey">
    <div>
      <label class="form-label">Team</label>
      <app-dropdown-select [options]="values" [placeholder]="'Please Select'" [(ngModel)]="selectedTeamId" ></app-dropdown-select>
    </div>
  </div>
  <button class="btn btn-purple w-100" [disabled]="!selectedTeamId" (click)="select()">Confirm</button>
</div>
<div class="modal-bottom-border">

</div>
