import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PortalNotification } from '../models/notifications.interface';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  notificationUrl = '';

  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string
  ) {
    this.notificationUrl = `${this.baseUrl}notification`;
  }

  getNotifications(): Observable<PortalNotification[]> {
    return this.http.get<PortalNotification[]>(`${this.notificationUrl}/all`);
  }

  deletePortalNotification(notificationId: number) {
    return this.http.delete(`${this.notificationUrl}/delete/${notificationId}`);
  }

  setNotificationSeen(notificaitonIds: number[]) {
    return this.http.post<PortalNotification[]>(
      `${this.notificationUrl}/seen`,
      notificaitonIds
    );
  }

  setNotificationAcknowledged(notificaitonId: number) {
    return this.http.post(
      `${this.notificationUrl}/acknowledge`,
      notificaitonId
    );
  }

  notifyUserOfConnectionRequest(userAK: number) {
    return this.http.post(
      `${this.notificationUrl}/connection/request/${userAK}`,
      undefined
    );
  }

  notifyUserOfConnectionAccepted(userAK: number) {
    return this.http.post(
      `${this.notificationUrl}/connection/accept/${userAK}`,
      undefined
    );
  }

  notifyUserOfStrengthSpotted(userAK: number, strengthId: string) {
    return this.http.post(
      `${this.notificationUrl}/strength/spotted/${userAK}/${strengthId}`,
      undefined
    );
  }

}
