import { Dictionary } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import {
  CardLayout,
  CarouselLayout
} from 'src/app/shared/models/carousel-card-layouts.interface';
import { EngagementResource } from 'src/app/shared/models/engagement-resource.interface';
import {
  Playbook,
  PlaybookExercise
} from 'src/app/shared/models/playbooks.interface';
import { GrowthTag, WorkOn, WorkOnHistory } from '../../shared/models';
import { accountAdminStateKey, AppState, growthStateKey } from '../app.state';

import {
  GrowthState, growthTagAdapter, GrowthType, playbookAdapter, sortGrowthTagByOrderByAndThenName, workOnAdapter, workOnHistoryAdapter
} from './growth.reducer';

export const selectGrowthState = (state: AppState) => state[growthStateKey];
export const selectWorkOnState = (state: AppState) =>
  state[growthStateKey].workOns;
export const selectWorkOnHistoryState = (state: AppState) =>
  state[growthStateKey].workOnHistory;
export const selectGrowthTagState = (state: AppState) =>
  state[growthStateKey].tags;
export const selectPlaybookState = (state: AppState) =>
  state[growthStateKey].playbooks;
export const selectSortBy = (state: AppState) =>
  state[growthStateKey].filterSortBy;
export const selectWorkOnType = (state: AppState) =>
  state[growthStateKey].filterWorkOnType;
export const selectFilteredTagIds = (state: AppState) =>
  state[growthStateKey].filteredTagIds;


export const selectUsefulNudges = createSelector(
  selectGrowthState,
  (state: GrowthState) => state.usefulNudges ?? []
)
// The adapter creates some default selectors which we can define here and use further down
const {
  selectIds: allWorkOnIds,
  selectEntities: allWorkOnEntities,
  selectAll: allWorkOns,
  selectTotal: totalWorkOns,
} = workOnAdapter.getSelectors(selectWorkOnState);

export const selectAllWorkOnEntities = allWorkOnEntities;
export const selectAllWorkOnsSorted = allWorkOns;
export const selectAllWorkOns = allWorkOns;

const {
  selectIds: allWorkOnHistoryIds,
  selectEntities: allWorkOnHistoryEntities,
  selectAll: allWorkOnHistories,
  selectTotal: totalWorkOnHistories,
} = workOnHistoryAdapter.getSelectors(selectWorkOnHistoryState);

const {
  selectIds: allTagIds,
  selectEntities: allTagEntities,
  selectAll: allTags,
  selectTotal: totalTags,
} = growthTagAdapter.getSelectors(selectGrowthTagState);

export const selectAllTagEntities = allTagEntities;

const {
  selectIds: allPlaybookIds,
  selectEntities: allPlaybookEntities,
  selectAll: allPlaybooks,
  selectTotal: totalPlaybooks,
} = playbookAdapter.getSelectors(selectPlaybookState);

// TODO: This is a bit inefficient, we would be better adding another array of ids for the active work ons and filtering only the once per load but for now it's fine
export const selectAllActiveWorkOns = createSelector(
  selectAllWorkOns,
  (workOns: WorkOn[]) => {
    return workOns
      .filter((x) => !x.inactive)
      .sort((a, b) => a.title.localeCompare(b.title));
  }
);

export const selectAllActiveUserSpecificWorkOns = createSelector(
  selectAllWorkOns,
  (workOns: WorkOn[]) => {
    return workOns
      .filter((x) => !x.inactive && x.isUserSpecific)
      .sort((a, b) => a.title.localeCompare(b.title));
  }
);

export const selectAllWorkOnsLoading = (state: AppState) =>
  state[growthStateKey].allWorkOnsLoading;

// Getting the selected work on is now just a case of referencing the work on dictionary by id
export const selectSelectedWorkOn = (state: AppState) =>
  state[growthStateKey].selectedWorkOnId != undefined
    ? state[growthStateKey].workOns.entities[
    state[growthStateKey].selectedWorkOnId!
    ]
    : undefined;

export const selectShowGrowthList = (state: AppState) =>
  state[growthStateKey].growthListShowing;

export const selectCurrentWorkOnHistory = createSelector(
  allWorkOnHistories,
  (workOnHistories: WorkOnHistory[]) => {
    let newCurrentWorkOnHistory = workOnHistories
      .filter((x) => x.startedDate && !x.completedDate)
      .sort((a, b) => a.orderById - b.orderById);
    if (newCurrentWorkOnHistory.length > 0) {
      return newCurrentWorkOnHistory[0];
    } else {
      return undefined;
    }
  }
);

export const selectCurrentWorkOn = createSelector(
  allWorkOnEntities,
  selectCurrentWorkOnHistory,
  (workOns: Dictionary<WorkOn>, currentWorkOnHistory?: WorkOnHistory) => {
    if (!currentWorkOnHistory) {
      return undefined;
    }
    return workOns[currentWorkOnHistory?.workOnId!];
  }
);

export const selectCurrentWorkOnTags = createSelector(
  selectAllTagEntities,
  selectCurrentWorkOn,
  (tags: Dictionary<GrowthTag>, workOn?: WorkOn) => {
    if (workOn == undefined || tags == undefined) return [];

    return workOn.tagIds.map((id) => tags![id]!);
  }
);

export const selectUpcomingWorkOnHistories = createSelector(
  allWorkOnHistories,
  (workOnHistories: WorkOnHistory[]) => {
    return workOnHistories
      .filter((x) => !x.startedDate && !x.completedDate)
      .sort((a, b) => a.orderById - b.orderById);
  }
);

export const selectUpcomingWorkOns = createSelector(
  selectUpcomingWorkOnHistories,
  allWorkOnEntities,
  (upcomingWorkOnHistories: WorkOnHistory[], workOns: Dictionary<WorkOn>) => {
    return upcomingWorkOnHistories.map((x) => workOns[x.workOnId]!);
  }
);

export const selectedCompletedWorkOnHistories = createSelector(
  allWorkOnHistories,
  (workOnHistories: WorkOnHistory[]) => {
    return workOnHistories
      .filter((x) => x.startedDate && x.completedDate)
      .sort((a, b) => a.orderById - b.orderById);
  }
);

export const selectedCompletedWorkOns = createSelector(
  allWorkOnEntities,
  selectedCompletedWorkOnHistories,
  (workOns: Dictionary<WorkOn>, completedWorkOnHistories: WorkOnHistory[]) => {
    return completedWorkOnHistories.map((x) => workOns[x.workOnId]!);
  }
);

export const selectGrowthTags = allTags;

export const selectEditingWorkOnId = (state: AppState) =>
  state[growthStateKey].editingWorkOnId;
export const selectSaveWorkOnResult = (state: AppState) =>
  state[growthStateKey].saveWorkOnResult;
export const selectSavingWorkOn = (state: AppState) =>
  state[growthStateKey].savingWorkOn;
export const selectSaveWorkOnError = (state: AppState) =>
  state[growthStateKey].saveWorkOnError;

export const selectAllPlaybooks = allPlaybooks;
export const selectAllPlaybooksSorted = allPlaybooks;

// TODO: Filter the active list just once into an array rather than on every select
export const selectAllActivePlaybooks = createSelector(
  selectAllPlaybooks,
  (playbooks: Playbook[]) => {
    return playbooks
      .filter((x) => !x.inactive)
      .map((x) => ({ ...x, exercises: x.exercises.filter((x) => !x.inactive) }))
      .sort((a, b) => a.title.localeCompare(b.title));
  }
);

export const selectEditingPlaybookId = (state: AppState) =>
  state[growthStateKey].editingPlaybookId;

export const selectEditingPlaybookExerciseId = (state: AppState) =>
  state[growthStateKey].editingPlaybookExerciseId;

export const selectCarouselHeading = (state: AppState) =>
  state[growthStateKey].selectedHeading;

export const selectSelectedWorkOnId = (state: AppState) =>
  state[growthStateKey].workOns.entities[
  state[growthStateKey].selectedWorkOnId!
  ];

//reference the selector in account-admin selectop
export const selectSelectedEngagementResourceId = (state: AppState) =>
  state[accountAdminStateKey].engagementResources.entities[
  state[growthStateKey].selectedEngagementResourceId!
  ];

export const selectSelectedPlaybookExerciseId = (state: AppState) =>
  state[growthStateKey].playbooks.entities[
    state[growthStateKey].selectedPlaybookId!
  ]?.exercises.find(
    (x) => x.id == state[growthStateKey].selectedPlaybookExerciseId
  );

export const selectSelectedGrowthType = (state: AppState) =>
  state[growthStateKey].selectedGrowthType;

export const selectSelectedGrowth = createSelector(
  selectSelectedGrowthType,
  selectSelectedWorkOnId,
  selectSelectedEngagementResourceId,
  selectSelectedPlaybookExerciseId,
  (
    growthType?: GrowthType,
    workOn?: WorkOn,
    engagementResource?: EngagementResource,
    playbookExercise?: PlaybookExercise
  ) => {
    if (growthType == GrowthType.WorkOn) {
      return workOn;
    } else if (growthType == GrowthType.EngagementResource) {
      return engagementResource;
    } else {
      return playbookExercise;
    }
  }
);

//rewrite
export const selectSelectedGrowth2 = (state: AppState) => {
  // The selected growth can either be just the selected work on
  // or it can come from the selected exercise within the selected playbook
  if (state[growthStateKey].selectedGrowthType == GrowthType.WorkOn) {
    return state[growthStateKey].workOns.entities[
      state[growthStateKey].selectedWorkOnId!
    ];
  } else if (
    state[growthStateKey].selectedGrowthType == GrowthType.EngagementResource
  ) {
    return state[growthStateKey].engagementResources.entities[
      state[growthStateKey].selectedEngagementResourceId!
    ];
  } else {
    return state[growthStateKey].playbooks.entities[
      state[growthStateKey].selectedPlaybookId!
    ]?.exercises.find(
      (x) => x.id == state[growthStateKey].selectedPlaybookExerciseId
    );
  }
};

//add eng resource
export const selectSelectedWorkOnOrPlaybook = (state: AppState) => {
  // The selected growth can either be just the selected work on
  // or it can come from the selected exercise within the selected playbook
  if (state[growthStateKey].selectedGrowthType == GrowthType.WorkOn)
    return state[growthStateKey].workOns.entities[
      state[growthStateKey].selectedWorkOnId!
    ];

  return state[growthStateKey].playbooks.entities[
    state[growthStateKey].selectedPlaybookId!
  ];
};

export const selectSelectedGrowthTags = createSelector(
  selectAllTagEntities,
  selectSelectedWorkOnOrPlaybook,
  (tags: Dictionary<GrowthTag>, workOn?: WorkOn | Playbook) => {
    if (workOn == undefined || tags == undefined) return [];

    return workOn.tagIds.map((id) => tags![id]!);
  }
);

export const selectIsGrowthShowing = (state: AppState) =>
  state[growthStateKey].growthListShowing;

export const selectFilteredTags = (state: AppState) =>
  state[growthStateKey].filteredTagIds.map(
    (x) => state[growthStateKey].tags.entities[x]!
  );

// TODO: Determine how we use this and if it's another reason to add a playbookExersises adapter
export const selectAllPlaybookExercises = createSelector(
  selectAllPlaybooks,
  (playbooks: Playbook[]) => {
    let exercises: PlaybookExercise[] = [];

    playbooks.forEach((playbook) => {
      exercises = [...exercises, ...playbook.exercises];
    });

    return exercises;
  }
);

export const selectEditingWorkOn = (state: AppState) =>
  state[growthStateKey].editingWorkOnId != undefined
    ? state[growthStateKey].workOns.entities[
    state[growthStateKey].editingWorkOnId!
    ]
    : null;

export const selectSavingPlaybook = (state: AppState) =>
  state[growthStateKey].savingPlaybook;

export const selectEditingPlaybookExercise = (state: AppState) =>
  state[growthStateKey].editingPlaybookId != undefined &&
    state[growthStateKey].editingPlaybookExerciseId != undefined
    ? state[growthStateKey].playbooks.entities[
      state[growthStateKey].editingPlaybookId!
    ]?.exercises.find(
      (x) => x.id == state[growthStateKey].editingPlaybookExerciseId
    )
    : null;

export const selectSavingPlaybookExercise = (state: AppState) =>
  state[growthStateKey].savingPlaybookExercise;

export const selectSuggestedWorkOnIds = (state: AppState) =>
  state[growthStateKey].suggestedWorkOnIds;
export const selectSuggestedWorkOns = (state: AppState) =>
  state[growthStateKey].suggestedWorkOnIds.map(
    (x) => state[growthStateKey].workOns.entities[x]!
  );

export const selectTags = (tagIds: number[]) =>
  createSelector(selectAllTagEntities, (tags: Dictionary<GrowthTag>) => {
    return Object.values(tags).filter((x) =>
      tagIds.includes(x?.id!)
    ) as GrowthTag[];
  });

export const selectWorkOnCarouselLayout = createSelector(
  selectAllWorkOns,
  selectAllTagEntities,
  selectFilteredTagIds,
  (
    workOns: WorkOn[],
    allTagEntities: Dictionary<GrowthTag>,
    filteredTagIds: number[]
  ) => {
    if (allTagEntities == undefined) return;

    let mapping = new Map<GrowthTag, WorkOn[]>();

    workOns.forEach((workOn) => {
      workOn.tagIds.forEach((tagId) => {
        if (filteredTagIds.includes(tagId) || filteredTagIds.length == 0) {
          let tag = allTagEntities![tagId]!;
          if (!mapping.has(tag)) {
            mapping.set(tag, [workOn]);
          } else {
            let existingWorkOns = mapping.get(tag)!;
            mapping.set(tag, [...existingWorkOns, workOn]);
          }
        }
      });
    });

    const orderedTags = [...mapping.keys()].sort(
      sortGrowthTagByOrderByAndThenName
    );
    const taggedWorkOns = mapping;

    let newCarouselLayout: CarouselLayout[] = [];
    orderedTags.forEach((tag) => {
      let carouselTitle = tag.tag;
      let cards: CardLayout[] = [];

      let taggedWorkOns = mapping.get(tag);

      if (taggedWorkOns) {
        taggedWorkOns.forEach((workOn) => {
          let card: CardLayout = {
            id: workOn.id,
            title: workOn.title,
            summary: workOn.summary,
            imageUrl: workOn.imageUrl,
            growthType: GrowthType.WorkOn,
            tagIds: workOn.tagIds,
            tags: workOn.tagIds.map((x) => allTagEntities[x]?.tag!),
            workOnType: workOn.type,
            sections: workOn.sections,
          };

          cards.push(card);
        });
      }

      let newCarousel: CarouselLayout = {
        name: carouselTitle,
        cards: cards,
      };
      newCarouselLayout.push(newCarousel);
    });
    return newCarouselLayout;
  }
);

export const selectCurrentWorkOnSearchTerm = (state: AppState) =>
  state[growthStateKey].currentWorkOnSearchTerm;


export const selectDashboardNudge = createSelector(
  selectGrowthState,
  (state: GrowthState) => state.dashboardNudge
);

export const selectOrgNudges = createSelector(
  selectGrowthState,
  (state: GrowthState) => state.organisationNudges
);

export const selectWorkOnThemes = createSelector(
  selectGrowthState,
  (state: GrowthState) => state.workOnThemes
);


export const selectPreviousNudge = createSelector(
  selectGrowthState,
  (state: GrowthState) => state.previousNudge
);
