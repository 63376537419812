import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { faClipboardSVG, faCloseSVG } from 'src/icons';
import { Clipboard } from '@angular/cdk/clipboard';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/+state/app.state';
import { DisplaySnackbarAlert } from 'src/app/+state/layout/actions/layout.actions';

@Component({
  selector: 'app-link-modal',
  templateUrl: './link-modal.component.html',
  styleUrls: ['./link-modal.component.scss'],
})
export class LinkModalComponent implements OnInit {
  link: string = '';
  title: string = 'Link';

  closeIcon = faCloseSVG;
  clipboard = faClipboardSVG;

  constructor(
    private modalRef: BsModalRef,
    private clipboad: Clipboard,
    private store: Store<AppState>
  ) {}

  ngOnInit(): void {}

  onCopyToClipboard() {
    this.clipboad.copy(this.link);

    this.store.dispatch(
      DisplaySnackbarAlert.SetAlert({
        alert: {
          messageHeader: 'Info',
          message: 'Link copied to clipboard!',
          alertType: 'info',
        },
      })
    );
  }

  close() {
    this.modalRef.hide();
  }
}
