<div class="modal-header justify-content-end">
  <fa-icon
    class="cursor-pointer fa-lg"
    [icon]="closeIcon"
    (click)="close()"
           data-cy="close"
  ></fa-icon>
</div>
<div
  [@addUserShowConfirm]="showNoLockConfirmation ? 'showConfirm' : 'addUser'"
  class="modal-body h-auto"
     data-cy="bulk-add-users-modal"
>
  <ng-container *ngIf="!showNoLockConfirmation">
    <ng-container *ngIf="!sendingInvites; else spinner">
      <div class="d-flex justify-content-between mb-2">
        <h2>Bulk invite users</h2>
        <div class="text-dark body-copy">
          <a [href]="downloadCsvTemplate">Download template</a>
          <fa-icon class="ms-1" [icon]="download"></fa-icon>
        </div>
      </div>
      <h3 class="mb-4 body-copy">
        Upload an Excel file (.xls, .xlsx or .xlsm) containing the following
        details: First name, Last name and Email. You can download a template
        using the link above.
      </h3>
      <div class="row mb-4">
        <div *ngIf="!isAccountAdminModalVersion" class="col-md-12 mb-2">
          <label class="form-label" for="mindflick-account">Mindflick Account</label>
          <app-dropdown-select [placeholder]="'Please Select'"
                               [options]="mindflickAccounts"
                               [(ngModel)]="selectedAccount"
                               [readonly]="accountReadonly"
                               (selectionChanged)="mindflickAccountSelectionChanged($event)"></app-dropdown-select>
        </div>
        <div class="col-md-12 mb-2">
          <label class="form-label" for="bulk-invite">Choose file</label>
          <input class="form-control"
                 type="file"
                 placeholder="Choose file"
                 (change)="handleFileUpload($event.target)"
                 data-cy="bulk-upload-input"
                 accept=".csv, .xls, .xlsx, .xlsm" />
          <div class="form-error" *ngIf="fileTypeWarning">
            *File should be a valid Excel format (.csv, .xls, .xlsx or .xlsm)
          </div>
        </div>

        <div *ngIf="!isAccountAdminModalVersion" class="mb-2">
          <label class="form-label" for="referrer">
            Referrer (optional)
            <fa-icon class="addUserToolTips ms-1 pt-1"
                     [icon]="questionIcon"
                     tooltip="Select the person who reffered these users. This is purely for filtering purposes on the user management page."></fa-icon>
          </label>
          <app-dropdown-select [options]="referrerOptions"
                               [(ngModel)]="selectedReferrer"></app-dropdown-select>
        </div>
        <div *ngIf="!isAccountAdminModalVersion && creditPotOptions.length" class="mb-2">
          <label class="form-label" for="referrer">
            Credit pot (optional)
            <fa-icon class="addUserToolTips ms-1 pt-1"
                     [icon]="questionIcon"
                     tooltip="Select the owner of the credit pot you want to assign these users to. This will use up the credit pot's seats instead of the main account's seats."></fa-icon>
          </label>
          <app-dropdown-select [options]="creditPotOptions"
                               [(ngModel)]="selectedCreditPot"></app-dropdown-select>
        </div>
        <div *ngIf="!isAccountAdminModalVersion && questionnaireOptions.length" class="mb-2">
          <label class="form-label" for="referrer">
            Attach a questionnaire (optional)
            <fa-icon class="addUserToolTips ms-1 pt-1"
                     [icon]="questionIcon"
                     tooltip="Attach a questionnaire you want the user to fill out after their sign up."></fa-icon>
          </label>
          <app-dropdown-select [options]="questionnaireOptions"
                               [(ngModel)]="selectedQuestionnaire"></app-dropdown-select>
        </div>
        <div class="col-12 mt-4">
          <label class="form-label d-flex justify-content-between">
            Spotlight Debrief
          </label>
          <app-dropdown-select [formControl]="fcIsDigitalDebriefRequired" [options]="debriefOptions"></app-dropdown-select>
        </div>

        <div *ngIf="!fcIsDigitalDebriefRequired.value" class="renewal-date position-relative col-12 mt-4">
          <label for="renewalDate"
                 class="form-label d-flex justify-content-between">
            Debrief date<fa-icon class="addUserToolTips ms-1 pt-1"
                                 [icon]="infoIcon"
                                 tooltip="The user will be locked out of Mindflick until their debrief, ensuring they enter the platform fully informed about their Spotlight preferences."
                                 [tooltipAppendToBody]="true"></fa-icon>
          </label>
          <input [(ngModel)]="debriefDate"
                 [ngModelOptions]="{ standalone: true }"
                 type="text"
                 placeholder="Select debrief date"
                 class="form-control"
                 [class.ng-touched]="invalidDebriefDate"
                 [class.ng-invalid]="invalidDebriefDate"
                 bsDatepicker
                 [container]="'.renewal-date'"
                 [bsConfig]="{
                showWeekNumbers: false,
                dateInputFormat: 'DD/MM/YYYY'
              }"
                 [minDate]="tomorrow"
                 (bsValueChange)="invalidDebriefDate = false" />
          <div *ngIf="invalidDebriefDate"
               class="form-error">
            * Please ensure a debrief date is selected for an in-person debrief
          </div>
        </div>

        <div *ngIf="isAccountAdminModalVersion" class="col-12 mt-0">
          <hr />
          <div class="d-flex p-0 mt-1 justify-content-around">
            <div class="col-11">
              <label 
                     class="form-label d-flex justify-content-between ">
                Send connection request with invite
              </label>
              <p class="body-copy">The user will receive a connection request from you upon joining Mindflick</p>
            </div>
            <input [formControl]="fcIsConnectionRequired"
                   class="form-check-input me-1"
                   type="checkbox"
                   [checked]="true" />
          </div>
        </div>
      </div>
      <button
        class="btn btn-purple w-100"
        (click)="submitForm()"
                 data-cy="bulk-add-users-submit"

        [disabled]="isDisabled"
      >
        Create Accounts
      </button>
      <div *ngIf="sendingInvitesSuccess" class="body-copy">
        Invite Successful
      </div>
      <div *ngIf="sendingInvitesError" class="form-error">
        {{ sendingInvitesError }}
      </div>
    </ng-container>
    <ng-template #spinner>
      <div class="spinner">
        <ngx-spinner
          bdColor="white"
          [fullScreen]="false"
          color="#7000DD"
          type="ball-fussion"
          size="medium"
          ><h3 class="spinner-text">Inviting...</h3></ngx-spinner
        >
      </div>
    </ng-template>
  </ng-container>
  <div *ngIf="showNoLockConfirmation"
       class="d-flex flex-column no-lock-confirm">
    <h2 class="mb-4">
      Warning
      <img class="ms-3 warning-sign"
           src="./assets/svg/portal-icons/Warning_Sign.svg" />
    </h2>
    <h3 class="mb-4">
      You're about to add users without locking them until onboarding date.
    </h3>
    <h3 class="mb-4">
      This will give them immediate access to Mindflick. Are you sure you want
      to continue?
    </h3>
    <div class="form-error mb-15">
      {{ sendingInvitesError }}
    </div>
    <div class="d-flex justify-content-between">
      <button class="w-100 me-2 btn btn-secondary" (click)="confirmNoLock()">
        Continue anyway
      </button>
      <button class="w-100 ms-2 btn btn-purple" (click)="cancelNoLock()">
        Back to add user
      </button>
    </div>
  </div>
</div>
<div class="modal-bottom-border"></div>
