<svg xmlns="http://www.w3.org/2000/svg"
         id="Layer_1"
         viewBox="0 0 449.58 449.58"
         version="1.1">
        <defs id="defs4">
            <style id="style2">
                .cls-9 {
                    fill: #fff;
                }

                .cls-12 {
                    fill: none;
                    stroke: #dadbda;
                }

                .cls-13,
                .cls-14,
                .cls-9 {
                    stroke-width: 0;
                }

                .cls-14 {
                    fill: #666;
                }
            </style>
        </defs>
        <circle id="Ellipse_882"
                [attr.fill]="selectedLetter === 'L' ? '#83a9ff' : '#666'"
                cx="315.51"
                cy="134.11"
                r="90" />
        <circle id="Ellipse_883"
                [attr.fill]="selectedLetter === 'F' ? '#ff2e5e' : '#666'"
                cx="133.7"
                cy="134"
                r="90" />
        <circle id="Ellipse_884"
                [attr.fill]="selectedLetter === 'E' ? '#31c99a' : '#666'"
                cx="315.8"
                cy="315.59"
                r="90" />
        <circle id="Ellipse_885"
                [attr.fill]="selectedLetter === 'X' ? '#fecf33' : '#666'"
                cx="133.7"
                cy="315.59"
                r="90" />
        <path id="Line_857" class="cls-12" d="M50.56 224.79h351" />
        <path id="Line_855" class="cls-12" d="M224.56 44.93v358" />
        <path class="cursor-pointer"
              d="m110.755 158.11 23.81-65.455h22.09l-3.927 10.637h-9.818l-5.89 16.363h8.18l-3.926 10.637h-8.182l-10.064 27.818Z"
              id="path972"
              style="isolation: isolate"
              font-style="oblique"
              font-size="90"
              font-family='FKScreamerLegacy-Slanted,"FK Screamer Legacy"'
              fill="#fff" />
        <path class="cursor-pointer"
              d="M321.275 92.685h12.272l-19.963 54.819h10.227l-3.846 10.636h-22.5z"
              id="path978"
              style="isolation: isolate"
              font-style="oblique"
              font-size="90"
              font-family='FKScreamerLegacy-Slanted,"FK Screamer Legacy"'
              fill="#fff" />
        <path class="cursor-pointer"
              transform="translate(300.98 339.44)"
              d="M15.955-65.455h22.09l-3.927 10.637H24.3l-5.89 16.363H27l-3.927 10.637h-8.591L8.264-10.636h9.818L14.236 0h-22.09Z"
              id="path1003"
              fill="#fff" />
        <path class="cursor-pointer"
              d="M149.37 274.035h11.292v.819l-16.937 33.381-6.136 31.255h-12.027l4.827-22.255h-.818l-11.373 22.255h-11.454v-.818l18.081-35.1 5.891-29.537h11.291l-4.663 21.846h.818z"
              id="path982"
              fill="#fff" />
        <g id="Task-Oriented" class="cls-7">
            <g aria-label="Forceful"
               transform="translate(106.68 182.5)"
               id="text73"
               fill="#fff">
                <path d="M1.26-9.38h6.314v1.316H2.66v2.856h4.214v1.316H2.66V0h-1.4z"
                      id="path955"
                      letter-spacing="0" />
                <path d="M12.058.14q-.98 0-1.75-.448-.756-.448-1.19-1.232-.434-.798-.434-1.806t.434-1.792q.434-.798 1.19-1.246.77-.448 1.75-.448t1.736.448q.77.448 1.204 1.246.434.784.434 1.792t-.434 1.806q-.434.784-1.204 1.232-.756.448-1.736.448zm-1.974-3.486q0 1.05.532 1.68.546.616 1.442.616.896 0 1.428-.616.546-.63.546-1.68 0-1.05-.546-1.666-.532-.63-1.428-.63-.896 0-1.442.63-.532.616-.532 1.666z"
                      id="path957"
                      letter-spacing=".02em" />
                <path d="M17.288-6.692h1.232l.14.938h.098q.42-.546.896-.812.49-.266 1.232-.266h.252v1.302h-.518q-.938 0-1.456.504-.504.49-.504 1.372V0h-1.372z"
                      id="path959"
                      letter-spacing="0" />
                <path d="M25.312.14q-.952 0-1.694-.434T22.47-1.512q-.406-.798-.406-1.834t.406-1.82q.42-.798 1.162-1.232.756-.434 1.708-.434 1.302 0 2.072.672.784.672.98 1.918h-1.288q-.378-1.4-1.736-1.4-.896 0-1.414.602-.518.602-.518 1.694t.518 1.694q.518.602 1.386.602 1.386 0 1.764-1.4h1.288q-.21 1.26-1.008 1.932-.798.658-2.072.658z"
                      id="path961"
                      letter-spacing=".02em" />
                <path d="M33.166.14q-.994 0-1.764-.448t-1.204-1.246q-.42-.798-.42-1.792 0-1.008.406-1.806.42-.798 1.148-1.232.742-.448 1.708-.448.924 0 1.624.406.714.406 1.092 1.162.392.742.392 1.722 0 .168-.028.588h-4.956q.112.896.644 1.414.532.518 1.4.518.644 0 1.05-.28.42-.294.588-.784h1.26q-.154.98-.952 1.61-.798.616-1.988.616zm1.666-4.13q-.056-.756-.546-1.218-.476-.462-1.246-.462-.728 0-1.204.448t-.63 1.232z"
                      id="path963"
                      letter-spacing=".02em" />
                <path d="M38.584-7.392q0-1.064.56-1.596.574-.546 1.89-.546h.63v1.12h-.616q-.63 0-.868.252-.224.238-.224.812v.658h1.708v1.12h-1.708V0h-1.372v-5.572h-1.47v-1.12h1.47z"
                      id="path965"
                      letter-spacing=".02em" />
                <path d="M45.528.14q-1.12 0-1.792-.714-.658-.714-.658-1.946v-4.172h1.372v3.976q0 .756.406 1.218.406.448 1.078.448.756 0 1.204-.504.448-.504.448-1.344v-3.794h1.372V0h-1.232l-.14-.798h-.098q-.728.938-1.96.938z"
                      id="path967"
                      letter-spacing=".02em" />
                <path d="M51.282-9.534h1.372V0h-1.372z"
                      id="path969"
                      letter-spacing=".02em" />
            </g>
        </g>
        <g id="Task-Oriented-2" class="cls-7">
            <g aria-label="Logical"
               transform="translate(292.03 182.53)"
               id="text80"
               fill="#fff">
                <path d="M1.26-9.38h1.4v8.064h4.662V0H1.26Z"
                      id="path988"
                      letter-spacing="0" />
                <path d="M11.728.14q-.98 0-1.75-.448-.756-.448-1.19-1.232-.434-.798-.434-1.806t.434-1.792q.434-.798 1.19-1.246.77-.448 1.75-.448t1.736.448q.77.448 1.204 1.246.434.784.434 1.792t-.434 1.806q-.434.784-1.204 1.232-.756.448-1.736.448ZM9.754-3.346q0 1.05.532 1.68.546.616 1.442.616.896 0 1.428-.616.546-.63.546-1.68 0-1.05-.546-1.666-.532-.63-1.428-.63-.896 0-1.442.63-.532.616-.532 1.666z"
                      id="path990"
                      letter-spacing=".02em" />
                <path d="M19.694 2.786q-1.022 0-1.792-.294t-1.19-.826q-.42-.518-.42-1.204 0-.924.686-1.526t1.89-.728v-.112q-.952-.21-1.526-.868-.574-.672-.574-1.554 0-.7.378-1.274.378-.574 1.05-.896.672-.336 1.498-.336.7 0 1.316.266l1.036-1.218.966.812-.98 1.148q.588.658.588 1.498 0 .882-.574 1.554-.574.658-1.526.868v.112q1.204.126 1.89.728.686.602.686 1.526 0 .686-.42 1.204-.42.532-1.19.826-.77.294-1.792.294zM17.664.378q0 .56.532.896.546.336 1.498.336t1.484-.336q.546-.336.546-.896t-.546-.882q-.532-.336-1.484-.336t-1.498.336q-.532.322-.532.882zm.434-4.676q0 .644.42 1.008.434.364 1.176.364t1.162-.364q.434-.364.434-1.008 0-.63-.434-.994-.42-.364-1.162-.364t-1.176.364q-.42.364-.42.994z"
                      id="path992"
                      letter-spacing=".02em" />
                <path d="M24.79-6.692h1.372V0H24.79zm-.042-2.842h1.456v1.47h-1.456z"
                      id="path994"
                      letter-spacing=".02em" />
                <path d="M31.3.14q-.952 0-1.694-.434t-1.148-1.218q-.406-.798-.406-1.834t.406-1.82q.42-.798 1.162-1.232.756-.434 1.708-.434 1.302 0 2.072.672.784.672.98 1.918h-1.288q-.378-1.4-1.736-1.4-.896 0-1.414.602-.518.602-.518 1.694t.518 1.694q.518.602 1.386.602 1.386 0 1.764-1.4h1.288q-.21 1.26-1.008 1.932Q32.574.14 31.3.14z"
                      id="path996"
                      letter-spacing=".02em" />
                <path d="M38.188.14q-1.05 0-1.708-.574-.658-.574-.658-1.484 0-.952.714-1.498.714-.56 1.974-.56h1.764v-.336q0-.686-.392-1.022-.392-.35-1.106-.35-1.274 0-1.484 1.232h-1.26q.084-1.078.812-1.722.742-.658 1.96-.658 1.344 0 2.072.63.742.616.742 1.75v2.744q0 .224.154.364.168.126.434.126h.168V0h-.392q-.616 0-1.022-.238t-.574-.7h-.098Q39.658.14 38.188.14zm-1.05-2.156q0 .462.336.742.336.266.924.266.84 0 1.358-.476.518-.49.518-1.274v-.154H38.58q-.728 0-1.092.21-.35.21-.35.686z"
                      id="path998"
                      letter-spacing=".02em" />
                <path d="M44.11-9.534h1.372V0H44.11z"
                      id="path1000"
                      letter-spacing=".02em" />
            </g>
        </g>
        <g id="Task-Oriented-3" class="cls-7">
            <g aria-label="Empathic"
               transform="translate(284.52 363.86)"
               id="text91"
               fill="#fff">
                <path d="M1.26-9.38h6.426v1.316H2.66v2.73h4.298v1.288H2.66v2.73h5.082V0H1.26Z"
                      id="path1006"
                      letter-spacing="0" />
                <path d="M9.608-6.692h1.232l.14.798h.098q.728-.938 1.862-.938.63 0 1.106.28.476.28.756.784h.098q.49-.546.994-.798.504-.266 1.106-.266 1.022 0 1.61.644.602.644.602 1.736V0H17.84v-4.256q0-.616-.364-.994-.364-.392-.924-.392-.644 0-1.05.448-.406.434-.406 1.12V0h-1.372v-4.256q0-.616-.364-.994-.364-.392-.924-.392-.644 0-1.05.448-.406.434-.406 1.12V0H9.608Z"
                      id="path1008"
                      letter-spacing=".02em" />
                <path d="M21.438-6.692h1.134l.14.84h.196q.686-.98 2.044-.98.868 0 1.554.448.7.448 1.078 1.246.392.784.392 1.792t-.392 1.806q-.392.798-1.092 1.246-.686.434-1.582.434-1.218 0-1.974-.826h-.126V2.66h-1.372zm1.372 3.346q0 1.078.504 1.694.504.602 1.4.602.868 0 1.358-.602.504-.616.504-1.694 0-1.078-.504-1.68-.49-.616-1.358-.616-.896 0-1.4.616-.504.602-.504 1.68z"
                      id="path1010"
                      letter-spacing=".02em" />
                <path d="M31.866.14q-1.05 0-1.708-.574-.658-.574-.658-1.484 0-.952.714-1.498.714-.56 1.974-.56h1.764v-.336q0-.686-.392-1.022-.392-.35-1.106-.35-1.274 0-1.484 1.232h-1.26q.084-1.078.812-1.722.742-.658 1.96-.658 1.344 0 2.072.63.742.616.742 1.75v2.744q0 .224.154.364.168.126.434.126h.168V0h-.392q-.616 0-1.022-.238t-.574-.7h-.098Q33.336.14 31.866.14zm-1.05-2.156q0 .462.336.742.336.266.924.266.84 0 1.358-.476.518-.49.518-1.274v-.154h-1.694q-.728 0-1.092.21-.35.21-.35.686z"
                      id="path1012"
                      letter-spacing="0" />
                <path d="M39.918 0q-.868 0-1.302-.406-.434-.42-.434-1.302v-3.864h-1.54v-1.12h.714q.602 0 .784-.322.182-.322.182-1.064v-.7h1.232v2.086h1.834v1.12h-1.834v3.64q0 .392.196.574.196.182.686.182h.952V0Z"
                      id="path1014"
                      letter-spacing=".02em" />
                <path d="M43.292-9.534h1.372v3.64h.098q.728-.938 1.988-.938 1.148 0 1.806.714.672.714.672 1.946V0h-1.372v-3.976q0-.756-.42-1.204-.406-.462-1.092-.462-.77 0-1.232.504-.448.49-.448 1.344V0h-1.372z"
                      id="path1016"
                      letter-spacing=".02em" />
                <path d="M51.482-6.692h1.372V0h-1.372zm-.042-2.842h1.456v1.47H51.44z"
                      id="path1018"
                      letter-spacing=".02em" />
                <path d="M57.992.14q-.952 0-1.694-.434T55.15-1.512q-.406-.798-.406-1.834t.406-1.82q.42-.798 1.162-1.232.756-.434 1.708-.434 1.302 0 2.072.672.784.672.98 1.918h-1.288q-.378-1.4-1.736-1.4-.896 0-1.414.602-.518.602-.518 1.694t.518 1.694q.518.602 1.386.602 1.386 0 1.764-1.4h1.288q-.21 1.26-1.008 1.932-.798.658-2.072.658z"
                      id="path1020"
                      letter-spacing=".02em" />
            </g>
        </g>
        <g id="Task-Oriented-4" class="cls-7">
            <g aria-label="eXpressive"
               transform="translate(97.61 363.89)"
               id="text108"
               fill="#fff">
                <path d="M3.962.14q-.994 0-1.764-.448T.994-1.554q-.42-.798-.42-1.792 0-1.008.406-1.806.42-.798 1.148-1.232.742-.448 1.708-.448.924 0 1.624.406.714.406 1.092 1.162.392.742.392 1.722 0 .168-.028.588H1.96q.112.896.644 1.414.532.518 1.4.518.644 0 1.05-.28.42-.294.588-.784h1.26q-.154.98-.952 1.61Q5.152.14 3.962.14Zm1.666-4.13q-.056-.756-.546-1.218-.476-.462-1.246-.462-.728 0-1.204.448t-.63 1.232z"
                      id="path1023"
                      letter-spacing="0" />
                <path d="m8.118-.14 3.164-4.578v-.196L8.286-9.24v-.14h1.568l2.324 3.36h.112l2.352-3.36h1.526v.14l-2.982 4.27v.196L16.392-.14V0h-1.568L12.29-3.654h-.126L9.644 0H8.118z"
                      id="path1025"
                      letter-spacing="0" />
                <path d="M18.048-6.692h1.134l.14.84h.196q.686-.98 2.044-.98.868 0 1.554.448.7.448 1.078 1.246.392.784.392 1.792t-.392 1.806q-.392.798-1.092 1.246-.686.434-1.582.434-1.218 0-1.974-.826h-.126V2.66h-1.372zm1.372 3.346q0 1.078.504 1.694.504.602 1.4.602.868 0 1.358-.602.504-.616.504-1.694 0-1.078-.504-1.68-.49-.616-1.358-.616-.896 0-1.4.616-.504.602-.504 1.68z"
                      id="path1027"
                      letter-spacing=".02em" />
                <path d="M26.488-6.692h1.232l.14.938h.098q.42-.546.896-.812.49-.266 1.232-.266h.252v1.302h-.518q-.938 0-1.456.504-.504.49-.504 1.372V0h-1.372z"
                      id="path1029"
                      letter-spacing="0" />
                <path d="M34.652.14q-.994 0-1.764-.448t-1.204-1.246q-.42-.798-.42-1.792 0-1.008.406-1.806.42-.798 1.148-1.232.742-.448 1.708-.448.924 0 1.624.406.714.406 1.092 1.162.392.742.392 1.722 0 .168-.028.588H32.65q.112.896.644 1.414.532.518 1.4.518.644 0 1.05-.28.42-.294.588-.784h1.26q-.154.98-.952 1.61-.798.616-1.988.616zm1.666-4.13q-.056-.756-.546-1.218-.476-.462-1.246-.462-.728 0-1.204.448t-.63 1.232z"
                      id="path1031"
                      letter-spacing=".02em" />
                <path d="M42.114.14q-1.288 0-2.086-.602-.784-.602-.924-1.75h1.274q.126.574.56.896.448.308 1.218.308.686 0 1.05-.238.378-.238.378-.686 0-.336-.238-.518-.224-.182-.7-.252l-1.33-.182q-.952-.14-1.442-.616-.49-.49-.49-1.26 0-.938.714-1.498.714-.574 1.904-.574 1.232 0 1.904.616.686.602.826 1.568h-1.274q-.266-1.036-1.498-1.036-.602 0-.952.224t-.35.63q0 .644.896.77l1.596.224q.84.112 1.288.602.448.476.448 1.246 0 .994-.714 1.568-.7.56-2.058.56z"
                      id="path1033"
                      letter-spacing=".02em" />
                <path d="M49.408.14q-1.288 0-2.086-.602-.784-.602-.924-1.75h1.274q.126.574.56.896.448.308 1.218.308.686 0 1.05-.238.378-.238.378-.686 0-.336-.238-.518-.224-.182-.7-.252l-1.33-.182q-.952-.14-1.442-.616-.49-.49-.49-1.26 0-.938.714-1.498.714-.574 1.904-.574 1.232 0 1.904.616.686.602.826 1.568h-1.274q-.266-1.036-1.498-1.036-.602 0-.952.224t-.35.63q0 .644.896.77l1.596.224q.84.112 1.288.602.448.476.448 1.246 0 .994-.714 1.568-.7.56-2.058.56z"
                      id="path1035"
                      letter-spacing=".02em" />
                <path d="M54.154-6.692h1.372V0h-1.372zm-.042-2.842h1.456v1.47h-1.456z"
                      id="path1037"
                      letter-spacing=".02em" />
                <path d="M57.148-6.552v-.14h1.386l1.918 5.334h.098l1.89-5.334h1.358v.14L61.334 0h-1.722z"
                      id="path1039"
                      letter-spacing=".01em" />
                <path d="M68.222.14q-.994 0-1.764-.448t-1.204-1.246q-.42-.798-.42-1.792 0-1.008.406-1.806.42-.798 1.148-1.232.742-.448 1.708-.448.924 0 1.624.406.714.406 1.092 1.162.392.742.392 1.722 0 .168-.028.588H66.22q.112.896.644 1.414.532.518 1.4.518.644 0 1.05-.28.42-.294.588-.784h1.26q-.154.98-.952 1.61-.798.616-1.988.616zm1.666-4.13q-.056-.756-.546-1.218-.476-.462-1.246-.462-.728 0-1.204.448t-.63 1.232z"
                      id="path1041"
                      letter-spacing=".02em" />
            </g>
        </g>

        <text
          style="font-size: 12px"
          fill="#B0B1B0"
          font-family="Zagma"
          text-anchor="middle"
          transform="translate(223.71,40.26)"
        >
            Task-Oriented
        </text>
        <text
          style="font-size: 12px"
          fill="#B0B1B0"
          font-family="Zagma"
          text-anchor="middle"
          transform="translate(34.71,225.12) rotate(-90)"
        >
            Externally Focused
        </text>
        <text
          style="font-size: 12px"
          fill="#B0B1B0"
          font-family="Zagma"
          text-anchor="middle"
          transform="translate(415.71,225.12) rotate(90)"
        >
            Internally Focused
        </text>
        <text
          style="font-size: 12px"
          fill="#B0B1B0"
          font-family="Zagma"
          text-anchor="middle"
          transform="translate(223.71,418.26)"
        >
            People-Oriented
        </text>
    </svg>