import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  inject,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { map, take, takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/+state/app.state';
import {
  selectAllUsers,
  selectCanUserViewAdmin,
  selectCurrentUser,
  selectIsMindflickCoach,
} from '../../+state/user/user.selector';
import {
  SetSubMenuDropdown,
  SetSubMenuLargePopout,
  SetSubMenuSmallPopout,
} from '../../+state/layout/actions/layout.actions';
import {
  SubMenuDropdownEnum,
  SubMenuLargePopoutEnum,
  SubMenuSmallPopoutEnum,
} from '../../+state/layout/reducers/layout.reducers';
import {
  selectAllNotifications,
  selectSignalrIsConnecting,
} from '../../+state/signalr/signalr.selector';
import {
  selectIsConnectionsPending,
  selectIsTeamRequestPending,
  selectShowDownloads,
  selectShowNotifications,
} from '../../+state/layout/selectors/layout.selector';

import { MarkNotificationAsSeen } from 'src/app/+state/signalr/signalr.actions';
import { selectAllMyTeamRequest } from '../../+state/team/team.selector';
import { MenuLink } from 'src/app/shared/models/navigation.interface';
import { selectChampionCanViewAnalytics } from 'src/app/+state/account/account.selector';

import { environment } from 'src/environments/environment';
import { ShepherdService } from 'angular-shepherd';
import { RbacService } from 'src/app/shared/services/rbac.service';
import { MindflickPermissionEnum } from 'src/app/shared/models/enums/mindflick-permission.enum';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'top-menu',
  templateUrl: './top-menu.component.html',
  styleUrls: ['./top-menu.component.scss'],
})
export class TopMenuComponent implements OnInit, OnDestroy {

  shepherdService = inject(ShepherdService);


  destroyed$: Subject<boolean> = new Subject<boolean>();

  connectingToSignalR$ = this.store.select(selectSignalrIsConnecting);
  notifications$ = this.store.select(selectAllNotifications);
  isNotificationsShowing$ = this.store.select(selectShowNotifications);
  isDownloadsShowing$ = this.store.select(selectShowDownloads);
  isConnectionsPending$ = this.store.select(selectIsConnectionsPending);
  isTeamRequestPending$ = this.store.select(selectIsTeamRequestPending);
  championCanViewAnalytics$ = this.store.select(selectChampionCanViewAnalytics);
  isMindflickCoach$ = this.store.select(selectIsMindflickCoach);

  teamRequests$ = this.store.select(selectAllMyTeamRequest);
  allUsers$ = this.store.select(selectAllUsers);

  canUserViewAdmin$ = this.store.select(selectCanUserViewAdmin);



  routeSubscription?: Subscription;

  env = environment;

  shadowRoutes = ['/documentation', '/discover', '/schematics', '/'];
  appUrl?: string;
  appHash?: string;
  currentVersion?: string;
  isBrowser: boolean;
  initBoxShadow = false;
  isLocalhost = false;
  needPrefix = false;
  routeStartPath: string = '';
  previousDocs: {
    url: string;
    version: string;
    unprefixedUrl: string;
  }[] = [];
  topMenuLinks: MenuLink[] = [
    { title: 'Home', routerLink: ['/dashboard'] },
    { title: 'Connect', routerLink: ['/connections'] },
    { title: 'Teams', routerLink: ['/teams'] },
    { title: 'Profile', routerLink: ['/profile'] },
    { title: 'Growth', noNav: ['/growth'] },
    { title: 'Account', routerLink: ['/account'] },
  ];

  superAdmin: MenuLink = { title: 'super-admin', routerLink: ['/super-admin'] };
  coachFeedback: MenuLink = { title: 'coach-feedback', routerLink: ['/super-admin/coach-feedback'] };
  accountAdmin: MenuLink = {
    title: 'account-admin',
    routerLink: ['/admin/user-management'],
  };

  isNotificationsPending: boolean = false;
  isNotificationsShowing: boolean = false;
  isDownloadsShowing: boolean = false;
  viewCoachFeedback: boolean = false;

  championCanViewAnalytics?: boolean = false;

  constructor(
    @Inject(PLATFORM_ID) platformId: number,
    public router: Router,
    public oidcSecurityService: OidcSecurityService,
    private store: Store<AppState>,
    private rbacService: RbacService
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    this.routeSubscription = this.router.events
      .pipe(takeUntil(this.destroyed$))
      .subscribe((event: any) => {
        if (event instanceof NavigationEnd) {
          let firstBreak = event.url.indexOf('/', 2);
          if (firstBreak > 0)
            this.routeStartPath = event.url.substring(0, firstBreak);
          else this.routeStartPath = event.url;

          //If the current route is to another user's profile then the active menu should be connections not profile
          let links = this.topMenuLinks.slice(0);
          if (event.url.startsWith('/profile/')) {
            links.forEach((l) => {
              if (l.routerLink && l.routerLink.length > 0)
                l.active = l.routerLink[0].startsWith('/connections');
            });
          }
          //Update the routerLinks in the topMenuLinks collection depending on the current route
          else
            links.forEach((l) => {
              if (l.noNav) {
                l.active = event.url.startsWith(l.noNav[0]);
              } else {
                if (l.routerLink && l.routerLink.length > 0)
                  l.active = event.url.startsWith(l.routerLink[0]);
              }
            });

          this.topMenuLinks = links;
        }
      });
  }

  public isAuthenticated$ = this.oidcSecurityService.isAuthenticated$.pipe(
    map((isAuthenticated) => {
      return isAuthenticated?.isAuthenticated;
    })
  );
  public isSuperAdmin$ = this.store
    .select(selectCurrentUser)
    .pipe(map((user) => user?.isSuperAdmin));

  ngOnInit(): void {
    this.notifications$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((notifications) => {
        this.isNotificationsPending =
          notifications.filter((x) => !x.seenDate).length > 0;
      });

    this.isNotificationsShowing$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((showing) => {
        this.isNotificationsShowing = showing;
      });

    this.isDownloadsShowing$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((showing) => {
        this.isDownloadsShowing = showing;
      });
      
      if (this.rbacService.isGrantedAnyAccount(MindflickPermissionEnum.ViewCoachFeedback)) {
        this.viewCoachFeedback = true;
      }
  }

  navigateToSection(link: MenuLink) {
    this.hideSubMenuLargePopout();
    this.onNavItemHover(link.title);
    if (link.routerLink != null) this.router.navigate(link.routerLink);
  }

  navigateHome() {
    this.hideSubMenuLargePopout();
    this.router.navigate(['/dashboard']);
  }

  hideSubMenuDropdown() {
    this.store.dispatch(
      SetSubMenuDropdown({ subMenuDropdown: SubMenuDropdownEnum.None })
    );
  }

  hideSubMenuSmallPopout() {
    this.store.dispatch(
      SetSubMenuSmallPopout({ subMenuSmallPopout: SubMenuSmallPopoutEnum.None })
    );
  }

  hideSubMenuLargePopout() {
    this.store.dispatch(
      SetSubMenuLargePopout({ subMenuLargePopout: SubMenuLargePopoutEnum.None })
    );
  }

  onNavItemHover(option: string) {
    //may have to review how we toggle off from dashboard as mouse can escape left
    if (option === 'Growth') {
      this.store.dispatch(
        SetSubMenuDropdown({ subMenuDropdown: SubMenuDropdownEnum.Growth })
      );
    } else {
      this.store.dispatch(
        SetSubMenuDropdown({ subMenuDropdown: SubMenuDropdownEnum.None })
      );
    }
  }

  login() {
    this.oidcSecurityService.authorize();
  }
  logout() {
    localStorage.clear();
    this.oidcSecurityService.logoff().subscribe(() => {
      //Subscription is required to actually logoff
    });
  }
  toggleNotifications() {
    if (this.isNotificationsShowing) {
      this.hideSubMenuSmallPopout();
    } else {
      this.notifications$.pipe(take(1)).subscribe((notifications) => {
        let unseenIds = notifications
          .filter((x) => !x.seenDate)
          .map((x) => x.id);

        if (unseenIds.length)
          this.store.dispatch(
            MarkNotificationAsSeen.Request({ notificationIds: unseenIds })
          );
      });
      this.store.dispatch(
        SetSubMenuSmallPopout({
          subMenuSmallPopout: SubMenuSmallPopoutEnum.Notification,
        })
      );
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.unsubscribe();
  }
}
