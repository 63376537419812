import { IconName, IconPrefix } from '@fortawesome/free-solid-svg-icons';

export type SidebarRoutesType = {
  [key in
    | 'individual'
    | 'accounts'
    | 'admin'
    | 'superadmin']: SidebarRouteItemValueType;
};

export type SidebarRouteItemValueType = {
  nestedRoutes: NestedRouteType[];
  isOpened: boolean;
  title: string;
  icon: [IconPrefix, IconName];
  path?: string;
};

export type NestedRouteType = {
  title: string;
  path?: string;
  isOpened: boolean;
  fragments:
    | {
        title: string;
        path: string;
        isOpened: boolean;
      }[]
    | [];
};

export const SidebarRoutesStructure: SidebarRoutesType = {
  individual: {
    nestedRoutes: [],
    isOpened: false,
    title: 'Individuals',
    icon: ['far', 'user'],
    path: '/individual',
  },
  accounts: {
    nestedRoutes: [],
    isOpened: false,
    title: 'Accounts',
    icon: ['fas', 'puzzle-piece'],
    path: '/accounts',
  },
  admin: {
    nestedRoutes: [],
    isOpened: false,
    title: 'Admin',
    icon: ['fas', 'toolbox'],
    path: '/admin',
  },
  superadmin: {
    nestedRoutes: [],
    isOpened: false,
    title: 'Super Admin',
    icon: ['fas', 'toolbox'],
    path: '/super-admin',
  },
};
