import { Injectable } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Observable, of } from 'rxjs';
import { ChooseFileModalComponent } from '../components/choose-file-modal/choose-file-modal.component';
import { ConfirmModalComponent } from '../components/confirm-modal/confirm-modal.component';
import { take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/+state/app.state';
import { PutConnectionDate } from 'src/app/+state/user/user.actions';
import * as TeamActions from 'src/app/+state/team/team.actions';
import { LinkModalComponent } from '../components/link-modal/link-modal.component';
import { Team } from '../models';

@Injectable({ providedIn: 'root' })
export class DialogService {
  constructor(
    private modalService: BsModalService,
    private store: Store<AppState>
  ) {}

  confirm(
    message: string,
    title: string,
    showGoBack: boolean = false,
    confirmBtnText: string = 'Confirm',
    goBackBtnText: string = 'Go Back'
  ): Observable<boolean> {
    //pass a message for the dialog box.

    let modal = this.modalService.show(ConfirmModalComponent, {
      class: 'modal-dialog-centered confirm-modal',
      ignoreBackdropClick: true,
    });
    let content = modal.content;

    //boolean result return as Observable
    if (content) {
      content.message = message;
      content.title = title;
      content.showDenyButton = showGoBack;
      content.affirmative = confirmBtnText;
      content.negatory = goBackBtnText;

      return content.confirmed;
    }

    return of(true);
  }

  chooseFile(maxSize: number = 0): Observable<File | undefined> {
    let modal = this.modalService.show(ChooseFileModalComponent, {
      class: 'modal-dialog-centered',
    });

    let content = modal.content;

    if (content) {
      content.maxSize = maxSize;

      return content.modalClosed;
    }

    return of(undefined);
  }

  showLink(link: string, title: string) {
    this.modalService.show(LinkModalComponent, {
      initialState: {
        link,
        title,
      },
    });
  }

  showAcceptConnectionRequestDialog(
    fromUserAk: number,
    notificationId?: number,
    fromProfilePage = false
  ) {
    this.confirm(
      'When you connect with someone, you are agreeing to share some of your personal information. This includes Strengths, Wriggle Room and your overall Spotlight preferences.',
      'Accept connection request'
    )
      .pipe(take(1))
      .subscribe((result) => {
        if (result) {
          this.store.dispatch(
            PutConnectionDate.Request({
              userAK: fromUserAk,
              notificationId,
              fromProfilePage,
            })
          );
        }
      });
  }
  showRequestToJoinTeamDialog(team: Team, notificationId?: number) {
    this.confirm(
      'When you join a team, you are agreeing to share your position on the Spotlight team map with all current and future members of this team.',
      'Request to join team'
    )
      .pipe(take(1))
      .subscribe((result) => {
        if (result) {
          this.store.dispatch(
            TeamActions.RequestToJoinTeam.Request({ team: team })
          );
        }
      });
  }

  showAcceptTeamRequestDialog(teamId: number, notificationId?: number) {
    this.confirm(
      'When you join a team, you are agreeing to share your position on the Spotlight team map with all current and future members of this team.',
      'Accept team request'
    )
      .pipe(take(1))
      .subscribe((result) => {
        if (result) {
          this.store.dispatch(
            TeamActions.PutAcceptedDate.Request({
              teamId,
              notificationId,
            })
          );
        }
      });
  }
}
