<div class="flex-fill d-flex justify-space-between">
  <h2 class="col-7 pb-4">Accounts Summary</h2>
  <app-btn-tertiary class="col-5 d-flex flex-row-reverse align-items-start" [icon]="add">
    <a
      class="no-underline d-flex"
      href="mailto:switch@mindflick.co.uk"
      a
    >
      Request new account
    </a>
  </app-btn-tertiary>
</div>
<div class="table-container">
  <div class="table-content">
    <div class="d-flex flex-column">
      <div class="row gx-0 py-2 px-3 summary-headers">
        <div class="col">Account</div>
        <div class="col">Admin</div>
        <div class="col">Date added</div>
        <div class="col">Renewal date</div>
        <div class="col text-center">Used seats</div>
      </div>
      <ng-container *ngIf="accountsInOrg$ | async as accounts">
        <div *ngFor="let account of accounts">
          <app-org-accounts-summary-table-row
            [account]="account"
          >
          </app-org-accounts-summary-table-row>
        </div>
      </ng-container>
    </div>
  </div>
</div>
