import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Subject, take, takeUntil } from 'rxjs';
import { GetUserAccountsWithUserManagePermission, SetManagedUsersNameFilter } from 'src/app/+state/account-admin/account-admin.actions';
import { AppState } from 'src/app/+state/app.state';
import {
  selectIsManagedUserFilterCleared,
  selectManagedUserFilter,
  selectSeatsInAccData,
  selectSelectedUserManagementAccountId,
} from '../../../+state/account-admin/account-admin.selector';
import { faFilterSVG } from '../../../../icons';
import { AddUserModalComponent } from '../../add-user-modal/add-user-modal.component';
import { UserManagementFilterModalComponent } from '../user-management-filter-modal/user-management-filter-modal.component';
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons';
import { SeatUsageBreakdownModalComponent } from '../../seat-usage-breakdown-modal/seat-usage-breakdown-modal.component';
import { selectCurrentAccountId } from 'src/app/+state/account/account.selector';

@Component({
  selector: 'app-user-management-header',
  templateUrl: './user-management-header.component.html',
  styleUrls: ['./user-management-header.component.scss'],
})
export class UserManagementHeaderComponent implements OnInit, OnDestroy {
  destroyed$ = new Subject<boolean>();
  managedUserFilter$ = this.store.select(selectManagedUserFilter);
  filterClear$ = this.store.select(selectIsManagedUserFilterCleared);
  seatsInOrg$ = this.store.select(selectSeatsInAccData);
  selectedAccountId$ = this.store.select(selectSelectedUserManagementAccountId);

  currentSearchTerm: string = '';
  isFilterApplied = false;
  url = '';

  filter = faFilterSVG;
  questionIcon = faCircleQuestion;

  constructor(
    private modalService: BsModalService,
    private store: Store<AppState>,
  ) { }

  ngOnInit(): void {
    this.filterClear$.pipe(takeUntil(this.destroyed$)).subscribe(isClear => {
      this.currentSearchTerm = isClear ? '' : this.currentSearchTerm;
    });
  }

  currentSearch(filterTerm: string) {
    this.store.dispatch(
      SetManagedUsersNameFilter({ searchTerm: filterTerm.trim() })
    );
  }

  addUser() {
    this.modalService.show(AddUserModalComponent, {
      class: 'modal-dialog-centered',
      ignoreBackdropClick: true,
    });
  }

  toggleShowFilter() {
    this.modalService.show(UserManagementFilterModalComponent, {
      class: 'modal-dialog-centered',
    });
  }

  openSeatUsageBreakdown() {
    this.modalService.show(SeatUsageBreakdownModalComponent);
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.unsubscribe();
  }
}
