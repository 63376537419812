import { Dictionary } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import { Tooltip, TooltipSection } from '../../shared/models';
import { AppState, tooltipStateKey } from '../app.state';
import { tooltipAdapter, tooltipSectionAdapter } from './tooltip.reducer';

export const selectTooltipState = (state: AppState) => state[tooltipStateKey];
export const selectTooltipSectionState = (state: AppState) =>
  state[tooltipStateKey].sections;

// get the selectors
const {
  selectIds: allTooltipIds,
  selectEntities: allTooltipEntities,
  selectAll: allTooltips,
  selectTotal: totalTooltips,
} = tooltipAdapter.getSelectors(selectTooltipState);

export const selectAllTooltipIds = allTooltipIds;
export const selectAllTooltipEntities = allTooltipEntities;
export const selectAllTooltips = allTooltips;

const {
  selectIds: allTooltipSectionIds,
  selectEntities: allTooltipSectionEntities,
  selectAll: allTooltipSections,
  selectTotal: totalTooltipSections,
} = tooltipSectionAdapter.getSelectors(selectTooltipSectionState);

export const selectAllTooltipSectionIds = allTooltipSectionIds;
export const selectAllTooltipSectionEntities = allTooltipSectionEntities;
export const selectAllTooltipSections = allTooltipSections;

export const selectIsLoadingAllTooltips = (state: AppState) =>
  state[tooltipStateKey].allTooltipsLoading;
export const selectAllTooltipsLoaded = (state: AppState) =>
  state[tooltipStateKey].allTooltipsLoaded;

export const selectTooltipId = (state: AppState) =>
  state[tooltipStateKey].editingTooltipId;
export const selectEditingTootlip = createSelector(
  selectAllTooltipEntities,
  selectTooltipId,
  (tooltips: Dictionary<Tooltip>, tooltipId?: number) => {
    if (tooltips && tooltipId) return tooltips[tooltipId];

    return null;
  }
);

export const selectSaveTooltipResult = (state: AppState) =>
  state[tooltipStateKey].saveTooltipResult;
export const selectSavingTooltip = (state: AppState) =>
  state[tooltipStateKey].savingTooltip;
export const selectSaveTooltipError = (state: AppState) =>
  state[tooltipStateKey].saveTooltipError;

export const selectSelectedTooltipId = (state: AppState) =>
  state[tooltipStateKey].selectedTooltipId;
export const selectSelectedTooltipSectionId = (state: AppState) =>
  state[tooltipStateKey].selectedTooltipSectionId;

// Once the user has selected a tooltip section this selector can be used to fetch the list of tooltips within that section
export const selectTooltipsInSelectedSection = createSelector(
  selectAllTooltips,
  selectSelectedTooltipSectionId,
  (tooltips: Tooltip[], sectionId?: number) => {
    if (tooltips && sectionId)
      return tooltips.filter((x) => x.sectionId == sectionId);

    return [];
  }
);

export const selectSelectedTooltipSection = createSelector(
  selectAllTooltipSectionEntities,
  selectSelectedTooltipSectionId,
  (sections?: Dictionary<TooltipSection>, id?: number) => {
    if (sections && id) return sections[id];

    return null;
  }
);

// Once the user has selected a tooltip this selector can be used to retrieve the tooltip details
export const selectSelectedTooltip = createSelector(
  selectAllTooltipEntities,
  selectSelectedTooltipId,
  (tooltips: Dictionary<Tooltip>, tooltipId?: number) => {
    if (tooltips && tooltipId) return tooltips[tooltipId];

    return null;
  }
);

export const selectSelectedPageId = (state: AppState) =>
  state[tooltipStateKey].selectedPageId!;

export const selectSelectedPage = createSelector(
  selectSelectedTooltip,
  selectSelectedPageId,
  (tooltip?: Tooltip | null, pageId?: number) => {
    if (tooltip && pageId != undefined)
      return tooltip.pages.find((x) => x.orderBy === pageId);

    return undefined;
  }
);

export const selectSelectedSectionNumberOfPage = createSelector(
  selectAllTooltips,
  selectSelectedTooltipId,

  (tooltips: Tooltip[], tooltipId?: number) => {
    if (tooltips && tooltipId)
      return tooltips.find((x) => x.id == tooltipId)?.pages.length;
    return undefined;
  }
);
