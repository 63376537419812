import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-tips-to-connect-circles',
  templateUrl: './tips-to-connect-circles.component.html',
  styleUrls: ['./tips-to-connect-circles.component.scss'],
})
export class TipsToConnectCirclesComponent implements OnInit, OnChanges {
  @Input()
  personOneFlex = '';

  @Input()
  personOneCope = '';

  @Input()
  personTwoFlex = '';

  @Input()
  personTwoCope = '';

  TL = '#FFFFFF';
  TR = '#FFFFFF';
  BL = '#FFFFFF';
  BR = '#FFFFFF';

  constructor() {}

  ngOnInit(): void {
    this.setCircleColors();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setCircleColors();
  }

  private setCircleColors() {
    this.setBottomRightCircle();
    this.setBottomLeftCircle();
    this.setTopRightCircle();
    this.setTopLeftCircle();
  }

  private setBottomRightCircle() {
    switch (this.personTwoFlex) {
      case 'F':
        this.BR = '#FF2E5E';
        break;
      case 'L':
        this.BR = '#83A9FF';
        break;
      case 'E':
        this.BR = '#31C99A';
        break;
      case 'X':
        this.BR = '#FECF33';
    }
  }

  private setBottomLeftCircle() {
    switch (this.personTwoCope) {
      case 'C':
        this.BL = '#000000';
        break;
      case 'O':
        this.BL = '#FF5F00';
        break;
      case 'P':
        this.BL = '#7000DD';
        break;
      case 'E':
        this.BL = '#878787';
    }
  }

  private setTopRightCircle() {
    switch (this.personOneFlex) {
      case 'F':
        this.TR = '#FF2E5E';
        break;
      case 'L':
        this.TR = '#83A9FF';
        break;
      case 'E':
        this.TR = '#31C99A';
        break;
      case 'X':
        this.TR = '#FECF33';
    }
  }

  private setTopLeftCircle() {
    switch (this.personOneCope) {
      case 'C':
        this.TL = '#000000';
        break;
      case 'O':
        this.TL = '#FF5F00';
        break;
      case 'P':
        this.TL = '#7000DD';
        break;
      case 'E':
        this.TL = '#878787';
    }
  }
}
