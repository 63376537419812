import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { BsModalService } from 'ngx-bootstrap/modal';
import { GetAllMindflickAccounts } from 'src/app/+state/account/account.actions';
import { GetEmailTemplates } from 'src/app/+state/admin/admin.actions';
import { AppState } from '../../+state/app.state';
import { SetSubMenuDropdown } from '../../+state/layout/actions/layout.actions';
import { SubMenuDropdownEnum } from '../../+state/layout/reducers/layout.reducers';
import { BulkInviteUsersComponent } from '../bulk-invite-users/bulk-invite-users.component';
import { SendEmailsModalComponent } from '../send-emails-modal/send-emails-modal.component';

@Component({
  selector: 'app-super-admin-dropdown',
  templateUrl: './super-admin-dropdown.component.html',
  styleUrls: ['./super-admin-dropdown.component.scss'],
})
export class SuperAdminDropdownComponent implements OnInit {
  isMouseWithinDropdown: boolean = true;

  constructor(
    private store: Store<AppState>,
    private modalService: BsModalService
  ) {}

  ngOnInit(): void {
    this.store.dispatch(GetAllMindflickAccounts.Request());
    this.store.dispatch(GetEmailTemplates.Request());
  }

  hideSuperAdminList() {
    this.isMouseWithinDropdown = false;
    setTimeout(() => {
      if (!this.isMouseWithinDropdown) {
        this.store.dispatch(
          SetSubMenuDropdown({ subMenuDropdown: SubMenuDropdownEnum.None })
        );
      }
    }, 1000);
  }

  showSuperAdminList() {
    this.isMouseWithinDropdown = true;
  }

  openBulkInviteModal() {
    this.modalService.show(BulkInviteUsersComponent);
  }

  showSendEmailsModal() {
    this.modalService.show(SendEmailsModalComponent);
  }
}
