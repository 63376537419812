import { ActionReducerMap } from '@ngrx/store';
import * as fromUserState from './user/user.reducer';
import * as fromAccountState from './account/account.reducer';
import * as fromStatusState from './status/status.reducer';
import * as fromSignalrState from './signalr/signalr.reducer';
import * as fromProfileState from './profile/profile.reducer';
import * as fromTeamState from './team/team.reducer';
import * as fromSurveyState from './survey/reducers/survey.reducer';
import * as fromLayoutState from './layout/reducers/layout.reducers';
import * as fromGrowthState from './growth/growth.reducer';
import * as fromTooltipState from './tooltip/tooltip.reducer';
import * as fromAuditState from './audit/audit.reducer';
import * as fromAdminState from './admin/admin.reducer';
import * as fromAccountAdminState from './account-admin/account-admin.reducer';
import * as fromSuperAdminState from './super-admin/super-admin.reducer';
import * as fromDebriefState from './debrief/debrief.reducer';

export const userStateKey = 'userState';
export const accountStateKey = 'accountState';
export const statusStateKey = 'statusState';
export const signalrStateKey = 'signalrState';
export const profileStateKey = 'profileState';
export const teamStateKey = 'teamState';
export const surveyStateKey = 'surveyState';
export const layoutStateKey = 'layoutState';
export const growthStateKey = 'growthState';
export const tooltipStateKey = 'tooltipState';
export const auditStateKey = 'auditState';
export const adminStateKey = 'adminState';
export const accountAdminStateKey = 'accountAdminState';
export const superAdminStateKey = 'superAdmiState';
export const debriefStateKey = 'debriefState';

export interface AppState {
  [userStateKey]: fromUserState.UserState;
  [accountStateKey]: fromAccountState.AccountState;
  [statusStateKey]: fromStatusState.StatusState;
  [signalrStateKey]: fromSignalrState.SignalrState;
  [profileStateKey]: fromProfileState.ProfileState;
  [teamStateKey]: fromTeamState.TeamState;
  [surveyStateKey]: fromSurveyState.SurveyState;
  [layoutStateKey]: fromLayoutState.LayoutState;
  [growthStateKey]: fromGrowthState.GrowthState;
  [tooltipStateKey]: fromTooltipState.TooltipState;
  [auditStateKey]: fromAuditState.AuditState;
  [adminStateKey]: fromAdminState.AdminState;
  [accountAdminStateKey]: fromAccountAdminState.AccountAdminState;
  [superAdminStateKey]: fromSuperAdminState.SuperAdminState;
  [debriefStateKey] : fromDebriefState.DebriefState;
}

export const reducers: ActionReducerMap<AppState> = {
  [userStateKey]: fromUserState.userReducer,
  [accountStateKey]: fromAccountState.accountsReducer,
  [statusStateKey]: fromStatusState.statusReducer,
  [signalrStateKey]: fromSignalrState.signalrReducer,
  [profileStateKey]: fromProfileState.profileReducer,
  [teamStateKey]: fromTeamState.teamReducer,
  [surveyStateKey]: fromSurveyState.surveyReducer,
  [layoutStateKey]: fromLayoutState.layoutReducer,
  [growthStateKey]: fromGrowthState.growthReducer,
  [tooltipStateKey]: fromTooltipState.tooltipReducer,
  [auditStateKey]: fromAuditState.auditReducer,
  [adminStateKey]: fromAdminState.adminReducer,
  [accountAdminStateKey]: fromAccountAdminState.accountAdminReducer,
  [superAdminStateKey]: fromSuperAdminState.superAdminReducer,
  [debriefStateKey]: fromDebriefState.debriefReducer,
};
