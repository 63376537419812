import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppState } from 'src/app/+state/app.state';
import { SetUserAdminPage } from 'src/app/+state/user/user.actions';
import {
    selectUserAdminPage
} from 'src/app/+state/user/user.selector';
import {
    AccountAdminGetRemainingSeatsForAcc,
    ClearManagedUsersFilter
} from '../../+state/account-admin/account-admin.actions';
import { selectSelectedUserManagementAccountId } from 'src/app/+state/account-admin/account-admin.selector';

@Component({
  selector: 'app-user-manage',
  templateUrl: './user-manage.component.html',
  styleUrls: ['./user-manage.component.scss'],
})
export class UserManageComponent implements OnInit, OnDestroy {
  destroyed$ = new Subject<boolean>();
  selectedAccountId$ = this.store.select(selectSelectedUserManagementAccountId);

  currentPage$ = this.store.select(selectUserAdminPage);

  currentPage = 1;

  constructor(
    private store: Store<AppState>,
  ) {}

  ngOnInit(): void {
    this.selectedAccountId$.pipe(takeUntil(this.destroyed$)).subscribe(accountId => {
      if (accountId) {
        this.store.dispatch(AccountAdminGetRemainingSeatsForAcc.Request({ accountId }));
      }
    })
    

    this.currentPage$.pipe(takeUntil(this.destroyed$)).subscribe((page) => {
      this.currentPage = page;
    });
  }

  setCurrentPage(page: number) {
    this.store.dispatch(SetUserAdminPage({ page }));
  }

  ngOnDestroy() {
    this.store.dispatch(ClearManagedUsersFilter());
    this.destroyed$.next(true);
    this.destroyed$.unsubscribe();
  }
}
