import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import {
  catchError,
  filter,
  map,
  mergeMap,
  withLatestFrom,
} from 'rxjs/operators';
import { AuditService } from 'src/app/shared/services/audit.service';
import { AppState, auditStateKey } from '../app.state';
import * as AuditActions from './audit.actions';

@Injectable()
export class AuditEffects {
  saveEvents$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuditActions.SaveEvents.Request),
      withLatestFrom(this.store),
      mergeMap(([_, state]) => {
        if (!state[auditStateKey].eventsSaving) {
          if (state[auditStateKey].auditEvents.length > 0) {
            // Need to dispatch a new action to set events saving flag as reducer happens before effects
            this.store.dispatch(AuditActions.SetAuditsSaving());

            return this.auditService
              .saveAudit(state[auditStateKey].auditEvents)
              .pipe(
                mergeMap((resp) => [
                  AuditActions.SaveEvents.Success(),
                  AuditActions.ClearEvents({
                    events: state[auditStateKey].auditEvents,
                  }),
                ]),
                catchError((error) =>
                  of(AuditActions.SaveEvents.Fail({ error }))
                )
              );
          } else
            return of(
              AuditActions.SaveEvents.Fail({
                error: 'No audit entries to save',
              })
            );
        }

        return of(
          AuditActions.SaveEvents.Fail({
            error: 'Audit save already in progress',
          })
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private auditService: AuditService,
    private store: Store<AppState>
  ) {}
}
