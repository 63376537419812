import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/+state/app.state';
import {
  SetSelectedGrowth,
  SetSelectedHeading,
} from 'src/app/+state/growth/growth.actions';
import { GrowthType } from 'src/app/+state/growth/growth.reducer';
import { SetSubMenuLargePopout } from 'src/app/+state/layout/actions/layout.actions';
import { SubMenuLargePopoutEnum } from 'src/app/+state/layout/reducers/layout.reducers';
import { WorkOnType } from 'src/app/shared/models';
import { faPinOutlineSVG } from 'src/icons';
import { CardSection } from '../../models/carousel-card-layouts.interface';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardComponent implements OnInit {
  @Input() title?: string;
  @Input() summary?: string;
  @Input() id?: number;
  @Input()
  set imageUrl(value: string | undefined) {
    if (value)
      this.encodedImageUri = "\"" +encodeURI(value) + "\"";
    else
      this.encodedImageUri = undefined;
  }
  @Input() growthTypeEnum: GrowthType = GrowthType.WorkOn;
  @Input() growthType: string = '';
  @Input() workOnType: WorkOnType = WorkOnType.None;
  @Input() sections: CardSection[] = [];

  @Input() tagIds?: number[] = [];
  @Input() tags?: string[] = [];
  @Input() hasRightMargin: boolean = true;
  @Input() minWidthPx: number = 325;
  @Input() widthVw: number = 25;

  @Input() canPin: boolean = false;

  @Output()
  pinClicked: EventEmitter<number> = new EventEmitter<number>();

  WorkOnType = WorkOnType;
  timeToRead: number = 0;

  isHovering = false;

  pinOutline = faPinOutlineSVG;
  encodedImageUri?: string;

  constructor(private store: Store<AppState>) { }

  ngOnInit(): void {
    this.timeToRead = 0;
    this.sections.forEach(
      (section) =>
      (this.timeToRead += Math.ceil(
        section.contentHtml.split(/\s+/).length / 250
      ))
    );
  }

  setSelectedPopout() {
    this.store.dispatch(
      SetSelectedGrowth({
        growthId: this.id!,
        growthType: this.growthTypeEnum,
        engagementResourceId: this.id,
      })
    );
    this.store.dispatch(
      SetSubMenuLargePopout({
        subMenuLargePopout: SubMenuLargePopoutEnum.GrowthDetails,
      })
    );
    this.store.dispatch(SetSelectedHeading({ heading: this.title! }));
  }

  onMouseEnter() {
    this.isHovering = true;
  }

  onMouseLeave() {
    this.isHovering = false;
  }

  onPinClicked(event: MouseEvent) {
    event.stopPropagation();

    if (this.canPin) this.pinClicked.emit(this.id);
  }
}
