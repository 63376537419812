<div *ngIf="originalItems.length > 0; else emptyState" [class.mb-35]="!isMoreThanOnePage">
  <ng-content select="[tabHeading]"></ng-content>

  <p *ngIf="!displayItemsTemplate">No display items template defined</p>

  <ng-container *ngTemplateOutlet="displayItemsTemplate; context: {displayItems: displayItems}">  </ng-container>

  <pagination *ngIf="isMoreThanOnePage && limit!=0"
              id="test-your-teams-pagination"
              class="mt-2"
              [(ngModel)]="currentPage"
              [totalItems]="originalItems.length"
              [itemsPerPage]="limit"
              [customPreviousTemplate]="prevTemplate"
              [customNextTemplate]="nextTemplate"
              (pageChanged)="setCurrentPage($event.page)"></pagination>
</div>

<ng-template #emptyState>
  <div *ngIf="!isLoading" class="d-flex align-content-center justify-content-center my-35">
    <p class="text-muted body-copy">{{emptyStateText}}</p>
  </div>

  <div *ngIf="isLoading" class="w-100 h-100 d-flex align-content-center justify-content-center">
    <div class="my-5 p-3 spinner-border text-primary">
    </div>
  </div>
</ng-template>

<ng-template #prevTemplate
             let-disabled="disabled"
             let-currentPage="currentPage">
  <app-btn-action [icon]="back"
                  [iconDimensions]="36"
                  [secondary]="true"
                  [disabled]="disabled">
  </app-btn-action>
</ng-template>

<ng-template #nextTemplate
             let-disabled="disabled"
             let-currentPage="currentPage">
  <app-btn-action [icon]="next"
                  [iconDimensions]="36"
                  [secondary]="true"
                  [disabled]="disabled">
  </app-btn-action>
</ng-template>
