<div class="dropdown-container position-absolute">
  <div
    class="growth-list-container pb-3 bg-black"
    (mouseleave)="onMouseLeaveList()"
       (mouseenter)="showGrowthList()"
  >
    <div class="growth-list-content">
      <h2 class="text-white">Growth</h2>

      <div class="border-bottom-grey d-flex growth-list-card ">
        <h1 class="text-white name-translate">Team Playbooks</h1>

        <div class="d-flex flex-row align-items-end mx-1">
          <app-btn-action
            [icon]="next"
            [iconDimensions]="35"
            (click)="navigateTo('playbooks')"
          ></app-btn-action>
        </div>

        <div class="d-flex flex-column-reverse flex-grow-1 align-items-end">
          <div class="d-flex flex-row px-3 align-items-end">
            <div class="d-flex flex-row">
              <div class="d-flex flex-column px-3 ">
                <div class="highlight-numbers fw-bold text-white">
                  {{ numberOfPlaybooks }}
                </div>
                <h3 class="fw-bold text-white">Playbooks</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="border-bottom-grey d-flex growth-list-card">
        <h1 class="text-white name-translate">Individual Work Ons</h1>

        <div class="d-flex flex-row align-items-end mx-1">
          <app-btn-action
            [icon]="next"
            [iconDimensions]="35"
            (click)="navigateTo('work-ons')"
          ></app-btn-action>
        </div>

        <div class="d-flex flex-column-reverse flex-grow-1 align-items-end">
          <div class="d-flex flex-row  px-3 align-items-end">
            <div class="d-flex flex-row">
              <div class="d-flex flex-column px-3 py-0">
                <div class="highlight-numbers fw-bold text-white">
                  {{ numberOfWorkOns }}
                </div>
                <h3 class="fw-bold text-white">Work Ons</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="shadow-container"></div>
</div>
