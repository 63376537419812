import { Component, EventEmitter, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AppState } from '../../../+state/app.state';
import { AddEvent } from '../../../+state/audit/audit.actions';
import { faCloseSVG } from '../../../../icons';
import { AuditEventType, PlatformAreas } from '../../models/enums/audit.enum';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss'],
})
export class ConfirmModalComponent implements OnInit {
  message: string = 'Are you sure?';
  title: string = 'Confirm';
  showDenyButton?: boolean;

  affirmative = 'Confirm';
  negatory = 'Go Back';

  confirmed = new EventEmitter<boolean>();
  closeIcon = faCloseSVG;

  constructor(private modalRef: BsModalRef, private store: Store<AppState>) {}

  ngOnInit(): void {}

  confirm() {
    if (this.title == 'Discard Changes?') {
      this.store.dispatch(
        AddEvent({
          event: {
            eventDate: new Date(),
            eventType: AuditEventType.DiscardTeamClicked,
            area: PlatformAreas.Teams,
          },
        })
      );
    }
    this.confirmed.emit(true);
    this.close();
  }

  deny() {
    this.confirmed.emit(false);
    this.close();
  }

  close() {
    this.modalRef.hide();
  }
}
