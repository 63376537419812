<main class="main">
  <section class="main-container transition-option">
    <div class="content-box">
      <div class="content w-100">
        <div id="content">
          <div class="common-header">
            <h1>Mindflick Platform</h1>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>
