import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subject, takeUntil } from 'rxjs';
import { AppState } from '../../../+state/app.state';
import { CreatePulseSurvey } from '../../../+state/user/user.actions';
import { selectIsPulseSurveyDue } from '../../../+state/user/user.selector';
import { faCloseSVG } from '../../../../icons';
import { fadeInOut } from '../../helpers/animations';
import { PMFAnswer } from '../../models/enums/pmf-survey.enum';
import { PulseSurvey } from '../../models/pulse-survey.interface';

@Component({
  selector: 'app-pulse-survey',
  templateUrl: './pulse-survey.component.html',
  styleUrls: ['./pulse-survey.component.scss'],
  animations: [fadeInOut],
})
export class PulseSurveyComponent implements OnInit, OnDestroy {
  destroyed$ = new Subject<boolean>();

  fgPMF = new FormGroup({
    pmfRating: new FormControl<PMFAnswer | undefined>(
      undefined,
      Validators.required
    ),
    npsRating: new FormControl<number>(0, [
      Validators.required,
      Validators.min(1),
    ]),
    followUpAnswer: new FormControl(''),
  });

  followUpQuestion = "Anything else you'd like to tell us?";
  closeIcon = faCloseSVG;

  NPSRating?: number;
  pmfAnswerEnum = PMFAnswer;

  isSurveyCompleted = false;
  constructor(private store: Store<AppState>) {}

  ngOnInit() {
    this.pmfRating.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((x) => {
        this.setFollowUpQuestion(x ?? undefined);
      });
  }

  setFollowUpQuestion(disappointment?: PMFAnswer) {
    switch (disappointment) {
      case this.pmfAnswerEnum.VeryDisappointed:
        this.followUpQuestion =
          'What is the main benefit you get from using Mindflick?';
        break;
      case this.pmfAnswerEnum.SomewhatDisappointed:
        this.followUpQuestion =
          'How can we improve Mindflick to better meet your needs?';
        break;
      case this.pmfAnswerEnum.NotDisappointed:
        this.followUpQuestion = 'What could we improve on?';
        break;
      default:
        this.followUpQuestion = "Anything else you'd like to tell us?";
        break;
    }
  }

  submit() {
    if (this.pmfRating.invalid) return;

    this.isSurveyCompleted = true;
    var survey: PulseSurvey = {
      pmfRating: this.pmfRating.value!,
      npsRating: this.npsRating.value!,
      followUpAnswer: this.fgPMF.controls.followUpAnswer.value!,
    };

    setTimeout(() => {
      this.store.dispatch(CreatePulseSurvey.Request({ survey }));
    }, 3000);
  }

  close() {
    if (!this.isSurveyCompleted)
      this.store.dispatch(CreatePulseSurvey.Request({ survey: {} }));
  }

  setNPS(rating: number) {
    this.NPSRating = rating;
    this.npsRating.setValue(rating);
  }

  get pmfRating() {
    return this.fgPMF.controls.pmfRating;
  }
  get npsRating() {
    return this.fgPMF.controls.npsRating;
  }

  get followUp() {
    return this.fgPMF.controls.followUpAnswer;
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.unsubscribe();
  }
}
