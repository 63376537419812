import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { faCloseSVG } from 'src/icons';

@Component({
  selector: 'app-account-locked-info-modal',
  templateUrl: './account-locked-info-modal.component.html',
  styleUrls: ['./account-locked-info-modal.component.scss']
})
export class AccountLockedInfoModalComponent {

  close = faCloseSVG;

  constructor(
    public bsModalRef: BsModalRef,
  ) {}

  hideModal() {
    this.bsModalRef.hide();
  }
}
