import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { faDropdownSVG, faSearchSVG } from '../../../../icons';
import { SelectValue } from '../../models';

@Component({
  selector: 'app-multi-select-dropdown',
  templateUrl: './multi-select-dropdown.component.html',
  styleUrl: './multi-select-dropdown.component.scss'
})
export class MultiSelectDropdownComponent implements OnChanges {
  @Input() items: SelectValue[] = [];
  @Input()
  set preSelected(value: SelectValue[]) {
    this.selectedItems = value;
    this.selectionChange.emit(this.selectedItems);
    this.setPlaceholder();
  }

  @Output() selectionChange = new EventEmitter<SelectValue[]>();

  selectedItems: SelectValue[] = [];
  isDropdownOpen = false;
  searchTerm: string = '';
  filteredItems: SelectValue[] = [];

  dropdown = faDropdownSVG;
  search = faSearchSVG;

  placeholder = 'Please select';

  ngOnChanges(changes: SimpleChanges) {
    if (changes.items.previousValue != changes.items.currentValue) {
      this.filteredItems = this.items;
    }
  }

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  onSelect(item: SelectValue) {
    if (this.isSelected(item)) {
      this.selectedItems = this.selectedItems.filter(i => i.value !== item.value);
    } else {
      this.selectedItems.push(item);
    }
    this.selectionChange.emit(this.selectedItems);
    this.setPlaceholder();
  }

  isSelected(item: SelectValue): boolean {
    return this.selectedItems.includes(item);
  }

  filterItems() {
    const lowerSearchTerm = this.searchTerm.toLowerCase();
    this.filteredItems = this.items.filter(item =>
      item.description.toLowerCase().includes(lowerSearchTerm)
    );
  }

  setPlaceholder() {
    this.placeholder = this.selectedItems.length ? this.selectedItems.length + ' items selected' : 'Search';
  }

  resetInput() {
    this.setPlaceholder();
    this.searchTerm = '';
    this.filterItems();
  }
}
