import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-send-invite',
  templateUrl: './send-invite.component.html',
  styleUrls: ['./send-invite.component.css'],
})
export class SendInviteComponent implements OnInit {
  constructor(public modalRef: BsModalRef) {}

  ngOnInit(): void {}

  sendInvite() {
    console.log('Sending Invite');

    this.modalRef.hide();
  }
}
