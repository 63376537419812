<div class="modal-header justify-content-end">
    <fa-icon
      class="cursor-pointer fa-lg"
      [icon]="closeIcon"
      (click)="close()"
    ></fa-icon>
  </div>
  <div class="modal-body">
    <h2 class="mb-4">Add note for {{ user?.name }}</h2>
    <form [formGroup]="addNoteForm" class="row mb-4">
        <textarea class="form-control" rows="8" formControlName="note">
        </textarea>
    </form>
    <button
      class="btn btn-purple w-100"
      (click)="saveChanges()"
    >
      Save Changes
    </button>
  </div>
  <div class="modal-bottom-border"></div>
  