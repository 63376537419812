<div class="form-control d-flex flex-wrap" [class.disabled]="disabled">
  <ng-container *ngIf="selectionObj.length > 0">
    <div *ngFor="let item of selectionObj" class="selection me-3 px-1 d-flex align-items-center text-center" (click)="onRemove(item)">
      {{ item.name }}<fa-icon class="px-1 fa-lg" [icon]="close"></fa-icon>
    </div>
  </ng-container>
  <input *ngIf="availableList"
  class="flex-grow-1"
  [(ngModel)]="selectionStr"
  [typeahead]="availableList"
  typeaheadOptionField="name"
  [typeaheadMinLength]="0"
  [typeaheadScrollable]="true"
  [typeaheadOptionsInScrollableView]="8"
  [disabled]="disabled"



  (typeaheadOnSelect)="onSelect($event.item)"
  />
  <fa-icon [icon]="dropdown"></fa-icon>
</div>

