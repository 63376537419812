import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { AppState } from 'src/app/+state/app.state';
import { selectMyTeams } from 'src/app/+state/team/team.selector';
import { NavBarType, NavigationItem, Team } from '../../models';
import { navStructure } from './navigation-structure';

@Component({
  selector: 'app-side-navbar',
  templateUrl: './side-navbar.component.html',
  styleUrls: ['./side-navbar.component.css'],
})
export class SideNavbarComponent implements OnInit, OnDestroy {
  myTeams$ = this.store.select(selectMyTeams);

  destroyed$ = new Subject<boolean>();

  structure = navStructure;

  constructor(
    private store: Store<AppState>,
    public oidcSecurityService: OidcSecurityService
  ) {}

  public isAuthenticated$ = this.oidcSecurityService.isAuthenticated$.pipe(
    map((isAuthenticated) => {
      return isAuthenticated?.isAuthenticated;
    })
  );

  ngOnInit(): void {
    this.myTeams$.pipe(takeUntil(this.destroyed$)).subscribe((teams) => {
      this.addMyTeamsToNavbar(teams);
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.unsubscribe();
  }

  addMyTeamsToNavbar(teams: Team[]) {
    let teamSection = this.structure.find((x) => x.id === 'teams');

    if (teamSection) {
      teamSection.children = teams.map((x) => ({
        id: x.id.toString(),
        title: x.name,
        type: NavBarType.Default,
        children: [],
        path: `/teams/${x.id}`,
      }));
    }
  }

  logout() {
    this.oidcSecurityService.logoff().subscribe(() => { });
  }

  login() {
    this.oidcSecurityService.authorize();
  }
}
