import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/+state/app.state';
import { SetSubMenuDropdown } from 'src/app/+state/layout/actions/layout.actions';
import { SubMenuDropdownEnum } from 'src/app/+state/layout/reducers/layout.reducers';
import { faNextSVG } from '../../../icons';

@Component({
  selector: 'app-account-admin-dropdown',
  templateUrl: './account-admin-dropdown.component.html',
  styleUrls: ['./account-admin-dropdown.component.scss'],
})
export class AccountAdminDropdownComponent implements OnInit {
  next = faNextSVG;
  isMouseWithinDropdown: boolean = true;

  constructor(private store: Store<AppState>, private router: Router) {}

  ngOnInit(): void {}

  navigateTo(route: string) {
    this.router.navigate([`/admin/${route}`]);

    this.hideAdminList();
  }

  hideAdminList() {
    this.isMouseWithinDropdown = false;

    setTimeout(() => {
      if (!this.isMouseWithinDropdown) {
        this.store.dispatch(
          SetSubMenuDropdown({ subMenuDropdown: SubMenuDropdownEnum.None })
        );
      }
    }, 1000);
  }

  showAdminList() {
    this.isMouseWithinDropdown = true;
  }
}
