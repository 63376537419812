import { createSelector } from '@ngrx/store';
import { Team, User } from '../../../shared/models';
import { ConnectionStatus } from '../../../shared/models/enums/connection-status.enum';
import { AppState, layoutStateKey } from '../../app.state';
import { selectAllMyTeamRequest } from '../../team/team.selector';
import { selectAllUsers } from '../../user/user.selector';
import {
  BackgroundStyle,
  HeaderLogo,
  LayoutState,
  SubMenuDropdownEnum,
  SubMenuLargePopoutEnum,
  SubMenuSmallPopoutEnum,
} from '../reducers/layout.reducers';

export const selectTitleBarVisible = (state: AppState) =>
  state.layoutState.titleBarVisible;

export const selectBackgroundStyle = (state: AppState) =>
  state.layoutState.backgroundStyle ?? BackgroundStyle.Blank;

export const selectHeaderLogo = (state: AppState) =>
  state.layoutState.headerLogo ?? HeaderLogo.MindFlick;

export const selectUploadImageError = (state: AppState) =>
  state.layoutState.uploadImageError ?? '';

export const selectUploadStatus = (state: AppState) =>
  state[layoutStateKey].uploadStatus;

export const selectIsWorkOnsGridShowing = (state: AppState) =>
  state.layoutState.isWorkOnsGridShowing;

export const selectIsPlaybooksGridShowing = (state: AppState) =>
  state.layoutState.isPlaybooksGridShowing;

export const selectIsDashboardLoading = (state: AppState) =>
  state[layoutStateKey].isDashboardLoading;

export const selectIsConnectionsViewed = (state: AppState) =>
  state[layoutStateKey].isConnectionsViewed;

export const selectSubMenuDropdown = (state: AppState) =>
  state.layoutState.subMenuDropdown ?? '';

export const selectShowGrowthList = createSelector(
  selectSubMenuDropdown,
  (subMenuDropdown) => subMenuDropdown == SubMenuDropdownEnum.Growth
);
export const selectShowSuperAdminList = createSelector(
  selectSubMenuDropdown,
  (subMenuDropdown) => subMenuDropdown == SubMenuDropdownEnum.SuperAdmin
);

export const selectShowAccountAdminList = createSelector(
  selectSubMenuDropdown,
  (subMenuDropdown) => subMenuDropdown == SubMenuDropdownEnum.AccountAdmin
);

export const selectSubMenuSmallPopout = (state: AppState) =>
  state.layoutState.subMenuSmallPopout ?? '';

export const selectShowTooltip = createSelector(
  selectSubMenuSmallPopout,
  (subMenuSmallPopout) => subMenuSmallPopout == SubMenuSmallPopoutEnum.Tooltip
);

export const selectShowNotifications = createSelector(
  selectSubMenuSmallPopout,
  (subMenuSmallPopout) =>
    subMenuSmallPopout == SubMenuSmallPopoutEnum.Notification
);

export const selectShowNudgeReflection = createSelector(
  selectSubMenuSmallPopout,
  (subMenuSmallPopout) =>
    subMenuSmallPopout == SubMenuSmallPopoutEnum.NudgeReflection
);

export const selectShowDownloads = createSelector(
  selectSubMenuSmallPopout,
  (subMenuSmallPopout) => subMenuSmallPopout == SubMenuSmallPopoutEnum.Downloads
);

export const selectSubMenuLargePopout = (state: AppState) =>
  state.layoutState.subMenuLargePopout ?? '';

export const selectShowGrowthDetails = createSelector(
  selectSubMenuLargePopout,
  (subMenuLargePopout) =>
    subMenuLargePopout == SubMenuLargePopoutEnum.GrowthDetails
);

export const selectShowTerms = createSelector(
  selectSubMenuLargePopout,
  (subMenuLargePopout) => subMenuLargePopout == SubMenuLargePopoutEnum.Terms
);

export const selectShowPrivacy = createSelector(
  selectSubMenuLargePopout,
  (subMenuLargePopout) => subMenuLargePopout == SubMenuLargePopoutEnum.Privacy
);

export const selectShowDataPolicy = createSelector(
  selectSubMenuLargePopout,
  (subMenuLargePopout) =>
    subMenuLargePopout == SubMenuLargePopoutEnum.DataPolicy
);

export const selectIsConnectionsPending = createSelector(
  selectAllUsers,
  selectIsConnectionsViewed,
  (users: User[], isConnectionsViewed: boolean) => {
    if (isConnectionsViewed) {
      return false;
    }

    var hasPendingUserRequest =
      users.filter((x) => x.connectionStatus == ConnectionStatus.Pending)
        .length > 0;

    return hasPendingUserRequest;
  }
);

export const selectIsTeamRequestPending = createSelector(
  selectAllMyTeamRequest,
  (teamRequests: Team[]) => {
    return teamRequests.filter((x) => x.teamHasInvitedUser).length > 0;
  }
);

const selectLayoutState = (state: AppState) => state[layoutStateKey];

export const selectSnackbarAlert = createSelector(
  selectLayoutState,
  (state: LayoutState) => {
    return state.alert;
  }
);

export const selectIsEditingTeamMechanics = createSelector(
  selectLayoutState,
  (state: LayoutState) => {
    return state.isEditingTeamMechanics;
  }
);

export const selectUserIsLocked = createSelector(
  selectLayoutState,
  (state: LayoutState) => {
    return state.userIsLocked;
  }
);

export const selectRequiresExternalAuthentication = createSelector(
  selectLayoutState,
  (state: LayoutState) => {
    return state.requiresExternalAuthentication;
  }
);



export const selectShowChampionCharter = (state: AppState) =>
  state[layoutStateKey].showChampionCharter;
