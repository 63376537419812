export interface GrowthTag {
  id: number;
  tag: string;
  orderById: number;
}

export interface newWorkOnOrder {
  workOnHistoryId: number;
  orderById: number;
}

export enum GrowthSortBy {
  None,
  Alphabetical,
}
