import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-flex-framework',
  templateUrl: './flex-framework.component.html',
  styleUrl: './flex-framework.component.scss'
})
export class FlexFrameworkComponent {
  @Input()
  selectedLetter?: string;
}
