import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject, takeUntil } from 'rxjs';
import { AppState } from '../../../+state/app.state';
import { SaveRecognizedStrengths } from '../../../+state/profile/profile.actions';
import { faCloseSVG, faNextSVG } from '../../../../icons';
import { Profile, SaveStrengthCommentDTO } from '../../../shared/models';
import { ShepherdService } from 'angular-shepherd';
import { OnboardingStepsService } from '../../../shared/services/onboarding-steps.service';

@Component({
  selector: 'app-add-strengths-comment',
  templateUrl: './add-strengths-comment.component.html',
  styleUrls: ['./add-strengths-comment.component.scss']
})
export class AddStrengthsCommentComponent implements OnInit, OnDestroy {
  shepherdService = inject(ShepherdService);
  onboardingService = inject(OnboardingStepsService);
  destroyed$ = new Subject<boolean>();


  profile?: Profile;
  strengthDescription = ''
  strengthId = ''

  close = faCloseSVG;
  next = faNextSVG;

  fcComment = new FormControl(null, Validators.required)
  fcChecked = new FormControl(false)

  constructor(private modalService: BsModalRef, private store: Store<AppState>) {
  }

  ngOnInit() {
    this.fcChecked.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(isChecked => {
      this.fcComment.setValidators(isChecked ? null : [Validators.required]);
      this.fcComment.updateValueAndValidity();
    })
  }

  hideModal() {
    this.modalService.hide();
  }

  addComment() {
    if (!this.fcComment.valid) {
      this.fcComment.markAsTouched();
      return
    }

    this.store.dispatch(SaveRecognizedStrengths.Request({
      modalRef: this.modalService.id,
      saveStrengthComment: {
        comment: this.fcComment.value ?? 'Shared feedback in person',
        strengthId: this.strengthId,
        strengthOwnerId: this.profile?.userAK,
       createdDate: new Date()
      } as SaveStrengthCommentDTO
    }))
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.unsubscribe();
  }
}
