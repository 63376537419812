import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AddUserModel } from 'src/app/admin/add-user-modal/add-user-modal.component';
import {
  MindflickAccountBasicDetails,
  MindflickOrganisation,
    MindflickRole,
    User,
    UserBasicDetails
} from '../models';
import {
    AccountAdminManagedUsersDTO,
    AccountSettings,
    AccountSummaryData,
    AddedUsersResponseModel,
    NewAccountAdminModel,
    OrganisationSettings,
    SeatsInAcc,
    UpdateUsersDelegatedInvitesModel,
} from '../models/account-admin-managed-users-DTO';
import { EngagementResourceCategory } from '../models/engagement-resource.interface';
import { HelpCenterCategory } from '../models/help-center.interface';
import { LiveAnalyticsGraph } from '../models/live-analytics/live-analytics-graph.interface';
import { DateRange } from '../models/date-range.interface';
import { RecreateUsersDTO } from '../models/reactivate-users-dto.interface';
import { EditRoleInfoInterface } from '../models/edit-role-info-model.interface';
import { AddNoteToAccountUserReq } from '../models/add-note-to-account-user';
import { AddNoteResponse } from '../models/add-note-response';

@Injectable({
  providedIn: 'root',
})
export class AccountAdminService {
  accountAdminUrl: string = '';

  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string
  ) {
    this.accountAdminUrl = `${baseUrl}accountadmin`;
  }

  deactivateUser(userAK: number, accountId: number) {
    return this.http.delete<number>(`${this.accountAdminUrl}/account/${accountId}/user/${userAK}`);
  }

  reactivateUsers(userAKs: number[], accountId: number) {
    return this.http.post<AccountAdminManagedUsersDTO[]>(`${this.accountAdminUrl}/account/${accountId}/users/reactivate`, userAKs);
  }

  recreateUsers(model: RecreateUsersDTO, accountId: number) {
    return this.http.post<AccountAdminManagedUsersDTO[]>(`${this.accountAdminUrl}/account/${accountId}/users/recreate`, model);
  }

  getAccountAdminUsers(accountId?: number) {
    return this.http.get<AccountAdminManagedUsersDTO[]>(
      `${this.accountAdminUrl}/admin/users/${accountId}`
    );
  }

  addUsers(users: AddUserModel[], accountId: number) {
    return this.http.post<AddedUsersResponseModel>(
      `${this.accountAdminUrl}/account/${accountId}/users`,
      users
    );
  }

  getMindflickAccountSettings(accId : number) {
    return this.http.get<AccountSettings>(`${this.accountAdminUrl}/settings/${accId}`);
  }

  setMindflickAccountSettings(settings: AccountSettings) {
    return this.http.post(`${this.accountAdminUrl}/settings`, settings);
  }

  setMindflickOrganisationSettings(settings: OrganisationSettings) {
    return this.http.post(`${this.accountAdminUrl}/organisation/settings`, settings);
  }

  getMindflickOrganisation() {
    return this.http.get<MindflickOrganisation>(`${this.accountAdminUrl}/organisation`);
  }

  resendAccountAdminSpotlightInvite(userAks: number[], accountId: number) {
    return this.http.post(
      `${this.accountAdminUrl}/account/${accountId}/users/resend`,
      userAks
    );
  }

  sendSignUpInvites(userAks: number[]) {
    return this.http.post(`${this.accountAdminUrl}/sign-up/invites`, userAks);
  }

  getSeatsInAccData(accountId: number) {
    return this.http.get<SeatsInAcc>(
      `${this.accountAdminUrl}/account/${accountId}/remaining-seats`
    );
  }

  getChampionViewStatus() {
    return this.http.get<boolean>(
      `${this.accountAdminUrl}/admin/champion-live-analytics-status`
    );
  }

  getEngagementResources() {
    return this.http.get<EngagementResourceCategory[]>(
      `${this.accountAdminUrl}/engagement-resources`
    );
  }

  getPinnedEngagementResources() {
    return this.http.get<number[]>(
      `${this.accountAdminUrl}/engagement-resources/pinned`
    );
  }

  addPinnedEngagementResource(engagementResourceId: number) {
    return this.http.post(
      `${this.accountAdminUrl}/engagement-resource/pin`,
      engagementResourceId
    );
  }

  deletePinnedEngagementResource(engagementResourceId: number) {
    return this.http.delete(
      `${this.accountAdminUrl}/engagement-resource/pinned/${engagementResourceId}`
    );
  }

  getHelpCenterCategories() {
    return this.http.get<HelpCenterCategory[]>(
      `${this.accountAdminUrl}/help-center/all`
    );
  }

  filterEngagementResourcesBySearchTerm(keywords: string) {
    return this.http.post<number[]>(
      `${this.accountAdminUrl}/engagement-resources/searchterms`,
      { keywords }
    );
  }

  updateCanDownloadPdf(canDownloadPdf: boolean, userAKs: number[], accountId: number) {
    var params = new HttpParams();

    params = params.append('canDownloadPdf', canDownloadPdf);

    return this.http.post(
      `${this.accountAdminUrl}/account/${accountId}/users/can-download-pdf`,
      userAKs,
      { params }
    );
  }

  unlockUser(userAk: number, accountId: number) {
    return this.http.patch(`${this.accountAdminUrl}/account/${accountId}/user/${userAk}/unlock`, {});
  }

  unlockUsers(userAks: number[], accountId: number) {
    return this.http.patch(`${this.accountAdminUrl}/account/${accountId}/users/unlock`, userAks);
  }

  updateUserDebriefDate(userAk: number, debriefDate: Date, accountId: number) {
    return this.http.patch(`${this.accountAdminUrl}/account/${accountId}/user/unlock-date`, {
      userAk,
      debriefDate,
    });
  }

  updateUsersDelegatedInvites(payload: UpdateUsersDelegatedInvitesModel, accountId: number) {
    return this.http.patch(
      `${this.accountAdminUrl}/account/${accountId}/user/delegated-invites`,
      payload
    );
  }

  downloadAdminTeamMap(memberIds: number[], accountId: number) {
    return this.http.post(`${this.accountAdminUrl}/account/${accountId}/team-map`, memberIds);
  }

  getOnboardingLiveAnalytics(accountId: number) {
    return this.http.get<LiveAnalyticsGraph[]>(`${this.accountAdminUrl}/live-analytics/onboarding/${accountId}`);
  }

  getConnectionsLiveAnalytics(accountid: number) {
    return this.http.get<LiveAnalyticsGraph[]>(`${this.accountAdminUrl}/live-analytics/connections/${accountid}`);
  }

  getTeamLiveAnalytics(accountId: number) {
    return this.http.get<LiveAnalyticsGraph[]>(`${this.accountAdminUrl}/live-analytics/teams/${accountId}`);
  }

  getInteractionLiveAnalytics(accountId: number, dateRange: DateRange) {
    let params = new HttpParams();

    params = params.append("fromDate", dateRange.fromDate.toISOString());
    params = params.append("toDate", dateRange.toDate.toISOString());

    return this.http.get<LiveAnalyticsGraph[]>(`${this.accountAdminUrl}/live-analytics/interactions/${accountId}`, { params });
  }

  getNudgeAndWorkOnLiveAnalytics(accountId: number, dateRange: DateRange) {
    let params = new HttpParams();

    params = params.append("fromDate", dateRange.fromDate.toISOString());
    params = params.append("toDate", dateRange.toDate.toISOString());

    return this.http.get<LiveAnalyticsGraph[]>(`${this.accountAdminUrl}/live-analytics/nudge-workOns/${accountId}`, { params });
  }

  getUsageLiveAnalytics(accountId: number, dateRange: DateRange) {
    let params = new HttpParams();

    params = params.append("fromDate", dateRange.fromDate.toISOString());
    params = params.append("toDate", dateRange.toDate.toISOString());

    return this.http.get<LiveAnalyticsGraph[]>(`${this.accountAdminUrl}/live-analytics/usage/${accountId}`, { params });
  }

  updateUserDetails(userDetails: EditRoleInfoInterface, accountId: number) {
    return this.http.post(`${this.accountAdminUrl}/account/${accountId}/user/update`, userDetails);
  }

  getAssignableRoles() {
    return this.http.get<MindflickRole[]>(`${this.accountAdminUrl}/roles/valid`);
  }


  addNoteToAccountUser(payload: AddNoteToAccountUserReq) {
    return this.http.post<AddNoteResponse>(`${this.accountAdminUrl}/user/add-note`, payload);
  }

  getUserAccountsWithRoleOrPermission(roleOrPermission: string) {
    return this.http.get<MindflickAccountBasicDetails[]>(`${this.accountAdminUrl}/accounts/permission/${roleOrPermission}`);
  }

  getAccountsSummaryInOrg(){
    return this.http.get<AccountSummaryData[]>(`${this.accountAdminUrl}/accounts-summary`)
  }

  setNewAccountAdmin(payload: NewAccountAdminModel){
    return this.http.post<MindflickRole | undefined>(`${this.accountAdminUrl}/account-admin/new`, payload)
  }

  getAccountChampions(accountId: number) {
    return this.http.get<UserBasicDetails[]>(`${this.accountAdminUrl}/account/${accountId}/champions`);
  }

  getAccountCoordinators(accountId: number) {
    return this.http.get<UserBasicDetails[]>(`${this.accountAdminUrl}/account/${accountId}/coordinators`);
  }

  getAccountsForAccountAdmin() {
    return this.http.get<MindflickAccountBasicDetails[]>(`${this.accountAdminUrl}/accounts`);
  }

  getAccountsForOrgAdmin() {
    return this.http.get<MindflickAccountBasicDetails[]>(`${this.accountAdminUrl}/organisation/accounts`);
  }

  addChampionToAccount(accountId: number, userAk: number) {
    return this.http.post<MindflickRole | undefined>(`${this.accountAdminUrl}/account/champion`, { mindflickAccountId: accountId, userAk });
  }

  addCoordinatorToAccount(accountId: number, userAk: number) {
    return this.http.post<MindflickRole | undefined>(`${this.accountAdminUrl}/account/coordinator`, { mindflickAccountId: accountId, userAk });
  }

  removeChampionFromAccount(accountId: number, userAk: number) {
    return this.http.delete(`${this.accountAdminUrl}/account/${accountId}/champion/${userAk}`);
  }

  removeCoordinatorFromAccount(accountId: number, userAk: number) {
    return this.http.delete(`${this.accountAdminUrl}/account/${accountId}/coordinator/${userAk}`);
  }

  getValidAccountAdminsForOrg() {
    return this.http.get<UserBasicDetails[]>(`${this.accountAdminUrl}/organisation/valid-account-admins`);
  }

  getValidChampionsForOrg() {
    return this.http.get<UserBasicDetails[]>(`${this.accountAdminUrl}/organisation/valid-champions`);
  }

  getValidCoordinatorsForOrg() {
    return this.http.get<UserBasicDetails[]>(`${this.accountAdminUrl}/organisation/valid-coordinators`);
  }
}
