import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AddUserModel } from 'src/app/admin/add-user-modal/add-user-modal.component';
import {
  EditUserDetailsModel,
  SelectedUserFlexCope,
  StandardResponse,
  TopTipToConnect,
  User,
} from '../models';
import { AddedUsersResponseModel } from '../models/account-admin-managed-users-DTO';
import { CookieSettings } from '../models/cookies';
import { PulseSurvey } from '../models/pulse-survey.interface';
import { TwoFactorAuthenticationKeys } from '../models/two-factor-authentication-keys';
import { DigitalDebriefStageEnum } from '../models/enums/digital-debrief-stage.enum';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  userUrl: string = '';

  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string
  ) {
    this.userUrl = `${baseUrl}user`;
  }

  getUser(): Observable<User> {
    return this.http.get<User>(`${this.userUrl}/current-user`);
  }

  getUserByAK(userAK: number): Observable<User> {
    return this.http.get<User>(`${this.userUrl}/alternate/${userAK}`);
  }

  getAllUsers(): Observable<User[]> {
    return this.http.get<User[]>(`${this.userUrl}/all`);
  }

  getMyConnectedUsers(): Observable<User[]> {
    return this.http.get<User[]>(`${this.userUrl}/connected/users`);
  }

  putConnectionDate(userId: number): Observable<SelectedUserFlexCope> {
    return this.http.get<SelectedUserFlexCope>(`${this.userUrl}/connect/${userId}`);
  }

  deletePendingConnection(userId: number) {
    return this.http.post(`${this.userUrl}/decline/${userId}`, {});
  }

  requestProfileAccess(
    userAK: number
  ): Observable<StandardResponse<undefined>> {
    return this.http.post<StandardResponse<undefined>>(
      `${this.userUrl}/request-access`,
      { userAK }
    );
  }

  updateUserDetails(body: EditUserDetailsModel) {
    return this.http.post<User>(`${this.userUrl}/update`, body);
  }

  sendExampleSignalrMessage(
    message: string
  ): Observable<StandardResponse<undefined>> {
    return this.http.post<StandardResponse<undefined>>(
      `${this.userUrl}/signalr-example`,
      { message: message }
    );
  }

  requestUserConnection(requestedUserGuid: string) {
    return this.http.post(`${this.userUrl}/connect-user/request`, {
      userGuid: requestedUserGuid,
    });
  }

  acceptUserConnection(requestedUserGuid: string) {
    return this.http.post(`${this.userUrl}/connect-user/accept`, {
      userGuid: requestedUserGuid,
    });
  }

  deleteUserConnection(userAK: number) {
    return this.http.delete(`${this.userUrl}/delete/${userAK}`);
  }

  updateUserPicture(formData: FormData) {
    return this.http.post<string>(`${this.userUrl}/photo/update`, formData);
  }

  setUserPicture(
    formData: FormData,
    spotlightResponseId: number,
    uniqueRef: string
  ) {
    return this.http.post<string>(
      `${this.userUrl}/${spotlightResponseId}/${uniqueRef}/photo/set`,
      formData
    );
  }
  deleteUserPicture() {
    return this.http.delete(`${this.userUrl}/photo/delete`);
  }

  generate2FARegistrationKeys(): Observable<TwoFactorAuthenticationKeys> {
    return this.http.post<TwoFactorAuthenticationKeys>(
      `${this.userUrl}/two-factor/generate`,
      {}
    );
  }

  verify2FAUserInputCode(code: string) {
    return this.http.post(`${this.userUrl}/two-factor/verify`, {
      TwoFactorCode: code,
    });
  }

  disable2FA() {
    return this.http.post(`${this.userUrl}/two-factor/disable`, {});
  }

  setPlatformSettings(key: string, value: string) {
    return this.http.post(`${this.userUrl}/platform-settings`, { key, value });
  }

  setPlatformBulkSettings(settings: { [id: string]: string; }) {
    return this.http.post(`${this.userUrl}/bulk-platform-settings`, settings);
  }

  setPlatformIntroViewedFlag() {
    return this.http.post<Date>(`${this.userUrl}/intro-viewed`, {});
  }

  getUserCookieSettings() {
    return this.http.get<CookieSettings>(`${this.userUrl}/cookie-settings`);
  }

  setUserCookieSettings(settings: CookieSettings) {
    return this.http.post(`${this.userUrl}/cookie-settings`, settings);
  }

  inviteDirectReport(payload: AddUserModel) {
    return this.http.post<AddedUsersResponseModel>(
      `${this.userUrl}/invite`,
      payload
    );
  }

  createPulseSurvey(survey: PulseSurvey) {
    return this.http.post<AddedUsersResponseModel>(
      `${this.userUrl}/pulse-survey`,
      survey
    );
  }

  getPinnedTipForUser(viewedUserAk: Number) {
    return this.http.get<TopTipToConnect>(
      `${this.userUrl}/tips-to-connect/pinned/${viewedUserAk}`
    );
  }

  setTopTip(pinnedTip: TopTipToConnect) {
    return this.http.post<TopTipToConnect>(
      `${this.userUrl}/tips-to-connect/pinned/add`,
      pinnedTip
    );
  }

  removeTopTip(pinnedTip: TopTipToConnect) {
    return this.http.post<TopTipToConnect>(
      `${this.userUrl}/tips-to-connect/remove-pinned`,
      pinnedTip
    );
  }

  setPlatformFirstLogin() {
    return this.http.get<User>(`${this.userUrl}/platform-first-login`);
  }

  acceptChampionCharter() {
    return this.http.post(`${this.userUrl}/champion-charter/accept`, {});
  }

  getHasAccessToBothProducts() {
    return this.http.get<boolean>(`${this.userUrl}/has-both-products`);
  }

  saveDebriefProgress(latestStage: DigitalDebriefStageEnum) {
    if (!latestStage) latestStage = DigitalDebriefStageEnum.Intro;
    return this.http.post<DigitalDebriefStageEnum>(`${this.userUrl}/debrief/stage/${latestStage}`, {});
  }

  getDebriefProgress() {
    return this.http.get<DigitalDebriefStageEnum>(`${this.userUrl}/debrief/stage`);
  }

  setDashboardOnboardingComplete() {
    return this.http.put<Date>(`${this.userUrl}/onboarding/dashboard`, {});
  }

  setProfileOnboardingComplete() {
    return this.http.put<Date>(`${this.userUrl}/onboarding/profile`, {});
  }


  setConnectionsOnboardingComplete() {
    return this.http.put<Date>(`${this.userUrl}/onboarding/connections`, {});
  }
}
