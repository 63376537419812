<div class="d-flex flex-column h-100">
  <div class="header d-flex bg-dark text-white py-5">
    <div class="flex-fill d-flex flex-column">
      <h2 class="mb-2">Admin</h2>
      <div class="col-md-8 d-flex align-items-end">
        <div>
          <h1>Live Analytics</h1>
        </div>
        <div *ngIf="mindflickAccounts$ | async as accounts" class="btn-group ms-4" dropdown placement="bottom right">
          <h2
            id="button-mindflick-accounts"
            class="d-flex"
            dropdownToggle
            type="button"
            aria-controls="dropdown-mindflick-accounts"
          >
            {{ selectedAccount?.name }}
            <fa-icon class="fs-4 pot-dropdown d-flex align-items-center" [icon]="dropdownIcon" />
          </h2>
          <ul
            id="dropdown-mindflick-accounts"
            *dropdownMenu
            class="dropdown-menu dropdown-menu-right"
            role="menu"
            aria-labelledby="button-mindflick-accounts"
          >
            <li
              *ngFor="let account of accounts"
              class="cursor-pointer"
              role="menuitem"
              (click)="onMindflickAccountChanged(account.accountId)"
            >
              <div class="dropdown-item" href="#">
                {{ account.name }}
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="d-flex">
      <div class="d-flex flex-column me-2">
        <div class="body-copy text-white mb-2">Filter by</div>
        <app-dropdown-select class="dropdown-width"
                             [readonly]="isDateDropdownReadonly"
                             [options]="dateFilterOptions"
                             [light]="true"
                             [(ngModel)]="selectedDateFilterOption"
                             (selectionChanged)="onDateSelectionChanged($event)"></app-dropdown-select>
      </div>
      <div *ngIf="selectedDateFilterOption === CUSTOM_RANGE_VALUE"
           class="d-flex flex-column me-2 position-relative"
           id="from-date">
        <div class="body-copy mb-2">From</div>
        <input type="text"
               placeholder="Datepicker"
               class="form-control"
               bsDatepicker
               type="text"
               [(ngModel)]="customDateFrom"
               [maxDate]="customDateTo"
               [bsConfig]="{ showWeekNumbers: false, dateInputFormat: 'DD/MM/YYYY' }"
               container="#from-date"
               (bsValueChange)="customDateChanged($event, customDateTo)" />
      </div>
      <div *ngIf="selectedDateFilterOption === CUSTOM_RANGE_VALUE"
           class="d-flex flex-column me-2 position-relative"
           id="to-date">
        <div class="body-copy mb-2">To</div>
        <input type="text"
               placeholder="Datepicker"
               class="form-control"
               bsDatepicker
               type="text"
               [(ngModel)]="customDateTo"
               [minDate]="customDateFrom"
               [maxDate]="today"
               [bsConfig]="{ showWeekNumbers: false, dateInputFormat: 'DD/MM/YYYY' }"
               container="#to-date"
               (bsValueChange)="customDateChanged(customDateFrom, $event)" />
      </div>
    </div>
  </div>
  <div class="page-container flex-fill d-flex flex-column py-4">
    <tabset >
      <tab [customClass]="'graph-container'" (selectTab)="onSelectTab(sectionType.Onboarding)">
        <ng-template tabHeading>
          <h3>Onboarding</h3>

        </ng-template>
        <div  *ngIf="!isLoading; else loading" class="d-flex">
          <div *ngFor="let graph of onboardingGraphs" class="d-flex col-4">
            <app-admin-live-analytics-graph [graph]="graph" class="d-flex flex-grow-1 mx-1"></app-admin-live-analytics-graph>
          </div>
        </div>
      </tab>
      <tab [customClass]="'graph-container'" (selectTab)="onSelectTab(sectionType.Connections)">
        <ng-template tabHeading>
          <h3>Connections</h3>
        </ng-template>
        <div *ngIf="!isLoading; else loading" class="connections">
          <div *ngFor="let graph of onboardingGraphs" class="d-flex tile" #tileContainer>
            <app-admin-live-analytics-graph [graph]="graph" [containerWidth]="tileContainer.clientWidth" class="d-flex flex-grow-1"></app-admin-live-analytics-graph>
          </div>
        </div>
      </tab>
      <tab [customClass]="'graph-container'" (selectTab)="onSelectTab(sectionType.Teams)">
        <ng-template tabHeading>
          <h3>Teams</h3>
        </ng-template>
        <div *ngIf="!isLoading; else loading" class="teams">
          <div *ngFor="let graph of onboardingGraphs" class="tile" #teamGraphContainer>
            <app-admin-live-analytics-graph [graph]="graph" [containerWidth]="teamGraphContainer.clientWidth" class="d-flex flex-grow-1"></app-admin-live-analytics-graph>
          </div>
        </div>
      </tab>
      <tab [customClass]="'graph-container'" (selectTab)="onSelectTab(sectionType.Interactions)">
        <ng-template tabHeading>
          <h3>Interactions</h3>
        </ng-template>
        <div *ngIf="!isLoading; else loading" class="d-flex">
          <div *ngFor="let graph of onboardingGraphs" class="d-flex col-4">
            <app-admin-live-analytics-graph [graph]="graph" class="d-flex flex-grow-1 mx-1"></app-admin-live-analytics-graph>
          </div>
        </div>
      </tab>
      <tab [customClass]="'graph-container'" (selectTab)="onSelectTab(sectionType.NudgesAndWorkOns)">
        <ng-template tabHeading>
          <h3>Nudges / Work-ons</h3>
        </ng-template>
        <div *ngIf="!isLoading; else loading" class="nudges">
          <div *ngFor="let graph of onboardingGraphs" class="tile" #nudgeGraphContainer>
            <app-admin-live-analytics-graph [graph]="graph" [containerWidth]="nudgeGraphContainer.clientWidth" class="d-flex flex-grow-1"></app-admin-live-analytics-graph>
          </div>
        </div>
      </tab>
      <tab [customClass]="'graph-container'" (selectTab)="onSelectTab(sectionType.Usage)">
        <ng-template tabHeading>
          <h3>Usage</h3>
        </ng-template>
        <div *ngIf="!isLoading; else loading" class="d-flex">
          <div *ngFor="let graph of onboardingGraphs" class="d-flex">
            <app-admin-live-analytics-graph [graph]="graph" class="d-flex flex-grow-1 mx-1"></app-admin-live-analytics-graph>
          </div>
        </div>
      </tab>
    </tabset>
  </div>
</div>

<ng-template #loading>
  <div  class="d-flex justify-content-center align-item-center h-100 mt-5">
    <div class="spinner-border text-primary">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</ng-template>
