import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GetHelpCenterCategories } from '../../../../+state/account-admin/account-admin.actions';
import { selectAllHelpCenterCategories } from '../../../../+state/account-admin/account-admin.selector';
import { AppState } from '../../../../+state/app.state';
import { HelpCenterArticle } from '../../../../shared/models/help-center.interface';

@Component({
  selector: 'app-article-page',
  templateUrl: './article-page.component.html',
  styleUrls: ['./article-page.component.scss'],
})
export class ArticlePageComponent implements OnInit, OnDestroy {
  categories$ = this.store.select(selectAllHelpCenterCategories);
  destroyed$: Subject<boolean> = new Subject<boolean>();

  article: HelpCenterArticle = {} as HelpCenterArticle;
  categoryTitle: string = '';
  articleTitle: string = '';

  constructor(
    private store: Store<AppState>,
    private router: ActivatedRoute,
    private route: Router
  ) {}

  ngOnInit(): void {
    this.store.dispatch(GetHelpCenterCategories.Request());

    let catTitle = this.router.snapshot.paramMap.get('category-title')!;
    let title = this.router.snapshot.paramMap.get('article-title')!;

    this.categories$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((categories) => {
        this.article = categories
          .find((x) => x.title.toLowerCase() == catTitle.toLowerCase())
          ?.articles.find((x) => x.title == title)!;
        this.categoryTitle = categories.find(
          (x) => x.title.toLowerCase() == catTitle.toLowerCase()
        )?.title!;
      });
  }

  goToHelpCenter() {
    this.route.navigateByUrl('/admin/help');
  }

  goToCategoryPage() {
    this.route.navigateByUrl(`/admin/help/${this.categoryTitle}`);
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.unsubscribe();
  }
}
