<div class="modal-header justify-content-end">
  <fa-icon class="cursor-pointer fa-lg" [icon]="closeIcon" (click)="close()"></fa-icon>
</div>
<div class="modal-body">
  <ng-container *ngIf="!(sendingEmails$ | async); else spinner">
    <h2 class="mb-2">Send Emails</h2>
    <h3 class="mb-4">Upload a csv file containing the clients emails</h3>
    <div class="row mb-4">
      <div class="col-md-12">
        <label class="form-label" for="mindflick-account">Email Template</label>
        <app-dropdown-select [placeholder]="'Please Select'" [options]="emailTemplates" [(ngModel)]="selectedEmail"></app-dropdown-select>
      </div>
      <div class="col-md-12">
        <label class="form-label" for="bulk-invite">Choose File</label>
        <input class="form-control" type="file" placeholder="Choose File" (change)="handleFileUpload($event.target)" />
      </div>
    </div>
    <button class="btn btn-purple w-100" (click)="sendEmails()">Send Invites</button>
    <div *ngIf="sendingEmailsSuccess$" class="body-copy">Invite Successful</div>
    <div *ngIf="sendingEmailsError$ | async as error" class="form-error">{{ error }}</div>
  </ng-container>
  <ng-template #spinner>
    <div class="spinner">
      <ngx-spinner bdColor="white" [fullScreen]="false" color="#7000DD" type="ball-fussion" size="medium"><h3 class="spinner-text">Emailing...</h3></ngx-spinner>
    </div>
  </ng-template>
</div>
<div class="modal-bottom-border">

</div>
