import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';
import { faBackSVG, faNextSVG } from '../../../../icons';

@Component({
  selector: 'app-paginated-tab',
  templateUrl: './paginated-tab.component.html',
  styleUrls: ['./paginated-tab.component.scss'],
})
export class PaginatedTabComponent implements OnInit, OnChanges {
  @Input() originalItems: any[] = [];
  @Input() limit: number = 18;
  @Input() emptyStateText = '';
  @Input() isLoading = false;
  @Output() displayChanged = new EventEmitter<any[]>();

  @Input() displayItemsTemplate!: TemplateRef<any>;

  displayItems: any[] = [];

  currentPage = 1;
  back = faBackSVG;
  next = faNextSVG;

  constructor() { }

  ngOnInit(): void {
    this.displayItems = this.originalItems.slice(0, this.limit);
    this.displayChanged.emit(this.displayItems);
  }

  ngOnChanges(changes: SimpleChanges) {

    const allItems = changes.originalItems ? changes.originalItems.currentValue : this.originalItems;

    if (this.limit == 0)
      this.displayItems = allItems;
    else
      this.displayItems = allItems.slice(
        (this.currentPage - 1) * this.currentPage,
        this.limit * this.currentPage
      );
    this.displayChanged.emit(this.displayItems);
  }

  setCurrentPage(page: number) {
    this.currentPage = page;
    this.displayItems = this.setDisplayArray();
    this.displayChanged.emit(this.displayItems);
  }

  setDisplayArray<T>(): T[] {
    return this.originalItems.slice(
      (this.currentPage - 1) * this.limit,
      this.currentPage * this.limit
    );
  }

  get isMoreThanOnePage() {
    return this.originalItems.length / this.limit > 1;
  }
}
