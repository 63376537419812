import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState } from '../../+state/app.state';
import * as LayoutActions from '../../+state/layout/actions/layout.actions';
import {
    AccountSeatsDTO,
  AddOrgDTO,
  CreditPot,
  MindflickAccount,
  MindflickAccountConnections,
  MindflickOrganisation,
  SaveCreditPotModel,
  SelectValue,
  SpotlightResponse,
  StandardResponse,
  Tooltip,
  UpdateUserModel,
  UserBasicDetails,
  UserManagementUser,
  WorkOn,
} from '../models';
import { CoachingClientsDTO } from '../models/coaching-clients-dto.interface';
import { CoachingFeedbackDTO } from '../models/coaching-feedback-dto.interface';
import { NewCoachingSession } from '../models/coaching-session.interface';
import {
  EngagementResource,
  EngagementResourceCategory,
  EngagementResourceCategoryRequest,
  EngagementResourceSubCategory,
} from '../models/engagement-resource.interface';
import { HelpCenterArticle } from '../models/help-center.interface';
import { Playbook, PlaybookExercise } from '../models/playbooks.interface';
import { GroupedCoachingFeedbackDTO } from '../models/practitioner-coaching-feedback-dto.interface';
import { UserPractitionerDTO } from '../models/user-practitioner.interface';
import { SendEngagementPushNotificationModel } from '../models/engagement-push-notification.interface';
import { QuestionnaireDTO } from '../models/questionnaire-option.interface';
import { AddQuestionnaireInvitesArgs } from '../models/add-questionnaire-invite.interface';
import { QuestionnaireResponseManagementDTO } from '../models/questionnaire-response-management-dto.interface';
import { DateRange } from '../models/date-range.interface';
import { AddUserModel } from 'src/app/super-admin/invite-user/invite-user.component';
import { AddedUsersResponseModel } from '../models/account-admin-managed-users-DTO';

@Injectable({
  providedIn: 'root',
})
export class SuperAdminService {
  apiUrl = '';

  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string,
    private store: Store<AppState>
  ) {
    this.apiUrl = `${baseUrl}superadmin`;
  }

  saveWorkOn(workOn: WorkOn) {
    return this.http.post<WorkOn>(`${this.apiUrl}/work-ons/save`, workOn);
  }

  savePlaybook(playbook: Playbook) {
    return this.http.post<Playbook>(`${this.apiUrl}/playbooks/save`, playbook);
  }

  savePlaybookExercise(exercise: PlaybookExercise) {
    const uploadData = new FormData();
    exercise.resourcesToUpload.forEach((file) => {
      uploadData.append('file', file, file.name);
    });

    uploadData.append('data', JSON.stringify(exercise));

    return this.http.post<PlaybookExercise>(
      `${this.apiUrl}/playbooks/exercises/save`,
      uploadData
    );
  }

  uploadImageFile(file: File): Promise<string> {
    if (
      file.type === 'image/jpeg' ||
      file.type === 'image/png' ||
      file.type === 'image/svg' ||
      file.type === 'image/jpg'
    ) {
      // File types supported for image
      if (file.size < 15000000) {
        //Allow 15MB uploads

        // Sample API Call
        const uploadData = new FormData();
        uploadData.append('file', file, file.name);

        return this.http
          .post(`${this.apiUrl}/playbooks/store-image`, uploadData)
          .toPromise()
          .then((result: any) => {
            return Promise.resolve(result.url);
          })
          .catch((error) => {
            // Handle error control
            console.error('Error:', error);
            this.store.dispatch(
              LayoutActions.UploadImageError({ error: 'File upload failed' })
            );
            return Promise.reject('Upload failed: ' + error);
          });
      } else {
        //TODO: Consider how to notify the user that the image is too large
        //Perhaps instead of throwing the reject resolve an empty URL and then show a message via the notifications work that is ongoing?

        this.store.dispatch(
          LayoutActions.UploadImageError({
            error: 'File is too large to upload, please resize the image',
          })
        );

        return Promise.reject('Size too large');
      }
    } else {
      this.store.dispatch(
        LayoutActions.UploadImageError({ error: 'Unsupported file format' })
      );
      return Promise.reject('Unsupported type');
      // Handle Unsupported type logic
    }
  }

  saveTooltip(tooltip: Tooltip): Observable<Tooltip> {
    return this.http.post<Tooltip>(`${this.apiUrl}/tooltip`, tooltip);
  }

  getEmailTemplates(): Observable<SelectValue[]> {
    return this.http.get<SelectValue[]>(`${this.apiUrl}/email-templates`);
  }

  sendEmails(emailCsv: File, emailId: number) {
    const uploadData = new FormData();
    uploadData.append('file', emailCsv, emailCsv.name);
    uploadData.append('data', emailId.toString());

    return this.http.post(`${this.apiUrl}/send-emails`, uploadData);
  }

  getUsersForUserManagement(mindflickAccountId: number) {
    return this.http.get<UserManagementUser[]>(
      `${this.apiUrl}/users/${mindflickAccountId}`
    );
  }

  sendSpotlightInvites(userAks: number[], sendSignUpEmailImmediately: boolean) {
    return this.http.post(`${this.apiUrl}/spotlight/invites`, {
      userAks,
      sendSignUpEmailImmediately,
    });
  }

  resendSpotlightInvites(userAks: number[]) {
    return this.http.post(`${this.apiUrl}/spotlight/resend`, userAks);
  }

  sendNudgesImmediately(userIds: number[]) {
    return this.http.post(`${this.apiUrl}/nudges/send-immediately`, userIds);
  }

  upsertEngagementResourceCategory(
    category: EngagementResourceCategoryRequest
  ) {
    return this.http.post<EngagementResourceCategory>(
      `${this.apiUrl}/engagement-resource/category`,
      category
    );
  }

  upsertEngagementResourceSubCategory(
    subCategory: EngagementResourceCategoryRequest
  ) {
    return this.http.post<EngagementResourceSubCategory>(
      `${this.apiUrl}/engagement-resource/sub-category`,
      subCategory
    );
  }

  saveEngagementResource(engagementResource: EngagementResource) {
    const uploadData = new FormData();
    engagementResource.attachmentsToUpload?.forEach((file) => {
      uploadData.append('file', file, file.name);
    });

    uploadData.append('data', JSON.stringify(engagementResource));

    return this.http.post<EngagementResource>(
      `${this.apiUrl}/engagement-resource`,
      uploadData
    );
  }

  saveHelpCenterArticle(article: HelpCenterArticle) {
    return this.http.post<HelpCenterArticle>(
      `${this.apiUrl}/help-center-article`,
      article
    );
  }

  getMindflickPractitioners(
    clientName?: string
  ): Observable<UserPractitionerDTO[]> {
    var params = new HttpParams();
    if (clientName) params = params.append('clientName', clientName);

    return this.http.get<UserPractitionerDTO[]>(
      `${this.apiUrl}/practitioners`,
      { params: params }
    );
  }

  getMindflickAccountConnections(mindflickAccountId: number, groupBy: string) {
    return this.http.get<MindflickAccountConnections>(
      `${this.apiUrl}/account/${mindflickAccountId}/connections/${groupBy}`
    );
  }

  getCoachingClientNames(dateRange: DateRange, coachAK?: number): Observable<CoachingClientsDTO> {
    var params = new HttpParams();
    if (coachAK) params = params.append('coachAK', coachAK);
    params = params.append('fromDate', dateRange.fromDate?.toDateString() ?? '');
    params = params.append('toDate', dateRange.toDate?.toDateString() ?? '');

    return this.http.get<CoachingClientsDTO>(
      `${this.apiUrl}/coaching/clients`,
      { params: params }
    );
  }

  getCoachingFeedback(
    dateRange: DateRange,
    coachAK?: number,
    clientName?: string
  ): Observable<CoachingFeedbackDTO> {
    var params = new HttpParams();
    if (clientName) params = params.append('clientName', clientName);
    if (coachAK) params = params.append('coachAK', coachAK);
    params = params.append('fromDate', dateRange.fromDate?.toDateString() ?? '');
    params = params.append('toDate', dateRange.toDate?.toDateString() ?? '');


    return this.http.get<CoachingFeedbackDTO>(
      `${this.apiUrl}/coaching/feedback`,
      { params: params }
    );
  }

  getGroupedCoachingFeedback(
    dateRange: DateRange,
    isPractitioner?: boolean
  ): Observable<GroupedCoachingFeedbackDTO[]> {
    var params = new HttpParams();
    if (isPractitioner)
      params = params.append('isPractitioner', isPractitioner);

    params = params.append('fromDate', dateRange.fromDate?.toDateString() ?? '');
    params = params.append('toDate', dateRange.toDate?.toDateString() ?? '');

    return this.http.get<GroupedCoachingFeedbackDTO[]>(
      `${this.apiUrl}/coaching/feedback-grouped`,
      { params: params }
    );
  }

  createCoachingSesssion(newSession: NewCoachingSession): Observable<number> {
    return this.http.post<number>(`${this.apiUrl}/coaching/new`, newSession);
  }

  getMindflickAccounts(): Observable<MindflickAccount[]> {
    return this.http.get<MindflickAccount[]>(`${this.apiUrl}/accounts`);
  }

  getMindflickOrganisations(): Observable<MindflickOrganisation[]> {
    return this.http.get<MindflickOrganisation[]>(`${this.apiUrl}/organisations`);
  }

  getMindflickOrganisationUsers(organisationId: number): Observable<UserBasicDetails[]> {
    return this.http.get<UserBasicDetails[]>(`${this.apiUrl}/organisation/${organisationId}/users`);
  }

  setMindflickOrganisationAdmin(organisationId: number, userAk: number) {
    return this.http.patch<{ adminName: string }>(`${this.apiUrl}/organisation/${organisationId}/admin/${userAk}`, {})
  }

  setMindflickOrganisationLead(organisationId: number, userAk: number) {
    return this.http.patch<{ leadName: string }>(`${this.apiUrl}/organisation/${organisationId}/lead/${userAk}`, {})
  }

  addMindflickAccount(account: MindflickAccount) {
    return this.http.post<MindflickAccount>(`${this.apiUrl}/account`, account);
  }

  updateMindflickAccount(account: MindflickAccount) {
    return this.http.patch<MindflickAccount>(`${this.apiUrl}/account`, account);
  }

  addCreditPot(creditPot: SaveCreditPotModel) {
    return this.http.post<CreditPot>(`${this.apiUrl}/credit-pot`, creditPot);
  }

  updateCreditPot(creditPot: SaveCreditPotModel) {
    return this.http.patch<CreditPot>(`${this.apiUrl}/credit-pot`, creditPot);
  }

  assignUserSpecificWorkOns(userAk: number, workOnIds: number[]) {
    return this.http.post(`${this.apiUrl}/work-ons/assign-user-specific`, {
      userAk,
      workOnIds,
    });
  }

  updateUser(updatedUserDetails: UpdateUserModel) {
    return this.http.patch(`${this.apiUrl}/user`, updatedUserDetails);
  }

  sendEngagementPushNotification(
    pushNotificationDetails: SendEngagementPushNotificationModel
  ) {
    return this.http.post(
      `${this.apiUrl}/engagement-notification`,
      pushNotificationDetails
    );
  }

  getQuestionnaires() {
    return this.http.get<QuestionnaireDTO[]>(
      `${this.apiUrl}/questionnaires`,
    );
  }

  addQuestionnaires(questionnaireInvites: AddQuestionnaireInvitesArgs[]) {
    return this.http.post(
      `${this.apiUrl}/questionnaire/invites/multi`,
      questionnaireInvites
    );
  }

  getQuestionnaireManagementDTO(spotlightResponseId: number) {
    return this.http.get<QuestionnaireResponseManagementDTO[]>(`${this.apiUrl}/${spotlightResponseId}/questionnaires`)
  }

  getUserSpotlightResponses(userAK: number) {
    return this.http.get<SpotlightResponse[]>(`${this.apiUrl}/user/${userAK}/spotlight-responses`)
  }

  linkUserWithSpotlightResponse(userAk: number, spotlightResponseId: number,) {
    return this.http.post(`${this.apiUrl}/user/link-profile`, {
      userAk,
      spotlightResponseId,
    });
  }

  addUserToDatabase(user: AddUserModel) {
    return this.http.post<AddedUsersResponseModel>(
      `${this.apiUrl}/invite/user`,
      user
    );
  }

  removeUserFromAccount(accountId: number, userAk: number): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/accounts/${accountId}/users/${userAk}`);
  }

  getAccountMindflickCoaches() {
    return this.http.get<UserBasicDetails[]>(`${this.apiUrl}/coaches`);
  }

  addMindflickCoachToAccount(userAk: number, mindflickAccountId: number) {
    return this.http.post<StandardResponse<UserBasicDetails>>(`${this.apiUrl}/account/coach`, { userAk, mindflickAccountId })
  }

  updateSeatCount(model: AccountSeatsDTO) {
    return this.http.post<MindflickAccount>(`${this.apiUrl}/account/${model.mindflickAccountId}/seats`, model);
  }

  addOrg(model: AddOrgDTO) {
    return this.http.post<MindflickOrganisation>(`${this.apiUrl}/organisation`, model);
  }
}
