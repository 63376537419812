<div class="menu-container">
  <div class="menu-page pb-3 bg-white h-100">
    <div class="menu-content d-flex flex-column h-100">
      <div class="d-flex pt-5 pb-4 align-items-center header">
        <app-btn-action class="tooltip-close top" [icon]="close" [iconDimensions]="35" (click)="closeTooltips()"></app-btn-action>
        <div class="d-flex flex-column pt-4">
          <h2>Tooltips</h2>
          <nav  aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item body-copy p-0 pe-1 first"  [ngClass]="{'active': !currentSectionTitle}" (click)="goToMenu()">Tooltips</li>
              <div *ngFor="let item of menuItems">
                <li  *ngIf="currentSectionTitle == item.shortName" class="breadcrumb-item body-copy pe-1" [ngClass]="{'active': currentSectionTitle && !currentPage}" (click)="goToSection()">{{item.shortName | titlecase}}</li>
              </div>
              <div *ngFor="let tooltip of tooltips">
                <li *ngIf="currentTooltip == tooltip.description" class="breadcrumb-item body-copy active" >{{tooltip.description | titlecase}}</li>
              </div>

            </ol>
        </nav>
      </div>
      </div>
      <div class="menu-item-container h-100">
        <div *ngIf="!isTooltipsShowing && !currentPage">
          <button *ngFor="let item of menuItems; index as i" class="d-flex align-items-center row w-100 mb-3 menu-item" (click)="setTooltipSection(item.id, item.shortName)">
            <img height="49px" width="49px"
                 src="./assets/svg/profile-placeholder.svg"
                 class="col-3 p-0"
                 />
            <h3 class="col-6 p-0 text-start">{{item.longName | titlecase}}</h3>
            <app-btn-tertiary class="col-3 pe-4" [icon]="next">View</app-btn-tertiary>
          </button>
        </div>
        <div *ngIf="isTooltipsShowing && !currentPage" class="pt-3">
          <ng-container *ngFor="let tooltip of tooltips; index as i">
            <div *ngIf="!tooltip.inactive" class="d-flex align-items-center row pb-3 pt-2 w-100 sub-menu-item" (click)="setTooltipId(tooltip.id)">
              <h3 class="col-10 p-0">{{tooltip.description}}</h3>
              <app-btn-tertiary class="col-2 pe-0" [icon]="next"  style="filter:brightness(0)"></app-btn-tertiary>
            </div>
          </ng-container>

        </div>
        <app-tooltip-pages *ngIf="currentPage"></app-tooltip-pages>
      </div>
    </div>
  </div>
  <div class="shadow-container h-100"></div>
</div>
