import { createSelector } from '@ngrx/store';
import { ConnectionDTO, Team, User } from 'src/app/shared/models';
import { TeamMechanicType } from 'src/app/shared/models/enums/team-mechanic-types.enum';
import {
  DashboardExercise,
  Playbook,
  PlaybookExercise,
  SuggestedExercise,
} from 'src/app/shared/models/playbooks.interface';
import { TeamSpotlightType } from '../../shared/models/enums/team-spotlight-type.enum';
import { AppState, teamStateKey, userStateKey } from '../app.state';
import {
  selectAllActivePlaybooks,
  selectAllPlaybookExercises,
  selectAllPlaybooks,
} from '../growth/growth.selector';
import { TeamState } from './team.reducer';
import { selectCurrentUser } from '../user/user.selector';

export const selectTeamState = (state: AppState) => state[teamStateKey];

export const selectAllTeamsInOrg = (state: AppState) =>
  state[teamStateKey].allTeams;

export const selectMyTeamsInOrg = createSelector(
  selectAllTeamsInOrg,
  (allTeams: Team[]) => {
    return allTeams.filter((x) => x.userIsMember);
  }
);

export const selectTeamHasInvitedUserTeams = createSelector(
  selectAllTeamsInOrg,
  (allTeams: Team[]) => {
    return allTeams.filter((x) => x.teamHasInvitedUser);
  }
);
export const selectUserRequestToJoinTeams = createSelector(
  selectAllTeamsInOrg,
  (allTeams: Team[]) => {
    return allTeams.filter((x) => x.userHasSentTeamRequest);
  }
);

export const selectMyTeams = createSelector(
  selectAllTeamsInOrg,
  (allTeams: Team[]) => {
    return allTeams.filter((x) => x.userIsMember);
  }
);

export const selectTeamInvitesToUser = createSelector(
  selectAllTeamsInOrg,
  (allTeams: Team[]) => {
    return allTeams.filter((x) => x.teamHasInvitedUser);
  }
);

export const selectUserSentTeamRequest = createSelector(
  selectAllTeamsInOrg,
  (allTeams: Team[]) => {
    return allTeams.filter((x) => x.userHasSentTeamRequest);
  }
);

export const selectPendingRequestsCount = createSelector(
  selectTeamInvitesToUser,
  (teams: Team[]) => {
    return teams.length;
  }
);

export const selectMyTeamsLoading = createSelector(
  selectTeamState,
  (state: TeamState) => {
    return state.myTeamsLoading;
  }
);

export const selectSelectedTeamId = (state: AppState) =>
  state[teamStateKey].selectedTeamId;

export const selectSavingManageTeam = (state: AppState) =>
  state[teamStateKey].isSavingTeamDetails;

export const selectSavingManageTeamWithId = (state: AppState) => {
  return {
    isSaving: state[teamStateKey].isSavingTeamDetails,
    lastId: state[teamStateKey].lastCreatedTeamId,
  };
};

export const selectLastCreatedTeamId = (state: AppState) => {
  state[teamStateKey].lastCreatedTeamId;
};

export const selectSelectedTeamMapUserAK = (state: AppState) =>
  state[teamStateKey].selectedTeamMapUserAK;

export const selectUnjoinedPublicAccountsInOrg = (state: AppState) =>
  //select all unjoined teams in the organisation which are not private
  state[teamStateKey].allTeams.filter(
    (team) => !team.userIsMember && !team.isPrivate
  );

export const selectPublicAccountTeams = createSelector(
  //selects all teams in the organisation which are not private
  selectTeamState,
  (state: TeamState) => {
    return state.allTeams.filter((x) => !x.isPrivate);
  }
);

export const selectSelectedTeam = (state: AppState) =>
  state[teamStateKey].currentTeamFullDetails;

export const selectIsSavingTeamMembers = (state: AppState) =>
  state[teamStateKey].areTeamMembersUpdating;

export const selectTeamMembersUpdateResult = (state: AppState) =>
  state[teamStateKey].teamMembersUpdateResult;

export const selectTeamMapIsFlex = (state: AppState) =>
  state[teamStateKey].teamMapIsFlex;

export const selectSelectedTeamStrengths = createSelector(
  selectSelectedTeam,
  (team: Team | undefined): ConnectionDTO[] => {
    var strengths: ConnectionDTO[] = [];

    if (!team) return [];

    team!.teamSpotlights
      .filter(
        (spotlight) => spotlight.spotlightType == TeamSpotlightType.Strength
      )
      .map((spotlight) => {
        strengths.push({
          description: spotlight.description,
          title: spotlight.title,
        });
      });

    return strengths;
  }
);

export const selectSelectedTeamTakeCares = createSelector(
  selectSelectedTeam,
  (team: Team | undefined) => {
    let takeCares: ConnectionDTO[] = [];

    if (!team) return [];

    team!.teamSpotlights
      .filter(
        (spotlight) => spotlight.spotlightType == TeamSpotlightType.TakeCare
      )
      .map((spotlight) => {
        takeCares.push({
          description: spotlight.description,
          title: spotlight.title,
        });
      });

    return takeCares;
  }
);

export const selectSelectedTeamEmptyChairTips = createSelector(
  selectSelectedTeam,
  (team: Team | undefined) => {
    let emptyChairTips: ConnectionDTO[] = [];

    if (!team) return [];

    team!.teamSpotlights
      .filter(
        (spotlight) => spotlight.spotlightType == TeamSpotlightType.EmptyChair
      )
      .map((spotlight) => {
        emptyChairTips.push({
          description: spotlight.description,
          title: spotlight.title,
        });
      });

    return emptyChairTips;
  }
);

export const selectSelectedTeamPurposes = createSelector(
  selectSelectedTeam,
  (team: Team | undefined) => {
    if (team)
      return team.mechanics.filter((x) => x.type == TeamMechanicType.Purpose);

    return undefined;
  }
);

export const selectSelectedTeamFoci = createSelector(
  selectSelectedTeam,
  (team: Team | undefined) => {
    if (team)
      return team.mechanics.filter((x) => x.type == TeamMechanicType.Focus);

    return undefined;
  }
);

export const selectSelectedTeamPrinciples = createSelector(
  selectSelectedTeam,
  (team: Team | undefined) => {
    if (team)
      return team.mechanics.filter((x) => x.type == TeamMechanicType.Principle);
    return undefined;
  }
);

export const selectSelectedTeamPreference = createSelector(
  selectSelectedTeam,
  (team: Team | undefined) => {
    let preferenceFlex = '';
    let preferenceCope = '';

    if (team?.flex?.length === 4) {
      switch (team?.flex[0]) {
        case 'F':
          preferenceFlex = 'Forcefully';
          break;
        case 'L':
          preferenceFlex = 'Logically';
          break;
        case 'E':
          preferenceFlex = 'Empathically';
          break;
        case 'X':
          preferenceFlex = 'Expressively';
          break;
      }
    }
    if (team?.flex?.length === 4) {
      switch (team?.cope[0]) {
        case 'C':
          preferenceCope = 'Contained';
          break;
        case 'O':
          preferenceCope = 'Optimistic';
          break;
        case 'P':
          preferenceCope = 'Prudent';
          break;
        case 'E':
          preferenceCope = 'Engaged';
          break;
      }
    }
    return preferenceFlex + ' ' + preferenceCope;
  }
);

export const selectSelectedTeamEmptyChair = createSelector(
  selectSelectedTeam,
  (team: Team | undefined) => {
    let emptyChairFlex = '';
    let emptyChairCope = '';

    if (team?.flex?.length === 4) {
      switch (team?.flex[3]) {
        case 'F':
          emptyChairFlex = 'Forcefully';
          break;
        case 'L':
          emptyChairFlex = 'Logically';
          break;
        case 'E':
          emptyChairFlex = 'Empathically';
          break;
        case 'X':
          emptyChairFlex = 'Expressively';
          break;
      }
    }
    if (team?.flex?.length === 4) {
      switch (team?.cope[3]) {
        case 'C':
          emptyChairCope = 'Contained';
          break;
        case 'O':
          emptyChairCope = 'Optimistic';
          break;
        case 'P':
          emptyChairCope = 'Prudent';
          break;
        case 'E':
          emptyChairCope = 'Engaged';
          break;
      }
    }
    return emptyChairFlex + ' ' + emptyChairCope;
  }
);

export const selectMyTeamCount = createSelector(
  selectMyTeamsInOrg,
  (teams: Team[]) => {
    return teams.length;
  }
);

export const selectSelectedTeamExercises = createSelector(
  selectSelectedTeam,
  selectAllPlaybookExercises,
  selectAllActivePlaybooks,
  (
    team: Team | undefined,
    exercises: PlaybookExercise[],
    playbooks: Playbook[]
  ) => {
    if (team) {
      return exercises
        .filter((x) => team.exercises.some((e) => e.exerciseId === x.id))
        .map((x) => ({
          ...x,
          totalExercises: playbooks.find((p) => p.id === x.playbookId)
            ?.exercises.length,
        }));
    }
    return [];
  }
);

export const selectSuggestedExercise = createSelector(
  selectSelectedTeam,
  selectAllPlaybookExercises,
  selectAllActivePlaybooks,
  (
    team: Team | undefined,
    exercises: PlaybookExercise[],
    playbooks: Playbook[]
  ) => {
    if (team) {
      let teamExercises = exercises
        .filter((x) => team.exercises.some((e) => e.exerciseId === x.id))
        .map((x) => ({
          ...x,
          totalExercises: playbooks.find((p) => p.id === x.playbookId)
            ?.exercises.length,
        }));

      if (!teamExercises.length) return undefined;

      // Can only get the next exercise if the exercise is not the last one
      let validExercises = teamExercises.filter(
        (x) => x.totalExercises && x.orderById + 1 < x.totalExercises
      );
      let finalExercises = teamExercises.filter(
        (x) => x.totalExercises && x.orderById + 1 >= x.totalExercises
      );
      let validPlaybooks = playbooks.filter(
        (x) => !finalExercises.some((e) => e.playbookId === x.id)
      );

      if (!validExercises.length || !validPlaybooks.length) return;

      let total = validExercises.length;

      let rnd = Math.floor(Math.random() * total);
      let selectedPlaybook = validPlaybooks.find(
        (x) => x.id === validExercises[rnd].playbookId
      )!;
      let selectedExercise = selectedPlaybook.exercises.find(
        (x) => x.orderById === validExercises[rnd].orderById + 1
      )!;

      // Map the details we need to display
      let suggestedExercise: SuggestedExercise = {
        exerciseTitle: selectedExercise.title,
        exerciseId: selectedExercise.id,
        playbookTitle: selectedPlaybook.title!,
        playbookId: selectedPlaybook.id!,
      };

      return suggestedExercise;
    }

    return undefined;
  }
);

export const selectAllTeamsExercises = createSelector(
  selectMyTeamsInOrg,
  selectAllPlaybookExercises,
  selectAllPlaybooks,
  (teams: Team[], exercises: PlaybookExercise[], playbooks: Playbook[]) => {
    let dashboardExercises: DashboardExercise[] = [];

    teams.forEach((team) => {
      dashboardExercises = [
        ...dashboardExercises,
        ...exercises
          .filter((e) => team.exercises.some((x) => x.exerciseId == e.id))
          .map((x) => ({
            exerciseId: x.id,
            exerciseNumber: x.orderById + 1,
            exerciseTitle: x.title,
            exerciseSummary: x.summary,
            addedDate: team.exercises.find((e) => e.exerciseId === x.id)
              ?.addedDate!,
            teamName: team.name,
            playbookTitle: playbooks.find((p) => p.id === x.playbookId)?.title!,
            playbookId: playbooks.find((p) => p.id === x.playbookId)?.id!,
            exerciseTotal: playbooks.find((p) => p.id === x.playbookId)
              ?.exercises.length!,
          })),
      ];
    });

    return dashboardExercises;
  }
);

export const selectNewTeamId = createSelector(
  selectMyTeamsInOrg,
  (teams: Team[]) => {
    let penultimateTeam = teams[teams.length - 1];
    return penultimateTeam?.id + 1;
  }
);

export const selectAllMyTeamRequest = createSelector(
  selectMyTeamsInOrg,
  (allTeams: Team[]) => {
    return allTeams.filter(
      (x) =>
        !x.userIsMember && (x.userHasSentTeamRequest || x.teamHasInvitedUser)
    );
  }
);
export const selectMyTeamRequestsLoading = (state: AppState) =>
  state[teamStateKey].myTeamRequestsLoading;

export const selectSavingTeamDetailsErrorMsg = (state: AppState) =>
  state[teamStateKey].isSavingTeamDetailsResult?.message;

export const selectTeamNameNotUniqueInOrg = createSelector(
  selectSavingTeamDetailsErrorMsg,
  (message?: string) => {
    if (message === 'Team name is not unique') {
      return true;
    } else {
      return false;
    }
  }
);

export const selectIsSavingTeamDetailsResult = createSelector(
  selectTeamState,
  (teamState: TeamState) => {
    return teamState.isSavingTeamDetailsResult;
  }
);

export const selectSelectedTeamIsUnjoined = createSelector(
  selectMyTeamsInOrg,
  selectSelectedTeamId,
  (allTeams: Team[], teamId?: number) => {
    let unjoinedTeams = allTeams.filter((team) => !team.userIsMember);
    return unjoinedTeams.find((x) => x.id === teamId);
  }
);

export const selectSelectedTeamIsInMyTeamRequests = createSelector(
  selectAllTeamsInOrg,
  selectSelectedTeamId,
  (allTeams: Team[], teamId?: number) => {
    let teamsWithRequest = allTeams.filter(
      (team) =>
        !team.userIsMember &&
        (team.userHasSentTeamRequest || team.teamHasInvitedUser)
    );
    return teamsWithRequest.find((x) => x.id === teamId);
  }
);

export const selectIsSelectedTeamInMyTeams = createSelector(
  selectMyTeamsInOrg,
  selectSelectedTeamId,
  (myTeams: Team[], teamId?: number) => {
    return myTeams.some((team) => team.id === teamId);
  }
);

export const selectIsSelectedTeamAnUnjoinedTeam = createSelector(
  selectAllTeamsInOrg,
  selectSelectedTeamId,
  (allTeams: Team[], teamId?: number) => {
    let inUnjoined = allTeams.some(
      (team) => team.id === teamId && !team.userIsMember
    );

    return inUnjoined;
  }
);

export const selectTeamRequestStatus = createSelector(
  selectAllTeamsInOrg,
  selectSelectedTeamId,
  (allTeams?: Team[], teamId?: number) => {
    let currentTeam = allTeams?.find((team) => team.id === teamId);

    if (currentTeam?.teamHasInvitedUser) {
      return TeamConnectionStatus.Invited;
    } else if (currentTeam?.userHasSentTeamRequest) {
      return TeamConnectionStatus.Requested;
    } else {
      return TeamConnectionStatus.None;
    }
  }
);

export const selectIsTeamPrivate = createSelector(
  selectSelectedTeam,
  (team?: Team) => {
    return team?.isPrivate;
  }
);

export enum TeamConnectionStatus {
  Requested,
  Invited,
  None,
}

export const selectPublicTeamsLoading = createSelector(
  selectTeamState,
  (state: TeamState) => {
    return state.publicAccountTeamsLoading;
  }
);

export const selectAllAdminTeamsLoading = createSelector(
  selectTeamState,
  (state: TeamState) => {
    return state.allTeamsForAdminLoading;
  }
);

export const selectAllTeamsLoading = createSelector(
  selectTeamState,
  (state: TeamState) => {
    return state.allTeamsLoading;
  }
);

export const selectTeamFromSelectAllTeamsInOrg = (teamId: number) =>
  createSelector(selectAllTeamsInOrg, (teams: Team[]) => {
    return teams.find((x) => x.id === teamId);
  });
export const selectSelectedTeamIsPrivate = (teamId: number) =>
  createSelector(selectAllTeamsInOrg, (teams: Team[]) => {
    let team = teams.find((x) => x.id === teamId);
    if (team) return team.isPrivate;
    else return false;
  });

export const selectSelectedTeamUserHasSentTeamRequest = (teamId: number) =>
  createSelector(selectAllTeamsInOrg, (teams: Team[]) => {
    let team = teams.find((x) => x.id === teamId);
    if (team) return team.userHasSentTeamRequest;
    else return false;
  });
export const selectSelectedTeamHasInvitedUser = (teamId: number) =>
  createSelector(selectAllTeamsInOrg, (teams: Team[]) => {
    let team = teams.find((x) => x.id === teamId);
    if (team) return team?.teamHasInvitedUser;
    else return false;
  });

export const selectUserIsMemberOfTeam = (teamId: number, userId: number) =>
  createSelector(selectAllTeamsInOrg, (teams: Team[]) => {
    let team = teams.find((x) => x.id === teamId);
    if (team) return team.userIsMember;
    else return false;
  });

export const selectInviteTeamMembersSuccess = (state: AppState) =>
  state[teamStateKey].inviteTeamMembersSuccess;
export const selectMemberIdBeingRemoved = (state: AppState) =>
  state[teamStateKey].memberIdBeingRemoved;
export const selectAreTeamMembersUpdating = (state: AppState) =>
  state[teamStateKey].areTeamMembersUpdating;

export const selectSaveTeamMechanicsSuccess = (state: AppState) =>
  state[teamStateKey].saveTeamMechanicsSuccess;

export const selectSaveTeamSettingsSuccess = (state: AppState) =>
  state[teamStateKey].saveTeamSettingsSuccess;

export const selectShowUploadTeamPhotoComponent = (state: AppState) =>
  state[teamStateKey].showUploadTeamPhotoComponent;

export const selectNewTeamForm = (state: AppState) =>
  state[teamStateKey].newTeamForm;

export const selectCurrentTeamFulLDetailsIsLoading = (state: AppState) =>
  state[teamStateKey].currentTeamFullDetailsLoading;
