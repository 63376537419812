<div *ngIf="userToEdit$ | async as userToEdit" class="large-popout-container">
    <div class="large-popout-shadow"></div>
    <div class="large-popout-content d-flex flex-column">
        <div class="d-flex pt-5 pb-4 align-items-center popout-close">
            <app-btn-action
              class="legal-close fa-lg top"
              [icon]="close"
              [iconDimensions]="35"
              (click)="closePopout()"
            ></app-btn-action>
        </div>
        <div class="flex-fill d-flex flex-column justify-content-between pt-5 pb-4">
            <div class="d-flex flex-column">
                <div>
                    <h2 class="mt-1">User details</h2>
                    <app-btn-tertiary [icon]="tick" (click)="saveChanges()">Save Changes</app-btn-tertiary>
                </div>
                
                <form [formGroup]="editDetailsForm">
                    <div class="py-4 row gx-0 border-bottom-grey">
                        <div class="col-6 pe-3">
                            <label class="form-label">First Name</label>
                            <input class="form-control" formControlName="firstName" readonly />
                        </div>
                        <div class="col-6 ps-3">
                            <label class="form-label">Last Name</label>
                            <input class="form-control" formControlName="lastName" readonly />
                        </div>
                        <div *ngIf="isSuperAdmin$ | async" class="col-6 pe-3">
                            <label class="form-label">Email</label>
                            <input
                              [placeholder]="'Email'"
                              class="form-control"
                              formControlName="email"
                            />
                        </div>
                        <div class="col-6" [class.ps-3]="isSuperAdmin$ | async" [class.pe-3]="!(isSuperAdmin$ | async)">
                            <label class="form-label">Department</label>
                            <app-autocomplete-input
                              [options]="(departmentOptions$ | async)!"
                              [placeholder]="userToEdit.department ?? 'Department'"
                              (termChanged)="setDepartment($event)"
                              [newOptionPlaceholder]="'department'"
                            ></app-autocomplete-input>
                        </div>
                        <div class="col-6" [class.ps-3]="!(isSuperAdmin$ | async)">
                            <label class="form-label">Role</label>
                            <app-autocomplete-input
                              [options]="(roleOptions$ | async)!"
                              [placeholder]="userToEdit.jobTitle ?? 'Role'"
                              (termChanged)="setRole($event)"
                              [newOptionPlaceholder]="'role'"
                            ></app-autocomplete-input>
                        </div>
                        
                        
                    </div>

                    <ng-container *ngIf="this.userToEdit.isLocked">
                        <h2 class="mt-4">User unlock date</h2>
                        <div class="py-4 row gx-0 border-bottom-grey">
                            <div class="position-relative unlock-date col-6">
                                <label class="form-label">Debrief Date</label>
                                <input
                                  [(ngModel)]="debriefDate"
                                  [ngModelOptions]="{ standalone: true }"
                                  type="text"
                                  placeholder="Select unlock date"
                                  class="form-control"
                                  bsDatepicker
                                  [minDate]="tomorrow"
                                  [container]="'.unlock-date'"
                                  [bsConfig]="{ showWeekNumbers: false, dateInputFormat: 'DD/MM/YYYY' }"
                                />
                            </div>
                        </div>
                    </ng-container>
                    
                </form>
            </div>
        </div>
    </div>
</div>
