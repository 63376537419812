import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../+state/app.state';
import { SetSubMenuSmallPopout } from '../../../../+state/layout/actions/layout.actions';
import { SubMenuSmallPopoutEnum } from '../../../../+state/layout/reducers/layout.reducers';
import { faHelpSVG } from '../../../../../icons';

@Component({
  selector: 'app-btn-tooltip',
  templateUrl: './btn-tooltip.component.html',
  styleUrls: ['./btn-tooltip.component.scss'],
})
export class BtnTooltipComponent implements OnInit {
  help = faHelpSVG;

  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {}

  displayTooltips() {
    this.store.dispatch(
      SetSubMenuSmallPopout({
        subMenuSmallPopout: SubMenuSmallPopoutEnum.Tooltip,
      })
    );
  }
}
