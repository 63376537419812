<div
  class="d-flex flex-column justify-content-end engagement-resource-card me-5"
  [ngClass]="growthType"
  (click)="setSelectedPopout()"
  (mouseenter)="onMouseEnter()"
  (mouseleave)="onMouseLeave()"
>
  <div
    class="position-relative img"
    [ngStyle]="{ 'background-image': 'url(' + encodedImageUri + ')' }"
  >
    <div
      *ngIf="canPin && isHovering"
      class="position-absolute pin-container d-flex align-items-start justify-content-end"
    >
      <fa-icon
        class="text-white fs-2 cursor-pointer"
        [icon]="pinOutline"
        (click)="onPinClicked($event)"
      ></fa-icon>
    </div>
    <div class="d-flex flex-wrap">
      <div *ngFor="let tag of tags" class="me-2">
        <div class="mf-tag mf-tag-opacity mb-2">{{ tag }}</div>
      </div>
    </div>
  </div>

  <div
    class="bottom d-flex flex-column justify-content-start align-items-start"
  >
    <div *ngIf="growthType === 'workOn'" class="w-100 type body-copy mb-1 row">
      <ng-container *ngIf="workOnType == WorkOnType.MicroAction">
        <div class="col-md-6">MICRO-ACTION</div>
      </ng-container>

      <ng-container *ngIf="workOnType == WorkOnType.Learn">
        <div class="col-md-6">Learn</div>
      </ng-container>

      <p *ngIf="timeToRead > 1" class="col-md-6 d-flex justify-content-end">
        <fa-icon [icon]="['fas', 'clock']" class="pe-2"></fa-icon
        >{{ timeToRead - 1 }}-{{ timeToRead }} min read
      </p>
      <p *ngIf="timeToRead <= 1" class="col-md-6 d-flex justify-content-end">
        <fa-icon [icon]="['fas', 'clock']" class="pe-2"></fa-icon>1 min read
      </p>
    </div>
    <h3 class="mb-2">{{ title }}</h3>
    <div class="flex-fill overflow-auto body-copy text-white">
      <ng-template [nestedEllipsis]>
        {{ summary }}
      </ng-template>
    </div>
    <button class="btn btn-purple mt-1">DETAILS</button>
  </div>
</div>
