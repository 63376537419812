import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { BsModalRef } from "ngx-bootstrap/modal";
import { Subject, take, takeUntil } from "rxjs";
import { SetNewAccountAdmin, SetSelectedAdminSummaryAccountId } from "src/app/+state/account-admin/account-admin.actions";
import { selectSelectedAccountSettingsAccount, selectSelectedAccountsSummaryData, selectValidAccountAdmins } from "src/app/+state/account-admin/account-admin.selector";
import { AppState } from "src/app/+state/app.state";
import { SelectValue } from "src/app/shared/models";
import { faCloseSVG } from "src/icons";

@Component({
  selector: "app-edit-account-admin-modal",
  templateUrl: "./edit-account-admin-modal.component.html",
  styleUrls: ["./edit-account-admin-modal.component.scss"],
})
export class EditAccountAdminModalComponent implements OnInit {
  selectedAccount$ = this.store.select(selectSelectedAccountsSummaryData);
  accountAdminOptions$ = this.store.select(selectValidAccountAdmins);

  accountAdminOptions: SelectValue[] = [];

  selectedUserAk?: number;

  closeIcon = faCloseSVG;

  destroyed$ = new Subject<boolean>();

  constructor(private store: Store<AppState>, private modalRef: BsModalRef) {}

  ngOnInit(): void {
    this.selectedAccount$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(account => {
        this.selectedUserAk = account?.accountAdminId;
      });

    this.accountAdminOptions$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(accountAdmins => {
        this.accountAdminOptions = accountAdmins.map(a => ({
          value: a.ak!,
          description: a.firstName + ' ' + a.lastName + ' (' + a.email + ')'
        }));
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.unsubscribe();
  }

  saveChanges() {
    if (!this.selectedUserAk)
      return;

    this.selectedAccount$
      .pipe(take(1))
      .subscribe(account => {
        if (account) 
          this.store.dispatch(SetNewAccountAdmin.Request({ payload: { mindflickAccountId: account.mindflickAccountId, userId: this.selectedUserAk! } }));
      });
  }

  close() {
    this.store.dispatch(SetSelectedAdminSummaryAccountId({ accountId: undefined }));
    this.modalRef.hide();
  }
}
