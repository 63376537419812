import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/+state/app.state';
import { SetSubMenuSmallPopout } from 'src/app/+state/layout/actions/layout.actions';
import { SubMenuSmallPopoutEnum } from 'src/app/+state/layout/reducers/layout.reducers';
import { faCloseSVG } from '../../../../icons';
import { QueueItem } from '../../models/downloads.interface';
import { DownloadService } from '../../services/download.service';

@Component({
  selector: 'app-downloads',
  templateUrl: './downloads.component.html',
  styleUrls: ['./downloads.component.scss'],
})
export class DownloadsComponent implements OnInit {
  close = faCloseSVG;

  constructor(
    public downloadService: DownloadService,
    private store: Store<AppState>
  ) {}

  ngOnInit(): void {}

  closeDownloads() {
    this.store.dispatch(
      SetSubMenuSmallPopout({ subMenuSmallPopout: SubMenuSmallPopoutEnum.None })
    );
  }

  removeComplete(completeItem: QueueItem) {
    this.downloadService.removeComplete(completeItem.downloadUrl);
  }
}
