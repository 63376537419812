import { createReducer, on } from '@ngrx/store';
import { GetMindflickAccountsForCurrentUser } from '../../account/account.actions';
import { GetNotifications } from '../../signalr/signalr.actions';
import { LoadSurveyFromInvite } from '../../survey/actions/survey.actions';
import { GetUser } from '../../user/user.actions';
import * as layoutActions from '../actions/layout.actions';
import * as UserAction from '../../user/user.actions';
import * as SurveyActions from '../../survey/actions/survey.actions';
import { UploadStatus } from '../../../shared/models/enums/upload-status.enum';
import {
  ReactivateUsers,
  RecreateUsers,
} from '../../account-admin/account-admin.actions';
import { AlertMessage } from '../../../shared/models/alert.interface';
import { CommonActions } from '../../common-actions';

export const layoutFeatureKey = 'layout';

export enum BackgroundStyle {
  Blank,
  IntroBoxesMiddle,
  IntroBoxesBottomRight,
  BlurredQuestionnaireDark,
}

export enum HeaderLogo {
  MindFlick,
  Spotlight,
}

export enum SubMenuDropdownEnum {
  None,
  Growth,
  SuperAdmin,
  AccountAdmin,
}

export enum SubMenuSmallPopoutEnum {
  None,
  Tooltip,
  Notification,
  Downloads,
  NudgeReflection,
}

export enum SubMenuLargePopoutEnum {
  None,
  GrowthDetails,
  Terms,
  DataPolicy,
  Privacy,
}

export interface LayoutState {
  titleBarVisible: boolean;
  backgroundStyle: BackgroundStyle;
  headerLogo: HeaderLogo;
  subMenuDropdown: SubMenuDropdownEnum;
  subMenuSmallPopout: SubMenuSmallPopoutEnum;
  subMenuLargePopout: SubMenuLargePopoutEnum;
  uploadImageError?: string;
  isWorkOnsGridShowing: boolean;
  isPlaybooksGridShowing: boolean;
  isDashboardLoading: boolean;
  isConnectionsViewed: boolean;
  uploadStatus?: UploadStatus;
  alert?: AlertMessage;
  isEditingTeamMechanics: boolean;
  userIsLocked: boolean;
  showChampionCharter: boolean;
  requiresExternalAuthentication: boolean;
}

export const initialState: LayoutState = {
  titleBarVisible: true,
  backgroundStyle: BackgroundStyle.IntroBoxesBottomRight,
  headerLogo: HeaderLogo.MindFlick,
  subMenuDropdown: SubMenuDropdownEnum.None,
  subMenuSmallPopout: SubMenuSmallPopoutEnum.None,
  subMenuLargePopout: SubMenuLargePopoutEnum.None,
  isWorkOnsGridShowing: false,
  isPlaybooksGridShowing: false,
  isDashboardLoading: true,
  isConnectionsViewed: false,
  isEditingTeamMechanics: false,
  userIsLocked: false,
  showChampionCharter: false,
  requiresExternalAuthentication: false
};

export const layoutReducer = createReducer(
  initialState,

  on(CommonActions.ClearState, (state, props) => ({
    ...initialState
  })),

  on(layoutActions.setBackground, (state, props) => ({
    ...state,
    ...{
      backgroundStyle: props.style,
    },
  })),
  on(layoutActions.setTitleBar, (state, props) => ({
    ...state,
    ...{
      titleBarVisible: props.visible,
    },
  })),
  on(layoutActions.SetSubMenuDropdown, (state, props) => ({
    ...state,
    ...{
      subMenuDropdown: props.subMenuDropdown,
    },
  })),
  on(layoutActions.SetSubMenuSmallPopout, (state, props) => ({
    ...state,
    ...{
      subMenuSmallPopout: props.subMenuSmallPopout,
    },
  })),
  on(layoutActions.SetSubMenuLargePopout, (state, props) => ({
    ...state,
    ...{
      subMenuLargePopout: props.subMenuLargePopout,
    },
  })),
  on(layoutActions.setHeaderLogo, (state, props) => ({
    ...state,
    headerLogo: props.logo,
  })),
  on(layoutActions.UploadImageError, (state, props) => ({
    ...state,
    uploadImageError: props.error,
  })),
  on(layoutActions.ToggleWorkOnsGrid, (state) => ({
    ...state,
    isWorkOnsGridShowing: !state.isWorkOnsGridShowing,
  })),
  on(layoutActions.TogglePlaybooksGrid, (state) => ({
    ...state,
    isPlaybooksGridShowing: !state.isPlaybooksGridShowing,
  })),
  on(GetUser.Request, (state) => ({
    ...state,
    isDashboardLoading: true,
  })),
  on(GetMindflickAccountsForCurrentUser.Success, (state) => ({
    ...state,
    isDashboardLoading: false,
  })),
  on(GetNotifications.Success, (state) => ({
    ...state,
    isDashboardLoading: false,
  })),
  on(LoadSurveyFromInvite.Success, (state) => ({
    ...state,
    isDashboardLoading: false,
  })),
  on(layoutActions.SetConnectionsViewed, (state) => ({
    ...state,
    isConnectionsViewed: true,
  })),
  on(layoutActions.SetDashboardIsLoading, (state, props) => ({
    ...state,
    isDashboardLoading: props.isLoading,
  })),
  on(UserAction.ChangeUserPicture.Request, (state) => ({
    ...state,
    uploadStatus: UploadStatus.InProgress,
  })),
  on(UserAction.ClearUserPicture.Request, (state) => ({
    ...state,
    uploadStatus: UploadStatus.InProgress,
  })),
  on(UserAction.ChangeUserPicture.Success, (state) => ({
    ...state,
    uploadStatus: UploadStatus.Completed,
  })),
  on(UserAction.ClearUserPicture.Success, (state) => ({
    ...state,
    uploadStatus: UploadStatus.Completed,
  })),
  on(UserAction.ChangeUserPicture.Fail, (state) => ({
    ...state,
    uploadStatus: UploadStatus.Failed,
  })),
  on(UserAction.ClearUserPicture.Fail, (state) => ({
    ...state,
    uploadStatus: UploadStatus.Failed,
  })),
  on(SurveyActions.SetUserPicture.Request, (state) => ({
    ...state,
    uploadStatus: UploadStatus.InProgress,
  })),
  on(SurveyActions.SetUserPicture.Success, (state) => ({
    ...state,
    uploadStatus: UploadStatus.Completed,
  })),
  on(SurveyActions.SetUserPicture.Fail, (state) => ({
    ...state,
    uploadStatus: UploadStatus.Failed,
  })),
  on(ReactivateUsers.Request, (state) => ({
    ...state,
    uploadStatus: UploadStatus.InProgress,
  })),
  on(ReactivateUsers.Fail, (state) => ({
    ...state,
    uploadStatus: UploadStatus.Failed,
  })),
  on(ReactivateUsers.Success, (state) => ({
    ...state,
    uploadStatus: UploadStatus.Completed,
  })),
  on(RecreateUsers.Success, (state) => ({
    ...state,
    uploadStatus: UploadStatus.Completed,
  })),
  on(layoutActions.DisplaySnackbarAlert.SetAlert, (state, { alert }) => ({
    ...state,
    alert: {
      alertType: alert.alertType,
      message: alert.message,
      isDismissible: alert.isDismissible ? alert.isDismissible : true,
      timeout: alert.timeout ? alert.timeout : 10 * 1000,
      messageHeader: alert.messageHeader ? alert.messageHeader : '',
    },
  })),
  on(layoutActions.DisplaySnackbarAlert.ClearAlert, (state) => ({
    ...state,
    alert: undefined,
  })),
  on(layoutActions.SetIsEditingTeamMechanics, (state, props) => ({
    ...state,
    isEditingTeamMechanics: props.isEditing,
  })),
  on(layoutActions.ShowLockedUserHoldingScreen, (state) => ({
    ...state,
    userIsLocked: true,
  })),
  on(layoutActions.SetNavigatedToSurvery, (state) => ({
    ...state,
    isDashboardLoading: false,
  })),
  on(layoutActions.ShowChampionCharter, (state) => ({
    ...state,
    showChampionCharter: true,
  })),
  on(layoutActions.ShowRequiresExternalAuthenticationScreen, (state) => ({
    ...state,
    requiresExternalAuthentication: true,
  })),
  on(UserAction.AcceptChampionCharter.Success, (state) => ({
    ...state,
    showChampionCharter: false,
  }))
);
