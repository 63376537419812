<div class="modal-header justify-content-end">
  <fa-icon
    class="cursor-pointer fa-lg"
    [icon]="closeIcon"
    (click)="close()"
  ></fa-icon>
</div>
<div class="modal-body">
  <h2 class="mb-4">Edit profile picture</h2>
  <div class="d-flex p-0 container">
    <div class="d-flex flex-column justify-content-end col-6">
      <p class="body-copy m-0">
        Minimum picture resolution 200x200px. Maximum picture size is 5MB.
        Formats accepted are png, jpeg.
      </p>
      <app-btn-tertiary
        [icon]="delete"
        [contentRightAligned]="false"
        class="my-2"
        (click)="clearPhoto()"
        >Remove photo</app-btn-tertiary
      >
      <div>
        <p *ngIf="!isFileSmallerThan5MB" class="small-text mb-0 form-error">
          Please ensure file is less than 5MB.
        </p>
      </div>
      <label for="file-upload" class="btn btn-purple">Upload Photo</label>
      <input
        id="file-upload"
        type="file"
        (change)="fileChangeEvent($event)"
        accept=".png,.jpg"
      />
    </div>
    <div class="col-6 align-self-end ms-4 picture">
      <image-cropper
        *ngIf="imageChangedEvent"
        [imageChangedEvent]="imageChangedEvent"
        [maintainAspectRatio]="true"
        [aspectRatio]="1 / 1"
        format="jpeg"
        (imageCropped)="imageCropped($event)"
      ></image-cropper>
      <div class="d-flex flex-column">
        <h2 class="text-start">Preview</h2>
        <img
          class="profile-picture"
          height="70"
          width="70"
          [src]="
            croppedImage
              ? croppedImage
              : currentUser?.strId && currentUser?.photoLastModified
              ? publicPhotoBasePath +
                currentUser?.strId +
                '_' +
                currentUser?.photoLastModified +
                '.jpg'
              : './assets/svg/profile-placeholder.svg'
          "
        />
      </div>
    </div>
  </div>
  <app-btn class="mt-3" label="Save Changes" [busy]="(uploadStatus$ | async) == inProgress" [fullWidth]="true" (buttonClicked)="saveChanges()"></app-btn>
  <p
    *ngIf="(uploadStatus$ | async) == uploadFailed"
    class="form-error p-0 m-0 fw-bold"
  >
    Upload failed. Please try again later.
  </p>
</div>
<div class="modal-bottom-border position-absolute w-100"></div>
