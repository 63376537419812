<div *ngIf="user" class="user-panel d-flex py-3" (click)="openUserProfile()">
  <div class="position-relative me-3 member-picture">
    <img class="member-picture" [src]="profileSrc" (error)="setDefaultPic()" />
    <fa-icon
      *ngIf="user.connectionStatus == ConnectionStatus.Pending"
      [icon]="['fas', 'circle']"
      alt="Connection request pending"
      class="nav-link text-secondary position-absolute circle"
    ></fa-icon>
  </div>
  <div class="flex-fill d-flex flex-column overflow-hidden">
    <h3 class="flex-fill ellipsis-overflow">
      {{ user.firstName }} {{ user.lastName }}
      <fa-icon *ngIf="displayAdminIcon" [icon]="isTeamAdmin"></fa-icon>
    </h3>
    <div
      class="body-copy ellipsis-overflow"
      [class.text-muted]="!user.jobTitle"
    >
      {{ user.jobTitle ? user.jobTitle : "Role not set" }}
    </div>
    <div
      *ngIf="user.id != (currentUser$ | async)?.id"
      class="body-copy ellipsis-overflow"
    >
      {{
        user.mutualConnectionsCount + " mutual connections" ||
          "No mutual connections"
      }}
    </div>
  </div>
  <div
    *ngIf="allowSelectCurrentUser || user.id != (currentUser$ | async)?.id"
    class="d-flex justify-content-end align-items-center"
  >
    <!--Add Team Member-->
    <app-btn-action
      *ngIf="buttonAction == ButtonActions.AddMember"
      [icon]="add"
      [iconDimensions]="35"
      (actionClick)="onActionButtonClicked($event)"
    >
    </app-btn-action>
    <!--Remove Team Member-->
    <app-btn-action
      *ngIf="buttonAction == ButtonActions.RemoveMember"
      [icon]="close"
      [iconDimensions]="35"
      [secondary]="true"
      [tooltip]="removeActionTooltip"
      (actionClick)="onActionButtonClicked($event)"
    >
    </app-btn-action>
    <!--Request connection-->
    <app-btn-action
      *ngIf="
        buttonAction == ButtonActions.Request &&
        user.connectionStatus == ConnectionStatus.Unconnected
      "
      [icon]="connect"
      [iconDimensions]="35"
      [tooltip]="'Request Connection'"
      (actionClick)="onRequestAccessClicked($event)"
    >
    </app-btn-action>
    <!-- revoke pending conneciton-->
    <app-btn-action
      *ngIf="buttonAction == ButtonActions.RevokeRequest"
      [icon]="revoke"
      [iconDimensions]="35"
      [secondary]="true"
      [tooltip]="removeActionTooltip"
      (actionClick)="declineUser($event, user.id)"
    >
    </app-btn-action>

    <app-btn-action
      class="me-2"
      [secondary]="true"
      *ngIf="buttonAction == ButtonActions.Pending"
      [icon]="close"
      [iconDimensions]="35"
      (actionClick)="declineUser($event, user.id)"
    >
    </app-btn-action>
    <app-btn-action
      *ngIf="buttonAction == ButtonActions.Pending"
      [icon]="tick"
      [iconDimensions]="35"
      (actionClick)="acceptUser($event, user.id)"
    >
    </app-btn-action>

    <app-btn-action
      class="me-2"
      [secondary]="true"
      *ngIf="buttonAction == ButtonActions.TeamRequest"
      [icon]="close"
      [iconDimensions]="35"
      (actionClick)="declineTeamRequest($event)"
    >
    </app-btn-action>
    <app-btn-action
      *ngIf="buttonAction == ButtonActions.TeamRequest"
      [icon]="tick"
      [iconDimensions]="35"
      (actionClick)="acceptTeamRequest($event)"
    >
    </app-btn-action>

    <app-btn-action
      *ngIf="buttonAction == ButtonActions.Connected"
      [icon]="right_arrow"
      [iconDimensions]="35"
    >
    </app-btn-action>

    <input
      *ngIf="buttonAction == ButtonActions.adminRightsUnchecked"
      type="checkbox"
      class="form-check-input me-2"
      [checked]="isChecked"
    />
  </div>
</div>
