<div class="d-flex flex-column h-100" *ngIf="selectedPage">
  <h3 class="pt-4 pb-3">{{selectedPage.title}}</h3>
  <div [innerHTML]="selectedPage.html | safeHtml" class="pt-2 overflow-auto flex-fill">{{selectedPage.html}}</div>
  <div class="pt-2 pb-5">
    <div class="d-flex">
      <app-btn-action [icon]="back"
                      [iconDimensions]="35"
                      [secondary]="true"
                      [disabled]="selectedPageId == 0"
                      (actionClick)="changePage(-1)"
                      class="col-2"></app-btn-action>
      <div class="d-flex col-8">
        <div *ngFor="let thisPage of [].constructor(numberOfPages); let i = index"
             id="pageDotTracker"
             class="align-self-center mx-auto">
          <fa-icon [icon]="['fas','circle']" class="fa-circle fa-2xs"
                   [ngClass]="{'purple': i < selectedPageId!,  'orange': i == selectedPageId!, 'grey': i > selectedPageId!}"></fa-icon>
        </div>
      </div>
      <app-btn-action [icon]="next"
                      [iconDimensions]="35"
                      [disabled]="selectedPageId! == numberOfPages! - 1"
                      (actionClick)="changePage(1)"
                      class="col-2 text-end"></app-btn-action>
    </div>
  </div>

</div>

