// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  useHash: true,
  apiUrl: '/api',
  localStoragePrefix: 'dev-',
  hostUrl: '/',
  redirectUrl: '/vdev',
  applicationName: 'Mindflick.Platform.vdev',
  identityServerRoot: 'https://app.mindflick.co/dev/identity',
  //identityServerRoot: 'https://localhost:5011',
  signalRHub: 'https://app.mindflick.co/dev/signalr/signalr/platformhub',
  //signalRHub: 'https://localhost:5007/signalr/platformhub',
  identityServerClientId: 'admin.mindflick.co.vdev',
  locale: 'vdev',
  version: '__buildnumber__ vdev',
  disableWebSockets: false,
  appInsights: {
    instrumentationKey: '6d22d49b-1774-4ec3-99f3-f00421097d6a',
  },
  //surveyApi: 'https://localhost:5001/api/',
  surveyApi: 'https://app.mindflick.co/dev/survey/api/',
  surveyUrl: 'https://app.mindflick.co/dev/#/survey/',
  publicStorageUrl: 'https://mindflickdevpublic.blob.core.windows.net/images/',
  platformResourceStorageUrl:
    'https://mindflickdevpublic.blob.core.windows.net/platform-resources/',
  spotlightUrl: 'https://app.mindflick.co/dev/spotlight/admin',
  debriefAudioStorageUrl: 'https://mindflickdevpublic.blob.core.windows.net/debrief-audio'
  //spotlightUrl: 'https://localhost:5001/vdev/',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
