

<div class="d-flex flex-column h-100 page">
  <app-help-center-header></app-help-center-header>
  <div class="d-flex flex-column category" *ngIf="article">
    <nav aria-label="breadcrumb" class="my-4">
      <ol class="breadcrumb">
        <li class="breadcrumb-item body-copy p-0 pe-1 first" (click)="goToHelpCenter()">Help Centre</li>
        <li class="breadcrumb-item body-copy pe-1" (click)="goToCategoryPage()">{{categoryTitle}}</li>
        <li class="breadcrumb-item body-copy pe-1 active">{{article.title}}</li>
      </ol>
    </nav>
    <div class="d-flex flex-column">
      <h2>{{article.title}}</h2>

      <div #content
           *ngFor="let section of article?.sections"
           class="formatted-content pt-3">
        <h3 #contentTitle class="mb-3">{{ section.title }}</h3>
        <div [innerHtml]="section.contentHtml | safeHtml"></div>
      </div>
    </div>
  </div>
  <app-help-center-email></app-help-center-email>
  <app-help-center-footer></app-help-center-footer>
</div>
