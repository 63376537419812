import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  Profile,
  SaveProfileSelectionModel,
  SaveStrengthCommentDTO,
  StrengthComment
} from '../models';
import { ProfileDetailsDTO } from '../models/profile-details-dto';
import { DigitalDebriefItem } from '../models/digital-debrief-item';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  profileUrl = '';

  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string
  ) {
    this.profileUrl = `${baseUrl}profile`;
  }

  getProfileForUser(userId: number): Observable<Profile> {
    return this.http.get<Profile>(`${this.profileUrl}/sections/${userId}`);
  }

  saveProfileSectionSelection(body: SaveProfileSelectionModel) {
    return this.http.post(`${this.profileUrl}/sections/selection`, body);
  }

  saveSpottedStrength(
    newStrengthComment: SaveStrengthCommentDTO
  ) {
    return this.http.post<StrengthComment>(
      `${this.profileUrl}/strength/${newStrengthComment.strengthOwnerId}/${newStrengthComment.strengthId}/comment`, newStrengthComment);
  }

  getProfilePDF(): Observable<Blob> {
    return this.http.get<Blob>(`${this.profileUrl}/pdf`);
  }

  getProfileDetails() {
    return this.http.get<ProfileDetailsDTO>(`${this.profileUrl}/details`);
  }

  markStrengthCommentAsAcknowledged(strengthCommentId: number) {
    return this.http.post<StrengthComment>(`${this.profileUrl}/strength/comment/${strengthCommentId}/acknowledge`, {});
  }

  saveSelectedProfileItems(items: DigitalDebriefItem[]) {
    return this.http.post(`${this.profileUrl}/debrief/profile-item`, items);
  }

  processDebriefReport(spotlightResponseId: number) {
    return this.http.get(`${this.profileUrl}/debrief/${spotlightResponseId}/process`)
  }
}
