import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { map, Subject, take, takeUntil } from 'rxjs';
import { AddChampionToAccount } from 'src/app/+state/account-admin/account-admin.actions';
import { selectAddChampionOptions, selectSelectedAccountSettingsAccount } from 'src/app/+state/account-admin/account-admin.selector';
import { AppState } from 'src/app/+state/app.state';
import { SelectValue } from 'src/app/shared/models';
import { faCloseSVG } from 'src/icons';

@Component({
  selector: 'app-add-account-champion-modal',
  templateUrl: './add-account-champion-modal.component.html',
  styleUrls: ['./add-account-champion-modal.component.scss']
})
export class AddAccountChampionModalComponent implements OnInit, OnDestroy {
  selectedAccount$ = this.store.select(selectSelectedAccountSettingsAccount);
  championOptions$ = this.store.select(selectAddChampionOptions);

  championOptions: SelectValue[] = [];

  selectedUserAk?: number;

  closeIcon = faCloseSVG;

  destroyed$ = new Subject<boolean>();

  constructor(private store: Store<AppState>, private modalRef: BsModalRef) {}

  ngOnInit(): void {
    this.championOptions$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(champions => {
        this.championOptions = champions.map(c => ({
          value: c.ak!,
          description: c.firstName + ' ' + c.lastName + ' (' + c.email + ')'
        }));

        this.selectedUserAk = undefined;
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.unsubscribe();
  }

  addChampion() {
    if (!this.selectedUserAk)
      return;

    this.selectedAccount$
      .pipe(take(1))
      .subscribe(account => {
        if (account) 
          this.store.dispatch(AddChampionToAccount.Request({ accountId: account.accountId, userAk: this.selectedUserAk! }));
      });
  }

  close() {
    this.modalRef.hide();
  }
}
