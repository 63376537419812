<div
  *ngIf="currentUser$ | async as currentUser"
  class="holding-screen bg-dark text-white d-flex flex-column justify-content-center align-items-center h-100 w-100"
>
  <ng-container *ngIf="currentUser.debriefDate; else noDate">
    <h2 class="header-word">Your account will go live in</h2>
    <div class="d-flex justify-content-center">
      <div class="d-flex flex-column align-items-center px-3">
        <h1 class="time-number mb-4">{{ daysLeft | number : "2.0-0" }}</h1>
        <h3 class="time-word">Days</h3>
      </div>
      <h1 class="time-colon mb-4">:</h1>
      <div class="d-flex flex-column align-items-center px-3">
        <h1 class="time-number mb-4">{{ hoursLeft | number : "2.0-0" }}</h1>
        <h3 class="time-word">Hours</h3>
      </div>
      <h1 class="time-colon mb-4">:</h1>
      <div class="d-flex flex-column align-items-center px-3">
        <h1 class="time-number mb-4">{{ minsLeft | number : "2.0-0" }}</h1>
        <h3 class="time-word">Minutes</h3>
      </div>
      <h1 class="time-colon mb-4">:</h1>
      <div class="d-flex flex-column align-items-center px-3">
        <h1 class="time-seconds mb-4">{{ secsLeft | number : "2.0-0" }}</h1>
        <h3 class="time-word">Seconds</h3>
      </div>
    </div>
    <h3 class="footer-message" (click)="openModal()">
      Why is my account locked <fa-icon class="" [icon]="circleQuestionIcon"></fa-icon>
    </h3>
  </ng-container>
  <ng-template #noDate>
    <h2 class="big-text mb-2">Your user account is currently locked</h2>
    <h3 class="account-question-text">
      If you have any questions in the meantime please contact your Mindflick
      admin
    </h3>
  </ng-template>
</div>
<img
  class="top-right-pattern"
  src="./assets/svg/nudge-patterns/top-right.svg"
/>
<div class="top-left-logo">
  <img class="me-4" src="./assets/images/logos/mindflick.svg" />
  <div class="nav-link cursor-pointer" (click)="logout()">Logout</div>
</div>
