import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import {
  ClearManagedUsersFilter,
  UpdateManagedUsersFilter,
} from '../../../+state/account-admin/account-admin.actions';
import {
  selectAccountAdminManagedUsers,
  selectAccountSettings,
  selectManagedUserFilter,
} from '../../../+state/account-admin/account-admin.selector';
import { AppState } from '../../../+state/app.state';
import { faCloseSVG } from '../../../../icons';
import {
  AccountAdminManagedUsersFilter,
  UserStatusEnum,
} from '../../../shared/models/account-admin-managed-users-DTO';
import { PlatformInviteStatusEnum } from '../../../shared/models/enums/platform-invite-status.enum';
import { SpotlightInviteStatusEnum } from '../../../shared/models/enums/spotlight-invite-status.enum';

@Component({
  selector: 'app-user-management-filter-modal',
  templateUrl: './user-management-filter-modal.component.html',
  styleUrls: ['./user-management-filter-modal.component.scss'],
})
export class UserManagementFilterModalComponent implements OnInit, OnDestroy {
  destroyed$: Subject<boolean> = new Subject<boolean>();
  users$ = this.store.select(selectAccountAdminManagedUsers);
  managedUserFilter$ = this.store.select(selectManagedUserFilter);
  adminCanAllocateSeats$ = this.store
    .select(selectAccountSettings)
    .pipe(map((settings) => settings?.adminCanAllocateSeats));

  filterForm = new UntypedFormGroup({
    userStatus: new UntypedFormControl(),
    userPreference: new UntypedFormControl(),
    dateAdded: new UntypedFormControl(),
    fromDate: new UntypedFormControl(),
    toDate: new UntypedFormControl(),
    department: new UntypedFormControl(),
    portalStatus: new UntypedFormControl(),
    appStatus: new UntypedFormControl(),
    hasUnusedSeats: new UntypedFormControl(),
  });

  userStatusOptions = [
    { value: UserStatusEnum.All, description: 'All' },
    { value: UserStatusEnum.User, description: 'User' },
    { value: UserStatusEnum.Champion, description: 'Champion' },
    { value: UserStatusEnum.Admin, description: 'Admin' },
  ];

  profileAccessOptions = [
    { value: true, description: 'Enabled' },
    { value: false, description: 'Disabled' },
  ];
  dateWeekAgo = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate() - 7
  );
  dateMonthAgo = new Date(
    new Date().getFullYear(),
    new Date().getMonth() - 1,
    new Date().getDate()
  );
  dateYearAgo = new Date(
    new Date().getFullYear() - 1,
    new Date().getMonth(),
    new Date().getDate()
  );
  departments: string[] = [];

  preferenceOptions = [
    { value: 'FC', description: 'FC' },
    { value: 'FO', description: 'FO' },
    { value: 'FP', description: 'FP' },
    { value: 'FE', description: 'FE' },
    { value: 'LC', description: 'LC' },
    { value: 'LO', description: 'LO' },
    { value: 'LP', description: 'LP' },
    { value: 'LE', description: 'LE' },
    { value: 'EC', description: 'EC' },
    { value: 'EO', description: 'EO' },
    { value: 'EP', description: 'EP' },
    { value: 'EE', description: 'EE' },
    { value: 'XC', description: 'XC' },
    { value: 'XO', description: 'XO' },
    { value: 'XP', description: 'XP' },
    { value: 'XE', description: 'XE' },
  ];
  dateAddedOptions = [
    { value: this.dateWeekAgo, description: 'Last Week' },
    { value: this.dateMonthAgo, description: 'Last Month' },
    { value: this.dateYearAgo, description: 'Year to date' },
    { value: 'custom', description: 'Specific date range' },
  ];
  departmentOptions = [{ value: '', description: '' }];
  portalStatusOptions = [
    { value: PlatformInviteStatusEnum.None, description: 'Not Invited' },
    { value: PlatformInviteStatusEnum.Invited, description: 'Invited' },
    { value: PlatformInviteStatusEnum.Locked, description: 'Locked' },
    { value: PlatformInviteStatusEnum.Completed, description: 'Active' },
  ];
  appStatusOptions = [
    { value: false, description: 'Inactive' },
    { value: true, description: 'Active' },
  ];

  isSpecificDateRange = false;
  minToDate?: Date;
  maxFromDate?: Date;

  hasUnusedSeats: boolean = false;
  nameFilter: string = '';

  closeIcon = faCloseSVG;

  constructor(
    private modalRef: BsModalRef,
    private localeService: BsLocaleService,
    private store: Store<AppState>
  ) {
    this.localeService.use('en-gb');
  }

  ngOnInit(): void {
    this.users$.pipe(takeUntil(this.destroyed$)).subscribe((users) => {
      this.departments = [
        ...new Set(users.filter(x => x.department != null && x.department != '').map((x) => x.department!)),
      ];
      this.departmentOptions = this.departments.map((x) => {
        return { value: x, description: x };
      });
    });

    this.managedUserFilter$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((filter) => {
        this.hasUnusedSeats = filter != undefined && filter.hasUnusedSeats;
        this.nameFilter = filter.nameFilter;
        this.filterForm.patchValue({
          userStatus:
            filter?.userStatus != undefined
              ? this.userStatusOptions.find(
                (x) => x.value == filter?.userStatus
              )?.value!
              : undefined,
          userPreference:
            filter?.preference != undefined
              ? this.preferenceOptions.find(
                (x) => x.value == filter?.preference
              )?.value!
              : undefined,
          dateAdded:
            filter?.dateAdded != undefined
              ? this.dateAddedOptions.find((x) => x.value == filter?.dateAdded)
                ?.value!
              : undefined,
          department:
            filter?.department != undefined
              ? this.departmentOptions.find(
                (x) => x.value == filter?.department
              )?.value!
              : undefined,
          portalStatus:
            filter?.portalStatus != undefined
              ? this.portalStatusOptions.find(
                (x) => x.value == filter?.portalStatus
              )?.value!
              : undefined,
          appStatus:
            filter?.appStatus != undefined
              ? this.appStatusOptions.find((x) => x.value == filter?.appStatus)
                ?.value!
              : undefined,
        });
      });
  }

  clearFilters() {
    this.store.dispatch(ClearManagedUsersFilter());
    this.close();
  }

  checkIfSpecificDate(event: any) {
    if (event == 'custom') {
      this.isSpecificDateRange = true;
    }
  }

  setMinToDate(event: any) {
    if (event) {
      this.minToDate = event as Date;
    }
  }

  setMaxFromDate(event: any) {
    if (event) {
      this.maxFromDate = event as Date;
    }
  }

  applyFilters() {
    var filter: AccountAdminManagedUsersFilter = {
      userStatus: this.filterForm.get('userStatus')?.value,
      preference: this.filterForm.get('userPreference')?.value,
      dateAdded: this.filterForm.get('dateAdded')?.value,
      toDate: this.filterForm.get('toDate')?.value,
      fromDate: this.filterForm.get('fromDate')?.value,
      department: this.filterForm.get('department')?.value,
      portalStatus: this.filterForm.get('portalStatus')?.value,
      appStatus: this.filterForm.get('appStatus')?.value,
      hasUnusedSeats: this.hasUnusedSeats,
      nameFilter: this.nameFilter,
    };

    this.store.dispatch(UpdateManagedUsersFilter({ filter }));
    this.close();
  }

  close() {
    this.modalRef.hide();
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.unsubscribe();
  }
}
