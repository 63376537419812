<div class="d-flex flex-row flex-wrap pt-4">
  <ng-container *ngIf="!showEmpty">
    <div class="tip_container col-4 p-3">
      <div class="tips-cell">
        <!-- ng-content to contain the image in the first tile -->
        <div class="coloured-dots">
          <ng-content></ng-content>
        </div>
        <h3 *ngIf="tileTitle" class="my-3">{{ tileTitle }}</h3>
        <p *ngIf="tileDescription" class="body-copy">{{ tileDescription }}</p>
      </div>
    </div>
    <div
      class="tip__container col-4 p-3"
      *ngFor="
        let itemTile of spotLightItems ? spotLightItems.slice(0, 5) : [];
        let i = index
      "
      [ngClass]="{ bottom: i >= 2, middle: i == 0 || i == 3 }"
    >
      <div class="d-flex">
        <p class="highlight-numbers m-0">0{{ i + 1 }}</p>
        <span class="flex-fill"></span>
        <div *ngIf="spotlightTab == 3" class="d-flex align-items-center">
          <div *ngIf="itemTile.isTopTip === true" class="small-text text-grey pe-1">TOP TIP</div>
          <fa-icon
            *ngIf="itemTile.isTopTip != true"
            [icon]="star"
            class="star-unpinned"
            (click)="setTopTip(itemTile.id)"
          ></fa-icon>
          <fa-icon
            *ngIf="itemTile.isTopTip === true"
            [icon]="star"
            class="star-pinned"
            (click)="removeTopTip(itemTile.id)"
          ></fa-icon>
        </div>
      </div>
      <h3 class="my-3">{{ itemTile.title }}</h3>
      <p class="body-copy">{{ itemTile.description }}</p>
    </div>
  </ng-container>
  <div *ngIf="showEmpty" class="d-flex flex-fill justify-content-center">
    <h3 class="my-4">This team doesn't have any connected members yet.</h3>
  </div>
</div>
