import { ElementRef, Input, OnDestroy, ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppState } from '../../../+state/app.state';
import {
  ChangeUserPicture,
  ClearUserPicture,
} from '../../../+state/user/user.actions';
import { selectCurrentUser } from '../../../+state/user/user.selector';
import { faCloseSVG, faDeleteSVG } from '../../../../icons';
import { User } from '../../models';
import { ImageCacheService } from '../../services/image-cache.service';
import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';
import { environment } from 'src/environments/environment';
import { selectUploadStatus } from '../../../+state/layout/selectors/layout.selector';
import { UploadStatus } from '../../models/enums/upload-status.enum';
import { SetUserPicture } from '../../../+state/survey/actions/survey.actions';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
@Component({
  selector: 'app-change-picture-modal',
  templateUrl: './change-picture-modal.component.html',
  styleUrls: ['./change-picture-modal.component.scss'],
})
export class ChangePictureModalComponent implements OnInit, OnDestroy {
  @Input() spotlightResponseId?: number;
  @Input() uniqueRef?: string;

  destroyed$: Subject<boolean> = new Subject<boolean>();

  currentUser$ = this.store.select(selectCurrentUser);
  uploadStatus$ = this.store.select(selectUploadStatus);
  currentUser?: User;
  isUploadEnabled: boolean = false;
  isFileSmallerThan5MB: boolean = true;
  imageChangedEvent?: any;
  defaultImg: string = './assets/svg/profile-placeholder.svg';
  croppedImage: SafeUrl | string | null | undefined = this.defaultImg;
  imageBlob: Blob | null | undefined;
  closeIcon = faCloseSVG;
  delete = faDeleteSVG;
  inProgress = UploadStatus.InProgress;
  uploadFailed = UploadStatus.Failed;
  base64headerLen = 'data:image/jpeg;base64,'.length;
  previousStatus?: UploadStatus;

  public publicPhotoBasePath = environment.publicStorageUrl + 'photos/thumb/';

  constructor(
    private modalRef: BsModalRef,
    private sanitizer: DomSanitizer,
    private store: Store<AppState>,
    private imageCache: ImageCacheService
  ) { }

  ngOnInit(): void {
    this.currentUser$.pipe(takeUntil(this.destroyed$)).subscribe((user) => {
      this.currentUser = user;
    });

    this.uploadStatus$.pipe(takeUntil(this.destroyed$)).subscribe((status) => {
      if (status == UploadStatus.Completed && this.previousStatus == UploadStatus.InProgress) {
        this.close();
      } else if (status == UploadStatus.InProgress) {
        this.isUploadEnabled = false;
      } else if (status == UploadStatus.Failed) {
        this.isUploadEnabled = true;
      }
      this.previousStatus = status;
    });
  }

  clearPhoto() {
    this.croppedImage = this.defaultImg;
    this.imageCache.flushCache();
    this.isUploadEnabled = true;
    this.imageChangedEvent = undefined;
  }

  base64ToBlob(base64: string) {
    var bin = atob(base64);
    var length = bin.length;
    var buf = new ArrayBuffer(length);
    var arr = new Uint8Array(buf);
    for (var i = 0; i < length; i++) {
      arr[i] = bin.charCodeAt(i);
    }
    return buf;
  }

  async saveChanges() {
    if (
      this.croppedImage == undefined ||
      this.croppedImage == this.defaultImg
    ) {
      this.store.dispatch(ClearUserPicture.Request());
    } else {
      const imageName = 'profile.jpeg';
      const imageFile = new File([this.imageBlob!], imageName, {
        type: 'image/jpeg',
      });

      var formData: FormData = new FormData();
      formData.append('file', imageFile);

      if (this.currentUser) {
        this.store.dispatch(ChangeUserPicture.Request({ formData }));
      } else {
        this.store.dispatch(
          SetUserPicture.Request({
            formData,
            spotlightResponseId: this.spotlightResponseId!,
            uniqueRef: this.uniqueRef!,
          })
        );
      }
      this.imageCache.setUserImage(
        'user/photo/' + this.currentUser?.strId!,
        this.imageBlob!
      );
    }
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.imageBlob = event.blob;
    this.croppedImage = this.sanitizer.bypassSecurityTrustUrl(event.objectUrl!);

    var stringLength = (event.base64?.length ?? 0) - this.base64headerLen;
    var sizeInBytes = Math.ceil(stringLength / 4) * 3; // Base64 encodes ~ 3 bytes over 4 chars

    this.isFileSmallerThan5MB = sizeInBytes < 5e6;
    this.isUploadEnabled = this.isFileSmallerThan5MB;
  }

  close() {
    this.modalRef.hide();
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.unsubscribe();
  }
}
