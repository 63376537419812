import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { faCloseSVG } from '../../../../icons';

@Component({
  selector: 'app-choose-file-modal',
  templateUrl: './choose-file-modal.component.html',
  styleUrls: ['./choose-file-modal.component.scss'],
})
export class ChooseFileModalComponent implements OnInit {
  @Input()
  maxSize = 0;

  file?: File;

  modalClosed = new EventEmitter<File | undefined>();
  closeIcon = faCloseSVG;

  constructor(private modalRef: BsModalRef) {}

  ngOnInit(): void {}

  handleFileUpload(target: EventTarget | null) {
    const input = target as HTMLInputElement;

    if (input && input.files) this.file = input.files.item(0)!;
  }

  confirm() {
    this.modalClosed.emit(this.file);
    this.modalRef.hide();
  }

  close() {
    this.modalClosed.emit(undefined);
    this.modalRef.hide();
  }
}
