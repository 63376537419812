import { createAction, props } from '@ngrx/store';
import { Tooltip, TooltipSection } from 'src/app/shared/models';

export namespace GetTooltips {
  export const Request = createAction('[Tooltip] Get Tooltips Request');

  export const Success = createAction(
    '[Tooltip] Get Tooltips Success',
    props<{
      sections: TooltipSection[];
      tooltips: Tooltip[];
    }>()
  );

  export const Fail = createAction(
    '[Tooltip] Get Tooltips Fail',
    props<{ error: any }>()
  );
}

export const SetSelectedTooltipSection = createAction(
  '[Tooltip] Set Selected Tooltip Section',
  props<{
    sectionId?: number;
  }>()
);

// It is OK to skip the setting of the tooltip section, if the selected tooltip is in a different section to the one already selected then
// the correct section will also be set by this action
export const SetSelectedTooltip = createAction(
  '[Tooltip] Set Selected Tooltip',
  props<{
    tooltipId?: number;
  }>()
);

export const SetSelectedTooltipByNames = createAction(
  '[Tooltip] Set Selected Tooltip By Names',
  props<{ tooltipName: string; sectionShortName: string }>()
);

export const SetSelectedPage = createAction(
  '[Tooltip] Set Selected Page',
  props<{
    pageId?: number;
  }>()
);

export const ChangeSelectedPage = createAction(
  '[Tooltip] Change Selected Page',
  props<{
    pageChange: number;
  }>()
);

export namespace SaveTooltip {
  export const Request = createAction(
    '[Tooltip] Save Tooltip Request',
    props<{ tooltip: Tooltip }>()
  );

  export const Success = createAction(
    '[Tooltip] Save Tooltip Success',
    props<{ tooltip: Tooltip }>()
  );

  export const Fail = createAction(
    '[Tooltip] Save Tooltip Fail',
    props<{ error: any }>()
  );

  export const Edit = createAction(
    '[Tooltip] Edit Tooltip ',
    props<{
      tooltipId?: number;
    }>()
  );

  export const Clear = createAction('[Tooltip] Clear Tooltip Request');
}
