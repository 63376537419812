export enum NotificationType {
  ConnectionRequest,
  ConnectionAccepted,
  TeamRequest,
  TeamMemberAccepted,
  TeamMemberRemoved,
  TeamMemberDeclined,
  StrengthSpotted,
  StrengthCommentAcknowledged
}
