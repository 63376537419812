<div class="modal-header justify-content-end">
  <fa-icon
    class="cursor-pointer fa-lg"
    [icon]="closeIcon"
    (click)="close()"
  ></fa-icon>
</div>
<div class="modal-body">
    <h2 class="mb-4">Edit Organisation Admin</h2>
    <div class="col-12">
      <label for="account-lead" class="form-label">Mindflick coach</label>
      <app-dropdown-select [options]="users"
                           placeholder="Please select"
                           [(ngModel)]="selectedUserAk"></app-dropdown-select>
    </div>
    <button class="btn btn-purple mt-4 w-100" (click)="saveChanges()">Save Changes</button>
</div>
<div class="modal-bottom-border"></div>