import { Routes } from '@angular/router';
import { AutoLoginPartialRoutesGuard } from 'angular-auth-oidc-client';
import { CallbackComponent } from './shared/components/callback/callback.component';
import { SuperAdminGuard } from './auth/super-admin.guard';

export const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'dashboard',
  },
  {
    path: 'public',
    loadChildren: () =>
      import('./public/public.module').then((m) => m.PublicModule),
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
    canActivate: [AutoLoginPartialRoutesGuard],
  },
  {
    path: 'profile',
    loadChildren: () =>
      import('./individuals/individuals.module').then(
        (m) => m.IndividualsModule
      ),
    canActivate: [AutoLoginPartialRoutesGuard],
  },
  {
    path: 'connections',
    loadChildren: () =>
      import('./connections/connections.module').then(
        (m) => m.ConnectionsModule
      ),
    canActivate: [AutoLoginPartialRoutesGuard],
  },
  {
    path: 'account',
    loadChildren: () =>
      import('./account/account.module').then((m) => m.AccountModule),
    canActivate: [AutoLoginPartialRoutesGuard],
  },
  {
    path: 'admin',
    loadChildren: () =>
      import('./admin/admin.module').then((m) => m.AdminModule),
    canActivate: [AutoLoginPartialRoutesGuard],
    data: ['Admin'],
  },
  {
    path: 'teams',
    loadChildren: () =>
      import('./teams/teams.module').then((m) => m.TeamsModule),
    canActivate: [AutoLoginPartialRoutesGuard],
  },
  {
    path: 'survey',
    loadChildren: () =>
      import('./survey/survey.module').then((m) => m.SurveyModule)
  },
  {
    path: 'growth',
    loadChildren: () =>
      import('./growth/growth.module').then((m) => m.GrowthModule),
    canActivate: [AutoLoginPartialRoutesGuard],
  },
  {
    path: 'super-admin',
    loadChildren: () =>
      import('./super-admin/super-admin.module').then(
        (m) => m.SuperAdminModule
      ),
    canActivate: [AutoLoginPartialRoutesGuard, SuperAdminGuard],
  },
  {
    path: 'debrief',
    loadChildren: () =>
      import('./debrief/debrief.module').then((m) => m.DebriefModule),
    canActivate: [AutoLoginPartialRoutesGuard],
  },
  { path: 'unauthorized', redirectTo: "dashboard" },
  { path: 'callback', component: CallbackComponent },

  {
    path: '**',
    redirectTo: 'dashboard',
  }
];
