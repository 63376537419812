import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-help-center-email',
  templateUrl: './help-center-email.component.html',
  styleUrls: ['./help-center-email.component.scss'],
})
export class HelpCenterEmailComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
