<div class="h-100">
  <div class="side-navbar-list-container position-relative w-100">
    <ul class="side-navbar-list">
      <li *ngFor="let item of structure" class="side-navbar-list-item py-4 mx-2">
        <app-navbar-item [item]="item"></app-navbar-item>
      </li>
      <li class="side-navbar-list-item py-4 mx-2" *ngIf="isAuthenticated$ | async">
        <div (click)="logout()" class="menu-item-font">
          <fa-icon [icon]="['fas','arrow-right-from-bracket']" alt="Logout" class="menu-item-icon"></fa-icon>
          Logout
        </div>
      </li>
      <li class="side-navbar-list-item py-4 mx-2" *ngIf="!(isAuthenticated$ | async)">
        <div (click)="login()" class="menu-item-font">
          <fa-icon [icon]="['fas','arrow-right-to-bracket']" alt="Logout" class="menu-item-icon"></fa-icon>
          Login
        </div>
      </li>
    </ul>
  </div>
</div>
