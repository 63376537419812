import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-empty-chair-icon',
  templateUrl: './empty-chair-icon.component.html',
  styleUrls: ['./empty-chair-icon.component.scss'],
})
export class EmptyChairIconComponent implements OnInit, OnChanges {
  @Input()
  ecFlex = '';

  @Input()
  ecCope = '';

  chairBack = '#FFFFFF';
  chairSeat = '#FFFFFF';

  constructor() {}

  ngOnInit(): void {
    this.setChairBack();
    this.setChairSeat();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setChairBack();
    this.setChairSeat();
  }

  private setChairSeat() {
    switch (this.ecCope) {
      case 'C':
        this.chairSeat = '#000000';
        break;
      case 'O':
        this.chairSeat = '#FF5F00';
        break;
      case 'P':
        this.chairSeat = '#7000DD';
        break;
      case 'E':
        this.chairSeat = '#878787';
        break;
    }
  }

  private setChairBack() {
    switch (this.ecFlex) {
      case 'F':
        this.chairBack = '#FF2E5E';
        break;
      case 'L':
        this.chairBack = '#83A9FF';
        break;
      case 'E':
        this.chairBack = '#31C99A';
        break;
      case 'X':
        this.chairBack = '#FECF33';
        break;
    }
  }
}
