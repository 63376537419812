import { Component, OnInit } from '@angular/core';
import {
    FormControl,
    UntypedFormControl,
    UntypedFormGroup,
    Validators,
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { map, take } from 'rxjs';
import {
    AddMindflickAccount,
    ClearSaveMindflickOutcomes,
} from 'src/app/+state/account/account.actions';
import { AppState } from 'src/app/+state/app.state';
import { MindflickAccount, SelectValue } from 'src/app/shared/models';
import { faCloseSVG } from 'src/icons';
import { SuperAdminService } from '../../shared/services/super-admin.service';
import { MindflickAccountType } from '../../shared/models/enums/mindflick-account-type.enum';

@Component({
  selector: 'app-add-mindflick-account-modal',
  templateUrl: './add-mindflick-account-modal.component.html',
  styleUrls: ['./add-mindflick-account-modal.component.scss'],
})
export class AddMindflickAccountModalComponent implements OnInit {

  orgId = 0;

  addAccountForm = new UntypedFormGroup({
    name: new UntypedFormControl('', Validators.required),
    seats: new UntypedFormControl(50, [Validators.required, Validators.min(0)]),
    renewalDate: new FormControl<Date | null>(null, [Validators.required]),
    maximumChurnPercentage: new FormControl<number>(15, [Validators.required, Validators.min(0), Validators.max(100)]),
    type: new UntypedFormControl(null, Validators.required)
  });

  coaches: SelectValue[] = [];
  types: SelectValue[] = [{ value: MindflickAccountType.Paid, description: 'Paid' }, { value: MindflickAccountType.Unpaid, description: 'Unpaid' }, { value: MindflickAccountType.Demo, description: 'Demo' },]
  closeIcon = faCloseSVG;

  constructor(private store: Store<AppState>, private modalRef: BsModalRef, private superAdminService: SuperAdminService) {}

  ngOnInit(): void {
    this.superAdminService.getAccountMindflickCoaches().pipe(take(1), map((practs) =>
      this.coaches = practs.map((p) => {
        return {
          value: p.ak,
          description: p.firstName + ' ' + p.lastName,
        } as SelectValue;
      })
    ))
      .subscribe(_ => _);

  }

  save() {
    if (this.addAccountForm.invalid) {
      this.addAccountForm.markAllAsTouched();
      return;
    }

    const newAccount: MindflickAccount = {
      ...this.addAccountForm.value,
      mindflickOrganisationId: this.orgId,
    };

    this.store.dispatch(AddMindflickAccount.Request({ account: newAccount }));

    this.close();
  }

  close() {
    this.store.dispatch(ClearSaveMindflickOutcomes());
    this.modalRef.hide();
  }
}
