<div class="modal-header justify-content-end">
  <fa-icon
    class="cursor-pointer fa-lg"
    [icon]="closeIcon"
    (click)="close()"
  ></fa-icon>
</div>
<div class="modal-body">
  <ng-container *ngIf="nudge">
    <div class="position-relative mb-4">
      <img class="nudge-image w-100" [src]="nudge.squareImageUrl" />
      <div class="fade-out w-100"></div>
    </div>

    <h2 class="mb-4">{{ nudge.title }}</h2>
    <div class="body-copy mb-2">{{ nudge.paragraph1 }}</div>
    <div class="body-copy">{{ nudge.paragraph2 }}</div>
  </ng-container>
</div>
<div class="modal-bottom-border"></div>
