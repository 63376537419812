import Step from 'shepherd.js/src/types/step';
import { Store } from '@ngrx/store';
import { inject } from '@angular/core';
import { filter, map, Observable, Subject } from 'rxjs';
import { AppState } from 'src/app/+state/app.state';
import { selectPendingUsers, selectSelectedUser } from 'src/app/+state/user/user.selector';
import { User } from '../models';
import { selectIsConnectionsPending } from 'src/app/+state/layout/selectors/layout.selector';
import { ShepherdService } from 'angular-shepherd';
import { addButtonSpan, addProgressToStep } from '../helpers/onboarding-footer.helpers';

//@Injectable({
//  providedIn: 'root',
//})
export class OnboardingStepsService {

  shepherdService = inject(ShepherdService);
  store = inject(Store<AppState>);

  anyPendingRequests$ = this.store.select(selectIsConnectionsPending);
  pendingUsers$ = this.store.select(selectPendingUsers);
  selectedUser$ = this.store.select(selectSelectedUser);
  destroyed$ = new Subject<boolean>();
  firstConnectionName = '';
  firstConnectionGender = '';
  anyPendingRequests = false;

  currentStepId$ = new Subject<string>();

  PROFILE_BEHAVIOUR_TAB = 'profile-spotlight-flex';
  PROFILE_CONNECT_TAB = 'profile-spotlight-tips-tab';
  PROFILE_CHAIR_TAB = 'profile-spotlight-chair-tab';
  PROFILE_WRIGGLE_TAB = 'profile-spotlight-wriggle-tab';
  PROFILE_SPOT_STRENGTH = 'profile-strengths-2';

  getOnboardingSteps(page: string): Observable<Step.StepOptions[]> {
    if (page === 'dashboard')
      return this.pendingUsers$.pipe(
        map((userData) => this.generateSteps(userData, page))
      );

    return this.selectedUser$.pipe(
      map((user) => user ? this.generateSteps([ user ], page) : this.generateSteps([], page))
    );
  }

  private generateSteps(userData: User[], page: string): Step.StepOptions[] {
    const updateCurrentStep = (id: string) => {
      this.currentStepId$.next(id);
    };


    let steps: Step.StepOptions[] = [];


    const weeklyNudgeSteps : Step.StepOptions[] = [
      {
        id: 'weekly-nudge',
        classes: "small-square",
        attachTo: {
          element: ".nudge-container",
          on: "right"
        },
        text: '<div class="mb-3"><h2 class="mb-2 mt-35">Weekly nudge</h2><p class="pt-1">Sometimes, we all need a little nudge.</p><p> Each week, Mindflick will send you a personalised tip to help you turn insight into action.</p></div>',
        buttons: [
          {
            text: "",
            classes: "btn btn-dot pe-1 first bg-primary",
          },
          {
            text: "",
            classes: "btn btn-dot cursor-pointer",
            action (){
              const currStep = this.getCurrentStep();
              currStep?.updateStepOptions({
                id: weeklyNudgeSteps[1].id,
                text : weeklyNudgeSteps[1].text,
                buttons : weeklyNudgeSteps[1].buttons
              })
            }
          },
          {
            text: "Next",
            classes: "btn btn-purple mb-35 mt-1",
            action (){
              const currStep = this.getCurrentStep();
              currStep?.updateStepOptions({
                id: weeklyNudgeSteps[1].id,
                text : weeklyNudgeSteps[1].text,
                buttons : weeklyNudgeSteps[1].buttons
              })
            }
          }
        ],
        when: {
          show() {
            addProgressToStep(this);
            addButtonSpan(this);
            updateCurrentStep(this.id);
          }
        }
      },
      {
        id: 'weekly-nudge-2',
        classes: "small-square",
        attachTo: {
          element: ".nudge-container",
          on: "right"
        },
        text: '<div class="mb-3"><h2 class="mb-2 mt-35">Weekly nudge</h2><p class="pt-1">Every Friday, you can rate how effective your nudge was.</p><p>Your feedback helps us fine-tune future nudges just for you.</p></div>',
        buttons: [
          {
            text: "",
            classes: "btn btn-dot pe-1 first cursor-pointer",
            action (){
              const currStep = this.getCurrentStep();
              currStep?.updateStepOptions({
                id: weeklyNudgeSteps[0].id,
                text : weeklyNudgeSteps[0].text,
                buttons : weeklyNudgeSteps[0].buttons
              })
            }
          },
          {
            text: "",
            classes: "btn btn-dot bg-primary",

          },
          {
            text: "Next",
            classes: "btn btn-purple mb-35 mt-1",
            action(){
              return this.next();
            }
          }
        ],
        when: {
          show() {
            addProgressToStep(this);
            addButtonSpan(this);
            updateCurrentStep(this.id);
          }
        }
      }
    ];


    const tipsToConnectSteps : Step.StepOptions[]=[
      {
        id: 'profile-spotlight-tips-map-1',
        classes: "small-square",
        attachTo: {
          element: ".shepherd-profile-spotlight",
          on: "right"
        },
        text: `<div class="mb-3"><h2 class="mb-2 mt-35">Tips to connect</h2><p class="pt-1">This section provides practical tips, based on your shared preferences, to help you quickly build a connection with ${this.firstConnectionName}. </p><p>These are tailored to your unique styles, so they’ll look different for everyone.</p></div>`,
        when: {
          show() {
            addProgressToStep(this);
            addButtonSpan(this);
            updateCurrentStep(this.id);
          }
        },
        buttons: [
          {
            text: "",
            classes: "btn btn-dot bg-primary pe-1 first",
          },
          {
            text: "",
            classes: "btn btn-dot ",
            action (){
              const currStep = this.getCurrentStep();
              currStep?.updateStepOptions({
                id: tipsToConnectSteps[1].id,
                text : tipsToConnectSteps[1].text,
                buttons : tipsToConnectSteps[1].buttons
              })
            }
          },
          {
            text: "Next",
            classes: "btn btn-purple ms-auto mb-35",
            action (){
              const currStep = this.getCurrentStep();
              currStep?.updateStepOptions({
                id: tipsToConnectSteps[1].id,
                text : tipsToConnectSteps[1].text,
                buttons : tipsToConnectSteps[1].buttons
              })
            }
          },
        ],
      },
      {
          id: 'profile-spotlight-tips-map-2',
          classes: "small-square",
          attachTo: {
            element: ".shepherd-profile-spotlight",
            on: "right"
          },
          text: `<div class="mb-3"><h2 class="mb-2 mt-35">Tips to connect</h2><p class="pt-1">To get the most out of these tips, use them to:</p><ul><li>Prepare for a meeting</li><li>Frame conversations effectively</li><li>Discuss how to work best together</li></ul><p><b>Tip:</b> Mark any tip as a favourite if you find it especially helpful.</p></div>`,
          when: {
            show() {
              addProgressToStep(this);
              addButtonSpan(this);
              updateCurrentStep(this.id);
            }
          },
          buttons: [
            {
              text: "",
              classes: "btn btn-dot pe-1 first",
              action (){
                const currStep = this.getCurrentStep();
                currStep?.updateStepOptions({
                  id: tipsToConnectSteps[0].id,
                  text : tipsToConnectSteps[0].text,
                  buttons : tipsToConnectSteps[0].buttons
                })
              }
            },
            {
              text: "",
              classes: "btn btn-dot bg-primary",
            },
            {
              text: "Next",
              classes: "btn btn-purple ms-auto mb-35",
              action() { return this.next(); }
            },
          ],
        },
    ]


    const emptyChairSteps : Step.StepOptions[] = [
      {
        id: 'profile-spotlight-chair-map-1',
        classes: "small-square",
        attachTo: {
          element: ".shepherd-profile-spotlight",
          on: "right"
        },
        text: `<div class="mb-3"><h2 class="mb-2 mt-35">Empty Chair</h2><p class="pt-1">MIndflick’s Empty Chair feature highlights the preferences that are least emphasised in your combined spotlights.</p><p> It offers priming questions from this perspective.</p></div>`,
        when: {
          show() {
            addProgressToStep(this);
            addButtonSpan(this);
            updateCurrentStep(this.id);
          }
        },
        buttons: [
          {
            text: "",
            classes: "btn btn-dot bg-primary pe-1 first",
          },
          {
            text: "",
            classes: "btn btn-dot",
            action (){
              const currStep = this.getCurrentStep();
              currStep?.updateStepOptions({
                id: emptyChairSteps[1].id,
                text : emptyChairSteps[1].text,
                buttons : emptyChairSteps[1].buttons
              })
            }
          },
          {
            text: "Next",
            classes: "btn btn-purple ms-auto mb-35",
            action (){
              const currStep = this.getCurrentStep();
              currStep?.updateStepOptions({
                id: emptyChairSteps[1].id,
                text : emptyChairSteps[1].text,
                buttons : emptyChairSteps[1].buttons
              })
            }
          },
        ],
      },
        {
          id: 'profile-spotlight-chair-map-2',
          classes: "small-square",
          attachTo: {
            element: ".shepherd-profile-spotlight",
            on: "right"
          },
          text: `<div class="mb-3"><h2 class="mb-2 mt-35">Empty Chair</h2><p class="pt-1">Use the Empty Chair questions to reveal any blind spots in your collaboration. These are particularly helpful:</p><ul><li>When planning projects together</li><li>Before presenting an idea to a wider group</li><li>When making a joint decision</li></div>`,
          when: {
            show() {
              addProgressToStep(this);
              addButtonSpan(this);
              updateCurrentStep(this.id);
            }
          },
          buttons: [
            {
              text: "",
              classes: "btn btn-dot pe-1 first",
              action (){
                const currStep = this.getCurrentStep();
                currStep?.updateStepOptions({
                  id: emptyChairSteps[0].id,
                  text : emptyChairSteps[0].text,
                  buttons : emptyChairSteps[0].buttons
                })
              }
            },
            {
              text: "",
              classes: "btn btn-dot bg-primary",
            },
            {
              text: "Next",
              classes: "btn btn-purple ms-auto mb-35",
              action() { return this.next(); }
            },
          ],
        },
    ]

    if (userData && userData.length > 0) {
      this.firstConnectionName = userData[0].firstName;
      this.firstConnectionGender = userData[0].gender ?? '?';
    }

    let pronounSubject = 'they';
    let pronounObject = 'them';
    let pronounPossessive = 'their';

    if (this.firstConnectionGender === 'm') {
      pronounSubject = 'he';
      pronounObject = 'him';
      pronounPossessive = 'his';
    } else if (this.firstConnectionGender === 'f') {
      pronounSubject = 'she';
      pronounObject = 'her';
      pronounPossessive = 'her';
    }

    if (page == 'dashboard') {
      steps = [
        {
          id: 'first-step',
          text: `<div class="mx-50"><h2 class="mb-2">Welcome to Mindflick</h2><p class="pt-1">Great! You’ve taken the first step by exploring your profile, and now it’s time to turn those insights into action - one small, manageable nudge at a time.</p><p class="mb-35">Together, we’ll explore how Mindflick can help you strengthen your connections with your colleagues. Sound good? Let’s dive in!</p></div>`,
          buttons: [
            {
              text: "Begin walkthrough",
              classes: "btn-shepherd btn btn-purple w-100 mx-50",
              action() { return this.next(); }
            }
          ],
          when: {
            show() {
              addProgressToStep(this);
              addButtonSpan(this);
              updateCurrentStep(this.id);
            }
          }
        },
        weeklyNudgeSteps[0],
        {
          id: 'popular-nudges',
          classes: "small-square",
          attachTo: {
            element: ".org-nudge",
            on: "right"
          },
          showOn: () => !!document.querySelector(".org-nudge"),
          text: '<div class="mb-3"><h2 class="mb-2 mt-35">Popular nudges</h2><p class="pt-1">Check out the most popular nudges in your organisation to see what’s resonating with your colleagues.</p></div>',
          buttons: [
            {
              text: "",
              classes: "btn btn-dot bg-primary first",
            },
            {
              text: "Next",
              classes: "btn btn-purple mb-35 mt-1",
              action() {
                return this.next();
              }
            },
          ],
          when: {
            show() {
              addProgressToStep(this);
              addButtonSpan(this);
              updateCurrentStep(this.id);
            }
          }
        },
        {
          id: 'pending-users',
          classes: "small-square",
          attachTo: {
            element: ".shepherd-step-highlight",
            on: "right"
          },
          advanceOn: { selector: '.user-panel', event: 'click' },
          showOn: () => !!document.querySelector(".pending-users .member-picture"),
          text: `<div class="mb-4"><h2 class="mb-2 mt-35">Connection requests</h2><p class="pt-1">Knowing your strengths is just the start. The next step? Flexing your style to bring out the best in others.</p><p>You've got a connection request from ${this.firstConnectionName}. Click on ${pronounPossessive} profile to learn more.</p></div>`,
          when: {
            show() {
              addProgressToStep(this);
              addButtonSpan(this);
              updateCurrentStep(this.id);
            },
          }
        },
        {
          id: 'accept-connection',
          classes: "small-square",
          attachTo: {
            element: ".profile-buttons",
            on: "bottom"
          },
          advanceOn: { selector: '.profile-buttons', event: 'click' },
          showOn: () => !!document.querySelector(".profile-buttons"),
          text: `<div class="mb-4"><h2 class="mb-2 mt-35">Permission-based sharing</h2><p class="pt-1">Your profile is personal to you. Other Mindflick users can only see your information if you accept connection requests or send them a request. You're in full control.</p><p>Want to connect with ${this.firstConnectionName} and explore ${pronounPossessive} profile?</p></div>`,
        },
      ];
    } else if (page == 'profile') {
      steps = [
        {
          id: 'profile-spotlight',
          classes: "small-square",
          attachTo: {
            element: ".shepherd-profile-spotlight",
            on: "right"
          },
          showOn: () => !!document.querySelector(".shepherd-profile-spotlight"),
          text: `<div class="mb-3"><h2 class="mb-2 mt-35">Mindset team map</h2><p class="pt-1">The mindset team map shows where you and your connection sit on the COPE framework. It highlights your similarities and differences, helping you understand how you might view situations differently.</p></div>`,
          when: {
            show() {
              addProgressToStep(this);
            }
          },
          buttons: [
            {
              text: "Next",
              classes: "btn btn-purple ms-auto mb-35",
              action() { return this.next(); }
            }
          ],
        },
        {
          id: this.PROFILE_BEHAVIOUR_TAB,
          classes: "small-square",
          attachTo: {
            element: ".shepherd-profile-behaviour-text",
            on: "bottom"
          },
          advanceOn: { selector: '.shepherd-profile-behaviour>.nav-link', event: 'click' },
          text: `<div class="mb-4"><h2 class="mb-2 mt-35">Behavioural style</h2><p class="pt-1">Click here to explore your behavioural style team map.</p></div>`,
        },
        {
          id: 'profile-spotlight-flex-map',
          classes: "small-square",
          attachTo: {
            element: ".shepherd-profile-spotlight",
            on: "right"
          },
          text: `<div class="mb-3"><h2 class="mb-2 mt-35">Behavioural style team map</h2><p class="pt-1">The behavioural style team map shows where you and your connection sit on the FLEX framework, helping you communicate and collaborate more effectively.</p></div>`,
          when: {
            show() {
              addProgressToStep(this);
            }
          },
          buttons: [
            {
              text: "Next",
              classes: "btn btn-purple ms-auto mb-35",
              action() { return this.next(); }
            }
          ],
        },
        {
          id: this.PROFILE_CONNECT_TAB,
          classes: "small-square",
          attachTo: {
            element: ".shepherd-profile-tips-text",
            on: "bottom"
          },
          advanceOn: { selector: '.shepherd-profile-tips>.nav-link', event: 'click' },
          text: `<div class="mb-4"><h2 class="mb-2 mt-35">Tips to connect</h2><p class="pt-1">Click here to explore tips for connecting with ${this.firstConnectionName}</p></div>`,
        },
        tipsToConnectSteps[0],
        {
          id: this.PROFILE_CHAIR_TAB,
          classes: "small-square",
          attachTo: {
            element: ".shepherd-profile-chair-text",
            on: "bottom"
          },
          advanceOn: { selector: '.shepherd-profile-chair>.nav-link', event: 'click' },
          text: `<div class="mb-4"><h2 class="mb-2 mt-35">Empty chair</h2><p class="pt-1">Click here to learn abut the Empty Chair and how to use it.</p></div>`,
        },
        emptyChairSteps[0],
        {
          id: 'profile-strengths',
          classes: "small-square",
          attachTo: {
            element: ".shepherd-profile-strengths",
            on: "right"
          },
          text: `<div class="mb-3"><h2 class="mb-2 mt-35">Playing to ${this.firstConnectionName}'s strengths</h2><p class="pt-1">Now that you’re connected, you can view ${this.firstConnectionName}'s strengths. Use them to:</p><ul><li>Prepare for a meeting with ${pronounObject}</li><li>Guide a coaching conversation on how ${pronounSubject} can use ${pronounPossessive} strengths in ${pronounPossessive} role</li><li>Provide ${pronounObject} with feedback</li></ul></div>`,
          when: {
            show() {
              addProgressToStep(this);
              addButtonSpan(this);
              updateCurrentStep(this.id);
            }
          },
          buttons: [
            {
              text: "",
              classes: "btn btn-dot bg-primary pe-1 first",
            },
            {
              text: "",
              classes: "btn btn-dot",
              action() { return this.next(); }
            },
            {
              text: "Next",
              classes: "btn btn-purple ms-auto mb-35",
              action() { return this.next(); }
            },
          ],
        },
        {
          id: this.PROFILE_SPOT_STRENGTH,
          classes: "small-square",
          attachTo: {
            element: ".shepherd-profile-strengths",
            on: "right"
          },
          advanceOn: { selector: '.shepherd-strength-spot', event: 'click' },
          text: `<div class="mb-3"><h2 class="mb-2 mt-35">Playing to ${this.firstConnectionName}'s strengths</h2><p class="pt-1">Strengths-based feedback boosts confidence and reinforces positive actions. Why not give it a try? </p><p>Look at ${this.firstConnectionName}’s strengths, recall a time you saw ${pronounSubject} shine, and click the thumbs up to acknowledge it.</p></div>`,
          when: {
            show() {
              addProgressToStep(this);
              addButtonSpan(this);
              updateCurrentStep(this.id);
            }
          },
          buttons: [
            {
              text: "",
              classes: "btn btn-dot pe-1 first mb-35",
              action() { return this.back(); }
            },
            {
              text: "",
              classes: "btn btn-dot bg-primary mb-35",
            },
          ],
        },
        {
          id: 'profile-strengths-spot',
          classes: "small-square",
          attachTo: {
            element: ".modal-content",
            on: "left"
          },
          text: `<div class="mb-4"><h2 class="mb-2 mt-35">Strengths spotting</h2><p class="pt-1">Think of a moment when you saw this strength in action.</p><p>Describe what you observed and the impact it had on you.</p></div>`,
          when: {
            show() {
              addProgressToStep(this);
            }
          },
        },
        {
          id: 'selected-strength',
          classes: "small-square",
          attachTo: {
            element: ".shepherd-selected-strength",
            on: "left"
          },
          text: `<div class="mb-3"><h2 class="mb-2 mt-35">Strength spotted</h2><p class="pt-1">Nice work! ${this.firstConnectionName} will be notified that you’ve spotted a strength in ${pronounObject}.</p><p>Keep strength spotting in mind - reinforcing positive actions encourages more of them!</p></div>`,
          when: {
            show() {
              addProgressToStep(this);
            }
          },
          buttons: [
            {
              text: "Next",
              classes: "btn btn-purple ms-auto mb-35",
              action() { return this.next(); }
            }
          ],
        },
        {
          id: this.PROFILE_WRIGGLE_TAB,
          classes: "small-square",
          attachTo: {
            element: ".shepherd-profile-wriggle-text",
            on: "bottom"
          },
          advanceOn: { selector: '.shepherd-profile-wriggle>.nav-link', event: 'click' },
          text: `<div class="mb-4"><h2 class="mb-2 mt-35">Wriggle Room</h2><p class="pt-1">Click here to explore how to give ${this.firstConnectionName} the wriggle room ${pronounSubject} need${pronounSubject === 'they' ? '' : 's'} to be at ${pronounPossessive} best.</p></div>`,
        },
        {
          id: 'profile-wriggle-rooms',
          classes: "small-square",
          attachTo: {
            element: ".shepherd-profile-strengths",
            on: "left"
          },
          text: `<div class="mb-3"><h2 class="mb-2 mt-35">Give others wriggle-room</h2><p class="pt-1">Explore how giving ${this.firstConnectionName} the wriggle-room ${pronounSubject} need${pronounSubject === 'they' ? '' : 's'}  can support ${pronounObject} in being at ${pronounPossessive} best and allow ${pronounObject} to fully play to ${pronounPossessive} strengths.</p></div>`,
          when: {
            show() {
              addProgressToStep(this);
              addButtonSpan(this);
              updateCurrentStep(this.id);
            }
          },
          buttons: [
            {
              text: "Next",
              classes: "btn btn-purple ms-auto mb-35",
              action() { return this.next(); }
            },
          ],
        },
        {
          id: 'connect-nav',
          classes: "small-square",
          attachTo: {
            element: ".shepherd-connect-nav",
            on: "bottom"
          },
          advanceOn: { selector: '.shepherd-connect-nav', event: 'click' },
          text: `<div class="mb-4"><h2 class="mb-2 mt-35">Connect</h2><p class="pt-1">Now that you know how to maximise connected profiles, click the 'Connect' tab to expand your network!</p></div>`,
        },
      ];
    } else if (page == 'connections') {
      steps = [
        {
          id: 'all-users',
          classes: "small-square",
          attachTo: {
            element: ".shepherd-all-users-text",
            on: "right"
          },
          advanceOn: { selector: '.shepherd-all-users>.nav-link', event: 'click' },
          text: `<div class="mb-4"><h2 class="mb-2 mt-35">View all users</h2><p class="pt-1 mb-35">${this.firstConnectionName} is now in your Connections. Click on the ‘All users’ tab to explore others you can connect with at Mindflick.</p></div>`,
        },
      ];
    } else if (page == 'accept-request') {
      steps = [{
        id: 'accept-connection',
        classes: "small-square",
        attachTo: {
          element: ".profile-buttons",
          on: "bottom"
        },
        showOn: () => !!document.querySelector(".profile-buttons"),
        advanceOn: { selector: '.profile-buttons .btn-orange', event: 'click' },
        text: `<div class="mb-4"><h2 class="mb-2 mt-35">Permission-based sharing</h2><p class="pt-1">Your profile is personal to you. Other Mindflick users can only see your information if you both accept connection requests. You're in full control. </p><p>Want to connect with ${this.firstConnectionName} and explore ${pronounPossessive} profile? Just accept ${pronounPossessive} request.</p></div>`,
      },];
    }


    //Remove connection request selection if no pending requests
    if (page == 'dashboard' && userData.length === 0) {
      steps.splice(-2, 2);
      steps.push(this.getConnectStep());
    }

    this.currentStepId$.next(steps[0].id ?? '');

    return steps;
  }

  getConnectStep(): Step.StepOptions {
    return {
      id: 'connect-nav',
      classes: "small-square",
      attachTo: {
        element: ".shepherd-connect-nav",
        on: "bottom"
      },
      advanceOn: { selector: '.shepherd-connect-nav', event: 'click' },
      text: `<div class="mb-3"><h2 class="mb-2 mt-35">Connect</h2><p class="pt-1">Now that you know how to maximise connected profiles, click the 'Connect' tab to expand your network!</p></div>`,
    };
  }

  removeStrengthSpottingSteps() {
    this.shepherdService.tourObject.removeStep('selected-strength');
    this.shepherdService.tourObject.removeStep('selected-strength');
  }

  unbindDestroyAndCompleteTour() {
    if (!this.shepherdService.tourObject) return;
    this.shepherdService.tourObject.steps.forEach(step => {
      step.off('destroy');
    });
    this.shepherdService.complete();
  }


}
