import { createAction, props } from '@ngrx/store';
import { PlatformEventAudit } from 'src/app/shared/models/audit.interface';

export const AddEvent = createAction(
  '[Audit] Add Event',
  props<{ event: PlatformEventAudit }>()
);

export const ClearEvents = createAction(
  '[Audit] Clear Events',
  props<{ events: PlatformEventAudit[] }>()
);

export const SetAuditsSaving = createAction('[Audit] Set Audits Saving');

export namespace SaveEvents {
  export const Request = createAction('[Audit] Save Events Request');

  export const Success = createAction('[Audit] Save Events Success');

  export const Fail = createAction(
    '[Audit] Save Events Fail',
    props<{ error: any }>()
  );
}
