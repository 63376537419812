<div class="bg-dark d-flex flex-column">
  <div class="d-flex text-white py-3 page-container">
    <h2 class="flex-fill ">Individual work ons you might like...</h2>
  </div>
  <div
    #workOnCards
    *ngIf="suggestedWorkOns$ | async as workOns"
    class="growth-carousel d-flex pb-3 mb-3 left-padding"
    (scroll)="checkWorkOnsScrollPosition()"
  >
    <ng-container *ngFor="let workOn of workOns">
      <app-work-on-card-small
        *ngIf="workOn"
        [id]="workOn.id"
        [title]="workOn.title"
        [summary]="workOn.summary"
        [imageUrl]="workOn.portalUpcomingImageUrl"
        [growthType]="GrowthType.WorkOn"
        [workOnType]="workOn.type"
      ></app-work-on-card-small>
    </ng-container>
  </div>
</div>
