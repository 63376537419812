<ng-container *ngIf="account">
  <div class="d-flex row-hover py-2 px-3 position-relative">
    <div class="col">{{ account.accountName }}</div>
    <div class="col">{{ account.accountAdminName }}</div>
    <div class="col">{{ account.dateAdded | date: "dd/MM/yy" }}</div>
    <div class="col">{{ account.renewalDate | date: "dd/MM/yy" }}</div>
    <div class="col text-center">{{ account.usedSeats }}/{{ account.totalSeats }}</div>
    <div class="position-absolute edit-icon" tooltip="Edit Account Admin">
      <app-btn-action [icon]="editIcon" [iconDimensions]="30" [secondary]="true" [primary]="true" [noBorder]="true" (actionClick)="openEditAccountAdminModal()"></app-btn-action>
    </div>
  </div>
    
  <div class="border-bottom-grey"></div>
  
</ng-container>
