<div class="position-absolute banner banner-backdrop"></div>
<div class="position-absolute banner banner-content">
  <div class="row gx-0 justify-content-between">
    <div class="col-8 d-flex flex-column pe-4 text-white">
      <h2 class="mb-2">We value your privacy</h2>
      <h3>
        We use strictly necessary cookies purely for authentication and security
        purposes. For further information, please see our
        <a
          class="text-white cursor-pointer"
          [href]="cookiePolicyUrl"
          target="_blank"
          >cookie notice.</a
        >
      </h3>
    </div>
    <div
      class="cookie-buttons col-4 d-flex flex-column justify-content-between ps-4"
    >
      <button class="btn btn-purple w-100 mb-2" (click)="acceptAll()">
        Understood
      </button>
    </div>
  </div>
</div>
