import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import {
  MindflickAccountConnections,
  SelectValue,
  UserBasicDetails,
  UserManagementUser,
} from 'src/app/shared/models';
import { EngagementResource } from 'src/app/shared/models/engagement-resource.interface';
import { HelpCenterArticle } from 'src/app/shared/models/help-center.interface';
import * as AdminActions from './admin.actions';
import { PlatformInviteStatusEnum } from 'src/app/shared/models/enums/platform-invite-status.enum';
import { CommonActions } from '../common-actions';
import * as SuperAdminActions from '../super-admin/super-admin.actions';

export interface UserManagementUserState
  extends EntityState<UserManagementUser> {}

export const userManagementUserAdapter: EntityAdapter<UserManagementUser> =
  createEntityAdapter<UserManagementUser>({
    sortComparer: sortUserByFullName,
    selectId: selectUserId,
  });

export function selectUserId(a: UserManagementUser): number {
  return a.ak;
}

export function sortUserByFullName(
  a: UserManagementUser,
  b: UserManagementUser
) {
  return `${a.firstName} ${a.lastName}`.localeCompare(
    `${b.firstName} ${b.lastName}`
  );
}

export interface AdminState {
  emailTemplates: SelectValue[];
  sendingEmails: boolean;
  sendingEmailsSuccess: boolean;
  sendingEmailsError?: string;
  userManagementUsers: UserManagementUserState;
  userManagementUsersLoading: boolean;
  userManagementUsersError?: string;
  userManagementPage: number;
  userManagementTake: number;
  userStatusFilter?: PlatformInviteStatusEnum;
  userReferrerFilter?: number;
  userAddedDateStartDate?: Date;
  userAddedDateEndDate?: Date;
  userNameFilter?: string;
  editingEngagementResource?: EngagementResource;
  engagementResourceSaving: boolean;
  engagementResourceSaveResult?: string;
  editingHelpCenterArticle?: HelpCenterArticle;
  helpCenterArticleSaving: boolean;
  helpCenterArticleSaveResult?: string;
  mindflickAccountConnectionsLoading: boolean;
  mindflickAccountConnections?: MindflickAccountConnections;
  selectedUserAk?: number;
}

export const initialAdminState: AdminState = {
  emailTemplates: [],
  sendingEmails: false,
  sendingEmailsSuccess: false,
  userManagementUsers: userManagementUserAdapter.getInitialState(),
  userManagementUsersLoading: false,
  userManagementPage: 1,
  userManagementTake: 15,
  engagementResourceSaving: false,
  helpCenterArticleSaving: false,
  mindflickAccountConnectionsLoading: false,
};

export const adminReducer = createReducer(
  initialAdminState,

  on(CommonActions.ClearState, (state, props) => ({
    ...initialAdminState
  })),
  
  on(AdminActions.GetEmailTemplates.Success, (state, props) => ({
    ...state,
    emailTemplates: props.templates,
  })),

  on(AdminActions.SendPlatformEmails.Request, (state) => ({
    ...state,
    sendingEmails: true,
    sendingEmailsSuccess: false,
    sendingEmailsError: undefined,
  })),

  on(AdminActions.SendPlatformEmails.Success, (state) => ({
    ...state,
    sendingEmails: false,
    sendingEmailsSuccess: true,
    sendingEmailsError: undefined,
  })),

  on(AdminActions.SendPlatformEmails.Fail, (state, props) => ({
    ...state,
    sendingEmails: true,
    sendingEmailsSuccess: false,
    sendingEmailsError: props.error,
  })),

  on(AdminActions.ResetSendPlatformEmailsState, (state) => ({
    ...state,
    sendingEmails: false,
    sendingEmailsSuccess: false,
    sendingEmailsError: undefined,
  })),
  on(AdminActions.GetUserManagementUsers.Request, (state) => ({
    ...state,
    userManagementUsers: userManagementUserAdapter.removeAll(
      state.userManagementUsers
    ),
    userManagementUsersLoading: true,
    userManagementUsersError: undefined,
  })),

  on(AdminActions.GetUserManagementUsers.Success, (state, props) => ({
    ...state,
    userManagementUsers: userManagementUserAdapter.setMany(
      props.users,
      state.userManagementUsers
    ),
    userManagementUsersLoading: false,
    userManagementPage: initialAdminState.userManagementPage,
    userManagementTake: initialAdminState.userManagementTake,
  })),

  on(AdminActions.GetUserManagementUsers.Fail, (state, props) => ({
    ...state,
    userManagementUsersLoading: false,
    userManagementUsersError: props.error,
  })),

  on(AdminActions.ClearUserManagementUsers, (state, props) => ({
    ...state,
    userManagementUsers: userManagementUserAdapter.removeAll(
      state.userManagementUsers
    ),
  })),

  on(AdminActions.SetUserManagementPage, (state, props) => ({
    ...state,
    userManagementPage: props.page,
  })),

  on(AdminActions.SetUserManagmentTake, (state, props) => ({
    ...state,
    userManagementTake: props.take,
  })),

  on(AdminActions.SendSpotlightInvites.Success, (state, props) => ({
    ...state,
    userManagementUsers: userManagementUserAdapter.updateMany(
      props.userAks.map((x) => ({
        id: x,
        changes: {
          platformStatus: PlatformInviteStatusEnum.Invited,
        },
      })),
      state.userManagementUsers
    ),
  })),

  on(AdminActions.SetUserManagementStatusFilter, (state, props) => ({
    ...state,
    userStatusFilter: props.status,
  })),
  on(AdminActions.SetUserManagementReferrerFilter, (state, props) => ({
    ...state,
    userReferrerFilter: props.referrerAk,
  })),
  on(AdminActions.SetUserManagementAddedDateFilter, (state, props) => ({
    ...state,
    userAddedDateStartDate: props.startDate,
    userAddedDateEndDate: props.endDate,
  })),
  on(AdminActions.SetUserNameFilter, (state, props) => ({
    ...state,
    userNameFilter: props.nameFilter,
  })),
  on(AdminActions.SetEditingEngagementResource, (state, props) => ({
    ...state,
    editingEngagementResource: props.engagementResource,
  })),
  on(AdminActions.ClearEditingEngagementResource, (state) => ({
    ...state,
    editingEngagementResource: undefined,
  })),
  on(AdminActions.SaveEngagementResource.Request, (state) => ({
    ...state,
    engagementResourceSaving: true,
    engagementResourceSaveResult: undefined,
  })),
  on(AdminActions.SaveEngagementResource.Success, (state, props) => ({
    ...state,
    editingEngagementResource: props.engagementResource,
    engagementResourceSaving: false,
    engagementResourceSaveResult: 'Save Successful',
  })),
  on(AdminActions.SaveEngagementResource.Fail, (state, props) => ({
    ...state,
    engagementResourceSaving: true,
    engagementResourceSaveResult: props.error,
  })),
  // on(AdminActions.ResetEngagamentResourceSaveResult, (state) => ({
  //   ...state,
  //   engagementResourceSaving: false,
  //   engagementResourceSaveResult: undefined
  // })),
  on(AdminActions.SetEditingHelpCenterArticle, (state, props) => ({
    ...state,
    editingHelpCenterArticle: props.article,
  })),
  on(AdminActions.ClearEditingHelpCenterArticle, (state, props) => ({
    ...state,
    editingHelpCenterArticle: undefined,
  })),
  on(AdminActions.SaveHelpCenterArticle.Request, (state) => ({
    ...state,
    helpCenterArticleSaving: true,
    helpCenterArticleSaveResult: undefined,
  })),
  on(AdminActions.SaveHelpCenterArticle.Success, (state, props) => ({
    ...state,
    editingHelpCenterArticle: props.article,
    helpCenterArticleSaving: false,
    helpCenterArticleSaveResult: 'Save Successful',
  })),
  on(AdminActions.SaveHelpCenterArticle.Fail, (state, props) => ({
    ...state,
    helpCenterArticleSaving: false,
    helpCenterArticleSaveResult: props.error,
  })),
  on(AdminActions.ResetHelpCenterArticleSaveResult, (state) => ({
    ...state,
    helpCenterArticleSaving: false,
    helpCenterArticleSaveResult: undefined,
  })),
  on(AdminActions.GetMindflickAccountConnections.Request, (state) => ({
    ...state,
    mindflickAccountConnectionsLoading: true,
    mindflickAccountConnections: undefined,
  })),
  on(AdminActions.GetMindflickAccountConnections.Success, (state, props) => ({
    ...state,
    mindflickAccountConnectionsLoading: false,
    mindflickAccountConnections: props.connections,
  })),
  on(AdminActions.GetMindflickAccountConnections.Fail, (state, props) => ({
    ...state,
    mindflickAccountConnectionsLoading: false,
    helpCenterArticleSaveResult: props.error,
  })),
  on(AdminActions.AssignUserSpecificWorkOns.Success, (state, props) => ({
    ...state,
    userManagementUsers: userManagementUserAdapter.updateOne(
      {
        id: props.userAk,
        changes: {
          assignedWorkOnIds: props.workOnIds,
        },
      },
      state.userManagementUsers
    ),
  })),
  on(AdminActions.SetSelectedUser, (state, props) => ({
    ...state,
    selectedUserAk: props.userAk,
  })),
  on(AdminActions.ClearSelectedUser, (state) => ({
    ...state,
    selectedUserAk: undefined,
  })),
  on(AdminActions.UpdateUser.Success, (state, props) => ({
    ...state,
    userManagementUsers: userManagementUserAdapter.updateOne(
      {
        id: props.updatedUser.ak,
        changes: {
          firstName: props.updatedUser.firstName,
          lastName: props.updatedUser.lastName,
          email: props.updatedUser.email,
          referredByAk: props.updatedUser.referredByAk,
          referredBy: props.updatedUser.referredByAk
            ? (state.userManagementUsers.entities[
                props.updatedUser.referredByAk
              ] as UserBasicDetails)
            : undefined,
        },
      },
      state.userManagementUsers
    ),
  })),
  on(SuperAdminActions.RemoveUserFromAccount.Success, (state, props) => ({
    ...state,
    userManagementUsers: userManagementUserAdapter.removeOne(props.userAk, state.userManagementUsers)
  })),
  on(SuperAdminActions.AddMindflickCoachToAccount.Success, (state, props) => ({
    ...state,
    userManagementUsers: props.resp.result ? userManagementUserAdapter.addOne(props.resp.result as UserManagementUser, state.userManagementUsers) : state.userManagementUsers
  }))
);
