import { NavigationItem } from '../../models';
import { NavBarType } from '../../models/enums/navbar-types.enum';

export const navStructure: NavigationItem[] = [
  {
    id: 'accounts',
    title: 'Accounts',
    type: NavBarType.Default,
    icon: ['fas', 'users'],
    children: [],
  },
  {
    id: 'individuals',
    title: 'Individuals',
    type: NavBarType.Default,
    icon: ['far', 'user'],
    children: [
      {
        id: 'test-1',
        title: 'Graham Test User',
        type: NavBarType.Default,
        path: '/individuals/profile/5788c234-188a-4a3c-9a9c-c728eb95d721',
        children: [],
      },
      {
        id: 'test-1',
        title: 'Mike Elsom',
        type: NavBarType.Default,
        path: '/individuals/profile/c91a75cb-d053-4024-a76f-535b651e5f04',
        children: [],
      },
    ],
  },
  {
    id: 'connections',
    title: 'Connections',
    type: NavBarType.Default,
    icon: ['far', 'user'],
    path: '/connections',
    children: [],
  },
  {
    id: 'teams',
    title: 'My Teams',
    type: NavBarType.Default,
    icon: ['fas', 'puzzle-piece'],
    children: [],
  },
  {
    id: 'admin',
    title: 'Admin',
    type: NavBarType.Default,
    icon: ['fas', 'toolbox'],
    children: [
      {
        id: 'admin-accounts',
        title: 'Accounts',
        type: NavBarType.Default,
        path: '/admin/accounts',
        children: [],
      },
    ],
  },
  {
    id: 'super-admin',
    title: 'Super Admin',
    type: NavBarType.Default,
    icon: ['fas', 'toolbox'],
    children: [
      {
        id: 'super-admin-accounts',
        title: 'Accounts',
        type: NavBarType.Default,
        path: '/super-admin/mindflick-accounts',
        children: [],
      },
    ],
  },
];
