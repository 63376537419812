import { createAction, props } from '@ngrx/store';
import {
  ConnectionWheelGroupByOptionsEnum,
  MindflickAccountConnections,
  SelectValue,
  UpdateUserModel,
  UserManagementUser,
} from 'src/app/shared/models';
import {
  EngagementResource,
  EngagementResourceCategory,
  EngagementResourceCategoryRequest,
  EngagementResourceSubCategory,
} from 'src/app/shared/models/engagement-resource.interface';
import { PlatformInviteStatusEnum } from 'src/app/shared/models/enums/platform-invite-status.enum';
import { HelpCenterArticle } from 'src/app/shared/models/help-center.interface';

export namespace GetEmailTemplates {
  export const Request = createAction('[Admin] Get Email Templates Request');

  export const Success = createAction(
    '[Admin] Get Email Templates Success',
    props<{ templates: SelectValue[] }>()
  );

  export const Fail = createAction(
    '[Admin] Get Email Templates Fail',
    props<{ error: any }>()
  );
}

export namespace SendPlatformEmails {
  export const Request = createAction(
    '[Admin] Send Platform Emails Request',
    props<{ emailCsv: File; emailTemplate: number }>()
  );

  export const Success = createAction('[Admin] Send Platform Emails Success');

  export const Fail = createAction(
    '[Admin] Send Platform Emails Fail',
    props<{ error: any }>()
  );
}

export const ResetSendPlatformEmailsState = createAction(
  '[Admin] Reset Send Platform Emails State'
);

export namespace GetUserManagementUsers {
  export const Request = createAction(
    '[Admin] Get User Management Users Request',
    props<{ mindflickAccountId: number }>()
  );

  export const Success = createAction(
    '[Admin] Get User Management Users Success',
    props<{ users: UserManagementUser[] }>()
  );

  export const Fail = createAction(
    '[Admin] Get User Management Users Fail',
    props<{ error: any }>()
  );
}

export const ClearUserManagementUsers = createAction(
  '[Admin] Clear User Management Users'
);

export const SetUserManagementPage = createAction(
  '[Admin] Set User Management Page',
  props<{ page: number }>()
);

export const SetUserManagmentTake = createAction(
  '[Admin] Set User Management Take',
  props<{ take: number }>()
);

export namespace SendSpotlightInvites {
  export const Request = createAction(
    '[Admin] Send Spotlight Invites Request',
    props<{ userAks: number[]; sendSignUpEmailImmediately: boolean }>()
  );

  export const Success = createAction(
    '[Admin] Send Spotlight Invites Success',
    props<{ userAks: number[] }>()
  );

  export const Fail = createAction(
    '[Admin] Send Spotlight Invites Fail',
    props<{ error: any }>()
  );
}

export namespace SendNudgesImmediately {
  export const Request = createAction(
    '[Admin] Send Nudges Request',
    props<{ userAks: number[] }>()
  );

  export const Success = createAction(
    '[Admin] Send Nudges Success',
    props<{ userAks: number[] }>()
  );

  export const Fail = createAction(
    '[Admin] Send Nudges Fail',
    props<{ error: any }>()
  );
}

export const SetUserManagementStatusFilter = createAction(
  '[Admin] Set User Management Status Filter',
  props<{ status?: PlatformInviteStatusEnum }>()
);

export const SetUserManagementReferrerFilter = createAction(
  '[Admin] Set User Management Referrer Filter',
  props<{ referrerAk?: number }>()
);

export const SetUserManagementAddedDateFilter = createAction(
  '[Admin] Set User Management Added Date Filter',
  props<{ startDate?: Date; endDate?: Date }>()
);

export const SetUserNameFilter = createAction(
  '[Admin] Set User Name Filter',
  props<{ nameFilter?: string }>()
);

export namespace ResendSpotlightInvites {
  export const Request = createAction(
    '[Admin] Resend Spotlight Invites Request',
    props<{ userAks: number[] }>()
  );

  export const Success = createAction(
    '[Admin] Resend Spotlight Invites Success'
  );

  export const Fail = createAction(
    '[Admin] Resend Spotlight Invites Fail',
    props<{ error: any }>()
  );
}

export namespace UpsertEngagementResourceCategory {
  export const Request = createAction(
    '[Admin] Upsert Engagement Resource Category Request',
    props<{ category: EngagementResourceCategoryRequest }>()
  );

  export const Success = createAction(
    '[Admin] Upsert Engagement Resource Category Success',
    props<{ category: EngagementResourceCategory }>()
  );

  export const Fail = createAction(
    '[Admin] Upsert Engagement Resource Category Fail',
    props<{ error: any }>()
  );
}

export namespace UpsertEngagementResouceSubCategory {
  export const Request = createAction(
    '[Admin] Upsert Engagement Resource Sub-Category Request',
    props<{ subCategory: EngagementResourceCategoryRequest }>()
  );

  export const Success = createAction(
    '[Admin] Upsert Engagement Resource Sub-Category Success',
    props<{ subCategory: EngagementResourceSubCategory }>()
  );

  export const Fail = createAction(
    '[Admin] Upsert Engagement Resource Sub-Category Fail',
    props<{ error: any }>()
  );
}

export const SetEditingEngagementResource = createAction(
  '[Admin] Set Editing Engagement Resource',
  props<{ engagementResource: EngagementResource }>()
);

export const ClearEditingEngagementResource = createAction(
  '[Admin] Clear Editing Engagement Resource'
);

export namespace SaveEngagementResource {
  export const Request = createAction(
    '[Admin] Save Engagement Resource Request',
    props<{ engagementResource: EngagementResource }>()
  );

  export const Success = createAction(
    '[Admin] Save Engagement Resource Success',
    props<{ engagementResource: EngagementResource }>()
  );

  export const Fail = createAction(
    '[Admin] Save Engagement Resource Fail',
    props<{ error: any }>()
  );
}

export const SetEditingHelpCenterArticle = createAction(
  '[Admin] Set Editing Help Center Article',
  props<{ article: HelpCenterArticle }>()
);

export const ClearEditingHelpCenterArticle = createAction(
  '[Admin] Clear Editing Help Center Article'
);

export namespace SaveHelpCenterArticle {
  export const Request = createAction(
    '[Admin] Save Help Center Article Request',
    props<{ article: HelpCenterArticle }>()
  );

  export const Success = createAction(
    '[Admin] Save Help Center Article Success',
    props<{ article: HelpCenterArticle }>()
  );

  export const Fail = createAction(
    '[Admin] Save Help Center Article Fail',
    props<{ error: any }>()
  );
}

export const ResetHelpCenterArticleSaveResult = createAction(
  '[Admin] Reset Help Center Article Save Result'
);

export namespace GetMindflickAccountConnections {
  export const Request = createAction(
    '[Admin] Get Account Connections Request',
    props<{
      mindflickAccountId: number;
      groupBy: ConnectionWheelGroupByOptionsEnum;
    }>()
  );

  export const Success = createAction(
    '[Admin] Get Account Connections Success',
    props<{ connections: MindflickAccountConnections }>()
  );

  export const Fail = createAction(
    '[Admin] Get Account Connections Fail',
    props<{ error: any }>()
  );
}

export namespace AssignUserSpecificWorkOns {
  export const Request = createAction(
    '[Admin] Assign User Specific Work Ons Request',
    props<{ userAk: number; workOnIds: number[] }>()
  );

  export const Success = createAction(
    '[Admin] Assign User Specific Work Ons Success',
    props<{ userAk: number; workOnIds: number[] }>()
  );

  export const Fail = createAction(
    '[Admin] Assign User Specific Work Ons Fail',
    props<{ error: any }>()
  );
}

export const SetSelectedUser = createAction(
  '[Admin] Set Selected User',
  props<{ userAk: number }>()
);

export const ClearSelectedUser = createAction('[Admin] Clear Selected User');

export namespace UpdateUser {
  export const Request = createAction(
    '[Admin] Update User Request',
    props<{ updatedUser: UpdateUserModel, modalRef?: string | number }>()
  );

  export const Success = createAction(
    '[Admin] Update User Success',
    props<{ updatedUser: UpdateUserModel }>()
  );

  export const Fail = createAction(
    '[Admin] Update User Fail',
    props<{ error: any }>()
  );
}
