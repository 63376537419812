import { createSelector } from '@ngrx/store';
import { AppState, superAdminStateKey } from '../app.state';
import { SuperAdminState } from './super-admin.reducer';
import { MindflickOrganisation } from 'src/app/shared/models';

export const selectSuperAdminState = (state: AppState) => state[superAdminStateKey];

export const selectMindflickPractitioners = createSelector(
  selectSuperAdminState,
  (state: SuperAdminState) => state.mindflickPractitioners
)

export const selectCoachingClientInfo = createSelector(
  selectSuperAdminState,
  (state: SuperAdminState) => state.clientInfo
)

export const selectCoachingFeedback = createSelector(
  selectSuperAdminState,
  (state: SuperAdminState) => state.coachingFeedback
)

export const selectGroupedCoachingFeedback = createSelector(
  selectSuperAdminState,
  (state: SuperAdminState) => state.groupedFeedback
)

export const selectQuestionnaires = createSelector(
  selectSuperAdminState,
  (state: SuperAdminState) => state.questionnaires
);
export const selectQuestionnaireResponseManagementDetails = createSelector(
  selectSuperAdminState,
  (state: SuperAdminState) => state.questionnaireResponseManagementDetails
);

export const selectUserSpotlightResponses = createSelector(
  selectSuperAdminState,
  (state: SuperAdminState) => state.userSpotlightResponses
);

export const selectIsAddingUser = createSelector(
  selectSuperAdminState,
  (state: SuperAdminState) => state.isAddingUser
);

export const selectIsAddingMindflickCoach = createSelector(
  selectSuperAdminState,
  (state: SuperAdminState) => state.isAddingMindflickCoach
);

export const selectIsAddingMindflickCoachSuccess = createSelector(
  selectSuperAdminState,
  (state: SuperAdminState) => state.isAddingMindflickCoachSuccess
);

export const selectIsAddingMindflickCoachError = createSelector(
  selectSuperAdminState,
  (state: SuperAdminState) => state.isAddingMindflickCoachError
);

export const selectMindflickOrganisations = createSelector(
  selectSuperAdminState,
  (state: SuperAdminState) => state.mindflickOrganisations
);

export const selectMindflickOrganisationsLoading = createSelector(
  selectSuperAdminState,
  (state: SuperAdminState) => state.mindflickOrganisationLoading
);

export const selectSelectedMindflickOrganisationId = createSelector(
  selectSuperAdminState,
  (state: SuperAdminState) => state.selectedOrganisationId
);

export const selectSelectedMindflickOrganisation = createSelector(
  selectMindflickOrganisations,
  selectSelectedMindflickOrganisationId,
  (orgs: MindflickOrganisation[], orgId?: number) => {
    return orgs.find(x => x.id === orgId);
  }
);

export const selectMindflickOrganisationUsers = createSelector(
  selectSuperAdminState,
  (state: SuperAdminState) => state.mindflickOrganisationUsers
);