<div *ngIf="settings" class="page-container row gx-5">
  <div class="col-md-4">
    <h2 class="pb-4">Organisation Settings</h2>
    <div>
      <label class="form-label mb-2">Session duration</label>
      <app-dropdown-select
        class="mb-2"
        [options]="sessionDurationOptions"
        [ngModel]="settings.sessionDurationMins"
        (selectionChanged)="sessionDurationChanged($event)"
      ></app-dropdown-select>
    </div>

    <div class="mb-5">
      <label class="form-label mb-2">Data retention duration</label>
      <app-dropdown-select
        [options]="dataRetentionOptions"
        [ngModel]="settings.dataRetentionDurationDays"
        (selectionChanged)="dataRetentionDurationChanged($event)"
      ></app-dropdown-select>
    </div>

    <div class="toggle-container p-3 mb-3">
      <app-toggle-box
        [description]="ssoTitle"
        [isOn]="settings.requiresExternalAuthentication"
        (toggleChanged)="singleSignOnChanged($event)"
      ></app-toggle-box>
      <div class="body-copy col-md-8 pt-1">
        {{ ssoLabel }}
      </div>
    </div>

    <div class="toggle-container p-3">
      <app-toggle-box
        [description]="mfaTitle"
        [isOn]="settings.mfaRequired"
        (toggleChanged)="mfaRequiredChanged($event)"
      ></app-toggle-box>
      <div class="body-copy col-md-8 pt-1">
        {{ mfaLabel }}
      </div>
      <div class="col-md-6"></div>
    </div>
  </div>
  <div class="col-md-8 mb-5">
    <app-organisation-accounts-summary-table></app-organisation-accounts-summary-table>
  </div>
</div>
