<div class="large-popout-container">
  <div class="large-popout-shadow"></div>
  <div class="large-popout-content d-flex flex-column">
    <div class="d-flex pt-5 pb-4 align-items-center popout-close">
      <app-btn-action class="legal-close fa-lg top"
                      [icon]="close"
                      [iconDimensions]="35"
                      (click)="closePopout()"></app-btn-action>
    </div>
    <div class="flex-fill d-flex flex-column justify-content-between pt-5 pb-4">
      <div *ngIf="mindflickOrganisation$ | async as organisation" class="d-flex flex-column">
        <h3 class="mt-2">{{ organisation.name }}</h3>
        <div class="row gx-0 mt-5 pb-4 border-bottom-grey">
          <div class="col-6 d-flex pe-5">
            <div class="flex-fill">
              <h2>Organisation Admin</h2>
              <h3 class="mt-2">{{ organisation.adminName }}</h3>
            </div>
            <app-btn-tertiary [icon]="editIcon" (click)="openEditOrganisationAdminModal()">Edit</app-btn-tertiary>
          </div>
          <div class="col-6 d-flex ps-5">
            <div class="flex-fill">
              <h2>Mindflick Lead</h2>
              <h3 class="mt-2">{{ organisation.organisationLeadName }}</h3>
            </div>
            <app-btn-tertiary [icon]="editIcon" (click)="openEditOrganisationLeadModal()">Edit</app-btn-tertiary>
          </div>
        </div>

        <div *ngIf="!loading" class="mt-4 d-flex flex-column" [class.pb-4]="selectedAccount" [class.border-bottom-dark-grey]="selectedAccount">
          <div class="d-flex justify-content-between">
            <h2>Organisation Accounts</h2>
            <app-btn-tertiary [icon]="plus" (click)="openAddAccountModal()">
              Add Account
            </app-btn-tertiary>
          </div>
          <ngx-datatable class="bootstrap mt-4"
                         [rows]="organisation.mindflickAccounts"
                         [loadingIndicator]="loading"
                         [columnMode]="ColumnMode.force"
                         [headerHeight]="40"
                         [summaryRow]="false"
                         [footerHeight]="40"
                         [limit]="6"
                         rowHeight="auto">
            <ngx-datatable-column name="Account" prop="name" [sortable]="true" />
            <ngx-datatable-column name="Admin" prop="adminName" [sortable]="true" />
            <ngx-datatable-column name="Expiry date" [sortable]="true">
              <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
                {{ row.renewalDate | date : "dd/MM/yyyy"}}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Used Seats" [sortable]="false">
              <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
                {{ row.usedSeats }} / {{ row.seats }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="" [sortable]="false">
              <ng-template ngx-datatable-cell-template let-row="row">
                <div class="d-flex justify-content-center">

                  <button class="btn btn-secondary btn-action me-2" (click)="accountSelected(row.id)">
                    <fa-icon [icon]="editIcon"></fa-icon>
                  </button>
                  <button class="btn btn-secondary btn-action" (click)="editSeats(row.id)">
                    <fa-icon [icon]="['fas', 'chair']"></fa-icon>
                  </button>
                </div>

              </ng-template>
            </ngx-datatable-column>
          </ngx-datatable>

        </div>
      </div>
    </div>
  </div>
</div>
