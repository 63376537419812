import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

//interface to declare the canDeactivate method
export interface CanComponentDeactivate {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

//service that will act as the CanDeactivate guard, defining the canDeactivate method.
@Injectable()
export class CanDeactivateGuard
  
{
  canDeactivate(
    component: CanComponentDeactivate,
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    let url: string = state.url;
    console.log('URL: ' + url);

    return component.canDeactivate ? component.canDeactivate() : true;
  }
}

//for any component which has defined canDeactivate method, the method will be called by the component instance otherwise true is returned by the guard.
//also able to access current route and the current state
