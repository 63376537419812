import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { faCloseSVG } from 'src/icons';
import { LiveAnalyticsNudgeDTO } from '../../models/live-analytics/top-live-analytics-nudge-dto.interface';

@Component({
  selector: 'app-nudge-details-modal',
  templateUrl: './nudge-details-modal.component.html',
  styleUrls: ['./nudge-details-modal.component.scss'],
})
export class NudgeDetailsModalComponent {
  nudge?: LiveAnalyticsNudgeDTO;

  closeIcon = faCloseSVG;

  constructor(private modalRef: BsModalRef) {}

  close() {
    this.modalRef.hide();
  }
}
