import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  SaveAnswersRequest,
  SpotlightResponse,
  SurveyCreateRequest,
  SurveyCreateResponse,
} from '../models';
import { QuestionnaireResponseDTO, SaveQuestionnaireAnswersDTO } from '../models/questionnaire-option.interface';

@Injectable({
  providedIn: 'root',
})
export class SurveyService {
  surveyUrl = '';
  apiUrl = "";
  profileUrl = "";
  constructor(private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string,
  ) {
    this.surveyUrl = `${environment.surveyApi}survey`;
  }

  getInvite(
    spotlightResponseId: number,
    uniqueRef: string,
    langId:string = 'en-GB'
  ): Observable<SpotlightResponse> {
    return this.http.get<SpotlightResponse>(
      `${this.surveyUrl}/invite/${spotlightResponseId}/${uniqueRef}/${langId}`
    );
  }

  saveProgress(body: SaveAnswersRequest) {
    return this.http.post(`${this.surveyUrl}/save`, body);
  }

  getSpotlightId(id: string) {
    return this.http.get<SpotlightResponse>(
      `${this.surveyUrl}/spotlightid/${id}/en-GB`
    );
  }

  updateSurvey(
    uniqueRef: string,
    spotlightResponseId: number,
    request: SurveyCreateRequest
  ) {
    return this.http.post<SurveyCreateResponse>(
      `${this.surveyUrl}/update/${spotlightResponseId}/${uniqueRef}`,
      request
    );
  }

  getQuestionnaires(uniqueRef: string, spotlightResponseId: number) {
    return this.http.get<QuestionnaireResponseDTO[]>(`${this.surveyUrl}/linked-questionnaires/${spotlightResponseId}/${uniqueRef}`);
  }

  getQuestionnaireResponseDetails(uniqueRef: string, questionnaireResponseId: number) {
    return this.http.get<QuestionnaireResponseDTO>(`${environment.surveyApi}questionnaire/details/${uniqueRef}/${questionnaireResponseId}`);
  }

  saveQuestionnaireAnswers(questionAnswers: SaveQuestionnaireAnswersDTO) {
    return this.http.post(`${environment.surveyApi}questionnaire/save`, questionAnswers);
  }
}
