<div class="large-popout-container">
  <div class="large-popout-shadow"></div>
  <div class="large-popout-content d-flex flex-column">
    <div class="pt-5 pe-5 border-bottom-grey">
      <app-champion-charter-content></app-champion-charter-content>
    </div>

    <div
      class="d-flex justify-content-between align-items-start mt-4 pb-5 pe-5"
    >
      <div>
        <h3>Accept to continue to site</h3>
        <div class="mt-3">
          <input
            type="checkbox" id="cbAgree"
            class="form-check-input me-2"
            [(ngModel)]="agreementConfirmed"
            [ngModelOptions]="{ standalone: true }"
          />
          <label class="small-text" for="cbAgree" id="lblAgree"
            >I agree to these commitments as a Mindflick Champion</label
          >
        </div>
      </div>
      <button
        class="btn btn-purple" id="btnConfirm"
        [disabled]="!agreementConfirmed"
        (click)="acceptChampionCharter()"
      >
        CONTINUE
      </button>
    </div>
  </div>
</div>
