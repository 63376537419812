<div class="download-container">
  <div class="download-page pb-3 bg-white">
    <div class="d-flex pt-5 pb-4 align-items-center header">
      <app-btn-action class="download-close" [icon]="close" [iconDimensions]="35" (click)="closeDownloads()"></app-btn-action>
      <div class="d-flex flex-column">
        <h2>Downloads</h2>
      </div>
    </div>
    <div class="download-content-container">
      <ng-container *ngFor="let completed of downloadService.completed">
        <div class="d-flex flex-column download-item mb-3">
          <div class="d-flex justify-content-between">
            <h3 class="mb-2">{{ completed.downloadName }}</h3>
            <fa-icon class="cursor-pointer" [icon]="close" (click)="removeComplete(completed)"></fa-icon>
          </div>
          <div class="body-copy">Complete</div>
        </div>
      </ng-container>
      <ng-container *ngFor="let download of downloadService.downloadQueue">
        <ng-container *ngIf="download.download$ | async as dl">
          <div class="d-flex flex-column download-item mb-3">
            <h3 class="mb-2">{{ download.downloadName }}</h3>
            <progressbar [animate]="true" [value]="dl.progress"></progressbar>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
  <div class="shadow-container"></div>
</div>
