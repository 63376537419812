import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { AppState } from 'src/app/+state/app.state';
import {
  SetSelectedGrowth,
  SetSelectedHeading,
} from 'src/app/+state/growth/growth.actions';
import { GrowthType } from 'src/app/+state/growth/growth.reducer';
import {
  selectAllTagEntities,
  selectSuggestedWorkOns,
} from 'src/app/+state/growth/growth.selector';
import { SetSubMenuLargePopout } from 'src/app/+state/layout/actions/layout.actions';
import { SubMenuLargePopoutEnum } from 'src/app/+state/layout/reducers/layout.reducers';
import { faNextSVG, faBackSVG } from 'src/icons';
import { WorkOn, WorkOnType } from '../../models';

@Component({
  selector: 'app-suggested-work-ons',
  templateUrl: './suggested-work-ons.component.html',
  styleUrls: ['./suggested-work-ons.component.scss'],
})
export class SuggestedWorkOnsComponent implements OnInit {
  @ViewChild('workOnCards')
  workOnCards?: ElementRef<any>;

  suggestedWorkOns$ = this.store.select(selectSuggestedWorkOns);

  disableWorkOnsLeft = true;
  disableWorkOnsRight = false;

  next = faNextSVG;
  back = faBackSVG;

  GrowthType = GrowthType;

  constructor(private store: Store<AppState>, private router: Router) {}

  ngOnInit(): void {}

  viewGrowth() {
    this.router.navigate(['/growth/work-ons']);
  }

  scrollWorkOnsLeft() {
    let newLeft =
      this.workOnCards?.nativeElement.scrollLeft -
      this.workOnCards?.nativeElement.clientWidth * 0.25;

    this.workOnCards?.nativeElement.scrollTo({
      left: newLeft,
      behavior: 'smooth',
    });

    this.checkWorkOnsControlState(
      newLeft,
      this.workOnCards?.nativeElement.scrollWidth,
      this.workOnCards?.nativeElement.clientWidth
    );
  }

  scrollWorkOnsRight() {
    let newLeft =
      this.workOnCards?.nativeElement.scrollLeft +
      this.workOnCards?.nativeElement.clientWidth * 0.25;

    this.workOnCards?.nativeElement.scrollTo({
      left: newLeft,
      behavior: 'smooth',
    });

    this.checkWorkOnsControlState(
      newLeft,
      this.workOnCards?.nativeElement.scrollWidth,
      this.workOnCards?.nativeElement.clientWidth
    );
  }

  checkWorkOnsControlState(
    newLeft: number,
    scrollWidth: number,
    clientWidth: number
  ) {
    if (newLeft <= 0) {
      this.disableWorkOnsLeft = true;
    } else {
      this.disableWorkOnsLeft = false;
    }

    if (newLeft + clientWidth >= scrollWidth - 1) {
      this.disableWorkOnsRight = true;
    } else {
      this.disableWorkOnsRight = false;
    }
  }

  checkWorkOnsScrollPosition() {
    let completedScrollPosition = this.workOnCards?.nativeElement.scrollLeft;

    this.checkWorkOnsControlState(
      completedScrollPosition,
      this.workOnCards?.nativeElement.scrollWidth,
      this.workOnCards?.nativeElement.clientWidth
    );
  }
}
