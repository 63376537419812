import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

export enum DigitalDebriefMessages {
    RevealMySpotlight,
    SpotlightReadoutCompleted,
    StrengthsSelected,
    StrengthsDeselected,
    ShowStrengthCurve,
    ShowTakeCareCurve,
    TakeCareReadoutComplete,
    ShowWriggleRoomSelection,
    WriggleRoomSelected,
    WriggleRoomDeselected,
    ShowWriggleRoomCurve,
    TriggerScrollToBottom,
}

export enum SystemMessages {
    ProfileProcessed
}

@Injectable({
  providedIn: 'root',
})
export class MessagingService {
    debriefMessages = new Subject<DigitalDebriefMessages>();
    systemMessages = new Subject<SystemMessages>();

    ngOnDestroy() {
        this.debriefMessages.unsubscribe();
        this.systemMessages.unsubscribe();
    }

    sendDigitalDebriefMessage(message: DigitalDebriefMessages) {
        this.debriefMessages.next(message);
    }

    sendSystemMessage(message: SystemMessages) {
        this.systemMessages.next(message);
    }
}