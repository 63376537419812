import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { NumericalSort } from 'src/app/shared/helpers/sorting-helpers';
import {
  GrowthSortBy,
  GrowthTag,
  WorkOn,
  WorkOnHistory,
  WorkOnType,
} from 'src/app/shared/models';
import { EngagementResource } from 'src/app/shared/models/engagement-resource.interface';
import {
  Playbook,
} from 'src/app/shared/models/playbooks.interface';
import { DashboardNudgeDTO } from '../../shared/models/nudge-dto.interface';
import { WorkOnTheme } from '../../shared/models/work-on-theme.interface';
import { engagementResourceAdapter } from '../account-admin/account-admin.reducer';
import * as GrowthActions from './growth.actions';
import { CommonActions } from '../common-actions';

// This reducer contains various entities so we create an entity state for each type
export interface GrowthTagState extends EntityState<GrowthTag> {}
export interface PlaybookState extends EntityState<Playbook> {}
export interface WorkOnState extends EntityState<WorkOn> {}
export interface WorkOnHistoryState extends EntityState<WorkOnHistory> {}
export interface EngagementResourceState
  extends EntityState<EngagementResource> {}

// For each entity type create an adapter
export const growthTagAdapter: EntityAdapter<GrowthTag> =
  createEntityAdapter<GrowthTag>({
    sortComparer: sortGrowthTagByOrderByAndThenName,
  });
export function sortGrowthTagByOrderByAndThenName(
  a: GrowthTag,
  b: GrowthTag
): number {
  //Put tags without an order by last
  if (a.orderById == 0 && b.orderById != 0) return 100;
  if (a.orderById != 0 && b.orderById == 0) return -100;
  //If they have the same order by then sort by name
  if (a.orderById == b.orderById) return a.tag.localeCompare(b.tag);
  //Otherwise just sort by order by
  return a.orderById - b.orderById;
}

export const playbookAdapter: EntityAdapter<Playbook> =
  createEntityAdapter<Playbook>({
    sortComparer: sortPlaybookByName,
  });
export function sortPlaybookByName(a: Playbook, b: Playbook): number {
  return a.title.localeCompare(b.title);
}

export const workOnAdapter: EntityAdapter<WorkOn> = createEntityAdapter<WorkOn>(
  {
    sortComparer: sortWorkOnByName,
  }
);
export function sortWorkOnByName(a: WorkOn, b: WorkOn): number {
  return a.title.localeCompare(b.title);
}

export const workOnHistoryAdapter: EntityAdapter<WorkOnHistory> =
  createEntityAdapter<WorkOnHistory>({
    sortComparer: sortWorkOnHistoryByDate,
  });
export function sortWorkOnHistoryByDate(
  a: WorkOnHistory,
  b: WorkOnHistory
): number {
  if (a.completedDate != undefined && b.completedDate != undefined)
    return a.completedDate.getTime() - b.completedDate.getTime();
  return a.orderById - b.orderById;
}

export interface GrowthState {
  // These are effectively tables containing each entity but are stored as dictionaries using the ID of the entity
  // which makes for much faster retrieval
  workOns: WorkOnState;
  playbooks: PlaybookState;
  engagementResources: EngagementResourceState;

  workOnHistory: WorkOnHistoryState;

  tags: GrowthTagState;
  filteredTagIds: number[];

  allWorkOnsLoading: boolean;

  savingPlaybook: boolean;
  savingPlaybookExercise: boolean;
  editingPlaybookExerciseId?: number;
  editingPlaybookId?: number;

  selectedWorkOnId?: number;
  growthListShowing: boolean;
  savingWorkOn: boolean;
  editingWorkOnId?: number;
  saveWorkOnResult?: string;
  saveWorkOnError: boolean;

  selectedPlaybookId?: number;
  selectedPlaybookExerciseId?: number;
  selectedGrowthType?: GrowthType;
  selectedHeading: string;
  selectedWorkOn?: WorkOn;
  suggestedWorkOnIds: number[];

  filterSortBy?: GrowthSortBy;
  filterWorkOnType?: WorkOnType;

  selectedEngagementResourceId?: number;
  currentWorkOnSearchTerm: string;

  dashboardNudge?: DashboardNudgeDTO;
  organisationNudges?: DashboardNudgeDTO[];
  usefulNudges?: DashboardNudgeDTO[];

  workOnThemes?: WorkOnTheme[];
  previousNudge?: DashboardNudgeDTO;
}

export const initialGrowthState: GrowthState = {
  workOns: workOnAdapter.getInitialState(),
  workOnHistory: workOnHistoryAdapter.getInitialState(),
  playbooks: playbookAdapter.getInitialState(),
  engagementResources: engagementResourceAdapter.getInitialState(),

  tags: growthTagAdapter.getInitialState(),
  filteredTagIds: [],

  allWorkOnsLoading: false,
  saveWorkOnError: false,
  savingWorkOn: false,
  savingPlaybook: false,
  savingPlaybookExercise: false,

  growthListShowing: false,

  selectedHeading: '',
  suggestedWorkOnIds: [],
  currentWorkOnSearchTerm: '',
};

export enum GrowthType {
  WorkOn,
  Playbook,
  EngagementResource,
}

export const growthReducer = createReducer(
  initialGrowthState,

  on(CommonActions.ClearState, (state, props) => ({
    ...initialGrowthState
  })),

  on(GrowthActions.GetWorkOns.Request, (state) => ({
    ...state,
    allWorkOnsLoading: true,
  })),

  on(GrowthActions.GetWorkOns.Success, (state, props) => ({
    ...state,
    workOns: workOnAdapter.upsertMany(props.workOns, state.workOns),
    workOnHistory: workOnHistoryAdapter.upsertMany(
      props.workOnHistory,
      state.workOnHistory
    ),
    tags: growthTagAdapter.upsertMany(props.tags, state.tags),
    playbooks: playbookAdapter.upsertMany(props.playbooks, state.playbooks),
    allWorkOnsLoading: false,
    suggestedWorkOnIds: props.suggestedWorkOnIds,
  })),

  on(GrowthActions.GetWorkOns.Fail, (state) => ({
    ...state,
    allWorkOns: [],
    allWorkOnsLoading: false,
  })),

  on(GrowthActions.SetSelectedWorkOn, (state, props) => ({
    ...state,
    selectedWorkOnId: props.workOnId,
  })),

  on(GrowthActions.ToggleGrowthListShowingOn, (state) => ({
    ...state,
    growthListShowing: true,
  })),
  on(GrowthActions.ToggleGrowthListShowingOff, (state) => ({
    ...state,
    growthListShowing: false,
  })),
  on(GrowthActions.SaveWorkOn.Success, (state, props) => ({
    ...state,
    growthListShowing: false,
  })),

  //workOnHistory - returns object containing currentWorkOn, upcoming and complete work ons
  on(GrowthActions.GetMyWorkOnHistory.Request, (state) => ({
    ...state,
  })),
  on(GrowthActions.GetMyWorkOnHistory.Success, (state, props) => ({
    ...state,
    upcomingWorkOnHistory: props.MyWorkOns.upcomingWorkOns,
    currentWorkOnHistory: props.MyWorkOns.currentWorkOn?.id,
    completedWorkOnHistory: props.MyWorkOns.completedWorkOns,
  })),
  on(GrowthActions.SaveWorkOn.Edit, (state, props) => ({
    ...state,
    editingWorkOnId: props.workOnId,
  })),
  on(GrowthActions.SaveWorkOn.Request, (state, props) => ({
    ...state,
    saveWorkOnResult: 'Saving...',
    saveWorkOnError: false,
    savingWorkOn: true,
  })),
  on(GrowthActions.SaveWorkOn.Success, (state, props) => {
    // Use the adapter to perform the update to the work on state
    let workOnState = workOnAdapter.setOne(props.newWorkOn, state.workOns);

    // Then push that state into the overall state object
    return {
      ...state,
      workOns: workOnState,
      saveWorkOnResult: 'Save Successful',
      saveWorkOnError: false,
      savingWorkOn: false,
      editingWorkOnId: props.newWorkOn.id,
    };
  }),

  on(GrowthActions.SaveWorkOn.Fail, (state, props) => ({
    ...state,
    saveWorkOnResult: 'Error: ' + props.error,
    saveWorkOnError: true,
    savingWorkOn: false,
  })),

  on(GrowthActions.ClearSaveWorkOnResult, (state) => ({
    ...state,
    saveWorkOnResult: undefined,
    saveWorkOnError: false,
    savingWorkOn: false,
  })),

  on(GrowthActions.SavePlaybook.Request, (state, props) => ({
    ...state,
    saveWorkOnResult: 'Saving...',
    saveWorkOnError: false,
    savingPlaybook: true,
  })),

  on(GrowthActions.SavePlaybook.Success, (state, props) => ({
    ...state,
    playbooks: playbookAdapter.upsertOne(props.savedPlaybook, state.playbooks),
    saveWorkOnResult: 'Save Successful',
    saveWorkOnError: false,
    savingPlaybook: false,
  })),

  on(GrowthActions.SavePlaybook.Fail, (state, props) => ({
    ...state,
    saveWorkOnResult: 'Error: ' + props.error,
    saveWorkOnError: true,
    savingPlaybook: false,
  })),
  on(GrowthActions.SavePlaybookExercise.Edit, (state, props) => ({
    ...state,
    editingPlaybookExerciseId: props.exerciseId,
    editingPlaybookId: props.playbookId,
  })),
  on(GrowthActions.SavePlaybookExercise.Request, (state, props) => ({
    ...state,
    savingPlaybookExercise: true,
    saveWorkOnResult: 'Saving...',
    saveWorkOnError: false,
  })),
  on(GrowthActions.SavePlaybookExercise.Success, (state, props) => ({
    ...state,
    allPlaybooks: state.playbooks.ids.map((p) => {
      // Modify just the playbook we just saved
      if (p === props.savedExercise.playbookId) {
        return {
          ...state.playbooks.entities[p],
          exercises: [
            ...state.playbooks.entities[p]!.exercises.filter(
              (e) => e.id !== props.savedExercise.id
            ),
            props.savedExercise,
          ].sort((a, b) => NumericalSort(a.orderById, b.orderById)),
        };
      }
      //Also return all the other unmodified entities
      return state.playbooks.entities[p];
    }),
    saveWorkOnResult: 'Save Successful',
    saveWorkOnError: false,
    editingPlaybookExerciseId: props.savedExercise.id,
    savingPlaybookExercise: false,
  })),

  on(GrowthActions.SavePlaybookExercise.Fail, (state, props) => ({
    ...state,
    saveWorkOnResult: 'Error: ' + props.error,
    saveWorkOnError: true,
    savingPlaybookExercise: false,
  })),
  on(GrowthActions.SetSelectedHeading, (state, props) => ({
    ...state,
    selectedHeading: props.heading,
  })),
  on(GrowthActions.SetSelectedGrowth, (state, props) => ({
    ...state,
    selectedGrowthType: props.growthType,
    selectedWorkOnId:
      props.growthType == GrowthType.WorkOn ? props.growthId : undefined,
    selectedPlaybookId:
      props.growthType == GrowthType.Playbook ? props.playbookId : undefined,
    selectedPlaybookExerciseId:
      props.growthType == GrowthType.Playbook ? props.growthId : undefined,
    selectedEngagementResourceId:
      props.growthType == GrowthType.EngagementResource
        ? props.engagementResourceId
        : undefined,
  })),

  on(GrowthActions.AddWorkOnToHistory.Success, (state, props) => {
    let workOnHistoryState = workOnHistoryAdapter.addOne(
      props.workOnHistoryEntry,
      state.workOnHistory
    );

    // Upcoming work ons are ones without a start or end date
    let upcomingWorkOnHistory = workOnHistoryState.ids
      .map((x) => workOnHistoryState.entities[x]!)
      .filter((x) => !x.startedDate && !x.completedDate)
      // sorted by the order by column
      .sort((a, b) => NumericalSort(a.orderById, b.orderById));

    return {
      ...state,
      workOnHistory: workOnHistoryState,
      upcomingWorkOnHistoryIds: upcomingWorkOnHistory.map((x) => x.id),
    };
  }),

  on(GrowthActions.FilterTags.Set, (state, props) => ({
    ...state,
    filteredTagIds: state.filteredTagIds.includes(props.tagId)
      ? state.filteredTagIds.filter((existingTag) => existingTag != props.tagId)
      : [...state.filteredTagIds, props.tagId],
  })),

  on(GrowthActions.FilterTags.SetMultiple, (state, props) => ({
    ...state,
    filteredTagIds: props.tagIds,
  })),
  on(GrowthActions.FilterTags.Clear, (state) => ({
    ...state,
    filteredTags: [],
  })),

  on(GrowthActions.UpdateUpcomingWorkOnsOrder.Request, (state, props) => ({
    ...state,
  })),
  on(GrowthActions.UpdateUpcomingWorkOnsOrder.Success, (state, props) => ({
    ...state,
    workOnHistory: workOnHistoryAdapter.upsertMany(
      props.upcomingWorkOns,
      state.workOnHistory
    ),
  })),
  on(GrowthActions.UpdateUpcomingWorkOnsOrder.Fail, (state, props) => ({
    ...state,
    updateUpcomingWorkOnsOrder: 'error: ' + props.error,
  })),
  on(GrowthActions.FilterTags.ClearIds, (state) => ({
    ...state,
    filteredTagIds: [],
  })),

  on(GrowthActions.FilterSortBy.Set, (state, props) => ({
    ...state,
    filterSortBy: props.sortBy,
  })),
  on(GrowthActions.FilterSortBy.Clear, (state) => ({
    ...state,
    filterSortBy: undefined,
  })),

  on(GrowthActions.FilterWorkOnType.Set, (state, props) => ({
    ...state,
    filterWorkOnType: props.workOnType,
  })),
  on(GrowthActions.FilterWorkOnType.Clear, (state) => ({
    ...state,
    filterWorkOnType: undefined,
  })),

  on(GrowthActions.ClearAllFilters, (state) => ({
    ...state,
    filteredTagIds: [],
    filteredTags: [],
    filterSortBy: GrowthSortBy.None,
    filterWorkOnType: WorkOnType.None,
  })),
  on(GrowthActions.SetCurrentWorkOnSearchTerm, (state, props) => ({
    ...state,
    currentWorkOnSearchTerm: props.currentSearchTerm,
  })),
  on(GrowthActions.DeleteUpcomingWorkOn.Success, (state, props) => ({
    ...state,
    workOnHistory: workOnHistoryAdapter.removeOne(
      props.historyId,
      state.workOnHistory
    ),
  })),

  on(GrowthActions.GetCurrentNudge.Success, (state, props) => ({
    ...state,
    dashboardNudge: props.nudge
  })),

  on(GrowthActions.GetTopOrganisationNudges.Success, (state, props) => ({
    ...state,
    organisationNudges: props.nudges
  })),

  on(GrowthActions.GetUsefulNudges.Success, (state, props) => ({
    ...state,
    usefulNudges: props.nudges
  })),
  on(GrowthActions.GetTopWorkOnThemes.Success, (state, props) => ({
    ...state,
    workOnThemes: props.themes
  })),

  on(GrowthActions.ReflectOnNudge.Success, (state, props) => ({
    ...state,
    dashboardNudge: props.nudge
  })),

  on(GrowthActions.GetPreviousNudge.Success, (state, props) => ({
    ...state,
    previousNudge: props.nudge
  })),

  on(GrowthActions.ReflectOnPreviousNudge.Success, (state, props) => ({
    ...state,
    previousNudge: props.nudge
  })),
);
