import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AppState } from 'src/app/+state/app.state';
import {
  selectRequestingProfileAccess,
  selectRequestingProfileAccessResponse,
} from 'src/app/+state/user/user.selector';
import * as UserActions from '../../../+state/user/user.actions';

@Component({
  selector: 'app-request-profile-access',
  templateUrl: './request-profile-access.component.html',
  styleUrls: ['./request-profile-access.component.css'],
})
export class RequestProfileAccessComponent implements OnInit {
  requestingProfileAccess$ = this.store.select(selectRequestingProfileAccess);
  response$ = this.store.select(selectRequestingProfileAccessResponse);

  email: string = '';

  constructor(public modalRef: BsModalRef, private store: Store<AppState>) {}

  ngOnInit(): void {}

  close() {
    this.store.dispatch(UserActions.ClearRequestProfileAccessResponse());

    this.modalRef.hide();
  }

  requestAccess() {
    console.log('Requesting Access to: ', this.email);

    //this.store.dispatch(UserActions.RequestProfileAccess.Request({ email: this.email }));
  }
}
