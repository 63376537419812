import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { AppState } from 'src/app/+state/app.state';
import {
  RemoveNotification,
  SetNotificationAsAcknowledged,
} from 'src/app/+state/signalr/signalr.actions';
import { selectNotificationsWithUser } from 'src/app/+state/signalr/signalr.selector';
import { selectMyTeams } from 'src/app/+state/team/team.selector';
import { selectAllUserEntities } from 'src/app/+state/user/user.selector';
import { environment } from 'src/environments/environment';
import {
  GetAllMindflickAccounts,
  GetMindflickAccountsForCurrentUser,
} from '../../../+state/account/account.actions';
import {
  selectAllAccounts,
  selectCurrentAccountId,
  selectUserAccounts,
} from '../../../+state/account/account.selector';
import { AddEvent } from '../../../+state/audit/audit.actions';
import { SetSubMenuSmallPopout } from '../../../+state/layout/actions/layout.actions';
import { SubMenuSmallPopoutEnum } from '../../../+state/layout/reducers/layout.reducers';
import { StrengthSpottedNotification } from '../../../+state/profile/profile.actions';

import { faCloseSVG, faNextSVG, faTickSVG } from '../../../../icons';
import { isNonNull } from '../../helpers/rxjs-type-guards';
import { User } from '../../models';
import { AuditEventType, PlatformAreas } from '../../models/enums/audit.enum';
import { ConnectionStatus } from '../../models/enums/connection-status.enum';
import { NotificationType } from '../../models/enums/notification-types.enum';
import { PortalNotification } from '../../models/notifications.interface';
import { DialogService } from '../../services/dialog.service';
import { clearRedirectUrlStorage } from '../../helpers/clear-redirect-storage';

@Component({
  selector: 'app-notifications-dropdown',
  templateUrl: './notifications-dropdown.component.html',
  styleUrls: ['./notifications-dropdown.component.scss'],
})
export class NotificationsDropdownComponent implements OnInit, OnDestroy {
  destroyed$: Subject<boolean> = new Subject<boolean>();

  notifications$ = this.store.select(selectNotificationsWithUser);
  allUsers$ = this.store.select(selectAllUserEntities);
  teams$ = this.store.select(selectMyTeams);
  currentAccount$ = this.store.select(selectCurrentAccountId);
  mindflickAccounts$ = this.store.select(selectUserAccounts);

  notificationType = NotificationType;
  notifications: PortalNotification[] = [];
  notificationUsers: User[] = [];

  currentAccountId = -1;
  mindflickAccountTeamNames: { [key: number]: string } = {};

  close = faCloseSVG;
  tick = faTickSVG;
  right = faNextSVG;

  ConnectionStatus = ConnectionStatus;

  nowMinusTwelve = new Date();

  public publicPhotoBasePath = environment.publicStorageUrl + 'photos/current/';

  constructor(
    private store: Store<AppState>,
    private dialogService: DialogService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.store.dispatch(GetMindflickAccountsForCurrentUser.Request());

    this.store.dispatch(
      AddEvent({
        event: {
          eventDate: new Date(),
          eventType: AuditEventType.PageVisited,
          area: PlatformAreas.Notifications,
        },
      })
    );
    this.nowMinusTwelve.setHours(new Date().getHours() - 12);
    this.notifications$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((notifications) => {
        this.notifications = notifications;
      });

    this.currentAccount$
      .pipe(takeUntil(this.destroyed$), filter(isNonNull))
      .subscribe((id) => {
        this.currentAccountId = id;
      });

    this.mindflickAccounts$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((accounts) => {
        accounts.forEach((account) => {
          this.mindflickAccountTeamNames[account.id] = account.name;
        });
      });
  }

  dismissNotification(notification: PortalNotification) {
    this.store.dispatch(
      RemoveNotification.Request({ notificationId: notification.id })
    );
  }

  acceptRequest(notification: PortalNotification) {
    //need to dispatch an accepted date for userId in MyTeamMembers
    this.store.dispatch(
      SetNotificationAsAcknowledged.Request({ notificationId: notification.id })
    );
    if (notification.type == this.notificationType.ConnectionRequest) {
      this.dialogService.showAcceptConnectionRequestDialog(
        notification.sendingUserAK,
        notification.id
      );
    } else if (notification.type == this.notificationType.TeamRequest) {
      this.dialogService.showAcceptTeamRequestDialog(
        JSON.parse(notification.payloadJson).TeamId,
        notification.id
      );
    }
  }

  viewProfile({ id, sendingUserAK }: PortalNotification) {
    this.store.dispatch(
      SetNotificationAsAcknowledged.Request({ notificationId: id })
    );

    this.closeNotifications();

    this.router.navigate([`/profile/${sendingUserAK}`]);
  }

  viewSpottedStrength({ id, payloadJson, sendingUserAK }: PortalNotification) {
    this.store.dispatch(
      SetNotificationAsAcknowledged.Request({ notificationId: id })
    );

    this.closeNotifications();

    var strengthId = JSON.parse(payloadJson);
    this.store.dispatch(StrengthSpottedNotification.View({ strengthId, userAK: sendingUserAK }));

    this.router.navigate([`/profile`]);
  }

  closeNotifications() {
    this.store.dispatch(
      SetSubMenuSmallPopout({ subMenuSmallPopout: SubMenuSmallPopoutEnum.None })
    );
  }

  switchAccounts(accountId: number) {
    let url = localStorage.getItem(environment.localStoragePrefix + 'redirect-url')!;
    localStorage.setItem(environment.localStoragePrefix + 'selected-mindflick-account', accountId.toString());
    clearRedirectUrlStorage()
    window.location.href = window.location.href.substring(0, 24);
    window.location.href += url?.slice(1);
    window.location.reload();
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.unsubscribe();
  }
}
