export enum ButtonActions {
  AddMember,
  RemoveMember,
  Request,
  Pending,
  Disabled,
  TeamRequest,
  RevokeRequest,
  Connected,
  adminRightsUnchecked,
  adminRightsChecked,
}
