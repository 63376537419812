import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-cope-framework',
  templateUrl: './cope-framework.component.html',
  styleUrl: './cope-framework.component.scss'
})
export class CopeFrameworkComponent {
  @Input()
  selectedLetter?: string;
}
