import { createAction, props } from '@ngrx/store';
import { AlertMessage } from '../../../shared/models/alert.interface';
import {
  BackgroundStyle,
  HeaderLogo,
  SubMenuDropdownEnum,
  SubMenuLargePopoutEnum,
  SubMenuSmallPopoutEnum,
} from '../reducers/layout.reducers';

export const noOpAction = createAction('[NoOp]');

export const setBackground = createAction(
  '[Layout] Set Background',
  props<{
    style: BackgroundStyle;
  }>()
);

export const setTitleBar = createAction(
  '[Layout] Set Title Bar',
  props<{
    visible: boolean;
  }>()
);

export const setHeaderLogo = createAction(
  '[Layout] Set Header Logo',
  props<{ logo: HeaderLogo }>()
);

export const UploadImageError = createAction(
  '[Layout] Upload Image Error',
  props<{
    error: string;
  }>()
);

export const ToggleWorkOnsGrid = createAction('[Layout] Toggle Work Ons Grid');

export const TogglePlaybooksGrid = createAction(
  '[Layout] Toggle Playbooks Grid'
);

export const SetSubMenuDropdown = createAction(
  '[Layout] Set Sub Menu Dropdown',
  props<{ subMenuDropdown: SubMenuDropdownEnum }>()
);

export const SetSubMenuSmallPopout = createAction(
  '[Layout] Set Sub Menu Small Popout',
  props<{ subMenuSmallPopout: SubMenuSmallPopoutEnum }>()
);

export const SetSubMenuLargePopout = createAction(
  '[Layout] Set Sub Menu Large Popout',
  props<{ subMenuLargePopout: SubMenuLargePopoutEnum }>()
);

export const SetSubMenuLargePopoutSuccess = createAction(
  '[Layout] Set Sub Menu Large Popout Success'
);

export const SetSubMenuLargePopoutFail = createAction(
  '[Layout] Set Sub Menu Large Popout Fail',
  props<{ error: any }>()
);

export const SetConnectionsViewed = createAction(
  '[Layout] Set Connections Viewed'
);

export const SetDashboardIsLoading = createAction(
  '[Layout] Set Dashboard Is Loading',
  props<{ isLoading: boolean }>()
);

export namespace DisplaySnackbarAlert {
  export const SetAlert = createAction(
    '[Layout] Set Snackbar Alert',
    props<{ alert: AlertMessage }>()
  );

  export const ClearAlert = createAction('[Layout] Clear Snackbar Alert');
}

export const SetIsEditingTeamMechanics = createAction(
  '[Layout] Set IsEditingTeamMechanics',
  props<{ isEditing: boolean }>()
);

export const ShowLockedUserHoldingScreen = createAction(
  '[Layout] Show Locked User Holding Screen'
);

export const ShowRequiresExternalAuthenticationScreen  = createAction(
  '[Layout] Show Requires External Authentication Screen'
);

export const SetNavigatedToSurvery = createAction(
  '[Layout] Set Navigated To Survey'
);

export const ShowChampionCharter = createAction(
  '[Layout] Show Champion Charter'
);
