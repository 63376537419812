import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AppState } from 'src/app/+state/app.state';
import { AcceptChampionCharter } from 'src/app/+state/user/user.actions';

@Component({
  selector: 'app-champion-charter-sign',
  templateUrl: './champion-charter-sign.component.html',
  styleUrls: ['./champion-charter-sign.component.scss'],
})
export class ChampionCharterSignComponent {
  agreementConfirmed = false;

  constructor(private store: Store<AppState>) {}

  acceptChampionCharter() {
    this.store.dispatch(AcceptChampionCharter.Request());
  }
}
