import { Component, Input, OnInit } from '@angular/core';
import { AppState } from 'src/app/+state/app.state';
import { ConnectionDTO, TopTipToConnect } from '../../models';
import { TeamSpotlightType } from '../../models/enums/team-spotlight-type.enum';
import { faStar as faStar } from '@fortawesome/free-solid-svg-icons';
import { faStar as fasStar } from '@fortawesome/free-regular-svg-icons';
import { Store } from '@ngrx/store';
import {
  RemoveTopTip,
  SetTopTip,
} from 'src/app/+state/profile/profile.actions';
import {
  selectCurrentUser,
  selectSelectedUserId,
} from 'src/app/+state/user/user.selector';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-spotlight-tab-items',
  templateUrl: './spotlight-tab-items.component.html',
  styleUrls: ['./spotlight-tab-items.component.scss'],
})
export class SpotlightTabItemsComponent implements OnInit {
  @Input() spotLightItems: ConnectionDTO[] = [];
  @Input() spotlightTab: TeamSpotlightType = TeamSpotlightType.TipsToConnect;
  @Input() tileTitle?: string;
  @Input() tileDescription?: string;
  @Input() emptyChair?: any;
  @Input() preferences?: any;
  @Input() showEmpty = false;
  star = faStar;
  starSolid = fasStar;
  newTopTip?: TopTipToConnect;
  viewedUserAk$ = this.store.select(selectSelectedUserId);
  currentUser$ = this.store.select(selectCurrentUser);
  destroyed$ = new Subject<boolean>();
  currentUserAk?: Number;
  viewedUserAk?: Number;

  //select current userAk

  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    this.viewedUserAk$.pipe(takeUntil(this.destroyed$)).subscribe((x) => {
      this.viewedUserAk = x;
    });

    this.currentUser$.pipe(takeUntil(this.destroyed$)).subscribe((user) => {
      this.currentUserAk = user?.id;
    });
  }

  // this.snackbarAlert$
  // .pipe(takeUntil(this.destroyed$), filter(isNonNull))
  // .subscribe((alert) => {
  //   this.alert = undefined;
  //   this.alert = alert;
  // });

  setTopTip(id: Number | undefined) {
    if (id != null) {
      this.spotLightItems = this.spotLightItems.map((x) => {
        return x.id === id
          ? {
              ...x,
              isTopTip: true,
            }
          : {
              ...x,
              isTopTip: false,
            };
      });
    }

    this.newTopTip = {
      viewedUserAk: this.viewedUserAk ?? 0,
      userAk: this.currentUserAk ?? 0,
      tipsToConnectId: id ?? 0,
    };

    if (this.newTopTip != undefined) {
      this.store.dispatch(SetTopTip.Request({ pinnedTip: this.newTopTip }));
    }
  }

  removeTopTip(id: Number | undefined) {
    if (id != null) {
      this.spotLightItems = this.spotLightItems.map((x) => {
        //we can set all to false
        return {
          ...x,
          isTopTip: false,
        };
      });

      this.newTopTip = {
        viewedUserAk: this.viewedUserAk ?? 0,
        userAk: this.currentUserAk ?? 0,
        tipsToConnectId: id,
      };
      this.store.dispatch(RemoveTopTip.Request({ pinnedTip: this.newTopTip }));
    }
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.unsubscribe();
  }
}
