import { Dictionary } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import { User } from 'src/app/shared/models';
import { PortalNotification } from 'src/app/shared/models/notifications.interface';
import { ConnectionStatus } from '../../shared/models/enums/connection-status.enum';
import { AppState, signalrStateKey } from '../app.state';
import { selectAllUserEntities, selectAllUsers } from '../user/user.selector';
import { SignalrState, notificationAdapter } from './signalr.reducer';

export const selectNotificationState = (state: AppState) =>
  state[signalrStateKey].notifications;
export const selectSignalrState = (state: AppState) => state[signalrStateKey];

export const selectNotificationSendingUserAk = (state: AppState) => state[signalrStateKey].sendingUserAk;

export const selectSignalRConnectionStatus = (state: AppState) =>
  state[signalrStateKey].connectionStatus;
export const selectError = (state: AppState) => state[signalrStateKey].error;
export const selectSignalRExampleLastMessage = (state: AppState) =>
  state[signalrStateKey].lastMessage;

const {
  selectIds: allNotificationIds,
  selectEntities: allNotificationEntities,
  selectAll: allNotifications,
  selectTotal: totalNotifications,
} = notificationAdapter.getSelectors(selectNotificationState);

export const selectAllNotifications = allNotifications;

export const selectShowNotificationList = (state: AppState) =>
  state[signalrStateKey].notificationsShowing;

export const selectSignalrIsConnecting = createSelector(
  selectSignalRConnectionStatus,
  (status: ConnectionStatus) => {
    return status == ConnectionStatus.Requested;
  }
);

export const selectNotificationsWithUser = createSelector(
  selectAllNotifications,
  selectAllUserEntities,
  (notifications: PortalNotification[], users: Dictionary<User>) => {
    return notifications.map((x) => ({
      ...x,
      sendingUser: users[x.sendingUserAK],
    }));
  }
);

