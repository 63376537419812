import { ClipboardModule } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BaseChartDirective } from 'ng2-charts';
import { AlertModule } from 'ngx-bootstrap/alert';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { ImageCropperComponent } from 'ngx-image-cropper';
import { NestedEllipsisModule } from 'ngx-nested-ellipsis';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AlertSnackbarComponent } from './components/alert-snackbar/alert-snackbar.component';
import { AutocompleteInputComponent } from './components/autocomplete-input/autocomplete-input.component';
import { BtnActionComponent } from './components/btn-action/btn-action.component';
import { BtnTertiaryComponent } from './components/btn-tertiary/btn-tertiary.component';
import { CardComponent } from './components/card/card.component';
import { CarouselComponent } from './components/carousel/carousel.component';
import { ChangePictureModalComponent } from './components/change-picture-modal/change-picture-modal.component';
import { ChooseFileModalComponent } from './components/choose-file-modal/choose-file-modal.component';
import { ConfirmModalComponent } from './components/confirm-modal/confirm-modal.component';
import { DownloadsComponent } from './components/downloads/downloads.component';
import { DropdownSelectComponent } from './components/dropdown-select/dropdown-select.component';
import { EmptyChairIconComponent } from './components/empty-chair-icon/empty-chair-icon.component';
import { GrowthDetailsPopoutComponent } from './components/growth-details-popout/growth-details-popout.component';
import { ItemListComponent } from './components/item-list/item-list.component';
import { LinkModalComponent } from './components/link-modal/link-modal.component';
import { LockedUserHoldingComponent } from './components/locked-user-holding/locked-user-holding.component';
import { MultiSelectListWithSearchComponent } from './components/multi-select-list-with-search/multi-select-list-with-search.component';
import { NotificationsDropdownComponent } from './components/notifications-dropdown/notifications-dropdown.component';
import { NudgeDetailsModalComponent } from './components/nudge-details-modal/nudge-details-modal.component';
import { PaginatedTabComponent } from './components/paginated-tab/paginated-tab.component';
import { PulseSurveyComponent } from './components/pulse-survey/pulse-survey.component';
import { RequestProfileAccessComponent } from './components/request-profile-access/request-profile-access.component';
import { SearchInputComponent } from './components/search-input/search-input.component';
import { SelectTeamModalComponent } from './components/select-team-modal/select-team-modal.component';
import { SendInviteComponent } from './components/send-invite/send-invite.component';
import { NavbarItemComponent } from './components/side-navbar/navbar-item/navbar-item.component';
import { SideNavbarComponent } from './components/side-navbar/side-navbar.component';
import { SpotlightTabItemsComponent } from './components/spotlight-tab-items/spotlight-tab-items.component';
import { SpotlightComponent } from './components/spotlight/spotlight.component';
import { StrengthsListComponent } from './components/strengths-list/strengths-list.component';
import { SuggestedWorkOnsComponent } from './components/suggested-work-ons/suggested-work-ons.component';
import { TeamMapComponent } from './components/team-map/team-map.component';
import { TipsToConnectCirclesComponent } from './components/tips-to-connect-circles/tips-to-connect-circles.component';
import { ToggleBoxComponent } from './components/toggle-box/toggle-box.component';
import { BtnTooltipComponent } from './components/tooltips/btn-tooltip/btn-tooltip.component';
import { TooltipMenuComponent } from './components/tooltips/tooltip-menu/tooltip-menu.component';
import { TooltipPagesComponent } from './components/tooltips/tooltip-pages/tooltip-pages.component';
import { UserPanelSmallComponent } from './components/user-panel-small/user-panel-small.component';
import { WorkOnCardSmallComponent } from './components/work-on-card-small/work-on-card-small.component';
import { DateAgoPipe } from './pipes/date-ago.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { SecureImagePipe } from './pipes/secure-image.pipe';
import { AddUserSuccessModalComponent } from './components/add-user-success-modal/add-user-success-modal.component';
import { SmoothHeightDirective } from './directives/smooth-height.directive';
import { NudgeReflectionPopupComponent } from './components/nudge-reflection-popup/nudge-reflection-popup.component';
import { StrengthsCurveComponent } from './components/strengths-curve/strengths-curve.component';
import { ChampionCharterContentComponent } from './components/champion-charter-content/champion-charter-content.component';
import { ChampionCharterSignComponent } from './components/champion-charter-sign/champion-charter-sign.component';
import { AccountLockedInfoModalComponent } from './components/account-locked-info-modal/account-locked-info-modal.component';
import { LockedRequiresExternalAuthComponent } from './components/locked-requires-external-auth/locked-requires-external-auth.component';
import { CallbackComponent } from './components/callback/callback.component';
import { AddDeactivatedUsersModalComponent } from './components/add-deactivated-users-modal/add-deactivated-users-modal.component';
import { IsGrantedDirective } from './directives/is-granted.directive';
import { EditUserPopoutComponent } from './components/edit-user-popout/edit-user-popout.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { MindflickOrganisationDetailsPopoutComponent } from './components/mindflick-organisation-details-popout/mindflick-organisation-details-popout.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { CopeFrameworkComponent } from './components/cope-framework/cope-framework.component';
import { FlexFrameworkComponent } from './components/flex-framework/flex-framework.component';
import { BtnComponent } from './components/btn/btn.component';

@NgModule({
  declarations: [
    ItemListComponent,
    MultiSelectListWithSearchComponent,
    SideNavbarComponent,
    NavbarItemComponent,
    ToggleBoxComponent,
    RequestProfileAccessComponent,
    SendInviteComponent,
    BtnActionComponent,
    BtnTertiaryComponent,
    UserPanelSmallComponent,
    SecureImagePipe,
    TeamMapComponent,
    SpotlightTabItemsComponent,
    SearchInputComponent,
    DropdownSelectComponent,
    SpotlightComponent,
    TipsToConnectCirclesComponent,
    EmptyChairIconComponent,
    NotificationsDropdownComponent,
    GrowthDetailsPopoutComponent,
    DateAgoPipe,
    SelectTeamModalComponent,
    BtnTooltipComponent,
    TooltipMenuComponent,
    TooltipPagesComponent,
    ConfirmModalComponent,
    ChooseFileModalComponent,
    DownloadsComponent,
    SafeHtmlPipe,
    SuggestedWorkOnsComponent,
    WorkOnCardSmallComponent,
    ChangePictureModalComponent,
    CarouselComponent,
    CardComponent,
    AlertSnackbarComponent,
    AutocompleteInputComponent,
    PaginatedTabComponent,
    LinkModalComponent,
    LockedUserHoldingComponent,
    NudgeDetailsModalComponent,
    PulseSurveyComponent,
    StrengthsListComponent,
    AddUserSuccessModalComponent,
    SmoothHeightDirective,
    NudgeReflectionPopupComponent,
    StrengthsCurveComponent,
    ChampionCharterContentComponent,
    ChampionCharterSignComponent,
    AccountLockedInfoModalComponent,
    LockedRequiresExternalAuthComponent,
    CallbackComponent,
    AddDeactivatedUsersModalComponent,
    IsGrantedDirective,
    EditUserPopoutComponent,
    MindflickOrganisationDetailsPopoutComponent,
    CopeFrameworkComponent,
    FlexFrameworkComponent,
    BtnComponent
  ],
  imports: [
    CommonModule,
    TypeaheadModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    NgxSpinnerModule,
    BaseChartDirective,
    TabsModule,
    ProgressbarModule,
    NestedEllipsisModule,
    ImageCropperComponent,
    AlertModule.forRoot(),
    PaginationModule,
    TooltipModule,
    ClipboardModule,
    BsDatepickerModule.forRoot(),
    NgxDatatableModule
  ],
  exports: [
    ItemListComponent,
    MultiSelectListWithSearchComponent,
    SideNavbarComponent,
    ToggleBoxComponent,
    RequestProfileAccessComponent,
    BtnActionComponent,
    BtnTertiaryComponent,
    UserPanelSmallComponent,
    SecureImagePipe,
    TeamMapComponent,
    SpotlightTabItemsComponent,
    SearchInputComponent,
    DropdownSelectComponent,
    SpotlightComponent,
    TipsToConnectCirclesComponent,
    EmptyChairIconComponent,
    NotificationsDropdownComponent,
    GrowthDetailsPopoutComponent,
    BtnTooltipComponent,
    TooltipMenuComponent,
    DateAgoPipe,
    TooltipPagesComponent,
    DownloadsComponent,
    SuggestedWorkOnsComponent,
    WorkOnCardSmallComponent,
    ChangePictureModalComponent,
    CarouselComponent,
    CardComponent,
    SafeHtmlPipe,
    AlertSnackbarComponent,
    SafeHtmlPipe,
    AutocompleteInputComponent,
    PaginatedTabComponent,
    LockedUserHoldingComponent,
    LockedRequiresExternalAuthComponent,
    PulseSurveyComponent,
    StrengthsListComponent,
    AddUserSuccessModalComponent,
    SmoothHeightDirective,
    NudgeReflectionPopupComponent,
    ChampionCharterContentComponent,
    ChampionCharterSignComponent,
    IsGrantedDirective,
    EditUserPopoutComponent,
    MindflickOrganisationDetailsPopoutComponent,
    CopeFrameworkComponent,
    FlexFrameworkComponent,
    BtnComponent
  ],
})
export class SharedModule {}
