import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { DateSortDesc } from 'src/app/shared/helpers/sorting-helpers';
import { PortalNotification } from 'src/app/shared/models/notifications.interface';
import { ConnectionStatus } from '../../shared/models/enums/connection-status.enum';
import * as SignalrActions from './signalr.actions';
import { CommonActions } from '../common-actions';
import { StrengthSpottedNotification } from '../profile/profile.actions';

export interface NotificationState extends EntityState<PortalNotification> {}

export const notificationAdapter: EntityAdapter<PortalNotification> =
  createEntityAdapter<PortalNotification>({
    sortComparer: sortNotificationsByDate,
  });
export function sortNotificationsByDate(
  a: PortalNotification,
  b: PortalNotification
): number {
  return DateSortDesc(a.notificationDate, b.notificationDate);
}

export interface SignalrState {
  connectionStatus: ConnectionStatus;
  error: any;
  lastMessage: string;
  notifications: NotificationState;
  notificationsShowing: boolean;
  sendingUserAk?: number;
  isDownloadingTeamMap: boolean;
}

export const initialSignalrState: SignalrState = {
  connectionStatus: ConnectionStatus.Unconnected,
  error: null,
  lastMessage: '',
  notifications: notificationAdapter.getInitialState(),
  notificationsShowing: false,
  isDownloadingTeamMap: false
};

export const signalrReducer = createReducer(
  initialSignalrState,

  on(CommonActions.ClearState, (state, props) => ({
    ...initialSignalrState
  })),

  on(SignalrActions.SetConnectionStatus, (state, props) => ({
    ...state,
    connectionStatus: props.connectionStatus,
    error: props.error,
  })),

  on(SignalrActions.Example.Handle, (state, props) => ({
    ...state,
    lastMessage: props.message,
  })),
  on(SignalrActions.Handle, (state, props) => ({
    ...state,
    notifications: notificationAdapter.upsertOne(
      props.notification,
      state.notifications
    ),
  })),
  on(StrengthSpottedNotification.View, (state, props) => ({
    ...state,
    sendingUserAk: props.userAK
  })),
  on(SignalrActions.RemoveNotification.Success, (state, props) => ({
    ...state,
    notifications: notificationAdapter.removeOne(
      props.notificationId,
      state.notifications
    ),
  })),
  on(SignalrActions.GetNotifications.Success, (state, props) => ({
    ...state,
    notifications: notificationAdapter.setMany(
      props.notifications,
      state.notifications
    ),
  })),
  on(SignalrActions.MarkNotificationAsSeen.Success, (state, props) => ({
    ...state,
    notifications: notificationAdapter.upsertMany(
      props.resp,
      state.notifications
    ),
  })),
  on(SignalrActions.SetNotificationAsAcknowledged.Success, (state, props) => ({
    ...state,
    notifications: notificationAdapter.updateOne(
      { id: props.notificationId, changes: { acknowledged: true } },
      state.notifications
    ),
  }))
);
