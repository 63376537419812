<div class="pt-3 overflow-auto"
     [ngStyle]="listHeight ? { height: listHeight + 'px' } : {}">
  <div class="item mb-2 ps-4 pt-4 pe-4 pb-3"
       *ngFor="let item of playingStrengths; let idx = index"
       [class.shepherd-selected-strength]="selectedIdx == idx"
>
    <div class="d-flex align-items-center">
      <img *ngIf="!showNumbers; else showNumber"
           class="m-0"
           width="42"
           height="42"
           [src]="iconPath" />
      <ng-template #showNumber>
        <div class="strength-number highlight-numbers" id="strength-count">
          0{{ idx + 1 }}
        </div>
      </ng-template>
      <div class="ms-3 flex-fill body-copy pe-1">{{ item.description }}</div>
    </div>
    <div *ngIf="isStrength" class="ms-0 mt-2">
        <div class="ms-1 body-copy text-primary cursor-pointer"
             (click)="openStrengthModal(item.id)"
             *ngIf="item.strengthSpotterIds && item.comments">
          Spotted
          {{
            item.timesSpotted | i18nPlural : strengthMessageMapping
          }}
        </div>
      <div *ngIf="!isOwnProfile && isStrength" class="d-flex justify-content-between text-primary body-copy align-items-baseline"
           >
        <div (click)="openStrengthModal(item.id)"
             [class.pe-none]="shepherdService.isActive"
             [class.cursor-pointer]="!shepherdService.isActive"
             >
          Spotted {{ item.timesSpotted  | i18nPlural : strengthMessageMapping }}
        </div>
        <div class="d-flex align-items-baseline shepherd-strength-spot"
                          [class.pe-none]="shepherdService.isActive && (onboardingService.currentStepId$ | async) != onboardingService.PROFILE_SPOT_STRENGTH"
             [class.cursor-pointer]="!shepherdService.isActive || (onboardingService.currentStepId$ | async) == onboardingService.PROFILE_SPOT_STRENGTH"
             (click)="selectStrength(item, false); selectedIdx=idx">
          <fa-icon [icon]="thumbEmpty" class="fs-5 d-flex align-self-end"></fa-icon>
          +1
        </div>
      </div>
    </div>
  </div>
</div>
