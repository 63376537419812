import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ColumnMode, SortType, SelectionType } from '@swimlane/ngx-datatable';
import { AppState } from 'src/app/+state/app.state';
import { ClearSelectedMindflickOrganisation, GetMindflickOrganisationUsers } from 'src/app/+state/super-admin/super-admin.actions';
import { selectSelectedMindflickOrganisation, selectSelectedMindflickOrganisationWithAccountAdminNames } from 'src/app/+state/super-admin/super-admin.selector';
import { faAddSVG, faCloseSVG, faEditSVG } from 'src/icons';
import { MindflickAccount } from '../../models';
import { Subject, filter, take, takeUntil } from 'rxjs';
import { DialogService } from '../../services/dialog.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { EditMindflickAccountModalComponent } from 'src/app/super-admin/edit-mindflick-account-modal/edit-mindflick-account-modal.component';
import { SetSelectedAccount } from 'src/app/+state/account/account.actions';
import { EditOrganisationAdminModalComponent } from 'src/app/super-admin/edit-organisation-admin-modal/edit-organisation-admin-modal.component';
import { EditOrganisationLeadModalComponent } from 'src/app/super-admin/edit-organisation-lead-modal/edit-organisation-lead-modal.component';
import { AddMindflickAccountModalComponent } from '../../../super-admin/add-mindflick-account-modal/add-mindflick-account-modal.component';
import { isNonNull } from '../../helpers/rxjs-type-guards';
import { EditSeatsModalComponent } from '../../../super-admin/mindflick-organisations/edit-seats-modal/edit-seats-modal.component';

@Component({
  selector: 'app-mindflick-organisation-details-popout',
  templateUrl: './mindflick-organisation-details-popout.component.html',
  styleUrls: ['./mindflick-organisation-details-popout.component.scss']
})
export class MindflickOrganisationDetailsPopoutComponent implements OnInit, AfterViewInit, OnDestroy {
  destroyed$ = new Subject<boolean>();
  org$ = this.store.select(selectSelectedMindflickOrganisation);
  mindflickOrganisation$ = this.store.select(selectSelectedMindflickOrganisationWithAccountAdminNames);

  selectedAccount?: MindflickAccount;
  accounts: MindflickAccount[] = [];

  close = faCloseSVG;
  editIcon = faEditSVG;
  plus = faAddSVG;

  ColumnMode = ColumnMode;
  SortType = SortType;
  SelectionType = SelectionType;
  selected: any[] = [];

  loading = true;

  constructor(private store: Store<AppState>, private dialogService: DialogService, private modalService: BsModalService) {}

  ngOnInit(): void {
    this.org$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(org => {
        if (org) {
          this.store.dispatch(GetMindflickOrganisationUsers.Request({ organisationId: org.id }));
        }
      });
    this.mindflickOrganisation$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(org => {
        if (org) {
          this.accounts = org.mindflickAccounts;
          if (this.selected.length != 0)
            this.selectedAccount = org.mindflickAccounts.find(x => x.id === this.selected[0].id);
        }

      });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.loading = false;
    }, 200);
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.unsubscribe();
  }

  accountSelected(accountId: number) {
    this.store.dispatch(SetSelectedAccount({ accountId: accountId }));

    this.modalService.show(EditMindflickAccountModalComponent);
  }

  openAddAccountModal() {
    this.mindflickOrganisation$
      .pipe(filter(isNonNull), take(1))
      .subscribe(org => {
        this.modalService.show(AddMindflickAccountModalComponent, { initialState: { orgId: org.id } });
      });
  }

  closeAccount(accountId: number) {
    this.dialogService
      .confirm(
        'This will close the account, making the Mindflick platform no longer accessible to it\'s users. Are you sure you wish to continue?',
        'Warning',
        false
      )
      .pipe(take(1))
      .subscribe(result => {
        if (result) {

        }
      });
  }

  closePopout() {
    this.store.dispatch(ClearSelectedMindflickOrganisation());
  }

  openEditOrganisationAdminModal() {
    this.mindflickOrganisation$
      .pipe(take(1))
      .subscribe(org => {
        if (org) {
          this.modalService.show(EditOrganisationAdminModalComponent, {
            initialState: {
              selectedUserAk: org.adminUserAk,
              mindflickOrganisationId: org.id
            }
          });
        }

      });
  }

  openEditOrganisationLeadModal() {
    this.mindflickOrganisation$
      .pipe(take(1))
      .subscribe(org => {
        if (org) {
          this.modalService.show(EditOrganisationLeadModalComponent, {
            initialState: {
              selectedUserAk: org.organisationLeadAk,
              mindflickOrgId: org.id
            }
          });
        }
      });
  }

  editSeats(accountId: number) {
    this.mindflickOrganisation$
      .pipe(take(1))
      .subscribe(org => {
        if (org) {
          var account = org.mindflickAccounts.find(x => x.id == accountId);
          this.modalService.show(EditSeatsModalComponent, {
            class: 'modal-dialog-centered',
            initialState: {
              mindflickAccountId: account?.id,
              minSeats: account?.usedSeats,
              currentSeats: account?.seats,
              orgId: org.id
            }
          });
        }
      });
  }
}
