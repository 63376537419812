import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { faCloseSVG } from 'src/icons';
import { UserBasicDetails } from '../../models';

@Component({
  selector: 'app-add-user-success-modal',
  templateUrl: './add-user-success-modal.component.html',
  styleUrls: ['./add-user-success-modal.component.scss'],
})
export class AddUserSuccessModalComponent implements OnInit {
  totalInvited = 0;

  notInvited: UserBasicDetails[] = [];

  closeIcon = faCloseSVG;

  constructor(private modalRef: BsModalRef) {}

  ngOnInit(): void {}

  close() {
    this.modalRef.hide();
  }
}
