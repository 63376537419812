<div class="dropdown w-100" [ngClass]="{'show': isDropdownOpen}" (appClickOutside)="isDropdownOpen = false; resetInput()">
  <div class="form-control d-flex" (click)="isDropdownOpen = true">
    <fa-icon *ngIf="isDropdownOpen" [icon]="search" class="fa-lg text-dark me-2 align-content-center"></fa-icon>
    <input type="text"
           class="flex-grow-1"
           [placeholder]="placeholder"
           [class.placeholder-black]="selectedItems.length > 0 && !isDropdownOpen"
           [(ngModel)]="searchTerm"
           (input)="filterItems()"
           (focus)="isDropdownOpen = true" />
    <fa-icon [icon]="dropdown" class="fa-lg text-dark"></fa-icon>
  </div>

  <div class="dropdown-menu options overflow-scroll w-100" [ngClass]="{'show': isDropdownOpen}">
    <div *ngFor="let item of filteredItems"
         [hover-class]="'option-highlighted'"
         class="option d-flex align-items-center"
         (click)="onSelect(item)">
      <input type="checkbox"
             class="form-check-input"
             [checked]="isSelected(item)" />
      <div class="d-flex flex-column ms-2">
        <label class="body-copy" [for]="item">{{ item.description }}</label>
        <label class="body-copy text-muted fs-12" [for]="item">{{ item.subText }}</label>
      </div>
    </div>
  </div>
</div>
