import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppState } from '../../../../+state/app.state';
import { ChangeSelectedPage } from '../../../../+state/tooltip/tooltip.actions';
import {
  selectSelectedPage,
  selectSelectedPageId,
  selectSelectedSectionNumberOfPage,
} from '../../../../+state/tooltip/tooltip.selector';
import { faBackSVG, faNextSVG } from '../../../../../icons';
import { TooltipPage } from '../../../models';

@Component({
  selector: 'app-tooltip-pages',
  templateUrl: './tooltip-pages.component.html',
  styleUrls: ['./tooltip-pages.component.scss'],
})
export class TooltipPagesComponent implements OnInit, OnDestroy {
  destroyed$: Subject<boolean> = new Subject<boolean>();

  selectedPage$ = this.store.select(selectSelectedPage);
  selectedPageId$: Observable<number> = this.store.select(selectSelectedPageId);
  numberOfPage$: Observable<number | undefined> = this.store.select(
    selectSelectedSectionNumberOfPage
  );

  selectedPage?: TooltipPage;
  selectedPageId?: number;
  numberOfPages?: number;

  next = faNextSVG;
  back = faBackSVG;

  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    this.selectedPage$.pipe(takeUntil(this.destroyed$)).subscribe((page) => {
      this.selectedPage = page;
    });

    this.selectedPageId$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((pageId) => {
        this.selectedPageId = pageId;
      });

    this.numberOfPage$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((numPages) => {
        this.numberOfPages = numPages!;
      });
  }

  changePage(pageChange: number) {
    this.store.dispatch(ChangeSelectedPage({ pageChange }));
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.unsubscribe();
  }
}
