<div class="header d-flex bg-dark text-white py-5">
  <div class="d-flex flex-column col-8">
    <h2 class="mb-2">Admin</h2>
    <h1>Help Center</h1>
  </div>
  <div class="w-100 position-relative" #search>
    <app-search-input [light]="true"
                      [placeholder]="'Search'"
                      value="currentSearchTerm"
                      (input)="changeCurrentSearchTerm($event)"
                      [(ngModel)]="currentSearchTerm"
                      [manualValue]="currentSearchTerm">
    </app-search-input>
    <div *ngIf="filteredCategories.length > 0" class="search-results" [style.width.px]="search.offsetWidth">
      <div *ngFor="let category of filteredCategories">
        <div *ngFor="let article of category.articles" class="d-flex flex-column my-1 text-black border-bottom">
          <p class="fw-bold mb-1">{{category.title}}</p>
          <p>{{article.title}}</p>
        </div>
      </div>
    </div>
    <div *ngIf="filteredCategories?.length === 0 && currentSearchTerm" class="search-results text-center" [style.width.px]="search.offsetWidth">
      <p class="fw-bold text-black mt-auto mb-auto">No results found.</p>
    </div>
  </div>


</div>
