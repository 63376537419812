import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { faCloseSVG, faDropdownSVG } from '../../../../icons';
import { ListItem } from '../../models/list-item.interface';

@Component({
  selector: 'app-multi-select-list-with-search',
  templateUrl: './multi-select-list-with-search.component.html',
  styleUrls: ['./multi-select-list-with-search.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: MultiSelectListWithSearchComponent,
    },
  ],
})
export class MultiSelectListWithSearchComponent<T>
  implements ControlValueAccessor, OnChanges
{
  // Need separate full list and available list so items can't be searched and added twice
  @Input()
  list: ListItem<T>[] | null = [];

  @Input()
  availableList: ListItem<T>[] | null = [];

  @Input()
  initialValue: number[] = [];

  @Output()
  change = new EventEmitter<number[]>();

  selectionStr: string = '';
  selectionObj: ListItem<T>[] = [];
  selection: number[] = [];

  touched = false;
  disabled = false;

  close = faCloseSVG;
  dropdown = faDropdownSVG;

  onChange = (selection: number[]) => {};

  onTouched = () => {};

  constructor(private cd: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges) {
    // The input value may have not been populated at the ngOnInit point in time
    // Listen for changes to the input values instead
    if (changes.initialValue)
      this.writeValue(changes.initialValue.currentValue);
  }

  // When an item is searched for an selected, push it into the selectionObj list to display in the control and push to the selection list so it can be propagated to the form accessor
  onSelect(item: ListItem<T>) {
    this.selectionStr = '';

    if (this.selection.indexOf(item.id) !== -1) return;

    this.selectionObj.push(item);
    this.selection.push(item.id);

    this.onChange(this.selection);
    this.change.emit(this.selection);
  }

  onRemove(item: ListItem<T>) {
    if (this.disabled) return;

    if (this.selection.indexOf(item.id) === -1) return;

    this.selectionObj.splice(this.selectionObj.indexOf(item), 1);
    this.selection.splice(this.selection.indexOf(item.id), 1);

    this.onChange(this.selection);
    this.change.emit(this.selection);
  }

  writeValue(selection: number[]): void {
    this.selection = selection;
    this.selectionObj = this.list
      ? this.list.filter((x) => selection.includes(x.id))
      : [];
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }
}
