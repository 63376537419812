<div class="modal-header justify-content-end">
    <fa-icon
      class="cursor-pointer fa-lg"
      [icon]="closeIcon"
      (click)="close()"
    ></fa-icon>
</div>
<div class="modal-body">
    <h2>Add Coordinator</h2>
    <h3 *ngIf="selectedAccount$ | async as account" class="mt-2">Select a coordinator to add to the {{ account.name }} account.</h3>
    <div class="mt-4">
        <label class="form-label">Select coordinator</label>
        <app-dropdown-select [options]="coordinatorOptions" [(ngModel)]="selectedUserAk" placeholder="Select coordinator"></app-dropdown-select>
    </div>
    <button class="btn btn-purple mt-4 w-100" (click)="addCoordinator()">Add Coordinator</button>
</div>
<div class="modal-bottom-border"></div>