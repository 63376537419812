import { Injectable } from '@angular/core';
import * as SignalrActions from './signalr.actions';
import { UserService } from '../../shared/services/user.service';
import { TeamService } from 'src/app/shared/services/team.service';
import { SignalrService } from 'src/app/shared/services/signalr.service';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { merge, of } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { NotificationService } from 'src/app/shared/services/notification.service';

@Injectable()
export class SignalrEffects {
  constructor(
    private actions$: Actions,
    private userService: UserService,
    private signalrService: SignalrService,
    private notificationService: NotificationService
  ) {}

  triggerExample$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SignalrActions.Example.Request),
      mergeMap((action: any) =>
        this.userService.sendExampleSignalrMessage(action.message).pipe(
          map((_) => SignalrActions.Example.Success()),
          catchError((error) => of(SignalrActions.Example.Fail({ error })))
        )
      )
    )
  );

  deleteNotification$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SignalrActions.RemoveNotification.Request),
      mergeMap((action) =>
        this.notificationService
          .deletePortalNotification(action.notificationId)
          .pipe(
            map(() => {
              return SignalrActions.RemoveNotification.Success({
                notificationId: action.notificationId,
              });
            }),
            catchError((error) =>
              of(SignalrActions.RemoveNotification.Fail({ error }))
            )
          )
      )
    )
  );

  getNotifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SignalrActions.GetNotifications.Request),
      mergeMap((action) =>
        this.notificationService.getNotifications().pipe(
          map((resp) => {
            return SignalrActions.GetNotifications.Success({
              notifications: resp,
            });
          }),
          catchError((error) =>
            of(SignalrActions.GetNotifications.Fail({ error }))
          )
        )
      )
    )
  );

  markNotificationAsSeen$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SignalrActions.MarkNotificationAsSeen.Request),
      mergeMap((action) =>
        this.notificationService
          .setNotificationSeen(action.notificationIds)
          .pipe(
            map((resp) =>
              SignalrActions.MarkNotificationAsSeen.Success({ resp })
            ),
            catchError((error) =>
              of(SignalrActions.MarkNotificationAsSeen.Fail({ error }))
            )
          )
      )
    )
  );

  setNotificationAcknowledged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SignalrActions.SetNotificationAsAcknowledged.Request),
      mergeMap((action) =>
        this.notificationService
          .setNotificationAcknowledged(action.notificationId)
          .pipe(
            map((resp) =>
              SignalrActions.SetNotificationAsAcknowledged.Success({
                notificationId: action.notificationId,
              })
            ),
            catchError((error) =>
              of(SignalrActions.SetNotificationAsAcknowledged.Fail({ error }))
            )
          )
      )
    )
  );

  notifyUserOfConnectionRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SignalrActions.NotifyUserOfConnectionRequest.Request),
      mergeMap((action) =>
        this.notificationService
          .notifyUserOfConnectionRequest(action.userAK)
          .pipe(
            map((resp) =>
              SignalrActions.NotifyUserOfConnectionRequest.Success()
            ),
            catchError((error) =>
              of(SignalrActions.NotifyUserOfConnectionRequest.Fail({ error }))
            )
          )
      )
    )
  );

  notifyUserOfConnectionSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SignalrActions.NotifyUserOfConnectionAccepted.Request),
      mergeMap((action) =>
        this.notificationService
          .notifyUserOfConnectionAccepted(action.userAK)
          .pipe(
            map((resp) =>
              SignalrActions.NotifyUserOfConnectionAccepted.Success()
            ),
            catchError((error) =>
              of(SignalrActions.NotifyUserOfConnectionAccepted.Fail({ error }))
            )
          )
      )
    )
  );

  notifyUserOfStrengthSpotted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SignalrActions.NotifyUserOfStrengthSpotted.Request),
      mergeMap((action) =>
        this.notificationService
          .notifyUserOfStrengthSpotted(action.userAK, action.strengthId)
          .pipe(
            map((resp) => SignalrActions.NotifyUserOfStrengthSpotted.Success()),
            catchError((error) =>
              of(SignalrActions.NotifyUserOfStrengthSpotted.Fail({ error }))
            )
          )
      )
    )
  );

  handleTeamMapProcessing$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SignalrActions.TeamMapProcessing.Updated),
      map(action => {
        return SignalrActions.TeamMapProcessing.TeamMapProcessed();
      })
    )
  );

}
