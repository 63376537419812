<div class="modal-header justify-content-end">
  <fa-icon class="cursor-pointer fa-lg"
           [icon]="closeIcon"
           (click)="close()"></fa-icon>
</div>
<div class="modal-body">
  <form [formGroup]="fgSeatAudit">
    <p>This account has {{ minSeats }} users currently active, it requires at least this many seats.</p>
    <div class="d-flex flex-column col-12 my-2">
      <label class="form-label">Seats</label>
      <input class="form-control" type="number" formControlName="seats" />
      <p *ngIf="seats?.hasError('required') && seats?.touched" class="form-error">Seats is required.</p>
      <p *ngIf="seats?.hasError('min') && seats?.touched" class="form-error">At least {{ minSeats}} seats are required.</p>

    </div>
    <div class="d-flex flex-column col-12 my-2">
      <label class="form-label">Reason for adjustment</label>
      <textarea class="form-control" formControlName="comment"></textarea>
      <p *ngIf="comment?.hasError('required') && comment?.touched" class="form-error">A reason why this change has been made is required</p>
    </div>
  </form>
  <button class="btn btn-purple w-100 mt-4" (click)="updateSeats()">
    Save changes
  </button>
</div>
<div class="modal-bottom-border"></div>
