import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../+state/app.state';
import { RbacService } from '../shared/services/rbac.service';
import { GetMindflickAccountSettings, GetMindflickOrganisation, GetUserAccountsWithUserManagePermission, GetValidChampionsForOrg, GetValidCoordinatorsForOrg } from '../+state/account-admin/account-admin.actions';
import { MindflickPermissionEnum } from '../shared/models/enums/mindflick-permission.enum';
import { MindflickRoleEnum } from '../shared/models/enums/mindflick-role.enum';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
})
export class AdminComponent implements OnInit {
  constructor(private store: Store<AppState>, private rbacService: RbacService) {}

  ngOnInit(): void {

    if (this.rbacService.isGrantedAnyAccount(MindflickPermissionEnum.AssignAccountChampion)) {
      this.store.dispatch(GetValidChampionsForOrg.Request());
    }

    if (this.rbacService.isGrantedAnyAccount(MindflickPermissionEnum.AssignAccountCoordinator) || this.rbacService.isGranted(MindflickRoleEnum.OrgAdmin)) {
      this.store.dispatch(GetValidCoordinatorsForOrg.Request());
    }

    this.store.dispatch(GetMindflickOrganisation.Request());
    this.store.dispatch(GetMindflickAccountSettings.Request({accId : 0}));
    this.store.dispatch(GetUserAccountsWithUserManagePermission.Request());
  }
}
